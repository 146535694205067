import {TableColumn} from '@fl/cmsch-fe-library';

import {OrganizationTableType} from '../../types/organization-table-type';
import {getCenterCodeFormat, getFarmCodeFormat} from './get-code-format';

export const columns: Array<TableColumn<OrganizationTableType>> = [
    {
        field: 'organizationCode',
        type: 'string',
    },
    {
        field: 'organizationName',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'roleCode',
        type: 'string',
    },
    {
        field: 'roleName',
        type: 'string',
    },
    {
        field: 'approvedFrom',
        type: 'date',
        dateFormat: 'date',
    },
    {
        field: 'approvedTo',
        type: 'date',
        dateFormat: 'date',
        filterTypes: ['null'],
    },
    {
        field: 'organizationCenterCode',
        type: 'string',
        formatValue: getCenterCodeFormat,
    },
    {
        field: 'farmCode',
        type: 'string',
        formatValue: getFarmCodeFormat,
    },
];
