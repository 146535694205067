const basicAnimalBrowserController = 'ProhlizecZvirat';
const comprehensiveAnimalBrowserController = 'KomplexniSeznam';

export const basicAnimalBrowserPermissions = {
    superUser: `${basicAnimalBrowserController}/PPSuperUzivatel`,
    breeder: `${basicAnimalBrowserController}/PPJakoChovatel`,
    authorizedOrganization: `${basicAnimalBrowserController}/PPOpravnenaOrganizace`,
    bullSelectorH: `${basicAnimalBrowserController}/BullSelector_H`,
    bullSelectorC: `${basicAnimalBrowserController}/BullSelector_C`,

};

export const comprehensiveAnimalBrowserPermissions = {
    superUser: `${comprehensiveAnimalBrowserController}/KSSuperuzivatel`,
    breeder: `${comprehensiveAnimalBrowserController}/KSChovatel`,
    authorizedOrganization: `${comprehensiveAnimalBrowserController}/KSOpravnenaOrganizace`,
};
