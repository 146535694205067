import * as Sentry from '@sentry/react';

const anonymousUsername = '(Anonymous)';

class Logger {
    constructor() {
        this.setAnonymousUser();
    }

    public setUser(username: string): void {
        Sentry.setUser({username});
    }

    public setAnonymousUser(): void {
        Sentry.setUser({username: anonymousUsername});
    }

    public logInfo(info: string): void {
        // eslint-disable-next-line no-console
        console.info(info);
        Sentry.captureEvent({
            message: info,
            level: 'info',
        });
    }

    public logWarn(warn: string): void {
        // eslint-disable-next-line no-console
        console.warn(warn);
        Sentry.captureEvent({
            message: warn,
            level: 'warning',
        });
    }

    public logError(err: Error): void {
        // eslint-disable-next-line no-console
        console.error(err);
        Sentry.captureException(err);
    }
}

export const logger = new Logger();
