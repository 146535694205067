import { FooterButtons, NullableKeys, Options, superUniqueClassName, todayDate } from '@fl/cmsch-fe-library';
import { isNull } from 'lodash/fp';
import moment from 'moment';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt, opt } from 'ts-opt';
import { useOurTranslation } from 'app/translations';
import { userDetailAction } from 'app/user-detail/model/action';
import { formHelpers, useForm } from 'utils/forms';
import { SubjectFormEntryData, subjectFormName, SubjectFormValues, subjectFormInitialValues, SubjectFormType } from '../../types/subject-form-values';
import { createValidate } from './validate';
import styles from './styles.sass';
interface Props {
  userProfileId: number;
  associationOptions: Options<number>;
  roleOptions: Options<number>;
  data: SubjectFormEntryData | null;
  subjectFormType: SubjectFormType;
  onSubmit(): void;
  onCancel(): void;
  onAssociationSearch(searchText: string): void;
  onSyncValidate(): void;
}

// eslint-disable-next-line max-lines-per-function
const SubjectFormBase: FC<Props> = ({
  onSubmit,
  onCancel,
  userProfileId,
  associationOptions,
  roleOptions,
  data,
  subjectFormType,
  onAssociationSearch,
  onSyncValidate
}) => {
  const dispatch = useDispatch();
  const {
    t,
    tCommon
  } = useOurTranslation('userDetail');
  const isEdit = !isNull(data);
  const isAssociation = useMemo(() => subjectFormType === 'ASSOCIATION', [subjectFormType]);
  const validate = useMemo(() => createValidate(isAssociation, isEdit), [isAssociation, isEdit]);
  const {
    Form,
    Fields,
    submitting,
    valid,
    pristine,
    asyncValidating,
    change
  } = useForm({
    form: subjectFormName,
    initialValues: subjectFormInitialValues,
    destroyOnUnmount: true,
    onSubmit,
    validate
  });
  const approvedToRef = useRef<HTMLInputElement>(null);
  const approveFrom = useSelector(formHelpers.formValues(subjectFormName)).prop('approvedFrom').orUndef();
  const approveFromMoment = useMemo(() => moment(approveFrom), [approveFrom]);
  const getRoleIds = useCallback((entry: SubjectFormEntryData) => isNull(entry.userProfileSubjectRoleId) ? entry.roles.map(role => role.roleId) : opt(entry.roleId).toArray(), []);
  useEffect(() => {
    if (isEdit) {
      const initFormData: NullableKeys<SubjectFormValues> = {
        subjectId: data.subjectId,
        roleIds: getRoleIds(data),
        userProfileId,
        approvedFrom: data.approvedFrom,
        approvedTo: data.approvedTo,
        userProfileAssociationRoleIds: isNull(data.userProfileSubjectRoleId) ? data.roles.map(role => role.userProfileSubjectRoleId) : [data.userProfileSubjectRoleId]
      };
      dispatch(formHelpers.initialize(subjectFormName, initFormData));
    } else {
      const initFormData: NullableKeys<SubjectFormValues> = {
        subjectId: null,
        roleIds: [],
        userProfileId,
        approvedFrom: todayDate(),
        approvedTo: null,
        userProfileAssociationRoleIds: null
      };
      dispatch(formHelpers.initialize(subjectFormName, initFormData));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRoleSearch = useCallback((searchText: string): void => {
    dispatch(userDetailAction.searchSubjectFormRoleOptions(searchText, subjectFormType));
  }, [dispatch, subjectFormType]);
  const handleApproveFromChange = useCallback((value: Opt<string>) => {
    onSyncValidate();
    value.onSome(date => {
      const [element] = document.getElementsByClassName(superUniqueClassName);
      element?.classList.remove('ant-picker-focused');
      setTimeout(() => approvedToRef.current?.focus());
      change('approvedFrom', date);
    });
  }, [onSyncValidate, approvedToRef, change]);
  return <Form data-sentry-element="Form" data-sentry-component="SubjectFormBase" data-sentry-source-file="index.tsx">
            <div className={styles.formContainer}>
                <div className={styles.doubleWidth}>
                    <Fields.NumberSelect name="subjectId" label={isAssociation ? t('association') : t('subject')} onSearch={onAssociationSearch} asyncSearch isRequired options={associationOptions} disabled={submitting || isEdit} onFieldChange={onSyncValidate} ignoreSearchMinLength searchOnMount={!isEdit} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div className={styles.doubleWidth}>
                    <Fields.NumberMultiSelect name="roleIds" label={t('roles')} onSearch={handleRoleSearch} asyncSearch isRequired options={roleOptions} disabled={submitting || isEdit} onFieldChange={onSyncValidate} ignoreSearchMinLength searchOnMount={!isEdit} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div>
                    <Fields.DateInput name="approvedFrom" label={t('approvedFrom')} onFieldChange={handleApproveFromChange} isRequired clearable dontIncludePast disabled={isEdit} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div>
                    <Fields.DateInput name="approvedTo" label={t('approvedTo')} onFieldChange={onSyncValidate} isRequired clearable dontIncludePast inputRef={approvedToRef} minDate={approveFromMoment} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
            </div>
            <FooterButtons submitting={submitting} valid={valid} pristine={pristine} onCancel={onCancel} asyncValidating={asyncValidating} submitTitle={isEdit ? tCommon('edit') : tCommon('add')} data-sentry-element="FooterButtons" data-sentry-source-file="index.tsx" />
        </Form>;
};
export const SubjectForm = memo(SubjectFormBase);