import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {renderCsvImortMessage} from 'utils/render-csv-import-message';
import {showBeError} from 'utils/show-be-error';

import {AddAnimalsFromCsvAction, matingAction} from '../action';
import {updateAnimalSessionAfterSelection} from './update-animal-session-saga-utils';

function* execute({payload: {sex, file, associationType}}: AddAnimalsFromCsvAction): SagaIterator {
    yield* put(matingAction.setLoading('loadingAnimalsFromFile', true));
    const response = yield* call(Api.importAnimalsInList, file, {sex, associationType});
    yield* put(matingAction.setLoading('loadingAnimalsFromFile', false));

    const title = t('mating/sagas')('addAnimalsFromFile');

    if (response.isSuccess) {
        const importResult = response.data;

        yield* put(showSuccess(title, renderCsvImortMessage(importResult), false, 'add-animals-from-csv'));
        yield* call(updateAnimalSessionAfterSelection, sex, associationType);
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* addAnimalsFromCsvSaga(): SagaIterator {
    yield takeLatestF('mating/ADD_ANIMALS_FROM_CSV', execute);
}
