import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {BasicRole} from 'api/gen/BasicRole';
import {PagedModelAssociationRole} from 'api/gen/PagedModelAssociationRole';
import {PagedModelBasicRole} from 'api/gen/PagedModelBasicRole';
import {PagedModelBreederRole} from 'api/gen/PagedModelBreederRole';
import {PagedModelOrganizationRole} from 'api/gen/PagedModelOrganizationRole';
import {PagedModelOtherSubjectRole} from 'api/gen/PagedModelOtherSubjectRole';
import {PagedModelPermissionDetail} from 'api/gen/PagedModelPermissionDetail';
import {PagedModelRolePermission} from 'api/gen/PagedModelRolePermission';
import {PagedModelRoleView} from 'api/gen/PagedModelRoleView';
import {PagedModelUserProfileContact} from 'api/gen/PagedModelUserProfileContact';
import {PagedModelUserSubjectView} from 'api/gen/PagedModelUserSubjectView';
import {PagedModelUserView} from 'api/gen/PagedModelUserView';
import {PermissionDetail} from 'api/gen/PermissionDetail';
import {RoleDetail} from 'api/gen/RoleDetail';
import {RolePermission} from 'api/gen/RolePermission';
import {RoleView} from 'api/gen/RoleView';
import {UserDetail} from 'api/gen/UserDetail';
import {AssociationRoleTableType} from 'app/user-detail/types/association-role-table-type';
import {BreederRoleTableType} from 'app/user-detail/types/breeder-role-table-type';
import {MemberOfSubjectsTableType} from 'app/user-detail/types/member-of-subjects-table-type';
import {OrganizationTableType} from 'app/user-detail/types/organization-table-type';
import {OtherSubjectRoleTableType} from 'app/user-detail/types/other-subject-role-table-type';
import {UserContactsTableType} from 'app/user-detail/types/user-contacts-table-type';

import {AppInfoType} from '../types';
import {AdminLoadingId} from '../types/admin-loading-id';
import {CreateOrEditMode} from '../types/permission-form-mode';
import {UsersTableType} from '../types/users-table-type';

export const adminAction = {
    getAppInfo: () => ({
        type: 'admin/GET_APP_INFO',
    }) as const,
    getAppInfoSuccess: (appInfo: AppInfoType) => ({
        type: 'admin/GET_APP_INFO_SUCCESS',
        payload: {appInfo},
    }) as const,
    createPermission: () => ({
        type: 'admin/CREATE_PERMISSION',
    }) as const,
    createRole: () => ({
        type: 'admin/CREATE_ROLE',
    }) as const,
    updatePermission: (permissionId: number) => ({
        type: 'admin/UPDATE_PERMISSION',
        payload: {permissionId},
    }) as const,
    updateRole: (roleId: number) => ({
        type: 'admin/UPDATE_ROLE',
        payload: {roleId},
    }) as const,
    updateUserMembership: () => ({
        type: 'admin/UPDATE_USER_MEMBERSHIP',
    }) as const,
    updateAssociationRoles: () => ({
        type: 'admin/UPDATE_ASSOCIATION_ROLES',
    }) as const,
    updateOtherSubjects: () => ({
        type: 'admin/UPDATE_OTHER_SUBJECTS',
    }) as const,
    createOtherSubjects: () => ({
        type: 'admin/CREATE_OTHER_SUBJECTS',
    }) as const,
    createAssociationRoles: () => ({
        type: 'admin/CREATE_ASSOCIATION_ROLES',
    }) as const,
    createOrganization: () => ({
        type: 'admin/CREATE_ORGANIZATION',
    }) as const,
    updateOrganization: () => ({
        type: 'admin/UPDATE_ORGANIZATION',
    }) as const,
    createUserMembership: () => ({
        type: 'admin/CREATE_USER_MEMBERSHIP',
    }) as const,
    getMemberOfSubjects: (
        tableDataRequest: Opt<TableDataRequest<MemberOfSubjectsTableType>>,
        userProfileId: number,
    ) => ({
        type: 'admin/GET_MEMBER_OF_SUBJECTS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getMemberOfSubjectsSuccess: (rolesPage: Opt<PagedModelUserSubjectView>) => ({
        type: 'admin/GET_MEMBER_OF_SUBJECTS_SUCCESS',
        payload: rolesPage,
    }) as const,
    getUserContacts: (
        tableDataRequest: Opt<TableDataRequest<UserContactsTableType>>,
        userProfileId: number,
    ) => ({
        type: 'admin/GET_USER_CONTACTS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getUserContactsSuccess: (rolesPage: Opt<PagedModelUserProfileContact>) => ({
        type: 'admin/GET_USER_CONTACTS_SUCCESS',
        payload: rolesPage,
    }) as const,
    validatePermission: (
        tableRecord: PermissionDetail | undefined,
        mode: CreateOrEditMode,
    ) => ({
        type: 'admin/VALIDATE_PERMISSION',
        payload: {tableRecord, mode},
    }) as const,
    validateRole: (tableRecord: RoleView | undefined, mode: CreateOrEditMode) => ({
        type: 'admin/VALIDATE_ROLE',
        payload: {tableRecord, mode},
    }) as const,
    getSubordinateAssignedRoles: (tableDataRequest: Opt<TableDataRequest<BasicRole>>, parentRoleId: number) => ({
        type: 'admin/GET_SUBORDINATE_ASSIGNED_ROLES',
        payload: {tableDataRequest, roleId: parentRoleId},
    }) as const,
    getSubordinateAssignedRolesSuccess: (rolesPage: Opt<PagedModelBasicRole>) => ({
        type: 'admin/GET_SUBORDINATE_ASSIGNED_ROLES_SUCCESS',
        payload: rolesPage,
    }) as const,
    getSubordinateUnassignedRoles: (tableDataRequest: Opt<TableDataRequest<BasicRole>>, parentRoleId: number) => ({
        type: 'admin/GET_SUBORDINATE_UNASSIGNED_ROLES',
        payload: {tableDataRequest, roleId: parentRoleId},
    }) as const,
    getSubordinateUnassignedRolesSuccess: (rolesPage: Opt<PagedModelBasicRole>) => ({
        type: 'admin/GET_SUBORDINATE_UNASSIGNED_ROLES_SUCCESS',
        payload: rolesPage,
    }) as const,
    getUsers: (tableDataRequest: Opt<TableDataRequest<UsersTableType>>) => ({
        type: 'admin/GET_USERS',
        payload: {tableDataRequest},
    }) as const,
    getUsersSuccess: (usersPage: Opt<PagedModelUserView>) => ({
        type: 'admin/GET_USERS_SUCCESS',
        payload: usersPage,
    }) as const,
    getRoles: (tableDataRequest: Opt<TableDataRequest<RoleView>>) => ({
        type: 'admin/GET_ROLES',
        payload: {tableDataRequest},
    }) as const,
    getRolesSuccess: (rolesPage: Opt<PagedModelRoleView>) => ({
        type: 'admin/GET_ROLES_SUCCESS',
        payload: rolesPage,
    }) as const,
    getAssociations: (tableDataRequest: Opt<TableDataRequest<AssociationRoleTableType>>, userProfileId: number) => ({
        type: 'admin/GET_USER_ASSOCIATIONS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getAssociationsSuccess: (associationsPage: Opt<PagedModelAssociationRole>) => ({
        type: 'admin/GET_USER_ASSOCIATIONS_SUCCESS',
        payload: associationsPage,
    }) as const,
    getOrganizations: (
        tableDataRequest: Opt<TableDataRequest<OrganizationTableType>>,
        userProfileId: number,
    ) => ({
        type: 'admin/GET_ORGANIZATIONS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getOrganizationsSuccess: (organizationsPage: Opt<PagedModelOrganizationRole>) => ({
        type: 'admin/GET_ORGANIZATIONS_SUCCESS',
        payload: organizationsPage,
    }) as const,
    getUserBreeders: (
        tableDataRequest: Opt<TableDataRequest<BreederRoleTableType>>,
        userProfileId: number,
    ) => ({
        type: 'admin/GET_USER_BREEDERS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getUserBreedersSuccess: (authorizedAssociationsPage: Opt<PagedModelBreederRole>) => ({
        type: 'admin/GET_USER_BREEDERS_SUCCESS',
        payload: authorizedAssociationsPage,
    }) as const,
    getOtherSubjectRoles: (request: Opt<TableDataRequest<OtherSubjectRoleTableType>>, userProfileId: number) => ({
        type: 'admin/GET_USER_OTHER_SUBJECT_ROLES',
        payload: {request, userProfileId},
    }) as const,
    getOtherSubjectRolesSuccess: (otherSubjectRolesPage: Opt<PagedModelOtherSubjectRole>) => ({
        type: 'admin/GET_USER_OTHER_SUBJECT_ROLES_SUCCESS',
        payload: otherSubjectRolesPage,
    }) as const,
    getRole: (roleId: number) => ({
        type: 'admin/GET_ROLE',
        payload: {roleId},
    }) as const,
    getRoleSuccess: (role: RoleDetail) => ({
        type: 'admin/GET_ROLE_SUCCESS',
        payload: role,
    }) as const,
    clearRole: () => ({
        type: 'admin/CLEAR_ROLE',
    }) as const,
    getUser: (userId: number) => ({
        type: 'admin/GET_USER',
        payload: {userId},
    }) as const,
    clearUser: () => ({
        type: 'admin/CLEAR_USER',
    }) as const,
    getUserSuccess: (user: UserDetail) => ({
        type: 'admin/GET_USER_SUCCESS',
        payload: user,
    }) as const,
    getRolePermissions: (roleId: number, tableDataRequest: Opt<TableDataRequest<RolePermission>>) => ({
        type: 'admin/GET_ROLE_PERMISSIONS',
        payload: {roleId, tableDataRequest},
    }) as const,
    getRolePermissionsSuccess: (rolePermissionsPage: Opt<PagedModelRolePermission>) => ({
        type: 'admin/GET_ROLE_PERMISSIONS_SUCCESS',
        payload: rolePermissionsPage,
    }) as const,
    getRoleUnassignedPermissions: (roleId: number, tableDataRequest: Opt<TableDataRequest<RolePermission>>) => ({
        type: 'admin/GET_ROLE_UNASSIGNED_PERMISSIONS',
        payload: {roleId, tableDataRequest},
    }) as const,
    getRoleUnassignedPermissionsSuccess: (roleUnassignedPermissionsPage: Opt<PagedModelRolePermission>) => ({
        type: 'admin/GET_ROLE_UNASSIGNED_PERMISSIONS_SUCCESS',
        payload: roleUnassignedPermissionsPage,
    }) as const,
    changeSubordinateRole: (parentRoleId: number, subordinateRoleId: number, method: 'add' | 'remove') => ({
        type: 'admin/CHANGE_SUBORDINATE_ROLE',
        payload: {parentRoleId, subordinateRoleId, method},
    }) as const,
    removePermissionFromRole: (roleId: number, permissionId: number) => ({
        type: 'admin/REMOVE_PERMISSION_FROM_ROLE',
        payload: {roleId, permissionId},
    }) as const,
    addPermissionToRole: (roleId: number, permissionId: number) => ({
        type: 'admin/ADD_PERMISSION_TO_ROLE',
        payload: {roleId, permissionId},
    }) as const,
    getPermissionDetails: (tableDataRequest: Opt<TableDataRequest<PermissionDetail>>) => ({
        type: 'admin/GET_PERMISSION_DETAILS',
        payload: {tableDataRequest},
    }) as const,
    getPermissionDetailsSuccess: (permissionsPage: Opt<PagedModelPermissionDetail>) => ({
        type: 'admin/GET_PERMISSION_DETAILS_SUCCESS',
        payload: permissionsPage,
    }) as const,
    deletePermission: (permissionId: number) => ({
        type: 'admin/DELETE_PERMISSION',
        payload: {permissionId},
    }) as const,
    deleteRole: (roleId: number) => ({
        type: 'admin/DELETE_ROLE',
        payload: {roleId},
    }) as const,
    setEditedPermissionId: (permissionId: number | null) => ({
        type: 'admin/SET_EDITED_PERMISSION_ID',
        payload: permissionId,
    }) as const,
    setEditedRoleId: (permissionId: number | null) => ({
        type: 'admin/SET_EDITED_ROLE_ID',
        payload: permissionId,
    }) as const,
    setPermissionCreateVisibility: (visible: boolean) => ({
        type: 'admin/SET_PERMISSION_CREATE_VISIBILITY',
        payload: visible,
    }) as const,
    setRoleCreateModalVisibility: (visible: boolean) => ({
        type: 'admin/SET_ROLE_CREATE_VISIBILITY',
        payload: visible,
    }) as const,
    deleteUserContact: (userProfileId: number, userContactId: number) => ({
        type: 'admin/DELETE_USER_CONTACT',
        payload: {userProfileId, userContactId},
    }) as const,
    updateUserContact: () => ({
        type: 'admin/UPDATE_USER_CONTACT',
    }) as const,
    createUserContact: () => ({
        type: 'admin/CREATE_USER_CONTACT',
    }) as const,
    updateBreeder: () => ({
        type: 'admin/UPDATE_BREEDER',
    }) as const,
    createBreeder: () => ({
        type: 'admin/CREATE_BREEDER',
    }) as const,
    setLoading: (id: AdminLoadingId, loading: boolean) => ({
        type: 'admin/SET_LOADING',
        payload: {id, loading},
    }) as const,
};

export type GetAppInfoAction = ReturnType<typeof adminAction.getAppInfo>;
export type GetUserContactsAction = ReturnType<typeof adminAction.getUserContacts>;
export type UpdateBreederAction = ReturnType<typeof adminAction.updateBreeder>;
export type CreateBreederAction = ReturnType<typeof adminAction.createBreeder>;
export type UpdateOtherSubjectsAction = ReturnType<typeof adminAction.updateOtherSubjects>;
export type CreateOrganizationAction = ReturnType<typeof adminAction.createOrganization>;
export type UpdateOrganizationAction = ReturnType<typeof adminAction.updateOrganization>;
export type CreateOtherSubjectsAction = ReturnType<typeof adminAction.createOtherSubjects>;
export type DeleteUserContactAction = ReturnType<typeof adminAction.deleteUserContact>;
export type UpdateUserContactAction = ReturnType<typeof adminAction.updateUserContact>;
export type CreateUserContactAction = ReturnType<typeof adminAction.createUserContact>;
export type CreateAssociationRolesAction = ReturnType<typeof adminAction.createAssociationRoles>;
export type UpdateAssociationRolesAction = ReturnType<typeof adminAction.updateAssociationRoles>;
export type GetUserContactsSuccessAction = ReturnType<typeof adminAction.getUserContactsSuccess>;
export type GetAssociationsAction = ReturnType<typeof adminAction.getAssociations>;
export type GetAssociationsSuccessAction = ReturnType<typeof adminAction.getAssociationsSuccess>;
export type GetOtherSubjectRolesAction = ReturnType<typeof adminAction.getOtherSubjectRoles>;
export type GetOtherSubjectRolesSuccessAction = ReturnType<typeof adminAction.getOtherSubjectRolesSuccess>;
export type GetUserAction = ReturnType<typeof adminAction.getUser>;
export type GetUserSuccessAction = ReturnType<typeof adminAction.getUserSuccess>;
export type ClearUserAction = ReturnType<typeof adminAction.clearUser>;
export type GetMemberOfSubjectsAction = ReturnType<typeof adminAction.getMemberOfSubjects>;
export type GetMemberOfSubjectsSuccessAction = ReturnType<typeof adminAction.getMemberOfSubjectsSuccess>;
export type CreateUserMembershipAction = ReturnType<typeof adminAction.createUserMembership>;
export type UpdateUserMembershipAction = ReturnType<typeof adminAction.updateUserMembership>;
export type GetUsersAction = ReturnType<typeof adminAction.getUsers>;
export type GetUsersSuccessAction = ReturnType<typeof adminAction.getUsersSuccess>;
export type GetAppInfoSuccessAction = ReturnType<typeof adminAction.getAppInfoSuccess>;
export type UpdatePermissionAction = ReturnType<typeof adminAction.updatePermission>;
export type ValidateRoleAction = ReturnType<typeof adminAction.validateRole>;
export type ChangeSubordinateRoleAction = ReturnType<typeof adminAction.changeSubordinateRole>;
export type DeletePermissionAction = ReturnType<typeof adminAction.deletePermission>;
export type UpdateRoleAction = ReturnType<typeof adminAction.updateRole>;
export type ValidatePermissionAction = ReturnType<typeof adminAction.validatePermission>;
export type GetRolesAction = ReturnType<typeof adminAction.getRoles>;
export type GetRolesSuccessAction = ReturnType<typeof adminAction.getRolesSuccess>;
export type DeleteRoleAction = ReturnType<typeof adminAction.deleteRole>;
export type GetSubordinateAssignedRolesAction = ReturnType<typeof adminAction.getSubordinateAssignedRoles>;
export type GetSubordinateAssignedRolesSuccessAction =
    ReturnType<typeof adminAction.getSubordinateAssignedRolesSuccess>;
export type GetSubordinateUnassignedRolesAction = ReturnType<typeof adminAction.getSubordinateUnassignedRoles>;
export type GetSubordinateUnassignedRolesSuccessAction =
    ReturnType<typeof adminAction.getSubordinateUnassignedRolesSuccess>;
export type GetRoleAction = ReturnType<typeof adminAction.getRole>;
export type GetRoleSuccessAction = ReturnType<typeof adminAction.getRoleSuccess>;
export type ClearRoleAction = ReturnType<typeof adminAction.clearRole>;
export type GetRolePermissionsAction = ReturnType<typeof adminAction.getRolePermissions>;
export type GetRolePermissionsSuccessAction = ReturnType<typeof adminAction.getRolePermissionsSuccess>;
export type GetRoleUnassignedPermissionsAction = ReturnType<typeof adminAction.getRoleUnassignedPermissions>;
export type GetRoleUnassignedPermissionsSuccessAction =
    ReturnType<typeof adminAction.getRoleUnassignedPermissionsSuccess>;
export type RemovePermissionFromRoleAction = ReturnType<typeof adminAction.removePermissionFromRole>;
export type AddPermissionToRoleAction = ReturnType<typeof adminAction.addPermissionToRole>;
export type GetPermissionDetailsAction = ReturnType<typeof adminAction.getPermissionDetails>;
export type GetPermissionDetailsSuccessAction = ReturnType<typeof adminAction.getPermissionDetailsSuccess>;
export type SetEditedPermissionIdAction = ReturnType<typeof adminAction.setEditedPermissionId>;
export type SetPermissionCreateVisibilityAction = ReturnType<typeof adminAction.setPermissionCreateVisibility>;
export type CreatePermissionAction = ReturnType<typeof adminAction.createPermission>;
export type CreateRoleAction = ReturnType<typeof adminAction.createRole>;
export type SetRoleCreateModalVisibilityAction = ReturnType<typeof adminAction.setRoleCreateModalVisibility>;
export type SetEditedRoleIdAction = ReturnType<typeof adminAction.setEditedRoleId>;
export type GetOrganizationsAction = ReturnType<typeof adminAction.getOrganizations>;
export type GetOrganizationsActionSuccess = ReturnType<typeof adminAction.getOrganizationsSuccess>;
export type GetUserBreedersAction = ReturnType<typeof adminAction.getUserBreeders>;
export type GetUserBreedersActionSuccess = ReturnType<typeof adminAction.getUserBreedersSuccess>;
export type SetLoadingAction = ReturnType<typeof adminAction.setLoading>;

export type AdminAction =
    | GetAppInfoAction
    | CreateBreederAction
    | UpdateBreederAction
    | CreateOrganizationAction
    | UpdateOrganizationAction
    | GetOrganizationsAction
    | GetOrganizationsActionSuccess
    | UpdateOtherSubjectsAction
    | CreateOtherSubjectsAction
    | UpdateAssociationRolesAction
    | CreateAssociationRolesAction
    | GetMemberOfSubjectsAction
    | CreateUserContactAction
    | GetAssociationsAction
    | GetAssociationsSuccessAction
    | GetOtherSubjectRolesAction
    | GetOtherSubjectRolesSuccessAction
    | DeleteUserContactAction
    | UpdateUserContactAction
    | GetUserContactsAction
    | GetUserContactsSuccessAction
    | GetMemberOfSubjectsSuccessAction
    | UpdateUserMembershipAction
    | GetUsersAction
    | GetUserAction
    | GetUserSuccessAction
    | GetUsersSuccessAction
    | GetAppInfoSuccessAction
    | SetEditedRoleIdAction
    | CreatePermissionAction
    | SetRoleCreateModalVisibilityAction
    | UpdateRoleAction
    | UpdatePermissionAction
    | GetSubordinateAssignedRolesAction
    | GetSubordinateAssignedRolesSuccessAction
    | GetSubordinateUnassignedRolesAction
    | GetSubordinateUnassignedRolesSuccessAction
    | ChangeSubordinateRoleAction
    | DeletePermissionAction
    | SetEditedPermissionIdAction
    | SetPermissionCreateVisibilityAction
    | ValidatePermissionAction
    | GetRoleSuccessAction
    | GetRolesAction
    | GetRolesSuccessAction
    | GetRolePermissionsAction
    | GetRolePermissionsSuccessAction
    | DeleteRoleAction
    | GetRoleAction
    | GetRoleUnassignedPermissionsAction
    | GetRoleUnassignedPermissionsSuccessAction
    | ValidateRoleAction
    | RemovePermissionFromRoleAction
    | AddPermissionToRoleAction
    | GetPermissionDetailsAction
    | CreateRoleAction
    | GetPermissionDetailsSuccessAction
    | GetUserBreedersAction
    | GetUserBreedersActionSuccess
    | ClearUserAction
    | ClearRoleAction
    | SetLoadingAction
    ;
