import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {AddAnimalsAction, matingAction} from '../action';
import {updateAnimalSessionAfterSelection} from './update-animal-session-saga-utils';

const te = t('mating/sagas');

function* execute({payload: {sex, animalIds, associationType}}: AddAnimalsAction): SagaIterator {
    yield* put(formHelpers.startSubmit('addAnimals'));

    const response = yield* call(Api.addAnimalsToList, {animalIds}, {sex, associationType});

    const title = te('addAnimals');

    if (response.isSuccess) {
        yield* put(showSuccess(title, te('animalsAdded'), false, 'add-animals'));
        yield* put(matingAction.toggleAddAnimalsVisibility(sex));
        yield* put(formHelpers.stopSubmit('addAnimals', {}));
        yield* call(updateAnimalSessionAfterSelection, sex, associationType);
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit('addAnimals', extractFormErrorsFromResponse(response)));
    }
}

export function* addAnimalsSaga(): SagaIterator {
    yield takeLatestF('mating/ADD_ANIMALS', execute);
}
