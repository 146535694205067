export const analysisProtocolCorrectionTranslations = {
    CS: {
        'apc/apc': {
            pageTitle: 'Přehled hlášení opravy rozborového protokolu',
            title: 'Přehled hlášení opravy rozborového protokolu',
        },
        'apc/createdReportsTable': {
            correctionType: 'Typ opravy',
            technicianNumber: 'Číslo technika',
            earTag: 'Ušní známka',
            stableNumber: 'Číslo stáje',
            proficiencyTestDate: 'Datum KU',
            updatedEarTag: 'Ušní známka ‐ změna',
            milkingDayTime: 'Denní doba',
            milkingInterval: 'Interval',
            milkingCount: 'Počet dojení',
            milkKg: 'Mléko (kg)',
            fatPercentage: 'Tuk (%)',
            proteinPercentage: 'Bílkovina (%)',
            lactosePercentage: 'Laktóza (%)',
            somaticCells: 'SB (tis./ml)',
            urea: 'Močovina (mg/100ml)',
            noApcReports: 'Nebyla nalezena žádná hlášení oprav.',
            rewriteApcReportFormData: 'Touto akcí smažete vyplněná data ve formuláři. Opravdu chcete provést tuto akci?',
        },
        'apc/overviewTable': {
            noCorrectionReports: 'Nebyla nalezena žádná hlášení oprav rozborových protokolů.',
            correctionTypes: {
                DISCARD: 'Zrušení',
                UPDATE: 'Dohlášení / Změna',
                EAR_TAG_UPDATE: 'Změna UZ',
            },
            newReport: 'Hlášení',
            deleteDisabledTitle: 'Odebírání záznamů hlášení opravy rozborového je možné provést pouze u záznamů, které ještě nebyly exportovány do COBOLu.',
            editDisabledTitle: 'Editaci záznamů hlášení opravy rozborového je možné provést pouze u záznamů, které ještě nebyly exportovány do COBOLu.',
        },
        'apc/sagas': {
            deleteCorrectionReport: 'Odstranění hlášení opravy rozborového protokolu',
            reportDeletedSuccessfully: 'Hlášení opravy rozborového protokolu bylo úspěšně odstraněno',
            stableDateRelations: 'Načtení stájí a dat KU',
            technicianNumber: 'Načtení techniků',
            analysisProtocol: 'Načtení rozborového protokolu',
        },
        'apc/new': {
            title: 'Hlášení opravy rozborového protokolu',
            correctionType: 'Typ opravy',
            technicianNumber: 'Číslo technika',
            earTag: 'Ušní známka',
            stableNumber: 'Číslo stáje',
            proficiencyTestDate: 'Datum KU',
            earTagChange: 'Ušní známka ‐ změna',
            milkingTripleAttribute: 'DD ‐ Interval ‐ Počet dojení',
            milkKg: 'Mléko (kg)',
            milkKgUnit: 'kg',
            fatPercentage: 'Tuk (%)',
            proteinPercentage: 'Bílkovina (%)',
            lactosePercentage: 'Laktóza (%)',
            somaticCells: 'SB (tis./ml)',
            somaticCellsUnit: 'tis./ml',
            urea: 'Močovina (mg/100ml)',
            ureaUnit: 'mg/100ml',
            discard: 'Zrušení',
            update: 'Dohlášení / Změna',
            earTagUpdate: 'Změna UZ',
        },
    },
    EN: {
        'apc/apc': {
            pageTitle: 'Analysis protocol correction report overview',
            title: 'Analysis protocol correction report overview',
        },
        'apc/createdReportsTable': {
            correctionType: 'Correction type',
            stableNumber: 'Stable number',
            proficiencyTestDate: 'PT Date',
            technicianNumber: 'Technician number',
            earTag: 'Ear tag',
            updatedEarTag: 'Ear tag - change',
            milkingDayTime: 'Day time',
            milkingInterval: 'Interval',
            milkingCount: 'Milking count',
            milkKg: 'Milk (kg)',
            fatPercentage: 'Fat (%)',
            proteinPercentage: 'Protein (%)',
            lactosePercentage: 'Lactose (%)',
            somaticCells: 'SC (1000/ml)',
            urea: 'Urea (mg/100ml)',
            noApcReports: 'No correction reports found.',
            rewriteApcReportFormData: 'This action will delete the entered data in the form. Are you sure you want to perform this action?',
        },
        'apc/overviewTable': {
            noCorrectionReports: 'No correction reports found.',
            correctionTypes: {
                DISCARD: 'Discard',
                UPDATE: 'Update',
                EAR_TAG_UPDATE: 'Ear tag update',
            },
            newReport: 'Report',
            deleteDisabledTitle: 'Removal of correction report records is only possible for records that have not yet been exported to COBOL.',
            editDisabledTitle: 'Editing of correction report records is only possible for records that have not yet been exported to COBOL.',
        },
        'apc/sagas': {
            deleteCorrectionReport: 'Deleting of correction report',
            reportDeletedSuccessfully: 'Correction report was successfully deleted',
            stableDateRelations: 'Load stables and proficiency test dates',
            technicianNumber: 'Load technicians',
            analysisProtocol: 'Load analysis protocol',
        },
        'apc/new': {
            title: 'Analysis protocol correction report',
            correctionType: 'Correction type',
            technicianNumber: 'Technician number',
            earTag: 'Ear tag',
            stableNumber: 'Stable number',
            proficiencyTestDate: 'PT Date',
            earTagChange: 'Ear tag - change',
            milkingTripleAttribute: 'DD - Interval - Milking count',
            milkKg: 'Milk (kg)',
            milkKgUnit: 'kg',
            fatPercentage: 'Fat (%)',
            proteinPercentage: 'Protein (%)',
            lactosePercentage: 'Lactose (%)',
            somaticCells: 'SC (1000/ml)',
            somaticCellsUnit: '1000/ml',
            urea: 'Urea (mg/100ml)',
            ureaUnit: 'mg/100ml',
            discard: 'Discard',
            update: 'Update',
            earTagUpdate: 'ET Update',
        },
    },
};
