import { Loader, TableDataRequest } from '@fl/cmsch-fe-library';
import { toString } from 'lodash/fp';
import React, { FC, useCallback, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import * as routerActions from 'redux-first-history';
import { Opt } from 'ts-opt';
import { Calf } from 'api/gen/Calf';
import { AnimalBrowserAction } from 'app/animal-browser/model/action';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { BackButton } from 'common/buttons';
import { Card, ScreenHeader } from 'common/layout';
import { replaceSpacesWithUnbreakableSpaces } from 'utils/replace-spaces-with-unbreakable-spaces';
import { CalfBrowserTable } from '../components/calf-browser';
import { animalBrowserAction, simpleAnimalBrowserSelector } from '../model';
interface DispatchProps {
  getCalves(tableDataRequest: Opt<TableDataRequest<Calf>>): void;
  goTo(path: string): void;
  clearFarmName(): void;
}
type Props = DispatchProps;
const CalfBrowserBase: FC<Props> = props => {
  const farmInfo = useSelector(simpleAnimalBrowserSelector.farmInfo);
  const exportInProgress = useSelector(simpleAnimalBrowserSelector.exportInProgress);
  const {
    t,
    tCommon
  } = useOurTranslation('calfBrowserTable');
  const {
    t: pageTitle
  } = useOurTranslation('stableTable');
  const farmNameOpt = farmInfo.prop('code').map(toString).noneIfEmpty();
  const {
    getCalves,
    goTo,
    clearFarmName
  } = props;
  const cardTitle = useMemo(() => farmNameOpt.map(farmName => `${t('tableName')}: ${replaceSpacesWithUnbreakableSpaces(farmName)}`).caseOf(title => <ScreenHeader title={title} />, () => <Loader show />), [farmNameOpt, t]);
  const goBackToList = useCallback(() => {
    clearFarmName();
    goTo('/animal-browser/stables');
  }, [goTo, clearFarmName]);
  return <Content browserTabTitle={pageTitle('pageTitle')} data-sentry-element="Content" data-sentry-component="CalfBrowserBase" data-sentry-source-file="calf-browser.tsx">
            <Card title={cardTitle} titleSize="big" extra={<BackButton text={tCommon('backToList')} onClick={goBackToList} />} noPadding data-sentry-element="Card" data-sentry-source-file="calf-browser.tsx">
                <CalfBrowserTable exportInProgress={exportInProgress} getCalves={getCalves} data-sentry-element="CalfBrowserTable" data-sentry-source-file="calf-browser.tsx" />
            </Card>
        </Content>;
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getCalves: (tableDataRequest: Opt<TableDataRequest<Calf>>): AnimalBrowserAction => dispatch(animalBrowserAction.getCalfBrowserTableData(tableDataRequest)),
  goTo: (path: string): void => void dispatch(routerActions.push(path)),
  clearFarmName: (): AnimalBrowserAction => dispatch(animalBrowserAction.clearFarmName())
});
export const CalfBrowser = connect(null, mapDispatchToProps)(CalfBrowserBase);