import {CustomWindow, ReduxDevtoolsExtensionOptions, sentryReduxEnhancer} from '@fl/cmsch-fe-library';
import {createBrowserHistory} from 'history';
import {AnyAction, applyMiddleware, compose, legacy_createStore} from 'redux';
import {createReduxHistoryContext} from 'redux-first-history';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createSagaMiddleware from 'redux-saga';
import {opt, ReduxDevtoolsCompatibilityHelper} from 'ts-opt';

import {Config} from 'app/config';

import {defaultState} from './default-state';
import {windowMiddleware} from './middleware/window-middleware';
import {createReducer} from './reducer';
import saga from './saga';
import {State} from './state';

const reduxDevtoolsOptions: ReduxDevtoolsExtensionOptions = {
    serialize: {
        replacer: ReduxDevtoolsCompatibilityHelper.replacer,
        reviver: ReduxDevtoolsCompatibilityHelper.reviver,
    },
    actionsBlacklist: ['@@redux-form/REGISTER_FIELD', '@@redux-form/UNREGISTER_FIELD'],
};

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = opt((window as CustomWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    .map(f => f(reduxDevtoolsOptions))
    .orElse(compose);

const sagaMiddleware = createSagaMiddleware();
const reduxHistoryContext = createReduxHistoryContext({history: createBrowserHistory()});
const {createReduxHistory, routerMiddleware, routerReducer} = reduxHistoryContext;
const middlewares = [sagaMiddleware, routerMiddleware, windowMiddleware];

if (Config.isDevelopment && !Config.disableImmutabilityCheck) {
    middlewares.push(reduxImmutableStateInvariant());
}

const reducer = createReducer(routerReducer);
const enhancer = composeEnhancers(compose(applyMiddleware(...middlewares), sentryReduxEnhancer));
const store = legacy_createStore<State, AnyAction, unknown, unknown>(
    reducer,
    defaultState,
    enhancer,
);

const history = createReduxHistory(store);

sagaMiddleware.run(saga);

export {history, store};
