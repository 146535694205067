import {TableColumn} from '@fl/cmsch-fe-library';

import {RoleView} from 'api/gen/RoleView';

import {adminDiscriminatorOptions} from '../../constants/options';

export const rolesColumns: Array<TableColumn<RoleView>> = [
    {
        field: 'code',
        type: 'string',
    },
    {
        field: 'discriminator',
        type: 'option',
        options: adminDiscriminatorOptions,
    },
    {
        field: 'name',
        type: 'string',
    },
    {
        field: 'permissionCount',
        type: 'number',
    },
    {
        field: 'userCount',
        type: 'number',
    },
    {
        field: 'userEnable',
        type: 'boolean',
    },
];
