import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put, select} from 'typed-redux-saga';

import {animalBrowserAction} from '../action';
import {simpleAnimalBrowserSelector} from '../selector';

export function* updateCabCalvesSessionAfterFilter(
    isFull: boolean,
    firstTime?: 'FIRST_LOAD',
): SagaIterator {
    const previousCountEmpty = (yield* select(simpleAnimalBrowserSelector.isCabCalvesSessionEmpty));
    if (firstTime) {
        yield* put(animalBrowserAction.firstCalveLoad(firstTime));
    } else if (isFull && !previousCountEmpty) {
        yield* put(animalBrowserAction.getCabCalves(opt({action: 'reset'})));
    }
    yield* put(animalBrowserAction.setCabCalvesExistInSession(isFull));
}
