import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call as callRaw} from 'redux-saga/effects';
import {all, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';
import {showBeError} from 'utils/show-be-error';

import {adminAction, GetAppInfoAction} from '../action';

function* execute(_: GetAppInfoAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('appInfo', true));

    const {legacyAppInfoResponse, appInfoResponse} = yield* all({
        legacyAppInfoResponse: callRaw(Api.getLegacyAppInfo),
        appInfoResponse: callRaw(Api.getApplicationInfo),
    });

    if (legacyAppInfoResponse.isSuccess && appInfoResponse.isSuccess) {
        yield* put(adminAction.getAppInfoSuccess({
            legacyBeAppInfo: legacyAppInfoResponse.data,
            beAppInfo: appInfoResponse.data,
        }));
    } else {
        yield putAll(showBeError(appInfoResponse, t('admin/sagas')('appInfo')));
    }

    yield* put(layoutAction.setItemLoading('appInfo', false));
}

export function* appInfoSaga(): SagaIterator {
    yield takeLatestF('admin/GET_APP_INFO', execute);
}
