import React, { memo, FC } from 'react';
import { Opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { useOurTranslation } from 'app/translations';
import { Modal } from 'common/layout/components/modal';
import { CreateAnimalListForm } from './form';
interface Props {
  visible: boolean;
  isAnimalsListEmpty: boolean;
  animalListEmptyMessage: string;
  animalLists: Opt<Array<AnimalList>>;
  onSubmit(name: string): void;
  onCancel(): void;
}
const ModalListNameBase: FC<Props> = props => {
  const {
    visible,
    isAnimalsListEmpty,
    animalListEmptyMessage,
    animalLists,
    onSubmit,
    onCancel
  } = props;
  const {
    t
  } = useOurTranslation('animalListModals');
  return <Modal title={t('enterListName')} visible={visible} onCancel={onCancel} footer={null} data-sentry-element="Modal" data-sentry-component="ModalListNameBase" data-sentry-source-file="index.tsx">
            <CreateAnimalListForm isAnimalsListEmpty={isAnimalsListEmpty} animalListEmptyMessage={animalListEmptyMessage} animalLists={animalLists} onCancel={onCancel} onSubmit={onSubmit} data-sentry-element="CreateAnimalListForm" data-sentry-source-file="index.tsx" />
        </Modal>;
};
export const ModalListName = memo(ModalListNameBase);