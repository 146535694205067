import {RefSelect, Options} from '@fl/cmsch-fe-library';
import {RefObject, useCallback} from 'react';
import {opt, Opt, testRe, uncurryTuple} from 'ts-opt';

import {ApcStableDate} from 'api/gen/ApcStableDate';
import {CorrectionType} from 'api/gen/CorrectionType';
import {Date} from 'api/gen/Date';
import {EarTag, earTagRegexGen} from 'api/gen/EarTag';
import {StableCode} from 'api/gen/StableCode';

import {
    genProtocolDateOptionsBasedOnStable,
    genProtocolStableOptionsBasedOnDate,
    genProtocolStableOptionsBasedOnStable,
    genNonProtocolDateOptionsBasedOnStable,
    getDateOptions,
} from '../utils/get-apc-options';
import {isApcDateListWithStables, isApcStableListWithDates} from '../utils/is-apc-stable-date';

interface DateAndStableChangeCallbacks {
    onStableNumberChange(stableName: Opt<StableCode>): void;
    onProficiencyTestDateChange(val: Opt<Date>): void;
}

interface Props {
    stableDateRelations: Opt<ApcStableDate>;
    correctionType: CorrectionType | undefined;
    earTagChangeRef: RefObject<HTMLInputElement>;
    milkKgRef: RefObject<HTMLInputElement>;
    stableNumberRef: RefSelect;
    proficiencyTestDateRef: RefSelect;
    stableNumber: StableCode | null;
    earTagValue: EarTag | null;
    isUpdateCorrection: boolean;
    submitRef: RefObject<HTMLElement>;
    getAnalysisProtocol(earTag: EarTag, proficiencyTestDate: Date): void;
    getStableDateRelation(stableCode: StableCode, proficiencyTestDate: Date): void;
    setProficiencyTestDateOptions(options: Options<string>): void;
    change(field: string, value: unknown): void;
    setStableOptions(options: Options<string>): void;
    clearAdditionalDataInProtocol(changedField: 'proficiencyTestDate' | 'stableNumber', value: string | null): void;
}

const protocolExists = (stableDateRelations: Opt<ApcStableDate>): boolean =>
    stableDateRelations.exists(r => r.tag === 'ApcDateListWithStables');

// eslint-disable-next-line max-lines-per-function
export const useDateAndStableChange = ({
    stableDateRelations,
    earTagValue,
    stableNumber,
    getStableDateRelation,
    getAnalysisProtocol,
    setProficiencyTestDateOptions,
    change,
    correctionType,
    milkKgRef,
    proficiencyTestDateRef,
    submitRef,
    stableNumberRef,
    earTagChangeRef,
    setStableOptions,
    isUpdateCorrection,
    clearAdditionalDataInProtocol,
}: Props): DateAndStableChangeCallbacks => {
    // eslint-disable-next-line max-lines-per-function
    const onProficiencyTestDateChange = useCallback((dateValue: Opt<Date>) => {
        clearAdditionalDataInProtocol('proficiencyTestDate', dateValue.orNull());
        if (dateValue.isFull) {
            if (!protocolExists(stableDateRelations) && stableNumber) {
            // protocol does not exist, value is not empty, implies correctionType === 'UPDATE'
                getStableDateRelation(stableNumber, dateValue.orElse(''));
                setTimeout(() => milkKgRef.current?.focus());
            } else {
            // protocol exists
                if (!isUpdateCorrection) {
                    stableDateRelations.narrow(isApcDateListWithStables)
                        .zip(dateValue)
                        .map(uncurryTuple(genProtocolStableOptionsBasedOnDate))
                        .onSome(options => {
                            setStableOptions(options);
                            if (options.length === 1) {
                                change('stableNumber', options[0]?.value);
                                if (correctionType === 'EAR_TAG_UPDATE') {
                                    setTimeout(() => earTagChangeRef.current?.focus());
                                }
                            } else {
                                setTimeout(() => stableNumberRef.current?.focus());
                            }
                        });
                    if (earTagValue && testRe(earTagRegexGen())(earTagValue)) {
                        getAnalysisProtocol(earTagValue, dateValue.orElse(''));
                    }
                } else {
                    // protocol exists and correctionType === 'UPDATE'
                    if (stableNumber) {
                        stableDateRelations.narrow(isApcDateListWithStables).onSome(relations => {
                            const equalDateDataInProtocol = relations.dateData?.filter(
                                date => date.proficiencyTestDate === dateValue.orElse(''),
                            );
                            const isStableInProtocolDateData = equalDateDataInProtocol?.some(data => data.stables?.some(
                                stable => stable.code === stableNumber),
                            );
                            if (isStableInProtocolDateData) {
                                getAnalysisProtocol(earTagValue || '', dateValue.orElse(''));
                            } else {
                                getStableDateRelation(stableNumber, dateValue.orElse(''));
                            }
                        });
                    } else {
                        getAnalysisProtocol(earTagValue || '', dateValue.orElse(''));
                    }
                    stableDateRelations.narrow(isApcDateListWithStables)
                        .zip(dateValue)
                        .map(uncurryTuple(genProtocolStableOptionsBasedOnDate))
                        .filter(xs => xs.length === 1)
                        .onSome(options => change('stableNumber', options[0]?.value));
                    setTimeout(() => milkKgRef.current?.focus());
                }
            }
        } else {
            if (isUpdateCorrection && stableNumber) {
                stableDateRelations.narrow(isApcDateListWithStables)
                    .zip(opt(stableNumber))
                    .map(uncurryTuple(genProtocolDateOptionsBasedOnStable))
                    .onSome(setProficiencyTestDateOptions);
            }
        }
    }, [
        earTagValue,
        getStableDateRelation,
        isUpdateCorrection,
        milkKgRef,
        change,
        correctionType,
        earTagChangeRef,
        stableNumberRef,
        getAnalysisProtocol,
        setStableOptions,
        stableDateRelations,
        stableNumber,
        clearAdditionalDataInProtocol,
        setProficiencyTestDateOptions,
    ]);
    // eslint-disable-next-line max-lines-per-function
    const onStableNumberChange = useCallback((stableName: Opt<StableCode>) => {
        if (isUpdateCorrection) {
            clearAdditionalDataInProtocol('stableNumber', stableName.orNull());
            change('proficiencyTestDate', null);
            stableDateRelations.narrow(isApcStableListWithDates)
                .zip(stableName)
                .map(uncurryTuple(genNonProtocolDateOptionsBasedOnStable))
                .onSome(setProficiencyTestDateOptions);
            if (stableName.isFull) setTimeout(() => proficiencyTestDateRef.current?.focus());
        }
        if (protocolExists(stableDateRelations)) {
            if (!stableName.isEmpty) {
                if (!isUpdateCorrection) {
                    setTimeout(() => earTagChangeRef.current?.focus());
                } else {
                    // protocol exists, correctionType === 'UPDATE', value exists
                    stableDateRelations.narrow(isApcDateListWithStables)
                        .zip(stableName)
                        .map(uncurryTuple(genProtocolDateOptionsBasedOnStable))
                        .onSome(setProficiencyTestDateOptions);
                    setTimeout(() => proficiencyTestDateRef.current?.focus());
                }
            } else if (isUpdateCorrection) {
                // stableName is empty and onStableNumberChange occured => field was cleared,
                // correctionType === 'UPDATE' and protocol exists =>
                // clear date options and value and set stable options
                stableDateRelations.narrow(isApcDateListWithStables)
                    .prop('dateData')
                    .map(getDateOptions)
                    .onSome(setProficiencyTestDateOptions);
                stableDateRelations.narrow(isApcDateListWithStables)
                    .map(genProtocolStableOptionsBasedOnStable)
                    .onSome(setStableOptions);
            }
            if (correctionType === 'DISCARD') setTimeout(() => submitRef.current?.focus());
        }
    }, [
        correctionType,
        submitRef,
        change,
        isUpdateCorrection,
        earTagChangeRef,
        proficiencyTestDateRef,
        setProficiencyTestDateOptions,
        setStableOptions,
        stableDateRelations,
        clearAdditionalDataInProtocol,
    ]);

    return {onStableNumberChange, onProficiencyTestDateChange};
};
