import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {UserMemberSubjectForm} from '../../types/user-member-form-values';

const te = t('admin/users');

export const validate = (values: UserMemberSubjectForm): Errors<UserMemberSubjectForm> => {
    const validator = new EskotValidator(values);
    const approveIsInvalid = (
        values.approvedFrom !== null
        && values.approvedTo !== null
        && values.approvedFrom > values.approvedTo
    );

    validator.nonEmpty('subjectId', te('subject'));
    validator.nonEmpty('approvedFrom', te('approvedFrom'));
    validator.customRule(
        'approvedTo',
        approveIsInvalid,
        te('dateFromToIsInvalid'),
    );

    return validator.generateErrorsObject();
};
