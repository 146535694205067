import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse, NullableKeys} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {genNakedPropOrCrash, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {CreateUserProfileBreederRole} from 'api/gen/CreateUserProfileBreederRole';
import {t} from 'app/translations';
import {userDetailAction} from 'app/user-detail';
import {BuildCenters, buildCenterOptions} from 'app/user-detail/model/sagas/build-center-options';
import {SubjectFacilityFormValues, subjectFacilityForm} from 'app/user-detail/types/subject-facility-form-values';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {CreateBreederAction, adminAction} from '../action';

const te = t('common');
const title = te('createEntry');

const buildValuesForApi = (formValues: NullableKeys<SubjectFacilityFormValues>): CreateUserProfileBreederRole => {
    const get = genNakedPropOrCrash(formValues);

    return {
        approvedFrom: get('approvedFrom'),
        approvedTo: opt(formValues).prop('approvedTo').orNull(),
        roleIds: get('roleIds'),
        breederId: get('subjectId'),
        userProfileId: get('userProfileId'),
        farmIds: get('farmIds'),
    };
};

function* execute(_: CreateBreederAction): SagaIterator {
    const formValues = yield* select(state => formHelpers.formValues(subjectFacilityForm)(state)
        .orCrash(`no form values: ${subjectFacilityForm}`));
    yield* put(formHelpers.startSubmit(subjectFacilityForm));

    const buildFarms: BuildCenters = {centerIds: formValues.farmIds, type: 'FARM'};

    const farmIds = yield* call(buildCenterOptions, buildFarms);

    const response = yield* call(Api.addUserProfileBreederRoleBindings, buildValuesForApi({
        centerIds: null,
        approvedFrom: formValues.approvedFrom,
        approvedTo: formValues.approvedTo,
        roleIds: formValues.roleIds,
        subjectId: formValues.subjectId,
        userProfileId: formValues.userProfileId,
        userProfileRoleIds: formValues.userProfileRoleIds,
        farmIds,
    }));

    if (response.isSuccess) {
        const userProfileId = opt(formValues.userProfileId).orCrash('No userProfileId found');

        yield* put(adminAction.getUserBreeders(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('createEntrySuccess'), false, 'createBreeder'));
        yield* put(userDetailAction.setFacilityModalVisible(null));
        yield* put(formHelpers.reset(subjectFacilityForm));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(subjectFacilityForm, extractFormErrorsFromResponse(response)));
    }
}

export function* createBreederSaga(): SagaIterator {
    yield takeLatestF('admin/CREATE_BREEDER', execute);
}
