import React from 'react';
import { useLocation } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { adminRoutes } from 'app/admin/routes/routes';
import { analysisProtocolCorrectionRoutes } from 'app/analysis-protocol-correction/routes/routes';
import { animalBrowserRoutes } from 'app/animal-browser/routes/routes';
import { errorRoutes } from 'app/error/routes/routes';
import { legacyRoutes } from 'app/legacy/routes';
import { matingRoutes } from 'app/mating/routes/routes';
import { milkabilityRoutes } from 'app/milkability/routes/routes';
import { userRoutes } from 'app/user/routes/routes';
import { appRoutePaths } from './route-paths';
const addEndListener = (node: HTMLElement, done: () => void): void => node.addEventListener('transitionend', done, false);
export const Routes: React.FC = () => {
  const location = useLocation();
  return <SwitchTransition data-sentry-element="SwitchTransition" data-sentry-component="Routes" data-sentry-source-file="routes.tsx">
            <CSSTransition key={location.pathname} addEndListener={addEndListener} classNames="fade" unmountOnExit mountOnEnter in data-sentry-element="CSSTransition" data-sentry-source-file="routes.tsx">
                <Switch location={location} data-sentry-element="Switch" data-sentry-source-file="routes.tsx">
                    <Redirect exact from="/" to={appRoutePaths.home} data-sentry-element="Redirect" data-sentry-source-file="routes.tsx" />
                    {userRoutes}
                    {adminRoutes}
                    {legacyRoutes}
                    {matingRoutes}
                    {animalBrowserRoutes}
                    {milkabilityRoutes}
                    {analysisProtocolCorrectionRoutes}
                    {errorRoutes}
                    <Redirect to="/" data-sentry-element="Redirect" data-sentry-source-file="routes.tsx" />
                </Switch>
            </CSSTransition>
        </SwitchTransition>;
};