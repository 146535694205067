import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {uniqBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {cabFilterFormName} from 'app/animal-browser/types/cab-filter-form-values';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {animalBrowserAction, GetBreederOptionsAction} from '../action';
import {simpleAnimalBrowserSelector} from '../selector';

const tt = t('comprehensiveOverview');

function* execute({payload}: GetBreederOptionsAction): SagaIterator {
    const selectedValues = (yield* select(formHelpers.formValues(cabFilterFormName))).prop('breederIds').orElse([]);
    const oldOptions = (yield* select(simpleAnimalBrowserSelector.breederOptions)).orElse([]);
    const selectedOptions = oldOptions.filter(option => selectedValues.includes(option.value));

    yield* put(animalBrowserAction.setLoading('breedersSearch', true));

    const response = yield* call(Api.getCabBreedersAutocompleteList, {text: payload});

    if (response.isSuccess) {
        const options = response.data.map(option => ({
            value: option.id,
            label: `${option.registrationNumber}${option.businessName ? ` - ${option.businessName}` : ''}`,
        }));
        yield* put(animalBrowserAction.getBreederOptionsSuccess((uniqBy('value', [...selectedOptions, ...options]))));
    } else {
        yield putAll(showBeError(response, tt('filter')));
    }

    yield* put(animalBrowserAction.setLoading('breedersSearch', false));
}
export function* getBreederOptions(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_BREEDER_OPTIONS', execute);
}
