import {putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {DeleteCorrectionReportAction, analysisProtocolCorrectionAction} from '../action';

const te = t('apc/sagas');

function* execute(action: DeleteCorrectionReportAction): SagaIterator {
    const {id: apcId, isCreatedCorrectionReport} = action.payload;

    yield* put(analysisProtocolCorrectionAction.setLoading('deleteAnalysisProtocolCorrectionReport', true));

    const response = yield* call(Api.deleteCorrectionReport, {apcId});

    if (response.isSuccess) {
        yield* put(showSuccess(te('deleteCorrectionReport'), te('reportDeletedSuccessfully')));
        yield* put(!isCreatedCorrectionReport
            ? analysisProtocolCorrectionAction.getCorrectionReports(opt({action: 'refresh'}))
            : analysisProtocolCorrectionAction.deleteCorrectionReportSuccess(apcId),
        );
        yield* put(analysisProtocolCorrectionAction.setEditingCorrectionReportId(undefined));
    } else {
        yield putAll(showBeError(response, te('deleteCorrectionReport')));
    }

    yield* put(analysisProtocolCorrectionAction.setLoading('deleteAnalysisProtocolCorrectionReport', false));
}

export function* deleteCorrectionReportSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/DELETE_CORRECTION_REPORT', execute);
}
