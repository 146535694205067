import {permissionActionNameV2MaxLength, permissionActionNameV2MinLength} from 'api/gen/PermissionActionNameV2';
import {permissionCodeV2MaxLength, permissionCodeV2MinLength} from 'api/gen/PermissionCodeV2';
import {permissionCommentV2MaxLength, permissionCommentV2MinLength} from 'api/gen/PermissionCommentV2';
import {
    permissionControllerNameV2MaxLength,
    permissionControllerNameV2MinLength,
} from 'api/gen/PermissionControllerNameV2';
import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {CreateOrUpdatePermissionFormValues} from '../../types/permission-form-values';

const te = t('admin/permissions');

export const validate =
    (values: CreateOrUpdatePermissionFormValues): Errors<CreateOrUpdatePermissionFormValues> => {
        const validator = new EskotValidator(values);

        validator.nonEmpty('actionName', te('action'));
        validator.maxStringLength('actionName', permissionActionNameV2MaxLength, te('action'));
        validator.minStringLength('actionName', permissionActionNameV2MinLength, te('action'));

        validator.nonEmpty('code', te('code'));
        validator.maxStringLength('code', permissionCodeV2MaxLength, te('code'));
        validator.minStringLength('code', permissionCodeV2MinLength, te('code'));

        if (values.comment) {
            validator.maxStringLength('comment', permissionCommentV2MaxLength, te('comment'));
            validator.minStringLength('comment', permissionCommentV2MinLength, te('comment'));
        }

        validator.nonEmpty('controllerName', te('controller'));
        validator.maxStringLength('controllerName', permissionControllerNameV2MaxLength, te('controller'));
        validator.minStringLength('controllerName', permissionControllerNameV2MinLength, te('controller'));

        validator.nonEmpty('discriminator', te('discriminator'));

        validator.nonEmpty('userEnable', te('userEnable'));

        return validator.generateErrorsObject();
    };
