import {Middleware} from 'redux';

import {MiddlewareAction} from './middleware-action';

// eslint-disable-next-line unicorn/consistent-function-scoping
export const windowMiddleware: Middleware = () => next => (action: MiddlewareAction) => {
    switch (action.type) {
        case 'middleware/OPEN_WINDOW': {
            const {url, name, features} = action.payload;
            window.open(url, name, features);
            break;
        }
        default: {
            return next(action);
        }
    }

    return next(action);
};

