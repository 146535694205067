import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {adminAction, RemovePermissionFromRoleAction} from '../action';

function* execute({payload: {roleId, permissionId}}: RemovePermissionFromRoleAction): SagaIterator {
    const response = yield* call(Api.removePermissionFromRole, {roleId, permissionId});

    if (response.isSuccess) {
        yield* put(adminAction.getRolePermissions(roleId, opt({action: 'refresh'})));
        yield* put(adminAction.getRoleUnassignedPermissions(roleId, opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, t('admin/sagas')('removePermissionFromRole')));
    }
}

export function* removePermissionFromRoleSaga(): SagaIterator {
    yield takeLatestF('admin/REMOVE_PERMISSION_FROM_ROLE', execute);
}
