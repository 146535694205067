import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AssociationType } from 'api/gen/AssociationType';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { matingAction } from '../../model';
import { BullSelectionTab } from '../bull-selection-tab';
interface Props {
  title: string;
  associationType: AssociationType;
}
const BullSelectorBase: FC<Props> = ({
  title,
  associationType
}) => {
  const {
    t
  } = useOurTranslation('mating/bullSelector');
  const dispatch = useDispatch();
  const resetScreen = useCallback(() => {
    dispatch(matingAction.resetScreen());
  }, [dispatch]);
  const initializeForms = useCallback(() => {
    dispatch(matingAction.initializeForms(true, associationType));
  }, [dispatch, associationType]);
  useEffect(() => {
    resetScreen();
  }, [resetScreen]);
  useEffect(() => {
    initializeForms();
  }, [initializeForms]);
  const header = useMemo(() => <ScreenHeader title={title} moduleForTutorials={associationType === 'HOLSTEIN' ? 'BULLSELECTOR_H' : 'BULLSELECTOR_C'} />, [associationType, title]);
  return <Content browserTabTitle={`${t(associationType === 'HOLSTEIN' ? 'pageTitleH' : 'pageTitleC')}`} noFooter data-sentry-element="Content" data-sentry-component="BullSelectorBase" data-sentry-source-file="index.tsx">
            <Card title={header} titleSize="big" noPadding data-sentry-element="Card" data-sentry-source-file="index.tsx">
                <BullSelectionTab associationType={associationType} loadingMsg={t('loadingBullSelector')} data-sentry-element="BullSelectionTab" data-sentry-source-file="index.tsx" />
            </Card>
        </Content>;
};
export const BullSelector = memo(BullSelectorBase);