import { Options, Option, TypedFormFieldComponents } from '@fl/cmsch-fe-library';
import React, { FC, useMemo } from 'react';
import { map, Opt } from 'ts-opt';
import { OrganizationToChoose } from 'api/gen/OrganizationToChoose';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { allOrganizationsValue } from '../../consts';
import { MatingBullsFilterFormValues } from '../../types';
import styles from './styles.sass';
const span0 = 0;
const span34 = 34;
const buildOrganizationOption = (organization: OrganizationToChoose): Option<number> => ({
  value: organization.id,
  label: `${organization.code} - ${organization.name} (${organization.numOfBulls} ks)`
});
interface Props {
  organizations: Opt<Array<OrganizationToChoose>>;
  organizationsLoading: boolean;
  Fields: TypedFormFieldComponents<MatingBullsFilterFormValues>;
}
export const ExtraFirstAccordionNodes: FC<Props> = ({
  organizations,
  organizationsLoading,
  Fields
}) => {
  const {
    t,
    tCommon
  } = useOurTranslation('mating/matingBullsFilterForm');
  const allOrganizationsOption: Option<number> = useMemo(() => ({
    value: allOrganizationsValue,
    label: t('chooseAllOrganizations')
  }), [t]);
  const organizationOptions: Options<number> = useMemo(() => organizations.map(map(buildOrganizationOption)).orElse([]), [organizations]);
  return <Ant.Row gutter={[{
    xs: span0,
    xl: span34
  }, span0]} className={styles.extraNodes} data-sentry-element="unknown" data-sentry-component="ExtraFirstAccordionNodes" data-sentry-source-file="extra-first-accordion-nodes.tsx">
            <Ant.Col xs={24} xl={12} data-sentry-element="unknown" data-sentry-source-file="extra-first-accordion-nodes.tsx">
                <Fields.NumberMultiSelect label={tCommon('organization')} name="organizationIds" options={organizationOptions} selectAllOption={allOrganizationsOption} isRequired hideHelpInitially fieldIsLoading={organizationsLoading} data-sentry-element="unknown" data-sentry-source-file="extra-first-accordion-nodes.tsx" />
            </Ant.Col>
        </Ant.Row>;
};