import {Options, TableColumn} from '@fl/cmsch-fe-library';

import {StableToChoose} from 'api/gen/StableToChoose';
import {getCalfTableLink} from 'app/animal-browser/utils/get-calf-table-link';
import {getCowTableLink} from 'app/animal-browser/utils/get-cow-table-link';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const farmStablesColumns = (
    productionAreas: Options<string>,
    t: TFunction<'stableTableTooltip'>,
): Array<TableColumn<StableToChoose>> => [
    {
        field: 'breederRegistrationNumber',
        type: 'number',
        formatValue: String,
    },
    {
        field: 'breederName',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'farmCode',
        type: 'router',
        linkTooltip: t('showCalvesOverview'),
        valueToLink: getCalfTableLink,
    },
    {
        field: 'farmName',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'stableCode',
        type: 'router',
        linkTooltip: t('showCowsOverview'),
        valueToLink: getCowTableLink,
    },
    {
        field: 'stableName',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'stableInStudbookRegistry',
        type: 'string',
    },
    {
        field: 'proficiencyTest',
        type: 'optionalBoolean',
        filterTypes: ['null'],
    },
    {
        field: 'productionArea',
        type: 'option',
        options: productionAreas,
        filterTypes: ['multiSelectString', 'null'],
        customFiltersOnly: true,
    },
    {
        field: 'organizationCode',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'farmAnimalCount',
        type: 'number',
        filterTypes: ['null'],
    },
    {
        field: 'stableAnimalCount',
        type: 'number',
        filterTypes: ['null'],
    },
];
