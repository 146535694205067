import {addAnimalsFromCsvSaga} from './add-animals-from-csv-saga';
import {addAnimalsFromDBSaga} from './add-animals-from-db-saga';
import {addAnimalsSaga} from './add-animals-saga';
import {confirmOverrideAnimalListFromIdsSaga} from './confirm-override-animal-list-from-ids-saga';
import {confirmOverrideAnimalListSaga} from './confirm-override-animal-list-saga';
import {createAnimalListFromIdsSaga} from './create-animal-list-from-ids-saga';
import {createAnimalListSaga} from './create-animal-list-saga';
import {deleteAnimalListSaga} from './delete-animal-list-saga';
import {deleteAnimalsSaga} from './delete-animals-saga';
import {exportCabAnimalsSaga} from './export-cab-animals-saga';
import {filterCabCalvesSaga} from './filter-cab-calves-saga';
import {filterCabFemalesSaga} from './filter-cab-females-saga';
import {getAnimalListsSaga} from './get-animal-lists-saga';
import {getAnimalsCountSaga} from './get-animals-count';
import {getAvailableAnimalsSaga} from './get-available-animals-saga';
import {getBreederOptions} from './get-breeder-options';
import {getCabCalvesSaga} from './get-cab-calves-saga';
import {getCabFemalesSaga} from './get-cab-females-saga';
import {getCalvesCsvSaga} from './get-calves-csv-saga';
import {getCowsCsvSaga} from './get-cows-csv-saga';
import {getDistrictCodesOptionsSaga} from './get-district-codes-options';
import {getFarmOptionsSaga} from './get-farm-options';
import {getMotherHuidSaga} from './get-mother-huid-saga';
import {getOrganizationCenterInsOptionsSaga} from './get-oragnization-center-ins-options';
import {getOrganizationCenterPtOptionsSaga} from './get-oragnization-center-pt-options';
import {getOrganizationInsOptionsSaga} from './get-oragnization-ins-options';
import {getOrganizationPtOptionsSaga} from './get-oragnization-pt-options';
import {getStableOptionsSaga} from './get-stable-options';
import {getStablesCsvSaga} from './get-stables-csv-saga';
import {getCalfBrowserSaga} from './load-calf-browser-saga';
import {getCowBrowserSaga} from './load-cow-browser-saga';
import {getStablesDataSaga} from './load-stables-saga';
import {renameAnimalListSaga} from './rename-animal-list-saga';

export const animalBrowserSagas = [
    getStablesDataSaga,
    getCowBrowserSaga,
    getCalfBrowserSaga,
    getCalvesCsvSaga,
    getCowsCsvSaga,
    getStablesCsvSaga,
    getCabFemalesSaga,
    filterCabFemalesSaga,
    exportCabAnimalsSaga,
    getCabCalvesSaga,
    filterCabCalvesSaga,
    getOrganizationPtOptionsSaga,
    getOrganizationInsOptionsSaga,
    getDistrictCodesOptionsSaga,
    getBreederOptions,
    getFarmOptionsSaga,
    getStableOptionsSaga,
    getOrganizationCenterInsOptionsSaga,
    getOrganizationCenterPtOptionsSaga,
    getAnimalListsSaga,
    renameAnimalListSaga,
    createAnimalListSaga,
    deleteAnimalListSaga,
    confirmOverrideAnimalListSaga,
    confirmOverrideAnimalListFromIdsSaga,
    createAnimalListFromIdsSaga,
    getAvailableAnimalsSaga,
    addAnimalsSaga,
    addAnimalsFromCsvSaga,
    addAnimalsFromDBSaga,
    getAnimalsCountSaga,
    deleteAnimalsSaga,
    getMotherHuidSaga,
];
