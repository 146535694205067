import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {simpleTableSelector} from 'utils/tables';

import {matingSelector} from '../../model';
import {MatingLoadingId} from '../../types';

const FEMALE = 'FEMALE';

const isCowsFilterFormLoading = (loading: Array<MatingLoadingId>): boolean =>
    loading.includes('animalLists')
    || loading.includes('criteriaDefinitions')
    || loading.includes('criteriaLists')
    || loading.includes('breeders')
;

const isCowsFilterFormReloading = (loading: Array<MatingLoadingId>): boolean =>
    loading.includes('criteriaListDetails')
;

interface CowTabState {
    tabLoading: boolean;
    formReloading: boolean;
}

export const useCowsTabState = (
    loading: Array<MatingLoadingId>,
): CowTabState => {
    const animalListsTag = useSelector(matingSelector.animalListsTag);
    const criteriaListsTag = useSelector(matingSelector.criteriaListsTag);
    const matingCowsTableState = useSelector(simpleTableSelector.tableState('matingCows'));
    const cowsPage = useSelector(matingSelector.femaleAnimalsPage);

    const listsLoading = useMemo(
        () => !animalListsTag.contains(FEMALE) || !criteriaListsTag.contains(FEMALE),
        [animalListsTag, criteriaListsTag],
    );
    const formLoading = useMemo(() => isCowsFilterFormLoading(loading) || listsLoading, [loading, listsLoading]);
    const formReloading = useMemo(() => isCowsFilterFormReloading(loading), [loading]);
    const tableInitializing = useMemo(
        () => !(matingCowsTableState && cowsPage.nonEmpty),
        [cowsPage.nonEmpty, matingCowsTableState],
    );

    return {
        tabLoading: formLoading || tableInitializing,
        formReloading,
    };
};
