import {convertAllErrorsFromBeToFormError, crash, putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {SagaIterator} from 'redux-saga';
import {opt, pipe, genNakedPropOrCrash} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {CorrectionType} from 'api/gen/CorrectionType';
import {CreateApc} from 'api/gen/CreateApc';
import {EditApc} from 'api/gen/EditApc';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {replaceUrlWithoutRerender} from 'utils/replace-url-without-rerender';

import {
    apcReportFormName,
    ApcReportFormValues,
    initialApcReportFormValues,
} from '../../components/apc-report-form/form-values';
import {analysisProtocolCorrectionAction, CreateCorrectionReportAction} from '../action';
// eslint-disable-next-line max-lines-per-function
const convertFormValuesToCreateApc = (formValues: ApcReportFormValues): CreateApc => {
    const formValuesOpt = opt(formValues);
    const get = genNakedPropOrCrash(formValues);

    switch (formValues.correctionType) {
        case 'DISCARD':
            return {
                correctionType: 'DISCARD',
                earTag: get('earTag'),
                technicianNumber: get('technicianNumber'),
                proficiencyTestDate: get('proficiencyTestDate'),
            };
        case 'UPDATE':
            return {
                correctionType: 'UPDATE',
                earTag: get('earTag'),
                technicianNumber: get('technicianNumber'),
                proficiencyTestDate: get('proficiencyTestDate'),
                milkKg: get('milkKg'),
                stableCode: get('stableNumber'),
                fatPercentage: formValuesOpt.prop('fatPercentage').orNull(),
                lactosePercentage: formValuesOpt.prop('lactosePercentage').orNull(),
                proteinPercentage: formValuesOpt.prop('proteinPercentage').orNull(),
                somaticCells: formValuesOpt.prop('somaticCells').orNull(),
                urea: formValuesOpt.prop('urea').orNull(),
            };
        case 'EAR_TAG_UPDATE':
            return {
                correctionType: 'EAR_TAG_UPDATE',
                earTag: get('earTag'),
                technicianNumber: get('technicianNumber'),
                proficiencyTestDate: get('proficiencyTestDate'),
                updatedEarTag: get('updatedEarTag'),
            };
        default:
            crash('missing correctionType');
    }
};

// eslint-disable-next-line max-lines-per-function
const convertFormValuesToEditApc = (formValues: ApcReportFormValues): EditApc => {
    const formValuesOpt = opt(formValues);
    const get = genNakedPropOrCrash(formValues);

    switch (formValues.correctionType) {
        case 'DISCARD':
            return {
                correctionType: 'DISCARD',
                technicianNumber: get('technicianNumber'),
                proficiencyTestDate: get('proficiencyTestDate'),
            };
        case 'UPDATE':
            return {
                correctionType: 'UPDATE',
                technicianNumber: get('technicianNumber'),
                proficiencyTestDate: get('proficiencyTestDate'),
                milkKg: get('milkKg'),
                stableCode: get('stableNumber'),
                fatPercentage: formValuesOpt.prop('fatPercentage').orElse(0),
                lactosePercentage: formValuesOpt.prop('lactosePercentage').orElse(0),
                proteinPercentage: formValuesOpt.prop('proteinPercentage').orElse(0),
                somaticCells: formValuesOpt.prop('somaticCells').orElse(0),
                urea: formValuesOpt.prop('urea').orElse(0),
            };
        case 'EAR_TAG_UPDATE':
            return {
                correctionType: 'EAR_TAG_UPDATE',
                technicianNumber: get('technicianNumber'),
                proficiencyTestDate: get('proficiencyTestDate'),
                updatedEarTag: get('updatedEarTag'),
            };
        default:
            crash('missing correctionType');
    }
};

const formValuesO = O.optic<ApcReportFormValues>();
const getInitialValuesWithPersistentFields = (
    correctionType: CorrectionType,
    technicianNumber: string,
): ApcReportFormValues => pipe(
    initialApcReportFormValues,
    O.set(formValuesO.prop('technicianNumber'))(technicianNumber),
    O.set(formValuesO.prop('correctionType'))(correctionType),
);

// eslint-disable-next-line max-lines-per-function
function* execute({
    payload: {
        formValues,
        focusEarTag,
        editingCorrectionReportId: apcId,
    },
}: CreateCorrectionReportAction): SagaIterator {
    yield* put(formHelpers.startSubmit(apcReportFormName));

    const response = yield* apcId
        ? call(Api.editCorrectionReport, convertFormValuesToEditApc(formValues), {apcId})
        : call(Api.createCorrectionReport, convertFormValuesToCreateApc(formValues));

    if (response.isSuccess) {
        yield* put(analysisProtocolCorrectionAction.createCorrectionReportSuccess(response.data));
        yield* put(formHelpers.initialize(
            apcReportFormName,
            getInitialValuesWithPersistentFields(
                opt(formValues.correctionType).orCrash('correctionType missing'),
                opt(formValues.technicianNumber).orCrash('technicianNumber missing'),
            ),
        ));
        yield* put(analysisProtocolCorrectionAction.resetFormData(true));
        replaceUrlWithoutRerender('/analysis-protocol-correction/new');
        focusEarTag();
    } else {
        const errors = convertAllErrorsFromBeToFormError(response.data);
        yield putAll(showBeError(response, t('apc/apc')('title')));
        yield* put(formHelpers.stopAsyncValidation(apcReportFormName, errors.errors));
        yield* put(formHelpers.setAsyncWarnings(apcReportFormName, errors.warnings));
    }

    yield* put(formHelpers.stopSubmit(apcReportFormName, {}));
}

export function* createCorrectionReportSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/CREATE_CORRECTION_REPORT', execute);
}
