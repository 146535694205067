import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';

import {LoadMilkabilityReportDetailAction, milkabilityAction} from '../action';

function* execute(action: LoadMilkabilityReportDetailAction): SagaIterator {
    yield* put(milkabilityAction.setLoading('loadingMilkabilityReportDetail', true));

    const {id} = action.payload;

    yield* put(milkabilityAction.setEditingMilkabilityReportId(id));

    const response = yield* call(Api.getMilkabilityReportDetail, {id});

    if (response.isSuccess) {
        const {apmv, earTag, examinationDate, milkedTotal, milkingTime, technicianNumber} = response.data;
        yield* put(formHelpers.initialize('milkabilityReport', {
            apmv,
            earTag,
            examinationDate,
            milkedTotal,
            milkingTime: {value: milkingTime, secondsOverLimit: 0},
            technicianNumber,
        }));
    } else {
        yield putAll(showBeError(response, t('milkability/sagas')('loadMilkabilityReportDetail')));
    }

    yield* put(milkabilityAction.setLoading('loadingMilkabilityReportDetail', false));
}

export function* loadMilkabilityReportDetailSaga(): SagaIterator {
    yield takeLatestF('milkability/LOAD_MILKABILITY_REPORT_DETAIL', execute);
}
