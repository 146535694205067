import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';
import {GetOrganizationsAction, matingAction} from '../action';

function* execute({payload: {associationType}}: GetOrganizationsAction): SagaIterator {
    yield* put(matingAction.setLoading('organizations', true));
    yield* put(formHelpers.change(matingBullsFilterFormName, 'organizationIds', []));

    const response = yield* call(Api.getOrganizations, {associationType});

    if (response.isSuccess) {
        yield* put(matingAction.getOrganizationsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('mating/sagas')('loadBullOrganizations')));
    }

    yield* put(matingAction.setLoading('organizations', false));
}

export function* getOrganizationsSaga(): SagaIterator {
    yield takeLatestF('mating/GET_ORGANIZATIONS', execute);
}
