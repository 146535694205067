import {downloadFileFromResponse, putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {getRequestedTableCsvExportParams, simpleTableSelector} from 'utils/tables';

import {analysisProtocolCorrectionAction, ExportCorrectionReportAction} from '../action';

function* execute(_: ExportCorrectionReportAction): SagaIterator {
    yield* put(analysisProtocolCorrectionAction.setLoading('exportingAnalysisProtocolCorrectionOverview', true));

    const tableState = opt(yield* select(simpleTableSelector.tableState('analysisProtocolCorrectionOverview')))
        .orCrash('no tableState');
    const {sort, fieldParams} = getRequestedTableCsvExportParams(tableState);

    const response = yield* call(Api.exportCorrectionReports, fieldParams, {sort});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('common')('downloadCsv')));
    }

    yield* put(analysisProtocolCorrectionAction.setLoading('exportingAnalysisProtocolCorrectionOverview', false));
}

export function* exportCorrectionReportsSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/EXPORT_CORRECTION_REPORTS', execute);
}
