import { classNames } from '@fl/cmsch-fe-library';
import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { AntLayout } from 'common/ant';
import { Config } from './config';
import styles from './styles.sass';
interface OuterProps {
  maxWidthContainer?: boolean;
  havePadding?: boolean;
  noFooter?: boolean;
  flex?: 'column' | 'row';
  children: ReactNode;
  browserTabTitle: string;
}
export const Content: React.FC<PropsWithChildren<OuterProps>> = ({
  maxWidthContainer,
  havePadding,
  noFooter,
  flex,
  children,
  browserTabTitle
}) => {
  const contentClassNames = useMemo(() => classNames(havePadding && styles.content, maxWidthContainer && styles.contentMaxWidthContainer, 'position-relative', flex && ['d-flex', `flex-${flex}`]), [havePadding, maxWidthContainer, flex]);
  return <>
            <Helmet title={browserTabTitle} data-sentry-element="Helmet" data-sentry-source-file="content.tsx" />
            <AntLayout data-sentry-element="AntLayout" data-sentry-source-file="content.tsx">
                <AntLayout.Content className={contentClassNames} data-sentry-element="unknown" data-sentry-source-file="content.tsx">
                    {children}
                </AntLayout.Content>
                {!noFooter && <AntLayout.Footer className="text-center">
                    {`Copyright © ${Config.year} Českomoravská společnost chovatelů, a.s.`}
                </AntLayout.Footer>}
            </AntLayout>
        </>;
};