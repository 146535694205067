import {setItem} from '@fl/cmsch-fe-library';
import {filter, reject} from 'lodash/fp';
import * as O from 'optics-ts';
import {flow, none, opt, pipe} from 'ts-opt';

import {AppAction} from 'app/setup';

import {AnalysisProtocolCorrectionState, initialAnalysisProtocolCorrectionState} from './state';

const stateO = O.optic<AnalysisProtocolCorrectionState>();

export const analysisProtocolCorrectionReducer = (
    state: AnalysisProtocolCorrectionState = initialAnalysisProtocolCorrectionState,
    action: AppAction,
): AnalysisProtocolCorrectionState => {
    switch (action.type) {
        case 'analysisProtocolCorrection/GET_CORRECTION_REPORTS_SUCCESS': {
            const {tableData} = action.payload;

            return O.set(stateO.prop('pageAnalysisProtocolCorrectionReports'))(tableData)(state);
        }

        case 'analysisProtocolCorrection/SET_LOADING': {
            const {id, loading} = action.payload;

            return O.modify(stateO.prop('loading'))(setItem(loading)(id))(state);
        }

        case 'analysisProtocolCorrection/GET_STABLE_DATE_RELATIONS_SUCCESS': {
            const {apcStableDate} = action.payload;

            return O.set(stateO.prop('apcStableDate'))(apcStableDate)(state);
        }

        case 'analysisProtocolCorrection/SET_PROFICIENCY_TEST_DATE_OPTIONS': {
            const {options} = action.payload;

            return O.set(stateO.prop('proficiencyTestDateOptions'))(opt(options))(state);
        }

        case 'analysisProtocolCorrection/SET_STABLE_OPTIONS': {
            const {options} = action.payload;

            return O.set(stateO.prop('stableOptions'))(opt(options))(state);
        }

        case 'analysisProtocolCorrection/GET_TECHNICIAN_OPTIONS_SUCCESS': {
            const {options} = action.payload;

            return O.set(stateO.prop('technicianOptions'))(opt(options))(state);
        }

        case 'analysisProtocolCorrection/CREATE_CORRECTION_REPORT_SUCCESS': {
            const {correctionReport} = action.payload;

            return O.modify(stateO.prop('createdCorrectionReports'))(flow(
                reject(x => x.id === correctionReport.id),
                xs => [...xs, correctionReport],
            ))(state);
        }

        case 'analysisProtocolCorrection/DELETE_CORRECTION_REPORT_SUCCESS': {
            const {id} = action.payload;

            return O.modify(stateO.prop('createdCorrectionReports'))(filter(x => x.id !== id))(state);
        }

        case 'analysisProtocolCorrection/RESET_CREATED_APC_REPORTS': {
            return O.set(stateO.prop('createdCorrectionReports'))([])(state);
        }

        case 'analysisProtocolCorrection/SET_EDITING_CORRECTION_REPORT_ID': {
            const {id} = action.payload;

            return O.set(stateO.prop('editingCorrectionReportId'))(id)(state);
        }

        case 'analysisProtocolCorrection/RESET_FORM_DATA': {
            const {dontResetTechnicianOptions} = action.payload;

            return pipe(
                state,
                O.modify(stateO.prop('technicianOptions'))(o => dontResetTechnicianOptions ? o : none),
                O.set(stateO.prop('proficiencyTestDateOptions'))(none),
                O.set(stateO.prop('editingCorrectionReportId'))(undefined),
                O.set(stateO.prop('stableOptions'))(none),
                O.set(stateO.prop('apcStableDate'))(none),
                O.set(stateO.prop('analysisProtocol'))(none),
            );
        }
        case 'analysisProtocolCorrection/clear_stable_and_date_data': {
            return pipe(
                state,
                O.set(stateO.prop('proficiencyTestDateOptions'))(none),
                O.set(stateO.prop('stableOptions'))(none),
                O.set(stateO.prop('apcStableDate'))(none),
            );
        }

        case 'analysisProtocolCorrection/GET_ANALYSIS_PROTOCOL_SUCCESS': {
            const {analysisProtocol} = action.payload;

            return O.set(stateO.prop('analysisProtocol'))(opt(analysisProtocol))(state);
        }

        default:
            return state;
    }
};
