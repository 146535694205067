import { TypedFormSectionInjectedProps } from '@fl/cmsch-fe-library';
import { filter, sortBy } from 'lodash/fp';
import React, { FC, Fragment, memo, useMemo } from 'react';
import { Opt, pipe, map } from 'ts-opt';
import { Accordion } from 'api/gen/Accordion';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { AccordionField } from 'app/mating/components/criteria-collapse/accordion-field';
import { Ant } from 'common/ant';
import { Criterias } from '../../types/criterias';
import styles from './styles.sass';
export interface AccordionCollapseProps {
  accordion: Accordion;
  criteriaDefinitions: Array<CriteriaDefinition>;
  resetCriteriaValue(criteriaName: Opt<string>): void;
}
type Props = TypedFormSectionInjectedProps<{
  criterias: Criterias;
}, 'criterias'> & AccordionCollapseProps;
const disabledPercentagesCowAccordionId = 2;
const disabledPercentagesBullAccordionId = 6;
const AccordionCollapseBase: FC<Props> = props => {
  const {
    accordion,
    criteriaDefinitions,
    Fields,
    resetCriteriaValue
  } = props;
  const disableTopAnimals = accordion.bookmark === 'FEMALE' && accordion.id === disabledPercentagesCowAccordionId || accordion.bookmark === 'MALE' && accordion.id === disabledPercentagesBullAccordionId;
  const accordionCriteria = useMemo(() => pipe(criteriaDefinitions, filter((criteriaDef: CriteriaDefinition) => criteriaDef.index.accordionId === accordion.id), sortBy((criteriaDef: CriteriaDefinition) => criteriaDef.index.position)), [criteriaDefinitions, accordion.id]);
  const renderedCriteria = useMemo(() => pipe(accordionCriteria, map((criteriaDef: CriteriaDefinition) => <Ant.Col xs={24} xl={12} key={criteriaDef.id} className={styles.accordionFieldWrapper}>
                    <AccordionField criteriaDefinition={criteriaDef} disableTopAnimals={disableTopAnimals} resetCriteriaValue={resetCriteriaValue} Fields={Fields} />
                </Ant.Col>)), [accordionCriteria, disableTopAnimals, resetCriteriaValue, Fields]);
  return <Fragment>
            {renderedCriteria}
        </Fragment>;
};
export const AccordionCollapse = memo(AccordionCollapseBase);