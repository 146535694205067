import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {GetAnimalListsAction, matingAction} from '../action';
import {getTableInfo} from './get-table-info';

function* execute({payload: {sex, associationType, withoutLoading}}: GetAnimalListsAction): SagaIterator {
    if (!withoutLoading) yield* put(matingAction.setLoading('animalLists', true));

    const response = yield* call(Api.getAnimalLists, getTableInfo(sex, associationType));

    if (response.isSuccess) {
        yield* put(matingAction.getAnimalListsSuccess(response.data, sex));
    } else {
        yield putAll(showBeError(response, t('mating/sagas')('loadAnimalsList')));
    }

    if (!withoutLoading) yield* put(matingAction.setLoading('animalLists', false));
}

export function* getAnimalListsSaga(): SagaIterator {
    yield takeLatestF('mating/GET_ANIMAL_LISTS', execute);
}
