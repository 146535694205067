import React, { Component, ComponentClass, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Content } from 'app/content';
import { WithTranslation } from 'app/translations/with-translation';
import { userAction } from '../model';
interface DispatchProps {
  logOut(): void;
}
type Props = DispatchProps & WithTranslation<'user/logout'>;
class LogoutBase extends Component<Props> {
  public componentDidMount(): void {
    const {
      logOut
    } = this.props;
    logOut();
  }
  public render(): ReactNode {
    const {
      t
    } = this.props;
    return <Content browserTabTitle="eSkot" noFooter flex="column" data-sentry-element="Content" data-sentry-component="LogoutBase" data-sentry-source-file="logout.tsx">
                <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                    <h2>{t('logoutHeading')}</h2>
                </div>
            </Content>;
  }
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  logOut: (): void => void dispatch(userAction.logOut())
});
const enhance = compose<ComponentClass>(connect(null, mapDispatchToProps), withTranslation('user/logout'));
export const Logout = enhance(LogoutBase);