import {defaultNumberRangePercentages} from '@fl/cmsch-fe-library';

import {CriteriaDefinition} from 'api/gen/CriteriaDefinition';

import {Criterias} from '../types/criterias';

export const getDefaultCriteriaValues = (criteriaDefinitions: Array<CriteriaDefinition>): Criterias => {
    const initialCriterias: Criterias = {
        radios: {},
        dateRanges: {},
        numberRanges: {},
    };

    criteriaDefinitions.forEach(criterion => {
        if (criterion.tag === 'BOOL') {
            initialCriterias.radios[criterion.code] = criterion.value;
        } else if (criterion.tag === 'DATE') {
            initialCriterias.dateRanges[criterion.code] = [criterion.values.leftValue, criterion.values.rightValue];
        } else {
            initialCriterias.numberRanges[criterion.code] = {
                minimum: criterion.values.leftValue,
                maximum: criterion.values.rightValue,
                percentage: defaultNumberRangePercentages,
                selectedType: null,
                criteriaType: criterion.tag,
            };
        }
    });

    return initialCriterias;
};
