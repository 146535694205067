import {takeLatestF, showBeError, putAll} from '@fl/cmsch-fe-library';
import {last, split} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {none, opt, orCrash, pipe} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {potentiallyChangeOfTableDataSource} from '../../utils/potentially-change-of-table-data-source';
import {animalBrowserAction, GetCalfBrowserTableDataAction} from '../action';
import {simpleAnimalBrowserSelector} from '../selector';

const getFilterParams = ({router: {location}}: State): string =>
    pipe(location?.pathname, split('/'), last, opt, orCrash('invalid url'));

// eslint-disable-next-line max-lines-per-function
function* execute(action: GetCalfBrowserTableDataAction): SagaIterator {
    const {tableDataRequest} = action.payload;

    if (tableDataRequest.isEmpty) {
        return yield* put(animalBrowserAction.getCalfBrowserTableDataSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('calfBrowser'));

    const tableState = yield* select(simpleTableSelector.tableState('calfBrowser'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'calfBrowser',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
        resetType: 'tableDataParams',
    });

    const columnSettings = getRequestedTableColumnSettings<'calfBrowser'>(tableState);

    const id = opt(yield* select(getFilterParams)).orCrash('Wrong ID');
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.getCalvesByFarm, filters, params, {farmId: parseInt(id, 10)});

    const farmCode = (yield* select(simpleAnimalBrowserSelector.farmInfo)).prop('code').orUndef();
    if (!farmCode || potentiallyChangeOfTableDataSource(tableDataRequest.orCrash('No tableDataRequest'))) {
        const farmInfo = yield* call(Api.getAnimalsBrowserFarm, {farmId: parseInt(id, 10)});
        if (farmInfo.isSuccess) {
            yield* put(animalBrowserAction.setFarmName(farmInfo.data));
        }
    }
    if (response.isSuccess) {
        yield* put(animalBrowserAction.getCalfBrowserTableDataSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('calfBrowser', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('calfBrowserTable')('tableName')));
    }
}

export function* getCalfBrowserSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_CALF_BROWSER_DATA', execute);
}
