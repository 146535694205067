import { classNames } from '@fl/cmsch-fe-library';
import React, { FC, memo } from 'react';
import { Config } from 'app/config';
import { useOurTranslation } from 'app/translations';
import { Button } from 'common/buttons';
import { Card } from 'common/layout';
import styles from 'app/styles.sass';
const MalformedUrlBase: FC = () => {
  const {
    t
  } = useOurTranslation('common/error');
  const classname = classNames(styles.errorFallbackScreen, 'pt-5');
  return <div className={classname} data-sentry-component="MalformedUrlBase" data-sentry-source-file="malformed-url.tsx">
            <div className={styles.errorFallbackScreenCard}>
                <Card data-sentry-element="Card" data-sentry-source-file="malformed-url.tsx">
                    <h2>{t('addressError')}</h2>
                    <p>
                        {t('malformedUrl')}
                        <br />
                        {t('goBackAndTryAgain')}
                        <br />
                        <a href={Config.url}>{Config.url}</a>
                    </p>
                    <Button visuals="primary" href={Config.url} data-sentry-element="Button" data-sentry-source-file="malformed-url.tsx">
                        {t('goToEskot')}
                    </Button>
                </Card>
            </div>
        </div>;
};
export const MalformedUrl = memo(MalformedUrlBase);