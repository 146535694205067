import React, { FC, memo } from 'react';
import { Opt } from 'ts-opt';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { LayoutDiv } from 'common/layout';
import { useForm } from 'utils/forms';
import { matingBullPreferencesFormName, MatingBullPreferencesFormValues, BullPreference, BullRanking } from '../../types';
import { MatingBullPreferencesTable, MatingBullPreferencesTableProps } from './mating-bull-preferences-table';
import { validate } from './validate';

// eslint-disable-next-line no-magic-numbers
const actionPanelRowGutter: [number, number] = [8, 8];
const initialValues: MatingBullPreferencesFormValues = {
  bullPreferences: []
};
interface Props {
  bullRankings: Array<BullRanking>;
  sumOfBullsRankCounts: Array<number>;
  bullPreferences: Opt<Array<BullPreference>>;
  resetScoringResults(): void;
  toggleSaveScoringResultsVisibility(): void;
  onSubmit(formValues: MatingBullPreferencesFormValues): void;
}
const MatingBullPreferencesFormBase: FC<Props> = props => {
  const {
    bullRankings,
    sumOfBullsRankCounts,
    bullPreferences,
    resetScoringResults,
    toggleSaveScoringResultsVisibility,
    onSubmit
  } = props;
  const {
    Form,
    Fields,
    valid,
    submitting
  } = useForm({
    form: matingBullPreferencesFormName,
    initialValues,
    onSubmit,
    validate
  });
  const {
    t
  } = useOurTranslation('mating/resultTab');
  const showingRecalculatedScoringResults = bullPreferences.nonEmpty;
  const FieldArray = Fields.genFieldArray<'bullPreferences', MatingBullPreferencesTableProps>();
  return <LayoutDiv marginCenter maxWidth={1900} data-sentry-element="LayoutDiv" data-sentry-component="MatingBullPreferencesFormBase" data-sentry-source-file="mating-bull-preferences-form.tsx">
            <Form data-sentry-element="Form" data-sentry-source-file="mating-bull-preferences-form.tsx">
                <FieldArray name="bullPreferences" Component={MatingBullPreferencesTable} props={{
        bullRankings,
        sumOfBullsRankCounts,
        bullPreferences
      }} data-sentry-element="FieldArray" data-sentry-source-file="mating-bull-preferences-form.tsx" />

                <LayoutDiv mt={3} mb={3} data-sentry-element="LayoutDiv" data-sentry-source-file="mating-bull-preferences-form.tsx">
                    <Ant.Row justify="center" gutter={actionPanelRowGutter} data-sentry-element="unknown" data-sentry-source-file="mating-bull-preferences-form.tsx">
                        <Ant.Col data-sentry-element="unknown" data-sentry-source-file="mating-bull-preferences-form.tsx">
                            {showingRecalculatedScoringResults ? <Button key="reset" onClick={resetScoringResults}>
                                        {t('resetPreferencesRecalculation')}
                                    </Button> : <Button key="submit" type="submit" disabled={!valid || submitting}>
                                        {t('recalculateAccordingToSelectedPreferences')}
                                    </Button>}
                        </Ant.Col>
                        <Ant.Col data-sentry-element="unknown" data-sentry-source-file="mating-bull-preferences-form.tsx">
                            <Button onClick={toggleSaveScoringResultsVisibility} data-sentry-element="Button" data-sentry-source-file="mating-bull-preferences-form.tsx">
                                {t('saveResultToFile')}
                            </Button>
                        </Ant.Col>
                    </Ant.Row>
                </LayoutDiv>
            </Form>
        </LayoutDiv>;
};
export const MatingBullPreferencesForm = memo(MatingBullPreferencesFormBase);