import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {adminAction, DeletePermissionAction} from '../action';

const te = t('admin/sagas');

function* execute({payload: {permissionId}}: DeletePermissionAction): SagaIterator {
    const response = yield* call(Api.deletePermission, {permissionId});

    const title = te('deletePermission');

    if (response.isSuccess) {
        yield* put(showSuccess(
            t('admin/sagas')('deletePermission'),
            t('admin/sagas')('permissionDeleted'),
            false,
            'deletePermission',
        ));
        yield* put(adminAction.getPermissionDetails(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* deletePermissionSaga(): SagaIterator {
    yield takeLatestF('admin/DELETE_PERMISSION', execute);
}
