import { FooterButtons, NullableKeys, Option, Options, superUniqueClassName } from '@fl/cmsch-fe-library';
import { isEqual, isNull } from 'lodash/fp';
import moment from 'moment';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt, opt } from 'ts-opt';
import { useOurTranslation } from 'app/translations';
import { formHelpers, useForm } from 'utils/forms';
import { Errors } from 'utils/validator';
import { allOptionValue } from '../../constants/consts';
import { userDetailAction } from '../../model/action';
import { simpleUserDetailSelector } from '../../model/selector';
import { CentersSettings, FarmsSettings } from '../../types/farms-settings';
import { subjectFacilityFormInitialValues, subjectFacilityForm, SubjectFacilityFormValues, SubjectFacilityFormEntryData, SubjectFacilityFormType } from '../../types/subject-facility-form-values';
import styles from './styles.sass';
const allOptionValueArray = [allOptionValue];
interface Props {
  userProfileId: number;
  subjectOptions: Options<number>;
  roleOptions: Options<number>;
  data: SubjectFacilityFormEntryData | null;
  subjectTitle: string;
  subjectFacilityFormType: SubjectFacilityFormType;
  centersSettings?: CentersSettings;
  farmsSettings: FarmsSettings;
  validate(values: SubjectFacilityFormValues): Errors<SubjectFacilityFormValues>;
  onSubmit(): void;
  onCancel(): void;
  onOrganizationSearch(searchText: string): void;
  onSyncValidate(): void;
}

// eslint-disable-next-line max-lines-per-function
const SubjectFacilityFormBase: FC<Props> = ({
  onSubmit,
  onCancel,
  userProfileId,
  subjectOptions,
  roleOptions,
  data,
  subjectFacilityFormType,
  onOrganizationSearch,
  onSyncValidate,
  subjectTitle,
  farmsSettings: {
    allFarmTitle,
    farmOptions,
    farmTitle,
    farmsAreEmpty,
    isRequired: farmsAreRequired,
    getFarms
  },
  centersSettings,
  validate
}) => {
  const dispatch = useDispatch();
  const {
    t,
    tCommon
  } = useOurTranslation('userDetail');
  const isOrganization = useMemo(() => subjectFacilityFormType === 'ORGANIZATION', [subjectFacilityFormType]);
  const createInitialValues = useMemo(() => subjectFacilityFormInitialValues(userProfileId), [userProfileId]);
  const {
    Form,
    Fields,
    submitting,
    valid,
    pristine,
    asyncValidating,
    destroy,
    change
  } = useForm({
    form: subjectFacilityForm,
    initialValues: createInitialValues,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true,
    onSubmit,
    validate
  });
  const formValues = useSelector(formHelpers.formValues(subjectFacilityForm));
  const subjectId = formValues.prop('subjectId').orUndef();
  const farmIds = formValues.prop('farmIds').orUndef();
  const centerIds = formValues.prop('centerIds').orUndef();
  const isEdit = !isNull(data);
  const loading = useSelector(simpleUserDetailSelector.loading);
  const allCentersOption: Option<number> | undefined = useMemo(() => centersSettings ? {
    value: allOptionValue,
    label: centersSettings.allCenterTitle
  } : undefined, [centersSettings]);
  const allFarmsOption: Option<number> | undefined = useMemo(() => ({
    value: allOptionValue,
    label: allFarmTitle
  }), [allFarmTitle]);
  useEffect(() => () => {
    destroy();
  }, [destroy]);
  const approveFrom = formValues.prop('approvedFrom').orUndef();
  const approveFromMoment = useMemo(() => moment(approveFrom), [approveFrom]);
  const approvedToRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isEdit) {
      const initFormData: NullableKeys<SubjectFacilityFormValues> = {
        subjectId: data.subjectId,
        roleIds: opt(data).prop('roleIds').orElse([]),
        userProfileId,
        approvedFrom: data.approvedFrom,
        approvedTo: data.approvedTo,
        centerIds: data.centerIds,
        userProfileRoleIds: data.userProfileRoleIds,
        farmIds: data.farmIds
      };
      dispatch(formHelpers.initialize(subjectFacilityForm, initFormData));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (centersSettings && !centersSettings.centersAreEmpty && !isEdit) {
      dispatch(formHelpers.change(subjectFacilityForm, 'centerIds', allOptionValueArray));
    }
  }, [centersSettings, isEdit, dispatch]);
  useEffect(() => {
    if (!farmsAreEmpty && !isEdit) {
      dispatch(formHelpers.change(subjectFacilityForm, 'farmIds', allOptionValueArray));
    }
  }, [farmsAreEmpty, isEdit, dispatch]);
  const handleRoleSearch = useCallback((searchText: string): void => {
    dispatch(userDetailAction.searchSubjectFacilityFormRoleOptions(searchText, subjectFacilityFormType));
  }, [dispatch, subjectFacilityFormType]);
  const handleSubjectIdChange = useCallback((newSubjectId: Opt<number>): void => {
    onSyncValidate();
    centersSettings?.getCenters(newSubjectId);
    getFarms(newSubjectId);
    if (centersSettings) {
      dispatch(formHelpers.change(subjectFacilityForm, 'centerIds', null));
      dispatch(formHelpers.untouch(subjectFacilityForm, 'centerIds'));
    }
    dispatch(formHelpers.change(subjectFacilityForm, 'farmIds', null));
    dispatch(formHelpers.untouch(subjectFacilityForm, 'farmIds'));
    dispatch(userDetailAction.setCentersOptions([]));
    dispatch(userDetailAction.setOrganizationFarmOptions([]));
    dispatch(userDetailAction.setFarms([]));
  }, [dispatch, onSyncValidate, centersSettings, getFarms]);
  const handleCenterIdsChange = useCallback((newCenterIdsOpt: Opt<Array<number>>) => {
    if (centersSettings && isOrganization) {
      const newCenterIds = newCenterIdsOpt.orElse([]);
      const allFarmsSelected = isEqual(farmIds, allOptionValueArray);
      const allOptionsSelected = newCenterIds.length === centersSettings.centerOptions.length;
      const allCentersSelected = isEqual(newCenterIds, allOptionValueArray) || allOptionsSelected;
      if (allOptionsSelected) {
        change('centerIds', allOptionValueArray);
      }
      if (!allCentersSelected && allFarmsSelected) {
        change('farmIds', null);
      } else if (allCentersSelected) {
        change('farmIds', allOptionValueArray);
      }
    }
    onSyncValidate();
  }, [onSyncValidate, farmIds, isOrganization, centersSettings, change]);
  const handleFarmIdsChange = useCallback((newFarmIdsOpt: Opt<Array<number>>) => {
    if (centersSettings && isOrganization) {
      const newFarmIds = newFarmIdsOpt.orElse([]);
      const allCentersSelected = isEqual(centerIds, allOptionValueArray);
      const allOptionsSelected = newFarmIds.length === farmOptions.length;
      const allFarmsSelected = isEqual(newFarmIds, allOptionValueArray) || allOptionsSelected;
      if (allFarmsSelected) {
        change('farmIds', allOptionValueArray);
      }
      if (!allFarmsSelected && allCentersSelected) {
        change('centerIds', null);
      } else if (allFarmsSelected) {
        change('centerIds', allOptionValueArray);
      }
    }
    onSyncValidate();
  }, [onSyncValidate, centerIds, isOrganization, farmOptions, centersSettings, change]);
  const handleApproveFromChange = useCallback((value: Opt<string>) => {
    onSyncValidate();
    value.onSome(date => {
      const [element] = document.getElementsByClassName(superUniqueClassName);
      element?.classList.remove('ant-picker-focused');
      setTimeout(() => approvedToRef.current?.focus());
      change('approvedFrom', date);
    });
  }, [onSyncValidate, approvedToRef, change]);
  useEffect(() => {
    if (!isEdit) {
      onOrganizationSearch('');
      handleRoleSearch('');
    }
  }, [onOrganizationSearch, handleRoleSearch, isEdit]);
  return <Form data-sentry-element="Form" data-sentry-component="SubjectFacilityFormBase" data-sentry-source-file="index.tsx">
            <div className={styles.formContainer}>
                <div className={styles.doubleWidth}>
                    <Fields.NumberSelect name="subjectId" label={subjectTitle} options={subjectOptions} disabled={submitting || isEdit} onFieldChange={handleSubjectIdChange} onSearch={onOrganizationSearch} asyncSearch isRequired ignoreSearchMinLength data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                {isOrganization && centersSettings && <div className={styles.doubleWidth}>
                    <Fields.NumberMultiSelect name="centerIds" label={centersSettings.centerTitle} options={centersSettings.centerOptions} disabled={submitting || isEdit || !subjectId || centersSettings.centersAreEmpty} fieldIsLoading={loading.includes('getCenters')} selectAllOption={allCentersOption} onFieldChange={handleCenterIdsChange} isRequired={centersSettings.isRequired} ignoreSearchMinLength />
                </div>}
                <div className={styles.doubleWidth}>
                    <Fields.NumberMultiSelect name="farmIds" label={farmTitle} options={farmOptions} disabled={submitting || isEdit || !subjectId || farmsAreEmpty} fieldIsLoading={loading.includes('getFarms')} selectAllOption={allFarmsOption} onFieldChange={handleFarmIdsChange} isRequired={farmsAreRequired} ignoreSearchMinLength data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div className={styles.doubleWidth}>
                    <Fields.NumberMultiSelect name="roleIds" label={t('roles')} options={roleOptions} disabled={submitting || isEdit} onFieldChange={onSyncValidate} onSearch={handleRoleSearch} asyncSearch isRequired ignoreSearchMinLength data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div>
                    <Fields.DateInput name="approvedFrom" label={t('approvedFrom')} onFieldChange={handleApproveFromChange} isRequired clearable dontIncludePast disabled={isEdit} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div>
                    <Fields.DateInput name="approvedTo" label={t('approvedTo')} onFieldChange={onSyncValidate} clearable dontIncludePast inputRef={approvedToRef} minDate={approveFromMoment} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
            </div>
            <FooterButtons submitting={submitting} valid={valid} pristine={pristine} onCancel={onCancel} asyncValidating={asyncValidating} submitTitle={isEdit ? tCommon('edit') : tCommon('add')} data-sentry-element="FooterButtons" data-sentry-source-file="index.tsx" />
        </Form>;
};
export const SubjectFacilityForm = memo(SubjectFacilityFormBase);