import {opt} from 'ts-opt';

import {AssociationRole} from 'api/gen/AssociationRole';
import {RoleApproval} from 'api/gen/RoleApproval';

import {AssociationRoleTableType} from '../../types/association-role-table-type';

const hasOnlyOneChild = (roles: Array<RoleApproval>): boolean => roles.length === 1;

// eslint-disable-next-line max-lines-per-function
export const rowMapper = ({
    roles,
    associationId,
    associationName,
    rowId,
}: AssociationRole): AssociationRoleTableType => {
    const getFirstLevelProp = <P extends keyof RoleApproval>(prop: P): RoleApproval[P] | null =>
        opt(roles).filter(hasOnlyOneChild).headIn().prop(prop).orNull();

    return ({
        associationId,
        associationName,
        rowId,
        roles,
        id: rowId,
        children: hasOnlyOneChild(roles)
            ? undefined
            : roles.map((role: RoleApproval): AssociationRoleTableType => ({
                rowId: role.rowId,
                userProfileSubjectRoleId: role.userProfileSubjectRoleId,
                roleName: role.roleName,
                roleCode: role.roleCode,
                approvedTo: role.approvedTo,
                approvedFrom: role.approvedFrom,
                associationName,
                associationId,
                roles: [role],
                roleId: role.roleId,
                id: role.rowId,
            })),
        approvedFrom: getFirstLevelProp('approvedFrom'),
        approvedTo: getFirstLevelProp('approvedTo'),
        roleCode: getFirstLevelProp('roleCode'),
        roleName: getFirstLevelProp('roleName'),
        userProfileSubjectRoleId: getFirstLevelProp('userProfileSubjectRoleId'),
        roleId: getFirstLevelProp('roleId'),
    });
};
