import {none, Opt} from 'ts-opt';

import {Language} from 'api/gen/Language';
import {ModuleGridSettings} from 'api/gen/ModuleGridSettings';
import {PagedModelAssociationRole} from 'api/gen/PagedModelAssociationRole';
import {PagedModelBreederRole} from 'api/gen/PagedModelBreederRole';
import {PagedModelOrganizationRole} from 'api/gen/PagedModelOrganizationRole';
import {PagedModelOtherSubjectRole} from 'api/gen/PagedModelOtherSubjectRole';
import {PagedModelUserProfileContact} from 'api/gen/PagedModelUserProfileContact';
import {PagedModelUserSubjectView} from 'api/gen/PagedModelUserSubjectView';
import {Principal} from 'api/gen/Principal';
import {UserProfile} from 'api/gen/UserProfile';
import {defaultLanguage} from 'app/consts';

export interface UserState {
    isUserLoading: boolean;
    currentUser: Opt<Principal>;
    language: Language;
    appSettingsList: Opt<Array<ModuleGridSettings>>;
    previousPathname: Opt<string>;
    currentPathname: Opt<string>;
    userProfile: Opt<UserProfile>;
    userMemberOfSubjects: Opt<PagedModelUserSubjectView>;
    userContacts: Opt<PagedModelUserProfileContact>;
    userAssociations: Opt<PagedModelAssociationRole>;
    userOtherSubjects: Opt<PagedModelOtherSubjectRole>;
    userOrganizations: Opt<PagedModelOrganizationRole>;
    userBreeders: Opt<PagedModelBreederRole>;
}

export const initialUserState: UserState = {
    isUserLoading: false,
    currentUser: none,
    language: defaultLanguage,
    appSettingsList: none,
    previousPathname: none,
    currentPathname: none,
    userProfile: none,
    userAssociations: none,
    userBreeders: none,
    userContacts: none,
    userMemberOfSubjects: none,
    userOrganizations: none,
    userOtherSubjects: none,
};
