import { ToolbarButton, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { StableToChoose } from 'api/gen/StableToChoose';
import { useOurTranslation } from 'app/translations';
import { ConnectedTable } from 'utils/tables';
import { simpleAnimalBrowserSelector } from '../../model';
import { farmStablesColumns } from './columns';
interface Props {
  exportInProgress?: boolean;
  exportDisabled?: boolean;
  getStables(tableDataRequest: Opt<TableDataRequest<StableToChoose>>): void;
  exportToCsv(): void;
}
const pathnamesForRefresh: Array<RegExp> = [/^\/animal-browser\/cow-browser\//, /^\/animal-browser\/calf-browser\//];
const StablesTableBase: FC<Props> = props => {
  const {
    exportInProgress,
    exportDisabled,
    getStables,
    exportToCsv
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('stableTableTooltip');
  const productionAreasInCentralEvidenceOptions = useSelector(simpleAnimalBrowserSelector.productionAreasInCentralEvidenceOptions);
  const headerButtons: Array<ToolbarButton> = useMemo(() => [{
    disabled: exportDisabled,
    onClick: exportToCsv,
    title: tCommon('downloadCsv'),
    id: 'exportCalves',
    role: 'export',
    loading: exportInProgress
  }], [exportDisabled, exportInProgress, exportToCsv, tCommon]);
  return <ConnectedTable tableId="stableToChoose" columns={farmStablesColumns(productionAreasInCentralEvidenceOptions.orElse([]), t)} onFetchTableData={getStables} pathnamesForRefresh={pathnamesForRefresh} rowDataPageSelector={simpleAnimalBrowserSelector.stablePage} enableTableSettings enableTableUserSettings paginationPosition="both" withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter headerButtons={headerButtons} data-sentry-element="ConnectedTable" data-sentry-component="StablesTableBase" data-sentry-source-file="index.tsx" />;
};
export const StablesTable = memo(StablesTableBase);