import {allObjectPaths, Dictionary} from '@fl/cmsch-fe-library';
import i18next, {InitOptions, ResourceLanguage} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as iots from 'io-ts';
import {fromPairs, path} from 'lodash/fp';
import {initReactI18next} from 'react-i18next';
import {opt} from 'ts-opt';

import {LanguageSchema} from 'api/gen/Language';
import {Config} from 'app/config';
import {defaultLanguage} from 'app/consts';
import {checkTypography} from 'utils/check-typography';

import {translations} from './translations';

const options: InitOptions = {
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    resources: translations,
    debug: Config.isDevelopment,
    interpolation: {
        escapeValue: false, // not needed for react
    },
    react: {
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
    },
};

// eslint-disable-next-line import/no-named-as-default-member
void i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(options);

export const i18n = i18next;

const languageG = LanguageSchema.is(i18n.language) ? i18n.language : defaultLanguage;

interface NestedStringObject {
    [_: string]: string | NestedStringObject;
}

const flattenObject = (x: NestedStringObject): Dictionary<string, string> =>
    fromPairs(allObjectPaths(x).map(p => [p, path(p)(x)]));

opt(i18n.getDataByLanguage(i18n.language) as unknown as ResourceLanguage) // Incorrect return type from library
    .map(flattenObject)
    .noneIf(_ => !Config.isDevelopment)
    .narrow(iots.record(iots.string, iots.string).is)
    .onSome(x => checkTypography(languageG, x));
