import {NullableKeys} from '@fl/cmsch-fe-library';

import {AuthDiscriminator} from 'api/gen/AuthDiscriminator';
import {CreatePermission} from 'api/gen/CreatePermission';

export const createOrUpdatePermissionFormName = 'permissions';

export interface CreateOrUpdatePermissionFormValues {
    actionName: string;
    code: string;
    comment: string;
    controllerName: string;
    discriminator: AuthDiscriminator | null;
    userEnable: boolean;
}

export const createOrUpdatePermissionFormValues: NullableKeys<CreatePermission> = {
    actionName: '',
    code: '',
    comment: '',
    controllerName: '',
    discriminator: null,
    userEnable: false,
};
