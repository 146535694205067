import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {CreateOrUpdateGridUserSettings} from 'api/gen/CreateOrUpdateGridUserSettings';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {tablesAction} from 'utils/tables';

import {SetUserSettingsAction} from '../action';

const te = t('user/sagas');

// TODO: https://redmine.favorlogic.com/issues/15933
function* execute({payload: {userSettings, options, defaultSettingsId}}: SetUserSettingsAction): SagaIterator {
    const appSettings: CreateOrUpdateGridUserSettings = {
        gridSettingsId: defaultSettingsId,
        columnSettings: userSettings.columnSettings,
        pageSize: userSettings.pageSize,
        name: userSettings.name,
        filters: userSettings.filters,
        sort: userSettings.sort,
    };

    const response = yield* call(Api.createOrUpdateUserSettings, appSettings);

    if (response.isSuccess) {
        yield* put(showSuccess(te('saveUserSettings'), te('userSettingsSaved')));
        if (options?.tableId) {
            yield* put(tablesAction.getTableSettings(
                options.tableId,
                'FORCE_LOAD',
                options.dontSetActive,
                userSettings.name,
            ));
        }
    } else {
        yield putAll(showBeError(response, te('saveUserSettings')));
    }
}

export function* setUserSettingsSaga(): SagaIterator {
    yield takeLatestF('user/SET_USER_SETTINGS', execute);
}
