import {takeLatestF, putAll, extractFormErrorsFromResponse, showInfo} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt, genNakedPropOrCrash} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ExteriorTraitIdealWeight} from 'api/gen/ExteriorTraitIdealWeight';
import {TraitsWeights} from 'api/gen/TraitsWeights';
import {isNullableNumberPair, NullableNumberPair} from 'app/mating/types/nullable-number-pair';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {matingBullPreferencesFormName} from '../../types/mating-bull-preferences-form-values';
import {
    matingParamsSelectionFormName,
    MatingParamsSelectionFormValues,
} from '../../types/mating-params-selection-form-values';
import {getAdjustedMatingScoreResult} from '../../utils/get-adjusted-mating-score-result';
import {GetMatingScoreAction, matingAction} from '../action';

const te = t('mating/sagas');

const relativePairsText = (count: number): string =>
    `${te('inbreedingExceeded')} ${count} ${te('pairs')}`;

const transformIdealWeight = (nullableNumberPair: NullableNumberPair): ExteriorTraitIdealWeight => ({
    ideal: nullableNumberPair[0],
    weight: opt(nullableNumberPair[1]).orCrash('ideal not set'),
});

// eslint-disable-next-line max-lines-per-function
const transformFormToDto = (form: MatingParamsSelectionFormValues): TraitsWeights => {
    const getOrCrash = genNakedPropOrCrash(form);

    const getExtTrait =
    (fieldName: keyof MatingParamsSelectionFormValues): ExteriorTraitIdealWeight =>
        opt(form[fieldName])
            .narrow(isNullableNumberPair)
            .map(transformIdealWeight)
            .orCrash(`${fieldName} not set`);

    return {
        inbreedingCoefficient: getOrCrash('RxyMaxU'),

        // left column
        productionTraitsWeights: {
            milkWeight: getOrCrash('VhMlk'),
            fatWeight: getOrCrash('VhTuk'),
            proteinWeight: getOrCrash('VhBlk'),
        },

        healthTraitsWeights: {
            somaticCellsWeight: getOrCrash('VhSB'),
            clinicalMastitisWeight: getOrCrash('VhKM'),
            limbDiseaseWeight: getOrCrash('VhOCD'),
        },

        functionalTraitsWeights: {
            milkabilityWeight: getOrCrash('VhDoj'),
            heifersFertilityWeight: getOrCrash('VhPld'),
            longevityWeight: getOrCrash('VhDlh'),
            firstChildbirthDifficultyWeight: getOrCrash('VhOOP1'),
        },

        // middle column
        exteriorTraitsWeight: {
            frame: getExtTrait('TRA'),
            chestWidth: getExtTrait('SHR'),
            bodyDepth: getExtTrait('HLT'),
            edginess: getExtTrait('HRA'),
            backAngle: getExtTrait('SKZ'),
            backWidth: getExtTrait('SRZ'),
            rearPosture: getExtTrait('PZZ'),
            sidePosture: getExtTrait('PZB'),
            hoovesAngle: getExtTrait('PAZ'),
            foreUdderAttachment: getExtTrait('PUV'),
            frontTeatsPlacement: getExtTrait('RPS'),
            teatsLength: getExtTrait('DST'),
            udderDepth: getExtTrait('HLV'),
            rearUdderAttachmentHeight: getExtTrait('VZU'),
            suspensoryLigament: getExtTrait('ZAV'),
            rearTeatsPlacement: getExtTrait('RZS'),
            rearUdderAttachmentWidth: getExtTrait('SZU'),
            bonesQuality: getExtTrait('KVK'),
            walkQuality: getExtTrait('CHO'),
            fitness: getExtTrait('KND'),
        },

        // right column
        productionGroupWeight: getOrCrash('VhProd'),
        exteriorGroupWeight: getOrCrash('VhExt'),
        healthGroupWeight: getOrCrash('VhZdr'),
        functionalGroupWeight: getOrCrash('VhFnkcn'),
    };
};

function* execute(_: GetMatingScoreAction): SagaIterator {
    yield* put(formHelpers.startSubmit(matingParamsSelectionFormName));

    const matingParamsSelectionFormValues = (yield* select(formHelpers.formValues(matingParamsSelectionFormName)))
        .orCrash('no mating params selection form values');
    const response = yield* call(Api.getMatingScore, transformFormToDto(matingParamsSelectionFormValues));

    const title = te('loadMatingScore');

    if (response.isSuccess) {
        yield* put(showInfo(title, relativePairsText(response.data.sumOfRelatedPairs), false, 'mating-score'));
        yield* put(matingAction.getMatingScoreSuccess(getAdjustedMatingScoreResult(response.data)));
        yield* put(formHelpers.reset(matingBullPreferencesFormName));
        yield* put(matingAction.setActiveTab('result'));
        yield* put(formHelpers.stopSubmit(matingParamsSelectionFormName, {}));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmitUnsafe(
            matingParamsSelectionFormName,
            extractFormErrorsFromResponse(response),
        ));
    }
}

export function* getMatingScoreSaga(): SagaIterator {
    yield takeLatestF('mating/GET_MATING_SCORE', execute);
}
