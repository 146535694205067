import {AnyTableColumn} from '@fl/cmsch-fe-library';
import {ReactNode} from 'react';

import {TFunction} from 'app/translations';

import {getAnimalBrowserLink} from '../../utils';

export interface RecalcMatingPrefsRow {
    id: number;
    rowName?: string; // either line registry value or total label
    bullName?: string;
    percentage?: ReactNode;
    rank1Count?: number;
    rank1Share?: number;
    rank2Count?: number;
    rank2Share?: number;
    rank3Count?: number;
    rank3Share?: number;
    rank4Count?: number;
    rank4Share?: number;
    rank5Count?: number;
    rank5Share?: number;
}

// eslint-disable-next-line max-lines-per-function
export const buildMatingPreferencesColumns = (
    t: TFunction<'mating/resultTab'>,
): Array<AnyTableColumn<RecalcMatingPrefsRow>> => [
    {
        field: 'rowName',
        column: t('lineRegistry'),
        type: 'hyperlink',
        valueToUrl: (value: string) => value === t('total') ? null : getAnimalBrowserLink(value),
        openInNewTab: true,
        width: 100,
        disableFilter: true,
    },
    {
        field: 'bullName',
        column: t('bullName'),
        type: 'string',
        width: 100,
        disableFilter: true,
    },
    {
        field: 'percentage',
        column: '%',
        type: 'reactNode',
        width: 90,
        disableFilter: true,
    },
    {
        column: t('bull', {number: 1}),
        type: 'grouping',
        subColumns: [
            {
                field: 'rank1Count',
                column: t('frequency'),
                type: 'number',
                disableFilter: true,
            },
            {
                field: 'rank1Share',
                column: t('share'),
                type: 'number',
                disableFilter: true,
            },
        ],
    },
    {
        column: t('bull', {number: 2}),
        type: 'grouping',
        subColumns: [
            {
                field: 'rank2Count',
                column: t('frequency'),
                type: 'number',
                disableFilter: true,
            },
            {
                field: 'rank2Share',
                column: t('share'),
                type: 'number',
                disableFilter: true,
            },
        ],
    },
    {
        column: t('bull', {number: 3}),
        type: 'grouping',
        subColumns: [
            {
                field: 'rank3Count',
                column: t('frequency'),
                type: 'number',
                disableFilter: true,
            },
            {
                field: 'rank3Share',
                column: t('share'),
                type: 'number',
                disableFilter: true,
            },
        ],
    },
    {
        column: t('bull', {number: 4}),
        type: 'grouping',
        subColumns: [
            {
                field: 'rank4Count',
                column: t('frequency'),
                type: 'number',
                disableFilter: true,
            },
            {
                field: 'rank4Share',
                column: t('share'),
                type: 'number',
                disableFilter: true,
            },
        ],
    },
    {
        column: t('bull', {number: 5}),
        type: 'grouping',
        subColumns: [
            {
                field: 'rank5Count',
                column: t('frequency'),
                type: 'number',
                disableFilter: true,
            },
            {
                field: 'rank5Share',
                column: t('share'),
                type: 'number',
                disableFilter: true,
            },
        ],
    },
];
