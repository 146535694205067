import {convertAllErrorsFromBeToFormError, putAll, takeLatestF, validateSchema} from '@fl/cmsch-fe-library';
import {keys} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {isEmpty, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ContactValueSchema} from 'api/gen/ContactValue';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {ValidateContactErrorsSchema} from '../../types/errors/validate-user-contact-errors';
import {userContactFormName} from '../../types/user-contact-form-values';
import {ValidateUserContactAction} from '../action';

// eslint-disable-next-line max-lines-per-function
function* execute(_: ValidateUserContactAction): SagaIterator {
    const {
        value,
        contactType,
        userProfileId,
    } = (yield* select(formHelpers.formValues(userContactFormName))).orCrash(`no ${userContactFormName} form values`);
    const pristine = yield* select(formHelpers.isPristine(userContactFormName));

    if (
        !ContactValueSchema.is(value)
        || isEmpty(contactType)
        || isEmpty(userProfileId)
        || pristine
    ) return; // not valid

    yield* put(formHelpers.startAsyncValidation(userContactFormName));

    const response = yield* call(
        Api.validateUserProfileContact,
        {
            value: opt(value).orCrash('Users contact form: value value not found'),
            contactType: opt(contactType).orCrash('Users contact form: contactType value not found'),
            userProfileId: opt(userProfileId).orCrash('Users contact form: userProfileId value not found'),
        },
    );

    if (!response.isSuccess) {
        if (response.isBadRequest) {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            const validErrors = validateSchema(ValidateContactErrorsSchema, 'FE')(errors.errors);
            yield* put(formHelpers.stopAsyncValidation(userContactFormName, validErrors));
            const fieldsWithNewErrors = keys(validErrors) as Array<keyof typeof validErrors>;

            if (!isEmpty(fieldsWithNewErrors)) {
                yield* put(formHelpers.touch(userContactFormName, ...fieldsWithNewErrors));
            }
        } else {
            yield putAll(showBeError(response, t('admin/sagas')('updateContact')));
        }
    } else {
        yield* put(formHelpers.stopAsyncValidation(userContactFormName, {}));
    }
}

export function* validateUserContactSaga(): SagaIterator {
    yield takeLatestF('userDetail/VALIDATE_USER_CONTACT', execute);
}
