import {Nullable} from '@fl/cmsch-fe-library';

export const createAnimalListFormName = 'createAnimalList';

export interface CreateAnimalListFormValues {
    name: Nullable<string>;
}

export const initialCreateAnimalListFormValues: CreateAnimalListFormValues = {
    name: null,
};
