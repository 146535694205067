import {setItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {none, opt, pipe} from 'ts-opt';

import {AppAction} from 'app/setup';

import {AnimalBrowserState, initialAnimalBrowserState} from './state';

const stateO = O.optic<AnimalBrowserState>();

export const animalBrowserReducer = (
    state: AnimalBrowserState = initialAnimalBrowserState,
    action: AppAction,
): AnimalBrowserState => {
    switch (action.type) {
        case 'animalBrowser/GET_STABLES_DATA_SUCCESS':
            return O.set(stateO.prop('stablesPage'))(action.payload)(state);

        case 'animalBrowser/GET_COW_BROWSER_DATA_SUCCESS':
            return O.set(stateO.prop('cowBrowserPage'))(action.payload)(state);

        case 'animalBrowser/SET_STABLE_NAME':
            return O.set(stateO.prop('stableInfo'))(opt(action.payload))(state);

        case 'animalBrowser/CLEAR_STABLE_NAME':
            return O.set(stateO.prop('stableInfo'))(initialAnimalBrowserState.stableInfo)(state);

        case 'animalBrowser/GET_CALF_BROWSER_DATA_SUCCESS':
            return O.set(stateO.prop('calfBrowserPage'))(action.payload)(state);

        case 'animalBrowser/SET_FARM_NAME':
            return O.set(stateO.prop('farmInfo'))(opt(action.payload))(state);

        case 'animalBrowser/CLEAR_FARM_NAME':
            return O.set(stateO.prop('farmInfo'))(initialAnimalBrowserState.farmInfo)(state);

        case 'animalBrowser/GET_PRODUCTION_AREAS_SUCCESS': {
            const {productionAreasInCentralEvidenceOptions} = action.payload;

            return O.set(stateO.prop('productionAreasInCentralEvidenceOptions'))(
                opt(productionAreasInCentralEvidenceOptions),
            )(state);
        }

        case 'animalBrowser/START_EXPORT':
            return O.set(stateO.prop('exportInProgress'))(true)(state);

        case 'animalBrowser/EXPORT_FINISHED':
            return O.set(stateO.prop('exportInProgress'))(false)(state);

        case 'animalBrowser/GET_CAB_FEMALES_SUCCESS': {
            const {tableData} = action.payload;

            return O.set(stateO.prop('cabFemalePage'))(tableData)(state);
        }

        case 'animalBrowser/SET_CAB_FEMALES_EXIST': {
            const {isFull} = action.payload;

            return O.set(stateO.prop('cabFemalesExistInSession'))(isFull)(state);
        }

        case 'animalBrowser/SET_LOADING': {
            const {id, loading} = action.payload;

            return O.modify(stateO.prop('loading'))(setItem(loading)(id))(state);
        }

        case 'animalBrowser/GET_CAB_CALVES_SUCCESS': {
            const {tableData} = action.payload;

            return O.set(stateO.prop('cabCalfPage'))(tableData)(state);
        }

        case 'animalBrowser/SET_CAB_CALVES_EXIST': {
            const {isFull} = action.payload;

            return O.set(stateO.prop('cabCalvesExistInSession'))(isFull)(state);
        }
        case 'animalBrowser/GET_ORGANIZATIONS_PT_OPTIONS_SUCCESS':
            return O.set(stateO.prop('organizationsPtOptions'))(opt(action.payload))(state);

        case 'animalBrowser/GET_ORGANIZATION_INS_OPTIONS_SUCCESS':
            return O.set(stateO.prop('organizationsInsOptions'))(opt(action.payload))(state);

        case 'animalBrowser/GET_ORGANIZATION_CENTER_PT_OPTIONS_SUCCESS':
            return O.set(
                stateO.prop('organizationsCenterPtOptions'),
            )(opt(action.payload))(state);

        case 'animalBrowser/GET_ORGANIZATION_CENTER_INS_OPTIONS_SUCCESS':
            return O.set(stateO.prop('organizationsCenterInsOptions'))(opt(action.payload))(state);

        case 'animalBrowser/GET_DISTRICT_CODES_OPTIONS_SUCCESS':
            return O.set(stateO.prop('districtCodesOptions'))(opt(action.payload))(state);

        case 'animalBrowser/GET_BREEDER_OPTIONS_SUCCESS':
            return O.set(stateO.prop('breederOptions'))(opt(action.payload))(state);

        case 'animalBrowser/GET_FARM_OPTIONS_SUCCESS':
            return O.set(stateO.prop('farmOptions'))(opt(action.payload))(state);

        case 'animalBrowser/GET_STABLE_OPTIONS_SUCCESS':
            return O.set(stateO.prop('stableOptions'))(opt(action.payload))(state);

        case 'animalBrowser/CLEAR_DATA':
            return pipe(
                state,
                O.set(stateO.prop('stableOptions'))(none),
                O.set(stateO.prop('farmOptions'))(none),
                O.set(stateO.prop('breederOptions'))(none),
                O.set(stateO.prop('districtCodesOptions'))(none),
                O.set(stateO.prop('organizationsCenterInsOptions'))(none),
                O.set(stateO.prop('organizationsCenterPtOptions'))(none),
                O.set(stateO.prop('organizationsInsOptions'))(none),
                O.set(stateO.prop('organizationsPtOptions'))(none),
                O.set(stateO.prop('cabCalvesExistInSession'))(false),
                O.set(stateO.prop('cabFemalesExistInSession'))(false),
            );

        case 'animalBrowser/FIRST_CALVE_LOAD':
            return O.set(stateO.prop('firstCabCalvesLoad'))(action.payload)(state);

        case 'animalBrowser/FIRST_BREEDING_FEMALE_LOAD':
            return O.set(stateO.prop('firstCabBreedingFemalesLoad'))(action.payload)(state);

        case 'animalBrowser/TOGGLE_CREATE_ANIMALS_LIST_MODAL':
            return O.modify(stateO.prop('createAnimalListsModalVisible'))(value => !value)(state);

        case 'animalBrowser/TOGGLE_OVERVIEW_ANIMALS_LIST_MODAL':
            return O.modify(stateO.prop('overviewListModalVisible'))(value => !value)(state);

        case 'animalBrowser/TOGGLE_OVERRIDE_ANIMALS_LIST_MODAL': {
            const {animalListId} = action.payload;

            return pipe(
                state,
                O.modify(stateO.prop('overwriteListNameModalVisible'))(value => !value),
                O.set(stateO.prop('overwriteAnimalListId'))(animalListId),
            );
        }

        case 'animalBrowser/TOGGLE_CREATE_ANIMALS_LIST_FROM_IDS_MODAL':
            return O.modify(stateO.prop('createAnimalListsFromIdsModalVisible'))(value => !value)(state);

        case 'animalBrowser/TOGGLE_OVERRIDE_ANIMALS_LIST_FROM_IDS_MODAL': {
            const {animalListId} = action.payload;

            return pipe(
                state,
                O.modify(stateO.prop('overwriteListFromIdsNameModalVisible'))(value => !value),
                O.set(stateO.prop('overwriteAnimalListFromIdsId'))(animalListId),
            );
        }

        case 'animalBrowser/GET_ANIMAL_LISTS_SUCCESS': {
            const {animalLists, animalType} = action.payload;

            return pipe(
                state,
                O.set(stateO.prop('animalLists'))(opt(animalLists)),
                O.set(stateO.prop('animalListsTag'))(opt(animalType)),
            );
        }

        case 'animalBrowser/SET_SELECTED_ANIMAL_IDS': {
            const {animalIds} = action.payload;

            return O.set(stateO.prop('selectedAnimalIds'))(animalIds)(state);
        }

        case 'animalBrowser/TOGGLE_ADD_ANIMALS_MODAL':
            return O.modify(stateO.prop('addAnimalsModalVisible'))(value => !value)(state);

        case 'animalBrowser/GET_AVAILABLE_ANIMALS_SUCCESS': {
            const {availableAnimals} = action.payload;

            return O.set(stateO.prop('availableAnimals'))(opt(availableAnimals))(state);
        }

        case 'animalBrowser/TOGGLE_ADD_ANIMALS_FROM_DB_VISIBILITY':
            return O.modify(stateO.prop('addAnimalsFromDBModalVisible'))(value => !value)(state);

        default:
            return state;
    }
};
