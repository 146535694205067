import { ActionSettings, beDateFormat, ToolbarButton, TableDataRequest } from '@fl/cmsch-fe-library';
import moment from 'moment';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { Milkability } from 'api/gen/Milkability';
import { milkabilityLoadingSelector, simpleMilkabilitySelector } from 'app/milkability/model/selector';
import { isDateWithinTwoControlYears, getControlInterval } from 'app/milkability/utils/is-date-within-two-control-years';
import { useOurTranslation } from 'app/translations';
import useCurrentUserHasAnyPermission from 'app/user/utils/use-current-user-has-any-permission';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { milkabilityPermissions } from '../../constants/permissions';
import { milkabilityOverviewColumns } from './columns';
interface Props {
  getMilkabilityReports(tableDataRequest: Opt<TableDataRequest<Milkability>>): void;
  exportMilkabilityReports(): void;
  deleteMilkabilityReport(id: number): void;
  goToNewReport(): void;
  editMilabilityReport(_id: number, row: Milkability): void;
}
const pathnamesForRefresh: Array<RegExp> = [/\/milkability\/.+/];
const OverviewTableBase: FC<Props> = props => {
  const {
    getMilkabilityReports,
    exportMilkabilityReports,
    deleteMilkabilityReport,
    goToNewReport,
    editMilabilityReport
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('milkability/overviewTable');
  const today = useMemo(moment, []);
  const {
    startDate,
    endDate
  } = useMemo(() => getControlInterval(today), [today]);
  const canUpdate = useCurrentUserHasAnyPermission([milkabilityPermissions.updateTechnician, milkabilityPermissions.updateLeaderTechnician, milkabilityPermissions.updateAdmin]);
  const actionSettings = useMemo((): ActionSettings<Milkability> => canUpdate ? {
    extraActions: [{
      id: 'delete',
      role: 'delete',
      callback: deleteMilkabilityReport,
      disabled: (_id: number | string, row: Milkability): boolean => !isDateWithinTwoControlYears(moment(row.examinationDate, beDateFormat, true), today),
      disabledTitle: t('deleteDisabledTitle', {
        fromYear: startDate.year(),
        toYear: endDate.year()
      })
    }, {
      id: 'edit',
      role: 'edit',
      callback: editMilabilityReport,
      disabled: (_id: number | string, row: Milkability): boolean => !isDateWithinTwoControlYears(moment(row.examinationDate, beDateFormat, true), today),
      disabledTitle: t('editDisabledTitle', {
        fromYear: startDate.year(),
        toYear: endDate.year()
      })
    }]
  } : {}, [canUpdate, deleteMilkabilityReport, editMilabilityReport, t, startDate, endDate, today]);
  const handleExport = useCallback(() => {
    exportMilkabilityReports();
  }, [exportMilkabilityReports]);
  const {
    exportingMilkabilityOverview
  } = useSelector(milkabilityLoadingSelector);
  const headerButtons = useMemo((): Array<ToolbarButton> => [{
    title: t('newReport'),
    role: 'create',
    onClick: goToNewReport,
    hidden: !canUpdate
  }, {
    title: tCommon('downloadCsv'),
    role: 'export',
    onClick: handleExport,
    loading: exportingMilkabilityOverview,
    testId: 'exportCSV'
  }], [canUpdate, exportingMilkabilityOverview, goToNewReport, handleExport, t, tCommon]);
  return <ConnectedTable tableId="milkabilityOverview" columns={milkabilityOverviewColumns} onFetchTableData={getMilkabilityReports} rowDataPageSelector={simpleMilkabilitySelector.pageMilkability} enableTableSettings enableTableUserSettings paginationPosition="both" withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter actionSettings={actionSettings} headerButtons={headerButtons} pathnamesForRefresh={pathnamesForRefresh} emptyTableContent={canUpdate && <Button role="create" onClick={goToNewReport}>
                    {t('newReport')}
                </Button>} emptyTableMessages={{
    noRows: t('noMilkabilityReports')
  }} data-sentry-element="ConnectedTable" data-sentry-component="OverviewTableBase" data-sentry-source-file="index.tsx" />;
};
export const OverviewTable = memo(OverviewTableBase);