import {showError, AuthData, putAll} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {Language} from 'api/gen/Language';
import {Config} from 'app/config';
import {logger} from 'app/sentry-logger';
import {State} from 'app/setup';
import {LANGUAGES, t} from 'app/translations';
import {userRoutePaths} from 'app/user/routes/route-paths';
import {languageStore} from 'utils/language-store';
import {showBeError} from 'utils/show-be-error';

import {userAction} from '../action';
import {LegacyUserApi} from '../legacy-api';

export interface LocationState {
    redirectTo?: string;
}

export const buildLoginRouteState = (state: State): LocationState =>
    opt(state.router.location)
        .map(location => ({redirectTo: `${location.pathname}${location.search}`}))
        .orCrash('missing location');

function* legacyLogin(authData: AuthData): SagaIterator {
    const response = yield* call(LegacyUserApi.loginLegacy, authData);

    if (!response.isSuccess || response.data !== '') {
        yield* put(showError(
            'Přihlášení do původní aplikace',
            'Nepodařilo se přihlásit do původní aplikace.',
        ));
    }
}

export function* setLegacyLanguageCookie(language: Language): SagaIterator {
    const response = yield* call(
        Api.setLegacyLanguageCookie,
        {locale: LANGUAGES[language].legacyBeTag},
    );

    if (!response.isSuccess || response.data !== '') {
        yield* put(showError(
            'Nastavení jazyku pro iframe',
            'Nepodařilo se nastavit jazyk pro iframe',
        ));
    }
}

function* afterLegacyBeLoginSetup(showErrorIfExists: boolean): SagaIterator {
    const response = yield* call(Api.userIsLoggedLegacy);
    if (!response.isSuccess && showErrorIfExists) {
        yield putAll(showBeError(response, t('user/sagas')('loadCurrentUser')));
    }
}

function* legacyLoginAndSetLanguageCookie(
    language: Language,
    showErrorIfExists: boolean,
    auth?: AuthData,
): SagaIterator {
    if (!Config.disableLegacyLogin && auth) {
        yield* call(legacyLogin, auth);
        yield* call(afterLegacyBeLoginSetup, showErrorIfExists);
    }
    yield* call(setLegacyLanguageCookie, language);
}

export function* afterBeLoginSetup(showErrorIfExists: boolean, auth?: AuthData): SagaIterator {
    const response = yield* call(Api.getPrincipal);
    if (response.isSuccess) {
        const user = response.data;
        yield* put(userAction.getCurrentUserSuccess(user));
        logger.setUser(user.username);
        yield* call(legacyLoginAndSetLanguageCookie, user.language, showErrorIfExists, auth);

        languageStore.set(user.language);
        yield* put(userAction.setUiLanguage(user.language));
    } else if (showErrorIfExists) {
        yield putAll(showBeError(response, t('user/sagas')('loadCurrentUser')));
        const location = yield* select((state: State) => state.router.location);

        if (location?.pathname === userRoutePaths.login) return;

        const routeState = yield* select(buildLoginRouteState);

        yield* put(routerActions.push(userRoutePaths.login, routeState));
    }
}
