import {Dictionary} from '@fl/cmsch-fe-library';
import {sum, times, constant, take} from 'lodash/fp';
import {opt} from 'ts-opt';

import {LineRegistry} from 'api/gen/LineRegistry';
import {ScoringResult} from 'api/gen/ScoringResult';

import {BullCountsAndShares, BullRanking} from '../types/bull-counts-and-shares';

const numberOfRanks = 5;
const hundredPercent = 100;

type BullToRankCountsMap = Dictionary<LineRegistry, Array<number>>;

const buildBullToRankCountsMap = (scoringResults: Array<ScoringResult>): BullToRankCountsMap => {
    const bullToRankCountsMap: BullToRankCountsMap = {};

    scoringResults.forEach(scoringResult => {
        take(numberOfRanks, scoringResult.bulls)
            .forEach((bull, index) => {
                const bullRanksCounts = bullToRankCountsMap[bull.lineRegistry] ?? times(constant(0))(numberOfRanks);
                const bullRanksCount = opt(bullRanksCounts[index]).orCrash('bull count not present');

                bullRanksCounts[index] = bullRanksCount + 1;
                bullToRankCountsMap[bull.lineRegistry] = bullRanksCounts;
            });
    });

    return bullToRankCountsMap;
};

const buildSumOfBullsRankCounts = (bullToRankCountsMap: BullToRankCountsMap): Array<number> => {
    const sumOfBullsRankCounts: Array<number> = [];

    for (let index = 0; index < numberOfRanks; index++) {
        const allCountsInOneRank = Object.values(bullToRankCountsMap)
            .map(bullCount => opt(bullCount?.[index]).orCrash('bull count not present'));
        sumOfBullsRankCounts[index] = sum(allCountsInOneRank);
    }

    return sumOfBullsRankCounts;
};

const buildBullRanking = (
    bullRanksCounts: Array<number> | undefined,
    sumOfBullsRankCounts: Array<number>,
): Array<[number, number]> =>
    opt(bullRanksCounts).orCrash('counts in ranks must be present')
        .map((countInRank, index) => [
            countInRank,
            Math.round(
                countInRank * hundredPercent / opt(sumOfBullsRankCounts[index]).orCrash('sum of bulls rank missing'),
            ),
        ]);

const compareBullRankCountsDescendingly = (
    xRankCounts: Array<number>,
    yRankCounts: Array<number>,
    rankIndex: number,
): number => {
    const countDifference = (yRankCounts[rankIndex] ?? 0) - (xRankCounts[rankIndex] ?? 0);

    return countDifference === 0 && rankIndex < numberOfRanks - 1
        ? compareBullRankCountsDescendingly(xRankCounts, yRankCounts, rankIndex + 1)
        : countDifference;
};

/**
 * Creates a sorted table where every bull from scoring results has its own row and columns are a tuple of
 * how many times the bull was present on the scoring position for any cow and its percentual share on the position.
 * Also returns number of all bulls on each scoring position.
 * @param scoringResults
 */
export const getSortedBullRankCountsFromScoringResults =
    (scoringResults: Array<ScoringResult>): BullCountsAndShares => {
        const bullToRankCountsMap = buildBullToRankCountsMap(scoringResults);
        const sumOfBullsRankCounts = buildSumOfBullsRankCounts(bullToRankCountsMap);

        const bullRankings: Array<BullRanking> =
        Object.entries(bullToRankCountsMap)
            .sort(([_xLinReg, xRankCounts], [_yLinReg, yRankCounts]) =>
                compareBullRankCountsDescendingly(xRankCounts ?? [], yRankCounts ?? [], 0))
            .map(([lineRegistry, bullRanksCounts]): BullRanking => ({
                lineRegistry,
                bullName: scoringResults.flatMap(x => x.bulls).find(b => b?.lineRegistry === lineRegistry)?.name,
                rankings: buildBullRanking(bullRanksCounts, sumOfBullsRankCounts),
            }));

        return {bullRankings, sumOfBullsRankCounts};
    };
