// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 992px){.src-app-mating-components-mating-tabs-___styles__hiddenItemsPadding___vwZ1V .ant-tabs-extra-content{margin-left:4px}}@media (max-width: 992px)and (min-width: 768px){.src-app-mating-components-mating-tabs-___styles__hiddenItemsPadding___vwZ1V .ant-tabs-extra-content{margin-right:3%}}
`, "",{"version":3,"sources":["webpack://./src/app/mating/components/mating-tabs/styles.sass"],"names":[],"mappings":"AAAA,0BAAyB,qGAAoD,eAAA,CAAA,CAAiB,gDAA+C,qGAAoD,eAAA,CAAA","sourcesContent":["@media(max-width: 992px){.hiddenItemsPadding :global .ant-tabs-extra-content{margin-left:4px}}@media(max-width: 992px)and (min-width: 768px){.hiddenItemsPadding :global .ant-tabs-extra-content{margin-right:3%}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hiddenItemsPadding": `src-app-mating-components-mating-tabs-___styles__hiddenItemsPadding___vwZ1V`
};
export default ___CSS_LOADER_EXPORT___;
