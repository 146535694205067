import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {animalBrowserAction, RenameAnimalListAction} from '../action';
import {getAnimalListVersion} from './get-animal-list-version';
import {getCabTableInfo} from './get-table-info';

const te = t('animalBrowserSagas');

function* execute({payload: {animalListId, name, animalType}}: RenameAnimalListAction): SagaIterator {
    const version = yield* call(getAnimalListVersion, animalListId);

    const {code, module} = getCabTableInfo(animalType);

    const response = yield* call(Api.validateAnimalList, {id: animalListId, name, module, code});

    const title = te('renameAnimalList');

    if (response.isSuccess) {
        const storingResponse = yield* call(Api.renameAnimalList, {name, version}, {animalListId});

        if (storingResponse.isSuccess) {
            yield* put(animalBrowserAction.getAnimalLists(animalType, true));
            yield* put(showSuccess(title, te('animalListRenamed')));
        } else {
            yield putAll(showBeError(storingResponse, title));
        }
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* renameAnimalListSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/RENAME_ANIMAL_LIST', execute);
}
