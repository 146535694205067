import {UserSubjectView} from 'api/gen/UserSubjectView';

import {MemberOfSubjectsTableType} from '../../types/member-of-subjects-table-type';

export const rowMapper = (row: UserSubjectView): MemberOfSubjectsTableType => ({
    approvedFrom: row.approvedFrom,
    code: row.code,
    subjectUserProfileId: row.subjectUserProfileId,
    approvedTo: row.approvedTo,
    businessName: row.businessName,
    id: row.subjectUserProfileId,
    subjectId: row.subjectId,
});
