import { Modal } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo } from 'react';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { createPathRegex } from 'utils/create-path-regex';
import { ConnectedTable } from 'utils/tables';
import { useRolesScreenSetup } from '../../hooks/use-role-screen-setup';
import { simpleAdminSelector } from '../../model/selector';
import { adminRoutePaths } from '../../routes/route-paths';
import { RoleForm } from '../role-form';
import { rolesColumns } from './columns';
const pathNamesForRefresh: Array<RegExp> = [createPathRegex(adminRoutePaths.roleDetail)];
const RolesTableBase: FC = _ => {
  const {
    isCreateModalVisible,
    id,
    headerButtons,
    actionSettings,
    t,
    getRoles,
    handleCancelCreateRole,
    handleCreateRole,
    openCreateRoleModal,
    handleCancelEditRole,
    handleSubmitEditRole
  } = useRolesScreenSetup();
  return <Fragment>
            <ConnectedTable tableId="roles" columns={rolesColumns} onFetchTableData={getRoles} rowDataPageSelector={simpleAdminSelector.rolesPage} actionSettings={actionSettings} headerButtons={headerButtons} withoutVerticalScrollbar pathnamesForRefresh={pathNamesForRefresh} paginationPosition="both" lazyLoadingLimit={20} showFulltextFilter enableTableSettings enableTableUserSettings highlightOnEdit emptyTableContent={<Button role="create" onClick={openCreateRoleModal}>
                        {t('role')}
                    </Button>} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
            <Modal title={t('newRole')} visible={isCreateModalVisible} onCancel={handleCancelCreateRole} footer={null} width={520} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
                <Ant.Row data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Ant.Col xs={24} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <RoleForm roleId={null} mode="create" onCancel={handleCancelCreateRole} onSubmit={handleCreateRole} data-sentry-element="RoleForm" data-sentry-source-file="index.tsx" />
                    </Ant.Col>
                </Ant.Row>
            </Modal>
            <Modal title={t('editRole')} visible={id !== null} onCancel={handleCancelEditRole} footer={null} width={520} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
                <Ant.Row data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Ant.Col xs={24} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <RoleForm roleId={id} mode="edit" onCancel={handleCancelEditRole} onSubmit={handleSubmitEditRole} data-sentry-element="RoleForm" data-sentry-source-file="index.tsx" />
                    </Ant.Col>
                </Ant.Row>
            </Modal>
        </Fragment>;
};
export const RolesTable = memo(RolesTableBase);