import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {CreateCriteriaListFormValues} from './create-criteria-list-form-values';

const te = t('mating/modalListName');

export const validate = (values: CreateCriteriaListFormValues): Errors<CreateCriteriaListFormValues> => {
    const validator = new EskotValidator(values);

    validator.nonEmpty('name', te('name'));

    return validator.generateErrorsObject();
};
