import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    tablesAction,
    getRequestedTablePageParams,
    simpleTableSelector,
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
} from 'utils/tables';

import {adminAction, GetUserContactsAction} from '../action';

function* execute({payload: {tableDataRequest, userProfileId}}: GetUserContactsAction): SagaIterator {
    if (tableDataRequest.isEmpty) return yield* put(adminAction.getUserContactsSuccess(none));

    yield* put(tablesAction.tableDataFetchStart('userContacts'));

    const tableState = yield* select(simpleTableSelector.tableState('userContacts'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'userContacts',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest: user detail contacts'),
        tableState,
    });
    const columnSettings = getRequestedTableColumnSettings<'userContacts'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.getUserContactsPage, filters, params, {userProfileId});

    if (response.isSuccess) {
        yield* put(adminAction.getUserContactsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('userContacts', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('userDetail/sagas')('loadUserContacts')));
        yield* put(tablesAction.tableDataFetchFailure('userContacts'));
    }
}

export function* getUserContactsSaga(): SagaIterator {
    yield takeLatestF('admin/GET_USER_CONTACTS', execute);
}
