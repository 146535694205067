/* eslint-disable max-lines */
import {TableColumn} from '@fl/cmsch-fe-library';

import {MaleHolsteinAnimalInList} from 'api/gen/MaleHolsteinAnimalInList';

import {getAnimalBrowserLink} from '../../utils';

export const matingHolsteinBullsColumns: Array<TableColumn<MaleHolsteinAnimalInList>> = [
    {
        field: 'lineRegistry',
        type: 'hyperlink',
        valueToUrl: getAnimalBrowserLink,
        openInNewTab: true,
        width: 130,
    }, {
        field: 'earTag',
        filterTypes: ['betweenEarTags'],
        type: 'string',
        width: 150,
    }, {
        field: 'birthDate',
        verticalName: true,
        type: 'date',
        dateFormat: 'date',
        width: 150,
        filterTypes: ['null'],
    }, {
        field: 'name',
        verticalName: true,
        type: 'string',
        width: 130,
        filterTypes: ['null'],
    }, {
        field: 'organizationCode',
        verticalName: true,
        type: 'string',
        width: 100,
    }, {
        field: 'rbvMilkKg',
        verticalName: true,
        type: 'number',
        width: 95,
        filterTypes: ['null'],
    }, {
        field: 'rbvFatKg',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'rbvFatPercentage',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'rbvProteinKg',
        verticalName: true,
        type: 'number',
        width: 120,
        filterTypes: ['null'],
    }, {
        field: 'rbvProteinPercentage',
        verticalName: true,
        type: 'number',
        width: 110,
        filterTypes: ['null'],
    }, {
        field: 'bvMilkKg',
        verticalName: true,
        type: 'number',
        width: 100,
        filterTypes: ['null'],
    }, {
        field: 'bvMilkKgReliability',
        verticalName: true,
        type: 'number',
        width: 120,
        filterTypes: ['betweenIntegers', 'equalsInteger', 'null'],
    }, {
        field: 'milkProductionIndex',
        verticalName: true,
        type: 'number',
        width: 100,
        filterTypes: ['null'],
    }, {
        field: 'bvFatKg',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'bvFatPercentage',
        verticalName: true,
        type: 'decimal',
        width: 80,
        fixedDecimals: 2,
        filterTypes: ['null'],
    }, {
        field: 'bvProteinKg',
        verticalName: true,
        type: 'number',
        width: 115,
        filterTypes: ['null'],
    }, {
        field: 'bvProteinPercentage',
        verticalName: true,
        type: 'decimal',
        width: 110,
        fixedDecimals: 2,
        filterTypes: ['null'],
    }, {
        field: 'sihIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'roboticMilkingIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'productionTraitsCode',
        verticalName: true,
        type: 'string',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'inbreedingCoefficient',
        verticalName: true,
        type: 'decimal',
        width: 100,
        fixedDecimals: 3,
    }, {
        field: 'rbvSomaticCells',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'exteriorTraitsCode',
        verticalName: true,
        type: 'string',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'exteriorReliability',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'rbvClinicalMastitis',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvLimbDisease',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvFrame',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvChestWidth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvBodyDepth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvEdginess',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvBackAngle',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvBackWidth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvRearPosture',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvSidePosture',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvHoovesAngle',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvForeUdderAttachment',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvFrontTeatsPlacement',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvTeatsLength',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvUdderDepth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvRearUdderAttachmentHeight',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvSuspensoryLigament',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvRearTeatsPlacement',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvRearUdderAttachmentWidth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvBonesQuality',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvWalkQuality',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvFitness',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    }, {
        field: 'rbvMilkStrength',
        verticalName: true,
        type: 'number',
        width: 60,
        filterTypes: ['null'],
    }, {
        field: 'rbvBodyStructure',
        verticalName: true,
        type: 'number',
        width: 60,
        filterTypes: ['null'],
    }, {
        field: 'rbvExtremities',
        verticalName: true,
        type: 'number',
        width: 95,
        filterTypes: ['null'],
    }, {
        field: 'rbvUdder',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    }, {
        field: 'rbvOverallCondition',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'extremitiesIndex',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'udderIndex',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'longevityIndex',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'rbvMothersChildbirthDifficulty',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    }, {
        field: 'rbvLongevity',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    }, {
        field: 'rbvHeifersFertility',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    }, {
        field: 'rbvDaughtersFertility',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    }, {
        field: 'rbvMilkability',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    }, {
        field: 'rbvFirstChildbirthDifficulty',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    }, {
        field: 'rbvAllChildBirthDifficulties',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    },
];
