import { Modal } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo } from 'react';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { useMemberOfSubjectsTabSetup } from '../../hooks/use-member-of-subjects-tab-setup';
import { MemberOfSubjectsTabProps } from '../../types/member-of-subjects-tab-props';
import { UserMemberForm } from '../user-member-form';
import { memberOfSubjectColumns } from './columns';
import { rowMapper } from './row-mapper';
const SubjectTableBase: FC<MemberOfSubjectsTabProps> = props => {
  const {
    actionSettings,
    formDataForInit,
    getMemberOfSubjects,
    handleCloseModal,
    handleOpenCreateModal,
    handleSubjectSearch,
    handleSubmitModal,
    headerButtons,
    isFromAdminModule,
    memberOfSubjectsSelector,
    modalTitle,
    pathnamesForRefresh,
    subjectOptions,
    t,
    userProfileId,
    validateAsync,
    visibleModal
  } = useMemberOfSubjectsTabSetup(props);
  return <Fragment>
            <ConnectedTable tableId={isFromAdminModule ? 'memberOfSubjects' : 'memberOfSubjectsProfile'} columns={memberOfSubjectColumns} onFetchTableData={getMemberOfSubjects} rowDataPageSelector={memberOfSubjectsSelector} rowDataToTableRowMapper={rowMapper} actionSettings={actionSettings} headerButtons={headerButtons} withoutVerticalScrollbar paginationPosition="both" pathnamesForRefresh={pathnamesForRefresh} lazyLoadingLimit={20} showFulltextFilter enableTableSettings enableTableUserSettings highlightOnEdit emptyTableContent={isFromAdminModule ? <Button role="create" onClick={handleOpenCreateModal}>
                        {t('memberOfSubject')}
                    </Button> : undefined} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
            {isFromAdminModule ? <Modal title={modalTitle} visible={visibleModal} onCancel={handleCloseModal} footer={null} width={768}>
                        <UserMemberForm userProfileId={userProfileId} onSubjectSearch={handleSubjectSearch} onSyncValidate={validateAsync} data={formDataForInit} onCancel={handleCloseModal} onSubmit={handleSubmitModal} subjectOptions={subjectOptions} />
                    </Modal> : undefined}
        </Fragment>;
};
export const SubjectTable = memo(SubjectTableBase);