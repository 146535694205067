import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {isNull, isEqual} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {earTagRegexGen} from 'api/gen/EarTag';
import {MilkedTotalRangeSchema} from 'api/gen/MilkedTotalRange';
import {MilkingTimeRangeSchema} from 'api/gen/MilkingTimeRange';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';

import {milkabilityReportFormName} from '../../components/milkability-report-form/form-values';
import {CalculateApmvAction, milkabilityAction} from '../action';

// eslint-disable-next-line max-lines-per-function
function* execute(_: CalculateApmvAction): SagaIterator {
    const milkabilityFormValues = (yield* select(formHelpers.formValues('milkabilityReport')))
        .orCrash('milkabilityFormValues are missing');

    const {earTag, examinationDate, milkedTotal, milkingTime} = milkabilityFormValues;
    const safeMilkingTime = opt(milkingTime);

    if (
        isNull(earTag)
        || !earTagRegexGen().test(earTag)
        || isNull(examinationDate)
        || !MilkedTotalRangeSchema.is(milkedTotal)
        || !MilkingTimeRangeSchema.is(milkingTime?.value)
        || safeMilkingTime.prop('secondsOverLimit').orElse(0) > 0
    ) return;

    yield* put(milkabilityAction.setLoading('validatingMilkabilityReportForm', true));

    const response = yield* call(Api.calculateApmv, {
        earTag,
        examinationDate,
        milkedTotal,
        milkingTime: safeMilkingTime.propOrCrash('value'),
    });

    if (response.isSuccess) {
        const afterResponseValues = (yield* select(formHelpers.formValues(milkabilityReportFormName)))
            .orCrash('milkabilityFormValues are missing');

        if (isEqual(milkabilityFormValues, afterResponseValues)) {
            yield* put(formHelpers.change('milkabilityReport', 'apmv', response.data.apmv));
        }
    } else {
        yield putAll(showBeError(response, t('milkability/sagas')('calculateAPMV')));
    }
    yield* put(milkabilityAction.setLoading('validatingMilkabilityReportForm', false));
}

export function* calculateApmvSaga(): SagaIterator {
    yield takeLatestF('milkability/CALCULATE_APMV', execute);
}
