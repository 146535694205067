import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { CabCalvesTable } from '../components/comprehensive-calves-table';
import { CabForm } from '../components/comprehensive-form';
import { CabModals } from '../components/comprehensive-modals';
import { animalBrowserAction } from '../model';
import { simpleAnimalBrowserSelector } from '../model/selector';
const calves = 'CALVES';
const ComprehensiveCalfBrowserBase: FC = _ => {
  const {
    t
  } = useOurTranslation('comprehensiveCalfBrowser');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(animalBrowserAction.clearData());
    dispatch(animalBrowserAction.filterCabCalves('FIRST_LOAD'));
    dispatch(animalBrowserAction.getAnimalLists(calves));
    return () => {
      dispatch(animalBrowserAction.firstCalveLoad());
    };
  }, [dispatch]);
  const title = t('title');
  const header = useMemo(() => <ScreenHeader title={title} moduleForTutorials="ANIMALS_BROWSER_ALL" />, [title]);
  const loading = useSelector(simpleAnimalBrowserSelector.loading);
  const sessionWasLoaded = useSelector(simpleAnimalBrowserSelector.firstCalvesLoad);
  const isCabCalvesSessionEmpty = useSelector(simpleAnimalBrowserSelector.isCabCalvesSessionEmpty);
  const cabCalvesLists = useSelector(simpleAnimalBrowserSelector.cabCalvesLists);
  const confirmAnimalListNameFromIdsLoading = loading.includes('confirmAnimalListNameFromIdsInProgress');
  const filterCabCalves = useCallback(() => {
    dispatch(animalBrowserAction.filterCabCalves());
  }, [dispatch]);
  return <Content browserTabTitle={t('pageTitle')} data-sentry-element="Content" data-sentry-component="ComprehensiveCalfBrowserBase" data-sentry-source-file="comprehensive-calf-browser.tsx">
            <Card title={header} titleSize="big" data-sentry-element="Card" data-sentry-source-file="comprehensive-calf-browser.tsx">
                <CabForm onSubmit={filterCabCalves} animalType="CALVES" animalsLists={cabCalvesLists} data-sentry-element="CabForm" data-sentry-source-file="comprehensive-calf-browser.tsx" />
                {sessionWasLoaded && !isCabCalvesSessionEmpty && <CabCalvesTable loading={loading} />}
                <CabModals isAnimalsPageEmpty={isCabCalvesSessionEmpty} animalsLists={cabCalvesLists} confirmAnimalListNameFromIdsLoading={confirmAnimalListNameFromIdsLoading} animalType="CALVES" data-sentry-element="CabModals" data-sentry-source-file="comprehensive-calf-browser.tsx" />
            </Card>
        </Content>;
};
export const ComprehensiveCalfBrowser = memo(ComprehensiveCalfBrowserBase);