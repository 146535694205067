import {DateStringNullable, Dictionary, NullableKeys, todayDate} from '@fl/cmsch-fe-library';

export const subjectFacilityForm = 'subjectFacilityForm';

export interface SubjectFacilityFormValues {
    approvedFrom: DateStringNullable;
    approvedTo: DateStringNullable;
    centerIds: Array<number>;
    farmIds: Array<number>;
    subjectId: number;
    roleIds: Array<number>;
    userProfileId: number;
    userProfileRoleIds: Array<number>;
}

export const subjectFacilityFormInitialValues = (userProfileId: number): NullableKeys<SubjectFacilityFormValues> => ({
    approvedFrom: todayDate(),
    approvedTo: null,
    centerIds: null,
    farmIds: null,
    subjectId: null,
    roleIds: [],
    userProfileId,
    userProfileRoleIds: [],
});

export interface SubjectFacilityFormEntryData {
    approvedFrom: DateStringNullable;
    approvedTo: DateStringNullable;
    subjectId: number;
    roleIds: Array<number>;
    userProfileId: number;
    userProfileRoleIds: Array<number>;
    centerIds: Array<number> | null;
    farmIds: Array<number>;
}

export type SubjectFacilityFormValuesErrors = Dictionary<keyof SubjectFacilityFormEntryData, string>;

export type SubjectFacilityFormType = 'BREEDER' | 'ORGANIZATION';
