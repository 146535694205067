import {Nullable, TripleAttributeValue} from '@fl/cmsch-fe-library';

interface MilkingTripleAttribute {
    milkingDayTime: Nullable<number>;
    milkingInterval: Nullable<number>;
    milkingCount: Nullable<number>;
}

export const convertApcReportToTripleAttributeValue = (x: MilkingTripleAttribute): TripleAttributeValue => ({
    left: x.milkingDayTime,
    center: x.milkingInterval,
    right: x.milkingCount,
});
