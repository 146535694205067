import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {createOrUpdatePermissionFormName} from '../../types/permission-form-values';
import {getPermissionFormValues, buildPermissionCreateValuesForApi} from '../../utils/permission';
import {adminAction, CreatePermissionAction} from '../action';

const te = t('admin/sagas');

function* execute(_: CreatePermissionAction): SagaIterator {
    yield* put(formHelpers.startSubmit(createOrUpdatePermissionFormName));
    const permissionFormValues = yield* select(getPermissionFormValues);

    const response = yield* call(Api.createPermission, buildPermissionCreateValuesForApi(permissionFormValues));

    const title = te('createPermission');

    if (response.isSuccess) {
        yield* put(adminAction.setPermissionCreateVisibility(false));
        yield* put(adminAction.getPermissionDetails(opt({action: 'refresh'})));
        yield* put(showSuccess(title, te('permissionAdded')));
        yield* put(formHelpers.reset(createOrUpdatePermissionFormName));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(createOrUpdatePermissionFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* createPermissionSaga(): SagaIterator {
    yield takeLatestF('admin/CREATE_PERMISSION', execute);
}
