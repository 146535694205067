// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-___styles__content___L9bpX{width:100%;align-self:center;margin:auto;padding:1.5rem}@media (min-width: 480px){.src-app-___styles__contentMaxWidthContainer___V7BpD{max-width:480px}}@media (min-width: 576px){.src-app-___styles__contentMaxWidthContainer___V7BpD{max-width:576px}}@media (min-width: 768px){.src-app-___styles__contentMaxWidthContainer___V7BpD{max-width:768px}}@media (min-width: 992px){.src-app-___styles__contentMaxWidthContainer___V7BpD{max-width:992px}}@media (min-width: 1200px){.src-app-___styles__contentMaxWidthContainer___V7BpD{max-width:1200px}}.src-app-___styles__errorFallbackScreen___oO3Ln{display:flex;align-items:center;justify-content:center;height:100%}.src-app-___styles__errorFallbackScreen___oO3Ln .src-app-___styles__errorFallbackScreenCard___tLP7K{width:450px;text-align:center}@media (max-width: 575px){.src-app-___styles__content___L9bpX{padding:.5rem}.src-app-___styles__content___L9bpX h3{padding-left:20px}}
`, "",{"version":3,"sources":["webpack://./src/app/styles.sass"],"names":[],"mappings":"AAAA,oCAAS,UAAA,CAAW,iBAAA,CAApB,WAAA,CAAA,cAAA,CAAiE,0BAAyB,qDAA0B,eAAA,CAAA,CAAiB,0BAAyB,qDAA0B,eAAA,CAAA,CAAiB,0BAAyB,qDAA0B,eAAA,CAAA,CAAiB,0BAAyB,qDAA0B,eAAA,CAAA,CAAiB,2BAA0B,qDAA0B,gBAAA,CAAA,CAAkB,gDAAqB,YAAA,CAAa,kBAAA,CAAmB,sBAAA,CAAuB,WAAA,CAAY,oGAA8C,WAAA,CAAY,iBAAA,CAAkB,0BAAyB,oCAAplB,aAAA,CAA2mB,uCAAY,iBAAA,CAAA","sourcesContent":[".content{width:100%;align-self:center;margin:auto;padding:1.5rem}@media(min-width: 480px){.contentMaxWidthContainer{max-width:480px}}@media(min-width: 576px){.contentMaxWidthContainer{max-width:576px}}@media(min-width: 768px){.contentMaxWidthContainer{max-width:768px}}@media(min-width: 992px){.contentMaxWidthContainer{max-width:992px}}@media(min-width: 1200px){.contentMaxWidthContainer{max-width:1200px}}.errorFallbackScreen{display:flex;align-items:center;justify-content:center;height:100%}.errorFallbackScreen .errorFallbackScreenCard{width:450px;text-align:center}@media(max-width: 575px){.content{padding:.5rem}.content h3{padding-left:20px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `src-app-___styles__content___L9bpX`,
	"contentMaxWidthContainer": `src-app-___styles__contentMaxWidthContainer___V7BpD`,
	"errorFallbackScreen": `src-app-___styles__errorFallbackScreen___oO3Ln`,
	"errorFallbackScreenCard": `src-app-___styles__errorFallbackScreenCard___tLP7K`
};
export default ___CSS_LOADER_EXPORT___;
