/* eslint-disable max-lines-per-function */
import {CabBreedingFemale} from 'api/gen/CabBreedingFemale';

import {CabBreedingFemaleRow} from '../../types/cab-breeding-female-row';

export const rowMapper = (row: CabBreedingFemale): CabBreedingFemaleRow => ({
    id: row.animalId,
    alive: row.alive,
    animalId: row.animalId,
    avgLactationCount: row.avgLactationCount,
    avgLactationDays: row.avgLactationDays,
    avgLactationFatKg: row.avgLactationFatKg,
    avgLactationFatPercentage: row.avgLactationFatPercentage,
    avgLactationMilkKg: row.avgLactationMilkKg,
    avgLactationProteinKg: row.avgLactationProteinKg,
    avgLactationProteinPercentage: row.avgLactationProteinPercentage,
    birthDate: row.birthDate,
    bodyStructure: row.bodyStructure,
    breedAlpha6: row.breedAlpha6,
    breederRegistrationNumber: row.breederRegistrationNumber,
    calveDate: row.calveDate,
    changeCode: row.changeCode,
    currentLactationDays: row.currentLactationDays,
    currentLactationFatKg: row.currentLactationFatKg,
    currentLactationFatPercentage: row.currentLactationFatPercentage,
    currentLactationLactoseKg: row.currentLactationLactoseKg,
    currentLactationLactosePercentage: row.currentLactationLactosePercentage,
    currentLactationMilkKg: row.currentLactationMilkKg,
    currentLactationMilkKgSum: row.currentLactationMilkKgSum,
    currentLactationOrder: row.currentLactationOrder,
    currentLactationProteinKg: row.currentLactationProteinKg,
    currentLactationProteinPercentage: row.currentLactationProteinPercentage,
    currentLactationSomaticCells: row.currentLactationSomaticCells,
    currentLactationUrea: row.currentLactationUrea,
    daysFromBirth: row.daysFromBirth,
    districtCode: row.districtCode,
    earTag: row.earTag,
    exterior: row.exterior,
    extremities: row.extremities,
    farmCode: row.farmCode,
    farmName: row.farmName,
    fatherLineRegistry: row.fatherLineRegistry,
    fatherName: row.fatherName,
    frame: row.frame,
    genotype: row.genotype,
    huid: row.huid,
    inseminationOrder: row.inseminationOrder,
    lastLactationDate: row.lastLactationDate,
    lastLactationDays: row.lastLactationDays,
    lastLactationFatKg: row.lastLactationFatKg,
    lastLactationFatPercentage: row.lastLactationFatPercentage,
    lastLactationFatProteinKg: row.lastLactationFatProteinKg,
    lastLactationMilkKg: row.lastLactationMilkKg,
    lastLactationOrder: row.lastLactationOrder,
    lastLactationProteinKg: row.lastLactationProteinKg,
    lastLactationProteinPercentage: row.lastLactationProteinPercentage,
    lastMatingDate: row.lastMatingDate,
    lastMilkYield: row.lastMilkYield,
    lifelongLactationDaysSum: row.lifelongLactationDaysSum,
    lifelongLactationFatKg: row.lifelongLactationFatKg,
    lifelongLactationFatPercentage: row.lifelongLactationFatPercentage,
    lifelongLactationMilkKg: row.lifelongLactationMilkKg,
    lifelongLactationMilkKgSum: row.lifelongLactationMilkKgSum,
    lifelongLactationProteinKg: row.lifelongLactationProteinKg,
    lifelongLactationProteinPercentage: row.lifelongLactationProteinPercentage,
    lifelongStandardizedLactationDays: row.lifelongStandardizedLactationDays,
    maxLactationDays: row.maxLactationDays,
    maxLactationFatKg: row.maxLactationFatKg,
    maxLactationFatPercentage: row.maxLactationFatPercentage,
    maxLactationFatProteinKg: row.maxLactationFatProteinKg,
    maxLactationMilkKg: row.maxLactationMilkKg,
    maxLactationOrder: row.maxLactationOrder,
    maxLactationProteinKg: row.maxLactationProteinKg,
    maxLactationProteinPercentage: row.maxLactationProteinPercentage,
    milkKgAvgPerDay: row.milkKgAvgPerDay,
    milkKgAvgPerLactationDay: row.milkKgAvgPerLactationDay,
    milkStrength: row.milkStrength,
    motherEarTag: row.motherEarTag,
    mothersFatherLineRegistry: row.mothersFatherLineRegistry,
    mothersFatherName: row.mothersFatherName,
    musculature: row.musculature,
    name: row.name,
    organizationInsCode: row.organizationInsCode,
    organizationCenterInsCode: row.organizationCenterInsCode,
    organizationPtCode: row.organizationPtCode,
    organizationCenterPtCode: row.organizationCenterPtCode,
    pregnant: row.pregnant,
    proficiencyTest: row.proficiencyTest,
    proficiencyTestDate: row.proficiencyTestDate,
    servicePeriod: row.servicePeriod,
    sihIndex: row.sihIndex,
    stableCode: row.stableCode,
    stableName: row.stableName,
    studbookSection: row.studbookSection,
    subjectBusinessName: row.subjectBusinessName,
    totalBreedingValue: row.totalBreedingValue,
    udder: row.udder,
});
