import {DateStringNullable, NullableKeys, todayDate} from '@fl/cmsch-fe-library';

export const userMemberSubjectFormName = 'userMemberSubject';

export interface UserMemberSubjectForm {
    userProfileId: number;
    subjectMemberId: number | null;
    subjectId: number;
    approvedFrom: DateStringNullable;
    approvedTo: DateStringNullable;
}

export const userMemberSubjectInitialValues = (userProfileId: number): NullableKeys<UserMemberSubjectForm> => ({
    subjectMemberId: null,
    subjectId: null,
    approvedFrom: todayDate(),
    approvedTo: null,
    userProfileId,
});
