import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetFleckviehMatingBullsAction, matingAction} from '../action';

const te = t('mating/sagas');

function* execute({payload: {tableDataRequest}}: GetFleckviehMatingBullsAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(matingAction.getMaleFleckviehAnimalsSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('matingFleckviehBulls'));

    const tableState = yield* select(simpleTableSelector.tableState('matingFleckviehBulls'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'matingFleckviehBulls',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
        resetType: 'tableDataParams',
    });

    const columnSettings = getRequestedTableColumnSettings<'matingFleckviehBulls'>(tableState);

    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.getMaleFleckviehAnimalsInList, filters, params);

    if (response.isSuccess) {
        const matingMaleAnimalsPage = response.data;

        yield* put(matingAction.getMaleFleckviehAnimalsSuccess(opt(matingMaleAnimalsPage)));
        yield* put(tablesAction.tableDataFetchSuccess('matingFleckviehBulls', tableDataParams));
    } else {
        yield putAll(showBeError(response, te('loadingBullList')));
        yield* put(tablesAction.tableDataFetchFailure('matingFleckviehBulls'));
    }
}

export function* getMatingFleckviehBullsSaga(): SagaIterator {
    yield takeLatestF('mating/GET_FLECKVIEH_MATING_BULLS', execute);
}
