import * as O from 'optics-ts';
import {none, opt, pipe, some} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialUserState, UserState} from './state';

const userStateO = O.optic<UserState>();

export const userReducer = (state: UserState = initialUserState, action: AppAction): UserState => {
    switch (action.type) {
        case 'user/INITIALIZE':
        case 'user/LOGIN':
            return O.set(userStateO.prop('isUserLoading'))(true)(state);

        case 'user/INITIALIZE_FINISH': {
            return pipe(
                state,
                O.set(userStateO.prop('isUserLoading'))(false),
            );
        }

        case 'user/GET_CURRENT_USER_SUCCESS':
            return O.set(userStateO.prop('currentUser'))(opt(action.payload))(state);

        case 'user/RESET_CURRENT_USER':
            return pipe(
                state,
                O.set(userStateO.prop('currentUser'))(none),
                O.set(userStateO.prop('isUserLoading'))(false),
            );

        case 'user/SET_UI_LANGUAGE':
            return O.set(userStateO.prop('language'))(action.payload)(state);

        case 'user/GET_APP_SETTINGS_LIST_SUCCESS': {
            const {appSettingsList} = action.payload;

            return O.set(userStateO.prop('appSettingsList'))(some(appSettingsList))(state);
        }

        case 'user/RESET_APP_SETTINGS': {
            return O.set(userStateO.prop('appSettingsList'))(none)(state);
        }

        case 'user/SET_PREVIOUS_AND_CURRENT_PATHNAME': {
            const {current, previous} = action.payload;

            return pipe(
                state,
                O.set(userStateO.prop('previousPathname'))(previous),
                O.set(userStateO.prop('currentPathname'))(current),
            );
        }

        case 'user/GET_USER_PROFILE_SUCCESS': {
            return O.set(userStateO.prop('userProfile'))(opt(action.payload))(state);
        }

        case 'user/GET_USER_BREEDERS_SUCCESS':
            return O.set(userStateO.prop('userBreeders'))(action.payload)(state);

        case 'user/GET_USER_ASSOCIATIONS_SUCCESS':
            return O.set(userStateO.prop('userAssociations'))(action.payload)(state);

        case 'user/GET_USER_CONTACTS_SUCCESS':
            return O.set(userStateO.prop('userContacts'))(action.payload)(state);

        case 'user/GET_USER_ORGANIZATIONS_SUCCESS':
            return O.set(userStateO.prop('userOrganizations'))(action.payload)(state);

        case 'user/GET_USER_OTHER_SUBJECTS_SUCCESS':
            return O.set(userStateO.prop('userOtherSubjects'))(action.payload)(state);

        case 'user/GET_USER_MEMBER_OF_SUBJECTS_SUCCESS':
            return O.set(userStateO.prop('userMemberOfSubjects'))(action.payload)(state);

        default:
            return state;
    }
};
