import {putAll} from '@fl/cmsch-fe-library';
import {change} from 'redux-form';
import {SagaIterator} from 'redux-saga';

import {CriteriaDefinition} from 'api/gen/CriteriaDefinition';

import {matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';
import {matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';
import {getDefaultCriteriaValues} from '../../utils/get-default-criteria-values';

export type MatingFormName = typeof matingCowsFilterFormName | typeof matingBullsFilterFormName;

export function* resetCriteriaValues(
    formName: MatingFormName,
    criteriasOfAccordion: Array<CriteriaDefinition>,
): SagaIterator {
    const defaultCriterionValues = getDefaultCriteriaValues(criteriasOfAccordion);

    const changeActions = criteriasOfAccordion.map(criterion => {
        if (criterion.tag === 'BOOL') {
            return change(
                formName,
                `criterias.radios.${criterion.code}`,
                defaultCriterionValues.radios[criterion.code],
            );
        } else if (criterion.tag === 'DATE') {
            return change(
                formName,
                `criterias.dateRanges.${criterion.code}`,
                defaultCriterionValues.dateRanges[criterion.code],
            );
        } else {
            return change(
                formName,
                `criterias.numberRanges.${criterion.code}`,
                defaultCriterionValues.numberRanges[criterion.code],
            );
        }
    });

    yield putAll(changeActions);
}
