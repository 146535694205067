import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {animalBrowserAction, ConfirmOverrideAnimalListAction} from '../action';
import {getAnimalListVersion} from './get-animal-list-version';

const te = t('animalBrowserSagas');

function* execute({payload: {animalType, animalListId}}: ConfirmOverrideAnimalListAction): SagaIterator {
    const version = yield* call(getAnimalListVersion, animalListId);

    yield* put(animalBrowserAction.setLoading('confirmAnimalListNameInProgress', true));

    const response = yield* call(Api.updateAnimalListFromSession, {version}, {animalListId});

    const title = te('saveAnimalList');

    if (response.isSuccess) {
        yield* put(animalBrowserAction.getAnimalLists(animalType, true));
        yield* put(showSuccess(
            title,
            animalType === 'BREEDING_FEMALES' ? te('breederListSuccess') : te('calvesListSuccess'),
        ));
    } else {
        yield putAll(showBeError(response, title));
    }
    yield* put(animalBrowserAction.toggleOverrideAnimalListVisibility(none));
    yield* put(animalBrowserAction.setLoading('confirmAnimalListNameInProgress', false));
}

export function* confirmOverrideAnimalListSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/CONFIRM_OVERRIDE_ANIMAL_LIST', execute);
}
