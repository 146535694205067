import { ActionSettings, TableDataRequest, ToolbarButton } from '@fl/cmsch-fe-library';
import React, { FC, memo, MouseEvent, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { AnimalType } from 'api/gen/AnimalType';
import { CabBreedingFemale } from 'api/gen/CabBreedingFemale';
import { useOurTranslation } from 'app/translations';
import { ConnectedTable } from 'utils/tables';
import { animalBrowserAction } from '../../model';
import { simpleAnimalBrowserSelector } from '../../model/selector';
import { BrowserLoadingId } from '../../types/browser-loading-id';
import { CabBreedingFemaleRow } from '../../types/cab-breeding-female-row';
import { MotherLinkProps } from '../../types/mother-link-props';
import { getCabFemaleColumns } from './columns';
import { rowMapper } from './row-mapper';
interface Props {
  loading: Array<BrowserLoadingId>;
}
const breedingFemales: AnimalType = 'BREEDING_FEMALES';
const CabFemalesTableBase: FC<Props> = ({
  loading
}) => {
  const dispatch = useDispatch();
  const {
    t
  } = useOurTranslation('common');
  const {
    t: tTable
  } = useOurTranslation('tables/table');
  const isPageEmpty = useSelector(simpleAnimalBrowserSelector.isCabFemalePageEmpty);
  const isSessionEmpty = useSelector(simpleAnimalBrowserSelector.isCabFemalesSessionEmpty);
  const selectedAnimalIds = useSelector(simpleAnimalBrowserSelector.selectedAnimalIds);
  const exportInProgress = loading.includes('exportInProgress');
  const handleMotherLink = useCallback((row: MotherLinkProps) => (e: MouseEvent) => {
    e.preventDefault();
    dispatch(animalBrowserAction.getMotherHuid(row.motherEarTag ?? '', row.huid ?? '', breedingFemales, 'COMPREHENSIVE'));
  }, [dispatch]);
  const getComprehensiveBreedingFemales = useCallback((tableDataRequest: Opt<TableDataRequest<CabBreedingFemale>>): void => {
    if (!isSessionEmpty) dispatch(animalBrowserAction.getCabFemales(tableDataRequest));
  }, [dispatch, isSessionEmpty]);
  const exportBreedingFemales = useCallback(() => {
    dispatch(animalBrowserAction.exportCabAnimals(breedingFemales));
  }, [dispatch]);
  const headerButtons = useMemo((): Array<ToolbarButton> => [{
    testId: 'exportCows',
    title: t('downloadCsv'),
    onClick: exportBreedingFemales,
    loading: exportInProgress,
    disabled: isPageEmpty,
    role: 'export'
  }], [exportInProgress, exportBreedingFemales, isPageEmpty, t]);
  const actionSettings = useMemo((): ActionSettings<CabBreedingFemaleRow> => ({
    selectedIds: selectedAnimalIds,
    onSelectedIdsChange: selectedIds => dispatch(animalBrowserAction.setSelectedAnimalIds(selectedIds))
  }), [dispatch, selectedAnimalIds]);
  return <ConnectedTable tableId="comprehensiveBreedingFemale" columns={getCabFemaleColumns(handleMotherLink)} onFetchTableData={getComprehensiveBreedingFemales} rowDataToTableRowMapper={rowMapper} rowDataPageSelector={simpleAnimalBrowserSelector.cabFemalePage} enableTableSettings enableTableUserSettings paginationPosition="both" withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter headerButtons={headerButtons} emptyTableMessages={{
    noRows: tTable('noRows')
  }} actionSettings={actionSettings} data-sentry-element="ConnectedTable" data-sentry-component="CabFemalesTableBase" data-sentry-source-file="index.tsx" />;
};
export const CabFemalesTable = memo(CabFemalesTableBase);