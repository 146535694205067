import React, { FC, memo } from 'react';
import { Ant } from 'common/ant';
import { ICONS } from 'common/icons';
import { Space } from 'common/layout';
interface Props {
  title: string;
}
const SectionHeaderBase: FC<Props> = props => {
  const {
    title
  } = props;
  return <Ant.Row gutter={16} className="pb-3" data-sentry-element="unknown" data-sentry-component="SectionHeaderBase" data-sentry-source-file="section-header.tsx">
            <Space align="center" data-sentry-element="Space" data-sentry-source-file="section-header.tsx">
                <span className="d-flex">{ICONS.unorderedListOutlined}</span>
                <span>{title}</span>
            </Space>
        </Ant.Row>;
};
export const SectionHeader = memo(SectionHeaderBase);