import {none, opt, Opt} from 'ts-opt';

import {cookieApprovalStore} from 'common/layout/utils/cookie-approval-store';

import {Config} from './config';

interface WindowWithGTagDataLayer extends Window {
    dataLayer: Array<unknown> | undefined;
}

declare const window: WindowWithGTagDataLayer;

const gTagIdsTimeout = 8000;
const googleAnalyticsId = 'google-analytics-script';

function gtag(..._: Array<unknown>): void {
    // dataLayer only works with implicit 'arguments' variable from function
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer?.push(arguments);
}

const initializeGTM = (): void => {
    if (Config.gaTrackingId) {
        window.dataLayer = window.dataLayer || [];

        gtag('js', new Date());
        gtag('config', Config.gaTrackingId);
    }
};

const loadGoogleAnalytics = (): void => {
    if (Config.gaTrackingId && !document.getElementById(googleAnalyticsId)) {
        const gaScript = document.createElement('script');
        gaScript.async = true;
        gaScript.id = googleAnalyticsId;
        gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${Config.gaTrackingId}`;
        document.head.appendChild(gaScript);

        gaScript.onload = initializeGTM;
    }
};

const unLoadGoogleAnalytics = (): void => {
    if (Config.gaTrackingId) {
        const gaScript = document.getElementById(googleAnalyticsId);
        if (gaScript?.parentNode) {
            gaScript.parentNode.removeChild(gaScript);
            window.dataLayer = undefined;
        }
    }
};

export const handleGoogleAnalyticsScript = (load: boolean): void => {
    if (load) {
        loadGoogleAnalytics();
    } else {
        unLoadGoogleAnalytics();
    }
};

export interface GTagClientAndSessionId {
    clientId: string;
    sessionId: string;
}

let cachedGTagClientAndSessionId: Opt<GTagClientAndSessionId> = none;

async function getClientId(): Promise<string> {
    return new Promise<string>(resolve => {
        gtag('get', Config.gaTrackingId, 'client_id', resolve);
    });
}

async function getSessionId(): Promise<string> {
    return new Promise<string>(resolve => {
        gtag('get', Config.gaTrackingId, 'session_id', resolve);
    });
}

async function loadGTagIds(): Promise<Opt<GTagClientAndSessionId>> {
    const [clientId, sessionId] = await Promise.all([getClientId(), getSessionId()]);

    cachedGTagClientAndSessionId = opt({clientId, sessionId});

    return cachedGTagClientAndSessionId;
}

async function rejectAfterTimeout(): Promise<Opt<GTagClientAndSessionId>> {
    return new Promise<Opt<GTagClientAndSessionId>>((_, reject) => {
        setTimeout(
            reject,
            gTagIdsTimeout,
            new Error(`request for gTagIds were not resolved in ${gTagIdsTimeout}ms, gTag may be blocked`),
        );
    });
}

function handleError(error: Error): Opt<GTagClientAndSessionId> {
    // eslint-disable-next-line no-console
    console.error(error);

    return none;
}

export async function getGTagIds(): Promise<Opt<GTagClientAndSessionId>> {
    if (!Config.gaTrackingId || !cookieApprovalStore.isStatisticalAccepted()) return none;
    if (cachedGTagClientAndSessionId.isSome()) return cachedGTagClientAndSessionId;

    return Promise.race([loadGTagIds(), rejectAfterTimeout()])
        .catch(handleError);
}
