import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetCorrectionReportsAction, analysisProtocolCorrectionAction} from '../action';

function* execute(action: GetCorrectionReportsAction): SagaIterator {
    const {tableDataRequest} = action.payload;

    if (tableDataRequest.isEmpty) {
        return yield* put(analysisProtocolCorrectionAction.getCorrectionReportsSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('analysisProtocolCorrectionOverview'));

    const tableState = yield* select(simpleTableSelector.tableState('analysisProtocolCorrectionOverview'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'analysisProtocolCorrectionOverview',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });

    const columnSettings = getRequestedTableColumnSettings<'analysisProtocolCorrectionOverview'>(tableState);

    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.getCorrectionReports, filters, params);

    if (response.isSuccess) {
        yield* put(analysisProtocolCorrectionAction.getCorrectionReportsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('analysisProtocolCorrectionOverview', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('apc/apc')('title')));
    }
}

export function* getCorrectionReportsSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/GET_CORRECTION_REPORTS', execute);
}
