import {Options, TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {AnalysisProtocol} from 'api/gen/AnalysisProtocol';
import {ApcStableDate} from 'api/gen/ApcStableDate';
import {CorrectionReport} from 'api/gen/CorrectionReport';
import {CorrectionReportDetail} from 'api/gen/CorrectionReportDetail';
import {CorrectionType} from 'api/gen/CorrectionType';
import {Date} from 'api/gen/Date';
import {EarTag} from 'api/gen/EarTag';
import {PagedModelCorrectionReport} from 'api/gen/PagedModelCorrectionReport';
import {StableCode} from 'api/gen/StableCode';
import {TechnicianNumber} from 'api/gen/TechnicianNumber';

import {ApcReportFormValues} from '../components/apc-report-form/form-values';
import {AnalysisProtocolCorrectionLoadingId} from '../types/analysis-protocol-correction-loading-id';

export const analysisProtocolCorrectionAction = {
    getCorrectionReports: (tableDataRequest: Opt<TableDataRequest<CorrectionReport>>) => ({
        type: 'analysisProtocolCorrection/GET_CORRECTION_REPORTS',
        payload: {tableDataRequest},
    }) as const,
    getCorrectionReportsSuccess: (tableData: Opt<PagedModelCorrectionReport>) => ({
        type: 'analysisProtocolCorrection/GET_CORRECTION_REPORTS_SUCCESS',
        payload: {tableData},
    }) as const,
    deleteCorrectionReport: (id: number, isCreatedCorrectionReport: boolean) => ({
        type: 'analysisProtocolCorrection/DELETE_CORRECTION_REPORT',
        payload: {id, isCreatedCorrectionReport},
    }) as const,
    deleteCorrectionReportSuccess: (id: number) => ({
        type: 'analysisProtocolCorrection/DELETE_CORRECTION_REPORT_SUCCESS',
        payload: {id},
    }) as const,
    exportCorrectionReports: () => ({
        type: 'analysisProtocolCorrection/EXPORT_CORRECTION_REPORTS',
    }) as const,
    setLoading: (id: AnalysisProtocolCorrectionLoadingId, loading: boolean) => ({
        type: 'analysisProtocolCorrection/SET_LOADING',
        payload: {id, loading},
    }) as const,
    getStableDateRelations: (focus: () => void, earTag: EarTag, correctionType: CorrectionType | undefined) => ({
        type: 'analysisProtocolCorrection/GET_STABLE_DATE_RELATIONS',
        payload: {focus, earTag, correctionType},
    }) as const,
    getStableDateRelationsSuccess: (apcStableDate: Opt<ApcStableDate>) => ({
        type: 'analysisProtocolCorrection/GET_STABLE_DATE_RELATIONS_SUCCESS',
        payload: {apcStableDate},
    }) as const,
    setProficiencyTestDateOptions: (options: Options<string>) => ({
        type: 'analysisProtocolCorrection/SET_PROFICIENCY_TEST_DATE_OPTIONS',
        payload: {options},
    }) as const,
    setStableOptions: (options: Options<string>) => ({
        type: 'analysisProtocolCorrection/SET_STABLE_OPTIONS',
        payload: {options},
    }) as const,
    getTechnicianOptions: () => ({
        type: 'analysisProtocolCorrection/GET_TECHNICIAN_OPTIONS',
    }) as const,
    getTechnicianOptionsSuccess: (options: Options<TechnicianNumber>) => ({
        type: 'analysisProtocolCorrection/GET_TECHNICIAN_OPTIONS_SUCCESS',
        payload: {options},
    }) as const,
    getAnalysisProtocol: (earTag: EarTag, proficiencyTestDate: Date) => ({
        type: 'analysisProtocolCorrection/GET_ANALYSIS_PROTOCOL',
        payload: {earTag, proficiencyTestDate},
    }) as const,
    getAnalysisProtocolSuccess: (analysisProtocol: AnalysisProtocol) => ({
        type: 'analysisProtocolCorrection/GET_ANALYSIS_PROTOCOL_SUCCESS',
        payload: {analysisProtocol},
    }) as const,
    getStableDateRelation: (stableCode: StableCode, proficiencyTestDate: Date) => ({
        type: 'analysisProtocolCorrection/GET_STABLE_DATE_RELATION',
        payload: {stableCode, proficiencyTestDate},
    }) as const,
    createCorrectionReport: (
        formValues: ApcReportFormValues,
        focusEarTag: () => void,
        editingCorrectionReportId?: number,
    ) => ({
        type: 'analysisProtocolCorrection/CREATE_CORRECTION_REPORT',
        payload: {formValues, focusEarTag, editingCorrectionReportId},
    }) as const,
    createCorrectionReportSuccess: (correctionReport: CorrectionReportDetail) => ({
        type: 'analysisProtocolCorrection/CREATE_CORRECTION_REPORT_SUCCESS',
        payload: {correctionReport},
    }) as const,
    resetCreatedApcReports: () => ({
        type: 'analysisProtocolCorrection/RESET_CREATED_APC_REPORTS',
    }) as const,
    validateCorrectionReport: (focus: () => void, getStableDateRelations?: boolean) => ({
        type: 'analysisProtocolCorrection/VALIDATE_CORRECTION_REPORT',
        payload: {focus, getStableDateRelations},
    }) as const,
    setEditingCorrectionReportId: (id?: number) => ({
        type: 'analysisProtocolCorrection/SET_EDITING_CORRECTION_REPORT_ID',
        payload: {id},
    }) as const,
    resetFormData: (dontResetTechnicianOptions?: boolean) => ({
        type: 'analysisProtocolCorrection/RESET_FORM_DATA',
        payload: {dontResetTechnicianOptions},
    }) as const,
    getCorrectionReportDetail: (focus: () => void, apcId: number) => ({
        type: 'analysisProtocolCorrection/GET_CORRECTION_REPORT_DETAIL',
        payload: {focus, apcId},
    }) as const,
    clearStableAndDateData: () => ({
        type: 'analysisProtocolCorrection/clear_stable_and_date_data',
    }) as const,
};

export type GetCorrectionReportsAction = ReturnType<typeof analysisProtocolCorrectionAction.getCorrectionReports>;
export type ClearStableAndDateDataAction = ReturnType<typeof analysisProtocolCorrectionAction.clearStableAndDateData>;
export type GetCorrectionReportsSuccessAction =
    ReturnType<typeof analysisProtocolCorrectionAction.getCorrectionReportsSuccess>;
export type DeleteCorrectionReportAction = ReturnType<typeof analysisProtocolCorrectionAction.deleteCorrectionReport>;
export type DeleteCorrectionReportSuccessAction =
    ReturnType<typeof analysisProtocolCorrectionAction.deleteCorrectionReportSuccess>;
export type ExportCorrectionReportAction = ReturnType<typeof analysisProtocolCorrectionAction.exportCorrectionReports>;
export type AnalysisProtocolCorrectionSetLoadingAction = ReturnType<typeof analysisProtocolCorrectionAction.setLoading>;
export type GetStableDateRelationsAction = ReturnType<typeof analysisProtocolCorrectionAction.getStableDateRelations>;
export type GetStableDateRelationsSuccessAction =
    ReturnType<typeof analysisProtocolCorrectionAction.getStableDateRelationsSuccess>;
export type SetProficiencyTestDateOptionsAction =
    ReturnType<typeof analysisProtocolCorrectionAction.setProficiencyTestDateOptions>;
export type SetStableOptionsAction = ReturnType<typeof analysisProtocolCorrectionAction.setStableOptions>;
export type GetTechnicianOptionsAction = ReturnType<typeof analysisProtocolCorrectionAction.getTechnicianOptions>;
export type GetTechnicianOptionsSuccessAction =
    ReturnType<typeof analysisProtocolCorrectionAction.getTechnicianOptionsSuccess>;
export type GetAnalysisProtocolAction = ReturnType<typeof analysisProtocolCorrectionAction.getAnalysisProtocol>;
export type GetAnalysisProtocolSuccessAction =
    ReturnType<typeof analysisProtocolCorrectionAction.getAnalysisProtocolSuccess>;
export type GetStableDateRelationAction = ReturnType<typeof analysisProtocolCorrectionAction.getStableDateRelation>;
export type CreateCorrectionReportAction = ReturnType<typeof analysisProtocolCorrectionAction.createCorrectionReport>;
export type CreateCorrectionReportSuccessAction =
    ReturnType<typeof analysisProtocolCorrectionAction.createCorrectionReportSuccess>;
export type ResetCreatedApcReportsAction = ReturnType<typeof analysisProtocolCorrectionAction.resetCreatedApcReports>;
export type ValidateCorrectionReportAction =
    ReturnType<typeof analysisProtocolCorrectionAction.validateCorrectionReport>;
export type SetEditingCorrectionReportIdAction =
    ReturnType<typeof analysisProtocolCorrectionAction.setEditingCorrectionReportId>;
export type ResetFormDataAction = ReturnType<typeof analysisProtocolCorrectionAction.resetFormData>;
export type GetCorrectionReportDetailAction =
    ReturnType<typeof analysisProtocolCorrectionAction.getCorrectionReportDetail>;

export type AnalysisProtocolCorrectionAction =
    | GetCorrectionReportsAction
    | ClearStableAndDateDataAction
    | GetCorrectionReportsSuccessAction
    | DeleteCorrectionReportAction
    | DeleteCorrectionReportSuccessAction
    | ExportCorrectionReportAction
    | AnalysisProtocolCorrectionSetLoadingAction
    | GetStableDateRelationsAction
    | GetStableDateRelationsSuccessAction
    | SetProficiencyTestDateOptionsAction
    | SetStableOptionsAction
    | GetTechnicianOptionsAction
    | GetTechnicianOptionsSuccessAction
    | GetAnalysisProtocolAction
    | GetAnalysisProtocolSuccessAction
    | GetStableDateRelationAction
    | CreateCorrectionReportAction
    | CreateCorrectionReportSuccessAction
    | ResetCreatedApcReportsAction
    | ValidateCorrectionReportAction
    | SetEditingCorrectionReportIdAction
    | ResetFormDataAction
    | GetCorrectionReportDetailAction
;
