import { ActionSettings, ToolbarButton, TableDataRequest } from '@fl/cmsch-fe-library';
import { identity } from 'lodash/fp';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { CorrectionReport } from 'api/gen/CorrectionReport';
import { useOurTranslation } from 'app/translations';
import useCurrentUserHasAnyPermission from 'app/user/utils/use-current-user-has-any-permission';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { apcPermissions } from '../../constants/permissions';
import { analysisProtocolCorrectionLoadingSelector, simpleAnalysisProtocolCorrectionSelector } from '../../model/selector';
import { getColumns } from './columns';
interface Props {
  getCorrectionReports(tableDataRequest: Opt<TableDataRequest<CorrectionReport>>): void;
  deleteCorrectionReport(id: number): void;
  exportCorrectionReports(): void;
  editCorrectionReport(report: CorrectionReport): void;
  goToNewReport(): void;
}
const pathnamesForRefresh: Array<RegExp> = [/\/analysis-protocol-correction\/.+/];
const OverviewTableBase: FC<Props> = props => {
  const {
    getCorrectionReports,
    deleteCorrectionReport,
    exportCorrectionReports,
    editCorrectionReport,
    goToNewReport
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('apc/overviewTable');
  const canUpdate = useCurrentUserHasAnyPermission([apcPermissions.updateTechnician, apcPermissions.updateLeaderTechnician, apcPermissions.updateAdmin]);
  const actionSettings = useMemo((): ActionSettings<CorrectionReport> => canUpdate ? {
    extraActions: [{
      id: 'delete',
      role: 'delete',
      callback: deleteCorrectionReport,
      disabled: (_id: number | string, row: CorrectionReport): boolean => row.exportedToCobolDatabase,
      disabledTitle: t('deleteDisabledTitle')
    }, {
      id: 'edit',
      role: 'edit',
      callback: (_: number | string, row: CorrectionReport): void => editCorrectionReport(row),
      disabled: (_id: number | string, row: CorrectionReport): boolean => row.exportedToCobolDatabase,
      disabledTitle: t('editDisabledTitle')
    }]
  } : {}, [canUpdate, deleteCorrectionReport, editCorrectionReport, t]);
  const handleExport = useCallback(() => {
    exportCorrectionReports();
  }, [exportCorrectionReports]);
  const {
    exportingAnalysisProtocolCorrectionOverview
  } = useSelector(analysisProtocolCorrectionLoadingSelector);
  const headerButtons = useMemo((): Array<ToolbarButton> => [{
    title: t('newReport'),
    role: 'create',
    onClick: goToNewReport,
    hidden: !canUpdate
  }, {
    title: tCommon('downloadCsv'),
    role: 'export',
    onClick: handleExport,
    loading: exportingAnalysisProtocolCorrectionOverview
  }], [canUpdate, exportingAnalysisProtocolCorrectionOverview, goToNewReport, handleExport, t, tCommon]);
  const columns = useMemo(() => getColumns(t), [t]);
  return <ConnectedTable tableId="analysisProtocolCorrectionOverview" columns={columns} onFetchTableData={getCorrectionReports} rowDataToTableRowMapper={identity} rowDataPageSelector={simpleAnalysisProtocolCorrectionSelector.pageCorrectionReports} enableTableSettings enableTableUserSettings paginationPosition="both" withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter actionSettings={actionSettings} headerButtons={headerButtons} emptyTableContent={canUpdate && <Button role="create" onClick={goToNewReport}>
                    {t('newReport')}
                </Button>} pathnamesForRefresh={pathnamesForRefresh} emptyTableMessages={{
    noRows: t('noCorrectionReports')
  }} data-sentry-element="ConnectedTable" data-sentry-component="OverviewTableBase" data-sentry-source-file="index.tsx" />;
};
export const OverviewTable = memo(OverviewTableBase);