import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {AddAnimalsFromDBFormValues} from './add-animals-from-db-form-values';

interface ValidateProps {
    selectLabel: string;
}

export const validate = ({selectLabel}: ValidateProps) =>
    (values: AddAnimalsFromDBFormValues): Errors<AddAnimalsFromDBFormValues> => {
        const validator = new EskotValidator(values);

        validator.nonEmpty('animalListId', selectLabel);

        return validator.generateErrorsObject();
    };
