import {UserProfileContact} from 'api/gen/UserProfileContact';

import {UserContactsTableType} from '../../types/user-contacts-table-type';

export const rowMapper = ({contactType, userContactId, value}: UserProfileContact): UserContactsTableType => ({
    contactType,
    userContactId,
    value,
    id: userContactId,
});
