import {SagaIterator} from 'redux-saga';
import {select} from 'typed-redux-saga';

import {State} from 'app/setup';

export function* getCriteriaListId(name: string): SagaIterator<number> {
    const criteriaLists = yield* select((state: State) => state.mating.criteriaLists);

    return criteriaLists
        .findIn(y => y.name === name)
        .prop('id')
        .orCrash('criteriaLists not available');
}
