import { join } from 'lodash/fp';
import React, { ReactNode } from 'react';
import { opt, map } from 'ts-opt';
import { FarmRow } from 'api/gen/FarmRow';
import { BreederRoleTableType } from '../../types/breeder-role-table-type';
export const getFarmCodeFormat = (value: string, {
  accessToAllFarms,
  farms
}: BreederRoleTableType): ReactNode => {
  const title = opt(farms).noneWhen(!accessToAllFarms).mapFlow(map((farm: FarmRow): string => farm.farmCode), join(', ')).orUndef();
  return <span title={title} data-sentry-component="getFarmCodeFormat" data-sentry-source-file="farm-code-format.tsx">{value}</span>;
};