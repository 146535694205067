import {TableColumn} from '@fl/cmsch-fe-library';

import {AssociationRoleTableType} from '../../types/association-role-table-type';

export const columns: Array<TableColumn<AssociationRoleTableType>> = [
    {
        field: 'associationId',
        type: 'number',
        align: 'left',
    },
    {
        field: 'associationName',
        type: 'string',
    },
    {
        field: 'approvedFrom',
        type: 'date',
        dateFormat: 'date',
    },
    {
        field: 'approvedTo',
        type: 'date',
        dateFormat: 'date',
        filterTypes: ['null'],
    },
    {
        field: 'roleCode',
        type: 'string',
    },
    {
        field: 'roleName',
        type: 'string',
    },
];
