import {Nullable} from '@fl/cmsch-fe-library';
import {compact, isEmpty, sum} from 'lodash/fp';
import {none, nonEmpty, opt} from 'ts-opt';

import {t} from 'app/translations';
import {Errors} from 'utils/validator';

import {MatingBullPreferencesFormValues} from '../../types/mating-bull-preferences-form-values';

const tm = t('mating/resultTab');

const hundredPercent = 100;
const validateBullPreferences = (
    bullPreferences: Array<Nullable<number> | undefined> | null | undefined,
): string | undefined => {
    const enteredValues = compact(bullPreferences);
    if (isEmpty(enteredValues)) return tm('noPercentagesEntered');
    else if (sum(enteredValues) !== hundredPercent) return tm('sumOfPercentagesDoesNotAddUp');
    else return undefined;
};

const percentMin = 0;
const percentMax = 100;
const validatePercentNumber = (value: Nullable<number> | undefined): string | undefined => opt(value).chain(x => {
    if (x < percentMin) return opt(`${tm('lesserThan')} ${percentMin}`);
    else if (x > percentMax) return opt(`${tm('greaterThan')} ${percentMax}`);
    else return none;
}).orUndef();

export const validate = (values: MatingBullPreferencesFormValues): Errors<MatingBullPreferencesFormValues> => {
    if (values.bullPreferences) {
        const fieldsErrors = values.bullPreferences.map(validatePercentNumber);

        if (nonEmpty(compact(fieldsErrors))) return {bullPreferences: fieldsErrors};
    }

    const bullPreferencesError = validateBullPreferences(values.bullPreferences);
    if (bullPreferencesError) {
        return {bullPreferences: bullPreferencesError};
    }

    return {};
};
