import { Modal } from '@fl/cmsch-fe-library';
import React, { FC, memo, useCallback } from 'react';
import { Opt, opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { CreateAnimalListForm } from 'app/modals/components/modal-list-name/form';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { ExportAnimalsForm } from '../export-animals-form';
import { ExportAnimalsFormValues } from '../export-animals-form/export-animals-form-values';
interface Props {
  animalListEmptyMessage: string;
  animalLists: Opt<Array<AnimalList>>;
  isAnimalsPageEmpty: boolean;
  title: string;
  visible: boolean;
  onSubmitSaveToFile(fileName: string): void;
  onCancel(): void;
  onSubmitSaveToDb(name: string): void;
}
const ModalMultipleSaveListBase: FC<Props> = ({
  animalListEmptyMessage,
  animalLists,
  isAnimalsPageEmpty,
  visible,
  title,
  onCancel,
  onSubmitSaveToDb,
  onSubmitSaveToFile
}) => {
  const {
    t
  } = useOurTranslation('animalListModals');
  const handleSubmitToFile = useCallback((values: ExportAnimalsFormValues) => opt(values.exportAnimalsInList).onSome(onSubmitSaveToFile), [onSubmitSaveToFile]);
  return <Modal onCancel={onCancel} footer={null} title={title} visible={visible} data-sentry-element="Modal" data-sentry-component="ModalMultipleSaveListBase" data-sentry-source-file="index.tsx">
            <Ant.Tabs type="card" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Ant.Tabs.TabPane key="saveToDb" tab={t('saveToDb')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <CreateAnimalListForm isAnimalsListEmpty={isAnimalsPageEmpty} animalListEmptyMessage={animalListEmptyMessage} animalLists={animalLists} onCancel={onCancel} onSubmit={onSubmitSaveToDb} data-sentry-element="CreateAnimalListForm" data-sentry-source-file="index.tsx" />
                </Ant.Tabs.TabPane>
                <Ant.Tabs.TabPane key="saveToFile" tab={t('saveToFile')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <ExportAnimalsForm isAnimalsPageEmpty={isAnimalsPageEmpty} onCancel={onCancel} onSubmit={handleSubmitToFile} data-sentry-element="ExportAnimalsForm" data-sentry-source-file="index.tsx" />
                </Ant.Tabs.TabPane>
            </Ant.Tabs>
        </Modal>;
};
export const ModalMultipleSaveList = memo(ModalMultipleSaveListBase);