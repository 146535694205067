import { Loader, formatDateTime } from '@fl/cmsch-fe-library';
import React, { FC, Fragment, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Opt } from 'ts-opt';
import { AssemblyVersions } from 'api/gen/AssemblyVersions';
import { DatabaseInfo } from 'api/gen/DatabaseInfo';
import { LibraryInfo } from 'api/gen/LibraryInfo';
import { Config } from 'app/config';
import { Content } from 'app/content';
import { AppAction, State } from 'app/setup';
import { TFunction, useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Card, simpleLayoutSelector, Space } from 'common/layout';
import { AppInfoRow } from '../components';
import { adminAction } from '../model/action';
import { AppInfoType } from '../types/app-info-type';
interface StateProps {
  isAppInfoLoading: boolean;
  appInfo: Opt<AppInfoType>;
}
interface DispatchProps {
  getAppInfo(): void;
}
const getAppInfoHeader = (t: TFunction<'admin/appInfo'>): ReactNode => <Space align="center" data-sentry-element="Space" data-sentry-component="getAppInfoHeader" data-sentry-source-file="app-info.tsx">
        <span>{t('applicationInfo')}</span>
    </Space>;
const getExternalLibraries = (lib: AssemblyVersions): ReactNode => <Fragment>
        <AppInfoRow rowName={<Fragment>
                    eSkotDALappInfo
                </Fragment>} rowValue={lib.eSkotDAL} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<Fragment>
                    eSkotTranslations
                </Fragment>} rowValue={lib.eSkotTranslations} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<Fragment>
                    eSkotLib
                </Fragment>} rowValue={lib.eSkotLib} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<Fragment>
                    custExtensions
                </Fragment>} rowValue={lib.custExtensions} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<Fragment>
                    MsSqlHintsInterceptor
                </Fragment>} rowValue={lib.MsSqlHintsInterceptor} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
    </Fragment>;
const getDisplayedDotNetAppInfo = (eSkotWeb: string, databaseInfo: DatabaseInfo, missingScripts: Array<string>, t: TFunction<'admin/appInfo'>): ReactNode => <Fragment>
        <AppInfoRow rowName={<Fragment>
                    {t('version')} eSkotWeb
                </Fragment>} rowValue={eSkotWeb} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<Fragment>
                    {t('eSkotDatabaseVersion')}
                </Fragment>} rowValue={databaseInfo.version} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<Fragment>
                    {t('missingScripts')}
                </Fragment>} rowValue={missingScripts.join(', ')} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<Fragment>
                    {t('database')} eSkot
                </Fragment>} rowValue={`${databaseInfo.dataSource}, ${databaseInfo.database}`} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<Fragment>
                    {t('deploymentDate')}
                </Fragment>} rowValue={databaseInfo.deployDate} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
    </Fragment>;
const appInfoDivider = <Ant.Row className="mt-2" />;
const getDisplayedJavaAppInfo = (beVersion: string, beBuiltAt: string, t: TFunction<'admin/appInfo'>, beLibraries: Array<LibraryInfo> | null): ReactNode => <Fragment>
        <AppInfoRow rowName={<Fragment>
                    {t('version')} eSkotWeb BE
                </Fragment>} rowValue={beVersion} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        {beLibraries?.map(({
    version,
    name
  }) => <AppInfoRow key={name} rowName={<div className="ml-4">
                        {name}
                    </div>} rowValue={version} />)}

        {appInfoDivider}
        <AppInfoRow rowName={<Fragment>
                    {t('version')} eSkotWeb FE
                </Fragment>} rowValue={Config.version} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
        <AppInfoRow rowName={<div className="ml-4">
                    {t('library')} cmsch-fe-library
                </div>} rowValue={Config.feLibraryVersion} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />

        {appInfoDivider}
        <AppInfoRow rowName={<Fragment>
                    {t('deploymentDate')}
                </Fragment>} rowValue={formatDateTime(beBuiltAt).orCrash('date should be correct')} data-sentry-element="AppInfoRow" data-sentry-source-file="app-info.tsx" />
    </Fragment>;
type Props = StateProps & DispatchProps;
const AppInfoBase: FC<Props> = ({
  getAppInfo,
  isAppInfoLoading,
  appInfo
}) => {
  useEffect(() => {
    getAppInfo();
  }, [getAppInfo]);
  const {
    t,
    tCommon
  } = useOurTranslation('admin/appInfo');
  if (isAppInfoLoading || appInfo.isEmpty) {
    return <Content browserTabTitle={t('pageTitle')}>
                <Loader show size="large" text={tCommon('loading')} />
            </Content>;
  }
  const appInfoPresent = appInfo.orCrash('has to be present');
  const {
    legacyBeAppInfo,
    beAppInfo
  } = appInfoPresent;
  const {
    assemblyVersions,
    databaseInfo,
    missingScripts
  } = legacyBeAppInfo;
  const {
    appVersion,
    libraries,
    buildTs
  } = beAppInfo;
  const externalLibraries = getExternalLibraries(assemblyVersions);
  const displayedDotNetAppInfo = getDisplayedDotNetAppInfo(assemblyVersions.eSkotWeb, databaseInfo, missingScripts, t);
  const displayedJavaAppInfo = getDisplayedJavaAppInfo(appVersion, buildTs, t, libraries);
  return <Content browserTabTitle={t('pageTitle')} data-sentry-element="Content" data-sentry-component="AppInfoBase" data-sentry-source-file="app-info.tsx">
            <Card title={getAppInfoHeader(t)} titleSize="big" leftMargin data-sentry-element="Card" data-sentry-source-file="app-info.tsx">
                <Ant.Divider orientation="left" data-sentry-element="unknown" data-sentry-source-file="app-info.tsx">
                    {t('applicationInfo')} eSkotWeb Java
                </Ant.Divider>
                {displayedJavaAppInfo}
                <Ant.Divider orientation="left" data-sentry-element="unknown" data-sentry-source-file="app-info.tsx">
                    {t('applicationInfo')} eSkotWeb .Net
                </Ant.Divider>
                {displayedDotNetAppInfo}
                <Ant.Divider orientation="left" data-sentry-element="unknown" data-sentry-source-file="app-info.tsx">
                    {t('externalLibraries')}
                </Ant.Divider>
                {externalLibraries}
            </Card>
        </Content>;
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getAppInfo: (): AppAction => dispatch(adminAction.getAppInfo())
});
const mapStateToProps = (state: State): StateProps => ({
  isAppInfoLoading: simpleLayoutSelector.isItemLoading('appInfo')(state),
  appInfo: state.admin.appInfo
});
export const AppInfo = compose<FC>(connect(mapStateToProps, mapDispatchToProps))(AppInfoBase);