export const matingTranslations = {
    CS: {
        'mating/mating': {
            pageTitle: 'Připařovací plán pro plemeno H',
            matingPlan: 'Připařovací plán',
            cowGroupSelection: 'Výběr skupiny plemenic',
            bullGroupSelection: 'Výběr skupiny býků',
            loadingMatingPlan: 'Načítání připařovacího plánu…',
            calcParamsSelection: 'Výběr parametrů výpočtu',
            result: 'Výsledek',
            resetAllSettings: 'Obnovení všech nastavení',
        },
        'mating/storeAnimalLists': {
            animalListName: 'Název seznamu zvířat',
        },
        'mating/bullSelector': {
            pageTitleH: 'Bullselector pro plemeno H',
            pageTitleC: 'Bullselector pro plemeno C',
            loadingBullSelector: 'Načítání Bullselectoru…',
        },
        'mating/matingBullsTable': {
            lineRegistry: 'Linie-Registr',
            name: 'Jméno',
            organizationShort: 'Org.',
            organization: 'Organizace',
            milkProductionIndex: 'DSI MLK',
            milkProductionIndexTooltip: 'Index produkce mléka',
            roboticMilkingIndex: 'RIH',
            productionTraitsCode: 'Kód Mlk',
            exteriorTraitsCode: 'Kód Ext',
            exteriorReliability: 'Spol Ext',
            extremitiesIndex: 'DSI KON',
            udderIndex: 'DSI VEM',
            longevityIndex: 'DSI DLH',
            rbvMothersChildbirthDifficulty: 'RPH Obt Por Mat',
            rbvLongevity: 'Dlouhověkost',
            rbvHeifersFertility: 'RPH Plod Vlast',
            rbvDaughtersFertility: 'RPH Plod Dcer',
            rbvMilkability: 'RPH Dojitelnost',
            rbvFirstChildbirthDifficulty: 'RPH Obt Por na L1',
            rbvAllChildBirthDifficulties: 'RPH Obt Por',
        },
        'mating/matingCowsTable': {
            earTag: 'UZ',
            farmCode: 'Kód hosp.',
            lastCalvingAt: 'Datum otel.',
            lactation: 'Laktace',
            lastAdmissionAt: 'Datum zap.',
            lastAdmissionAtTooltip: 'Datum zapuštění',
            pregnant: 'Březost',
            lactationDays: 'Mlk dny',
            milkKg: 'Mlk Kg',
            fatKg: 'T Kg',
            fatPercentage: 'T %',
            proteinKg: 'B Kg',
            proteinPercentage: 'B %',
            redIndex: 'RED',
            milkStrength: 'MLS',
            bodyStructure: 'STe',
            extremities: 'Končetiny',
            udder: 'Vemeno',
            overallClass: 'Třída',
            overallCondition: 'Body celkem',
            genotype: 'Genot.',
        },
        'mating/matingTableCommon': {
            birthdayShort: 'Datum nar.',
            bvMilkKg: 'PH Mlk Kg',
            bvMilkKgReliability: 'SPOL PH Mlk Kg',
            bvFatKg: 'PH T Kg',
            bvFatPercentage: 'PH T %',
            bvProteinKg: 'PH B Kg',
            bvProteinPercentage: 'PH B %',
            sihIndex: 'SIH',
            inbreedingCoefficient: 'KOEF PŘÍB',
            rbvMilkKg: 'RPH Mlk Kg',
            rbvFatKg: 'RPH T KG',
            rbvFatPercentage: 'RPH % tuku',
            rbvProteinKg: 'RPH B Kg',
            rbvProteinPercentage: 'RPH B %',
            rbvSomaticCells: 'RPH SB',
            rbvClinicalMastitis: 'RPH KM',
            rbvLimbDisease: 'RPH OCD',
            rbvFrame: 'RPH TRA',
            rbvChestWidth: 'RPH SHR',
            rbvBodyDepth: 'RPH HLT',
            rbvEdginess: 'RPH HRA',
            rbvBackAngle: 'RPH SKZ',
            rbvBackWidth: 'RPH SRZ',
            rbvRearPosture: 'RPH PZZ',
            rbvSidePosture: 'RPH PZB',
            rbvHoovesAngle: 'RPH PAZ',
            rbvForeUdderAttachment: 'RPH PUV',
            rbvFrontTeatsPlacement: 'RPH RPS',
            rbvTeatsLength: 'RPH DST',
            rbvUdderDepth: 'RPH HLV',
            rbvRearUdderAttachmentHeight: 'RPH VZU',
            rbvSuspensoryLigament: 'RPH ZAV',
            rbvRearTeatsPlacement: 'RPH RZS',
            rbvRearUdderAttachmentWidth: 'RPH SZU',
            rbvBonesQuality: 'RPH KVK',
            rbvWalkQuality: 'RPH CHO',
            rbvFitness: 'RPH KND',
            rbvMilkStrength: 'RPH MLS',
            rbvBodyStructure: 'RPH STe',
            rbvExtremities: 'RPH Kon',
            rbvUdder: 'RPH Vem',
            rbvOverallCondition: 'RPH Celkem',
        },
        'mating/matingBullsFilterForm': {
            saveCriterias: 'Uložit seznam parametrů',
            actionRewritesAnimalList: 'Tato akce přepíše aktuální seznam zvířat.',
            formIsInvalid: 'Formulář je nevalidní.',
            filter: 'Filtrovat',
            addBulls: 'Přidat býky',
            addBullsFromFile: 'Přidat býky ze souboru',
            activeWithAtLeastOneBullList: 'Funkce je aktivní při alespoň jednom uloženém seznamu býků.',
            addBullsFromList: 'Přidat býky ze seznamu',
            activeWithAtLeastOneAddedBull: 'Funkce je aktivní při alespoň jednom přidaném býkovi do seznamu.',
            saveBullList: 'Uložit seznam býků',
            exportBulls: 'Exportovat býky',
            deleteBulls: 'Smazat býky',
            activeWithAtLeastOneCriteriaList: 'Funkce je aktivní při alespoň jednom uloženém seznamu kritérií.',
            loadCriteriaFromList: 'Načíst parametry ze seznamu',
            manageSavedLists: 'Spravovat uložené seznamy',
            chooseAllOrganizations: 'Vybrat všechny organizace',
        },
        'mating/matingCowsFilterForm': {
            saveCriterias: 'Uložit seznam parametrů',
            actionRewritesAnimalList: 'Tato akce přepíše aktuální seznam zvířat.',
            formIsInvalid: 'Formulář je nevalidní.',
            filter: 'Filtrovat',
            activeWithAtLeastOneCriteriaList: 'Funkce je aktivní při alespoň jednom uloženém seznamu kritérií.',
            loadCriteriaFromList: 'Načíst parametry ze seznamu',
            manageSavedLists: 'Spravovat uložené seznamy',
            breeder: 'Chovatel',
            farms: 'Hospodářství',
            loadingMatingPlan: 'Načítání připařovacího plánu…',
            addCows: 'Přidat plemenice',
            addCowsFromFile: 'Přidat plemenice ze souboru',
            activeWithAtLeastOneCowList: 'Funkce je aktivní při alespoň jednom uloženém seznamu plemenic.',
            addCowsFromList: 'Přidat plemenice ze seznamu',
            activeWithAtLeastOneAddedCow: 'Funkce je aktivní při alespoň jedné přidané plemenici do seznamu.',
            saveCowsList: 'Uložit seznam plemenic',
            exportCows: 'Exportovat plemenice',
            deleteCows: 'Smazat plemenice',
            numberOfAnimals: '{{number}} ks',
        },
        'mating/modalAddAnimals': {
            femaleTitle: 'Zadejte ušní číslo',
            maleTitle: 'Zadejte linie a registr',
            chooseAnimalList: 'Vyberte seznam zvířat',
            animalList: 'Seznam zvířat',
            addingAnimalsFromFile: 'Probíhá proces vkládání jedinců ze souboru do aplikace.',
        },
        'mating/modalListName': {
            name: 'Název',
            enterCriteriaListName: 'Zadejte jméno seznamu kritérií',
            confirmListName: 'Potvrďte jméno seznamu',
            listAlreadyExists: 'Seznam se zvoleným názvem již existuje. Přejete si přepsat uložený seznam?',
            atLeastOneAnimal: 'Seznam zvířat musí obsahovat minimálně jedno zvíře.',
            saveBreeders: 'Uložení plemenic',
            saveBulls: 'Uložení býků',
        },
        'mating/modalExportAnimals': {
            enterFileName: 'Vyplňte jméno souboru.',
            atLeastOneAnimal: 'Seznam zvířat musí obsahovat minimálně jedno zvíře.',
            fileNameLength: 'Délka jména souboru je maximálně 75 znaků.',
            incorrectFileName: 'Název souboru obsahuje nepovolené znaky. Můžete zkusit: export-zvirat',
        },
        'mating/modalGetCriteriaDetails': {
            criteriaList: 'Seznam parametrů',
            defaultList: 'základní seznam',
            chooseCriteriaList: 'Vyberte seznam parametrů výběru',
        },
        'mating/modalListsOverview': {
            name: 'Název',
            cantSaveParams: 'Uložení seznamu parametrů není možné v důsledku nevalidních dat, která byla zadána.',
            paramsSubmitTooltip: 'Jméno nemůže být prázdné.',
            noSavedLists: 'Nebyly uloženy žádné seznamy.',
            managementOfSavedLists: 'Správa uložených seznamů',
            listAlreadyExists: 'Seznam se zvoleným názvem již existuje. Přejete si přepsat uložený seznam?',
            areYouSure: 'Jste si jisti, že chcete opravdu smazat tento seznam?',
            listAlreadyExistsWarning: 'Uložením nového seznamu přepíšete původní.',
            nameAlreadyExistsInTableWarning: 'Uložením nového seznamu přepíšete existující nastavení tabulky.',
        },
        'mating/modalSaveScoringResults': {
            enterFileName: 'Zadejte název souboru.',
            maxFileNameLength: 'Délka názvu souboru je maximálně 100 znaků.',
            enterFileNameForMatingPlan: 'Zadejte jméno souboru pro uložení přípařovacího plánu',
        },
        'mating/matingParamsSelectionForm': {
            mustBeFilledIn: 'Musí být vyplněné',
            wrongCoefficientFormat: 'Koeficient má nesprávný formát',
            inbreedingCoefficientOutsideAllowedRange: 'Koeficient je mimo povolený rozsah',
            weightOutsideAllowedRange: 'Váha je mimo povolený rozsah',
            idealWeightOutsideAllowedRange: 'Váha znaku je mimo povolený rozsah',
            code: 'Kód',
            loadingCalculationCriteria: 'Načítání parametrů výpočtu…',
            formInvalid: 'Formulář není validní',
            noBullsOrCowsSelected: 'Nemáte vybrány plemenice nebo býky',
            scoreIsBeingCalculated: 'Probíhá výpočet skóre',
            productionSignsWeight: 'Váhy produkčních znaků',
            medicalSignsWeight: 'Váhy zdravotních znaků',
            functionalSignsWeight: 'Váhy funkčních znaků',
            signWeight: 'Váha znaku',
            shareOfSignsInTotalScore: 'Podíl skupin znaků na celkovém skóre',
            calculateScore: 'Vypočíst skóre',
            noListAvailable: 'Není k dispozici žádný seznam parametrů',
            loadCriteriaFromList: 'Načíst parametry ze seznamu',
            formIsInvalid: 'Formulář je nevalidní',
            saveCriteriaList: 'Uložit seznam parametrů',
            manageSavedLists: 'Spravovat uložené seznamy',
            inPoints: 'V bodech',
            upToFourExterior: 'Doporučujeme vybrat maximálně čtyři znaky exteriéru.',
            mustBeWholeNumber: 'Hodnota musí být celé číslo.',
        },
        'mating/resultTab': {
            resetPreferencesRecalculation: 'Resetovat přepočet preferencí',
            recalculateAccordingToSelectedPreferences: 'Přepočíst dle zvolených preferencí',
            saveResultToFile: 'Uložit výsledek do souboru',
            noPercentagesEntered: 'Nemáte vyplněna žádná procenta',
            sumOfPercentagesDoesNotAddUp: 'Součet procent není roven 100',
            lesserThan: 'Menší než',
            greaterThan: 'Větší než',
            total: 'Celkem',
            bullNumber: 'Číslo býka',
            bull: 'Býk {{number}}',
            frequency: 'Četnost',
            share: '% podíl',
            number: 'Číslo',
            score: 'Skóre',
            bullInbreeding: 'Příb.',
            lineRegistry: 'LIN-REG',
            bullName: 'Jméno',
        },
        'mating/sagas': {
            saveAnimalList: 'Uložení seznamu zvířat',
            listOfStallionsSavedSuccessfully: 'Seznam plemenic úspěšně uložen',
            loadingBullList: 'Načtení seznamu býků',
            noBullsMatchTheFilter: 'Filtru neodpovídají žádní býci.',
            renameAnimalList: 'Přejmenování seznamu zvířat',
            deleteAnimals: 'Smazání zvířat',
            animalsDeleted: 'Zvířata byla úspěšně smazána.',
            loadSettingsList: 'Načtení seznamu nastavení',
            resetAllSettings: 'Obnovení všech nastavení',
            resetComplete: 'Obnovení dokončeno.',
            loadCowList: 'Načtení seznamu krav',
            noStudsMatchTheFilter: 'Filtru neodpovídají žádné plemenice.',
            loadCriteriaList: 'Načtení parametrů výběru',
            criteriaLoaded: 'Parametry byly úspěšně načteny',
            renameCriteriaList: 'Přejmenování seznamu kritérií',
            criteriaListRenamed: 'Seznam kritérií úspěšně přejmenován',
            removeAnimal: 'Odebrání zířete',
            animalRemoved: 'Zvíře bylo odebráno.',
            deleteAnimalList: 'Odstranění seznamu zvířat',
            animalListDeleted: 'Seznam zvířat byl úspěšně smazán',
            addAnimalsFromFile: 'Přidání zvířat ze souboru',
            loadFarmsForBreeder: 'Načtení hospodářství pro chovatele',
            createCriteriaList: 'Vytvoření seznamu kritérií',
            criteriaListCreated: 'Seznam kritérií úspěšně vytvořen',
            loadCriteriaLists: 'Načtení seznamů kritérií',
            loadBullOrganizations: 'Načtení organizací býků',
            deleteCriteriaList: 'Odstranění seznamu kritérií',
            criteriaListDeleted: 'Seznam kritérií byl úspěšně smazán',
            addAnimals: 'Přidání zvířat',
            animalsFiltering: 'Filtrování zvířat',
            loadMatingScore: 'Načtení výsledků hodnocení',
            inbreedingExceeded: 'Z důvodu překročení maximální hodnoty příbuznosti se mating skóre nepočítalo pro',
            pairs: 'párů',
            loadAnimalsList: 'Načtení seznamů zvířat',
            animalsAdded: 'Zvířata byla úspěšně přidána.',
            exportAnimals: 'Export zvířat do CSV',
            addAnimalsFromDb: 'Přidání zvířat z databáze',
            animalsAddedFromDb: 'Zvířata byla úspěšně přidána z databáze.',
            saveCriteriaList: 'Uložení seznamu kritérií',
            criteriaListSaved: 'Seznam kritérií úspěšně uložen',
            createAnimalList: 'Vytvoření seznamu zvířat',
            animalListCreated: 'Seznam zvířat úspěšně vytvořen',
            loadBreederList: 'Načtení seznamu chovatelů',
            loadAccordionDefinitions: 'Načtení definice akoredonů',
            loadCriteriaDefinitionsFor: 'Načtení definice kritérií pro',
            animalListRenamed: 'Seznam zvířat úspěšně přejmenován',
        },
    },
    EN: {
        'mating/mating': {
            pageTitle: 'Mating plan - H',
            matingPlan: 'Mating plan',
            cowGroupSelection: 'Cow group selection',
            bullGroupSelection: 'Bull group selection',
            loadingMatingPlan: 'Loading mating plan...',
            calcParamsSelection: 'Calculation parameters selection',
            result: 'Result',
            resetAllSettings: 'Reset all settings',
        },
        'mating/storeAnimalLists': {
            animalListName: 'Animal list name',
        },
        'mating/bullSelector': {
            pageTitleH: 'Bullselector - H',
            pageTitleC: 'Bullselector - C',
            loadingBullSelector: 'Loading Bullselector...',
        },
        'mating/matingBullsTable': {
            lineRegistry: 'Line-Registry',
            name: 'Name',
            organizationShort: 'Org.',
            organization: 'Organization',
            milkProductionIndex: 'DSI MLK',
            milkProductionIndexTooltip: 'Milk production index',
            roboticMilkingIndex: 'RIH',
            productionTraitsCode: 'Mlk Code',
            exteriorTraitsCode: 'Ext code',
            exteriorReliability: 'Ext reli',
            extremitiesIndex: 'DSI Ext',
            udderIndex: 'DSI Udd',
            longevityIndex: 'DSI LON',
            rbvMothersChildbirthDifficulty: 'RBV Mot Chi Dif',
            rbvLongevity: 'Longevity',
            rbvHeifersFertility: 'RBV Hief Ferti',
            rbvDaughtersFertility: 'RBV Daug Fert',
            rbvMilkability: 'RBV Milkability',
            rbvFirstChildbirthDifficulty: 'RBV 1st Chi Dif',
            rbvAllChildBirthDifficulties: 'RPH Chi Dif',
        },
        'mating/matingCowsTable': {
            earTag: 'ET',
            farmCode: 'Farm code',
            lastCalvingAt: 'Last calv.',
            lactation: 'Lactation',
            lastAdmissionAt: 'Last adm.',
            lastAdmissionAtTooltip: 'Last admission at',
            pregnant: 'Pregnant',
            lactationDays: 'Mlk days',
            milkKg: 'Mlk Kg',
            fatKg: 'F Kg',
            fatPercentage: 'F %',
            proteinKg: 'P Kg',
            proteinPercentage: 'P %',
            redIndex: 'RED',
            milkStrength: 'MLS',
            bodyStructure: 'BST',
            extremities: 'Extremities',
            udder: 'Udder',
            overallClass: 'Class',
            overallCondition: 'Over. cond.',
            genotype: 'Genot.',
        },
        'mating/matingTableCommon': {
            birthdayShort: 'Birthday',
            bvMilkKg: 'BV Mlk Kg',
            bvMilkKgReliability: 'RELI BV Mlk Kg',
            bvFatKg: 'BV F Kg',
            bvFatPercentage: 'BV F %',
            bvProteinKg: 'BV P Kg',
            bvProteinPercentage: 'BV P %',
            sihIndex: 'SIH',
            inbreedingCoefficient: 'INBR COEF',
            rbvMilkKg: 'RBV Mlk Kg',
            rbvFatKg: 'RBV F KG',
            rbvFatPercentage: 'RBV % fat',
            rbvProteinKg: 'RBV P Kg',
            rbvProteinPercentage: 'RBV P %',
            rbvSomaticCells: 'RBV SC',
            rbvClinicalMastitis: 'RBV CM',
            rbvLimbDisease: 'RBV OCD',
            rbvFrame: 'RBV FRA',
            rbvChestWidth: 'RBV CHW',
            rbvBodyDepth: 'RBV BDE',
            rbvEdginess: 'RBV EDG',
            rbvBackAngle: 'RBV BAA',
            rbvBackWidth: 'RBV BWD',
            rbvRearPosture: 'RBV RPO',
            rbvSidePosture: 'RPH PZB',
            rbvHoovesAngle: 'RBV HOA',
            rbvForeUdderAttachment: 'RBV FUA',
            rbvFrontTeatsPlacement: 'RBV FTP',
            rbvTeatsLength: 'RBV TEL',
            rbvUdderDepth: 'RBV UDE',
            rbvRearUdderAttachmentHeight: 'RBV RUH',
            rbvSuspensoryLigament: 'RBV SUL',
            rbvRearTeatsPlacement: 'RBV RTP',
            rbvRearUdderAttachmentWidth: 'RBV RUW',
            rbvBonesQuality: 'RBV BQU',
            rbvWalkQuality: 'RBV WAQ',
            rbvFitness: 'RBV FIT',
            rbvMilkStrength: 'RBV MLS',
            rbvBodyStructure: 'RBV BST',
            rbvExtremities: 'RBV Ext',
            rbvUdder: 'RBV Udd',
            rbvOverallCondition: 'RBV Overall',
        },
        'mating/matingBullsFilterForm': {
            saveCriterias: 'Save criterias',
            actionRewritesAnimalList: 'This action rewrites the current animal list.',
            formIsInvalid: 'Form is invalid.',
            filter: 'Filter',
            addBulls: 'Add bulls',
            addBullsFromFile: 'Add bulls from a file',
            activeWithAtLeastOneBullList: 'Function is active when at least one bull list is added.',
            addBullsFromList: 'Add bulls from a list',
            activeWithAtLeastOneAddedBull: 'Function is active when at least one bull is added to the list.',
            saveBullList: 'Save bull list',
            exportBulls: 'Export bulls',
            deleteBulls: 'Delete bulls',
            activeWithAtLeastOneCriteriaList: 'Function is active when at least one criteria list is added.',
            loadCriteriaFromList: 'Load criteria from list',
            manageSavedLists: 'Manage saved lists',
            chooseAllOrganizations: 'Choose all organizations',
        },
        'mating/matingCowsFilterForm': {
            saveCriterias: 'Save criterias',
            actionRewritesAnimalList: 'This action rewrites the current animal list.',
            formIsInvalid: 'Form is invalid.',
            filter: 'Filter',
            activeWithAtLeastOneCriteriaList: 'Function is active when at least one criteria list is added.',
            loadCriteriaFromList: 'Load criteria from list',
            manageSavedLists: 'Manage saved lists',
            breeder: 'Breeder',
            farms: 'Farms',
            loadingMatingPlan: 'Loading mating plan...',
            addCows: 'Add cows',
            addCowsFromFile: 'Add cows from a file',
            activeWithAtLeastOneCowList: 'Function is active when at least one cow list is added.',
            addCowsFromList: 'Add cows from a list',
            activeWithAtLeastOneAddedCow: 'Function is active when at least one cow is added to the list.',
            saveCowsList: 'Save cows list',
            exportCows: 'Export cows',
            deleteCows: 'Delete cows',
            numberOfAnimals: '{{number}} pcs',
        },
        'mating/modalAddAnimals': {
            femaleTitle: 'Enter ear tag',
            maleTitle: 'Enter line-registry',
            chooseAnimalList: 'Choose animal list',
            animalList: 'Animal list',
            addingAnimalsFromFile: 'The process of adding individuals from a file to the application is in progress.',
        },
        'mating/modalListName': {
            name: 'Name',
            enterCriteriaListName: 'Enter criteria list name',
            confirmListName: 'Confirm list name',
            listAlreadyExists: 'A list with the name you entered already exists. Do you wish to overwrite the saved list?',
            atLeastOneAnimal: 'Animal list has to contain at least one animal.',
            saveBreeders: 'Save breeders',
            saveBulls: 'Save bulls',
        },
        'mating/modalExportAnimals': {
            enterFileName: 'Enter file name.',
            atLeastOneAnimal: 'Animal list has to contain at least one animal.',
            fileNameLength: 'The maximum file name length is 75 characters.',
            incorrectFileName: 'The file name contains illegal characters. You can try: animal-export',
        },
        'mating/modalGetCriteriaDetails': {
            criteriaList: 'Criteria list',
            defaultList: 'default list',
            chooseCriteriaList: 'Choose criteria list',
        },
        'mating/modalListsOverview': {
            name: 'Name',
            cantSaveParams: 'The saving of the parameter list is not possible due to invalid data that was entered.',
            paramsSubmitTooltip: 'Name cannot be empty.',
            noSavedLists: 'No lists were saved.',
            managementOfSavedLists: 'Management of saved lists',
            listAlreadyExists: 'A list with the name you entered already exists. Do you wish to overwrite the saved list?',
            areYouSure: 'Are you sure you want to delete this list?',
            listAlreadyExistsWarning: 'Saving the new list will overwrite the existing one.',
            nameAlreadyExistsInTableWarning: 'Saving the new list will overwrite the existing table setting name.',
        },
        'mating/modalSaveScoringResults': {
            enterFileName: 'Enter file name.',
            maxFileNameLength: 'The maximum length for the file name is 100 characters.',
            enterFileNameForMatingPlan: 'Enter the name for the file you want to save the mating plan into',
        },
        'mating/matingParamsSelectionForm': {
            mustBeFilledIn: 'Must be filled in',
            wrongCoefficientFormat: 'The coefficient is formatted incorrectly.',
            inbreedingCoefficientOutsideAllowedRange: 'The coefficient is outside of allowed range',
            weightOutsideAllowedRange: 'Weight is outside of allowed range',
            idealWeightOutsideAllowedRange: 'Ideal weight is outside of allowed range',
            code: 'Code',
            loadingCalculationCriteria: 'Loading calculation criteria...',
            formInvalid: 'Form is invalid',
            noBullsOrCowsSelected: 'You have no bulls or cows selected',
            scoreIsBeingCalculated: 'Score is being calculated',
            productionSignsWeight: 'Weights of production signs',
            medicalSignsWeight: 'Weights of medical signs',
            functionalSignsWeight: 'Weights of functional signs',
            signWeight: 'Sign weight',
            shareOfSignsInTotalScore: 'The share of groups of characters in the total score',
            calculateScore: 'Calculate score',
            noListAvailable: 'No criteria list available',
            loadCriteriaFromList: 'Load criteria from list',
            formIsInvalid: 'Form is invalid',
            saveCriteriaList: 'Save criteria list',
            manageSavedLists: 'Manage saved lists',
            inPoints: 'In points',
            upToFourExterior: 'We recommend choosing a maximum of four exterior traits.',
            mustBeWholeNumber: 'The value must be a whole number.',
        },
        'mating/resultTab': {
            resetPreferencesRecalculation: 'Reset preferences recalculation',
            recalculateAccordingToSelectedPreferences: 'Recalculate according to selected preferences',
            saveResultToFile: 'Save result to file',
            noPercentagesEntered: 'You have no percentages entered',
            sumOfPercentagesDoesNotAddUp: 'The sum of the percentages is not equal to 100',
            lesserThan: 'Lesser than',
            greaterThan: 'Greater than',
            total: 'Total',
            bullNumber: 'Bull number',
            bull: 'Bull {{number}}',
            frequency: 'Frequency',
            share: '% share',
            number: 'Number',
            score: 'Score',
            bullInbreeding: 'Inbr.',
            lineRegistry: 'LIN-REG',
            bullName: 'Name',
        },
        'mating/sagas': {
            saveAnimalList: 'Save animal list',
            listOfStallionsSavedSuccessfully: 'List of stallions saved successfully',
            loadingBullList: 'Load bull list',
            noBullsMatchTheFilter: 'No bulls match the filter.',
            renameAnimalList: 'Rename animal list',
            deleteAnimals: 'Delete animals',
            animalsDeleted: 'Animals were deleted successfully.',
            loadSettingsList: 'Load settings list',
            resetAllSettings: 'Reset all settings',
            resetComplete: 'Reset complete.',
            loadCowList: 'Load cow list',
            noStudsMatchTheFilter: 'No studs match the filter.',
            loadCriteriaList: 'Load criteria list',
            criteriaLoaded: 'Criteria were successfully loaded',
            renameCriteriaList: 'Rename criteria list',
            criteriaListRenamed: 'Criteria list was renamed successfully',
            removeAnimal: 'Remove animal',
            animalRemoved: 'Animal was removed.',
            deleteAnimalList: 'Delete animal list',
            animalListDeleted: 'Animal list was successfully deleted',
            addAnimalsFromFile: 'Add animals from file',
            loadFarmsForBreeder: 'Load farms for breeder',
            createCriteriaList: 'Create criteria list',
            criteriaListCreated: 'Criteria list was created successfully',
            loadCriteriaLists: 'Load criteria list',
            loadBullOrganizations: 'Load bull organizations',
            deleteCriteriaList: 'Delete criteria list',
            criteriaListDeleted: 'Criteria list was successfully deleted',
            addAnimals: 'Add animals',
            animalsFiltering: 'Animals filtering',
            loadMatingScore: 'Load mating score',
            inbreedingExceeded:
                'Due to exceeding the maximum value of relatedness, the mating score was not calculated for',
            pairs: 'pairs',
            loadAnimalsList: 'Load animals list',
            animalsAdded: 'Animals were successfully added.',
            exportAnimals: 'Export animals to CSV',
            addAnimalsFromDb: 'Add animals from DB',
            animalsAddedFromDb: 'Animals were successfully added from the database.',
            saveCriteriaList: 'Save criteria list',
            criteriaListSaved: 'Criteria list was saved successfully',
            createAnimalList: 'Create animal list',
            animalListCreated: 'Animals list was created successfully',
            loadBreederList: 'Load breeder list',
            loadAccordionDefinitions: 'Load accordion definitions',
            loadCriteriaDefinitionsFor: 'Load criteria definitions for',
            animalListRenamed: 'Animal list renamed successfully',
        },
    },
};
