import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {GetUserProfileAction, userAction} from '../action';

const tt = t('user/profile');

function* execute({payload}: GetUserProfileAction): SagaIterator {
    const {userProfileId, isFromUserProfile} = payload;
    const response = yield* call(
        (isFromUserProfile ? Api.getLoggedInUserProfile : Api.getUserProfileV2),
        {userProfileId},
    );

    if (response.isSuccess) {
        yield* put(userAction.getUserProfileSuccess(response.data));
    } else {
        yield putAll(showBeError(response, tt('profile')));
    }
}

export function* getUserProfileSaga(): SagaIterator {
    yield takeLatestF('user/GET_USER_PROFILE', execute);
}
