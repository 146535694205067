import { Table, useVirtualPagination } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { ScoringResult } from 'api/gen/ScoringResult';
import { useOurTranslation } from 'app/translations';
import { LayoutDiv } from 'common/layout';
import { getScoringResultsColumns, ScoringResultsRow } from './scoring-results-columns';
const convertRow = (row: ScoringResult, index: number): ScoringResultsRow => ({
  id: index,
  earTag: row.earTag,
  bull1LineRegistry: row.bulls?.[0]?.lineRegistry,
  bull1Name: row.bulls?.[0]?.name,
  bull1Score: row.bulls?.[0]?.score,
  bull1Inbreeding: row.bulls?.[0]?.inbreeding ?? undefined,
  bull2LineRegistry: row.bulls?.[1]?.lineRegistry,
  bull2Name: row.bulls?.[1]?.name,
  bull2Score: row.bulls?.[1]?.score,
  bull2Inbreeding: row.bulls?.[1]?.inbreeding ?? undefined,
  bull3LineRegistry: row.bulls?.[2]?.lineRegistry,
  // eslint-disable-line no-magic-numbers
  bull3Name: row.bulls?.[2]?.name,
  // eslint-disable-line no-magic-numbers
  bull3Score: row.bulls?.[2]?.score,
  // eslint-disable-line no-magic-numbers
  bull3Inbreeding: row.bulls?.[2]?.inbreeding ?? undefined,
  // eslint-disable-line no-magic-numbers

  bull4LineRegistry: row.bulls?.[3]?.lineRegistry,
  // eslint-disable-line no-magic-numbers
  bull4Name: row.bulls?.[3]?.name,
  // eslint-disable-line no-magic-numbers
  bull4Score: row.bulls?.[3]?.score,
  // eslint-disable-line no-magic-numbers
  bull4Inbreeding: row.bulls?.[3]?.inbreeding ?? undefined,
  // eslint-disable-line no-magic-numbers

  bull5LineRegistry: row.bulls?.[4]?.lineRegistry,
  // eslint-disable-line no-magic-numbers
  bull5Name: row.bulls?.[4]?.name,
  // eslint-disable-line no-magic-numbers
  bull5Score: row.bulls?.[4]?.score,
  // eslint-disable-line no-magic-numbers
  bull5Inbreeding: row.bulls?.[4]?.inbreeding ?? undefined // eslint-disable-line no-magic-numbers
});
interface Props {
  scoringResults: Array<ScoringResult>;
}
const ScoringResultsTableBase: FC<Props> = ({
  scoringResults
}) => {
  const {
    t,
    tCommon
  } = useOurTranslation('mating/resultTab');
  const rows = useMemo(() => scoringResults.map(convertRow), [scoringResults]);
  const columns = useMemo(() => getScoringResultsColumns(t, tCommon), [t, tCommon]);
  const {
    paginatedRows,
    totalItems,
    pagination,
    setPagination
  } = useVirtualPagination(rows);
  return <LayoutDiv marginCenter maxWidth={1900} data-sentry-element="LayoutDiv" data-sentry-component="ScoringResultsTableBase" data-sentry-source-file="scoring-results-table.tsx">
            <Table tableId="scoringResults" columns={columns} rows={paginatedRows} totalItems={totalItems} size="small" bordered pagination={pagination} onPaginationChange={setPagination} scroll={{
      x: true
    }} data-sentry-element="Table" data-sentry-source-file="scoring-results-table.tsx" />
        </LayoutDiv>;
};
export const ScoringResultsTable = memo(ScoringResultsTableBase);