import { Loader, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { FC, useCallback, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import * as routerActions from 'redux-first-history';
import { Opt } from 'ts-opt';
import { BreedingFemale } from 'api/gen/BreedingFemale';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { BackButton } from 'common/buttons';
import { Card, ScreenHeader } from 'common/layout';
import { replaceSpacesWithUnbreakableSpaces } from 'utils/replace-spaces-with-unbreakable-spaces';
import { CowBrowserTable } from '../components/cow-browser';
import { animalBrowserAction, simpleAnimalBrowserSelector } from '../model';
import { AnimalBrowserAction } from '../model/action';
interface DispatchProps {
  getCows(tableDataRequest: Opt<TableDataRequest<BreedingFemale>>): void;
  goTo(path: string): void;
  clearStableName(): void;
}
type Props = DispatchProps;
const CowBrowserBase: FC<Props> = props => {
  const stableInfo = useSelector(simpleAnimalBrowserSelector.stableInfo);
  const exportInProgress = useSelector(simpleAnimalBrowserSelector.exportInProgress);
  const stableNameOpt = stableInfo.prop('code');
  const {
    t,
    tCommon
  } = useOurTranslation('cowBrowserTable');
  const {
    t: pageTitle
  } = useOurTranslation('stableTable');
  const {
    getCows,
    goTo,
    clearStableName
  } = props;
  const goBackToList = useCallback(() => {
    clearStableName();
    goTo('/animal-browser/stables');
  }, [goTo, clearStableName]);
  const cardTitle = useMemo(() => stableNameOpt.map(stableName => `${t('tableName')}: ${replaceSpacesWithUnbreakableSpaces(stableName)}`).caseOf(title => <ScreenHeader title={title} />, () => <Loader show />), [stableNameOpt, t]);
  return <Content browserTabTitle={pageTitle('pageTitle')} data-sentry-element="Content" data-sentry-component="CowBrowserBase" data-sentry-source-file="cow-browser.tsx">
            <Card title={cardTitle} titleSize="big" extra={<BackButton text={tCommon('backToList')} onClick={goBackToList} />} noPadding data-sentry-element="Card" data-sentry-source-file="cow-browser.tsx">
                <CowBrowserTable exportInProgress={exportInProgress} getCows={getCows} data-sentry-element="CowBrowserTable" data-sentry-source-file="cow-browser.tsx" />
            </Card>
        </Content>;
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getCows: (tableDataRequest: Opt<TableDataRequest<BreedingFemale>>): AnimalBrowserAction => dispatch(animalBrowserAction.getcowBrowserTableData(tableDataRequest)),
  goTo: (path: string): void => void dispatch(routerActions.push(path)),
  clearStableName: (): AnimalBrowserAction => dispatch(animalBrowserAction.clearStableName())
});
export const CowBrowser = connect(null, mapDispatchToProps)(CowBrowserBase);