import { Modal } from '@fl/cmsch-fe-library';
import React, { FC, Fragment, memo } from 'react';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { usePermissionsScreenSetup } from '../../hooks/use-permissions-screen-setup';
import { simpleAdminSelector } from '../../model/selector';
import { PermissionForm } from '../permissions-form';
import { permissionsColumns } from './columns';
const PermissionsTableBase: FC = () => {
  const {
    tTable,
    t,
    id,
    isCreateModalVisible,
    getPermissionDetails,
    handleCancelEditPermission,
    handleCancelCreatePermission,
    handleCreatePermission,
    openCreatePermissionModal,
    handleSubmitEditPermission,
    actionSettings,
    headerButtons
  } = usePermissionsScreenSetup();
  return <Fragment>
            <ConnectedTable tableId="permissions" columns={permissionsColumns} onFetchTableData={getPermissionDetails} rowDataPageSelector={simpleAdminSelector.permissionDetailsPage} enableTableSettings withoutVerticalScrollbar paginationPosition="both" lazyLoadingLimit={20} enableTableUserSettings showFulltextFilter headerButtons={headerButtons} emptyTableMessages={{
      noRows: tTable('noRows')
    }} highlightOnEdit actionSettings={actionSettings} emptyTableContent={<Button role="create" onClick={openCreatePermissionModal}>
                        {t('newPermission')}
                    </Button>} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
            <Modal title={t('editPermission')} visible={id !== null} onCancel={handleCancelEditPermission} footer={null} width={520} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
                <Ant.Row data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Ant.Col xs={24} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <PermissionForm mode="edit" permissionId={id} onSubmit={handleSubmitEditPermission} onCancel={handleCancelEditPermission} data-sentry-element="PermissionForm" data-sentry-source-file="index.tsx" />
                    </Ant.Col>
                </Ant.Row>
            </Modal>
            <Modal title={t('createNewPermission')} visible={isCreateModalVisible} onCancel={handleCancelCreatePermission} footer={null} width={520} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
                <Ant.Row data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Ant.Col xs={24} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <PermissionForm mode="create" permissionId={null} onSubmit={handleCreatePermission} onCancel={handleCancelCreatePermission} data-sentry-element="PermissionForm" data-sentry-source-file="index.tsx" />
                    </Ant.Col>
                </Ant.Row>
            </Modal>
        </Fragment>;
};
export const PermissionsTable = memo(PermissionsTableBase);