import {addPermissionToRoleSaga} from './add-permission-to-role';
import {changeSubordinateRoleSago} from './change-subordinate-role';
import {createAssociationRolesSaga} from './create-association-roles-saga';
import {createBreederSaga} from './create-breeder-saga';
import {createOrganizationsSaga} from './create-organizations-saga';
import {createOtherSubjectsSaga} from './create-other-subjects-saga';
import {createPermissionSaga} from './create-permission-saga';
import {createRoleSaga} from './create-role-saga';
import {createUserContactSaga} from './create-user-contact';
import {createUserMembershipSaga} from './create-user-membership-saga';
import {deletePermissionSaga} from './delete-permission-saga';
import {deleteRoleSaga} from './delete-role-saga';
import {deleteUserContactSaga} from './delete-user-contact-saga';
import {updatePermissionSaga} from './edit-permission-saga';
import {updateRoleSaga} from './edit-role-saga';
import {updateUserContactSaga} from './edit-user-contact-saga';
import {appInfoSaga} from './get-app-info';
import {getMemberOfSubjectsSaga} from './get-member-of-subjects-saga';
import {getOrganizationsSaga} from './get-organizations-saga';
import {getPermissionDetails} from './get-permission-details-saga';
import {getRolePermissionsV2Saga} from './get-role-permissions-saga';
import {getRoleSaga} from './get-role-saga';
import {getRoleUnassignedPermissionsV2Saga} from './get-role-unassigned-permissions-saga';
import {getRoles} from './get-roles-saga';
import {getSubordinateAssignedRolesSaga} from './get-subordinate-assigned-role-saga';
import {getSubordinateUnassignedRolesSaga} from './get-subordinate-unassigned-role-saga';
import {getUserAssociationsSaga} from './get-user-associations-saga';
import {getUserBreedersSaga} from './get-user-breeders';
import {getUserContactsSaga} from './get-user-contacts-saga';
import {getUserOtherSubjectRolesSaga} from './get-user-other-subject-roles-saga';
import {getUserSaga} from './get-user-saga';
import {getUsersSaga} from './get-users-saga';
import {removePermissionFromRoleSaga} from './remove-permission-from-role';
import {updateAssociationRolesSaga} from './update-association-roles-saga';
import {updateBreederSaga} from './update-breeder-saga';
import {updateOrganizationsSaga} from './update-organizations-saga';
import {updateOtherSubjectsSaga} from './update-other-subjects-saga';
import {updateUserMembershipSaga} from './update-user-membership-saga';
import {validatePermissionSaga} from './validate-permission-saga';
import {validateRoleSaga} from './validate-role-saga';

export const adminSagas = [
    appInfoSaga,
    updateBreederSaga,
    createBreederSaga,
    createOrganizationsSaga,
    getOrganizationsSaga,
    createAssociationRolesSaga,
    createOtherSubjectsSaga,
    updateOtherSubjectsSaga,
    updateOrganizationsSaga,
    updateAssociationRolesSaga,
    createUserContactSaga,
    deleteUserContactSaga,
    updateUserContactSaga,
    getRolePermissionsV2Saga,
    getRoleUnassignedPermissionsV2Saga,
    removePermissionFromRoleSaga,
    addPermissionToRoleSaga,
    getPermissionDetails,
    deletePermissionSaga,
    updatePermissionSaga,
    validatePermissionSaga,
    createPermissionSaga,
    getRoles,
    deleteRoleSaga,
    createRoleSaga,
    validateRoleSaga,
    updateRoleSaga,
    getRoleSaga,
    getSubordinateAssignedRolesSaga,
    getSubordinateUnassignedRolesSaga,
    changeSubordinateRoleSago,
    getUsersSaga,
    getUserSaga,
    getMemberOfSubjectsSaga,
    updateUserMembershipSaga,
    createUserMembershipSaga,
    getUserContactsSaga,
    getUserAssociationsSaga,
    getUserOtherSubjectRolesSaga,
    getUserBreedersSaga,
];
