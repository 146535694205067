import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {SagaIterator} from 'redux-saga';
import {pipe} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {AnalysisProtocol} from 'api/gen/AnalysisProtocol';
import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';

import {apcReportFormName, ApcReportFormValues} from '../../components/apc-report-form/form-values';
import {convertApcReportToTripleAttributeValue} from '../../utils/convert-apc-report-to-triple-attribute-value';
import {analysisProtocolCorrectionAction, GetAnalysisProtocolAction} from '../action';

const formValuesO = O.optic<ApcReportFormValues>();

const updateFormValuesFromAnalysisProtocol = (
    formValues: ApcReportFormValues,
    analysisProtocol: AnalysisProtocol,
): ApcReportFormValues => pipe(
    formValues,
    O.set(formValuesO.prop('milkKg'))(analysisProtocol.milkKg),
    O.set(formValuesO.prop('proteinPercentage'))(analysisProtocol.proteinPercentage),
    O.set(formValuesO.prop('fatPercentage'))(analysisProtocol.fatPercentage),
    O.set(formValuesO.prop('urea'))(analysisProtocol.urea),
    O.set(formValuesO.prop('milkingTripleAttribute'))(convertApcReportToTripleAttributeValue(analysisProtocol)),
    O.set(formValuesO.prop('somaticCells'))(analysisProtocol.somaticCells),
    O.set(formValuesO.prop('lactosePercentage'))(analysisProtocol.lactosePercentage),
);

function* execute({payload: {earTag, proficiencyTestDate}}: GetAnalysisProtocolAction): SagaIterator {
    yield* put(analysisProtocolCorrectionAction.setLoading('analysisProtocolLoading', true));

    const response = yield* call(Api.getAnalysisProtocol, {earTag, proficiencyTestDate});

    if (response.isSuccess) {
        const formValues = (yield* select(formHelpers.formValues(apcReportFormName))).orCrash('no form values');
        yield* put(formHelpers.initialize(
            apcReportFormName,
            updateFormValuesFromAnalysisProtocol(formValues, response.data),
        ));
        yield* put(analysisProtocolCorrectionAction.getAnalysisProtocolSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('apc/sagas')('analysisProtocol')));
    }
    yield* put(analysisProtocolCorrectionAction.setLoading('analysisProtocolLoading', false));
}

export function* getAnalysisProtocolSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/GET_ANALYSIS_PROTOCOL', execute);
}
