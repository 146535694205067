export const systemAction = {
    checkFEVersion: (showErrorOnVersionMismatchAndNoNewFeVersion?: boolean) => ({
        type: 'system/CHECK_FE_VERSION',
        payload: {showErrorOnVersionMismatchAndNoNewFeVersion},
    }) as const,
    changeRefreshModalVisibility: (visible: boolean) => ({
        type: 'system/CHANGE_REFRESH_MODAL_VISIBILITY',
        payload: {visible},
    }) as const,
    changeServerError: (error: boolean) => ({
        type: 'system/CHANGE_SERVER_ERROR',
        payload: {error},
    }) as const,
};

export type CheckFEVersionAction = ReturnType<typeof systemAction.checkFEVersion>;
export type ChangeRefreshModalVisibilityAction = ReturnType<typeof systemAction.changeRefreshModalVisibility>;
export type ChangeServerErrorAction = ReturnType<typeof systemAction.changeServerError>;

export type SystemAction =
    | CheckFEVersionAction
    | ChangeRefreshModalVisibilityAction
    | ChangeServerErrorAction
    ;
