import {contactValueMaxLength, contactValueMinLength} from 'api/gen/ContactValue';
import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {UserContactFormValues} from '../../types/user-contact-form-values';

const te = t('admin/users');

export const validate =
    (values: UserContactFormValues): Errors<UserContactFormValues> => {
        const validator = new EskotValidator(values);
        const contactValueLabel = te('contactValue');

        validator.nonEmpty('contactType', te('contactType'));
        validator.nonEmpty('value', contactValueLabel);
        validator.maxStringLength('value', contactValueMaxLength, contactValueLabel);
        validator.minStringLength('value', contactValueMinLength, contactValueLabel);

        if (values.contactType === 'E_MAIL') {
            validator.email('value', contactValueLabel);
        }

        return validator.generateErrorsObject();
    };
