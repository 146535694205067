import React, { FC, memo, ReactNode } from 'react';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { MatingTabKey } from '../../types';
import { BullSelectionTab } from '../bull-selection-tab';
import { CowSelectionTab } from '../cow-selection-tab';
import { ParamsSelectionTab } from '../params-selection-tab';
import { ResultTab } from '../result-tab';
import styles from './styles.sass';
interface Props {
  activeTab: MatingTabKey;
  globalActionsInTabBar: ReactNode;
  isMatingResultEmpty: boolean;
  handleActiveTabChange(newActiveTab: string): void;
}
const MatingTabsBase: FC<Props> = props => {
  const {
    activeTab,
    globalActionsInTabBar,
    isMatingResultEmpty,
    handleActiveTabChange
  } = props;
  const {
    t
  } = useOurTranslation('mating/mating');
  return <div className={styles.hiddenItemsPadding} data-sentry-component="MatingTabsBase" data-sentry-source-file="index.tsx">
            <Ant.Tabs type="card" activeKey={activeTab} tabBarExtraContent={globalActionsInTabBar} onChange={handleActiveTabChange} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Ant.Tabs.TabPane key="cowSelection" tab={t('cowGroupSelection')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {activeTab === 'cowSelection' && <CowSelectionTab />}
                </Ant.Tabs.TabPane>
                <Ant.Tabs.TabPane key="bullSelection" tab={t('bullGroupSelection')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {activeTab === 'bullSelection' && <BullSelectionTab associationType="HOLSTEIN" loadingMsg={t('loadingMatingPlan')} />}
                </Ant.Tabs.TabPane>
                <Ant.Tabs.TabPane key="paramsSelection" tab={t('calcParamsSelection')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {activeTab === 'paramsSelection' && <ParamsSelectionTab />}
                </Ant.Tabs.TabPane>
                <Ant.Tabs.TabPane key="result" disabled={isMatingResultEmpty} tab={t('result')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {activeTab === 'result' && <ResultTab />}
                </Ant.Tabs.TabPane>
            </Ant.Tabs>
        </div>;
};
export const MatingTabs = memo(MatingTabsBase);