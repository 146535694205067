import React, { Fragment, FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { AssociationType } from 'api/gen/AssociationType';
import { CreateOrUpdateGridUserSettings } from 'api/gen/CreateOrUpdateGridUserSettings';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { CriteriaList } from 'api/gen/CriteriaList';
import { WriteCriteria } from 'api/gen/WriteCriteria';
import { MatingLoadingId } from 'app/mating/types';
import { ModalListConfirmName, ModalListsOverview, ModalAddAnimals, ModalAddAnimalsFromDb } from 'app/modals/components';
import { useOurTranslation } from 'app/translations';
import { matingAction, matingSelector } from '../../model';
import { ModalCriteriaListName } from '../modal-criteria-list-name';
import { ModalCriteriaListNameConfirm } from '../modal-criteria-list-name-confirm';
import { ModalGetCriteriaDetails } from '../modal-get-criteria-details';
import { ModalMultipleSaveList } from '../modal-multiple-save-list';
const FEMALE = 'FEMALE';
interface Props {
  associationType: AssociationType;
  isAnimalsPageEmpty: boolean;
  loading: Array<MatingLoadingId>;
  animalLists: Opt<Array<AnimalList>>;
  criteriaLists: Opt<Array<CriteriaList>>;
  criteriaDefinitions: Opt<Array<CriteriaDefinition>>;
  tableSettingsNameList: Array<string>;
  toggleAnimalListsVisibility(): void;
  toggleAddAnimalsVisibility(): void;
  toggleAddAnimalsFromDBVisibility(): void;
  toggleListsOverviewVisibility(): void;
  toggleCriteriaListsVisibility(): void;
  toggleCriteriaListDetailsVisibility(): void;
}

// eslint-disable-next-line max-lines-per-function
const MatingCowsModalsBase: FC<Props> = ({
  associationType,
  isAnimalsPageEmpty,
  loading,
  animalLists,
  criteriaLists,
  criteriaDefinitions,
  toggleAnimalListsVisibility,
  toggleAddAnimalsVisibility,
  toggleAddAnimalsFromDBVisibility,
  toggleListsOverviewVisibility,
  toggleCriteriaListsVisibility,
  toggleCriteriaListDetailsVisibility,
  tableSettingsNameList
}) => {
  const dispatch = useDispatch();
  const animalListId = useSelector(matingSelector.animalListId);
  const availableAnimals = useSelector(matingSelector.availableAnimals);
  const criteriaListId = useSelector(matingSelector.criteriaListId);
  const animalListsModalVisible = useSelector(matingSelector.femaleAnimalListsModalVisible);
  const femaleAnimalListsConfirmModalVisible = useSelector(matingSelector.femaleAnimalListsConfirmModalVisible);
  const addCowsModalVisible = useSelector(matingSelector.addCowsModalVisible);
  const cowsFromDBVisibilityModalVisible = useSelector(matingSelector.cowsFromDBVisibilityModalVisible);
  const cowsCriteriaListsModalVisible = useSelector(matingSelector.cowsCriteriaListsModalVisible);
  const cowsCriteriaListsConfirmModalVisible = useSelector(matingSelector.cowsCriteriaListsConfirmModalVisible);
  const cowsCriteriaListDetailsModalVisible = useSelector(matingSelector.cowsCriteriaListDetailsModalVisible);
  const cowsListsOverviewModalVisible = useSelector(matingSelector.cowsListsOverviewModalVisible);
  const confirmAnimalListNameLoading = loading.includes('confirmAnimalListNameInProgress');
  const confirmCriteriaListNameLoading = loading.includes('confirmCriteriaListNameInProgress');
  const animalListsLoading = loading.includes('animalLists');
  const createCowsCriteriaList = useCallback((name: string): void => {
    dispatch(matingAction.createCriteriaList(FEMALE, name, criteriaDefinitions, associationType));
  }, [dispatch, criteriaDefinitions, associationType]);
  const confirmCowsCriteriaList = useCallback((criteriaList: Array<WriteCriteria>, gridUserSettings: CreateOrUpdateGridUserSettings | null): void => {
    const listId = criteriaListId.orCrash('criteriaListId not available');
    dispatch(matingAction.confirmCriteriaList({
      bookmark: FEMALE,
      criteriaListId: listId,
      criteria: criteriaList,
      gridUserSettings,
      associationType
    }));
  }, [criteriaListId, associationType, dispatch]);
  const createCowsList = useCallback((name: string): void => {
    dispatch(matingAction.createAnimalList(FEMALE, name, associationType));
  }, [dispatch, associationType]);
  const renameCowsList = useCallback((id: number, name: string): void => {
    dispatch(matingAction.renameAnimalList(FEMALE, id, name, associationType));
  }, [dispatch, associationType]);
  const renameCowsCriteriaList = useCallback((id: number, name: string, gridUserSettingsId: number | null): void => {
    dispatch(matingAction.renameCriteriaList({
      bookmark: FEMALE,
      id,
      gridUserSettingsId,
      name,
      associationType
    }));
  }, [dispatch, associationType]);
  const confirmCowsList = useCallback((listId: number): void => {
    dispatch(matingAction.confirmAnimalList(FEMALE, listId, associationType));
  }, [dispatch, associationType]);
  const deleteCowsList = useCallback((listId: number): void => {
    dispatch(matingAction.deleteAnimalList(listId, FEMALE, associationType));
  }, [dispatch, associationType]);
  const deleteCowsCriteriaList = useCallback((listId: number, gridUserSettingsId: number | null): void => {
    dispatch(matingAction.deleteCriteriaList(listId, FEMALE, associationType, gridUserSettingsId));
  }, [dispatch, associationType]);
  const getAvailableCows = useCallback((input: string): void => {
    dispatch(matingAction.getAvailableAnimals(FEMALE, input, associationType));
  }, [dispatch, associationType]);
  const setGetAvailableAnimalsLoading = useCallback((): void => {
    dispatch(matingAction.setLoading('getAvailableAnimals', true));
  }, [dispatch]);
  const addCows = useCallback((animalIds: Array<number>): void => {
    dispatch(matingAction.addAnimals(FEMALE, animalIds, associationType));
  }, [dispatch, associationType]);
  const addCowsFromDB = useCallback((listId: number): void => {
    dispatch(matingAction.addAnimalsFromDB(FEMALE, listId, associationType));
  }, [dispatch, associationType]);
  const getCowsCsv = useCallback((fileName: string): void => {
    dispatch(matingAction.getAnimalsCsv(FEMALE, fileName, associationType));
  }, [dispatch, associationType]);
  const toggleOverwriteCowsListVisibility = useCallback((listId: Opt<number>): void => {
    dispatch(matingAction.toggleOverwriteAnimalListVisibility(FEMALE, listId));
  }, [dispatch]);
  const toggleCowsOverwriteCriteriaListVisibility = useCallback((gridUserSettings: CreateOrUpdateGridUserSettings | null, writeCriteria?: Array<WriteCriteria>): void => {
    dispatch(matingAction.toggleOverwriteCriteriaListVisibility(FEMALE, gridUserSettings, writeCriteria));
  }, [dispatch]);
  const getCowsCriteriaListDetails = useCallback((listId: number): void => {
    dispatch(matingAction.getCriteriaListDetails(FEMALE, associationType, listId));
  }, [dispatch, associationType]);
  const {
    t,
    tCommon
  } = useOurTranslation('mating/modalAddAnimals');
  const {
    t: tOverview
  } = useOurTranslation('mating/modalListsOverview');
  const {
    t: tListName
  } = useOurTranslation('mating/modalListName');
  return <Fragment>
            <ModalListConfirmName visible={femaleAnimalListsConfirmModalVisible} animalListId={animalListId} onSubmit={confirmCowsList} onCancel={toggleOverwriteCowsListVisibility} loading={confirmAnimalListNameLoading} listAlreadyExistsMessage={tListName('listAlreadyExists')} data-sentry-element="ModalListConfirmName" data-sentry-source-file="cows-modals.tsx" />
            <ModalMultipleSaveList visible={animalListsModalVisible} isAnimalsPageEmpty={isAnimalsPageEmpty} animalListEmptyMessage={tListName('atLeastOneAnimal')} onSubmitSaveToDb={createCowsList} onSubmitSaveToFile={getCowsCsv} onCancel={toggleAnimalListsVisibility} animalLists={animalLists} title={tListName('saveBreeders')} data-sentry-element="ModalMultipleSaveList" data-sentry-source-file="cows-modals.tsx" />
            <ModalAddAnimals onCancel={toggleAddAnimalsVisibility} onSearch={getAvailableCows} onSubmit={addCows} visible={addCowsModalVisible} title={t('femaleTitle')} fieldLabel={tCommon('earTagTooltip')} submitLabel={tCommon('add')} availableAnimals={availableAnimals} loadingAvailableAnimals={loading.includes('getAvailableAnimals')} setAvailableAnimalsLoading={setGetAvailableAnimalsLoading} data-sentry-element="ModalAddAnimals" data-sentry-source-file="cows-modals.tsx" />
            <ModalAddAnimalsFromDb animalListsLoading={animalListsLoading} visible={cowsFromDBVisibilityModalVisible} animalLists={animalLists} onCancel={toggleAddAnimalsFromDBVisibility} onSubmit={addCowsFromDB} title={t('chooseAnimalList')} submitLabel={tCommon('add')} selectLabel={t('animalList')} data-sentry-element="ModalAddAnimalsFromDb" data-sentry-source-file="cows-modals.tsx" />
            <ModalListsOverview visible={cowsListsOverviewModalVisible} animalLists={animalLists} criteriaLists={criteriaLists} onCancel={toggleListsOverviewVisibility} renameAnimalList={renameCowsList} renameCriteriaList={renameCowsCriteriaList} deleteAnimalList={deleteCowsList} deleteCriteriaList={deleteCowsCriteriaList} noListsSaved={tOverview('noSavedLists')} title={tOverview('managementOfSavedLists')} areYouSureToDeleteMessage={tOverview('areYouSure')} listAlreadyExistsMessage={tOverview('listAlreadyExists')} data-sentry-element="ModalListsOverview" data-sentry-source-file="cows-modals.tsx" />
            <ModalCriteriaListName visible={cowsCriteriaListsModalVisible} onSubmit={createCowsCriteriaList} tableSettingsNameList={tableSettingsNameList} onCancel={toggleCriteriaListsVisibility} criteriaLists={criteriaLists} data-sentry-element="ModalCriteriaListName" data-sentry-source-file="cows-modals.tsx" />
            <ModalCriteriaListNameConfirm visible={cowsCriteriaListsConfirmModalVisible} onSubmit={confirmCowsCriteriaList} onCancel={toggleCowsOverwriteCriteriaListVisibility} loading={confirmCriteriaListNameLoading} data-sentry-element="ModalCriteriaListNameConfirm" data-sentry-source-file="cows-modals.tsx" />
            <ModalGetCriteriaDetails visible={cowsCriteriaListDetailsModalVisible} criteriaLists={criteriaLists} onSubmit={getCowsCriteriaListDetails} onCancel={toggleCriteriaListDetailsVisibility} data-sentry-element="ModalGetCriteriaDetails" data-sentry-source-file="cows-modals.tsx" />
        </Fragment>;
};
export const MatingCowsModals = memo(MatingCowsModalsBase);