// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-mating-components-criteria-collapse-___styles__accordionFieldWrapper___KNvZ5{line-height:1.3}.src-app-mating-components-criteria-collapse-___styles__accordionFieldWrapper___KNvZ5 label{height:-moz-fit-content!important;height:fit-content!important}.src-app-mating-components-criteria-collapse-___styles__accordionFieldWrapper___KNvZ5 .ant-form-item{margin-bottom:0!important}@media (max-width: 1199px){.src-app-mating-components-criteria-collapse-___styles__accordionFieldWrapper___KNvZ5{padding-bottom:.8rem}}
`, "",{"version":3,"sources":["webpack://./src/app/mating/components/criteria-collapse/styles.sass"],"names":[],"mappings":"AAAA,sFAAuB,eAAA,CAAgB,4FAAqC,iCAAA,CAAA,4BAAA,CAA8B,qGAA8C,yBAAA,CAA6B,2BAA0B,sFAAuB,oBAAA,CAAA","sourcesContent":[".accordionFieldWrapper{line-height:1.3}.accordionFieldWrapper :global label{height:fit-content !important}.accordionFieldWrapper :global .ant-form-item{margin-bottom:0px !important}@media(max-width: 1199px){.accordionFieldWrapper{padding-bottom:.8rem}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionFieldWrapper": `src-app-mating-components-criteria-collapse-___styles__accordionFieldWrapper___KNvZ5`
};
export default ___CSS_LOADER_EXPORT___;
