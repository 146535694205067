// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-mating-components-result-tab-___styles__percentSuccess___KPMda,.src-app-mating-components-result-tab-___styles__percentError___qFXiP{white-space:initial}.src-app-mating-components-result-tab-___styles__percentError___qFXiP{color:#dc3545}.src-app-mating-components-result-tab-___styles__percentSuccess___KPMda{color:#28a745}
`, "",{"version":3,"sources":["webpack://./src/app/mating/components/result-tab/styles.sass"],"names":[],"mappings":"AAAA,8IAA8B,mBAAA,CAAoB,sEAAc,aAAA,CAAc,wEAAgB,aAAA","sourcesContent":[".percentSuccess,.percentError{white-space:initial}.percentError{color:#dc3545}.percentSuccess{color:#28a745}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"percentSuccess": `src-app-mating-components-result-tab-___styles__percentSuccess___KPMda`,
	"percentError": `src-app-mating-components-result-tab-___styles__percentError___qFXiP`
};
export default ___CSS_LOADER_EXPORT___;
