import {excess} from '@fl/cmsch-fe-library';
import * as t from 'io-ts';

export const ValidateBreederErrorsSchema = excess(t.partial({
    approvedFrom: t.string,
    approvedTo: t.string,
    farmIds: t.string,
    roleIds: t.string,
    breederId: t.string,
}));

