import {convertAllErrorsFromBeToFormError, takeLatestF} from '@fl/cmsch-fe-library';
import {has, omit, keys, union, isEmpty, isEqual} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ValidateMilkabilityReport} from 'api/gen/ValidateMilkabilityReport';
import {formHelpers} from 'utils/forms';

import {milkabilityReportFormName} from '../../components/milkability-report-form/form-values';
import {validate, defaultEarTagValue} from '../../components/milkability-report-form/validate';
import {milkabilityAction, ValidateMilkabilityDataAction} from '../action';
import {simpleMilkabilitySelector} from '../selector';

const ignoredFieldsCount = 1;
const roundTwoDecimalsCoefficient = 100;

const roundValue = (value: number): number =>
    Math.round(value * roundTwoDecimalsCoefficient) / roundTwoDecimalsCoefficient;

const omitApmv = omit('apmv');

// eslint-disable-next-line max-lines-per-function
function* execute(_: ValidateMilkabilityDataAction): SagaIterator {
    const milkabilityFormValues = (yield* select(formHelpers.formValues('milkabilityReport')))
        .orCrash('milkabilityFormValues are missing');

    const editingMilkabilityReport = yield* select(simpleMilkabilitySelector.editingMilkabilityReport);

    const syncValidationErrors = omitApmv(validate(milkabilityFormValues));

    /*
     * Async validation should only run when there is at least one synchronously
     * validated field (except for apmv).
     */
    if (keys(syncValidationErrors).length < keys(milkabilityFormValues).length - ignoredFieldsCount) {
        yield* put(milkabilityAction.setLoading('validatingMilkabilityReportForm', true));
        const {earTag, examinationDate, technicianNumber, milkedTotal, milkingTime} = milkabilityFormValues;

        const report: ValidateMilkabilityReport = {
            earTag: !has('earTag')(syncValidationErrors) && earTag !== defaultEarTagValue ? earTag : null,
            examinationDate: !has('examinationDate')(syncValidationErrors) ? examinationDate : null,
            technicianNumber: !has('technicianNumber')(syncValidationErrors) ? technicianNumber : null,
            milkedTotal: !has('milkedTotal')(syncValidationErrors)
                ? milkedTotal && roundValue(milkedTotal)
                : null,
            milkingTime: !has('milkingTime')(syncValidationErrors)
                ? milkingTime && roundValue(milkingTime.value)
                : null,
            tag: editingMilkabilityReport ? 'ValidateMilkabilityReportUpdate' : 'ValidateMilkabilityReportCreate',
        };

        const response = yield* call(Api.validateMilkabilityData, report);

        if (!response.isSuccess) {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            const afterResponseValues = (yield* select(formHelpers.formValues(milkabilityReportFormName)))
                .orCrash('milkabilityFormValues are missing');

            // only show errors if form values other than apmv haven't changed between validation request and response
            if (isEqual(omitApmv(milkabilityFormValues), omitApmv(afterResponseValues))) {
                yield* put(formHelpers.stopAsyncValidation('milkabilityReport', errors.errors));
                yield* put(formHelpers.setAsyncWarnings('milkabilityReport', errors.warnings));

                const fieldsWithNewErrors = union(keys(errors.errors), keys(errors.warnings));

                if (!isEmpty(fieldsWithNewErrors)) {
                    // TODO: improve types (use io-ts for validation?)
                    // @ts-expect-error BE errors not typed for form fields
                    yield* put(formHelpers.touch('milkabilityReport', ...fieldsWithNewErrors));
                }
            } else {
                yield* put(formHelpers.stopAsyncValidation('milkabilityReport', {}));
                yield* put(formHelpers.setAsyncWarnings('milkabilityReport', {}));
            }
        } else {
            yield* put(formHelpers.stopAsyncValidation('milkabilityReport', {}));
            yield* put(formHelpers.setAsyncWarnings('milkabilityReport', {}));
        }

        yield* put(milkabilityAction.setLoading('validatingMilkabilityReportForm', false));
    }
}

export function* validateMilkabilityDataSaga(): SagaIterator {
    yield takeLatestF('milkability/VALIDATE_MILK_DATA', execute);
}
