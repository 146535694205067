import {takeLatestF, Response, putAll, showBeError} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {PagedModelStableToChoose} from 'api/gen/PagedModelStableToChoose';
import {ProductionAreaInCE} from 'api/gen/ProductionAreaInCE';
import {t} from 'app/translations';
import {
    tablesAction,
    getRequestedTablePageParams,
    getRequestedTableColumnSettings,
    simpleTableSelector,
    convertTableDataParamsToDto,
} from 'utils/tables';

import {potentiallyChangeOfTableDataSource} from '../../utils/potentially-change-of-table-data-source';
import {animalBrowserAction, GetStablesDataAction} from '../action';

// eslint-disable-next-line max-lines-per-function
function* execute(action: GetStablesDataAction): SagaIterator {
    const {tableDataRequest} = action.payload;

    if (tableDataRequest.isEmpty) {
        return yield* put(animalBrowserAction.getStablesTableDataSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('stableToChoose'));

    const tableState = yield* select(simpleTableSelector.tableState('stableToChoose'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'stableToChoose',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
        resetType: 'tableDataParams',
    });

    const columnSettings = getRequestedTableColumnSettings<'stableToChoose'>(tableState);

    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response: Response<PagedModelStableToChoose> = yield* call(Api.getStablesToChoose, filters, params);

    if (response.isSuccess) {
        yield* put(animalBrowserAction.getStablesTableDataSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('stableToChoose', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('stableTable')('tableName')));
    }
    if (potentiallyChangeOfTableDataSource(tableDataRequest.orCrash('No tableDataRequest'))){
        const prodAreasReponse = yield* call(Api.getProductionAreasInCentralEvidence);
        if (prodAreasReponse.isSuccess) {
            const options = prodAreasReponse.data.map((prod: ProductionAreaInCE) => ({
                value: prod.name,
                label: prod.name,
            }));

            yield* put(animalBrowserAction.getProductionAreasInCentralEvidenceSuccess(options));
        }
    }
}

export function* getStablesDataSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_STABLES_DATA', execute);
}
