import { Table, TypedFieldArrayInjectedProps, TypedFormFieldArrayFieldName } from '@fl/cmsch-fe-library';
import { sum } from 'lodash/fp';
import React, { useMemo, memo, FC, ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Opt, optArrayOpt } from 'ts-opt';
import { useOurTranslation } from 'app/translations';
import { formHelpers } from 'utils/forms';
import { BullPreference, BullRanking, MatingBullPreferencesFormValues } from '../../types';
import { buildMatingPreferencesColumns, RecalcMatingPrefsRow } from './mating-bull-preferences-columns';
import styles from './styles.sass';
const rank1Index = 0;
const rank2Index = 1;
const rank3Index = 2;
const rank4Index = 3;
const rank5Index = 4;
const countIndex = 0;
const shareIndex = 1;
const getPercentage = (bullPreferences: Array<BullPreference>, {
  lineRegistry
}: BullRanking): number | undefined => bullPreferences.find(bullPreference => bullPreference.lineRegistry === lineRegistry)?.percentage;
const getFieldName = (index: number): TypedFormFieldArrayFieldName<'bullPreferences'> => formHelpers.buildFieldArrayName('matingBullPreferences', 'bullPreferences', index);
const hundredPercent = 100;
const colorStyleForTotalPercentage = (totalPercentage: number): string => totalPercentage === hundredPercent ? styles.percentSuccess : styles.percentError;
export const renderTotalPercentageCell = (bullPreferences: Array<number | undefined | null>): ReactNode => {
  const totalPercentage = optArrayOpt(bullPreferences).chainToOpt(sum).orElse(0);
  return <span className={colorStyleForTotalPercentage(totalPercentage)} data-sentry-component="renderTotalPercentageCell" data-sentry-source-file="mating-bull-preferences-table.tsx">{totalPercentage}%</span>;
};
export interface MatingBullPreferencesTableProps {
  bullRankings: Array<BullRanking>;
  sumOfBullsRankCounts: Array<number>;
  bullPreferences: Opt<Array<BullPreference>>;
}
type Props = TypedFieldArrayInjectedProps<MatingBullPreferencesFormValues, 'bullPreferences'> & MatingBullPreferencesTableProps;
const RecalculateMatingPreferencesTableBase: FC<Props> = props => {
  const {
    bullRankings,
    sumOfBullsRankCounts,
    bullPreferences,
    Fields
  } = props;
  const {
    t
  } = useOurTranslation('mating/resultTab');
  const currentBullPreferences = useSelector(formHelpers.formValues('matingBullPreferences')).prop('bullPreferences');
  const totalRow = useMemo((): RecalcMatingPrefsRow => ({
    id: bullRankings.length + 1,
    rowName: t('total'),
    percentage: renderTotalPercentageCell(currentBullPreferences.orElse([])),
    rank1Count: sumOfBullsRankCounts[rank1Index],
    rank2Count: sumOfBullsRankCounts[rank2Index],
    rank3Count: sumOfBullsRankCounts[rank3Index],
    rank4Count: sumOfBullsRankCounts[rank4Index],
    rank5Count: sumOfBullsRankCounts[rank5Index]
  }), [bullRankings, sumOfBullsRankCounts, t, currentBullPreferences]);
  const renderPercentage = useCallback((bull: BullRanking, index: number) => bullPreferences.caseOf<ReactNode>(x => getPercentage(x, bull), () => <Fields.NumberInput name={getFieldName(index)} hideHelpInitially size="small" />), [bullPreferences, Fields]);
  const matingPreferencesRows: Array<RecalcMatingPrefsRow> = useMemo(() => [...bullRankings.map((bull: BullRanking, index: number) => ({
    id: index,
    rowName: bull.lineRegistry,
    bullName: bull.bullName,
    percentage: renderPercentage(bull, index),
    rank1Count: bull.rankings[rank1Index]?.[countIndex],
    rank1Share: bull.rankings[rank1Index]?.[shareIndex],
    rank2Count: bull.rankings[rank2Index]?.[countIndex],
    rank2Share: bull.rankings[rank2Index]?.[shareIndex],
    rank3Count: bull.rankings[rank3Index]?.[countIndex],
    rank3Share: bull.rankings[rank3Index]?.[shareIndex],
    rank4Count: bull.rankings[rank4Index]?.[countIndex],
    rank4Share: bull.rankings[rank4Index]?.[shareIndex],
    rank5Count: bull.rankings[rank5Index]?.[countIndex],
    rank5Share: bull.rankings[rank5Index]?.[shareIndex]
  })), totalRow], [bullRankings, totalRow, renderPercentage]);
  const matingPreferencesColumns = useMemo(() => buildMatingPreferencesColumns(t), [t]);
  return <Table tableId="matingBullPreferences" columns={matingPreferencesColumns} rows={matingPreferencesRows} totalItems={matingPreferencesRows.length - 1} size="small" bordered scroll={{
    x: true
  }} data-sentry-element="Table" data-sentry-component="RecalculateMatingPreferencesTableBase" data-sentry-source-file="mating-bull-preferences-table.tsx" />;
};
export const MatingBullPreferencesTable = memo(RecalculateMatingPreferencesTableBase);