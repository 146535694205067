import { ActionSettings, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo, useCallback, useMemo } from 'react';
import { Opt } from 'ts-opt';
import { RolePermission } from 'api/gen/RolePermission';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { ConnectedTable } from 'utils/tables';
import { simpleAdminSelector } from '../../model/selector';
import { getPermissionTableColumns } from '../../utils/get-permissions-table-columns';
interface Props {
  roleId: string;
  canUpdateRole: boolean;
  getRolePermissions(roleId: string, tableDataRequest: Opt<TableDataRequest<RolePermission>>): void;
  removePermissionFromRole(roleId: string, permissionId: number): void;
}
const RolePermissionsTableBase: FC<Props> = ({
  getRolePermissions,
  removePermissionFromRole,
  roleId,
  canUpdateRole
}) => {
  const onTableFetch = useCallback((tableDataRequest: Opt<TableDataRequest<RolePermission>>) => {
    getRolePermissions(roleId, tableDataRequest);
  }, [roleId, getRolePermissions]);
  const onRemoveClick = useCallback((_id: number, permission: RolePermission) => {
    removePermissionFromRole(roleId, permission.id);
  }, [roleId, removePermissionFromRole]);
  const {
    t
  } = useOurTranslation('admin/rolePermissionsTable');
  const actionSettings: ActionSettings<RolePermission> = useMemo(() => ({
    extraActions: [{
      id: 'removePermission',
      role: 'delete',
      confirmClick: false,
      callback: onRemoveClick,
      hidden: !canUpdateRole
    }]
  }), [canUpdateRole, onRemoveClick]);
  return <Fragment>
            <div className="mx-2">
                <Ant.Typography.Title level={5} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {t('assignedPermissions')}
                </Ant.Typography.Title>
            </div>
            <ConnectedTable tableId="rolePermissions" columns={getPermissionTableColumns} onFetchTableData={onTableFetch} rowDataPageSelector={simpleAdminSelector.rolePermissionsPage} actionSettings={actionSettings} withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter enableTableSettings enableTableUserSettings data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
        </Fragment>;
};
export const RolePermissionsTable = memo(RolePermissionsTableBase);