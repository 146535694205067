import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {StableDateRelationMilking} from 'api/gen/StableDateRelationMilking';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';

import {apcReportFormName, ApcReportFormValues} from '../../components/apc-report-form/form-values';
import {convertApcReportToTripleAttributeValue} from '../../utils/convert-apc-report-to-triple-attribute-value';
import {analysisProtocolCorrectionAction, GetStableDateRelationAction} from '../action';

const formValuesO = O.optic<ApcReportFormValues>();

const updateFormValuesFromAlternativeProtocol = (
    formValues: ApcReportFormValues,
    alternativeProtocol: StableDateRelationMilking,
): ApcReportFormValues =>
    O.set(
        formValuesO.prop('milkingTripleAttribute'),
    )(convertApcReportToTripleAttributeValue(alternativeProtocol))(formValues);

function* execute({payload: {stableCode, proficiencyTestDate}}: GetStableDateRelationAction): SagaIterator {
    yield* put(analysisProtocolCorrectionAction.setLoading('analysisProtocolLoading', true));

    const response = yield* call(Api.getStableDateRelation, {stableCode, proficiencyTestDate});

    if (response.isSuccess) {
        const formValues = (yield* select(formHelpers.formValues(apcReportFormName))).orCrash('no form values');
        yield* put(formHelpers.initialize(
            apcReportFormName,
            updateFormValuesFromAlternativeProtocol(formValues, response.data),
        ));
    } else {
        yield putAll(showBeError(response, t('apc/sagas')('analysisProtocol')));
    }
    yield* put(analysisProtocolCorrectionAction.setLoading('analysisProtocolLoading', false));
}

export function* getStableDateRelationSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/GET_STABLE_DATE_RELATION', execute);
}
