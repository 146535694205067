import React, { FC, memo } from 'react';
import { useOurTranslation } from 'app/translations';
import { Button } from 'common/buttons';
import styles from './styles.sass';
interface Props {
  testId?: string;
  resetForms(): void;
}
const ResetAllSettingsButtonBase: FC<Props> = ({
  resetForms,
  testId
}) => {
  const {
    t
  } = useOurTranslation('mating/mating');
  return <div className={styles.resetAllButton} data-test-id={testId} data-sentry-component="ResetAllSettingsButtonBase" data-sentry-source-file="index.tsx">
            <Button onClick={resetForms} danger data-sentry-element="Button" data-sentry-source-file="index.tsx">
                {t('resetAllSettings')}
            </Button>
        </div>;
};
export const ResetAllSettingsButton = memo(ResetAllSettingsButtonBase);