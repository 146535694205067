import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {uniqBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {subjectFormName} from '../../types/subject-form-values';
import {userDetailAction, SearchSubjectFormRoleOptionsAction} from '../action';
import {simpleUserDetailSelector} from '../selector';

function* execute({payload: {searchText, subjectFormType}}: SearchSubjectFormRoleOptionsAction): SagaIterator {
    const isAssociationForm = subjectFormType === 'ASSOCIATION';
    const response = yield* call(
        isAssociationForm ? Api.searchAssociationRoles : Api.searchOtherSubjectRoles,
        {text: searchText},
    );

    if (response.isSuccess) {
        const roleSelector = isAssociationForm
            ? simpleUserDetailSelector.associationRoleOptions
            : simpleUserDetailSelector.otherSubjectRoleOptions;
        const oldOptions = yield* select(roleSelector);

        const ids = (yield* select(formHelpers.formValues(subjectFormName))).prop('roleIds').orElse([]);
        const selectedOptions = oldOptions.filter(option => ids.includes(option.id));

        const setRoleOptions = isAssociationForm
            ? userDetailAction.setAssociationRoleOptions
            : userDetailAction.setOtherSubjectRoleOptions;
        yield* put(setRoleOptions(uniqBy('id', [...selectedOptions, ...response.data])));
    } else {
        yield putAll(showBeError(response, t('admin/users')('roleLoading')));
    }
}

export function* searchSubjectFormRolesOptionsSaga(): SagaIterator {
    yield takeLatestF('userDetail/SEARCH_SUBJECT_FORM_ROLE_OPTIONS', execute);
}
