import React, { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { versionCheckInterval } from 'app/consts';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { systemAction } from '../../model';
import { simpleSystemSelector } from '../../model/selector';
const RefreshModalBase: FC = () => {
  const dispatch = useDispatch();
  const {
    t
  } = useOurTranslation('system/system');
  const visible = useSelector(simpleSystemSelector.refreshModalVisible);
  useEffect(() => {
    const interval = setInterval(() => dispatch(systemAction.checkFEVersion()), versionCheckInterval);
    return (): void => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);
  return <Ant.Modal visible={visible} title={t('refreshModalTitle')} closable={false} cancelButtonProps={{
    hidden: true
  }} onOk={handleRefresh} data-sentry-element="unknown" data-sentry-component="RefreshModalBase" data-sentry-source-file="index.tsx">
            {t('refreshModalText')}
        </Ant.Modal>;
};
export const RefreshModal = memo(RefreshModalBase);