import React, { Fragment, FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { none, Opt } from 'ts-opt';
import { AssociationType } from 'api/gen/AssociationType';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { WriteCriteria } from 'api/gen/WriteCriteria';
import { ModalListsOverview } from 'app/modals/components';
import { useOurTranslation } from 'app/translations';
import { matingAction, matingSelector } from '../../model';
import { ModalCriteriaListName } from '../modal-criteria-list-name';
import { ModalCriteriaListNameConfirm } from '../modal-criteria-list-name-confirm';
import { ModalGetCriteriaDetails } from '../modal-get-criteria-details';
const bookmark = 'COMPUTATION';
interface Props {
  associationType: AssociationType;
  criteriaDefinitions: Opt<Array<CriteriaDefinition>>;
  toggleSaveCriteriaListVisibility(): void;
  toggleCriteriaListOverviewVisibility(): void;
}
const ParamsModalsBase: FC<Props> = props => {
  const {
    associationType,
    criteriaDefinitions,
    toggleSaveCriteriaListVisibility,
    toggleCriteriaListOverviewVisibility
  } = props;
  const dispatch = useDispatch();
  const criteriaLists = useSelector(matingSelector.criteriaLists);
  const criteriaListId = useSelector(matingSelector.criteriaListId);
  const listsOverviewModalData = useSelector(matingSelector.listsOverviewModalData);
  const criteriaListsModalData = useSelector(matingSelector.criteriaListsModalData);
  const criteriaListsConfirmModalData = useSelector(matingSelector.criteriaListsConfirmModalData);
  const criteriaListDetailsModalData = useSelector(matingSelector.criteriaListDetailsModalData);
  const loading = useSelector(matingSelector.loading);
  const confirmCriteriaListNameInProgress = loading.includes('confirmCriteriaListNameInProgress');
  const createParamsCriteriaList = useCallback((name: string): void => {
    dispatch(matingAction.createCriteriaList(bookmark, name, criteriaDefinitions, associationType));
  }, [criteriaDefinitions, associationType, dispatch]);
  const renameParamsCriteriaList = useCallback((id: number, name: string) => {
    dispatch(matingAction.renameCriteriaList({
      bookmark,
      id,
      gridUserSettingsId: null,
      name,
      associationType
    }));
  }, [dispatch, associationType]);
  const deleteParamsCriteriaList = useCallback((id: number) => {
    dispatch(matingAction.deleteCriteriaList(id, bookmark, associationType, null));
  }, [dispatch, associationType]);
  const confirmParamsCriteriaList = useCallback((criteriaList: Array<WriteCriteria>): void => {
    const listId = criteriaListId.orCrash('criteriaListId not available');
    dispatch(matingAction.confirmCriteriaList({
      bookmark,
      criteriaListId: listId,
      criteria: criteriaList,
      gridUserSettings: null,
      associationType
    }));
  }, [dispatch, criteriaListId, associationType]);
  const toggleOverwriteParamsCriteriaListVisibility = useCallback((): void => {
    dispatch(matingAction.toggleOverwriteCriteriaListVisibility(bookmark));
  }, [dispatch]);
  const toggleParamsCriteriaListDetailsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleCriteriaListDetailsVisibility(bookmark));
  }, [dispatch]);
  const getCowsCriteriaListDetails = useCallback((id: number): void => {
    dispatch(matingAction.getCriteriaListDetails(bookmark, associationType, id));
  }, [dispatch, associationType]);
  const {
    t
  } = useOurTranslation('mating/modalListsOverview');
  return <Fragment>
            <ModalCriteriaListName visible={criteriaListsModalData === bookmark} criteriaLists={criteriaLists} onSubmit={createParamsCriteriaList} tableSettingsNameList={[]} onCancel={toggleSaveCriteriaListVisibility} data-sentry-element="ModalCriteriaListName" data-sentry-source-file="params-modals.tsx" />
            <ModalCriteriaListNameConfirm visible={criteriaListsConfirmModalData === bookmark} onSubmit={confirmParamsCriteriaList} onCancel={toggleOverwriteParamsCriteriaListVisibility} loading={confirmCriteriaListNameInProgress} data-sentry-element="ModalCriteriaListNameConfirm" data-sentry-source-file="params-modals.tsx" />
            <ModalListsOverview visible={listsOverviewModalData === bookmark} animalLists={none} criteriaLists={criteriaLists} onCancel={toggleCriteriaListOverviewVisibility} renameCriteriaList={renameParamsCriteriaList} deleteCriteriaList={deleteParamsCriteriaList} noListsSaved={t('noSavedLists')} title={t('managementOfSavedLists')} areYouSureToDeleteMessage={t('areYouSure')} listAlreadyExistsMessage={t('listAlreadyExists')} data-sentry-element="ModalListsOverview" data-sentry-source-file="params-modals.tsx" />
            <ModalGetCriteriaDetails visible={criteriaListDetailsModalData === bookmark} criteriaLists={criteriaLists} onSubmit={getCowsCriteriaListDetails} onCancel={toggleParamsCriteriaListDetailsVisibility} data-sentry-element="ModalGetCriteriaDetails" data-sentry-source-file="params-modals.tsx" />
        </Fragment>;
};
export const ParamsModals = memo(ParamsModalsBase);