import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call as callRaw} from 'redux-saga/effects';
import {all, call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {AssociationType} from 'api/gen/AssociationType';
import {Bookmark} from 'api/gen/Bookmark';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {InitializeFormsAction, matingAction} from '../action';
import {setMatingFormInitialValues} from './set-mating-form-initial-values-saga';

const te = t('mating/sagas');

function* getCriteriaAccordions(associationType: AssociationType): SagaIterator {
    const response = yield* call(Api.getAccordions, {associationType});

    if (response.isSuccess) {
        yield* put(matingAction.getCriteriaAccordionsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, te('loadAccordionDefinitions')));
    }
}

function* getCriteriaDefinitions(bookmark: Bookmark, associationType: AssociationType): SagaIterator {
    const response = yield* call(Api.findCriteriaDefinitions, {bookmark, associationType});

    if (response.isSuccess) {
        yield* put(matingAction.getCriteriaDefinitionsSuccess(bookmark, response.data));
        yield* call(setMatingFormInitialValues, bookmark);
    } else {
        yield putAll(showBeError(response, `${te('loadCriteriaDefinitionsFor')} ${bookmark}`));
    }
}

function* initializeBullSelectorForms(asociationType: AssociationType): SagaIterator {
    yield* put(matingAction.getOrganizations(asociationType));

    yield* all([
        callRaw(getCriteriaAccordions, asociationType),
        callRaw(getCriteriaDefinitions, 'MALE', asociationType),
    ]);
}

function* initializeMatingForms(asociationType: AssociationType): SagaIterator {
    yield* put(matingAction.getOrganizations(asociationType));
    yield* put(matingAction.getBreeders());

    yield* all([
        callRaw(getCriteriaAccordions, asociationType),
        callRaw(getCriteriaDefinitions, 'MALE', asociationType),
        callRaw(getCriteriaDefinitions, 'FEMALE', asociationType),
        callRaw(getCriteriaDefinitions, 'COMPUTATION', asociationType),
    ]);
}

function* execute({payload: {bullSelector, associationType}}: InitializeFormsAction): SagaIterator {
    yield* put(matingAction.setLoading('criteriaDefinitions', true));

    yield* bullSelector
        ? call(initializeBullSelectorForms, associationType)
        : call(initializeMatingForms, associationType);

    yield* put(matingAction.setLoading('criteriaDefinitions', false));
}

export function* initializeFormsSaga(): SagaIterator {
    yield takeLatestF('mating/INITIALIZE_FORMS', execute);
}
