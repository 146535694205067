export const milkabilityTranslations = {
    CS: {
        'milkability/milkability': {
            pageTitle: 'Přehled hlášení dojitelnosti',
            milkabilityReportOverview: 'Přehled hlášení dojitelnosti',
        },
        'milkability/overviewTable': {
            technicianNumber: 'Číslo technika',
            technicianNumberTooltip: 'Č. technika',
            examinationDate: 'Datum zkoušky',
            milkedTotal: 'Celkový výdojek',
            milkingTime: 'Doba dojení',
            apmv: 'APMV',
            newReport: 'Hlášení',
            exportToCSV: 'Export do CSV',
            deleteDisabledTitle: 'Odebírání záznamů hlášení dojitelnosti je možné provést pouze v rámci posledních dvou kontrolních let (datum zkoušky 1. 10. {{fromYear}} – 30. 9. {{toYear}}).',
            editDisabledTitle: 'Změnu záznamů hlášení dojitelnosti je možné provést pouze v rámci posledních dvou kontrolních let (datum zkoušky 1. 10. {{fromYear}} – 30. 9. {{toYear}}).',
            rewriteMilkabilityReportFormData: 'Touto akcí smažete vyplněná data ve formuláři. Opravdu chcete provést tuto akci?',
            noMilkabilityReports: 'Nebyla nalezena žádná hlášení dojitelnosti.',
        },
        'milkability/new': {
            title: 'Hlášení dojitelnosti',
        },
        'milkability/sagas': {
            deleteMilkabilityReport: 'Odstranění hlášení dojitelnosti',
            reportDeletedSuccessfully: 'Hlášení dojitelnosti bylo úspěšně odstraněno',
            createMilkabilityReport: 'Vytvoření hlášení dojitelnosti',
            calculateAPMV: 'Výpočet APMV',
            loadMilkabilityReportDetail: 'Načtení detailu hlášení dojitelnosti',
        },
    },
    EN: {
        'milkability/milkability': {
            pageTitle: 'Přehled hlášení dojitelnosti',
            milkabilityReportOverview: 'Milkability report overview',
        },
        'milkability/overviewTable': {
            technicianNumber: 'Technician number',
            technicianNumberTooltip: 'Technician n.',
            examinationDate: 'Examination date',
            milkedTotal: 'Total milked',
            milkingTime: 'Milking time',
            apmv: 'APMV',
            newReport: 'New report',
            exportToCSV: 'Export to CSV',
            deleteDisabledTitle: 'Deleting of milkability reports for the control year (examination date 1 October - 30 September) is only possible within the control year.',
            editDisabledTitle: 'Editing of milkability reports for the control year (examination date 1 October - 30 September) is only possible within the control year.',
            rewriteMilkabilityReportFormData: 'This action will delete the entered data in the form. Are you sure you want to perform this action?',
            noMilkabilityReports: 'No milkability reports found.',
        },
        'milkability/new': {
            title: 'Milkability report',
        },
        'milkability/sagas': {
            deleteMilkabilityReport: 'Delete milkability report',
            reportDeletedSuccessfully: 'Milkability report was successfully deleted',
            createMilkabilityReport: 'Create milkability report',
            calculateAPMV: 'Calculate APMV',
            loadMilkabilityReportDetail: 'Načtení detailu hlášení dojitelnosti',
        },
    },
};
