import { CriteriaFilter } from 'api/gen/CriteriaFilter';
import { Criterias } from '../types/criterias';
export const getParsedFilterCriterias = (criterias: Criterias): Array<CriteriaFilter> => {
  const filters: Array<CriteriaFilter> = [];
  for (const [key, value] of Object.entries(criterias.radios)) {
    if (value !== null && value !== undefined) {
      filters.push({
        code: key,
        operator: 'IS',
        tag: 'BOOL',
        value
      });
    }
  }
  for (const [key, value] of Object.entries(criterias.dateRanges)) {
    if (value && (value[0] || value[1])) {
      filters.push({
        code: key,
        operator: 'BETWEEN',
        tag: 'DATE',
        leftValue: value[0],
        rightValue: value[1]
      });
    }
  }
  for (const [key, value] of Object.entries(criterias.numberRanges)) {
    if (value?.selectedType === 'percentage') {
      filters.push({
        code: key,
        operator: 'TOP',
        tag: 'SINGLE_INT',
        value: value.percentage
      });
    } else if (value?.selectedType === 'number' && (value.maximum !== null || value.minimum !== null)) {
      filters.push({
        code: key,
        operator: 'BETWEEN',
        tag: value.criteriaType,
        leftValue: value.minimum,
        rightValue: value.maximum
      });
    }
  }
  return filters;
};