import {SagaIterator} from 'redux-saga';
import {select} from 'typed-redux-saga';

import {formHelpers} from 'utils/forms';

import {MatingCowsFilterFormValues, matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';

export function* getMatingCowsFilterFormValues(): SagaIterator<MatingCowsFilterFormValues> {
    return (yield* select(formHelpers.formValues(matingCowsFilterFormName)))
        .orCrash('no matingCowsFilterFormValues');
}
