import {SagaIterator} from 'redux-saga';
import {Opt} from 'ts-opt';
import {select} from 'typed-redux-saga';

import {AnimalList} from 'api/gen/AnimalList';
import {State} from 'app/setup';

export function* getAnimalListId(name: string): SagaIterator<number> {
    const animalLists: Opt<Array<AnimalList>> =
        (yield* select((state: State) => state.mating.animalLists));
    const animalList = animalLists
        .findIn(y => y.name === name)
        .orCrash('animalLists not available');

    return animalList.id;
}
