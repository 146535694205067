import {none, Opt} from 'ts-opt';

import {MilkabilityDetail} from 'api/gen/MilkabilityDetail';
import {PagedModelMilkability} from 'api/gen/PagedModelMilkability';

import {MilkabilityLoadingId} from '../types/milkability-loading-id';

export interface MilkabilityState {
    pageMilkability: Opt<PagedModelMilkability>;
    createdMilkabilityReports: Array<MilkabilityDetail>;
    editingMilkabilityReportId?: number;
    loading: Array<MilkabilityLoadingId>;
}

export const initialMilkabilityState: MilkabilityState = {
    pageMilkability: none,
    createdMilkabilityReports: [],
    editingMilkabilityReportId: undefined,
    loading: [],
};
