import { Options, FooterButtons } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { head } from 'ts-opt';
import { useForm } from 'utils/forms';
import { addAnimalsFromDBFormName, AddAnimalsFromDBFormValues } from './add-animals-from-db-form-values';
import { validate } from './validate';
interface Props {
  listsOptions: Options<number>;
  submitLabel: string;
  selectLabel: string;
  animalListsLoading: boolean;
  onSubmit(values: AddAnimalsFromDBFormValues): void;
  onCancel(): void;
}
const AddAnimalsFromDbFormBase: FC<Props> = props => {
  const {
    listsOptions,
    submitLabel,
    selectLabel,
    animalListsLoading,
    onSubmit,
    onCancel
  } = props;
  const initialValues = useMemo((): AddAnimalsFromDBFormValues => ({
    animalListId: head(listsOptions).prop('value').orNull()
  }), [listsOptions]);
  const validateFn = useMemo(() => validate({
    selectLabel
  }), [selectLabel]);
  const {
    Form,
    Fields,
    submitting,
    valid
  } = useForm({
    form: addAnimalsFromDBFormName,
    initialValues,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate: validateFn,
    onSubmit
  });
  return <Form data-sentry-element="Form" data-sentry-component="AddAnimalsFromDbFormBase" data-sentry-source-file="form.tsx">
            <Fields.NumberSelect name="animalListId" label={selectLabel} options={listsOptions} disabled={submitting} autoFocus fieldIsLoading={animalListsLoading} data-sentry-element="unknown" data-sentry-source-file="form.tsx" />
            <FooterButtons valid={valid} submitting={submitting} submitTitle={submitLabel} onCancel={onCancel} data-sentry-element="FooterButtons" data-sentry-source-file="form.tsx" />
        </Form>;
};
export const AddAnimalsFromDbForm = memo(AddAnimalsFromDbFormBase);