import {TableDataRequest} from '@fl/cmsch-fe-library';
import {useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Opt} from 'ts-opt';

import {UserProfile} from 'api/gen/UserProfile';
import {TFunction, useOurTranslation} from 'app/translations';
import {AssociationRoleTableType} from 'app/user-detail/types/association-role-table-type';
import {AssociationTabProps} from 'app/user-detail/types/associaton-tab-props';
import {BreederRoleTableType} from 'app/user-detail/types/breeder-role-table-type';
import {BreedersTabProps} from 'app/user-detail/types/breeders-tab-props';
import {ContactsTabProps} from 'app/user-detail/types/contacts-tab-props';
import {MemberOfSubjectsTabProps} from 'app/user-detail/types/member-of-subjects-tab-props';
import {MemberOfSubjectsTableType} from 'app/user-detail/types/member-of-subjects-table-type';
import {OrganizationTableType} from 'app/user-detail/types/organization-table-type';
import {OrganizationsTabProps} from 'app/user-detail/types/organizations-tab-props';
import {OtherSubjectRoleTableType} from 'app/user-detail/types/other-subject-role-table-type';
import {OtherSubjectsTabProps} from 'app/user-detail/types/other-subjects-tab-props';
import {UserContactsTableType} from 'app/user-detail/types/user-contacts-table-type';
import {createPathRegex} from 'utils/create-path-regex';

import {simpleUserSelector, userAction} from '../model';
import {userRoutePaths} from '../routes/route-paths';

const profilePathnamesForRefresh = [createPathRegex(userRoutePaths.profile)];

interface UseUserDetailSetup {
    user: Opt<UserProfile>;
    breedersProps: BreedersTabProps;
    associationsProps: AssociationTabProps;
    organizationsProps: OrganizationsTabProps;
    otherSubjectsProps: OtherSubjectsTabProps;
    memberOfSubjectsProps: MemberOfSubjectsTabProps;
    contactsProps: ContactsTabProps;
    tCommon: TFunction<'common'>;
    t: TFunction<'user/profile'>;
}

// eslint-disable-next-line max-lines-per-function
export const useProfileSetup = (): UseUserDetailSetup => {
    const {t, tCommon} = useOurTranslation('user/profile');
    const user = useSelector(simpleUserSelector.userProfile);
    const principalUser = useSelector(simpleUserSelector.currentUser);
    const userProfileId = principalUser.prop('userProfileId').orNull();

    const dispatch = useDispatch();

    useEffect(() => {
        if (userProfileId) dispatch(userAction.getUserProfile(userProfileId, true));
    }, [dispatch, userProfileId]);

    const getBreedersTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<BreederRoleTableType>>): void => {
            if (userProfileId) dispatch(userAction.getUserBreeders(tableDataRequest, userProfileId));
        }, [dispatch, userProfileId]);

    const breedersProps: BreedersTabProps = useMemo(() => ({
        getTableData: getBreedersTableData,
        selector: simpleUserSelector.userBreeders,
        pathnamesForRefresh: profilePathnamesForRefresh,
    }), [getBreedersTableData]);

    const getAssociationsTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<AssociationRoleTableType>>): void => {
            if (userProfileId) dispatch(userAction.getUserAssociations(tableDataRequest, userProfileId));
        }, [dispatch, userProfileId]);

    const associationsProps: AssociationTabProps = useMemo(() => ({
        getTableData: getAssociationsTableData,
        selector: simpleUserSelector.userAssociations,
        pathnamesForRefresh: profilePathnamesForRefresh,
    }), [getAssociationsTableData]);

    const getOrganizationsTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<OrganizationTableType>>): void => {
            if (userProfileId) dispatch(userAction.getUserOrganizations(tableDataRequest, userProfileId));
        }, [dispatch, userProfileId]);

    const organizationsProps: OrganizationsTabProps = useMemo(() => ({
        getTableData: getOrganizationsTableData,
        selector: simpleUserSelector.userOrganizations,
        pathnamesForRefresh: profilePathnamesForRefresh,
    }), [getOrganizationsTableData]);

    const getOtherSubjectsTableData = useCallback(
        (request: Opt<TableDataRequest<OtherSubjectRoleTableType>>): void => {
            if (userProfileId) dispatch(userAction.getOtherSubjects(request, userProfileId));
        }, [dispatch, userProfileId]);

    const otherSubjectsProps: OtherSubjectsTabProps = useMemo(() => ({
        getTableData: getOtherSubjectsTableData,
        selector: simpleUserSelector.userOtherSubjects,
        pathnamesForRefresh: profilePathnamesForRefresh,
    }), [getOtherSubjectsTableData]);

    const getMemberOfSubjectsTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<MemberOfSubjectsTableType>>): void => {
            if (userProfileId) dispatch(userAction.getUserMemberOfSubjects(tableDataRequest, userProfileId));
        }, [dispatch, userProfileId]);

    const memberOfSubjectsProps: MemberOfSubjectsTabProps = useMemo(() => ({
        getTableData: getMemberOfSubjectsTableData,
        selector: simpleUserSelector.userMemberOfSubjects,
        pathnamesForRefresh: profilePathnamesForRefresh,
    }), [getMemberOfSubjectsTableData]);

    const getUserContactsTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<UserContactsTableType>>): void => {
            if (userProfileId) dispatch(userAction.getUserContacts(tableDataRequest, userProfileId));
        }, [dispatch, userProfileId]);

    const contactsProps: ContactsTabProps = useMemo(() => ({
        getTableData: getUserContactsTableData,
        selector: simpleUserSelector.userContacts,
        pathnamesForRefresh: profilePathnamesForRefresh,
    }), [getUserContactsTableData]);

    return {
        associationsProps,
        breedersProps,
        contactsProps,
        memberOfSubjectsProps,
        organizationsProps,
        otherSubjectsProps,
        user,
        t,
        tCommon,
    };
};
