import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {AddAnimalsFormValues} from './add-animals-form-values';

export const getValidate = (label: string) =>
    (values: AddAnimalsFormValues): Errors<AddAnimalsFormValues> => {
        const validator = new EskotValidator(values);

        validator.nonEmpty('addAnimalsToList', label);

        return validator.generateErrorsObject();
    };
