import { Modal } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo } from 'react';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { useAssociationTabSetup } from '../../hooks/use-association-tab-setup';
import { AssociationTabProps } from '../../types/associaton-tab-props';
import { SubjectForm } from '../subject-form';
import { columns } from './columns';
import { rowMapper } from './row-mapper';
const AssociationsTableBase: FC<AssociationTabProps> = props => {
  const {
    actionMode,
    actionSettings,
    associationForm,
    associationOptions,
    formDataForInit,
    headerButtons,
    pathnamesForRefresh,
    roleOptions,
    userProfileId,
    visibleModal,
    isFromAdminModule,
    asociationsSelector,
    getAsociations,
    handleAssociationSearch,
    handleCloseModal,
    handleOpenCreateModal,
    handleSubmitModal,
    t,
    validateDebounced
  } = useAssociationTabSetup(props);
  return <Fragment>
            <ConnectedTable tableId={isFromAdminModule ? 'userAssociations' : 'userAssociationsProfile'} columns={columns} onFetchTableData={getAsociations} rowDataPageSelector={asociationsSelector} actionSettings={actionSettings} headerButtons={headerButtons} withoutVerticalScrollbar paginationPosition="both" pathnamesForRefresh={pathnamesForRefresh} lazyLoadingLimit={20} showFulltextFilter enableTableSettings enableTableUserSettings highlightOnEdit rowDataToTableRowMapper={rowMapper} emptyTableContent={isFromAdminModule ? <Button role="create" onClick={handleOpenCreateModal}>
                        {t('association')}
                    </Button> : undefined} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
            {isFromAdminModule ? <Modal title={t(actionMode === 'create' ? 'creteAssociationRole' : 'editAssociationRole')} visible={visibleModal === associationForm} onCancel={handleCloseModal} footer={null} width={768}>
                    <Ant.Row>
                        <Ant.Col xs={24}>
                            <SubjectForm subjectFormType="ASSOCIATION" data={formDataForInit} onCancel={handleCloseModal} onSubmit={handleSubmitModal} associationOptions={associationOptions} roleOptions={roleOptions} userProfileId={userProfileId} onAssociationSearch={handleAssociationSearch} onSyncValidate={validateDebounced} />
                        </Ant.Col>
                    </Ant.Row>
                </Modal> : undefined}
        </Fragment>;
};
export const AssociationsTable = memo(AssociationsTableBase);