import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {GetAppSettingsListAction, userAction} from '../action';

function* getAppSettingsListExecute({payload: {module}}: GetAppSettingsListAction): SagaIterator {
    const response = yield* call(Api.getGridSettings, {module});

    if (response.isSuccess) {
        yield* put(userAction.getAppSettingsListSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('mating/sagas')('loadSettingsList')));
    }
}

export function* getAppSettingsListSaga(): SagaIterator {
    yield takeLatestF('user/GET_APP_SETTINGS_LIST', getAppSettingsListExecute);
}
