import { LoaderWrapper } from '@fl/cmsch-fe-library';
import { maxBy } from 'lodash/fp';
import React, { FC, Fragment, memo, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { opt, filter, pipe } from 'ts-opt';
import { Content } from 'app/content';
import { NavigationRestriction, pathRestrictions, restrictedPaths } from 'app/navigation';
import { useOurTranslation } from 'app/translations';
import { userHasAccessComputation } from 'app/user/utils/user-has-access';
import { Button } from 'common/buttons';
import { userAction } from '../../model/action';
import { simpleUserSelector } from '../../model/selector';
export const getCurrentPathRestriction = (restrictions: Array<NavigationRestriction>) => (currentPath: string): NavigationRestriction | undefined => pipe(restrictions, filter((x: NavigationRestriction) => currentPath.startsWith(x.path)), maxBy(x => x.path.length));
const UserPermissionGuardBase: FC<PropsWithChildren> = props => {
  const {
    children
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    t
  } = useOurTranslation('user/withUser');
  const isUserLoading = useSelector(simpleUserSelector.isUserLoading);
  const currentUserName = useSelector(simpleUserSelector.currentUser).prop('username');
  const currentUserPermissions = useSelector(simpleUserSelector.currentUserPermissions);
  const currentPathRestriction = useMemo(() => getCurrentPathRestriction(pathRestrictions)(location.pathname), [location.pathname]);
  useEffect(() => {
    if (currentUserName.isEmpty) {
      // eslint-disable-next-line @fl/use-eta-reduction
      dispatch(userAction.initialize(restrictedPaths.some(p => location.pathname.startsWith(p))));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goToLogIn = useCallback(() => {
    dispatch(userAction.logOut(true));
  }, [dispatch]);
  const userHasAccess = useMemo(() => userHasAccessComputation(opt(currentUserPermissions), currentPathRestriction), [currentPathRestriction, currentUserPermissions]);
  if (isUserLoading) {
    return <Content browserTabTitle="eSkot" noFooter>
                <LoaderWrapper loading stretch size="large" text={`${t('loadingUser')}`} />
            </Content>;
  }
  if (!userHasAccess) {
    return <Content browserTabTitle="eSkot" noFooter>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h3 className="text-center">{t('noPermissionMessage')}</h3>
                    <Button visuals="primary" onClick={goToLogIn}>
                        {t('loginAsAnother')}
                    </Button>
                </div>
            </Content>;
  }
  return <Fragment>
            {children}
        </Fragment>;
};
export const UserPermissionGuard = memo(UserPermissionGuardBase);