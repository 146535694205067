import React, { FC, memo, useMemo } from 'react';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { PermissionsTable } from '../components/permission-table';
const PermissionsBase: FC = () => {
  const {
    t
  } = useOurTranslation('admin/permissions');
  const title = t('pageTitle');
  const header = useMemo(() => <ScreenHeader title={title} />, [title]);
  return <Content browserTabTitle={title} data-sentry-element="Content" data-sentry-component="PermissionsBase" data-sentry-source-file="permissions.tsx">
            <Card title={header} titleSize="big" noPadding data-sentry-element="Card" data-sentry-source-file="permissions.tsx">
                <PermissionsTable data-sentry-element="PermissionsTable" data-sentry-source-file="permissions.tsx" />
            </Card>
        </Content>;
};
export const Permissions = memo(PermissionsBase);