/* eslint-disable max-lines-per-function */
import {CabCalf} from 'api/gen/CabCalf';

import {CabCalfRow} from '../../types/cab-calf-row';

export const rowMapper = (row: CabCalf): CabCalfRow => ({
    id: row.animalId,
    animalId: row.animalId,
    birthDate: row.birthDate,
    breedAlpha6: row.breedAlpha6,
    breederRegistrationNumber: row.breederRegistrationNumber,
    districtCode: row.districtCode,
    earTag: row.earTag,
    farmCode: row.farmCode,
    farmName: row.farmName,
    fatherEarTag: row.fatherEarTag,
    fatherLineRegistry: row.fatherLineRegistry,
    fatherName: row.fatherName,
    fatherSihIndex: row.fatherSihIndex,
    fatherTotalBreedingValue: row.fatherTotalBreedingValue,
    firstInbreedingAt: row.firstInbreedingAt,
    firstPregnancyAt: row.firstPregnancyAt,
    genotype: row.genotype,
    genotypeIndex: row.genotypeIndex,
    huid: row.huid,
    inbreedingBullEarTag: row.inbreedingBullEarTag,
    inbreedingBullLineRegistry: row.inbreedingBullLineRegistry,
    inbreedingBullName: row.inbreedingBullName,
    motherAvgLactationDays: row.motherAvgLactationDays,
    motherAvgLactationMilkKg: row.motherAvgLactationMilkKg,
    motherBodyStructure: row.motherBodyStructure,
    motherEarTag: row.motherEarTag,
    motherExterior: row.motherExterior,
    motherExtremities: row.motherExtremities,
    motherFirstLactationDays: row.motherFirstLactationDays,
    motherFirstLactationFatKg: row.motherFirstLactationFatKg,
    motherFirstLactationFatPercentage: row.motherFirstLactationFatPercentage,
    motherFirstLactationMilkKg: row.motherFirstLactationMilkKg,
    motherFirstLactationProteinKg: row.motherFirstLactationProteinKg,
    motherFirstLactationProteinPercentage: row.motherFirstLactationProteinPercentage,
    motherFrame: row.motherFrame,
    motherLifelongLactationMilkKg: row.motherLifelongLactationMilkKg,
    motherMaxLactationFatKg: row.motherMaxLactationFatKg,
    motherMaxLactationFatPercentage: row.motherMaxLactationFatPercentage,
    motherMaxLactationMilkKg: row.motherMaxLactationMilkKg,
    motherMaxLactationOrder: row.motherMaxLactationOrder,
    motherMaxLactationProteinKg: row.motherMaxLactationProteinKg,
    motherMaxLactationProteinPercentage: row.motherMaxLactationProteinPercentage,
    motherMilkStrength: row.motherMilkStrength,
    motherMusculature: row.motherMusculature,
    motherName: row.motherName,
    motherSihIndex: row.motherSihIndex,
    motherStandardizedLactationCount: row.motherStandardizedLactationCount,
    motherTotalBreedingValue: row.motherTotalBreedingValue,
    motherUdder: row.motherUdder,
    organizationInsCode: row.organizationInsCode,
    organizationCenterInsCode: row.organizationCenterInsCode,
    organizationPtCode: row.organizationPtCode,
    organizationCenterPtCode: row.organizationCenterPtCode,
    pregnant: row.pregnant,
    sex: row.sex,
    sihIndex: row.sihIndex,
    stableCode: row.stableCode,
    stableName: row.stableName,
    studbookSection: row.studbookSection,
    subjectBusinessName: row.subjectBusinessName,
    totalBreedingValue: row.totalBreedingValue,
});
