import {debounceTime, Options, TableDataRequest, ToolbarButton, ActionSettings} from '@fl/cmsch-fe-library';
import {debounce, isEmpty} from 'lodash/fp';
import {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {BasicRole} from 'api/gen/BasicRole';
import {OtherSubject} from 'api/gen/OtherSubject';
import {PagedModelOtherSubjectRole} from 'api/gen/PagedModelOtherSubjectRole';
import {State} from 'app/setup';
import {TFunction, useOurTranslation} from 'app/translations';

import {defaultProps} from '../constants/consts';
import {userDetailAction} from '../model/action';
import {
    simpleUserDetailSelector,
    userOtherSubjectsOptionsSelector,
    otherSubjectRoleOptionsSelector,
} from '../model/selector';
import {CreateOrEditOrNull} from '../types/create-or-edit-or-null';
import {OtherSubjectRoleTableType} from '../types/other-subject-role-table-type';
import {OtherSubjectsTabProps} from '../types/other-subjects-tab-props';
import {SubjectFormEntryData} from '../types/subject-form-values';
import {getMatchingApprovedValues} from '../utils/get-matching-approved-values';

const otherSubjectsForm = 'otherSubjectForm';

interface UseOtherSubjectTabSetup {
    userProfileId: number;
    visibleModal: string | null;
    otherSubjectOptions: Options<number>;
    roleOptions: Options<number>;
    formDataForInit: SubjectFormEntryData | null;
    headerButtons: Array<ToolbarButton>;
    actionSettings: ActionSettings<OtherSubjectRoleTableType>;
    actionMode: CreateOrEditOrNull;
    pathnamesForRefresh: Array<RegExp>;
    isFromAdminModule: boolean;
    otherSubjectsForm: string;
    t: TFunction<'admin/users'>;
    getOtherSubjects(tableDataRequest: Opt<TableDataRequest<OtherSubjectRoleTableType>>): void;
    handleSubmitModal(): void;
    handleOpenCreateModal(): void;
    handleCloseModal(): void;
    handleOtherSubjectsSearch(searchText: string): void;
    validateDebounced(): void;
    otherSubjectsSelector(state: State): Opt<PagedModelOtherSubjectRole>;
}

// eslint-disable-next-line max-lines-per-function
export const useOtherSubjectsTabSetup = ({
    getTableData,
    pathnamesForRefresh,
    selector,
    adminProps,
}: OtherSubjectsTabProps): UseOtherSubjectTabSetup => {
    const dispatch = useDispatch();
    const {t} = useOurTranslation('admin/users');

    const isFromAdminModule = !isEmpty(adminProps);
    const {canUpdate, userProfileId, createEntry, updateEntry} = isFromAdminModule ? adminProps : defaultProps;

    const [actionMode, setActionMode] = useState<CreateOrEditOrNull>(null);
    const [rowForEdit, setRowForEdit] = useState<OtherSubjectRoleTableType | null>(null);

    const formDataForInit = useMemo((): SubjectFormEntryData | null => {
        if (rowForEdit !== null) {
            const sameApproved = getMatchingApprovedValues(rowForEdit);

            return {
                userProfileSubjectRoleId: rowForEdit.userProfileSubjectRoleId,
                approvedFrom: sameApproved.approvedFrom,
                approvedTo: sameApproved.approvedTo,
                subjectId: rowForEdit.otherSubjectId,
                roleId: rowForEdit.roleId,
                roles: rowForEdit.roles,
            };
        }

        return null;
    }, [rowForEdit]);

    const visibleModal = useSelector(simpleUserDetailSelector.associationsModalVisible);
    const otherSubjectOptions: Options<number> = useSelector(userOtherSubjectsOptionsSelector);
    const roleOptions: Options<number> = useSelector(otherSubjectRoleOptionsSelector);

    const handleSubmitModal = useCallback(() => actionMode === 'edit' ? updateEntry?.() : createEntry?.(),
        [actionMode, createEntry, updateEntry]);

    const handleOpenEditModal = useCallback((_rowId: string, row: OtherSubjectRoleTableType) => {
        setRowForEdit(row);
        setActionMode('edit');
        const otherSubjectOptionsForEdit: Array<OtherSubject> = [
            {
                id: row.otherSubjectId,
                otherSubjectName: row.otherSubjectName,
            },
        ];
        const roles: Array<BasicRole> = row.roles.map((r): BasicRole => ({
            id: r.roleId,
            name: r.roleName,
            code: r.roleCode,
        }));
        dispatch(userDetailAction.setOtherSubjectsOptions(otherSubjectOptionsForEdit));
        dispatch(userDetailAction.setOtherSubjectRoleOptions(roles));
        dispatch(userDetailAction.setAssociationsModalVisible(otherSubjectsForm));
    }, [dispatch]);

    const handleOpenCreateModal = useCallback(() => {
        setActionMode('create');
        setRowForEdit(null);
        dispatch(userDetailAction.setAssociationsModalVisible(otherSubjectsForm));
    }, [dispatch]);

    const handleCloseModal = useCallback(() => {
        dispatch(userDetailAction.setAssociationsModalVisible(null));
        setActionMode(null);
        dispatch(userDetailAction.setOtherSubjectsOptions([]));
        dispatch(userDetailAction.setOtherSubjectRoleOptions([]));
        setRowForEdit(null);
    }, [dispatch]);

    const handleOtherSubjectsSearch = useCallback((searchText: string): void => {
        dispatch(userDetailAction.searchOtherSubjects(searchText));
    }, [dispatch]);

    const validateData = useCallback(() => {
        dispatch(userDetailAction.validateOtherSubjects());
    }, [dispatch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validateDebounced = useMemo(() => debounce(debounceTime)(validateData), []);

    const headerButtons: Array<ToolbarButton> = useMemo(() => isFromAdminModule ? [
        {
            id: 'create',
            icon: 'plusOutlined',
            onClick: handleOpenCreateModal,
            title: t('subject'),
            visuals: 'primary',
            hidden: !canUpdate,
        },
    ] : [], [handleOpenCreateModal, t, canUpdate, isFromAdminModule]);

    const actionSettings: ActionSettings<OtherSubjectRoleTableType> = useMemo(() => canUpdate ? ({
        extraActions: [
            {
                id: 'edit',
                role: 'edit',
                callback: handleOpenEditModal,
                hidden: !canUpdate,
            },
        ],
    }) : {}, [handleOpenEditModal, canUpdate]);

    return {
        userProfileId,
        visibleModal,
        otherSubjectOptions,
        roleOptions,
        formDataForInit,
        headerButtons,
        actionSettings,
        actionMode,
        pathnamesForRefresh,
        isFromAdminModule,
        otherSubjectsForm,
        t,
        otherSubjectsSelector: selector,
        getOtherSubjects: getTableData,
        handleSubmitModal,
        handleOpenCreateModal,
        handleCloseModal,
        handleOtherSubjectsSearch,
        validateDebounced,
    };
};
