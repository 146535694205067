import { isEmpty } from 'lodash/fp';
import React, { FC, Fragment, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { AnimalType } from 'api/gen/AnimalType';
import { animalBrowserAction, simpleAnimalBrowserSelector } from 'app/animal-browser/model';
import { useOurTranslation } from 'app/translations';
import { Button } from 'common/buttons';
import { OurCollapse } from 'common/layout/components/our-collapse';
import { useForm } from 'utils/forms';
import { cabFilterFormName, cabInitialFormValues } from '../../types/cab-filter-form-values';
import { CABButtons } from '../comprehensive-buttons';
import styles from './styles.sass';
interface Props {
  animalType: AnimalType;
  animalsLists: Opt<Array<AnimalList>>;
  onSubmit(): void;
}

// eslint-disable-next-line max-lines-per-function
const CabFormBase: FC<Props> = ({
  onSubmit,
  animalType,
  animalsLists
}) => {
  const dispatch = useDispatch();
  const organizationPtOptions = useSelector(simpleAnimalBrowserSelector.organizationPtOptions);
  const organizationsInsOptions = useSelector(simpleAnimalBrowserSelector.organizationsInsOptions);
  const organizationsCenterInsOptions = useSelector(simpleAnimalBrowserSelector.organizationsCenterInsOptions);
  const organizationsCenterPtOptions = useSelector(simpleAnimalBrowserSelector.organizationsCenterPtOptions);
  const districtCodesOptions = useSelector(simpleAnimalBrowserSelector.districtCodesOptions);
  const breederOptions = useSelector(simpleAnimalBrowserSelector.breederOptions);
  const farmOptions = useSelector(simpleAnimalBrowserSelector.farmOptions);
  const stableOptions = useSelector(simpleAnimalBrowserSelector.stableOptions);
  const selectedAnimalIds = useSelector(simpleAnimalBrowserSelector.selectedAnimalIds);
  const loading = useSelector(simpleAnimalBrowserSelector.loading);
  const getAnimalListsLoading = loading.includes('getAnimalLists');
  const deleteAnimalsLoading = loading.includes('deleteAnimals');
  const handleOrganizationPtSearch = useCallback((value: string): void => {
    dispatch(animalBrowserAction.getOrganizationPtOptions(value));
  }, [dispatch]);
  const handleOrganizationCenterPtSearch = useCallback((value: string): void => {
    dispatch(animalBrowserAction.getOrganizationCenterPtOptions(value));
  }, [dispatch]);
  const handleOrganizationInsSearch = useCallback((value: string): void => {
    dispatch(animalBrowserAction.getOrganizationInsOptions(value));
  }, [dispatch]);
  const handleOrganizationCenterInsSearch = useCallback((value: string): void => {
    dispatch(animalBrowserAction.getOrganizationCenterInsOptions(value));
  }, [dispatch]);
  const handleDistrictCodesOptionsSearch = useCallback((value: string): void => {
    dispatch(animalBrowserAction.getDistrictCodesOptions(value));
  }, [dispatch]);
  const handleBreederOptionsSearch = useCallback((value: string): void => {
    dispatch(animalBrowserAction.getBreederOptions(value));
  }, [dispatch]);
  const handleFarmOptionsSearch = useCallback((value: string): void => {
    dispatch(animalBrowserAction.getFarmOptions(value));
  }, [dispatch]);
  const handleStableOptionsSearch = useCallback((value: string): void => {
    dispatch(animalBrowserAction.getStableOptions(value));
  }, [dispatch]);
  const addAnimalsFromCsv = useCallback((file: File) => {
    dispatch(animalBrowserAction.addAnimalsFromCsv(animalType, file));
  }, [animalType, dispatch]);
  const addAnimalsFromDB = useCallback(() => {
    dispatch(animalBrowserAction.toggleAddAnimalsFromDBVisibility());
  }, [dispatch]);
  const toggleCreateAnimalListFromIdsModalVisibility = useCallback(() => {
    dispatch(animalBrowserAction.toggleCreateAnimalsListFromIdsModal());
  }, [dispatch]);
  const toggleOverviewListModalVisibility = useCallback(() => {
    dispatch(animalBrowserAction.toggleOverviewAnimalsListModal());
  }, [dispatch]);
  const toggleAddAnimalsModalVisibility = useCallback(() => {
    dispatch(animalBrowserAction.toggleAddAnimalsModal());
  }, [dispatch]);
  const handleDeleteAnimals = useCallback(() => {
    dispatch(animalBrowserAction.deleteAnimals(animalType));
  }, [dispatch, animalType]);
  const {
    Form,
    submitting,
    Fields,
    reset,
    pristine
  } = useForm({
    form: cabFilterFormName,
    initialValues: cabInitialFormValues,
    onSubmit,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  });
  const {
    t
  } = useOurTranslation('comprehensiveOverview');
  const actions = useMemo(() => <Fragment>
            <Button onClick={reset} stopClickEventPropagation danger disabled={pristine} testId="reset">
                {t('reset')}
            </Button>
            <Button type="submit" visuals="primary" disabled={submitting} loading={submitting} testId="filterAnimals">
                {t('search')}
            </Button>
        </Fragment>, [submitting, t, reset, pristine]);
  return <Form data-sentry-element="Form" data-sentry-component="CabFormBase" data-sentry-source-file="index.tsx">
            <OurCollapse title={t('filterTitle')} id={0} extraActions={actions} data-sentry-element="OurCollapse" data-sentry-source-file="index.tsx">
                <div className={styles.filterContainer}>
                    <Fields.NumberMultiSelect name="organizationPtIds" label={t('organizationPt')} options={organizationPtOptions.orElse([])} onSearch={handleOrganizationPtSearch} searchable clearable helpText={t('organizationPtTooltip')} isHelpTextWithIcon asyncSearch fieldIsLoading={loading.includes('organizationPtSearch')} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                    <Fields.NumberMultiSelect name="organizationInsIds" label={t('organizationIns')} options={organizationsInsOptions.orElse([])} onSearch={handleOrganizationInsSearch} searchable clearable helpText={t('organizationInsTooltip')} isHelpTextWithIcon asyncSearch fieldIsLoading={loading.includes('organizationInsSearch')} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                    <Fields.NumberMultiSelect name="organizationCenterPtIds" label={t('organizationCenterPt')} options={organizationsCenterPtOptions.orElse([])} onSearch={handleOrganizationCenterPtSearch} searchable clearable helpText={t('organizationCenterPtTooltip')} isHelpTextWithIcon asyncSearch fieldIsLoading={loading.includes('organizationCenterPtSearch')} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                    <Fields.NumberMultiSelect name="organizationCenterInsIds" label={t('organizationCenterIns')} options={organizationsCenterInsOptions.orElse([])} onSearch={handleOrganizationCenterInsSearch} searchable clearable helpText={t('organizationCenterInsTooltip')} isHelpTextWithIcon asyncSearch fieldIsLoading={loading.includes('organizationCenterInsSearch')} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                    <Fields.NumberMultiSelect name="districtCodes" label={t('district')} options={districtCodesOptions.orElse([])} onSearch={handleDistrictCodesOptionsSearch} searchable clearable helpText={t('districtTooltip')} isHelpTextWithIcon asyncSearch fieldIsLoading={loading.includes('districtCodesSearch')} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                    <Fields.NumberMultiSelect name="breederIds" label={t('breeder')} options={breederOptions.orElse([])} onSearch={handleBreederOptionsSearch} searchable clearable helpText={t('breederTooltip')} isHelpTextWithIcon asyncSearch fieldIsLoading={loading.includes('breedersSearch')} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                    <Fields.NumberMultiSelect name="farmIds" label={t('farm')} options={farmOptions.orElse([])} onSearch={handleFarmOptionsSearch} searchable clearable helpText={t('farmTooltip')} isHelpTextWithIcon asyncSearch fieldIsLoading={loading.includes('farmsSearch')} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                    {animalType === 'BREEDING_FEMALES' && <Fields.NumberMultiSelect name="stableIds" label={t('stable')} options={stableOptions.orElse([])} onSearch={handleStableOptionsSearch} searchable clearable helpText={t('stableTooltip')} isHelpTextWithIcon asyncSearch fieldIsLoading={loading.includes('stablesSearch')} />}
                </div>
            </OurCollapse>
            <CABButtons onAnimalsCsvUploadClick={addAnimalsFromCsv} onAddAnimalsClick={toggleAddAnimalsModalVisibility} onManageListsClick={toggleOverviewListModalVisibility} onSaveListClick={toggleCreateAnimalListFromIdsModalVisibility} onDeleteAnimalsClick={handleDeleteAnimals} onSelectListClick={addAnimalsFromDB} disableSelectList={animalsLists.forAll(isEmpty)} getAnimalListsLoading={getAnimalListsLoading} deleteAnimalsLoading={deleteAnimalsLoading} disableSaveList={isEmpty(selectedAnimalIds)} data-sentry-element="CABButtons" data-sentry-source-file="index.tsx" />
        </Form>;
};
export const CabForm = memo(CabFormBase);