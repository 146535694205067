import {forkRestartingOnError, showError} from '@fl/cmsch-fe-library';
import {each, tap} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {ForkEffect} from 'redux-saga/effects';
import {flow, isFunction, map} from 'ts-opt';
import {all, put} from 'typed-redux-saga';

import {adminSagas} from 'app/admin';
import {analysisProtocolCorrectionSagas} from 'app/analysis-protocol-correction/model/sagas';
import {animalBrowserSagas} from 'app/animal-browser';
import {Config} from 'app/config';
import {matingSagas} from 'app/mating';
import {milkabilitySagas} from 'app/milkability';
import {logger} from 'app/sentry-logger';
import {systemSagas} from 'app/system';
import {t} from 'app/translations';
import {userSagas} from 'app/user';
import {userDetailSagas} from 'app/user-detail/model/sagas';
import {layoutSagas} from 'common/layout/model/sagas';
import {tablesSagas} from 'utils/tables';

type Saga = () => SagaIterator;

function* handleSagaError(sagaName: string, error: Error): SagaIterator {
    logger.logError(error);
    const tt = t('common/error');

    if (Config.isProduction) {
        yield* put(showError(tt('unexpectedError'), tt('unexpectedErrorOccurred')));
    } else {
        yield* put(showError(tt('internalSagaError'), tt('errorOccurredIn', {sagaName})));
    }
}

const validateSaga = (saga: unknown): void => {
    if (!isFunction(saga)) throw new Error(`The key ${JSON.stringify(saga)} is not a saga.`);
};

const process: (sagas: Array<Saga>) => Array<ForkEffect> = flow(
    tap<Array<Saga>>(each(validateSaga)),
    map((saga: Saga) => forkRestartingOnError(saga, handleSagaError)),
);

const sagas: Array<Saga> = [
    ...tablesSagas,
    ...userSagas,
    ...adminSagas,
    ...matingSagas,
    ...animalBrowserSagas,
    ...milkabilitySagas,
    ...analysisProtocolCorrectionSagas,
    ...systemSagas,
    ...layoutSagas,
    ...userDetailSagas,
];

export default function*(): SagaIterator {
    yield* all(process(sagas));
}
