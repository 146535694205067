import React, { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { useOurTranslation } from 'app/translations';
import { Button } from 'common/buttons';
interface Props extends WrappedFieldProps {
  title: string;
  loading?: boolean;
  toggleCriteriaListsVisibility(): void;
}
export const SaveCriteriasButton: FC<Props> = ({
  meta,
  loading,
  title,
  toggleCriteriaListsVisibility
}) => {
  const {
    t
  } = useOurTranslation('mating/modalListsOverview');
  return <Button onClick={toggleCriteriaListsVisibility} block loading={loading} disabled={meta.invalid || meta.pristine} title={meta.error ? t('cantSaveParams') : undefined} testId="saveCriterias" data-sentry-element="Button" data-sentry-component="SaveCriteriasButton" data-sentry-source-file="save-criterias-button.tsx">
            {title}
        </Button>;
};