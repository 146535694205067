import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {SubjectFacilityFormValues} from '../../types/subject-facility-form-values';

const te = t('admin/users');

export const createValidate = (farmsRequired: boolean, isEdit: boolean) =>
    (values: SubjectFacilityFormValues): Errors<SubjectFacilityFormValues> => {
        const validator = new EskotValidator(values);

        const approveIsInvalid = (
            values.approvedFrom !== null
            && values.approvedTo !== null
            && values.approvedFrom > values.approvedTo
        );

        validator.customRule(
            'approvedTo',
            approveIsInvalid,
            te('dateFromToIsInvalid'),
        );

        if (!isEdit) validator.nonEmpty('approvedFrom', te('approvedFrom'));
        validator.nonEmpty('subjectId', te('breederNumber'));
        if (farmsRequired) validator.nonEmpty('farmIds', te('farms'));
        validator.nonEmpty('roleIds', te('roles'));

        return validator.generateErrorsObject();
    };
