import {getFarmsSaga} from './get-farms-saga';
import {getOrganizationCentersSaga} from './get-organization-centers-saga';
import {getOrganizationFarmsSaga} from './get-organization-farms-saga';
import {searchAssociationsSaga} from './search-associations-saga';
import {searchBreedersSaga} from './search-breeders-saga';
import {searchOrganizationsSaga} from './search-organizations-saga';
import {searchOtherSubjectsSaga} from './search-other-subjects-saga';
import {searchSubjectFacilityFormRolesOptionsSaga} from './search-subject-facility-form-roles-options-saga';
import {searchSubjectFormRolesOptionsSaga} from './search-subject-form-roles-options';
import {searchSubjectsSaga} from './search-subjects-saga';
import {validateBreederSaga} from './validate-breeder-saga';
import {validateOrganizationsSaga} from './validate-organizations-saga';
import {validateOtherSubjectsSaga} from './validate-other-subjects-saga';
import {validateUserAssociationsSaga} from './validate-user-associations-saga';
import {validateUserContactSaga} from './validate-user-contact-saga';
import {validateUserMembershipSaga} from './validate-user-membersip-saga';

export const userDetailSagas = [
    validateOtherSubjectsSaga,
    validateBreederSaga,
    getFarmsSaga,
    searchBreedersSaga,
    searchOrganizationsSaga,
    getOrganizationCentersSaga,
    validateOrganizationsSaga,
    searchOtherSubjectsSaga,
    validateUserAssociationsSaga,
    searchAssociationsSaga,
    validateUserContactSaga,
    validateUserMembershipSaga,
    searchSubjectsSaga,
    searchSubjectFacilityFormRolesOptionsSaga,
    searchSubjectFormRolesOptionsSaga,
    getOrganizationFarmsSaga,
];
