import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {animalBrowserAction, ConfirmOverrideAnimalListFromIdsAction} from '../action';
import {simpleAnimalBrowserSelector} from '../selector';
import {getAnimalListVersion} from './get-animal-list-version';

const te = t('animalBrowserSagas');

function* execute({payload: {animalType, animalListId}}: ConfirmOverrideAnimalListFromIdsAction): SagaIterator {
    yield* put(animalBrowserAction.setLoading('confirmAnimalListNameFromIdsInProgress', true));

    const version = yield* call(getAnimalListVersion, animalListId);
    const animalIds = yield* select(simpleAnimalBrowserSelector.selectedAnimalIds);
    const response = yield* call(Api.updateAnimalListFromIds, {version, animalIds}, {animalListId});

    if (response.isSuccess) {
        yield* put(animalBrowserAction.getAnimalLists(animalType, true));
        yield* put(showSuccess(
            te('saveAnimalList'),
            animalType === 'BREEDING_FEMALES' ? te('breederListSuccess') : te('calvesListSuccess'),
        ));
    } else {
        yield putAll(showBeError(response, te('saveAnimalList')));
    }

    yield* put(animalBrowserAction.toggleOverrideAnimalListFromIdsVisibility(none));
    yield* put(animalBrowserAction.setLoading('confirmAnimalListNameFromIdsInProgress', false));
}

export function* confirmOverrideAnimalListFromIdsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/CONFIRM_OVERRIDE_ANIMAL_LIST_FROM_IDS', execute);
}
