import {takeLatestF, showError} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {Config} from 'app/config';
import {appRoutePaths} from 'app/routes/route-paths';
import {logger} from 'app/sentry-logger';
import {State} from 'app/setup';
import {t} from 'app/translations';

import {userRoutePaths} from '../../routes/route-paths';
import {LogOutAction, userAction} from '../action';
import {simpleUserSelector} from '../selector';
import {buildLoginRouteState} from './saga-utils';

const te = t('user/sagas');

const title = te('logout');
function* execute({payload: {goToLogin}}: LogOutAction): SagaIterator {
    const currentUser = yield* select(simpleUserSelector.currentUser);

    const location = yield* select((state: State) => state.router.location);

    if (goToLogin && location?.pathname !== userRoutePaths.login) {
        const routeState = yield* select(buildLoginRouteState);

        yield* put(routerActions.push(userRoutePaths.login, routeState));
    } else if (location?.pathname === userRoutePaths.logout) {
        yield* put(routerActions.push(appRoutePaths.home));
    }

    if (currentUser.isFull) {
        logger.setAnonymousUser();

        const response = yield* call(Api.logout);

        if (!response.isSuccess) yield* put(showError(title, te('unexpectedErrorWhileLogout')));

        if (!Config.disableLegacyLogin) {
            const legacyResponse = yield* call(Api.logoutLegacy);

            if (!legacyResponse.isSuccess && legacyResponse.data !== '') {
                yield* put(showError(title, te('unexpectedErrorWhileLegacyLogout')));
            }
        }
    }

    yield* put(userAction.logOutClear());
}

export function* logoutSaga(): SagaIterator {
    yield takeLatestF('user/LOGOUT', execute);
}
