import {excess} from '@fl/cmsch-fe-library';
import * as t from 'io-ts';

export const ValidateOrganizationErrorsSchema = excess(t.partial({
    approvedFrom: t.string,
    approvedTo: t.string,
    organizationCenterIds: t.string,
    farmIds: t.string,
    roleIds: t.string,
    organizationId: t.string,
}));
