import {takeLatestF, putAll, showBeError, downloadFileFromString} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {Sex} from 'api/gen/Sex';
import {t} from 'app/translations';

import {ExportBullselectorModalResultAction, matingAction} from '../action';

function* execute({payload: {fileName, associationType}}: ExportBullselectorModalResultAction): SagaIterator {
    const sex: Sex = 'MALE';
    const response = yield* call(Api.exportAnimalsInList, {fileName}, {sex, associationType});

    if (response.isSuccess) {
        downloadFileFromString(response.data, fileName.endsWith('.csv') ? fileName : fileName.concat('.csv'));
        yield* put(matingAction.toggleExportBullselectorResultModal());
    } else {
        yield putAll(showBeError(response, t('common')('downloadCsv')));
    }
}

export function* exportBullselectorModalResultSaga(): SagaIterator {
    yield takeLatestF('mating/EXPORT_BULLSELECTOR_MODAL_RESULT', execute);
}
