import { ActionSettings, TableDataRequest, ToolbarButton } from '@fl/cmsch-fe-library';
import React, { FC, MouseEvent, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { AnimalType } from 'api/gen/AnimalType';
import { CabCalf } from 'api/gen/CabCalf';
import { animalBrowserAction } from 'app/animal-browser/model';
import { BrowserLoadingId } from 'app/animal-browser/types/browser-loading-id';
import { CabCalfRow } from 'app/animal-browser/types/cab-calf-row';
import { useOurTranslation } from 'app/translations';
import { ConnectedTable } from 'utils/tables';
import { simpleAnimalBrowserSelector } from '../../model/selector';
import { MotherLinkProps } from '../../types/mother-link-props';
import { getCabCalfColumns } from './columns';
import { rowMapper } from './row-mapper';
const calves: AnimalType = 'CALVES';
interface Props {
  loading: Array<BrowserLoadingId>;
}
const CabCalvesTableBase: FC<Props> = ({
  loading
}) => {
  const dispatch = useDispatch();
  const {
    t
  } = useOurTranslation('common');
  const {
    t: tTable
  } = useOurTranslation('tables/table');
  const isSessionEmpty = useSelector(simpleAnimalBrowserSelector.isCabCalvesSessionEmpty);
  const isPageEmpty = useSelector(simpleAnimalBrowserSelector.isCabCalfPageEmpty);
  const selectedAnimalIds = useSelector(simpleAnimalBrowserSelector.selectedAnimalIds);
  const exportInProgress = loading.includes('exportInProgress');
  const handleMotherLink = useCallback((row: MotherLinkProps) => (e: MouseEvent) => {
    e.preventDefault();
    dispatch(animalBrowserAction.getMotherHuid(row.motherEarTag ?? '', row.huid ?? '', calves, 'COMPREHENSIVE'));
  }, [dispatch]);
  const getComprehensiveCalves = useCallback((tableDataRequest: Opt<TableDataRequest<CabCalf>>): void => {
    if (!isSessionEmpty) dispatch(animalBrowserAction.getCabCalves(tableDataRequest));
  }, [dispatch, isSessionEmpty]);
  const exportCalves = useCallback(() => {
    dispatch(animalBrowserAction.exportCabAnimals(calves));
  }, [dispatch]);
  const headerButtons: Array<ToolbarButton> = useMemo(() => [{
    id: 'exportCalves',
    title: t('downloadCsv'),
    onClick: exportCalves,
    loading: exportInProgress,
    disabled: isPageEmpty,
    role: 'export'
  }], [exportInProgress, exportCalves, isPageEmpty, t]);
  const actionSettings = useMemo((): ActionSettings<CabCalfRow> => ({
    selectedIds: selectedAnimalIds,
    onSelectedIdsChange: selectedIds => dispatch(animalBrowserAction.setSelectedAnimalIds(selectedIds))
  }), [dispatch, selectedAnimalIds]);
  return <ConnectedTable tableId="comprehensiveCalves" columns={getCabCalfColumns(handleMotherLink)} onFetchTableData={getComprehensiveCalves} rowDataToTableRowMapper={rowMapper} rowDataPageSelector={simpleAnimalBrowserSelector.cabCalfPage} enableTableSettings enableTableUserSettings paginationPosition="both" withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter headerButtons={headerButtons} emptyTableMessages={{
    noRows: tTable('noRows')
  }} actionSettings={actionSettings} data-sentry-element="ConnectedTable" data-sentry-component="CabCalvesTableBase" data-sentry-source-file="index.tsx" />;
};
export const CabCalvesTable = memo(CabCalvesTableBase);