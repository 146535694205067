import {Nullable} from '@fl/cmsch-fe-library';
import {optEmptyString} from 'ts-opt';

import {getAnimalBrowserLink} from './get-animal-browser-link';

interface ReplaceHuidAndGetBrowserLinkParams {
    huid: Nullable<string>;
    earTag: Nullable<string>;
    childHuid: Nullable<string>;
}

export const replaceHuidAndGetBrowserLink = (
    {
        huid,
        earTag,
        childHuid,
    }: ReplaceHuidAndGetBrowserLinkParams,
): string => {
    const newHuid = huid ? optEmptyString(huid) : optEmptyString(childHuid);

    return getAnimalBrowserLink({earTag: earTag ?? '', huid: newHuid.orElse('')});
};
