import {setItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {none, opt} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialAdminState, AdminState} from './state';

const adminStateO = O.optic<AdminState>();

export const adminReducer = (state: AdminState = initialAdminState, action: AppAction): AdminState => {
    switch (action.type) {
        case 'admin/GET_APP_INFO_SUCCESS': {
            const {appInfo} = action.payload;

            return O.set(adminStateO.prop('appInfo'))(opt(appInfo))(state);
        }

        case 'admin/GET_PERMISSION_DETAILS_SUCCESS':
            return O.set(adminStateO.prop('permissionDetailsPage'))(action.payload)(state);

        case 'admin/SET_EDITED_PERMISSION_ID':
            return O.set(adminStateO.prop('permissionIdInEdit'))(action.payload)(state);

        case 'admin/SET_PERMISSION_CREATE_VISIBILITY':
            return O.set(adminStateO.prop('permissionCreateVisible'))(action.payload)(state);

        case 'admin/SET_ROLE_CREATE_VISIBILITY':
            return O.set(adminStateO.prop('roleCreateModalVisible'))(action.payload)(state);

        case 'admin/SET_EDITED_ROLE_ID':
            return O.set(adminStateO.prop('roleIdInEdit'))(action.payload)(state);

        case 'admin/GET_SUBORDINATE_ASSIGNED_ROLES_SUCCESS':
            return O.set(adminStateO.prop('subordinateAssignedRolePage'))(action.payload)(state);

        case 'admin/GET_SUBORDINATE_UNASSIGNED_ROLES_SUCCESS':
            return O.set(adminStateO.prop('subordinateUnassignedRolePage'))(action.payload)(state);

        case 'admin/GET_ROLES_SUCCESS':
            return O.set(adminStateO.prop('rolesPage'))(action.payload)(state);

        case 'admin/GET_ROLE_UNASSIGNED_PERMISSIONS_SUCCESS':
            return O.set(adminStateO.prop('roleUnassignedPermissionsPage'))(action.payload)(state);

        case 'admin/GET_ROLE_PERMISSIONS_SUCCESS':
            return O.set(adminStateO.prop('rolePermissionsPage'))(action.payload)(state);

        case 'admin/GET_ROLE_SUCCESS':
            return O.set(adminStateO.prop('roleDetail'))(opt(action.payload))(state);

        case 'admin/GET_USERS_SUCCESS':
            return O.set(adminStateO.prop('usersPage'))(action.payload)(state);

        case 'admin/GET_USER_SUCCESS':
            return O.set(adminStateO.prop('userDetail'))(opt(action.payload))(state);

        case 'admin/GET_MEMBER_OF_SUBJECTS_SUCCESS':
            return O.set(adminStateO.prop('memberOfSubjects'))(action.payload)(state);

        case 'admin/GET_USER_CONTACTS_SUCCESS':
            return O.set(adminStateO.prop('userContacts'))(action.payload)(state);

        case 'admin/GET_USER_ASSOCIATIONS_SUCCESS':
            return O.set(adminStateO.prop('userAssociations'))(action.payload)(state);

        case 'admin/GET_USER_OTHER_SUBJECT_ROLES_SUCCESS':
            return O.set(adminStateO.prop('userOtherSubjectRoles'))(action.payload)(state);

        case 'admin/GET_USER_BREEDERS_SUCCESS':
            return O.set(adminStateO.prop('userBreeders'))(action.payload)(state);

        case 'admin/GET_ORGANIZATIONS_SUCCESS':
            return O.set(adminStateO.prop('organizations'))(action.payload)(state);

        case 'admin/CLEAR_USER':
            return O.set(adminStateO.prop('userDetail'))(none)(state);

        case 'admin/CLEAR_ROLE':
            return O.set(adminStateO.prop('roleDetail'))(none)(state);

        case 'admin/SET_LOADING': {
            const {id, loading} = action.payload;

            return O.modify(adminStateO.prop('loading'))(setItem(loading)(id))(state);
        }

        default:
            return state;
    }
};
