import { LoaderWrapper, useIdFromRouteOrNull } from '@fl/cmsch-fe-library';
import React, { FC, memo, ReactNode, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as routerActions from 'redux-first-history';
import { MilkabilityDetail } from 'api/gen/MilkabilityDetail';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { BackButton } from 'common/buttons';
import { Card, ScreenHeader } from 'common/layout';
import { formHelpers } from 'utils/forms';
import { replaceUrlWithoutRerender } from 'utils/replace-url-without-rerender';
import { CreatedMilkabilityReportsTable, MilkabilityReportForm } from '../components';
import { MilkabilityFormValues } from '../components/milkability-report-form/form-values';
import { milkabilityAction } from '../model';
import { isMilkabilityReportFormEmptySelector, milkabilityLoadingSelector, simpleMilkabilitySelector } from '../model/selector';
const getHeader = (title: string): ReactNode => <ScreenHeader title={title} data-sentry-element="ScreenHeader" data-sentry-component="getHeader" data-sentry-source-file="new-milkability-report.tsx" />;
const NewMilkabilityReportBase: FC = _ => {
  const {
    t,
    tCommon
  } = useOurTranslation('milkability/new');
  const {
    t: pageTitle
  } = useOurTranslation('milkability/milkability');
  const dispatch = useDispatch();
  const routeId = useIdFromRouteOrNull();
  useEffect(() => {
    if (routeId) dispatch(milkabilityAction.loadMilkabilityReportDetail(routeId));
  }, [dispatch, routeId]);
  const {
    deleteCreatedMilkabilityReport,
    validatingMilkabilityReportForm,
    loadingMilkabilityReportDetail
  } = useSelector(milkabilityLoadingSelector);
  const createdMilkabilityReports = useSelector(simpleMilkabilitySelector.createdMilkabilityReports);
  const isMilkabilityReportFormEmpty = useSelector(isMilkabilityReportFormEmptySelector);
  const editingMilkabilityReport = useSelector(simpleMilkabilitySelector.editingMilkabilityReport);
  const editingMilkabilityReportId = useSelector(simpleMilkabilitySelector.editingMilkabilityReportId);
  const earTagInputRef = useRef<HTMLInputElement>(null);
  const technicianNumberInputRef = useRef<HTMLInputElement>(null);
  const goBackToList = useCallback(() => {
    dispatch(routerActions.push('/milkability'));
  }, [dispatch]);
  const validateMilkabilityData = useCallback(() => {
    dispatch(milkabilityAction.validateMilkabilityData());
  }, [dispatch]);

  /* After creating a new report, technician number and examination date
   * keep their values, the rest is cleared and ear tag input is focused
   */
  const createMilkabilityReport = useCallback((data: MilkabilityFormValues) => {
    dispatch(milkabilityAction.createMilkabilityReport(data, () => earTagInputRef.current?.focus(), editingMilkabilityReportId));
  }, [dispatch, editingMilkabilityReportId]);
  const setEditingMilkabilityReport = useCallback((id?: number) => {
    dispatch(milkabilityAction.setEditingMilkabilityReportId(id));
  }, [dispatch]);
  const topRef = useRef<HTMLSpanElement>(null);
  const editCreatedMilkabilityReport = useCallback((_id: string | number, row: MilkabilityDetail) => {
    topRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
    dispatch(milkabilityAction.loadMilkabilityReportDetail(row.id));
    dispatch(routerActions.push(`/milkability/${row.id}`));
  }, [dispatch]);
  const deleteMilkabilityReport = useCallback((id: number) => {
    dispatch(milkabilityAction.deleteMilkabilityReport(id));
  }, [dispatch]);
  const resetCreatedMilkabilityReports = useCallback(() => {
    dispatch(milkabilityAction.resetCreatedMilkabilityReports());
  }, [dispatch]);
  const calculateApmvAndValidateReport = useCallback(() => {
    dispatch(milkabilityAction.calculateApmv());
    validateMilkabilityData();
  }, [dispatch, validateMilkabilityData]);

  // Reset form and focus ear tag input
  const cancelEditMilkabilityReport = useCallback(() => {
    if (editingMilkabilityReport) setEditingMilkabilityReport(undefined);
    dispatch(formHelpers.initialize('milkabilityReport', {
      technicianNumber: null,
      examinationDate: null,
      apmv: null,
      earTag: null,
      milkedTotal: null,
      milkingTime: null
    }));
    setTimeout(() => technicianNumberInputRef.current?.focus());
    replaceUrlWithoutRerender('/milkability/new');
  }, [dispatch, setEditingMilkabilityReport, editingMilkabilityReport]);
  useEffect(() => (): void => {
    setEditingMilkabilityReport(undefined);
  }, [setEditingMilkabilityReport]);
  return <Content browserTabTitle={pageTitle('milkabilityReportOverview')} data-sentry-element="Content" data-sentry-component="NewMilkabilityReportBase" data-sentry-source-file="new-milkability-report.tsx">
            <span ref={topRef} />
            <Card title={getHeader(t('title'))} titleSize="big" extra={<BackButton text={tCommon('backToList')} onClick={goBackToList} />} data-sentry-element="Card" data-sentry-source-file="new-milkability-report.tsx">
                <LoaderWrapper loading={loadingMilkabilityReportDetail} data-sentry-element="LoaderWrapper" data-sentry-source-file="new-milkability-report.tsx">
                    <MilkabilityReportForm validatingMilkabilityReportForm={validatingMilkabilityReportForm} editingMilkabilityReport={editingMilkabilityReport} earTagInputRef={earTagInputRef} technicianNumberInputRef={technicianNumberInputRef} validateMilkabilityData={validateMilkabilityData} onSubmit={createMilkabilityReport} calculateApmvAndValidateReport={calculateApmvAndValidateReport} cancelEditMilkabilityReport={cancelEditMilkabilityReport} data-sentry-element="MilkabilityReportForm" data-sentry-source-file="new-milkability-report.tsx" />
                </LoaderWrapper>
                <CreatedMilkabilityReportsTable createdMilkabilityReports={createdMilkabilityReports} isMilkabilityReportFormEmpty={isMilkabilityReportFormEmpty} deleteCreatedReportInProgress={deleteCreatedMilkabilityReport} editCreatedMilkabilityReport={editCreatedMilkabilityReport} deleteMilkabilityReport={deleteMilkabilityReport} resetCreatedMilkabilityReports={resetCreatedMilkabilityReports} data-sentry-element="CreatedMilkabilityReportsTable" data-sentry-source-file="new-milkability-report.tsx" />
            </Card>
        </Content>;
};
export const NewMilkabilityReport = memo(NewMilkabilityReportBase);