// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-user-detail-components-user-detail-wrapper-___styles__active___beqni{color:#77ab11}.src-app-user-detail-components-user-detail-wrapper-___styles__inactive___JsOqo{color:#ff4d4f}.src-app-user-detail-components-user-detail-wrapper-___styles__marker___lYFL6{font-size:small;display:flex;margin-bottom:4px}.src-app-user-detail-components-user-detail-wrapper-___styles__icon___KVpor{display:flex;align-self:center;margin-right:2px;padding-top:2px}.src-app-user-detail-components-user-detail-wrapper-___styles__tabs___V_R1p .ant-tabs-top .ant-tabs-nav{margin:0!important}@media (max-width: 575px){.src-app-user-detail-components-user-detail-wrapper-___styles__marker___lYFL6{font-size:x-small;margin-bottom:1px}.src-app-user-detail-components-user-detail-wrapper-___styles__icon___KVpor{padding-top:1px}}
`, "",{"version":3,"sources":["webpack://./src/app/user-detail/components/user-detail-wrapper/styles.sass"],"names":[],"mappings":"AAAA,8EAAQ,aAAA,CAAc,gFAAU,aAAA,CAAc,8EAAQ,eAAA,CAAgB,YAAA,CAAa,iBAAA,CAAkB,4EAAM,YAAA,CAAa,iBAAA,CAAkB,gBAAA,CAAiB,eAAA,CAAgB,wGAA3K,kBAAA,CAAyO,0BAAyB,8EAAQ,iBAAA,CAAkB,iBAAA,CAAkB,4EAAM,eAAA,CAAA","sourcesContent":[".active{color:#77ab11}.inactive{color:#ff4d4f}.marker{font-size:small;display:flex;margin-bottom:4px}.icon{display:flex;align-self:center;margin-right:2px;padding-top:2px}.tabs :global .ant-tabs-top .ant-tabs-nav{margin:0 !important}@media(max-width: 575px){.marker{font-size:x-small;margin-bottom:1px}.icon{padding-top:1px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `src-app-user-detail-components-user-detail-wrapper-___styles__active___beqni`,
	"inactive": `src-app-user-detail-components-user-detail-wrapper-___styles__inactive___JsOqo`,
	"marker": `src-app-user-detail-components-user-detail-wrapper-___styles__marker___lYFL6`,
	"icon": `src-app-user-detail-components-user-detail-wrapper-___styles__icon___KVpor`,
	"tabs": `src-app-user-detail-components-user-detail-wrapper-___styles__tabs___V_R1p`
};
export default ___CSS_LOADER_EXPORT___;
