import { ToolbarButton, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { Opt } from 'ts-opt';
import { Calf } from 'api/gen/Calf';
import { ConnectedTable } from 'utils/tables';
import { simpleAnimalBrowserSelector } from '../../model';
import { useAnimalsBrowserBasicSetup } from '../../utils/use-animals-browser-basic-setup';
import { getCalfBrowserColumns } from './columns';
interface Props {
  exportInProgress: boolean;
  getCalves(tableDataRequest: Opt<TableDataRequest<Calf>>): void;
}
const CalfBrowserTableBase: FC<Props> = ({
  exportInProgress,
  getCalves
}) => {
  const {
    tCommon,
    exportTooltip,
    exportDisabled,
    exportToCSV,
    handleMotherLink
  } = useAnimalsBrowserBasicSetup('CALVES');
  const headerButtons: Array<ToolbarButton> = useMemo(() => [{
    disabled: exportDisabled,
    onClick: exportToCSV,
    title: tCommon('downloadCsv'),
    tooltip: exportTooltip,
    id: 'exportCalves',
    role: 'export',
    loading: exportInProgress
  }], [exportDisabled, exportToCSV, tCommon, exportTooltip, exportInProgress]);
  return <ConnectedTable tableId="calfBrowser" columns={getCalfBrowserColumns(handleMotherLink)} onFetchTableData={getCalves} rowDataPageSelector={simpleAnimalBrowserSelector.calfBrowserPage} enableTableSettings enableTableUserSettings headerButtons={headerButtons} paginationPosition="both" withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter data-sentry-element="ConnectedTable" data-sentry-component="CalfBrowserTableBase" data-sentry-source-file="index.tsx" />;
};
export const CalfBrowserTable = memo(CalfBrowserTableBase);