import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {initialLegacyState, LegacyState} from './state';

const legacyStateO = O.optic<LegacyState>();

export const legacyReducer = (state: LegacyState = initialLegacyState, action: AppAction): LegacyState => {
    switch (action.type) {
        case 'legacy/RESET_SCREEN': {
            return initialLegacyState;
        }

        case 'legacy/SET_IS_IFRAME_LOADING': {
            const {value} = action.payload;

            return O.set(legacyStateO.prop('iframeLoading'))(value)(state);
        }

        default:
            return state;
    }
};
