import { FooterButtons } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { useOurTranslation } from 'app/translations';
import { useForm } from 'utils/forms';
import { exportAnimalsFormInitialValues, exportAnimalsFormName } from './export-animals-form-values';
import { ExportAnimalsProps } from './export-animals-props';
import { getValidate } from './validate';
const ExportAnimalsFormBase: FC<ExportAnimalsProps> = props => {
  const {
    isAnimalsPageEmpty,
    onSubmit,
    onCancel
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('animalListModals');
  const validate = useMemo(() => getValidate(isAnimalsPageEmpty), [isAnimalsPageEmpty]);
  const {
    Form,
    Fields,
    submitting,
    valid,
    pristine
  } = useForm({
    form: exportAnimalsFormName,
    initialValues: exportAnimalsFormInitialValues,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    onSubmit,
    validate
  });
  return <Form data-sentry-element="Form" data-sentry-component="ExportAnimalsFormBase" data-sentry-source-file="index.tsx">
            <Fields.Input name="exportAnimalsInList" type="text" label={t('name')} disabled={submitting} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <FooterButtons valid={valid} submitting={submitting} pristine={pristine} submitTitle={tCommon('save')} onCancel={onCancel} data-sentry-element="FooterButtons" data-sentry-source-file="index.tsx" />
        </Form>;
};
export const ExportAnimalsForm = memo(ExportAnimalsFormBase);