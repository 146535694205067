import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {select, call} from 'typed-redux-saga';

import {State} from 'app/setup';

import {matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';
import {matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';
import {ResetSingleCriteriaValueAction} from '../action';
import {MatingFormName, resetCriteriaValues} from './reset-criteria-values-saga-utils';

function* execute({payload: {sex, criteriaCode}}: ResetSingleCriteriaValueAction): SagaIterator {
    if (sex === 'FEMALE') {
        const cowCriteriaDefinitions = (yield* select((state: State) => state.mating.cowCriteriaDefinitions))
            .orCrash('criteria definitions not availabe');

        yield* call(
            resetCriteriaValues,
            matingCowsFilterFormName as MatingFormName,
            cowCriteriaDefinitions.filter(x => x.code === criteriaCode),
        );
    } else {
        const bullCriteriaDefinitions = (yield* select((state: State) => state.mating.bullCriteriaDefinitions))
            .orCrash('criteria definitions not availabe');

        yield* call(
            resetCriteriaValues,
            matingBullsFilterFormName as MatingFormName,
            bullCriteriaDefinitions.filter(x => x.code === criteriaCode),
        );
    }
}

export function* resetSingleCriteriaValueSaga(): SagaIterator {
    yield takeLatestF('mating/RESET_SINGLE_CRITERIA_VALUE', execute);
}
