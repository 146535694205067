import { Notifications, StyleVariables, ComponentsContextProvider, initSentry } from '@fl/cmsch-fe-library';
import React, { FC, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { connect, Provider, useSelector, useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';
import { compose } from 'redux';
import { Api } from 'api/gen/Api';
import { Language } from 'api/gen/Language';
import { Config } from 'app/config';
import { ErrorBoundary } from 'app/error-boundary';
import { handleGoogleAnalyticsScript } from 'app/gtag';
import { logger } from 'app/sentry-logger';
import { RefreshModal } from 'app/system/components/refresh-modal';
import { i18n } from 'app/translations/i18n';
import { Ant } from 'common/ant';
import { CookieModal, layoutAction } from 'common/layout';
import { MenuAppWrapper } from 'common/layout/components/menu-app-wrapper';
import { cookieApprovalStore } from 'common/layout/utils/cookie-approval-store';
import { getTableConfig } from 'utils/table-config';
import { simpleTableSelector } from 'utils/tables';
import { injectStore } from './api';
import { Routes } from './routes/routes';
import { State } from './setup';
import { history, store } from './setup/store';
import { LANGUAGES } from './translations';
import { t } from './translations/t';
import { pathNamesSelector, userAction, UserPermissionGuard } from './user';
import { deleteUserSettingsSagaExecute } from './user/model/sagas/delete-user-settings-saga';
import 'styles/styles.sass';
import 'styles/ant-design.less';
const styleVariables: StyleVariables = {
  primary: '#77AB11',
  secondary: '#E1F1C7'
};
interface StateProps {
  language: Language;
}
type Props = StateProps;
initSentry({
  dsn: 'https://ef81dc6de0034590a3975b8a02023d29@o413656.ingest.sentry.io/5384139',
  environment: Config.environment,
  enabled: !Config.local
});
const AppBase: FC<Props> = ({
  language
}) => {
  const [cookieModalVisible, setCookieModalVisible] = useState(cookieApprovalStore.get().isEmpty);
  const dispatch = useDispatch();
  useEffect(() => {
    const isEnabled = cookieApprovalStore.isStatisticalAccepted();
    handleGoogleAnalyticsScript(isEnabled);
    dispatch(layoutAction.setStatisticalCookie(isEnabled));
  }, [dispatch]);
  const tablesState = useSelector(simpleTableSelector.tables);
  const pathNames = useSelector(pathNamesSelector);
  return <DndProvider backend={HTML5Backend} data-sentry-element="DndProvider" data-sentry-component="AppBase" data-sentry-source-file="app.tsx">
            <I18nextProvider i18n={i18n} data-sentry-element="I18nextProvider" data-sentry-source-file="app.tsx">
                <ComponentsContextProvider language={language} t={t} tablesState={tablesState} pathNames={pathNames} styleVariables={styleVariables} getTableConfig={getTableConfig} getTableSettings={Api.getGridSettings} userAction={userAction} logger={logger} deleteUserSettingsSagaExecute={deleteUserSettingsSagaExecute} requiredRedStarPosition="right" data-sentry-element="ComponentsContextProvider" data-sentry-source-file="app.tsx">
                    <Ant.ConfigProvider locale={LANGUAGES[language].antdLocalizationFile} data-sentry-element="unknown" data-sentry-source-file="app.tsx">
                        <HelmetProvider data-sentry-element="HelmetProvider" data-sentry-source-file="app.tsx">
                            <Router history={history} data-sentry-element="Router" data-sentry-source-file="app.tsx">
                                <Notifications data-sentry-element="Notifications" data-sentry-source-file="app.tsx" />
                                <MenuAppWrapper data-sentry-element="MenuAppWrapper" data-sentry-source-file="app.tsx">
                                    <UserPermissionGuard data-sentry-element="UserPermissionGuard" data-sentry-source-file="app.tsx">
                                        <Routes data-sentry-element="Routes" data-sentry-source-file="app.tsx" />
                                    </UserPermissionGuard>
                                </MenuAppWrapper>
                                <CookieModal visible={cookieModalVisible} setVisible={setCookieModalVisible} data-sentry-element="CookieModal" data-sentry-source-file="app.tsx" />
                                <RefreshModal data-sentry-element="RefreshModal" data-sentry-source-file="app.tsx" />
                            </Router>
                        </HelmetProvider>
                    </Ant.ConfigProvider>
                </ComponentsContextProvider>
            </I18nextProvider>
        </DndProvider>;
};
document.addEventListener('scroll', () => {
  document.documentElement.dataset['scroll'] = String(window.scrollY);
});
injectStore(store);
const mapStateToProps = (state: State): StateProps => ({
  language: state.user.language
});
const enhance = compose<React.ComponentType>(connect(mapStateToProps, null));
const App = enhance(AppBase);
const StoreWrappedApp: React.FC = () => <Provider store={store} data-sentry-element="Provider" data-sentry-component="StoreWrappedApp" data-sentry-source-file="app.tsx">
        <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="app.tsx">
            <App data-sentry-element="App" data-sentry-source-file="app.tsx" />
        </ErrorBoundary>
    </Provider>;
export default StoreWrappedApp;