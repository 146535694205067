import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {Milkability} from 'api/gen/Milkability';
import {MilkabilityDetail} from 'api/gen/MilkabilityDetail';
import {PagedModelMilkability} from 'api/gen/PagedModelMilkability';

import {MilkabilityFormValues} from '../components/milkability-report-form/form-values';
import {MilkabilityLoadingId} from '../types/milkability-loading-id';

export const milkabilityAction = {
    getMilkabilityReports: (tableDataRequest: Opt<TableDataRequest<Milkability>>) => ({
        type: 'milkability/GET_MILKABILITY_REPORTS',
        payload: {tableDataRequest},
    }) as const,
    getMilkabilityReportsSuccess: (tableData: Opt<PagedModelMilkability>) => ({
        type: 'milkability/GET_MILKABILITY_REPORTS_SUCCESS',
        payload: {tableData},
    }) as const,
    exportMilkabilityReports: () => ({
        type: 'milkability/EXPORT_MILKABILITY_REPORTS',
    }) as const,
    deleteMilkabilityReport: (id: number) => ({
        type: 'milkability/DELETE_MILKABILITY_REPORT',
        payload: {id},
    }) as const,
    deleteMilkabilityReportSuccess: (id: number) => ({
        type: 'milkability/DELETE_MILKABILITY_REPORT_SUCCESS',
        payload: {id},
    }) as const,
    validateMilkabilityData: () => ({
        type: 'milkability/VALIDATE_MILK_DATA',
    }) as const,
    createMilkabilityReport: (
        data: MilkabilityFormValues,
        focusEarTag: (() => void) | undefined,
        editingId?: number,
    ) => ({
        type: 'milkability/CREATE_MILKABILITY_REPORT',
        payload: {data, focusEarTag, editingId},
    }) as const,
    createMilkabilityReportSuccess: (data: MilkabilityDetail) => ({
        type: 'milkability/CREATE_MILKABILITY_REPORT_SUCCESS',
        payload: {data},
    }) as const,
    resetCreatedMilkabilityReports: () => ({
        type: 'milkability/RESET_CREATED_MILKABILITY_REPORTS',
    }) as const,
    calculateApmv: () => ({
        type: 'milkability/CALCULATE_APMV',
    }) as const,
    calculateApmvSuccess: (apmv: number) => ({
        type: 'milkability/CALCULATE_APMV_SUCCESS',
        payload: {apmv},
    }) as const,
    setEditingMilkabilityReportId: (id?: number) => ({
        type: 'milkability/SET_EDITING_MILK_REPORT_ID',
        payload: {id},
    }) as const,
    setLoading: (id: MilkabilityLoadingId, loading: boolean) => ({
        type: 'milkability/SET_LOADING',
        payload: {id, loading},
    }) as const,
    loadMilkabilityReportDetail: (id: number) => ({
        type: 'milkability/LOAD_MILKABILITY_REPORT_DETAIL',
        payload: {id},
    }) as const,
};

export type GetMilkabilityReportsAction = ReturnType<typeof milkabilityAction.getMilkabilityReports>;
export type GetMilkabilityReportsSuccessAction = ReturnType<typeof milkabilityAction.getMilkabilityReportsSuccess>;
export type ExportMilkabilityReportsAction = ReturnType<typeof milkabilityAction.exportMilkabilityReports>;
export type DeleteMilkabilityReportAction = ReturnType<typeof milkabilityAction.deleteMilkabilityReport>;
export type DeleteMilkabilityReportSuccessAction = ReturnType<typeof milkabilityAction.deleteMilkabilityReportSuccess>;
export type ValidateMilkabilityDataAction = ReturnType<typeof milkabilityAction.validateMilkabilityData>;
export type CreateMilkabilityReportAction = ReturnType<typeof milkabilityAction.createMilkabilityReport>;
export type CreateMilkabilityReportSuccessAction = ReturnType<typeof milkabilityAction.createMilkabilityReportSuccess>;
export type ResetCreatedMilkabilityReportsAction = ReturnType<typeof milkabilityAction.resetCreatedMilkabilityReports>;
export type CalculateApmvAction = ReturnType<typeof milkabilityAction.calculateApmv>;
export type CalculateApmvSuccessAction = ReturnType<typeof milkabilityAction.calculateApmvSuccess>;
export type SetEditingMilkabilityReportIdAction = ReturnType<typeof milkabilityAction.setEditingMilkabilityReportId>;
export type SetLoadingAction = ReturnType<typeof milkabilityAction.setLoading>;
export type LoadMilkabilityReportDetailAction = ReturnType<typeof milkabilityAction.loadMilkabilityReportDetail>;

export type MilkabilityAction =
    | GetMilkabilityReportsAction
    | GetMilkabilityReportsSuccessAction
    | ExportMilkabilityReportsAction
    | DeleteMilkabilityReportAction
    | DeleteMilkabilityReportSuccessAction
    | ValidateMilkabilityDataAction
    | CreateMilkabilityReportAction
    | CreateMilkabilityReportSuccessAction
    | ResetCreatedMilkabilityReportsAction
    | CalculateApmvAction
    | CalculateApmvSuccessAction
    | SetEditingMilkabilityReportIdAction
    | SetLoadingAction
    | LoadMilkabilityReportDetailAction
;
