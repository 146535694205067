/* eslint-disable no-irregular-whitespace */
import {apmvRangeMaximum, apmvRangeMinimum} from 'api/gen/ApmvRange';

const DECIMAL_PLACES = 2;

export const commonTranslations = {
    CS: {
        'common': {
            pageTitle: 'Načítání',
            loading: 'Načítání…',
            loadingUser: 'Načítání uživatele…',
            firstName: 'Jméno',
            lastName: 'Příjmení',
            email: 'E-mail',
            username: 'Uživatelské jméno',
            company: 'Organizace',
            active: 'Aktivní',
            inactive: 'Neaktivní',
            save: 'Uložit',
            cancel: 'Zrušit',
            submit: 'Odeslat',
            create: 'Vytvořit',
            edit: 'Upravit',
            confirm: 'Potvrdit',
            close: 'Zavřít',
            delete: 'Smazat',
            yes: 'Ano',
            no: 'Ne',
            unselected: 'Nevybráno',
            from: 'Od',
            to: 'Do',
            loadingTable: 'Načítání tabulky…',
            unexpectedError: 'Došlo k neočekávané chybě',
            requestWasNotExecuted: 'Požadavek nebyl vykonán',
            add: 'Přidat',
            export: 'Exportovat',
            load: 'Načíst',
            password: 'Heslo',
            confirmPassword: 'Potvrzení hesla',
            backToList: 'Zpět na seznam',
            users: 'Uživatelé',
            organization: 'Organizace',
            earTag: 'UZ',
            earTagTooltip: 'Ušní známka',
            earTagPlaceholder: 'např. CZ000123456789',
            cowEarTag: 'UZ pl.',
            cowEarTagTooltip: 'Ušní známka plemenice',
            farms: 'Hospodářství',
            breeder: 'Chovatel',
            businessName: 'Obchodní jméno',
            officeRank: 'Funkce ve společnosti',
            linReg: 'Linie a registr',
            videoTutorial: 'Videonávod',
            pdfTutorial: 'PDF Manuál',
            stableCode: 'Číslo stáje',
            stableCodeTooltip: 'Stáj',
            stableName: 'Název stáje',
            stableNameTooltip: 'Název',
            breed: 'Plemeno',
            lactation: 'Laktace',
            lactationTooltip: 'Pořadí laktace',
            validating: 'Ověřování…',
            downloadCsv: 'CSV',
            resetSettings: 'Obnovit nastavení',
            select: 'Vybrat',
            phone: 'Telefon',
            fax: 'Fax',
            createEntry: 'Vytvoření záznamu',
            createEntrySuccess: 'Vytvoření záznamu bylo úspěšné.',
            deleteEntry: 'Smazání záznamu',
            deleteEntrySuccess: 'Smazání záznamu bylo úspěšné.',
            updateEntry: 'Aktualizace záznamu',
            updateEntrySuccess: 'Aktualizace záznamu byla úspěšná.',
            validateEntry: 'Validace záznamu',
        },
        'common/menu': {
            'administration': 'Administrace',
            'users': 'Uživatelé',
            'roles': 'Role',
            'permissions': 'Oprávnění',
            'bulkPrint': 'Tiskové dávky',
            'operationalAssemblies': 'Provozní sestavy',
            'buildLogReport': 'Buildlog report',
            'about': 'O eSkotu',
            'importInseminationReport': 'Import hlášení inseminací',
            'getInseminationReport': 'Inseminační výkaz / ET',
            'reproductionReport': 'Reprodukce',
            'supd': 'SUPD',
            'fileManager': 'Úložiště souborů',
            'tbdRequest': 'Požadavky na TDB',
            'tbdRequestApprove': 'Schválit požadavky na TDB',
            'supdRequest': 'Požadavky na sestavy',
            'supdRequestApprove': 'Schválit požadavky sestavy',
            'browser': 'Prohlížeč',
            'animalSearch': 'Vyhledání zvířete',
            'bullsReport': 'Sestavy býků',
            'czechBulls': 'České strakaté',
            'holsteinBulls': 'Holštýnské',
            'cowsReport': 'Sestavy krav',
            'czechCows': 'České strakaté',
            'holsteinCows': 'Holštýnské',
            'enums': 'Jmenovky',
            'inseminationTechnicians': 'Inseminační technici',
            'breedBooksEnums': 'Plemenné knihy',
            'subjects': 'Subjekty',
            'bullLine': 'Linie býka',
            'signsEnum': 'Číselník znaků',
            'breedBooks': 'Plemenné knihy',
            'animalList': 'Seznam zvířat',
            'bulkAssemblyGeneration': 'Hromadné generování sestav',
            'animalRenumber': 'Přečíslování zvířete',
            'etCombination': 'Kombinace ET',
            'animalHistory': 'Historie zvířete',
            'genderChange': 'Změna pohlaví',
            'breedingGame': 'Plemeno hra',
            'mating': 'Mating',
            'calculation': 'Připařovací plán',
            'bullselectorH': 'Bullselector ‐ H',
            'bullselectorC': 'Bullselector ‐ C',
            'user': 'Uživatel',
            'password': 'Změnit heslo',
            'profile': 'Profil uživatele',
            'login': 'Přihlásit',
            'logout': 'Odhlásit',
            'cowBrowser': 'Prohlížeč plemenic',
            'comprehensiveBreedingFemaleBrowser': 'Komplexní seznam plemenic',
            'comprehensiveCalfBrowser': 'Komplexní seznam telat',
            'users-new': 'Uživatelé ‐ přepracováno',
            'reports': 'Hlášení',
            'reporting': 'Reporty',
            'milkability': 'Dojitelnost',
            'analysisProtocolCorrection': 'Oprava rozborového protokolu',
        },
        'common/urlTitles': {
            '/Admin/Users': 'Administrace uživatelů',
            '/FlmExport/TiskoveDavky': 'Tiskové dávky',
            '/FlmExport/ProvozniSestavy': 'Provozní sestavy',
            '/Report/BuildLogReport': 'Build Log report',
            '/Reprodukce/SeznamDavekHlaseni': 'Přehled hlášení reprodukce',
            '/FileManagerT/FileManagerUser': 'SUPD | Úložiště souborů',
            '/FlmPozadavky/PozadavekSubjekt': 'SUPD | Požadavky na TDB',
            '/FlmPozadavky/SchvalitPozadavek': 'SUPD | Schválit požadavky na TDB',
            '/FlmPozadavky/SestavaSubjekt': 'SUPD | Požadavky na sestavy',
            '/FlmPozadavky/SchvalitSestava': 'SUPD | Schválit požadavky na sestavy',
            '/ProhlizecZvirat': 'Vyhledání zvířete',
            '/ProhlizecZvirat/SestavyZvirat/bykC': 'Sestavy býků | České strakaté',
            '/ProhlizecZvirat/SestavyZvirat/bykH': 'Sestavy býků | Holštýnské',
            '/ProhlizecZvirat/SestavyZvirat/kravaC': 'Sestavy krav | České strakaté',
            '/ProhlizecZvirat/SestavyZvirat/kravaH': 'Sestavy krav | Holštýnské',
            '/Jmenovky/InseminacniTechnici': 'Jmenovky | Inseminační technici',
            '/PlemennaKniha/JmenovkaPK': 'Jmenovky | Plemenné knihy',
            '/AaaSubjekt/SeznamSubjektu': 'Jmenovky | Subjekty',
            '/JmeLinieByka/LinieByka': 'Jmenovky | Linie býka',
            '/Znaky/Znaky': 'Jmenovky | Číselník znaků',
            '/PlemennaKniha/SeznamZvirat': 'Plemenné knihy | Seznam zvířat',
            '/PlemennaKniha/GenerovaniSestav': 'Plemenné knihy | Hromadné generování sestav',
            '/PlemennaKniha/PrecislovaniZvirete': 'Plemenné knihy | Přečíslování zvířete',
            '/PlemennaKniha/KombinaceET': 'Plemenné knihy | Kombinace ET',
            '/PlemennaKniha/HistorieZvirete': 'Plemenné knihy | Historie zvířete',
            '/PlemennaKniha/ZmenaPohlavi': 'Plemenné knihy | Změna pohlaví',
            '/PlemennaKniha/PlemenoHra': 'Plemenné knihy | Plemeno hra',
            '/Account/ChangePassword': 'Uživatelský profil | Změna hesla',
            '/home': 'eSkot',
            'loading': 'Načítání…',
        },
        'common/error': {
            addressError: 'Chyba v adrese',
            malformedUrl: 'Požadovanou adresu nelze zobrazit z důvodu poškozené URL adresy.',
            goBackAndTryAgain: 'Prosím přejděte zpět na hlavní stránku a zkuste operaci opakovat:',
            goToEskot: 'Přejít na eSkot.cz',
            unexpectedError: 'Neočekávaná chyba',
            unexpectedErrorOccurred: 'V aplikaci došlo k neočekávané chybě, ' +
            'problém byl zaznamenán do našeho systému a budeme se ho snažit co nejdříve vyřešit. ' +
            'Omlouváme se za způsobené potíže.',
            internalSagaError: 'Interní chyba Sagy',
            errorOccurredIn: 'Nastala chyba v Sage "{{sagaName}}"',
        },
        'common/validator': {
            isRequired: '{{label}} je povinný údaj.',
            mustBeChecked: 'Políčko {{label}} musí být zaškrtnuto.',
            oneChar: 'znak',
            lessThanFiveChars: 'znaky',
            moreChars: 'znaků',
            minLength: '{{label}} musí mít délku alespoň {{minLen}} {{characters}}.',
            maxLength: '{{label}} musí mít délku nejvýše {{maxLen}} {{characters}}.',
            mustHaveFormat: '{{label}} musí být v platném formátu.',
            wrongFormat: '{{label}} má nesprávný formát.',
            isNotInteger: '{{label}} není celé číslo.',
            isNotFloatNumber: '{{label}} není desetinné číslo.',
            minNumber: '{{label}} musí být minimálně číslo {{min}}.',
            maxNumber: '{{label}} musí být maximálně číslo {{max}}.',
            cantBeNegativeNumber: '{{label}} nemůže být záporné číslo.',
            isNotFloatOneDecimal: '{{label}} musí být číslo, max. 1 desetinné místo.',
            arrayLength: '{{label}} musí mít alespoň {{length}} prvků.',
            uniqueError: 'Hodnota pro {{label}} již existuje.',
            incorrectChecksum: 'Nesprávný kontrolní součet.',
            cinNotValid: '{{label}} není validní (nesedí kontrolní číslice).',
            cinUserDetails: 'Musí být vyplněno {{cinLabel}} nebo zaškrtnuto {{noCinLabel}}.',
            cinBulkEdit: 'Musí být vyplněno {{cinLabel}} nebo {{prefillName}}.',
            passwordsMustMatch: 'Hesla se musí shodovat',
            betweenNumbers: '{{label}} musí být v rozmezí {{min}} až {{max}} {{unit}}.',
            betweenTimes: '{{label}} musí být v rozmezí {{min}} až {{max}} {{unit}}. a zadaná hodnota platným časovým údajem.',
            betweenNumbersNoUnit: '{{label}} musí být v rozmezí {{min}} až {{max}}.',
            apmv: `Korigovaný průměrný měsíční výdojek (APMV) není v intervalu ${apmvRangeMinimum.toFixed(DECIMAL_PLACES)} až ${apmvRangeMaximum.toFixed(DECIMAL_PLACES)} l.`,
            examinationDate: 'Datum zkoušky musí být v posledních dvou kontrolních letech.',
            examinationDateFuture: 'Datum zkoušky nesmí být v budoucnosti.',
        },
    },
    EN: {
        'common': {
            pageTitle: 'Loading',
            loading: 'Loading…',
            loadingUser: 'Loading user…',
            firstName: 'Forename',
            lastName: 'Surname',
            email: 'E-mail',
            username: 'Username',
            company: 'Company',
            active: 'Active',
            inactive: 'Inactive',
            save: 'Save',
            cancel: 'Cancel',
            submit: 'Submit',
            create: 'Create',
            edit: 'Edit',
            confirm: 'Confirm',
            close: 'Close',
            delete: 'Delete',
            yes: 'Yes',
            no: 'No',
            unselected: 'Unselected',
            from: 'From',
            to: 'To',
            loadingTable: 'Loading table...',
            unexpectedError: 'An unexpected error occurred',
            requestWasNotExecuted: 'Request was not executed',
            add: 'Add',
            export: 'Export',
            load: 'Load',
            password: 'Password',
            confirmPassword: 'Confirm password',
            backToList: 'Back to list',
            users: 'Users',
            organization: 'Organization',
            earTag: 'ET',
            earTagTooltip: 'Ear tag',
            earTagPlaceholder: 'e.g. CZ000123456789',
            cowEarTag: 'Cow ET',
            cowEarTagTooltip: 'Cow ear tag',
            farms: 'Farms',
            breeder: 'Breeder',
            businessName: 'Business name',
            officeRank: 'Office rank',
            linReg: 'Line and registry',
            videoTutorial: 'Video tutorial',
            pdfTutorial: 'PDF Manual',
            stableCode: 'Stable Code',
            stableCodeTooltip: 'Stable',
            stableName: 'Stable Name',
            stableNameTooltip: 'Name',
            breed: 'Breed',
            lactation: 'Lactation',
            lactationTooltip: 'Lactation order',
            validating: 'Validating…',
            downloadCsv: 'CSV',
            resetSettings: 'Reset settings',
            select: 'Select',
            phone: 'Phone',
            fax: 'Fax',
            createEntry: 'Create entry',
            createEntrySuccess: 'Entry was created successfully.',
            deleteEntry: 'Delete entry',
            deleteEntrySuccess: 'Entry was deleted successfully.',
            updateEntry: 'Update entry',
            updateEntrySuccess: 'Entry was updated successfully.',
            validateEntry: 'Entry validation',
        },
        'common/menu': {
            'administration': 'Administration',
            'users': 'Users',
            'roles': 'Roles',
            'permissions': 'Permissions',
            'bulkPrint': 'Bulk print',
            'operationalAssemblies': 'Op. assemblies',
            'buildLogReport': 'Buildlog report',
            'about': 'About eSkot',
            'importInseminationReport': 'Import insemination report',
            'getInseminationReport': 'Get insemination report/ ET',
            'reproductionReport': 'Reproduction',
            'supd': 'SUPD',
            'fileManager': 'File manager',
            'tbdRequest': 'TDB requests',
            'tbdRequestApprove': 'Approve TBD requests',
            'supdRequest': 'SUPD requests',
            'supdRequestApprove': 'Approve SUPD requests',
            'browser': 'Browser',
            'animalSearch': 'Animal search',
            'bullsReport': 'Bulls report',
            'czechBulls': 'Czech Fleckvieh',
            'holsteinBulls': 'Holstein',
            'cowsReport': 'Cows report',
            'czechCows': 'Czech Fleckvieh',
            'holsteinCows': 'Holstein',
            'enums': 'Enums',
            'inseminationTechnicians': 'Insemination technicians',
            'breedBooksEnums': 'Breeding books',
            'subjects': 'Subjects',
            'bullLine': 'Bull line',
            'signsEnum': 'Signs enum',
            'breedBooks': 'Breeding records',
            'animalList': 'Animal list',
            'bulkAssemblyGeneration': 'Bulk assembly generation',
            'animalRenumber': 'Animal renumber',
            'etCombination': 'ET combination',
            'animalHistory': 'Animal history',
            'genderChange': 'Gender change',
            'breedingGame': 'Breeding game',
            'mating': 'Mating',
            'calculation': 'Preparation plan',
            'bullselectorH': 'Bullselector - H',
            'bullselectorC': 'Bullselector ‐ C',
            'user': 'User',
            'password': 'Change password',
            'profile': 'User profile',
            'login': 'Log in',
            'logout': 'Log out',
            'comprehensiveBreedingFemaleBrowser': 'Comprehensive breeding female browser',
            'comprehensiveCalfBrowser': 'Comprehensive calf browser',
            'cowBrowser': 'Cow browser',
            'users-new': 'Users - reworked',
            'reports': 'Reports',
            'reporting': 'Reporting',
            'milkability': 'Milkability',
            'analysisProtocolCorrection': 'Analysis protocol correction',
        },
        'common/urlTitles': {
            '/Admin/Users': 'Administration of users',
            '/FlmExport/TiskoveDavky': 'Bulk print',
            '/FlmExport/ProvozniSestavy': 'Operational assemblies',
            '/Report/BuildLogReport': 'Build Log report',
            '/Reprodukce/SeznamDavekHlaseni': 'Reproduction',
            '/FileManagerT/FileManagerUser': 'SUPD | File manager',
            '/FlmPozadavky/PozadavekSubjekt': 'SUPD | TDB requests',
            '/FlmPozadavky/SchvalitPozadavek': 'SUPD | Approve TDB requests',
            '/FlmPozadavky/SestavaSubjekt': 'SUPD | SUPD requests',
            '/FlmPozadavky/SchvalitSestava': 'SUPD | Approve SUPD requests',
            '/ProhlizecZvirat': 'Animal search',
            '/ProhlizecZvirat/SestavyZvirat/bykC': 'Bulls report | Czech Fleckvieh',
            '/ProhlizecZvirat/SestavyZvirat/bykH': 'Bulls report | Holstein',
            '/ProhlizecZvirat/SestavyZvirat/kravaC': 'Cows report | Czech Fleckvieh',
            '/ProhlizecZvirat/SestavyZvirat/kravaH': 'Cows report | Holstein',
            '/Jmenovky/InseminacniTechnici': 'Enums | Insemination technicians',
            '/PlemennaKniha/JmenovkaPK': 'Enums | Breeding books',
            '/AaaSubjekt/SeznamSubjektu': 'Enums | Subjects',
            '/JmeLinieByka/LinieByka': 'Enums | Bull line',
            '/Znaky/Znaky': 'Enums | Signs enum',
            '/PlemennaKniha/SeznamZvirat': 'Breeding records | Animal list',
            '/PlemennaKniha/GenerovaniSestav': 'Breeding records | Bulk assembly generation',
            '/PlemennaKniha/PrecislovaniZvirete': 'Breeding records | Animal renumber',
            '/PlemennaKniha/KombinaceET': 'Breeding records | ET combination',
            '/PlemennaKniha/HistorieZvirete': 'Breeding records | Animal history',
            '/PlemennaKniha/ZmenaPohlavi': 'Breeding records | Gender change',
            '/PlemennaKniha/PlemenoHra': 'Breeding records | Breeding game',
            '/Account/ChangePassword': 'User profile | Change password',
            '/home': 'eSkot',
            'loading': 'Loading…',
        },
        'common/error': {
            addressError: 'Address error',
            malformedUrl: 'The requested address could not be displayed due to URL corruption.',
            goBackAndTryAgain: 'Please go back to the main page and try the operation again:',
            goToEskot: 'Go to eSkot.cz',
            unexpectedError: 'Unexpected error',
            unexpectedErrorOccurred: 'The application encountered an unexpected error, ' +
            'the problem has been logged into our system and we will try to resolve it as soon as possible. ' +
            'We apologize for the inconvenience.',
            internalSagaError: 'Internal Saga error',
            errorOccurredIn: 'An error has occurred in Saga "{{sagaName}}"',
        },
        'common/validator': {
            isRequired: '{{label}} is required.',
            mustBeChecked: '{{label}} must be checked.',
            oneChar: 'character',
            lessThanFiveChars: 'characters',
            moreChars: 'characters',
            minLength: '{{label}} must have length at least {{minLen}} {{characters}}.',
            maxLength: '{{label}} must have length at most {{maxLen}} {{characters}}.',
            mustHaveFormat: '{{label}} must be in a valid format.',
            wrongFormat: '{{label}} has wrong format.',
            isNotInteger: '{{label}} is not an integer.',
            isNotFloatNumber: '{{label}} is not a float number.',
            minNumber: '{{label}} must be at least {{min}}.',
            maxNumber: '{{label}} must be at most {{max}}.',
            cantBeNegativeNumber: '{{label}} can\'t be a negative number.',
            isNotFloatOneDecimal: '{{label}} must be a number, max. 1 decimal place.',
            arrayLength: '{{label}} must have at least {{length}} elements.',
            uniqueError: 'Value for {{label}} already exists.',
            incorrectChecksum: 'Incorrect checksum.',
            cinNotValid: '{{label}} is not valid (check digits are not valid).',
            cinUserDetails: '{{cinLabel}} must be filled out or {{noCinLabel}} checked out.',
            cinBulkEdit: '{{cinLabel}} or {{prefillName}} must be checked.',
            passwordsMustMatch: 'Passwords must match.',
            betweenNumbers: '{{label}} must be between {{min}} and {{max}} {{unit}}.',
            betweenNumbersNoUnit: '{{label}} must be between {{min}} and {{max}}.',
            betweenTimes: '{{label}} must be between {{min}} and {{max}} {{unit}}. and the entered value is a valid time.',
            apmv: `Corrected average monthly milking (APMV) is not in the range ${apmvRangeMinimum} to ${apmvRangeMaximum} l.`,
            examinationDate: 'Examination date must be in the current control year.',
            examinationDateFuture: 'Examination date must not be in future.',
        },
    },
};
