import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    tablesAction,
    getRequestedTablePageParams,
    simpleTableSelector,
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
} from 'utils/tables';

import {adminAction, GetRolePermissionsAction} from '../action';

function* execute({payload: {roleId, tableDataRequest}}: GetRolePermissionsAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(adminAction.getRolePermissionsSuccess(none));
    }
    yield* put(tablesAction.tableDataFetchStart('rolePermissions'));

    const tableState = yield* select(simpleTableSelector.tableState('rolePermissions'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'rolePermissions',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });
    const columnSettings = getRequestedTableColumnSettings<'rolePermissions'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.getPermissionsAssignedToRole, filters, params, {roleId});

    if (response.isSuccess) {
        yield* put(adminAction.getRolePermissionsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('rolePermissions', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('admin/sagas')('loadPermissionList')));
        yield* put(tablesAction.tableDataFetchFailure('rolePermissions'));
    }
}

export function* getRolePermissionsV2Saga(): SagaIterator {
    yield takeLatestF('admin/GET_ROLE_PERMISSIONS', execute);
}
