import {NumberRangeValue, DateRangeType, Dictionary, Nullable} from '@fl/cmsch-fe-library';

export const initialCriterias: Criterias = {
    radios: {},
    dateRanges: {},
    numberRanges: {},
};

export interface Criterias {
    radios: Dictionary<string, Nullable<boolean>>;
    dateRanges: Dictionary<string, Nullable<DateRangeType>>;
    numberRanges: Dictionary<string, NumberRangeValue>;
}
