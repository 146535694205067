import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {select, call, put} from 'typed-redux-saga';

import {State} from 'app/setup';
import {formHelpers} from 'utils/forms';

import {firstAccordionPosition} from '../../components/criteria-collapse';
import {matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';
import {matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';
import {ResetAccordionValuesAction} from '../action';
import {MatingFormName, resetCriteriaValues} from './reset-criteria-values-saga-utils';

// eslint-disable-next-line max-lines-per-function
function* execute({payload: {sex, accordionId}}: ResetAccordionValuesAction): SagaIterator {
    const criteriaAccordions = (yield* select((state: State) => state.mating.criteriaAccordions))
        .orCrash('criteriaAccordions not availabe');
    const resetAccordion = opt(criteriaAccordions.find(x => x.id === accordionId))
        .orCrash('accordion not found');

    if (sex === 'FEMALE') {
        const cowCriteriaDefinitions = (yield* select((state: State) => state.mating.cowCriteriaDefinitions))
            .orCrash('criteria definitions not availabe');

        yield* call(
            resetCriteriaValues,
            matingCowsFilterFormName as MatingFormName,
            cowCriteriaDefinitions.filter(x => x.index.accordionId === accordionId),
        );

        if (resetAccordion.position === firstAccordionPosition) {
            yield* put(formHelpers.change(matingCowsFilterFormName, 'farmIds', null));
            yield* put(formHelpers.untouch(matingCowsFilterFormName, 'farmIds'));
            yield* put(formHelpers.change(matingCowsFilterFormName, 'breederId', null));
            yield* put(formHelpers.untouch(matingCowsFilterFormName, 'breederId'));
        }
    } else {
        const bullCriteriaDefinitions = (yield* select((state: State) => state.mating.bullCriteriaDefinitions))
            .orCrash('criteria definitions not availabe');

        yield* call(
            resetCriteriaValues,
            matingBullsFilterFormName as MatingFormName,
            bullCriteriaDefinitions.filter(x => x.index.accordionId === accordionId),
        );

        if (resetAccordion.position === firstAccordionPosition) {
            yield* put(formHelpers.change(matingBullsFilterFormName, 'organizationIds', null));
            yield* put(formHelpers.untouch(matingBullsFilterFormName, 'organizationIds'));
        }
    }
}

export function* resetAccordionValuesSaga(): SagaIterator {
    yield takeLatestF('mating/RESET_ACCORDION_VALUES', execute);
}
