import {earTagRegexGen} from 'api/gen/EarTag';
import {milkKgDecimalPlaces, milkKgMaximum, milkKgMinimum} from 'api/gen/MilkKg';
import {positiveOrZeroDecimal52Maximum, positiveOrZeroDecimal52Minimum} from 'api/gen/PositiveOrZeroDecimal52';
import {positiveOrZeroDecimal80Maximum, positiveOrZeroDecimal80Minimum} from 'api/gen/PositiveOrZeroDecimal80';
import {technicianNumberRegexGen} from 'api/gen/TechnicianNumber';
import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {ApcReportFormValues} from './form-values';

const te = t('apc/new');

const oneHundredth = 0.01;
const positiveDecimal52InclusiveMaximum = positiveOrZeroDecimal52Maximum - oneHundredth;
const positiveOrZeroDecimal80InclusiveMaximum = positiveOrZeroDecimal80Maximum - 1;

// eslint-disable-next-line max-lines-per-function
export const validate = (values: ApcReportFormValues): Errors<ApcReportFormValues> => {
    const validator = new EskotValidator(values);

    validator.nonEmpty('correctionType', te('correctionType'));

    validator.nonEmpty('technicianNumber', te('technicianNumber'));
    validator.pattern('technicianNumber', technicianNumberRegexGen(), te('technicianNumber'));

    validator.nonEmpty('earTag', te('earTag'));
    validator.pattern('earTag', earTagRegexGen(), te('earTag'));

    validator.nonEmpty('stableNumber', te('stableNumber'));

    validator.nonEmpty('proficiencyTestDate', te('proficiencyTestDate'));

    if (values.correctionType === 'EAR_TAG_UPDATE') {
        validator.nonEmpty('updatedEarTag', te('earTagChange'));
        validator.pattern('updatedEarTag', earTagRegexGen(), te('earTagChange'));
    } else if (values.correctionType === 'UPDATE') {
        validator.betweenNumbers({
            fieldName: 'fatPercentage',
            label: te('fatPercentage'),
            min: positiveOrZeroDecimal52Minimum,
            max: positiveDecimal52InclusiveMaximum,
            inclusive: true,
            precision: 2,
        });

        validator.betweenNumbers({
            fieldName: 'lactosePercentage',
            label: te('lactosePercentage'),
            min: positiveOrZeroDecimal52Minimum,
            max: positiveDecimal52InclusiveMaximum,
            inclusive: true,
            precision: 2,
        });

        validator.betweenNumbers({
            fieldName: 'proteinPercentage',
            label: te('proteinPercentage'),
            min: positiveOrZeroDecimal52Minimum,
            max: positiveDecimal52InclusiveMaximum,
            inclusive: true,
            precision: 2,
        });

        validator.betweenNumbers({
            fieldName: 'somaticCells',
            label: te('somaticCells'),
            min: positiveOrZeroDecimal80Minimum,
            max: positiveOrZeroDecimal80InclusiveMaximum,
            inclusive: true,
            precision: 0,
        });

        validator.betweenNumbers({
            fieldName: 'urea',
            label: te('urea'),
            min: positiveOrZeroDecimal52Minimum,
            max: positiveDecimal52InclusiveMaximum,
            inclusive: true,
            precision: 2,
        });
    }

    validator.nonEmpty('milkKg', te('milkKg'));
    validator.betweenNumbers({
        fieldName: 'milkKg',
        label: te('milkKg'),
        min: milkKgMinimum,
        max: milkKgMaximum,
        inclusive: true,
        precision: milkKgDecimalPlaces,
    });

    return validator.generateErrorsObject();
};
