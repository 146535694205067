import { Modal } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo } from 'react';
import { useContactTabSetup } from 'app/user-detail/hooks/use-contact-tab-setup';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { ContactsTabProps } from '../../types/contacts-tab-props';
import { ContactsForm } from '../contact-form';
import { columns } from './columns';
import { rowMapper } from './row-mapper';
const UserContactsTableBase: FC<ContactsTabProps> = props => {
  const {
    actionMode,
    actionSettings,
    contactOptions,
    contactsSelector,
    formDataForInit,
    getContacts,
    handleCloseModal,
    handleOpenCreateModal,
    handleSubmitModal,
    headerButtons,
    isFromAdminModule,
    pathnamesForRefresh,
    t,
    tCommon,
    userProfileId,
    validateDebounced,
    visibleModal
  } = useContactTabSetup(props);
  return <Fragment>
            <ConnectedTable tableId={isFromAdminModule ? 'userContacts' : 'userContactsProfile'} columns={columns(tCommon)} onFetchTableData={getContacts} rowDataPageSelector={contactsSelector} rowDataToTableRowMapper={rowMapper} actionSettings={actionSettings} headerButtons={headerButtons} withoutVerticalScrollbar paginationPosition="both" pathnamesForRefresh={pathnamesForRefresh} lazyLoadingLimit={20} showFulltextFilter enableTableSettings highlightOnEdit enableTableUserSettings emptyTableContent={isFromAdminModule ? <Button role="create" onClick={handleOpenCreateModal}>
                        {t('contact')}
                    </Button> : undefined} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
            {isFromAdminModule && <Modal title={t(actionMode === 'create' ? 'addContact' : 'editContact')} visible={visibleModal} onCancel={handleCloseModal} footer={null} width={520}>
                <Ant.Row>
                    <Ant.Col xs={24}>
                        <ContactsForm mode={actionMode} userProfileId={userProfileId} data={formDataForInit} contactOptions={contactOptions} onSyncValidate={validateDebounced} onCancel={handleCloseModal} onSubmit={handleSubmitModal} />
                    </Ant.Col>
                </Ant.Row>
            </Modal>}
        </Fragment>;
};
export const UserContactsTable = memo(UserContactsTableBase);