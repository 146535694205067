import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {matingAction, CreateAnimalListAction} from '../action';
import {getAnimalListId} from './get-animal-list-id';
import {getTableInfo} from './get-table-info';

const te = t('mating/sagas');

function* execute({payload: {sex, name, associationType}}: CreateAnimalListAction): SagaIterator {
    const {module, code} = getTableInfo(sex, associationType);

    yield* put(formHelpers.startSubmit('createAnimalList'));
    const response = yield* call(Api.validateAnimalList, {id: null, name, module, code});

    const title = te('createAnimalList');

    if (response.isSuccess) {
        const storingResponse = yield* call(Api.createAnimalListFromSession, {module, code, name});
        yield* put(matingAction.toggleAnimalListsVisibility(sex));
        yield* put(formHelpers.stopSubmit('createAnimalList'));

        if (storingResponse.isSuccess) {
            yield* put(matingAction.getAnimalLists(sex, associationType, true));
            yield* put(showSuccess(title, te('animalListCreated'), false, 'create-animal-list'));
        } else {
            yield putAll(showBeError(storingResponse, title, {includeFieldErrors: true}));
        }
    } else if (response.isBadRequest) {
        yield* put(formHelpers.stopSubmit('createAnimalList'));
        yield* put(matingAction.toggleAnimalListsVisibility(sex));
        const id = yield* call(getAnimalListId, name);

        yield* put(matingAction.toggleOverwriteAnimalListVisibility(sex, opt(id)));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit('createAnimalList', extractFormErrorsFromResponse(response)));
    }
}

export function* createAnimalListSaga(): SagaIterator {
    yield takeLatestF('mating/VALIDATE_ANIMAL_LIST_NAME', execute);
}
