import { TypedFormFieldComponents } from '@fl/cmsch-fe-library';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { Accordion } from 'api/gen/Accordion';
import { AssociationType } from 'api/gen/AssociationType';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { useForm } from 'utils/forms';
import { matingAction, matingSelector } from '../../model';
import { MatingBullsFilterFormValues, matingCowsFilterFormName, MatingCowsFilterFormValues, matingCowsInitialFormValues } from '../../types';
import { AnimalsFormContainer } from '../animals-form-containter';
import { CriteriasInCollapse } from '../criteria-collapse';
import { BottomButtons } from './bottom-buttons';
import { ExtraFirstAccordionNodes } from './extra-first-accordion-nodes';
import { validate } from './validate';
const FEMALE = 'FEMALE';
const antFormProps = {
  layout: 'horizontal',
  labelAlign: 'left',
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 6
    },
    xl: {
      span: 5
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 18
    },
    xl: {
      span: 19
    }
  },
  labelWrap: true
} as const;
interface Props {
  associationType: AssociationType;
  criteriaAccordions: Opt<Array<Accordion>>;
  criteriaDefinitions: Opt<Array<CriteriaDefinition>>;
  criteriaListsEmpty: boolean;
  animalListsEmpty: boolean;
  isAnimalsPageEmpty: boolean;
  onSubmit(): void;
  toggleAnimalListsVisibility(): void;
  toggleAddAnimalsVisibility(): void;
  toggleAddAnimalsFromDBVisibility(): void;
  toggleListsOverviewVisibility(): void;
  toggleCriteriaListsVisibility(): void;
  toggleCriteriaListDetailsVisibility(): void;
}
const MatingCowsFilterFormBase: FC<Props> = props => {
  const {
    associationType,
    criteriaAccordions,
    criteriaDefinitions,
    criteriaListsEmpty,
    animalListsEmpty,
    isAnimalsPageEmpty,
    onSubmit,
    toggleAnimalListsVisibility,
    toggleAddAnimalsVisibility,
    toggleAddAnimalsFromDBVisibility,
    toggleListsOverviewVisibility,
    toggleCriteriaListsVisibility,
    toggleCriteriaListDetailsVisibility
  } = props;
  const dispatch = useDispatch();
  const resetCowsAccordionValues = useCallback((accordionId: number): void => {
    dispatch(matingAction.resetAccordionValues(FEMALE, accordionId));
  }, [dispatch]);
  const resetCowsCriteriaValue = useCallback((criteriaCode: Opt<string>): void => {
    dispatch(matingAction.resetSingleCriteriaValue(FEMALE, criteriaCode.orElse('')));
  }, [dispatch]);
  const getFarms = useCallback(() => void dispatch(matingAction.getFarms()), [dispatch]);
  const deleteCowsFromList = useCallback((silentMode?: boolean): void => {
    dispatch(matingAction.deleteAnimalsFromList(FEMALE, associationType, silentMode));
  }, [dispatch, associationType]);
  const addCowsFromCsv = useCallback((file: File): void => {
    dispatch(matingAction.addAnimalsFromCsv(FEMALE, file, associationType));
  }, [dispatch, associationType]);
  const {
    Form,
    Fields,
    valid,
    submitting
  } = useForm({
    form: matingCowsFilterFormName,
    initialValues: matingCowsInitialFormValues,
    onSubmit,
    validate
  }, antFormProps);
  const breeders = useSelector(matingSelector.breeders);
  const farms = useSelector(matingSelector.farms);
  const extraFirstAccordionNodes = useMemo(() => <ExtraFirstAccordionNodes breeders={breeders} farms={farms} getFarms={getFarms} Fields={Fields} />, [breeders, farms, getFarms, Fields]);
  const criteriaAccordionNodes = useMemo(() => <CriteriasInCollapse criteriaAccordions={criteriaAccordions.orElse([])} criteriaDefinitions={criteriaDefinitions.orElse([])} extraFirstAccordionNodes={extraFirstAccordionNodes} resetCowsAccordionValues={resetCowsAccordionValues} resetCriteriaValue={resetCowsCriteriaValue}
  // TODO: improve type after updating typescript to 4.7+
  Fields={Fields as TypedFormFieldComponents<MatingCowsFilterFormValues | MatingBullsFilterFormValues>} />, [Fields, criteriaAccordions, criteriaDefinitions, extraFirstAccordionNodes, resetCowsAccordionValues, resetCowsCriteriaValue]);
  const bottomButtons = useMemo(() => <BottomButtons valid={valid} submitting={submitting} criteriaListsEmpty={criteriaListsEmpty} animalListsEmpty={animalListsEmpty} isAnimalsPageEmpty={isAnimalsPageEmpty} handleCowsCsvUpload={addCowsFromCsv} toggleAnimalListsVisibility={toggleAnimalListsVisibility} handleDeleteCowsFromList={deleteCowsFromList} toggleAddAnimalsVisibility={toggleAddAnimalsVisibility} toggleAddAnimalsFromDBVisibility={toggleAddAnimalsFromDBVisibility} toggleListsOverviewVisibility={toggleListsOverviewVisibility} toggleCriteriaListsVisibility={toggleCriteriaListsVisibility} toggleCriteriaListDetailsVisibility={toggleCriteriaListDetailsVisibility} />, [valid, submitting, criteriaListsEmpty, animalListsEmpty, isAnimalsPageEmpty, addCowsFromCsv, toggleAnimalListsVisibility, deleteCowsFromList, toggleAddAnimalsVisibility, toggleAddAnimalsFromDBVisibility, toggleListsOverviewVisibility, toggleCriteriaListsVisibility, toggleCriteriaListDetailsVisibility]);
  return useMemo(() => <AnimalsFormContainer>
            <Form>
                {criteriaAccordionNodes}
                {bottomButtons}
            </Form>
        </AnimalsFormContainer>, [Form, bottomButtons, criteriaAccordionNodes]);
};
export const MatingCowsFilterForm = memo(MatingCowsFilterFormBase);