import { LoaderWrapper } from '@fl/cmsch-fe-library';
import { isUndefined } from 'lodash/fp';
import React, { FC, memo } from 'react';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { BackButton } from 'common/buttons';
import { Card } from 'common/layout';
import { ApcReportForm } from '../components/apc-report-form';
import { CreatedApcReportsTable } from '../components/created-apc-reports-table';
import { useNewApcReportScreenSetup } from '../hooks/use-new-apc-report-screen-setup';
const NewApcReportBase: FC = () => {
  const {
    header,
    tCommon,
    correctionType,
    proficiencyTestDateOptions,
    stableOptions,
    stableDateRelations,
    technicianOptions,
    stableDateOptionsLoading,
    technicianOptionsLoading,
    analysisProtocolLoading,
    stableNumber,
    proficiencyTestDateValue,
    earTagValue,
    earTagRef,
    createdCorrectionReports,
    editingCorrectionReportId,
    correctionTypeRef,
    technicianNumberValue,
    technicianNumberRef,
    goBackToList,
    asyncValidate,
    deleteCreatedApcReport,
    editCreatedApcReport,
    resetCreatedApcReports,
    getAnalysisProtocol,
    getStableDateRelation,
    setStableOptions,
    createCorrectionReport,
    onCancel,
    setProficiencyTestDateOptions,
    resetWithoutCorrectionTypeAndTechnicianNumber,
    clearAdditionalDataInProtocol
  } = useNewApcReportScreenSetup();
  const {
    t
  } = useOurTranslation('apc/apc');
  return <Content browserTabTitle={t('pageTitle')} data-sentry-element="Content" data-sentry-component="NewApcReportBase" data-sentry-source-file="new-apc-report.tsx">
            <Card title={header} titleSize="big" extra={<BackButton text={tCommon('backToList')} onClick={goBackToList} />} data-sentry-element="Card" data-sentry-source-file="new-apc-report.tsx">
                <LoaderWrapper data-sentry-element="LoaderWrapper" data-sentry-source-file="new-apc-report.tsx">
                    <ApcReportForm correctionType={correctionType} correctionTypeRef={correctionTypeRef} proficiencyTestDateOptions={proficiencyTestDateOptions} stableOptions={stableOptions} stableDateRelations={stableDateRelations} technicianOptions={technicianOptions} technicianOptionsLoading={technicianOptionsLoading} stableDateOptionsLoading={stableDateOptionsLoading} analysisProtocolLoading={analysisProtocolLoading} stableNumber={stableNumber} proficiencyTestDateValue={proficiencyTestDateValue} earTagValue={earTagValue} earTagRef={earTagRef} technicianNumberRef={technicianNumberRef} editingCorrectionReport={!isUndefined(editingCorrectionReportId)} technicianNumberValue={technicianNumberValue} setProficiencyTestDateOptions={setProficiencyTestDateOptions} setStableOptions={setStableOptions} getAnalysisProtocol={getAnalysisProtocol} getStableDateRelation={getStableDateRelation} asyncValidate={asyncValidate} onSubmit={createCorrectionReport} onCancel={onCancel} resetWithoutCorrectionTypeAndTechnicianNumber={resetWithoutCorrectionTypeAndTechnicianNumber} clearAdditionalDataInProtocol={clearAdditionalDataInProtocol} data-sentry-element="ApcReportForm" data-sentry-source-file="new-apc-report.tsx" />
                </LoaderWrapper>
                <CreatedApcReportsTable createdApcReports={createdCorrectionReports} deleteCreatedApcReport={deleteCreatedApcReport} editCreatedApcReport={editCreatedApcReport} resetCreatedApcReports={resetCreatedApcReports} data-sentry-element="CreatedApcReportsTable" data-sentry-source-file="new-apc-report.tsx" />
            </Card>
        </Content>;
};
export const NewApcReport = memo(NewApcReportBase);