import { debounceTime, FooterButtons } from '@fl/cmsch-fe-library';
import { debounce } from 'lodash/fp';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tCommon, useOurTranslation } from 'app/translations';
import { formHelpers, useForm } from 'utils/forms';
import { adminDiscriminatorOptions } from '../../constants/options';
import { adminAction } from '../../model';
import { simpleAdminSelector } from '../../model/selector';
import { CreateOrEditMode } from '../../types/permission-form-mode';
import { createOrUpdatePermissionFormName, createOrUpdatePermissionFormValues } from '../../types/permission-form-values';
import { validate } from './validate';
interface Props {
  permissionId: number | null;
  mode: CreateOrEditMode;
  onSubmit(): void;
  onCancel(): void;
}
const PermissionFormBase: FC<Props> = ({
  onSubmit,
  onCancel,
  mode,
  permissionId
}) => {
  const tableData = useSelector(simpleAdminSelector.permissionDetailsTableData);
  const dispatch = useDispatch();
  const {
    t
  } = useOurTranslation('admin/permissions');
  const {
    Form,
    Fields,
    submitting,
    valid,
    pristine,
    asyncValidating
  } = useForm({
    form: createOrUpdatePermissionFormName,
    initialValues: createOrUpdatePermissionFormValues,
    destroyOnUnmount: true,
    onSubmit,
    validate
  });
  const isEdit = mode === 'edit' && permissionId !== null;
  const initFormData = useMemo(() => tableData.filter(perm => perm.id === permissionId)[0], []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEdit && initFormData) {
      dispatch(formHelpers.initialize(createOrUpdatePermissionFormName, initFormData));
    }
  }, [dispatch, initFormData, isEdit, tableData]);
  const validateData = useCallback(() => {
    dispatch(adminAction.validatePermission(initFormData, mode));
  }, [dispatch, initFormData, mode]);
  const validateDebounced = useMemo(() => debounce(debounceTime)(validateData), [validateData]);
  return <Form data-sentry-element="Form" data-sentry-component="PermissionFormBase" data-sentry-source-file="index.tsx">
            <Fields.Input name="code" type="text" label={t('code')} disabled={submitting} autoFocus onFieldChange={validateDebounced} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.Input name="controllerName" type="text" label={t('controller')} disabled={mode === 'edit'} onFieldChange={validateDebounced} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.Input name="actionName" type="text" label={t('action')} disabled={mode === 'edit'} onFieldChange={validateDebounced} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.Input name="comment" type="text" label={t('comment')} disabled={submitting} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.StringSelect name="discriminator" label={t('discriminator')} options={adminDiscriminatorOptions} disabled={submitting} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.Checkbox name="userEnable" label={t('userEnable')} disabled={submitting} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <FooterButtons submitting={submitting} valid={valid} pristine={pristine} withoutDivider onCancel={onCancel} asyncValidating={asyncValidating} submitTitle={isEdit ? tCommon('edit') : tCommon('create')} data-sentry-element="FooterButtons" data-sentry-source-file="index.tsx" />
        </Form>;
};
export const PermissionForm = memo(PermissionFormBase);