import { Modal } from '@fl/cmsch-fe-library';
import React, { FC, Fragment, memo } from 'react';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { useOtherSubjectsTabSetup } from '../../hooks/use-other-subject-tab-setup';
import { OtherSubjectsTabProps } from '../../types/other-subjects-tab-props';
import { SubjectForm } from '../subject-form';
import { columns } from './columns';
import { rowMapper } from './row-mapper';
const OtherSubjectsTableBase: FC<OtherSubjectsTabProps> = props => {
  const {
    userProfileId,
    visibleModal,
    otherSubjectOptions,
    roleOptions,
    formDataForInit,
    headerButtons,
    actionSettings,
    actionMode,
    pathnamesForRefresh,
    isFromAdminModule,
    otherSubjectsForm,
    t,
    getOtherSubjects,
    handleSubmitModal,
    handleOpenCreateModal,
    handleCloseModal,
    handleOtherSubjectsSearch,
    validateDebounced,
    otherSubjectsSelector
  } = useOtherSubjectsTabSetup(props);
  return <Fragment>
            <ConnectedTable tableId={isFromAdminModule ? 'userOtherSubjectRoles' : 'userOtherSubjectRolesProfile'} columns={columns} onFetchTableData={getOtherSubjects} rowDataPageSelector={otherSubjectsSelector} actionSettings={actionSettings} headerButtons={headerButtons} withoutVerticalScrollbar paginationPosition="both" pathnamesForRefresh={pathnamesForRefresh} lazyLoadingLimit={20} showFulltextFilter enableTableSettings enableTableUserSettings rowDataToTableRowMapper={rowMapper} highlightOnEdit emptyTableContent={isFromAdminModule ? <Button role="create" onClick={handleOpenCreateModal}>
                        {t('subject')}
                    </Button> : undefined} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
            {isFromAdminModule ? <Modal title={t(actionMode === 'create' ? 'createOtherSubjectRole' : 'editOtherSubjectRole')} visible={visibleModal === otherSubjectsForm} onCancel={handleCloseModal} footer={null} width={768}>
                    <Ant.Row>
                        <Ant.Col xs={24}>
                            <SubjectForm subjectFormType="OTHER_SUBJECT" data={formDataForInit} onCancel={handleCloseModal} onSubmit={handleSubmitModal} associationOptions={otherSubjectOptions} roleOptions={roleOptions} userProfileId={userProfileId} onAssociationSearch={handleOtherSubjectsSearch} onSyncValidate={validateDebounced} />
                        </Ant.Col>
                    </Ant.Row>
                </Modal> : undefined}
        </Fragment>;
};
export const OtherSubjectsTable = memo(OtherSubjectsTableBase);