import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {adminAction, ChangeSubordinateRoleAction} from '../action';

function* execute({payload: {subordinateRoleId, parentRoleId, method}}: ChangeSubordinateRoleAction): SagaIterator {
    const apiMethod = method === 'add' ? Api.addSubordinateRoleToRole : Api.removeSubordinateRoleFromRole;

    const response = yield* call(apiMethod, {roleId: parentRoleId, subordinateRoleId});

    if (response.isSuccess) {
        yield* put(adminAction.getSubordinateAssignedRoles(opt({action: 'refresh'}), parentRoleId));
        yield* put(adminAction.getSubordinateUnassignedRoles(opt({action: 'refresh'}), parentRoleId));
    } else {
        yield putAll(showBeError(response, t('admin/sagas')('removePermissionFromRole')));
    }
}

export function* changeSubordinateRoleSago(): SagaIterator {
    yield takeLatestF('admin/CHANGE_SUBORDINATE_ROLE', execute);
}
