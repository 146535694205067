export const userTranslations = {
    CS: {
        'user/login': {
            loginHeading: 'Přihlášení',
            username: 'Uživatelské jméno',
            password: 'Heslo',
            loginButton: 'Přihlásit',
            forgottenPassword: 'Zapomenuté jméno nebo heslo?',
            loadingUser: 'Načítání uživatele',
        },
        'user/logout': {
            logoutHeading: 'Odhlašování…',
        },
        'user/profile': {
            pageTitle: 'Uživatelský profil',
            username: 'Přihlašovací jméno',
            company: 'Organizace',
            permissions: 'Oprávnění',
            assignedRoles: 'Přiřazené role',
            profile: 'Uživatelský profil',
            cookieSettings: 'Nastavení cookies',
        },
        'user/withUser': {
            notLoggedIn: 'Nejste přihlášen.',
            login: 'Přihlásit se',
            noPermissionMessage: 'Nemáte oprávnění pro zobrazení této stránky.',
            loginAsAnother: 'Přihlásit se za jiného uživatele',
            loadingUser: 'Načítání uživatele',
        },
        'user/sagas': {
            loadAllRoles: 'Načtení všech rolí',
            languageChange: 'Změna jazyka',
            deleteUserSettings: 'Smazání uživatelského nastavení',
            settingsReset: 'Nastavení bylo resetováno',
            saveUserSettings: 'Uložení uživatelského nastavení',
            userSettingsSaved: 'Nastavení bylo uloženo',
            loadAllPermissions: 'Načtení všech oprávnění',
            loadUserSettings: 'Načtení uživatelského nastavení',
            initializingUser: 'Inicializace uživatele',
            logout: 'Odhlášení',
            unexpectedErrorWhileLogout: 'Nečekáná chyba při odhlášení.',
            unexpectedErrorWhileLegacyLogout: 'Nečekáná chyba při odhlášení z původní aplikace.',
            login: 'Přihlášení',
            loadCurrentUser: 'Načtení aktuálního uživatele',
        },
    },
    EN: {
        'user/login': {
            loginHeading: 'Login',
            username: 'Username',
            password: 'Password',
            loginButton: 'Login',
            forgottenPassword: 'Forgotten username or password?',
            loadingUser: 'Loading user',
        },
        'user/logout': {
            logoutHeading: 'Logging out...',
        },
        'user/profile': {
            pageTitle: 'User profile',
            username: 'Username',
            company: 'Company',
            permissions: 'Permissions',
            assignedRoles: 'Assigned roles',
            profile: 'User profile',
            cookieSettings: 'Cookie settings',
        },
        'user/withUser': {
            notLoggedIn: 'You are not logged in.',
            login: 'Login',
            noPermissionMessage: 'You do not have permission to view this page.',
            loginAsAnother: 'Login as a different user',
            loadingUser: 'Loading user',
        },
        'user/sagas': {
            loadAllRoles: 'Load all roles',
            languageChange: 'Language change',
            deleteUserSettings: 'Delete user settings',
            settingsReset: 'Settings have been reset',
            saveUserSettings: 'Save user settings',
            userSettingsSaved: 'Settings have been saved',
            loadAllPermissions: 'Load all permissions',
            loadUserSettings: 'Load user settings',
            initializingUser: 'Initializing user',
            logout: 'Logout',
            unexpectedErrorWhileLogout: 'Unexpected error occurred while logging out.',
            unexpectedErrorWhileLegacyLogout: 'Unexpected error occurred while logging out of the legacy app.',
            login: 'Login',
            loadCurrentUser: 'Load current user',
        },
    },
};
