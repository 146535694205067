/* eslint-disable max-lines */
import {TableColumn} from '@fl/cmsch-fe-library';

import {FemaleHolsteinAnimalInList} from 'api/gen/FemaleHolsteinAnimalInList';
import {t} from 'app/translations';

import {getAnimalBrowserLink} from '../../utils';

const tm = t('mating/matingCowsTable');

export const matingCowsColumns: Array<TableColumn<FemaleHolsteinAnimalInList>> = [
    {
        field: 'earTag',
        type: 'hyperlink',
        filterTypes: ['betweenEarTags'],
        valueToUrl: getAnimalBrowserLink,
        openInNewTab: true,
        width: 150,
    },
    {
        field: 'farmCode',
        verticalName: true,
        type: 'string',
        width: 150,
    },
    {
        field: 'birthDate',
        verticalName: true,
        type: 'date',
        dateFormat: 'date',
        width: 150,
        filterTypes: ['null'],
    },
    {
        field: 'lastCalvingAt',
        verticalName: true,
        type: 'date',
        dateFormat: 'date',
        width: 150,
        filterTypes: ['null'],
    },
    {
        field: 'lactation',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    },
    {
        field: 'lastMatingDate',
        verticalName: true,
        tooltip: tm('lastAdmissionAtTooltip'),
        type: 'date',
        dateFormat: 'date',
        width: 80,
        filterTypes: ['null'],
    },
    {
        field: 'pregnant',
        verticalName: true,
        type: 'boolean',
        width: 85,
        disableTooltip: true,
    },
    {
        field: 'lactationDays',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    },
    {
        field: 'milkKg',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    },
    {
        field: 'fatKg',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    },
    {
        field: 'fatPercentage',
        verticalName: true,
        type: 'decimal',
        width: 80,
        fixedDecimals: 2,
        filterTypes: ['null'],
    },
    {
        field: 'proteinKg',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    },
    {
        field: 'proteinPercentage',
        verticalName: true,
        type: 'decimal',
        width: 85,
        fixedDecimals: 2,
        filterTypes: ['null'],
    },
    {
        field: 'bvMilkKg',
        verticalName: true,
        type: 'number',
        width: 100,
        filterTypes: ['null'],
    },
    {
        field: 'bvMilkKgReliability',
        verticalName: true,
        type: 'number',
        width: 120,
        filterTypes: ['null'],
    },
    {
        field: 'bvFatKg',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    },
    {
        field: 'bvFatPercentage',
        verticalName: true,
        type: 'decimal',
        width: 80,
        fixedDecimals: 2,
        filterTypes: ['null'],
    },
    {
        field: 'bvProteinKg',
        verticalName: true,
        type: 'number',
        width: 115,
        filterTypes: ['null'],
    },
    {
        field: 'bvProteinPercentage',
        verticalName: true,
        type: 'decimal',
        width: 110,
        fixedDecimals: 2,
        filterTypes: ['null'],
    },
    {
        field: 'sihIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'redIndex',
        verticalName: true,
        type: 'boolean',
        width: 65,
    },
    {
        field: 'inbreedingCoefficient',
        verticalName: true,
        type: 'decimal',
        width: 100,
        fixedDecimals: 3,
    },
    {
        field: 'rbvMilkKg',
        verticalName: true,
        type: 'number',
        width: 95,
        filterTypes: ['null'],
    },
    {
        field: 'rbvFatKg',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    },
    {
        field: 'rbvFatPercentage',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    },
    {
        field: 'rbvProteinKg',
        verticalName: true,
        type: 'number',
        width: 120,
        filterTypes: ['null'],
    },
    {
        field: 'rbvProteinPercentage',
        verticalName: true,
        type: 'number',
        width: 110,
        filterTypes: ['null'],
    },
    {
        field: 'rbvSomaticCells',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvClinicalMastitis',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvLimbDisease',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvFrame',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvChestWidth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvBodyDepth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvEdginess',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvBackAngle',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvBackWidth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvRearPosture',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvSidePosture',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvHoovesAngle',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvForeUdderAttachment',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvFrontTeatsPlacement',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvTeatsLength',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvUdderDepth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvRearUdderAttachmentHeight',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvSuspensoryLigament',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvRearTeatsPlacement',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvRearUdderAttachmentWidth',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvBonesQuality',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvWalkQuality',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvFitness',
        verticalName: true,
        type: 'number',
        width: 65,
        filterTypes: ['null'],
    },
    {
        field: 'rbvMilkStrength',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    },
    {
        field: 'milkStrength',
        verticalName: true,
        type: 'number',
        width: 60,
        filterTypes: ['null'],
    },
    {
        field: 'rbvBodyStructure',
        verticalName: true,
        type: 'number',
        width: 60,
        filterTypes: ['null'],
    },
    {
        field: 'rbvExtremities',
        verticalName: true,
        type: 'number',
        width: 60,
        filterTypes: ['null'],
    },
    {
        field: 'rbvUdder',
        verticalName: true,
        type: 'number',
        width: 60,
        filterTypes: ['null'],
    },
    {
        field: 'rbvOverallCondition',
        verticalName: true,
        type: 'number',
        width: 60,
        filterTypes: ['null'],
    },
    {
        field: 'bodyStructure',
        verticalName: true,
        type: 'number',
        width: 60,
        filterTypes: ['null'],
    },
    {
        field: 'extremities',
        verticalName: true,
        type: 'number',
        width: 95,
        filterTypes: ['null'],
    },
    {
        field: 'udder',
        verticalName: true,
        type: 'number',
        width: 85,
        filterTypes: ['null'],
    },
    {
        field: 'overallClass',
        verticalName: true,
        type: 'string',
        width: 80,
        filterTypes: ['null'],
    },
    {
        field: 'overallCondition',
        verticalName: true,
        type: 'number',
        width: 80,
        filterTypes: ['null'],
    },
    {
        field: 'genotype',
        verticalName: true,
        type: 'string',
        width: 60,
        filterTypes: ['null'],
    },
    {
        field: 'breedAlpha13',
        verticalName: true,
        type: 'string',
        width: 60,
        sortable: true,
        filterTypes: ['null'],
    },
];
