import {AppModule} from 'api/gen/AppModule';
import {AssociationType} from 'api/gen/AssociationType';
import {GridCode} from 'api/gen/GridCode';
import {Sex} from 'api/gen/Sex';

interface TableInfo {
    module: AppModule;
    code: GridCode;
}

export const getTableInfo = (sex: Sex, associationType: AssociationType): TableInfo => ({
    module: associationType === 'FLECKVIEH' ? 'MATING_C' : 'MATING_H',
    code: sex === 'MALE' ? 'BULL' : 'COW',
});
