export const systemTranslations = {
    CS: {
        'system/system': {
            refreshModalTitle: 'Aktualizace',
            refreshModalText: 'Byla vydána nová verze aplikace, pokračujte stisknutím tlačítka OK. ' +
                'Veškeré rozpracované změny budou ztraceny.',
            versionMismatch: 'Nekompatibilní verze',
            versionMismatchBody: 'Verze aplikace není kompatibilní s aplikací na serveru.',
        },
    },
    EN: {
        'system/system': {
            refreshModalTitle: 'Update',
            refreshModalText: 'A new version of the application has been released, press OK to continue.' +
                'All unsaved changes will be lost.',
            versionMismatch: 'Incompatible version',
            versionMismatchBody: 'Application version is incompatible with server application.',
        },
    },
};
