import { ToolbarButton, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { Opt } from 'ts-opt';
import { BreedingFemale } from 'api/gen/BreedingFemale';
import { ConnectedTable } from 'utils/tables';
import { simpleAnimalBrowserSelector } from '../../model';
import { useAnimalsBrowserBasicSetup } from '../../utils/use-animals-browser-basic-setup';
import { getCowBrowserColumns } from './columns';
interface Props {
  exportInProgress: boolean;
  getCows(tableDataRequest: Opt<TableDataRequest<BreedingFemale>>): void;
}
const CowBrowserTableBase: FC<Props> = ({
  exportInProgress,
  getCows
}) => {
  const {
    tCommon,
    exportTooltip,
    exportDisabled,
    exportToCSV,
    handleMotherLink
  } = useAnimalsBrowserBasicSetup('BREEDING_FEMALES');
  const headerButtons: Array<ToolbarButton> = useMemo(() => [{
    disabled: exportDisabled,
    onClick: exportToCSV,
    title: tCommon('downloadCsv'),
    tooltip: exportTooltip,
    id: 'exportCows',
    loading: exportInProgress,
    role: 'export'
  }], [exportDisabled, exportInProgress, exportToCSV, exportTooltip, tCommon]);
  return <ConnectedTable tableId="cowBrowser" columns={getCowBrowserColumns(handleMotherLink)} onFetchTableData={getCows} headerButtons={headerButtons} rowDataPageSelector={simpleAnimalBrowserSelector.cowBrowserPage} enableTableSettings enableTableUserSettings paginationPosition="both" withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter data-sentry-element="ConnectedTable" data-sentry-component="CowBrowserTableBase" data-sentry-source-file="index.tsx" />;
};
export const CowBrowserTable = memo(CowBrowserTableBase);