import { FooterButtons, Options } from '@fl/cmsch-fe-library';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { Opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { useOurTranslation } from 'app/translations';
import { useForm } from 'utils/forms';
import { Warnings } from 'utils/validator';
import { getListName } from '../../utils/get-list-name';
import { createAnimalListFormName, CreateAnimalListFormValues, initialCreateAnimalListFormValues } from './create-animal-list-form-values';
import { validate } from './validate';
interface Props {
  isAnimalsListEmpty: boolean;
  animalListEmptyMessage: string;
  animalLists: Opt<Array<AnimalList>>;
  onSubmit(name: string): void;
  onCancel(): void;
}
const CreateAnimalListFormBase: FC<Props> = props => {
  const {
    onSubmit,
    onCancel,
    isAnimalsListEmpty,
    animalListEmptyMessage,
    animalLists
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('animalListModals');
  const handleSubmit = useCallback(({
    name
  }: CreateAnimalListFormValues) => {
    if (name) onSubmit(name);
  }, [onSubmit]);
  const validateFn = useMemo(() => validate({
    isAnimalsListEmpty,
    animalListEmptyMessage
  }), [isAnimalsListEmpty, animalListEmptyMessage]);
  const listsOptions: Options<string> = useMemo(() => animalLists.mapIn(y => ({
    value: y.name,
    label: getListName(y)
  })).orElse([]), [animalLists]);
  const validateWarnings = useCallback(({
    name
  }: CreateAnimalListFormValues): Warnings<CreateAnimalListFormValues> => listsOptions.some(x => x.value === name) ? {
    name: t('listAlreadyExistsWarning')
  } : {}, [listsOptions, t]);
  const {
    Form,
    Fields,
    submitting,
    valid
  } = useForm({
    form: createAnimalListFormName,
    initialValues: initialCreateAnimalListFormValues,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate: validateFn,
    onSubmit: handleSubmit,
    warn: validateWarnings
  });
  return <Form data-sentry-element="Form" data-sentry-component="CreateAnimalListFormBase" data-sentry-source-file="form.tsx">
            <Fields.StringSelect label={t('name')} name="name" options={listsOptions} includeValueToOptions includeSearchValueInOptions isRequired touchOnChange data-sentry-element="unknown" data-sentry-source-file="form.tsx" />
            <FooterButtons valid={valid} submitting={submitting} submitTitle={tCommon('save')} onCancel={onCancel} data-sentry-element="FooterButtons" data-sentry-source-file="form.tsx" />
        </Form>;
};
export const CreateAnimalListForm = memo(CreateAnimalListFormBase);