import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetPermissionDetailsAction, adminAction} from '../action';

const tt = t('admin/sagas');
function* execute(action: GetPermissionDetailsAction): SagaIterator {
    const {tableDataRequest} = action.payload;
    if (tableDataRequest.isEmpty) {
        return yield* put(adminAction.getPermissionDetailsSuccess(none));
    }
    yield* put(tablesAction.tableDataFetchStart('permissions'));

    const tableState = yield* select(simpleTableSelector.tableState('permissions'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'permissions',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest for permissionDetails'),
        tableState,
        resetType: 'tableDataParams',
    });

    const columnSettings = getRequestedTableColumnSettings<'permissions'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});

    const response = yield* call(Api.findPermissions, filters, params);

    if (response.isSuccess) {
        yield* put(adminAction.getPermissionDetailsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('permissions', tableDataParams));
    } else {
        yield putAll(showBeError(response, tt('loadPermissionsList')));
        yield* put(tablesAction.tableDataFetchFailure('permissions'));
    }
}
export function* getPermissionDetails(): SagaIterator {
    yield takeLatestF('admin/GET_PERMISSION_DETAILS', execute);
}
