// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-user-components-login-form-___styles__login___ZDEJN{width:350px}@media (max-width: 575px){.src-app-user-components-login-form-___styles__login___ZDEJN{width:100vw}}
`, "",{"version":3,"sources":["webpack://./src/app/user/components/login-form/styles.sass"],"names":[],"mappings":"AAAA,6DAAO,WAAA,CAAY,0BAAyB,6DAAO,WAAA,CAAA","sourcesContent":[".login{width:350px}@media(max-width: 575px){.login{width:100vw}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `src-app-user-components-login-form-___styles__login___ZDEJN`
};
export default ___CSS_LOADER_EXPORT___;
