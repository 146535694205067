// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-mating-components-bulls-filter-form-___styles__extraNodes___Rm0yG{overflow:hidden;margin-left:0!important;margin-right:0!important}.src-app-mating-components-bulls-filter-form-___styles__extraNodes___Rm0yG .ant-form-item-label>label{margin-bottom:0!important}.src-app-mating-components-bulls-filter-form-___styles__extraNodes___Rm0yG .ant-form-item-control{margin-bottom:1px!important;max-width:99vw}@media (max-width: 1199px){.src-app-mating-components-bulls-filter-form-___styles__extraNodes___Rm0yG .ant-form-item-label>label{margin-bottom:1rem!important}}@media (max-width: 575px){.src-app-mating-components-bulls-filter-form-___styles__extraNodes___Rm0yG{margin-bottom:.8rem!important}.src-app-mating-components-bulls-filter-form-___styles__extraNodes___Rm0yG .ant-form-item-label{padding-bottom:0!important;margin-top:5px}.src-app-mating-components-bulls-filter-form-___styles__extraNodes___Rm0yG .ant-form-item-label>label{margin-bottom:0!important}}
`, "",{"version":3,"sources":["webpack://./src/app/mating/components/bulls-filter-form/styles.sass"],"names":[],"mappings":"AAAA,2EAAY,eAAA,CAAgB,uBAAA,CAAyB,wBAAA,CAA0B,sGAA+C,yBAAA,CAA2B,kGAA2C,2BAAA,CAA6B,cAAA,CAAe,2BAA0B,sGAA+C,4BAAA,CAAA,CAA+B,0BAAyB,2EAAY,6BAAA,CAA+B,gGAAyC,0BAAA,CAA4B,cAAA,CAAe,sGAA+C,yBAAA,CAAA","sourcesContent":[".extraNodes{overflow:hidden;margin-left:0 !important;margin-right:0 !important}.extraNodes :global .ant-form-item-label>label{margin-bottom:0 !important}.extraNodes :global .ant-form-item-control{margin-bottom:1px !important;max-width:99vw}@media(max-width: 1199px){.extraNodes :global .ant-form-item-label>label{margin-bottom:1rem !important}}@media(max-width: 575px){.extraNodes{margin-bottom:.8rem !important}.extraNodes :global .ant-form-item-label{padding-bottom:0 !important;margin-top:5px}.extraNodes :global .ant-form-item-label>label{margin-bottom:0 !important}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraNodes": `src-app-mating-components-bulls-filter-form-___styles__extraNodes___Rm0yG`
};
export default ___CSS_LOADER_EXPORT___;
