import {SagaIterator} from 'redux-saga';
import {select} from 'typed-redux-saga';

import {State} from 'app/setup';

export function* getAnimalListVersion(id: number): SagaIterator<string> {
    const animalLists = yield* select((state: State) => state.mating.animalLists);
    const animalList = animalLists
        .findIn(y => y.id === id)
        .orCrash('animalLists not available');

    return animalList.version;
}
