import { Loader, debounceTime } from '@fl/cmsch-fe-library';
import { isEmpty, debounce } from 'lodash/fp';
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssociationType } from 'api/gen/AssociationType';
import { matingSelector } from 'app/mating/model/selector';
import { useOurTranslation } from 'app/translations';
import { matingAction } from '../../model';
import { MatingParamsSelectionForm } from './mating-params-selection-form';
import { ParamsModals } from './params-modals';
const bookmark = 'COMPUTATION';
const associationType: AssociationType = 'HOLSTEIN';
export const ParamsSelectionTab: FC = _ => {
  const dispatch = useDispatch();
  const loading = useSelector(matingSelector.loading);
  const criteriaDefinitions = useSelector(matingSelector.paramsCriteriaDefinitions);
  const criteriaLists = useSelector(matingSelector.criteriaLists);
  const isCowsSessionEmpty = useSelector(matingSelector.isCowsSessionEmpty);
  const isBullsSessionEmpty = useSelector(matingSelector.isBullsSessionEmpty);
  const animalsAvailableInSession = useMemo(() => !isBullsSessionEmpty && !isCowsSessionEmpty, [isCowsSessionEmpty, isBullsSessionEmpty]);
  const {
    t
  } = useOurTranslation('mating/matingParamsSelectionForm');
  useEffect(() => {
    dispatch(matingAction.getCriteriaLists(bookmark, associationType));
  }, [dispatch]);
  const toggleSaveCriteriaListVisibility = useCallback((): void => {
    dispatch(matingAction.toggleCriteriaListsVisibility(bookmark));
  }, [dispatch]);
  const toggleLoadCriteriaListVisibility = useCallback((): void => {
    dispatch(matingAction.toggleCriteriaListDetailsVisibility(bookmark));
  }, [dispatch]);
  const toggleCriteriaListOverviewVisibility = useCallback((): void => {
    dispatch(matingAction.toggleListsOverviewVisibility(bookmark));
  }, [dispatch]);
  const getMatingScore = useCallback((): void => {
    dispatch(matingAction.getMatingScore());
  }, [dispatch]);
  const debouncedGetMatingScore = useMemo(() => debounce(debounceTime)(getMatingScore), [getMatingScore]);
  return <Fragment>
            {!isEmpty(loading) ? <Loader size="large" show text={t('loadingCalculationCriteria')} /> : <MatingParamsSelectionForm criteriaDefinitions={criteriaDefinitions} loading={loading} criteriaListsEmpty={criteriaLists.map(isEmpty).orTrue()} animalsAvailableInSession={animalsAvailableInSession} toggleSaveCriteriaListVisibility={toggleSaveCriteriaListVisibility} toggleLoadCriteriaListVisibility={toggleLoadCriteriaListVisibility} toggleCriteriaListOverviewVisibility={toggleCriteriaListOverviewVisibility} onSubmit={debouncedGetMatingScore} />}
            <ParamsModals associationType={associationType} criteriaDefinitions={criteriaDefinitions} toggleSaveCriteriaListVisibility={toggleSaveCriteriaListVisibility} toggleCriteriaListOverviewVisibility={toggleCriteriaListOverviewVisibility} data-sentry-element="ParamsModals" data-sentry-source-file="index.tsx" />
        </Fragment>;
};