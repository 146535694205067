import React, { memo, FC } from 'react';
import { Opt } from 'ts-opt';
import { CriteriaList } from 'api/gen/CriteriaList';
import { useOurTranslation } from 'app/translations';
import { Modal } from 'common/layout/components/modal';
import { CreateCriteriaListForm } from './form';
interface Props {
  visible: boolean;
  criteriaLists: Opt<Array<CriteriaList>>;
  tableSettingsNameList: Array<string>;
  onSubmit(name: string): void;
  onCancel(): void;
}
const ModalCriteriaListNameBase: FC<Props> = props => {
  const {
    visible,
    criteriaLists,
    tableSettingsNameList,
    onSubmit,
    onCancel
  } = props;
  const {
    t
  } = useOurTranslation('mating/modalListName');
  return <Modal title={t('enterCriteriaListName')} visible={visible} onCancel={onCancel} footer={null} data-sentry-element="Modal" data-sentry-component="ModalCriteriaListNameBase" data-sentry-source-file="index.tsx">
            <CreateCriteriaListForm criteriaLists={criteriaLists} tableSettingsNameList={tableSettingsNameList} onCancel={onCancel} onSubmit={onSubmit} visible={visible} data-sentry-element="CreateCriteriaListForm" data-sentry-source-file="index.tsx" />
        </Modal>;
};
export const ModalCriteriaListName = memo(ModalCriteriaListNameBase);