import {AssociationType} from 'api/gen/AssociationType';
import {Bookmark} from 'api/gen/Bookmark';
import {TableId} from 'common/types/table-id';

export const getMatingTableId = (bookmark: Bookmark, associationType: AssociationType): TableId | null => {
    switch (bookmark) {
        case 'FEMALE':
            return 'matingCows';
        case 'MALE':
            return associationType === 'FLECKVIEH' ? 'matingFleckviehBulls' : 'matingHolsteinBulls';
        case 'COMPUTATION':
            return null;
    }
};
