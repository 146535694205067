import { ActionSettings, ToolbarButton, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { useCallback, useMemo, memo, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Opt } from 'ts-opt';
import { MaleFleckviehAnimalInList } from 'api/gen/MaleFleckviehAnimalInList';
import { useOurTranslation } from 'app/translations';
import { ConnectedTable } from 'utils/tables';
import { matingAction, matingSelector } from '../../model';
import { matingFleckviehBullsColumns } from './mating-fleckvieh-bulls-columns';
interface Props {
  exportInProgress: boolean;
  removeBull(animalId: number): void;
  exportBullselectorResult(): void;
}
const MatingBullsFleckviehTableBase: FC<Props> = ({
  exportInProgress,
  removeBull,
  exportBullselectorResult
}) => {
  const dispatch = useDispatch();
  const getMatingBulls = useCallback((tableDataRequest: Opt<TableDataRequest<MaleFleckviehAnimalInList>>) => {
    dispatch(matingAction.getFleckviehMatingBulls(tableDataRequest));
  }, [dispatch]);
  const {
    t
  } = useOurTranslation('common');
  const isPageEmpty = useSelector(matingSelector.isFleckviehPageEmpty);
  const actionSettings: ActionSettings<MaleFleckviehAnimalInList> = useMemo(() => ({
    onRowRemove: removeBull
  }), [removeBull]);
  const headerButtons: Array<ToolbarButton> = useMemo(() => [{
    id: 'exportCows',
    title: t('downloadCsv'),
    onClick: exportBullselectorResult,
    disabled: isPageEmpty,
    role: 'export',
    loading: exportInProgress
  }], [exportBullselectorResult, exportInProgress, isPageEmpty, t]);
  return <ConnectedTable tableId="matingFleckviehBulls" columns={matingFleckviehBullsColumns} onFetchTableData={getMatingBulls} rowDataPageSelector={matingSelector.maleFleckviehAnimalsPage} actionSettings={actionSettings} enableTableUserSettings withoutVerticalScrollbar paginationPosition="both" lazyLoadingLimit={20} showFulltextFilter headerButtons={headerButtons} data-sentry-element="ConnectedTable" data-sentry-component="MatingBullsFleckviehTableBase" data-sentry-source-file="mating-bulls-fleckvieh-table.tsx" />;
};
export const MatingBullsFleckviehTable = memo(MatingBullsFleckviehTableBase);