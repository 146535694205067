import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { opt } from 'ts-opt';
import { AssociationType } from 'api/gen/AssociationType';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { Card, ScreenHeader } from 'common/layout';
import { MatingTabs, ResetAllSettingsButton } from '../components';
import { matingAction, matingSelector } from '../model';
import { matingTabGuard, MatingTabKey } from '../types';
const topActionsDedicatedPanelWidthThreshold = 900;
// when change, do not forget change module name in <ScreenHeader />
const associationType: AssociationType = 'HOLSTEIN';
export const Mating: FC = _ => {
  const {
    t
  } = useOurTranslation('mating/mating');
  const dispatch = useDispatch();
  const activeTab = useSelector(matingSelector.activeTab);
  const matingResult = useSelector(matingSelector.matingResult);
  useEffect(() => {
    dispatch(matingAction.resetScreen());
    dispatch(matingAction.initializeForms(false, associationType));
    dispatch(matingAction.getAnimalsCount('MALE', 'HOLSTEIN'));
    dispatch(matingAction.getAnimalsCount('FEMALE', 'HOLSTEIN'));
  }, [dispatch]);
  const resetForms = useCallback(() => {
    dispatch(matingAction.resetForms(associationType));
  }, [dispatch]);
  const refreshTabData = useCallback((tab: MatingTabKey) => {
    if (tab === 'bullSelection') {
      dispatch(matingAction.getAnimalsCount('MALE', 'HOLSTEIN'));
    } else if (tab === 'cowSelection') {
      dispatch(matingAction.getAnimalsCount('FEMALE', 'HOLSTEIN'));
    }
  }, [dispatch]);
  const handleActiveTabChange = useCallback((newActiveTab: string) => {
    const tab = opt(newActiveTab).narrow(matingTabGuard).orCrash('unknown mating tab key');
    if (tab === activeTab) return;
    dispatch(matingAction.resetAnimalAndCriteriaTags());
    refreshTabData(tab);
    dispatch(matingAction.setActiveTab(tab));
  }, [dispatch, refreshTabData, activeTab]);
  const globalActionsInTabBar = useMemo((): ReactNode => {
    const actions = <ResetAllSettingsButton resetForms={resetForms} testId="reset-all-settings" />;
    return <MediaQuery minWidth={topActionsDedicatedPanelWidthThreshold}>
                {(matches: boolean): ReactNode => matches ? actions : <Ant.Popover content={actions} placement="bottomRight" trigger="click">
                        <Button icon="moreOutlined" />
                    </Ant.Popover>}
            </MediaQuery>;
  }, [resetForms]);
  const header = useMemo(() => <ScreenHeader title={t('matingPlan')} moduleForTutorials="MATING_H" />, [t]);
  return <Content browserTabTitle={t('pageTitle')} noFooter data-sentry-element="Content" data-sentry-component="Mating" data-sentry-source-file="mating.tsx">
            <Card title={header} titleSize="big" noPadding noHeadBorder data-sentry-element="Card" data-sentry-source-file="mating.tsx">
                <MatingTabs activeTab={activeTab} globalActionsInTabBar={globalActionsInTabBar} isMatingResultEmpty={matingResult.isEmpty} handleActiveTabChange={handleActiveTabChange} data-sentry-element="MatingTabs" data-sentry-source-file="mating.tsx" />
            </Card>
        </Content>;
};