import React, { FC, memo, useCallback, useMemo } from 'react';
import { Opt, none } from 'ts-opt';
import { useOurTranslation } from 'app/translations';
import { Modal } from 'common/layout/components/modal';
interface Props {
  visible: boolean;
  animalListId: Opt<number>;
  loading: boolean;
  listAlreadyExistsMessage: string;
  onSubmit(animalListId: number): void;
  onCancel(animalListId: Opt<number>): void;
}
const ModalListNameConfirmBase: FC<Props> = props => {
  const {
    visible,
    animalListId,
    loading,
    listAlreadyExistsMessage,
    onSubmit,
    onCancel
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('animalListModals');
  const handleCancel = useCallback(() => {
    onCancel(none);
  }, [onCancel]);
  const handleSubmit = useCallback(() => {
    onSubmit(animalListId.orCrash('animalListId not available'));
  }, [animalListId, onSubmit]);
  const footer = useMemo(() => ({
    submitText: tCommon('confirm'),
    cancelText: tCommon('cancel'),
    onSubmit: handleSubmit,
    loading
  }), [handleSubmit, tCommon, loading]);
  return <Modal title={t('confirmListName')} visible={visible} onCancel={handleCancel} defaultFooter={footer} data-sentry-element="Modal" data-sentry-component="ModalListNameConfirmBase" data-sentry-source-file="index.tsx">
            {listAlreadyExistsMessage}
        </Modal>;
};
export const ModalListConfirmName = memo(ModalListNameConfirmBase);