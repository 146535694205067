import {NullableKeys} from '@fl/cmsch-fe-library';
import {flip} from 'fp-ts/lib/function';
import {isUndefined} from 'lodash/fp';
import {createSelector, Selector} from 'reselect';
import {isEmpty, Opt, prop} from 'ts-opt';

import {MilkabilityDetail} from 'api/gen/MilkabilityDetail';
import {PagedModelMilkability} from 'api/gen/PagedModelMilkability';
import {State} from 'app/setup';
import {formHelpers} from 'utils/forms';

import {MilkabilityFormValues} from '../components/milkability-report-form/form-values';
import {MilkabilityLoadingId} from '../types/milkability-loading-id';

export const simpleMilkabilitySelector = {
    pageMilkability: ({milkability}: State): Opt<PagedModelMilkability> => milkability.pageMilkability,
    loading: ({milkability}: State): Array<MilkabilityLoadingId> => milkability.loading,
    validatingMilkabilityReportForm: ({milkability}: State): boolean =>
        milkability.loading.includes('validatingMilkabilityReportForm'),
    deleteCreatedReportInProgress: ({milkability}: State): boolean =>
        milkability.loading.includes('deleteCreatedMilkabilityReport'),
    createdMilkabilityReports: ({milkability}: State): Array<MilkabilityDetail> =>
        milkability.createdMilkabilityReports,
    editingMilkabilityReport: ({milkability}: State): boolean => !isUndefined(milkability.editingMilkabilityReportId),
    editingMilkabilityReportId: ({milkability}: State): number | undefined => milkability.editingMilkabilityReportId,
};

const fieldNames = ['apmv', 'milkedTotal', 'milkingTime', 'technicianNumber', 'examinationDate', 'earTag'] as const;
export const isMilkabilityReportFormEmptySelector =
    createSelector<[Selector<State, Opt<NullableKeys<MilkabilityFormValues>>>], boolean>(
        formHelpers.formValues('milkabilityReport'),
        values => fieldNames.map(flip(prop)(values)).every(isEmpty),
    );

export const milkabilityLoadingSelector = createSelector(
    simpleMilkabilitySelector.loading,
    loading => ({
        validatingMilkabilityReportForm: loading.includes('validatingMilkabilityReportForm'),
        deleteCreatedMilkabilityReport: loading.includes('deleteCreatedMilkabilityReport'),
        exportingMilkabilityOverview: loading.includes('exportingMilkabilityOverview'),
        loadingMilkabilityReportDetail: loading.includes('loadingMilkabilityReportDetail'),
    }),
);
