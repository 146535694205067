// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-user-detail-components-user-member-form-___styles__formContainer___sNcKA{display:grid;grid-template-columns:repeat(2,1fr);gap:20px}.src-app-user-detail-components-user-member-form-___styles__select___mUudA{grid-column:span 2}@media (max-width: 767px){.src-app-user-detail-components-user-member-form-___styles__formContainer___sNcKA{grid-template-columns:1fr}.src-app-user-detail-components-user-member-form-___styles__select___mUudA{grid-column:span 1}}
`, "",{"version":3,"sources":["webpack://./src/app/user-detail/components/user-member-form/styles.sass"],"names":[],"mappings":"AAAA,kFAAe,YAAA,CAAa,mCAAA,CAAqC,QAAA,CAAS,2EAAQ,kBAAA,CAAmB,0BAAyB,kFAAe,yBAAA,CAA0B,2EAAQ,kBAAA,CAAA","sourcesContent":[".formContainer{display:grid;grid-template-columns:repeat(2, 1fr);gap:20px}.select{grid-column:span 2}@media(max-width: 767px){.formContainer{grid-template-columns:1fr}.select{grid-column:span 1}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `src-app-user-detail-components-user-member-form-___styles__formContainer___sNcKA`,
	"select": `src-app-user-detail-components-user-member-form-___styles__select___mUudA`
};
export default ___CSS_LOADER_EXPORT___;
