import {
    convertAllErrorsFromBeToFormError,
    Dictionary,
    NullableKeys,
    putAll,
    takeLatestF,
    toPairs,
} from '@fl/cmsch-fe-library';
import * as iots from 'io-ts';
import {fromPairs, map} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {id, opt, optEmptyString, pipe} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {RoleCodeSchema} from 'api/gen/RoleCode';
import {RoleNameSchema} from 'api/gen/RoleName';
import {RoleView} from 'api/gen/RoleView';
import {ValidateRole} from 'api/gen/ValidateRole';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {roleFormName} from '../../types';
import {CreateOrEditMode} from '../../types/permission-form-mode';
import {ValidateRoleAction} from '../action';

export const atLeastOneFieldIsFilled = (name: string | null, code: string | null): boolean =>
    RoleCodeSchema.is(code)
    || RoleNameSchema.is(name);

export const shouldAsyncValidate = (
    name: string | null,
    code: string | null,
    tableRecord: RoleView | undefined,
    mode: CreateOrEditMode,
): boolean =>
    mode === 'edit'
        ? opt(tableRecord).exists(x => x.name !== name || x.code !== code)
        : atLeastOneFieldIsFilled(name, code);

export const validStringFieldValueOrNull = (value: string | null, schema: iots.Type<string>): string | null =>
    optEmptyString(value).narrow(schema.is).orNull();

export const sameFieldsToNull =
    <T extends Dictionary<string, unknown>>(x: T) => <U extends Dictionary<string, unknown>>(y: U): NullableKeys<U> =>
        pipe(y,
            toPairs,
            map(([k, v]) => [k, x[k as string] === v ? null : v]),
            a => fromPairs(a) as NullableKeys<U>,
        );

const genApiObject = (
    name: string | null,
    code: string | null,
    tableRecord: RoleView | undefined,
    mode: CreateOrEditMode,
): ValidateRole => pipe(
    {
        name: validStringFieldValueOrNull(name, RoleNameSchema),
        code: validStringFieldValueOrNull(code, RoleCodeSchema),
    },
    mode === 'edit' ? sameFieldsToNull(opt(tableRecord).orCrash('no table record')) : id,
);

function* execute({payload: {mode, tableRecord}}: ValidateRoleAction): SagaIterator {
    const {
        name,
        code,
    } = (yield* select(formHelpers.formValues(roleFormName)))
        .orCrash('no permission form values');

    if (!shouldAsyncValidate(name, code, tableRecord, mode)) return;
    yield* put(formHelpers.startAsyncValidation(roleFormName));

    const response = yield* call(
        Api.validateRole,
        genApiObject(name, code, tableRecord, mode),
    );

    if (!response.isSuccess) {
        if (response.isBadRequest) {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            yield* put(formHelpers.stopAsyncValidation(roleFormName, errors.errors));
            yield* put(formHelpers.touch(roleFormName, 'code', 'name'));
        } else {
            yield putAll(showBeError(response, t('admin/sagas')('createRole')));
        }
    } else {
        yield* put(formHelpers.stopAsyncValidation(roleFormName, {}));
    }
}

export function* validateRoleSaga(): SagaIterator {
    yield takeLatestF('admin/VALIDATE_ROLE', execute);
}
