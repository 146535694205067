import {NullableKeys} from '@fl/cmsch-fe-library';

import {ContactType} from 'api/gen/ContactType';
import {ContactValue} from 'api/gen/ContactValue';

export const userContactFormName = 'userContactForm';

export interface UserContactFormValues {
    value: ContactValue;
    contactType: ContactType;
    userProfileId: number;
    userContactId: number;
}

export const userContactInitialValues: NullableKeys<UserContactFormValues> = {
    value: '',
    contactType: null,
    userProfileId: null,
    userContactId: null,
};

