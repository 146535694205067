import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {CreateAnimalListFormValues} from './create-animal-list-form-values';

const te = t('animalListModals');

interface ValidateProps {
    isAnimalsListEmpty: boolean;
    animalListEmptyMessage: string;
}

export const validate = ({isAnimalsListEmpty, animalListEmptyMessage}: ValidateProps) =>
    (values: CreateAnimalListFormValues): Errors<CreateAnimalListFormValues> => {
        const validator = new EskotValidator(values);

        validator.nonEmpty('name', te('name'));
        validator.customRule('name', isAnimalsListEmpty, animalListEmptyMessage);

        return validator.generateErrorsObject();
    };
