import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {DeleteAnimalListAction, matingAction} from '../action';
import {getAnimalListVersion} from './get-animal-list-version';

const te = t('mating/sagas');

function* execute({payload: {animalListId, sex, associationType}}: DeleteAnimalListAction): SagaIterator {
    const version = yield* call(getAnimalListVersion, animalListId);
    const response = yield* call(Api.deleteAnimalList, {version}, {animalListId});

    const title = te('deleteAnimalList');

    if (response.isSuccess) {
        yield* put(matingAction.getAnimalLists(sex, associationType, true));
        yield* put(showSuccess(title, te('animalListDeleted'), false, 'delete-animal-list'));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* deleteAnimalListSaga(): SagaIterator {
    yield takeLatestF('mating/DELETE_ANIMAL_LIST', execute);
}
