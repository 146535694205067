// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-modals-components-modal-lists-overview-___styles__colWrapper___EK4UH{display:flex;align-items:center;height:100%}
`, "",{"version":3,"sources":["webpack://./src/app/modals/components/modal-lists-overview/styles.sass"],"names":[],"mappings":"AAAA,8EAAY,YAAA,CAAa,kBAAA,CAAmB,WAAA","sourcesContent":[".colWrapper{display:flex;align-items:center;height:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colWrapper": `src-app-modals-components-modal-lists-overview-___styles__colWrapper___EK4UH`
};
export default ___CSS_LOADER_EXPORT___;
