/* eslint-disable max-lines */
import {RefSelect, debounceTime, Options, TypedFormFieldComponents} from '@fl/cmsch-fe-library';
import {debounce, includes, isEmpty, replace} from 'lodash/fp';
import {ComponentType, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt, pipe, testRe} from 'ts-opt';

import {ApcStableDate} from 'api/gen/ApcStableDate';
import {CorrectionType} from 'api/gen/CorrectionType';
import {Date} from 'api/gen/Date';
import {EarTag} from 'api/gen/EarTag';
import {positiveOrZeroDecimal80RegexGen} from 'api/gen/PositiveOrZeroDecimal80';
import {StableCode} from 'api/gen/StableCode';
import {technicianNumberRegexGen} from 'api/gen/TechnicianNumber';
import {
    analysisProtocolCorrectionAction,
    simpleAnalysisProtocolCorrectionSelector,
} from 'app/analysis-protocol-correction/model';
import {TFunction, useOurTranslation} from 'app/translations';
import {formHelpers, useForm} from 'utils/forms';

import {
    apcReportFormName,
    ApcReportFormValues,
    initialApcReportFormValues,
} from '../components/apc-report-form/form-values';
import {validate} from '../components/apc-report-form/validate';
import {correctionTypeOptions} from '../components/overview-table/columns';
import {
    getDateOptions,
    genProtocolStableOptionsBasedOnDate,
    getStableOptions,
    genNonProtocolDateOptionsBasedOnStable,
    genProtocolDateOptionsBasedOnStable,
} from '../utils/get-apc-options';
import {useDateAndStableChange} from './use-date-and-stable-change';

type NumberInputType = 'decimal52' | 'decimal80' | 'positiveOrZeroDecimal80';

const roundNumberToPrecision = (value: number, type: NumberInputType): number => {
    // eslint-disable-next-line no-magic-numbers
    const decimal = type === 'decimal52' ? Math.pow(10, 2) : 1;

    return Math.round(decimal * value) / decimal;
};

interface Props {
    stableDateRelations: Opt<ApcStableDate>;
    earTagRef: RefObject<HTMLInputElement>;
    technicianNumberRef: RefObject<HTMLInputElement>;
    correctionType: CorrectionType | undefined;
    stableNumber: StableCode | null;
    proficiencyTestDateValue: Date | null;
    earTagValue: EarTag | null;
    technicianNumberValue: string | null;
    analysisProtocolLoading: boolean;
    stableDateOptionsLoading: boolean;
    technicianOptionsLoading: boolean;
    editingCorrectionReport: boolean;
    proficiencyTestDateOptions: Opt<Options<Date>>;
    stableOptions: Opt<Options<StableCode>>;
    setStableOptions(options: Options<string>): void;
    setProficiencyTestDateOptions(options: Options<string>): void;
    onSubmit(formValues: ApcReportFormValues): void;
    asyncValidate(focusSubmit: () => void, getStableDateRelations?: boolean): void;
    getAnalysisProtocol(earTag: EarTag, proficiencyTestDate: Date): void;
    getStableDateRelation(stableCode: StableCode, proficiencyTestDate: Date): void;
    resetWithoutCorrectionTypeAndTechnicianNumber(
        correctionType: CorrectionType | null,
        technicianNumber: string | null,
    ): void;
    clearAdditionalDataInProtocol(changedField: 'proficiencyTestDate' | 'stableNumber', value: string | null): void;
}

interface UseNewApcReportFormSetup {
    Form: ComponentType<{children?: ReactNode}>;
    Fields: TypedFormFieldComponents<ApcReportFormValues>;
    correctionTypeOptions: Options<CorrectionType>;
    technicianNumberRef: RefObject<HTMLInputElement>;
    // TODO temporarily disabled due to bad data in the database. Change for above
    //  Tag TECHNICIANS (https://redmine.favorlogic.com/issues/13226)
    // technicianNumberRef: RefObject<BaseSelectRef>;
    earTagChangeRef: RefObject<HTMLInputElement>;
    proficiencyTestDateRef: RefSelect;
    stableNumberRef: RefSelect;
    milkKgRef: RefObject<HTMLInputElement>;
    t: TFunction<'apc/new'>;
    tCommon: TFunction<'common'>;
    stableNumberDisabled: boolean;
    submitDisabled: boolean;
    isUpdateCorrection: boolean;
    processingForm: boolean;
    fatPercentageRef: RefObject<HTMLInputElement>;
    proteinPercentageRef: RefObject<HTMLInputElement>;
    lactosePercentageRef: RefObject<HTMLInputElement>;
    somaticCellsRef: RefObject<HTMLInputElement>;
    ureaRef: RefObject<HTMLInputElement>;
    submitRef: RefObject<HTMLElement>;
    proficiencyTestDateDisabled(): boolean;
    onTechnicianNumberChange(value: Opt<string>): void;
    // TODO temporarily disabled due to bad data in the database. Change for above
    //  Tag TECHNICIANS (https://redmine.favorlogic.com/issues/13226)
    // onTechnicianNumberChange(): void;
    onEarTagChange(): void;
    onUpdatedEarTagChange(val: Opt<string>): void;
    onProficiencyTestDateChange(val: Opt<Date>): void;
    onStableNumberChange(val: Opt<StableCode>): void;
    onCorrectionTypeChange(value: Opt<CorrectionType>): void;
    onMilkKgInput(value: string): void;
    onFatPercentageInput(value: string): void;
    onProteinPercentageInput(value: string): void;
    onLactosePercentageInput(value: string): void;
    onSomaticCellsInput(value: string): void;
    onUreaInput(value: string): void;
    isAdditionalFieldDisabled(field: keyof ApcReportFormValues): boolean;
}

const protocolExists = (stableDateRelations: Opt<ApcStableDate>): boolean =>
    stableDateRelations.exists(r => r.tag === 'ApcDateListWithStables');

const decimal52Regex = /^\d{1,3}[,.]\d{2}/;
const decimal80Regex = /^\d{1,8}/;

// eslint-disable-next-line max-lines-per-function
export const useNewApcReportFormSetup = ({
    stableDateRelations,
    proficiencyTestDateValue,
    stableNumber,
    earTagRef,
    correctionType,
    earTagValue,
    analysisProtocolLoading,
    stableDateOptionsLoading,
    technicianOptionsLoading,
    technicianNumberRef,
    editingCorrectionReport,
    proficiencyTestDateOptions,
    stableOptions,
    technicianNumberValue,
    setStableOptions,
    setProficiencyTestDateOptions,
    onSubmit,
    asyncValidate,
    getAnalysisProtocol,
    getStableDateRelation,
    resetWithoutCorrectionTypeAndTechnicianNumber,
    clearAdditionalDataInProtocol,
}: Props): UseNewApcReportFormSetup => {
    // TODO temporarily disabled due to bad data in the database. Change for above
    //  Tag TECHNICIANS (https://redmine.favorlogic.com/issues/13226)
    // const technicianNumberRef = useRef<BaseSelectRef>(null);
    const earTagChangeRef = useRef<HTMLInputElement>(null);
    const milkKgRef = useRef<HTMLInputElement>(null);
    const proficiencyTestDateRef: RefSelect = useRef(null);
    const stableNumberRef: RefSelect = useRef(null);
    const fatPercentageRef = useRef<HTMLInputElement>(null);
    const proteinPercentageRef = useRef<HTMLInputElement>(null);
    const lactosePercentageRef = useRef<HTMLInputElement>(null);
    const somaticCellsRef = useRef<HTMLInputElement>(null);
    const ureaRef = useRef<HTMLInputElement>(null);
    const submitRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    const {t, tCommon} = useOurTranslation('apc/new');
    const {t: tOverview} = useOurTranslation('apc/overviewTable');

    const isUpdateCorrection = correctionType === 'UPDATE';
    const focusStableNumber = useCallback(() => {
        setTimeout(() => stableNumberRef.current?.focus());
    }, []);
    // eslint-disable-next-line max-lines-per-function
    useEffect(() => {
        // run after stableDateRelations fetch
        // eslint-disable-next-line max-lines-per-function
        stableDateRelations.onSome(relations => {
            if (relations.tag === 'ApcDateListWithStables') { // protocol exist
                if (editingCorrectionReport) {
                    pipe(relations.dateData, getDateOptions, setProficiencyTestDateOptions);
                }
                if (isUpdateCorrection) {
                    pipe(relations.stableData, getStableOptions, setStableOptions);
                    if (stableNumber) {
                        pipe(
                            stableNumber,
                            genProtocolDateOptionsBasedOnStable(relations),
                            setProficiencyTestDateOptions,
                        );
                    } else {
                        pipe(relations.dateData, getDateOptions, setProficiencyTestDateOptions);
                    }
                    setTimeout(() => proficiencyTestDateRef.current?.focus());
                } else if (proficiencyTestDateValue) {
                    pipe(proficiencyTestDateValue, genProtocolStableOptionsBasedOnDate(relations), setStableOptions);
                    setTimeout(() => proficiencyTestDateRef.current?.focus());
                }
            } else {
                if (stableNumber) {
                    pipe(
                        stableNumber,
                        genNonProtocolDateOptionsBasedOnStable(relations),
                        setProficiencyTestDateOptions,
                    );
                }
                focusStableNumber();
            }
            if (!editingCorrectionReport) proficiencyTestDateRef.current?.focus();
        });
    }, [setStableOptions, stableDateRelations]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (editingCorrectionReport) technicianNumberRef.current?.focus();
    }, [editingCorrectionReport, technicianNumberRef]);

    const {Form, Fields, submitting, asyncValidating, invalid, change} = useForm({
        form: apcReportFormName,
        initialValues: initialApcReportFormValues,
        validate,
        onSubmit,
    });

    const correctionTypeOptionsMemoized = useMemo(() => correctionTypeOptions(tOverview), [tOverview]);

    const asyncValidateDebounced = useMemo(() => debounce(debounceTime, asyncValidate), [asyncValidate]);

    const focusSubmit = useCallback(() => {
        setTimeout(() => submitRef.current?.focus());
    }, []);
    const focusProficiencyTestDate = useCallback(() => {
        setTimeout(() => proficiencyTestDateRef.current?.focus());
    }, []);

    const asyncValidateWithoutRelations = useCallback(() => {
        asyncValidateDebounced(focusSubmit);
    }, [asyncValidateDebounced, focusSubmit]);

    const onCorrectionTypeChange = useCallback((value: Opt<CorrectionType>) => {
        asyncValidateWithoutRelations();
        resetWithoutCorrectionTypeAndTechnicianNumber(value.orNull(), technicianNumberValue);
        dispatch(analysisProtocolCorrectionAction.clearStableAndDateData());
        setTimeout(() => technicianNumberRef.current?.focus());
    }, [
        asyncValidateWithoutRelations,
        resetWithoutCorrectionTypeAndTechnicianNumber,
        dispatch,
        technicianNumberValue,
        technicianNumberRef,
    ]);

    const onEarTagChange = useCallback(() => {
        setStableOptions([]);
        setProficiencyTestDateOptions([]);
        asyncValidateDebounced(focusProficiencyTestDate, true);
        change('milkingTripleAttribute', null);
        change('milkKg', null);
        change('fatPercentage', null);
        change('proteinPercentage', null);
        change('lactosePercentage', null);
        change('somaticCells', null);
        change('urea', null);
        change('proficiencyTestDate', null);
        change('stableNumber', null);
        dispatch(analysisProtocolCorrectionAction.clearStableAndDateData());
    }, [
        setStableOptions,
        setProficiencyTestDateOptions,
        asyncValidateDebounced,
        focusProficiencyTestDate,
        change,
        dispatch,
    ]);

    const {
        onStableNumberChange,
        onProficiencyTestDateChange,
    } = useDateAndStableChange({
        milkKgRef,
        isUpdateCorrection,
        proficiencyTestDateRef,
        stableNumberRef,
        earTagChangeRef,
        submitRef,
        stableDateRelations,
        earTagValue,
        stableNumber,
        getStableDateRelation,
        getAnalysisProtocol,
        setProficiencyTestDateOptions,
        correctionType,
        setStableOptions,
        clearAdditionalDataInProtocol,
        change,
    });

    const proficiencyTestDateDisabled = useCallback((): boolean => {
        if (proficiencyTestDateOptions.forAll(isEmpty)) return true;
        if (editingCorrectionReport) return false;
        if (isUpdateCorrection) return false;

        return !protocolExists(stableDateRelations);
    }, [editingCorrectionReport, isUpdateCorrection, proficiencyTestDateOptions, stableDateRelations]);

    const stableNumberDisabled =
       protocolExists(stableDateRelations)
       && correctionType !== 'UPDATE'
       && isEmpty(proficiencyTestDateValue) || stableOptions.forAll(isEmpty);

    const onTechnicianNumberChange = useCallback((value: Opt<string>) => {
        if (value.testReOrFalse(technicianNumberRegexGen())) {
            if (editingCorrectionReport) {
                if (stableNumberDisabled) {
                    setTimeout(() => proficiencyTestDateRef.current?.focus());
                } else {
                    setTimeout(() => stableNumberRef.current?.focus());
                }
            } else {
                setTimeout(() => earTagRef.current?.focus());
            }
        }
        // TODO temporarily disabled due to bad data in the database. Change for above
        //  Tag TECHNICIANS (https://redmine.favorlogic.com/issues/13226)
        // setTimeout(() => earTagRef.current?.focus());
    }, [earTagRef, editingCorrectionReport, stableNumberDisabled]);

    const submitDisabled = useMemo(() => submitting
        || invalid
        || Boolean(asyncValidating)
        || technicianOptionsLoading
        || stableDateOptionsLoading
        || analysisProtocolLoading
    , [
        analysisProtocolLoading,
        asyncValidating,
        stableDateOptionsLoading,
        submitting,
        technicianOptionsLoading,
        invalid,
    ]);

    useEffect(() => {
        if (!submitDisabled && correctionType === 'DISCARD' && technicianNumberValue) {
            setTimeout(() => submitRef.current?.focus());
        }
    }, [correctionType, submitDisabled, technicianNumberValue]);

    const onUpdatedEarTagChange = useCallback(() => {
        asyncValidateWithoutRelations();
    }, [asyncValidateWithoutRelations]);

    const focusNextInputIfValid = useCallback((
        currentField: keyof ApcReportFormValues,
        nextFieldRef: RefObject<HTMLInputElement>,
        inputType: NumberInputType,
        value: string,
        isSubmitRef?: boolean,
    ): void => {
        if (testRe(inputType === 'decimal52' ? decimal52Regex : decimal80Regex)(value)) {
            const numberValue = Number.parseFloat(replace(',', '.')(value));
            change(currentField, roundNumberToPrecision(numberValue, inputType));
            setTimeout(() => {
                nextFieldRef.current?.focus();
                if (!isSubmitRef) nextFieldRef.current?.select();
            });
        } else {
            dispatch(formHelpers.touch(apcReportFormName, currentField));
        }
    }, [change, dispatch]);

    const onMilkKgInput = useCallback((value: string) => {
        const isFatPercentageFieldDisabled = fatPercentageRef.current?.disabled;
        const nextFieldRef = isFatPercentageFieldDisabled ? submitRef : fatPercentageRef;

        focusNextInputIfValid('milkKg', nextFieldRef, 'decimal52', value, nextFieldRef === submitRef);
    }, [focusNextInputIfValid]);
    const onFatPercentageInput = useCallback((value: string) => {
        focusNextInputIfValid('fatPercentage', proteinPercentageRef, 'decimal52', value);
    }, [focusNextInputIfValid]);
    const onProteinPercentageInput = useCallback((value: string) => {
        focusNextInputIfValid('proteinPercentage', lactosePercentageRef, 'decimal52', value);
    }, [focusNextInputIfValid]);
    const onLactosePercentageInput = useCallback((value: string) => {
        focusNextInputIfValid('lactosePercentage', somaticCellsRef, 'decimal52', value);
    }, [focusNextInputIfValid]);
    const somaticCellMaxLength = 8;
    const onSomaticCellsInput = useCallback((value: string) => {
        if (testRe(positiveOrZeroDecimal80RegexGen)(value) && value.length === somaticCellMaxLength) {
            const numberValue = Number.parseFloat(replace(',', '.')(value));
            change('somaticCells', roundNumberToPrecision(numberValue, 'positiveOrZeroDecimal80'));
            setTimeout(() => {
                ureaRef.current?.focus();
                ureaRef.current?.select();
            });
        } else {
            dispatch(formHelpers.touch(apcReportFormName, 'somaticCells'));
        }
    }, [change, dispatch]);
    const onUreaInput = useCallback((value: string) => {
        focusNextInputIfValid('urea', submitRef, 'decimal52', value);
    }, [focusNextInputIfValid]);

    const analysisProtocol = useSelector(simpleAnalysisProtocolCorrectionSelector.analysisProtocol);
    const formValues = useSelector(formHelpers.formValues(apcReportFormName))
        .orElse(initialApcReportFormValues);
    const formState = useSelector(formHelpers.formState(apcReportFormName));

    const fieldsWithError = useSelector(formHelpers.getFieldsWithErrors(apcReportFormName));

    const isAnalysisProtocolFieldZeroOrNull = useCallback((field: keyof ApcReportFormValues): boolean => {
        const extractedField = formValues[field];

        return extractedField === 0 || extractedField === null;
    }, [formValues]);

    const hasValueAndNotTouched = useCallback((field: keyof ApcReportFormValues): boolean => {
        const active = formState.prop('fields').prop(field).prop('visited').orFalse();

        return !active && !isAnalysisProtocolFieldZeroOrNull(field);
    }, [formState, isAnalysisProtocolFieldZeroOrNull]);

    const isAdditionalFieldDisabled = useCallback((field: keyof ApcReportFormValues): boolean => {
        if (!isUpdateCorrection) return true;

        return hasValueAndNotTouched(field) && !fieldsWithError.exists(includes(field));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analysisProtocol, onProficiencyTestDateChange, isUpdateCorrection, fieldsWithError]);

    return {
        Form,
        Fields,
        correctionTypeOptions: correctionTypeOptionsMemoized,
        technicianNumberRef,
        earTagChangeRef,
        milkKgRef,
        proficiencyTestDateRef,
        stableNumberRef,
        tCommon,
        t,
        proficiencyTestDateDisabled,
        stableNumberDisabled,
        submitDisabled,
        fatPercentageRef,
        proteinPercentageRef,
        lactosePercentageRef,
        somaticCellsRef,
        ureaRef,
        submitRef,
        processingForm: asyncValidating === true || submitting,
        onTechnicianNumberChange,
        onEarTagChange,
        onUpdatedEarTagChange,
        onProficiencyTestDateChange,
        onStableNumberChange,
        isUpdateCorrection,
        onCorrectionTypeChange,
        onMilkKgInput,
        onFatPercentageInput,
        onProteinPercentageInput,
        onLactosePercentageInput,
        onSomaticCellsInput,
        onUreaInput,
        isAdditionalFieldDisabled,
    };
};
