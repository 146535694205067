import {convertAllErrorsFromBeToFormError, takeLatestF} from '@fl/cmsch-fe-library';
import {isEmpty, keys} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {earTagRegexGen} from 'api/gen/EarTag';
import {formHelpers} from 'utils/forms';

import {apcReportFormName} from '../../components/apc-report-form/form-values';
import {analysisProtocolCorrectionAction, ValidateCorrectionReportAction} from '../action';

// eslint-disable-next-line max-lines-per-function
function* execute({payload: {focus, getStableDateRelations}}: ValidateCorrectionReportAction): SagaIterator {
    const {
        earTag,
        correctionType,
        updatedEarTag,
    } = (yield* select(formHelpers.formValues(apcReportFormName))).orCrash('missing formValues');

    if (earTag && earTagRegexGen().test(earTag) && correctionType) {
        yield* put(formHelpers.startAsyncValidation(apcReportFormName));

        const earTagChangeRegexTest = correctionType === 'EAR_TAG_UPDATE'
            && updatedEarTag
            && earTagRegexGen().test(updatedEarTag);

        const response = yield* call(
            Api.validateCorrectionData,
            correctionType === 'DISCARD' || correctionType === 'UPDATE'
                ? {correctionType, earTag}
                : {correctionType, earTag, updatedEarTag: earTagChangeRegexTest ? updatedEarTag : earTag},
        );

        if (response.isSuccess) {
            yield* put(formHelpers.stopAsyncValidation(apcReportFormName, {}));
            if (getStableDateRelations) {
                yield* put(analysisProtocolCorrectionAction.getStableDateRelations(focus, earTag, correctionType));
            } else {
                focus();
            }
        } else {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            yield* put(formHelpers.stopAsyncValidation(apcReportFormName, errors.errors));

            const fieldsWithNewErrors = keys(errors.errors);
            if (!isEmpty(fieldsWithNewErrors)) {
                // TODO: improve types (use io-ts for validation?)
                // @ts-expect-error BE errors not typed for form fields
                yield* put(formHelpers.touch(apcReportFormName, ...fieldsWithNewErrors));
            }
        }
    }
}

export function* validateCorrectionReportSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/VALIDATE_CORRECTION_REPORT', execute);
}
