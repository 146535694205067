import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put, take} from 'typed-redux-saga';

import {AssociationType} from 'api/gen/AssociationType';
import {Sex} from 'api/gen/Sex';

import {matingAction} from '../action';

export function* updateAnimalSessionAfterSelection(sex: Sex, associationType: AssociationType): SagaIterator {
    if (sex === 'FEMALE') {
        yield* put(matingAction.getMatingCows(opt({action: 'refresh'})));
        yield* put(matingAction.getAnimalsCount('FEMALE', 'HOLSTEIN'));
        yield* take('mating/SET_FEMALE_ANIMAL_COUNT_IN_SESSION');
        yield* put(matingAction.setFemaleSessionState('selection'));
    } else {
        if (associationType === 'HOLSTEIN') {
            yield* put(matingAction.getHolsteinMatingBulls(opt({action: 'refresh'})));
            yield* put(matingAction.getAnimalsCount('MALE', 'HOLSTEIN'));
        } else {
            yield* put(matingAction.getFleckviehMatingBulls(opt({action: 'refresh'})));
            yield* put(matingAction.getAnimalsCount('MALE', 'FLECKVIEH'));
        }
        yield* put(matingAction.setMaleSessionState('selection'));
    }
}

export function* updateAnimalSessionAfterFilter(
    sex: Sex,
    associationType: AssociationType,
    isFull: boolean,
): SagaIterator {
    if (sex === 'FEMALE') {
        yield* put(matingAction.getMatingCows(opt({action: 'reset'})));
        yield* put(matingAction.setFemaleAnimalExistInSession(isFull));
        yield* put(matingAction.setFemaleSessionState('filter'));
    } else {
        yield* associationType === 'HOLSTEIN'
            ? put(matingAction.getHolsteinMatingBulls(opt({action: 'reset'})))
            : put(matingAction.getFleckviehMatingBulls(opt({action: 'reset'})));
        yield* put(matingAction.setMaleAnimalExistInSession(isFull));
        yield* put(matingAction.setMaleSessionState('filter'));
    }
}
