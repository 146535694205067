import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {SubjectFacilityFormValues} from '../../types/subject-facility-form-values';

const te = t('admin/users');

interface ValidatorProps {
    centersRequired: boolean;
    farmsRequired: boolean;
    isEdit: boolean;
}

export const createValidate = ({centersRequired, farmsRequired, isEdit}: ValidatorProps) =>
    (values: SubjectFacilityFormValues): Errors<SubjectFacilityFormValues> => {
        const validator = new EskotValidator(values);
        const approveIsInvalid = (
            values.approvedFrom !== null
            && values.approvedTo !== null
            && values.approvedFrom > values.approvedTo
        );

        validator.customRule(
            'approvedTo',
            approveIsInvalid,
            te('dateFromToIsInvalid'),
        );

        if (!isEdit) validator.nonEmpty('approvedFrom', te('approvedFrom'));
        validator.nonEmpty('subjectId', te('organization'));
        if (centersRequired) validator.nonEmpty('centerIds', te('centers'));
        if (farmsRequired) validator.nonEmpty('farmIds', te('farms'));

        validator.nonEmpty('roleIds', te('roles'));

        return validator.generateErrorsObject();
    };
