import * as Sentry from '@sentry/react';
import React, { PropsWithChildren, useCallback, FC } from 'react';
import { useSelector } from 'react-redux';
import { Config } from 'app/config';
import { Button } from 'common/buttons';
import { Card } from 'common/layout';
import { simpleSystemSelector } from './system/model/selector';
import styles from './styles.sass';
const ErrorScreen: FC = () => {
  const handleRestartClick = useCallback((): void => window.location.reload(), []);
  return <div className={styles.errorFallbackScreen} data-sentry-component="ErrorScreen" data-sentry-source-file="error-boundary.tsx">
            <div className={styles.errorFallbackScreenCard}>
                <Card data-sentry-element="Card" data-sentry-source-file="error-boundary.tsx">
                    <h2>Neočekávaná chyba</h2>
                    <p>
                        V aplikaci došlo k neočekávané chybě.<br />Problém byl zaznamenán do našeho systému<br />
                        a budeme se ho snažit co nejdříve vyřešit.<br />Omlouváme se za způsobené potíže.
                    </p>
                    <Button visuals="primary" onClick={handleRestartClick} data-sentry-element="Button" data-sentry-source-file="error-boundary.tsx">
                        Restartovat aplikaci
                    </Button>
                </Card>
            </div>
        </div>;
};
export const ErrorBoundary: FC<PropsWithChildren> = props => {
  const {
    children
  } = props;
  const serverError = useSelector(simpleSystemSelector.serverError);

  // Protects against running from saved html file on disk, which later breaks on CORS and in router
  if (window.location.protocol.includes('file')) {
    return <div className={styles.errorFallbackScreen}>
                <div className={styles.errorFallbackScreenCard}>
                    <Card>
                        <h2>Chyba připojení</h2>
                        <p>
                            Webovou aplikaci nelze spustit jako uložený soubor z disku.<br />
                            Prosím přejděte na online verzi na webové adrese:<br />
                            <a href={Config.url}>{Config.url}</a>
                        </p>
                        <Button visuals="primary" href={Config.url}>Přejít na eSkot.cz</Button>
                    </Card>
                </div>
            </div>;
  }
  return <Sentry.ErrorBoundary fallback={<ErrorScreen />} data-sentry-element="unknown" data-sentry-component="ErrorBoundary" data-sentry-source-file="error-boundary.tsx">
            {serverError ? <ErrorScreen /> : children}
        </Sentry.ErrorBoundary>;
};