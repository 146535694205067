import { FooterButtons, NullableKeys, Options } from '@fl/cmsch-fe-library';
import { isNull } from 'lodash/fp';
import React, { FC, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ContactType } from 'api/gen/ContactType';
import { useOurTranslation } from 'app/translations';
import { formHelpers, useForm } from '../../../../utils/forms';
import { CreateOrEditOrNull } from '../../types/create-or-edit-or-null';
import { userContactFormName, userContactInitialValues, UserContactFormValues } from '../../types/user-contact-form-values';
import { validate } from './validate';
interface Props {
  userProfileId: number;
  mode: CreateOrEditOrNull;
  data: UserContactFormValues | null;
  contactOptions: Options<ContactType>;
  onSubmit(): void;
  onCancel(): void;
  onSyncValidate(): void;
}
const ContactsFormBase: FC<Props> = ({
  onSubmit,
  onCancel,
  mode,
  userProfileId,
  contactOptions,
  onSyncValidate,
  data
}) => {
  const dispatch = useDispatch();
  const {
    Form,
    Fields,
    submitting,
    valid,
    pristine,
    asyncValidating
  } = useForm({
    form: userContactFormName,
    initialValues: userContactInitialValues,
    destroyOnUnmount: true,
    onSubmit,
    validate
  });
  const {
    t,
    tCommon
  } = useOurTranslation('userDetail');
  const isEdit = !isNull(data);

  // TODO (ondrej.krpec, 24. 6. 2024, TASK: 15071) Fix synchronous validation error when editing data in the form
  useEffect(() => {
    if (isEdit) {
      const initFormData: NullableKeys<UserContactFormValues> = {
        value: data.value,
        contactType: data.contactType,
        userProfileId,
        userContactId: data.userContactId
      };
      dispatch(formHelpers.initialize(userContactFormName, initFormData));
    } else {
      const initFormData: NullableKeys<UserContactFormValues> = {
        value: null,
        contactType: null,
        userProfileId,
        userContactId: null
      };
      dispatch(formHelpers.initialize(userContactFormName, initFormData));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Form data-sentry-element="Form" data-sentry-component="ContactsFormBase" data-sentry-source-file="index.tsx">
            <Fields.StringSelect name="contactType" label={t('contactType')} options={contactOptions} disabled={submitting || mode === 'edit'} onFieldChange={onSyncValidate} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.Input name="value" type="text" label={t('contactValue')} disabled={submitting} onFieldChange={onSyncValidate} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <FooterButtons submitting={submitting} valid={valid} pristine={pristine} onCancel={onCancel} asyncValidating={asyncValidating} submitTitle={mode === 'edit' ? tCommon('edit') : tCommon('add')} data-sentry-element="FooterButtons" data-sentry-source-file="index.tsx" />
        </Form>;
};
export const ContactsForm = memo(ContactsFormBase);