import {head, isFull, opt, optEmptyArray} from 'ts-opt';

import {OrganizationRole} from 'api/gen/OrganizationRole';
import {OrganizationRoleFacility} from 'api/gen/OrganizationRoleFacility';
import {t} from 'app/translations';

import {OrganizationTableType, CenterChildren, RolesChildren, FarmChildren} from '../../types/organization-table-type';

const getComputedCenter = (
    role: OrganizationRoleFacility,
    organizationCode: string,
    organizationName: string | null,
    organizationId: number,
): Array<CenterChildren> =>
    role.accessToAllOrganizationCenters
    || role.organizationCenters?.length === 1
    || role.organizationCenters === null
        ? []
        : role.organizationCenters.map(oc => ({
            id: oc.rowId,
            organizationCode,
            organizationId,
            organizationName,
            approvedFrom: role.approvedFrom,
            approvedTo: role.approvedTo,
            roleCode: role.roleCode,
            roleIds: [role.roleId],
            roleName: role.roleName,
            userProfileOrganizationRoleIds: [role.userProfileOrganizationRoleId],
            organizationCenterCode: oc.organizationCenterCode,
            organizationCenterId: oc.organizationCenterId,
            level: 3,
        }));

const getComputedFarms = (
    role: OrganizationRoleFacility,
    organizationCode: string,
    organizationName: string | null,
    organizationId: number,
): Array<FarmChildren> =>
    role.accessToAllFarms
    || role.farms?.length === 1
    || role.farms === null
        ? []
        : role.farms.map(f => ({
            id: f.rowId,
            organizationCode,
            organizationId,
            organizationName,
            approvedFrom: role.approvedFrom,
            approvedTo: role.approvedTo,
            roleCode: role.roleCode,
            roleIds: [role.roleId],
            roleName: role.roleName,
            userProfileOrganizationRoleIds: [role.userProfileOrganizationRoleId],
            farmCode: f.farmCode,
            farmId: f.farmId,
            level: 3,
        }));

const roleHasOneChild = (role: OrganizationRoleFacility | undefined): boolean =>
    opt(role).prop('organizationCenters').exists(xs => xs.length === 1)
    || opt(role).prop('accessToAllOrganizationCenters').orFalse();

const shouldBeOnlyOneRow = (roles: Array<OrganizationRoleFacility>): boolean =>
    roles.length === 1
    && roleHasOneChild(roles[0]);

const getOrganizationCenterCodeInRole = (role: OrganizationRoleFacility): string | null => {
    if (role.accessToAllOrganizationCenters && isFull(role.organizationCenters)) return t('admin/users')('allCenters');

    return role.organizationCenters?.length === 1
        ? opt(role).prop('organizationCenters').headIn().prop('organizationCenterCode').orNull()
        : null;
};

const getFarmCodeInRole = (role: OrganizationRoleFacility): string | null => {
    if (role.accessToAllFarms && isFull(role.farms)) return t('admin/users')('allFarms');

    return role.farms?.length === 1
        ? opt(role).prop('farms').headIn().prop('farmCode').orNull()
        : null;
};

// eslint-disable-next-line max-lines-per-function
export const rowMapper = ({
    roles,
    organizationCode,
    organizationName,
    rowId,
    organizationId,
}: OrganizationRole): OrganizationTableType => {
    const isSingleRow = shouldBeOnlyOneRow(roles);

    const getFirstLevelProp = <P extends keyof OrganizationRoleFacility>(prop: P): OrganizationRoleFacility[P] | null =>
        isSingleRow ? opt(roles).headIn().prop(prop).orNull() : null;

    return ({
        id: rowId,
        rowId,
        organizationCode,
        organizationName,
        organizationId,
        roles,
        children: isSingleRow
            ? undefined
            : roles.map((role: OrganizationRoleFacility): RolesChildren => ({
                id: role.rowId,
                organizationCode,
                organizationId,
                organizationName,
                roles: [role],
                accessToAllOrganizationCenters: role.accessToAllOrganizationCenters && isFull(role.organizationCenters),
                accessToAllFarms: role.accessToAllFarms && isFull(role.farms),
                approvedFrom: role.approvedFrom,
                approvedTo: role.approvedTo,
                roleCode: role.roleCode,
                roleIds: [role.roleId],
                roleName: role.roleName,
                userProfileOrganizationRoleIds: [role.userProfileOrganizationRoleId],
                organizationCenters: role.organizationCenters,
                farms: role.farms,
                farmCode: getFarmCodeInRole(role),
                farmIds: role.farms?.map(f => f.farmId) ?? [],
                children: optEmptyArray([
                    ...getComputedCenter(role, organizationCode, organizationName, organizationId),
                    ...getComputedFarms(role, organizationCode, organizationName, organizationId),
                ]).orUndef(),
                organizationCenterCode: getOrganizationCenterCodeInRole(role),
                organizationCenterIds: role.organizationCenters?.map(oc => oc.organizationCenterId) ?? [],
                level: 2,
            })),
        approvedFrom: getFirstLevelProp('approvedFrom'),
        approvedTo: getFirstLevelProp('approvedTo'),
        roleName: getFirstLevelProp('roleName'),
        roleCode: getFirstLevelProp('roleCode'),
        roleIds: roles.map(role => role.roleId),
        userProfileOrganizationRoleIds: roles.map(role => role.userProfileOrganizationRoleId),
        organizationCenterCode: opt(roles).filter(_ => isSingleRow).headIn().chain(role =>
            role.accessToAllOrganizationCenters && isFull(role.organizationCenters)
                ? opt(t('admin/users')('allCenters'))
                : head(role.organizationCenters).prop('organizationCenterCode'),
        ).orNull(),
        farmCode: opt(roles).filter(_ => isSingleRow).headIn().chain(role =>
            role.accessToAllFarms && isFull(role.farms)
                ? opt(t('admin/users')('allFarms'))
                : head(role.farms).prop('farmCode'),
        ).orNull(),
        level: 1,
        accessToAllOrganizationCenters: roles.every(role =>
            role.accessToAllOrganizationCenters
            && isFull(role.organizationCenters),
        ),
        accessToAllFarms: roles.every(role => role.accessToAllFarms && isFull(role.farms)),
        organizationCenterIds: roles.flatMap(
            role => role.organizationCenters?.map(oc => oc.organizationCenterId) ?? [],
        ),
        farmIds: roles.flatMap(
            role => role.farms?.map(f => f.farmId) ?? [],
        ),
        organizationCenters: isSingleRow ? roles.flatMap(r => r.organizationCenters ?? []) : null,
        farms: isSingleRow ? roles.flatMap(r => r.farms ?? []) : null,
    });
};
