import { Options } from '@fl/cmsch-fe-library';
import React, { memo, useMemo, useCallback, FC } from 'react';
import { Opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { Modal } from 'common/layout/components/modal';
import { getListName } from '../../utils/get-list-name';
import { AddAnimalsFromDBFormValues } from './add-animals-from-db-form-values';
import { AddAnimalsFromDbForm } from './form';
interface Props {
  visible: boolean;
  animalLists: Opt<Array<AnimalList>>;
  title: string;
  submitLabel: string;
  selectLabel: string;
  animalListsLoading: boolean;
  onSubmit(animalListId: number): void;
  onCancel(): void;
}
const ModalAddAnimalsFromDbBase: FC<Props> = props => {
  const {
    visible,
    animalLists,
    title,
    submitLabel,
    selectLabel,
    animalListsLoading,
    onSubmit,
    onCancel
  } = props;
  const listsOptions: Options<number> = useMemo(() => animalLists.mapIn(y => ({
    value: y.id,
    label: getListName(y)
  })).orElse([]), [animalLists]);
  const handleSubmit = useCallback(({
    animalListId
  }: AddAnimalsFromDBFormValues) => {
    if (animalListId) onSubmit(animalListId);
  }, [onSubmit]);
  return <Modal title={title} visible={visible} onCancel={onCancel} footer={null} data-sentry-element="Modal" data-sentry-component="ModalAddAnimalsFromDbBase" data-sentry-source-file="index.tsx">
            <AddAnimalsFromDbForm animalListsLoading={animalListsLoading} listsOptions={listsOptions} submitLabel={submitLabel} selectLabel={selectLabel} onCancel={onCancel} onSubmit={handleSubmit} data-sentry-element="AddAnimalsFromDbForm" data-sentry-source-file="index.tsx" />
        </Modal>;
};
export const ModalAddAnimalsFromDb = memo(ModalAddAnimalsFromDbBase);