// TODO temporarily disabled due to bad data in the database.
//  Tag TECHNICIANS (https://redmine.favorlogic.com/issues/13226)
// import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
// import {call, put} from 'typed-redux-saga';
//
// import {Api} from 'api/gen/Api';
// import {t} from 'app/translations';
//
// import {analysisProtocolCorrectionAction, GetTechnicianOptionsAction} from '../action';
//
// function* execute(_: GetTechnicianOptionsAction): SagaIterator {
//     yield* put(analysisProtocolCorrectionAction.setLoading('technicianOptionsLoading', true));
//     const response = yield* call(Api.getTechnicians);
//
//     if (response.isSuccess) {
//         yield* put(analysisProtocolCorrectionAction.getTechnicianOptionsSuccess(
//             (response.data.technicians || []).map(technician => ({
//                 label: technician,
//                 value: technician,
//             }))),
//         );
//     } else {
//         yield putAll(showBeError(response, t('apc/sagas')('technicianNumber')));
//     }
//
//     yield* put(analysisProtocolCorrectionAction.setLoading('technicianOptionsLoading', false));
// }

export function* getTechnicianOptionsSaga(): SagaIterator {
    // yield takeLatestF('analysisProtocolCorrection/GET_TECHNICIAN_OPTIONS', execute);
}
