import { Loader, LoaderWrapper, classNames } from '@fl/cmsch-fe-library';
import { isEmpty } from 'lodash/fp';
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssociationType } from 'api/gen/AssociationType';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { simpleTableSelector } from 'utils/tables';
import { matingSelector, matingAction } from '../../model';
import { MatingCowsFilterForm } from '../cows-filter-form';
import { MatingCowsTable } from '../cows-table';
import { MatingCowsModals } from './cows-modals';
import { useCowsTabState } from './cows-tab-state';
const FEMALE = 'FEMALE';
const associationType: AssociationType = 'HOLSTEIN';
export const CowSelectionTab: FC = _ => {
  const dispatch = useDispatch();
  const loading = useSelector(matingSelector.loading);
  const isCowsSessionEmpty = useSelector(matingSelector.isCowsSessionEmpty);
  const cowsSessionState = useSelector(matingSelector.cowsSessionState);
  const animalLists = useSelector(matingSelector.animalLists);
  const criteriaLists = useSelector(matingSelector.criteriaLists);
  const criteriaAccordions = useSelector(matingSelector.criteriaAccordions);
  const userSettings = useSelector(simpleTableSelector.userSettings('COW'));
  const cowsCriteriaAccordions = useMemo(() => criteriaAccordions.filterIn(x => x.bookmark === FEMALE), [criteriaAccordions]);
  const cowCriteriaDefinitions = useSelector(matingSelector.cowCriteriaDefinitions);
  const {
    tabLoading,
    formReloading
  } = useCowsTabState(loading);
  const hideTable = isCowsSessionEmpty && cowsSessionState !== 'selection' || tabLoading;
  useEffect(() => {
    dispatch(matingAction.getAnimalLists(FEMALE, associationType));
    dispatch(matingAction.getCriteriaLists(FEMALE, associationType));
  }, [dispatch]);
  const filterCows = useCallback(() => {
    dispatch(matingAction.filterAnimals(FEMALE, associationType));
  }, [dispatch]);
  const toggleCowsListsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleAnimalListsVisibility(FEMALE));
  }, [dispatch]);
  const toggleAddCowsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleAddAnimalsVisibility(FEMALE));
  }, [dispatch]);
  const toggleAddCowsFromDBVisibility = useCallback((): void => {
    dispatch(matingAction.toggleAddAnimalsFromDBVisibility(FEMALE));
  }, [dispatch]);
  const toggleCowsListsOverviewVisibility = useCallback((): void => {
    dispatch(matingAction.toggleListsOverviewVisibility(FEMALE));
  }, [dispatch]);
  const toggleCowsCriteriaListsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleCriteriaListsVisibility(FEMALE));
  }, [dispatch]);
  const toggleCowsCriteriaListDetailsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleCriteriaListDetailsVisibility(FEMALE));
  }, [dispatch]);
  const {
    t
  } = useOurTranslation('mating/matingCowsFilterForm');
  const {
    t: tTable
  } = useOurTranslation('tables/table');
  const {
    t: tLoader
  } = useOurTranslation('mating/modalAddAnimals');
  const table = useMemo(() => <MatingCowsTable loading={loading} />, [loading]);
  const tableNameLists = useMemo(() => userSettings?.map(x => x.name), [userSettings]);
  return <Fragment>
            {(tabLoading || formReloading) && <Loader size="large" show text={t('loadingMatingPlan')} spacing />}
            {!(tabLoading || formReloading) && <LoaderWrapper loading={loading.includes('loadingAnimalsFromFile')} text={tLoader('addingAnimalsFromFile')} size="large" background>
                    <MatingCowsFilterForm associationType={associationType} criteriaAccordions={cowsCriteriaAccordions} criteriaDefinitions={cowCriteriaDefinitions} animalListsEmpty={animalLists.forAll(isEmpty)} criteriaListsEmpty={criteriaLists.forAll(isEmpty)} isAnimalsPageEmpty={isCowsSessionEmpty} onSubmit={filterCows} toggleAnimalListsVisibility={toggleCowsListsVisibility} toggleAddAnimalsVisibility={toggleAddCowsVisibility} toggleAddAnimalsFromDBVisibility={toggleAddCowsFromDBVisibility} toggleListsOverviewVisibility={toggleCowsListsOverviewVisibility} toggleCriteriaListsVisibility={toggleCowsCriteriaListsVisibility} toggleCriteriaListDetailsVisibility={toggleCowsCriteriaListDetailsVisibility} />
                </LoaderWrapper>}
            {isCowsSessionEmpty && cowsSessionState === 'filter' && <div className="p-2"><Ant.Empty description={tTable('noRows')} /></div>}
            <div className={classNames(hideTable && 'd-none')}>{table}</div>
            <MatingCowsModals isAnimalsPageEmpty={isCowsSessionEmpty} tableSettingsNameList={tableNameLists || []} associationType={associationType} loading={loading} animalLists={animalLists} criteriaLists={criteriaLists} criteriaDefinitions={cowCriteriaDefinitions} toggleAnimalListsVisibility={toggleCowsListsVisibility} toggleAddAnimalsVisibility={toggleAddCowsVisibility} toggleAddAnimalsFromDBVisibility={toggleAddCowsFromDBVisibility} toggleListsOverviewVisibility={toggleCowsListsOverviewVisibility} toggleCriteriaListsVisibility={toggleCowsCriteriaListsVisibility} toggleCriteriaListDetailsVisibility={toggleCowsCriteriaListDetailsVisibility} data-sentry-element="MatingCowsModals" data-sentry-source-file="index.tsx" />
        </Fragment>;
};