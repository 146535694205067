import { TableDataRequest } from '@fl/cmsch-fe-library';
import React, { FC, memo, ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as routerActions from 'redux-first-history';
import { Opt } from 'ts-opt';
import { Milkability } from 'api/gen/Milkability';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { formHelpers } from 'utils/forms';
import { OverviewTable } from '../components';
import { initialMilkabilityFormValues, milkabilityReportFormName } from '../components/milkability-report-form/form-values';
import { milkabilityAction } from '../model';
const getHeader = (title: string): ReactNode => <ScreenHeader title={title} moduleForTutorials="MILKABILITY" data-sentry-element="ScreenHeader" data-sentry-component="getHeader" data-sentry-source-file="overview.tsx" />;
const OverviewBase: FC = _ => {
  const {
    t
  } = useOurTranslation('milkability/milkability');
  const dispatch = useDispatch();
  const getMilkabilityReports = useCallback((tableDataRequest: Opt<TableDataRequest<Milkability>>) => {
    dispatch(milkabilityAction.getMilkabilityReports(tableDataRequest));
  }, [dispatch]);
  const exportMilkabilityReports = useCallback(() => {
    dispatch(milkabilityAction.exportMilkabilityReports());
  }, [dispatch]);
  const deleteMilkabilityReport = useCallback((id: number) => {
    dispatch(milkabilityAction.deleteMilkabilityReport(id));
  }, [dispatch]);
  const goToNewReport = useCallback(() => {
    dispatch(formHelpers.initialize(milkabilityReportFormName, initialMilkabilityFormValues));
    dispatch(routerActions.push('/milkability/new'));
  }, [dispatch]);
  const editMilabilityReport = useCallback((id: number) => {
    dispatch(routerActions.push(`/milkability/${id}`));
  }, [dispatch]);
  return <Content browserTabTitle={t('milkabilityReportOverview')} data-sentry-element="Content" data-sentry-component="OverviewBase" data-sentry-source-file="overview.tsx">
            <Card title={getHeader(t('milkabilityReportOverview'))} titleSize="big" noPadding data-sentry-element="Card" data-sentry-source-file="overview.tsx">
                <OverviewTable getMilkabilityReports={getMilkabilityReports} exportMilkabilityReports={exportMilkabilityReports} deleteMilkabilityReport={deleteMilkabilityReport} goToNewReport={goToNewReport} editMilabilityReport={editMilabilityReport} data-sentry-element="OverviewTable" data-sentry-source-file="overview.tsx" />
            </Card>
        </Content>;
};
export const Overview = memo(OverviewBase);