import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put} from 'typed-redux-saga';

import {AnimalType} from 'api/gen/AnimalType';

import {animalBrowserAction} from '../action';

export function* updateAnimalSessionAfterAddition(animalType: AnimalType): SagaIterator {
    yield* put(animalBrowserAction.getAnimalsCount(animalType));

    if (animalType === 'BREEDING_FEMALES') {
        yield* put(animalBrowserAction.getCabFemales(opt({action: 'refresh'})));
    } else {
        yield* put(animalBrowserAction.getCabCalves(opt({action: 'refresh'})));
    }
}
