import React, { FC, memo, useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as routerActions from 'redux-first-history';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { LoginForm } from '../components';
import { simpleUserSelector, userAction } from '../model';
const LoginBase: FC = () => {
  const dispatch = useDispatch();
  const userName = useSelector(simpleUserSelector.currentUser).prop('username').orFalse();
  useLayoutEffect(() => {
    if (userName) dispatch(routerActions.push('/'));
  }, [dispatch, userName]);
  const logIn = useCallback(() => {
    dispatch(userAction.logIn());
  }, [dispatch]);
  const {
    t
  } = useOurTranslation('user/login');
  return <Content browserTabTitle="eSkot" noFooter flex="column" data-sentry-element="Content" data-sentry-component="LoginBase" data-sentry-source-file="login.tsx">
            <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                <h1>{t('loginHeading')}</h1>
                <LoginForm onSubmit={logIn} data-sentry-element="LoginForm" data-sentry-source-file="login.tsx" />
            </div>
        </Content>;
};
export const Login = memo(LoginBase);