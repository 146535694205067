import {DateStringNullable} from '@fl/cmsch-fe-library';
import {uniq} from 'lodash/fp';
import {filter, headIn, isEmpty, opt, orNull, pipe} from 'ts-opt';

import {Approved, ApprovedWithChildren} from '../types/approved';

const getApprovedValues = (key: keyof Approved, children: Array<ApprovedWithChildren>): Array<DateStringNullable> =>
    children.flatMap(x => [x[key], ...getApprovedValues(key, x.children ?? [])]);

export const getMatchingApprovedValues = <T extends ApprovedWithChildren>(data: T): Approved => {
    const {approvedFrom, approvedTo, children} = data;

    if (isEmpty(children)) return {approvedFrom, approvedTo};
    const sameValue = (key: keyof Approved): DateStringNullable => pipe(
        children ?? [],
        x => getApprovedValues(key, x),
        uniq,
        opt,
        filter((uniqValues: Array<DateStringNullable>) => uniqValues.length === 1),
        headIn,
        orNull,
    );

    return {
        approvedFrom: sameValue('approvedFrom'),
        approvedTo: sameValue('approvedTo'),
    };
};
