const controller = 'Admin';

export const adminPermissions = {
    users: `${controller}/Users`,
    roles: `${controller}/Roles`,
    permissions: `${controller}/Permissions`,
    appInfo: `${controller}/GetApplicationInfo`,
    deletePermissons: `${controller}/DeletePermission`,
    updatePermissions: `${controller}/UpdatePermission`,
    createPermissions: `${controller}/CreatePermission`,
    deleteRole: `${controller}/DeleteRole`,
    updateRole: `${controller}/UpdateRole`,
    createRole: `${controller}/CreateRole`,
    updateUser: `${controller}/UpdateUser`,

};

const flmExportController = 'FlmExport';
const reportController = 'Report';
export const legacyAdminPermissions = {
    bulkPrint: `${flmExportController}/TiskoveDavky`,
    operationalAssemblies: `${flmExportController}/ProvozniSestavy`,
    buildLogReport: `${reportController}/BuildLogReport`,
};
