import {TableColumn} from '@fl/cmsch-fe-library';

import {UsersTableType} from '../../types/users-table-type';

export const usersColumns: Array<TableColumn<UsersTableType>> = [
    {
        field: 'active',
        type: 'boolean',
    },
    {
        field: 'firstName',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'lastName',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'registrationEmail',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'aaGuid',
        type: 'string',
    },
    {
        field: 'username',
        type: 'string',
    },
];
