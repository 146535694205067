import {putAll, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {AssociationType} from 'api/gen/AssociationType';
import {Bookmark} from 'api/gen/Bookmark';
import {t} from 'app/translations';
import {TableId} from 'common/types/table-id';
import {showBeError} from 'utils/show-be-error';
import {getTableConfig} from 'utils/table-config';
import {simpleTableSelector, tablesAction} from 'utils/tables';

import {matingAction, RenameCriteriaListAction} from '../action';
import {getCriteriaListVersion} from './get-criteria-list-version';
import {getMatingTableId} from './get-mating-table-id';

interface DefaultGridSettingsType {
    tableId: TableId | null;
    gridSettingsId: number | null;
}

function* getDefaultGridSettings(
    bookmark: Bookmark,
    associationType: AssociationType,
): SagaIterator<DefaultGridSettingsType> {
    const tableId = getMatingTableId(bookmark, associationType);

    if (tableId) {
        const {serverUserSettingsIds} = getTableConfig(tableId) || {};
        const tableConfig = yield* select(simpleTableSelector.tableConfig(
            serverUserSettingsIds?.module,
            serverUserSettingsIds?.code,
        ));

        const gridSettingsId = tableConfig?.gridSettings?.find(x => x.isDefault)?.id;

        return {
            tableId,
            gridSettingsId: gridSettingsId ?? null,
        };
    }

    return {
        tableId,
        gridSettingsId: null,
    };
}

const te = t('mating/sagas');

// eslint-disable-next-line max-lines-per-function
function* execute({
    payload: {
        bookmark,
        id,
        name,
        associationType,
        gridUserSettingsId,
    },
}: RenameCriteriaListAction): SagaIterator {
    const version = yield* call(getCriteriaListVersion, id);
    const {gridSettingsId, tableId} = yield* call(getDefaultGridSettings, bookmark, associationType);

    const response = yield* call(Api.validateCriteriaList, {
        criteriaListId: id,
        name,
        gridUserSettingsId,
        gridSettingsId,
    }, {bookmark, associationType});

    const title = te('renameCriteriaList');

    if (response.isSuccess) {
        const storingResponse = yield* call(Api.renameCriteriaList, {name, version}, {criteriaListId: id});

        if (storingResponse.isSuccess) {
            yield* put(matingAction.getCriteriaLists(bookmark, associationType, true));
            if (gridUserSettingsId && tableId) {
                yield* put(tablesAction.getTableSettings(tableId, 'FORCE_LOAD'));
            }
            yield* put(showSuccess(title, te('criteriaListRenamed')));
        } else {
            yield putAll(showBeError(storingResponse, title));
        }
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* renameCriteriaListSaga(): SagaIterator {
    yield takeLatestF('mating/RENAME_CRITERIA_LIST', execute);
}
