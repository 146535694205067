import {TableDataRequest, useIdFromRouteOrNull} from '@fl/cmsch-fe-library';
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {UserDetail} from 'api/gen/UserDetail';
import {TFunction, useOurTranslation} from 'app/translations';
import {AssociationRoleTableType} from 'app/user-detail/types/association-role-table-type';
import {AssociationTabProps} from 'app/user-detail/types/associaton-tab-props';
import {BreederRoleTableType} from 'app/user-detail/types/breeder-role-table-type';
import {BreedersTabProps} from 'app/user-detail/types/breeders-tab-props';
import {ContactsTabProps} from 'app/user-detail/types/contacts-tab-props';
import {MemberOfSubjectsTabProps} from 'app/user-detail/types/member-of-subjects-tab-props';
import {MemberOfSubjectsTableType} from 'app/user-detail/types/member-of-subjects-table-type';
import {OrganizationTableType} from 'app/user-detail/types/organization-table-type';
import {OrganizationsTabProps} from 'app/user-detail/types/organizations-tab-props';
import {OtherSubjectRoleTableType} from 'app/user-detail/types/other-subject-role-table-type';
import {OtherSubjectsTabProps} from 'app/user-detail/types/other-subjects-tab-props';
import {UserContactsTableType} from 'app/user-detail/types/user-contacts-table-type';
import useCurrentUserHasAllPermissions from 'app/user/utils/use-current-user-has-all-permissions';

import {adminUserPathNameForRefresh} from '../constants/consts';
import {adminPermissions} from '../constants/permissions';
import {adminAction} from '../model';
import {simpleAdminSelector} from '../model/selector';

interface UseUserDetailSetup {
    user: Opt<UserDetail>;
    breedersProps: BreedersTabProps;
    associationsProps: AssociationTabProps;
    organizationsProps: OrganizationsTabProps;
    otherSubjectsProps: OtherSubjectsTabProps;
    memberOfSubjectsProps: MemberOfSubjectsTabProps;
    contactsProps: ContactsTabProps;
    tCommon: TFunction<'common'>;
    t: TFunction<'admin/users'>;
}

// eslint-disable-next-line max-lines-per-function
export const useUserDetailSetup = (): UseUserDetailSetup => {
    const dispatch = useDispatch();

    const userProfileId = useIdFromRouteOrNull();
    const canUpdate = useCurrentUserHasAllPermissions([adminPermissions.updateUser]);

    const userProfileIdIsNotValid = Boolean(userProfileId && isNaN(Number(userProfileId))) || userProfileId === null;

    if (userProfileIdIsNotValid) {
        throw new Error(`User detail id is not a number. ID: ${userProfileId ?? ''}`);
    }

    useEffect(() => {
        dispatch(adminAction.getUser(Number(userProfileId)));
    }, [dispatch, userProfileId]);

    useEffect(() => () => {
        dispatch(adminAction.clearUser());
    }, [dispatch]);

    const {t, tCommon} = useOurTranslation('admin/users');
    const user = useSelector(simpleAdminSelector.userDetail);

    const getBreedersTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<BreederRoleTableType>>): void => {
            if (userProfileId) dispatch(adminAction.getUserBreeders(tableDataRequest, userProfileId));
        },
        [dispatch, userProfileId],
    );

    const handleCreateBreeder = useCallback(() => {
        dispatch(adminAction.createBreeder());
    }, [dispatch]);

    const handleUpdateBreeder = useCallback(() => {
        dispatch(adminAction.updateBreeder());
    }, [dispatch]);

    const breedersProps: BreedersTabProps = useMemo(() => ({
        selector: simpleAdminSelector.userBreeders,
        getTableData: getBreedersTableData,
        pathnamesForRefresh: adminUserPathNameForRefresh,
        adminProps: {
            canUpdate,
            userProfileId,
            createEntry: handleCreateBreeder,
            updateEntry: handleUpdateBreeder,
        },
    }), [canUpdate, userProfileId, getBreedersTableData, handleCreateBreeder, handleUpdateBreeder]);

    const getAssociationsTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<AssociationRoleTableType>>): void => {
            dispatch(adminAction.getAssociations(tableDataRequest, userProfileId));
        },
        [dispatch, userProfileId],
    );

    const handleCreateAssociation = useCallback(() => {
        dispatch(adminAction.createAssociationRoles());
    }, [dispatch]);

    const handleUpdateAssociation = useCallback(() => {
        dispatch(adminAction.updateAssociationRoles());
    }, [dispatch]);

    const associationsProps: AssociationTabProps = useMemo(() => ({
        getTableData: getAssociationsTableData,
        selector: simpleAdminSelector.userAssociations,
        pathnamesForRefresh: adminUserPathNameForRefresh,
        adminProps: {
            canUpdate,
            userProfileId,
            createEntry: handleCreateAssociation,
            updateEntry: handleUpdateAssociation,
        },
    }), [canUpdate, userProfileId, getAssociationsTableData, handleCreateAssociation, handleUpdateAssociation]);

    const getOrganizationsTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<OrganizationTableType>>): void => {
            dispatch(adminAction.getOrganizations(tableDataRequest, userProfileId));
        },
        [dispatch, userProfileId],
    );

    const handleCreateOrganization = useCallback(() => {
        dispatch(adminAction.createOrganization());
    }, [dispatch]);

    const handleUpdateOrganization = useCallback(() => {
        dispatch(adminAction.updateOrganization());
    }, [dispatch]);

    const organizationsProps: OrganizationsTabProps = useMemo(() => ({
        getTableData: getOrganizationsTableData,
        selector: simpleAdminSelector.organizations,
        pathnamesForRefresh: adminUserPathNameForRefresh,
        adminProps: {
            canUpdate,
            userProfileId,
            createEntry: handleCreateOrganization,
            updateEntry: handleUpdateOrganization,
        },
    }), [canUpdate, userProfileId, getOrganizationsTableData, handleCreateOrganization, handleUpdateOrganization]);

    const getOtherSubjectsTableData = useCallback(
        (request: Opt<TableDataRequest<OtherSubjectRoleTableType>>): void => {
            dispatch(adminAction.getOtherSubjectRoles(request, userProfileId));
        },
        [dispatch, userProfileId],
    );

    const handleCreateOtherSubject = useCallback(() => {
        dispatch(adminAction.createOtherSubjects());
    }, [dispatch]);

    const handleUpdateOtherSubject = useCallback(() => {
        dispatch(adminAction.updateOtherSubjects());
    }, [dispatch]);

    const otherSubjectsProps: OtherSubjectsTabProps = useMemo(() => ({
        getTableData: getOtherSubjectsTableData,
        selector: simpleAdminSelector.userOtherSubjectRoles,
        pathnamesForRefresh: adminUserPathNameForRefresh,
        adminProps: {
            canUpdate,
            userProfileId,
            createEntry: handleCreateOtherSubject,
            updateEntry: handleUpdateOtherSubject,
        },
    }), [canUpdate, userProfileId, getOtherSubjectsTableData, handleCreateOtherSubject, handleUpdateOtherSubject]);

    const getMemberOfSubjectsTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<MemberOfSubjectsTableType>>): void => {
            dispatch(adminAction.getMemberOfSubjects(tableDataRequest, userProfileId));
        },
        [dispatch, userProfileId],
    );

    const handleCreateMemberOfSubject = useCallback(() => {
        dispatch(adminAction.createUserMembership());
    }, [dispatch]);

    const handleUpdateMemberOfSubject = useCallback(() => {
        dispatch(adminAction.updateUserMembership());
    }, [dispatch]);

    const memberOfSubjectsProps: MemberOfSubjectsTabProps = useMemo(() => ({
        getTableData: getMemberOfSubjectsTableData,
        selector: simpleAdminSelector.memberOfSubjectsPage,
        pathnamesForRefresh: adminUserPathNameForRefresh,
        adminProps: {
            canUpdate,
            userProfileId,
            createEntry: handleCreateMemberOfSubject,
            updateEntry: handleUpdateMemberOfSubject,
        },
    }), [
        canUpdate,
        userProfileId,
        getMemberOfSubjectsTableData,
        handleCreateMemberOfSubject,
        handleUpdateMemberOfSubject,
    ]);

    const getUserContactsTableData = useCallback(
        (tableDataRequest: Opt<TableDataRequest<UserContactsTableType>>): void => {
            dispatch(adminAction.getUserContacts(tableDataRequest, userProfileId));
        },
        [dispatch, userProfileId],
    );

    const handleCreateContact = useCallback(() => {
        dispatch(adminAction.createUserContact());
    }, [dispatch]);

    const handleUpdateContact = useCallback(() => {
        dispatch(adminAction.updateUserContact());
    }, [dispatch]);

    const handleDeleteContact = useCallback((rowId: number) => {
        dispatch(adminAction.deleteUserContact(userProfileId, rowId));
    }, [dispatch, userProfileId]);

    const contactsProps: ContactsTabProps = useMemo(() => ({
        getTableData: getUserContactsTableData,
        selector: simpleAdminSelector.userContacts,
        pathnamesForRefresh: adminUserPathNameForRefresh,
        adminProps: {
            canUpdate,
            userProfileId,
            createEntry: handleCreateContact,
            updateEntry: handleUpdateContact,
            deleteEntry: handleDeleteContact,
        },
    }), [
        canUpdate,
        userProfileId,
        getUserContactsTableData,
        handleCreateContact,
        handleUpdateContact,
        handleDeleteContact,
    ]);

    return {
        associationsProps,
        breedersProps,
        contactsProps,
        memberOfSubjectsProps,
        organizationsProps,
        otherSubjectsProps,
        user,
        t,
        tCommon,
    };
};
