import { ActionSettings, ToolbarButton, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { useMemo, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { AssociationType } from 'api/gen/AssociationType';
import { FemaleHolsteinAnimalInList } from 'api/gen/FemaleHolsteinAnimalInList';
import { useOurTranslation } from 'app/translations';
import { ConnectedTable } from 'utils/tables';
import { matingAction, matingSelector } from '../../model';
import { MatingLoadingId } from '../../types';
import { matingCowsColumns } from './mating-cows-columns';
const FEMALE = 'FEMALE';
const associationType: AssociationType = 'HOLSTEIN';
const pathnamesForRefresh: Array<RegExp> = [/^\/mating-h/];
interface Props {
  loading: Array<MatingLoadingId>;
}
const MatingCowsTableBase: React.FC<Props> = props => {
  const {
    loading
  } = props;
  const {
    t
  } = useOurTranslation('common');
  const dispatch = useDispatch();
  const isPageEmpty = useSelector(matingSelector.isCowsPageEmpty);
  const exportInProgress = loading.includes('exportInProgress');
  const removeCow = useCallback((id: number) => {
    dispatch(matingAction.removeAnimal(FEMALE, id, associationType));
  }, [dispatch]);
  const getMatingCows = useCallback((tableDataRequest: Opt<TableDataRequest<FemaleHolsteinAnimalInList>>): void => {
    dispatch(matingAction.getMatingCows(tableDataRequest));
  }, [dispatch]);
  const exportMatingCows = useCallback((): void => {
    dispatch(matingAction.exportMatingAnimals(FEMALE, associationType));
  }, [dispatch]);
  const actionSettings: ActionSettings<FemaleHolsteinAnimalInList> = useMemo(() => ({
    onRowRemove: removeCow
  }), [removeCow]);
  const headerButtons: Array<ToolbarButton> = useMemo(() => [{
    id: 'exportCows',
    title: t('downloadCsv'),
    onClick: exportMatingCows,
    loading: exportInProgress,
    disabled: isPageEmpty,
    role: 'export'
  }], [exportInProgress, exportMatingCows, isPageEmpty, t]);
  return useMemo(() => <ConnectedTable tableId="matingCows" columns={matingCowsColumns} onFetchTableData={getMatingCows} rowDataPageSelector={matingSelector.femaleAnimalsPage} actionSettings={actionSettings} withoutVerticalScrollbar showFulltextFilter paginationPosition="both" headerButtons={headerButtons} enableTableUserSettings pathnamesForRefresh={pathnamesForRefresh} />, [actionSettings, getMatingCows, headerButtons]);
};
export const MatingCowsTable = memo(MatingCowsTableBase);