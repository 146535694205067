export const userDetailTranslations = {
    CS: {
        'userDetail': {
            roles: 'Role',
            associations: 'Svazy',
            association: 'Svaz',
            authorizedOrganizations: 'Oprávněné organizace',
            organization: 'Organizace',
            centers: 'Střediska',
            breeders: 'Chovatelé',
            breederNumber: 'Registrační číslo chovatele',
            farms: 'Hospodářství',
            otherSubjects: 'Ostatní subjekty',
            memberOfSubjects: 'Člen subjektů',
            contactInformation: 'Kontaktní údaje',
            dateFromToIsInvalid: 'Schváleno do nesmí být dřív než Schváleno od.',
            editUserAsMemberOfSubject: 'Upravit člena subjektu',
            creteUserAsMemberOfSubject: 'Přidat člena subjektu',
            memberOfSubject: 'Člen subjektu',
            creteAssociationRole: 'Přidat role svazu',
            creteAuthorizedAssociationRole: 'Přidat role organizaci',
            editAuthorizedAssociationRole: 'Upravit role organizaci',
            createOtherSubjectRole: 'Přidat role subjektu',
            editAssociationRole: 'Upravit role svazu',
            editOtherSubjectRole: 'Upravit role subjektu',
            subjectsLoading: 'Načítání subjektů',
            roleLoading: 'Načítání rolí',
            associationsLoading: 'Načítání svazů',
            organizationsLoading: 'Načítání organizací',
            centersLoading: 'Načítání středisek',
            otherSubjectsLoading: 'Načítání ostatních subjektů',
            subject: 'Subjekt',
            approvedFrom: 'Schváleno od',
            approvedTo: 'Schváleno do',
            addContact: 'Přidat kontakt',
            contact: 'Kontakt',
            editContact: 'Upravit kontakt',
            contactType: 'Typ kontaktu',
            contactValue: 'Hodnota kontaktu',
            allCenters: 'Všechna střediska',
            allFarms: 'Všechna hospodářství',
            breedersOptionsLoading: 'Načtení chovatelů',
            farmOptionsLoading: 'Načtení hospodářství',
            createRoleToUser: 'Přidat roli chovateli',
            updateRoleToUser: 'Upravit roli chovateli',
            addBreeder: 'Chovatel',
            email: 'Registrační e-mail',
            cookieSettings: 'Nastavení cookies',
        },
        'userDetail/sagas': {
            loadAssociations: 'Načtení svazú',
            loadBreeders: 'Načtení chovatelů',
            loadOrganizations: 'Načtení svazú',
            loadOtherSubjects: 'Načtení ostatních subjektů',
            loadMemberOfSubjects: 'Načtení členů subjektů',
            loadUserContacts: 'Načtení kontaktů',
        },
    },
    EN: {
        'userDetail': {
            roles: 'Roles',
            associations: 'Associations',
            association: 'Association',
            authorizedOrganizations: 'Organizations',
            organization: 'Organization',
            centers: 'Centers',
            breeders: 'Breeders',
            breederNumber: 'Breeder registration number',
            farms: 'Farms',
            otherSubjects: 'Other subjects',
            memberOfSubjects: 'Member of subjects',
            contactInformation: 'Contact',
            addContact: 'Add contact',
            contact: 'Contact',
            editContact: 'Edit contact',
            dateFromToIsInvalid: 'Approved to date cannot be earlier than the approved from date.',
            editUserAsMemberOfSubject: 'Update subject member',
            creteUserAsMemberOfSubject: 'Add subject member',
            memberOfSubject: 'Subject member',
            creteAssociationRole: 'Create association role',
            creteAuthorizedAssociationRole: 'Create authorized association role',
            editAuthorizedAssociationRole: 'Edit authorized association role',
            editAssociationRole: 'Edit association role',
            createOtherSubjectRole: 'Create other subject role',
            editOtherSubjectRole: 'Edit other subject role',
            subjectsLoading: 'Loading of subjects',
            roleLoading: 'Loading of roles',
            associationsLoading: 'Loading of associations',
            organizationsLoading: 'Loading of organizations',
            centersLoading: 'Loading of centers',
            otherSubjectsLoading: 'Loading of other subjects',
            subject: 'Subject',
            approvedFrom: 'Approved from',
            approvedTo: 'Approved to',
            allCenters: 'All organization centers',
            contactType: 'Contact type',
            contactValue: 'Contact value',
            allFarms: 'All farms',
            breedersOptionsLoading: 'Loading breeders',
            farmOptionsLoading: 'Loading farms',
            createRoleToUser: 'Add role to breeder',
            updateRoleToUser: 'Edit role to breeder',
            addBreeder: 'Breeder',
            email: 'Registration e-mail',
            cookieSettings: 'Cookie settings',
        },
        'userDetail/sagas': {
            loadAssociations: 'Load associations',
            loadBreeders: 'Load breeders',
            loadOrganizations: 'Load organizations',
            loadOtherSubjects: 'Load other subjects',
            loadMemberOfSubjects: 'Load members of subjects',
            loadUserContacts: 'Load user contacts',
        },

    },
};
