import { ActionSettings, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Opt } from 'ts-opt';
import { BasicRole } from 'api/gen/BasicRole';
import { tCommon, useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { ConnectedTable } from 'utils/tables';
import { adminAction } from '../../model';
import { simpleAdminSelector } from '../../model/selector';
import { subordinateRoleTableColumns } from './columns';
interface Props {
  parentRoleId: number;
  canUpdateRole: boolean;
}
const SubordinateRoleUnassignedTableBase: FC<Props> = ({
  parentRoleId,
  canUpdateRole
}) => {
  const {
    t
  } = useOurTranslation('admin/roles');
  const dispatch = useDispatch();
  const onTableFetch = useCallback((tableDataRequest: Opt<TableDataRequest<BasicRole>>) => {
    dispatch(adminAction.getSubordinateUnassignedRoles(tableDataRequest, parentRoleId));
  }, [dispatch, parentRoleId]);
  const handleAddClick = useCallback((subordinateRoleId: number) => {
    dispatch(adminAction.changeSubordinateRole(parentRoleId, subordinateRoleId, 'add'));
  }, [dispatch, parentRoleId]);
  const actionSettings: ActionSettings<BasicRole> = useMemo(() => ({
    extraActions: [{
      id: 'addSubordinateRole',
      title: tCommon('add'),
      icon: 'plusSquareFilled',
      callback: handleAddClick,
      hidden: !canUpdateRole
    }]
  }), [canUpdateRole, handleAddClick]);
  return <Fragment>
            <div className="mx-2">
                <Ant.Typography.Title level={5} data-sentry-element="unknown" data-sentry-source-file="unassigned-table.tsx">
                    {t('subordinateUnassignedRoles')}
                </Ant.Typography.Title>
            </div>
            <ConnectedTable tableId="roleSubordinateUnassignedRoles" columns={subordinateRoleTableColumns} onFetchTableData={onTableFetch} rowDataPageSelector={simpleAdminSelector.subordinateUnassignedRolePage} actionSettings={actionSettings} withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter enableTableSettings enableTableUserSettings data-sentry-element="ConnectedTable" data-sentry-source-file="unassigned-table.tsx" />
        </Fragment>;
};
export const SubordinateRoleUnassignedTable = memo(SubordinateRoleUnassignedTableBase);