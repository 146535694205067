import {GTagClientAndSessionId} from 'app/gtag';

export interface GTagMessageForLegacyBe {
    type: 'gtagInit';
    payload: {
        gaClientId: string,
        gaSessionId: string,
    };
}

// 'gtagInit' type, 'gaClientId' and 'gaSessionId' are agreed GA field names with legacy BE .NET code
export const genGTagMessageForLegacyBe = (gTagIds: GTagClientAndSessionId): GTagMessageForLegacyBe => ({
    type: 'gtagInit',
    payload: {
        gaClientId: gTagIds.clientId,
        gaSessionId: gTagIds.sessionId,
    },
});
