import { ActionSettings, ToolbarButton, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { useCallback, useMemo, memo, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Opt } from 'ts-opt';
import { MaleHolsteinAnimalInList } from 'api/gen/MaleHolsteinAnimalInList';
import { useOurTranslation } from 'app/translations';
import { ConnectedTable } from 'utils/tables';
import { matingAction, matingSelector } from '../../model';
import { matingHolsteinBullsColumns } from './mating-holstein-bulls-columns';
const pathnamesForRefresh: Array<RegExp> = [/^\/mating-h/, /^\/bullselector-h/];
interface Props {
  exportInProgress: boolean;
  removeBull(animalId: number): void;
  exportBullselectorResult(): void;
}
const MatingBullsHolsteinTableBase: FC<Props> = ({
  exportInProgress,
  removeBull,
  exportBullselectorResult
}) => {
  const dispatch = useDispatch();
  const getMatingBulls = useCallback((tableDataRequest: Opt<TableDataRequest<MaleHolsteinAnimalInList>>) => {
    dispatch(matingAction.getHolsteinMatingBulls(tableDataRequest));
  }, [dispatch]);
  const {
    t
  } = useOurTranslation('common');
  const isPageEmpty = useSelector(matingSelector.isHolsteinPageEmpty);
  const actionSettings: ActionSettings<MaleHolsteinAnimalInList> = useMemo(() => ({
    onRowRemove: removeBull
  }), [removeBull]);
  const headerButtons: Array<ToolbarButton> = useMemo(() => [{
    id: 'exportCows',
    title: t('downloadCsv'),
    onClick: exportBullselectorResult,
    disabled: isPageEmpty,
    role: 'export',
    loading: exportInProgress
  }], [exportBullselectorResult, exportInProgress, isPageEmpty, t]);
  return <ConnectedTable tableId="matingHolsteinBulls" columns={matingHolsteinBullsColumns} onFetchTableData={getMatingBulls} rowDataPageSelector={matingSelector.maleHolsteinAnimalsPage} actionSettings={actionSettings} withoutVerticalScrollbar showFulltextFilter paginationPosition="both" headerButtons={headerButtons} enableTableUserSettings pathnamesForRefresh={pathnamesForRefresh} data-sentry-element="ConnectedTable" data-sentry-component="MatingBullsHolsteinTableBase" data-sentry-source-file="mating-bulls-holstein-table.tsx" />;
};
export const MatingBullsHolsteinTable = memo(MatingBullsHolsteinTableBase);