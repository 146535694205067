import { RefSelect, Options, useIdFromRouteOrNull } from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import React, { ReactNode, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as routerActions from 'redux-first-history';
import { Opt, pipe } from 'ts-opt';
import { ApcStableDate } from 'api/gen/ApcStableDate';
import { CorrectionReportDetail } from 'api/gen/CorrectionReportDetail';
import { CorrectionType } from 'api/gen/CorrectionType';
import { Date } from 'api/gen/Date';
import { EarTag } from 'api/gen/EarTag';
import { StableCode } from 'api/gen/StableCode';
import { TechnicianNumber } from 'api/gen/TechnicianNumber';
import { TFunction, useOurTranslation } from 'app/translations';
import { ScreenHeader } from 'common/layout';
import { formHelpers } from 'utils/forms';
import { replaceUrlWithoutRerender } from 'utils/replace-url-without-rerender';
import { apcReportFormName, ApcReportFormValues, initialApcReportFormValues } from '../components/apc-report-form/form-values';
import { analysisProtocolCorrectionAction, analysisProtocolCorrectionLoadingSelector, analysisProtocolLoadingSelector, simpleAnalysisProtocolCorrectionSelector, stableDateOptionsLoadingSelector, technicianOptionsLoadingSelector } from '../model';
import { convertCorrectionReportToFormValues } from '../utils/convert-correction-report-to-form-values';
interface UseNewApcReportScreenSetup {
  header: ReactNode;
  tCommon: TFunction<'common'>;
  correctionType: CorrectionType | undefined;
  proficiencyTestDateOptions: Opt<Options<Date>>;
  stableOptions: Opt<Options<string>>;
  stableDateRelations: Opt<ApcStableDate>;
  technicianOptions: Opt<Options<TechnicianNumber>>;
  technicianOptionsLoading: boolean;
  stableDateOptionsLoading: boolean;
  analysisProtocolLoading: boolean;
  stableNumber: StableCode | null;
  proficiencyTestDateValue: Date | null;
  earTagValue: EarTag | null;
  earTagRef: RefObject<HTMLInputElement>;
  technicianNumberRef: RefObject<HTMLInputElement>;
  correctionTypeRef: RefSelect;
  editingCorrectionReportId: number | undefined;
  createdCorrectionReports: Array<CorrectionReportDetail>;
  correctionReportDetailLoading: boolean;
  technicianNumberValue: string | null;
  setProficiencyTestDateOptions(options: Options<string>): void;
  goBackToList(): void;
  setStableOptions(options: Options<string>): void;
  getAnalysisProtocol(earTag: EarTag, proficiencyTestDate: Date): void;
  getStableDateRelation(stableCode: StableCode, proficiencyTestDate: Date): void;
  createCorrectionReport(values: ApcReportFormValues): void;
  editCreatedApcReport(row: CorrectionReportDetail): void;
  deleteCreatedApcReport(id: number, isCreatedCorrectionReport: boolean): void;
  resetCreatedApcReports(): void;
  asyncValidate(focusSubmit: () => void, getStableDateRelations?: boolean): void;
  onCancel(): void;
  resetWithoutCorrectionTypeAndTechnicianNumber(correctionType: CorrectionType | null, technicianNumber: string | null): void;
  clearAdditionalDataInProtocol(changedField: 'proficiencyTestDate' | 'stableNumber', value: string | null): void;
}
const formValuesO = O.optic<ApcReportFormValues>();
const getFormValuesWithCorrectionTypeAndTechnicianNumber = (correctionType: CorrectionType | null, technicianNumber: string | null): ApcReportFormValues => pipe(initialApcReportFormValues, O.set(formValuesO.prop('correctionType'))(correctionType), O.set(formValuesO.prop('technicianNumber'))(technicianNumber));
const getFormValuesWithoutProtocol = (formValues: ApcReportFormValues, changedField: 'proficiencyTestDate' | 'stableNumber', value: string | null): ApcReportFormValues => pipe(formValues, O.set(formValuesO.prop('milkingTripleAttribute'))(null), O.set(formValuesO.prop('milkKg'))(null), O.set(formValuesO.prop('fatPercentage'))(null), O.set(formValuesO.prop('proteinPercentage'))(null), O.set(formValuesO.prop('lactosePercentage'))(null), O.set(formValuesO.prop('somaticCells'))(null), O.set(formValuesO.prop('urea'))(null), O.set(formValuesO.prop(changedField))(value));

// eslint-disable-next-line max-lines-per-function
export const useNewApcReportScreenSetup = (): UseNewApcReportScreenSetup => {
  const {
    t,
    tCommon
  } = useOurTranslation('apc/new');
  const earTagRef = useRef<HTMLInputElement>(null);
  const technicianNumberRef = useRef<HTMLInputElement>(null);
  const correctionTypeRef: RefSelect = useRef(null);
  const focusTechnicianNumber = useCallback(() => {
    setTimeout(() => technicianNumberRef.current?.focus());
  }, []);
  const dispatch = useDispatch();
  const apcReportId = useIdFromRouteOrNull();
  useEffect(() => {
    // TODO temporarily disabled due to bad data in the database.
    //  Tag TECHNICIANS (https://redmine.favorlogic.com/issues/13226)
    // dispatch(analysisProtocolCorrectionAction.getTechnicianOptions());
    if (apcReportId) {
      dispatch(analysisProtocolCorrectionAction.getCorrectionReportDetail(focusTechnicianNumber, apcReportId));
    }
    return () => {
      dispatch(analysisProtocolCorrectionAction.resetFormData());
      dispatch(formHelpers.initialize(apcReportFormName, initialApcReportFormValues));
    };
  }, [apcReportId, dispatch, focusTechnicianNumber]);
  const header = useMemo(() => <ScreenHeader title={t('title')} />, [t]);
  const correctionType = useSelector(simpleAnalysisProtocolCorrectionSelector.correctionType);
  const proficiencyTestDateOptions = useSelector(simpleAnalysisProtocolCorrectionSelector.proficiencyTestDateOptions);
  const stableOptions = useSelector(simpleAnalysisProtocolCorrectionSelector.stableOptions);
  const stableDateRelations = useSelector(simpleAnalysisProtocolCorrectionSelector.stableDateRelations);
  const technicianOptions = useSelector(simpleAnalysisProtocolCorrectionSelector.technicianOptions);
  const technicianOptionsLoading = useSelector(technicianOptionsLoadingSelector);
  const stableDateOptionsLoading = useSelector(stableDateOptionsLoadingSelector);
  const analysisProtocolLoading = useSelector(analysisProtocolLoadingSelector);
  const formValues = useSelector(formHelpers.formValues(apcReportFormName)).orElse(initialApcReportFormValues);
  const {
    stableNumber,
    proficiencyTestDate: proficiencyTestDateValue,
    earTag: earTagValue,
    technicianNumber: technicianNumberValue
  } = formValues;
  const createdCorrectionReports = useSelector(simpleAnalysisProtocolCorrectionSelector.createdCorrectionReports);
  const editingCorrectionReportId = useSelector(simpleAnalysisProtocolCorrectionSelector.editingCorrectionReportId);
  const {
    correctionReportDetailLoading
  } = useSelector(analysisProtocolCorrectionLoadingSelector);
  const setProficiencyTestDateOptions = useCallback((options: Options<string>) => {
    dispatch(analysisProtocolCorrectionAction.setProficiencyTestDateOptions(options));
  }, [dispatch]);
  const setStableOptions = useCallback((options: Options<string>) => {
    dispatch(analysisProtocolCorrectionAction.setStableOptions(options));
  }, [dispatch]);
  const getAnalysisProtocol = useCallback((earTag: EarTag, proficiencyTestDate: Date) => {
    dispatch(analysisProtocolCorrectionAction.getAnalysisProtocol(earTag, proficiencyTestDate));
  }, [dispatch]);
  const getStableDateRelation = useCallback((stableCode: StableCode, proficiencyTestDate: Date) => {
    dispatch(analysisProtocolCorrectionAction.getStableDateRelation(stableCode, proficiencyTestDate));
  }, [dispatch]);
  const focusEarTag = useCallback(() => {
    setTimeout(() => earTagRef.current?.focus());
  }, []);
  const createCorrectionReport = useCallback((values: ApcReportFormValues) => {
    dispatch(analysisProtocolCorrectionAction.createCorrectionReport(values, focusEarTag, editingCorrectionReportId));
  }, [dispatch, focusEarTag, editingCorrectionReportId]);
  const editCreatedApcReport = useCallback((row: CorrectionReportDetail) => {
    dispatch(analysisProtocolCorrectionAction.setEditingCorrectionReportId(row.id));
    dispatch(analysisProtocolCorrectionAction.getStableDateRelations(focusTechnicianNumber, row.earTag, correctionType));
    dispatch(formHelpers.initialize(apcReportFormName, convertCorrectionReportToFormValues(row)));
  }, [correctionType, dispatch, focusTechnicianNumber]);
  const deleteCreatedApcReport = useCallback((id: number, isCreatedCorrectionReport: boolean) => {
    dispatch(analysisProtocolCorrectionAction.deleteCorrectionReport(id, isCreatedCorrectionReport));
  }, [dispatch]);
  const resetCreatedApcReports = useCallback(() => {
    dispatch(analysisProtocolCorrectionAction.resetCreatedApcReports());
  }, [dispatch]);
  const resetForm = useCallback((dontResetTechniciacOptions: boolean) => {
    dispatch(formHelpers.initialize(apcReportFormName, initialApcReportFormValues));
    dispatch(analysisProtocolCorrectionAction.resetFormData(dontResetTechniciacOptions));
  }, [dispatch]);
  const asyncValidate = useCallback((focusSubmit: () => void, getStableDateRelations?: boolean) => {
    dispatch(analysisProtocolCorrectionAction.validateCorrectionReport(focusSubmit, getStableDateRelations));
  }, [dispatch]);
  const goBackToList = useCallback(() => {
    dispatch(routerActions.push('/analysis-protocol-correction'));
    resetForm(false);
  }, [dispatch, resetForm]);
  const onCancel = useCallback(() => {
    resetForm(false);
    replaceUrlWithoutRerender('/analysis-protocol-correction/new');
    setTimeout(() => technicianNumberRef.current?.focus());
  }, [resetForm]);
  const resetWithoutCorrectionTypeAndTechnicianNumber = useCallback((cType: CorrectionType | null, technicianNumber: string | null) => {
    dispatch(formHelpers.initialize(apcReportFormName, getFormValuesWithCorrectionTypeAndTechnicianNumber(cType, technicianNumber)));
  }, [dispatch]);
  const clearAdditionalDataInProtocol = useCallback((changedField: 'proficiencyTestDate' | 'stableNumber', value: string | null) => {
    if (changedField === 'stableNumber') {
      dispatch(formHelpers.initialize(apcReportFormName, getFormValuesWithoutProtocol(formValues, changedField, value)));
    } else {
      dispatch(formHelpers.initialize(apcReportFormName, getFormValuesWithoutProtocol(formValues, changedField, value)));
    }
  }, [dispatch, formValues]);
  return {
    header,
    tCommon,
    correctionType,
    proficiencyTestDateOptions,
    stableOptions,
    stableDateRelations,
    technicianOptions,
    stableDateOptionsLoading,
    technicianOptionsLoading,
    analysisProtocolLoading,
    stableNumber,
    proficiencyTestDateValue,
    earTagValue,
    earTagRef,
    editingCorrectionReportId,
    createdCorrectionReports,
    technicianNumberValue,
    goBackToList,
    correctionReportDetailLoading,
    setProficiencyTestDateOptions,
    setStableOptions,
    createCorrectionReport,
    asyncValidate,
    deleteCreatedApcReport,
    editCreatedApcReport,
    resetCreatedApcReports,
    getAnalysisProtocol,
    getStableDateRelation,
    onCancel,
    correctionTypeRef,
    resetWithoutCorrectionTypeAndTechnicianNumber,
    clearAdditionalDataInProtocol,
    technicianNumberRef
  };
};