import { Loader, LoaderWrapper, classNames } from '@fl/cmsch-fe-library';
import { isEmpty } from 'lodash/fp';
import React, { Fragment, FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssociationType } from 'api/gen/AssociationType';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { simpleTableSelector } from 'utils/tables';
import { matingSelector, matingAction } from '../../model';
import { MatingBullsFilterForm } from '../bulls-filter-form';
import { MatingBullsTable } from '../bulls-table';
import { MatingBullsModals } from './bulls-modals';
import { useBullTabState } from './bulls-tab-state';
const MALE = 'MALE';
interface Props {
  loadingMsg: string;
  isMating?: boolean;
  associationType: AssociationType;
}
const BullSelectionTabBase: FC<Props> = ({
  associationType,
  loadingMsg,
  isMating
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(matingSelector.loading);
  const isBullsSessionEmpty = useSelector(matingSelector.isBullsSessionEmpty);
  const bullsSessionState = useSelector(matingSelector.bullsSessionState);
  const organizations = useSelector(matingSelector.organizations);
  const animalLists = useSelector(matingSelector.animalLists);
  const criteriaLists = useSelector(matingSelector.criteriaLists);
  const criteriaAccordions = useSelector(matingSelector.criteriaAccordions);
  const userSettings = useSelector(simpleTableSelector.userSettings('BULL'));
  const bullCriteriaDefinitions = useSelector(matingSelector.bullCriteriaDefinitions);
  const bullsCriteriaAccordions = useMemo(() => criteriaAccordions.filterIn(x => x.bookmark === MALE), [criteriaAccordions]);
  const {
    tabLoading,
    formReloading
  } = useBullTabState(associationType, loading, isMating);
  const hideTable = isBullsSessionEmpty && bullsSessionState !== 'selection' || tabLoading;
  useEffect(() => {
    dispatch(matingAction.getAnimalLists(MALE, associationType));
    dispatch(matingAction.getCriteriaLists(MALE, associationType));
  }, [dispatch, associationType]);
  const filterBulls = useCallback(() => {
    dispatch(matingAction.filterAnimals(MALE, associationType));
  }, [associationType, dispatch]);
  const toggleBullsListsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleAnimalListsVisibility(MALE));
  }, [dispatch]);
  const toggleBullsCriteriaListsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleCriteriaListsVisibility(MALE));
  }, [dispatch]);
  const toggleAddBullsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleAddAnimalsVisibility(MALE));
  }, [dispatch]);
  const toggleAddBullsFromDBVisibility = useCallback((): void => {
    dispatch(matingAction.toggleAddAnimalsFromDBVisibility(MALE));
  }, [dispatch]);
  const toggleBullsListsOverviewVisibility = useCallback((): void => {
    dispatch(matingAction.toggleListsOverviewVisibility(MALE));
  }, [dispatch]);
  const toggleBullsCriteriaListDetailsVisibility = useCallback((): void => {
    dispatch(matingAction.toggleCriteriaListDetailsVisibility(MALE));
  }, [dispatch]);
  const {
    t: tTable
  } = useOurTranslation('tables/table');
  const {
    t
  } = useOurTranslation('mating/modalAddAnimals');
  const table = useMemo(() => <MatingBullsTable associationType={associationType} loading={loading} />, [loading, associationType]);
  const tableNameLists = useMemo(() => userSettings?.map(x => x.name), [userSettings]);
  return <Fragment>
            {(tabLoading || formReloading) && <Loader size="large" show text={loadingMsg} spacing />}
            {!(tabLoading || formReloading) && <LoaderWrapper loading={loading.includes('loadingAnimalsFromFile')} text={t('addingAnimalsFromFile')} size="large" background>
                    <MatingBullsFilterForm associationType={associationType} organizations={organizations} criteriaAccordions={bullsCriteriaAccordions} criteriaDefinitions={bullCriteriaDefinitions} animalListsEmpty={animalLists.forAll(isEmpty)} criteriaListsEmpty={criteriaLists.forAll(isEmpty)} isAnimalsPageEmpty={isBullsSessionEmpty} organizationsLoading={loading.includes('organizations')} onSubmit={filterBulls} toggleAnimalListsVisibility={toggleBullsListsVisibility} toggleAddAnimalsVisibility={toggleAddBullsVisibility} toggleAddAnimalsFromDBVisibility={toggleAddBullsFromDBVisibility} toggleListsOverviewVisibility={toggleBullsListsOverviewVisibility} toggleCriteriaListsVisibility={toggleBullsCriteriaListsVisibility} toggleCriteriaListDetailsVisibility={toggleBullsCriteriaListDetailsVisibility} />
                </LoaderWrapper>}

            {isBullsSessionEmpty && bullsSessionState === 'filter' && <div className="p-2"><Ant.Empty description={tTable('noRows')} /></div>}
            <div className={classNames(hideTable && 'd-none')}>{table}</div>
            <MatingBullsModals isAnimalsPageEmpty={isBullsSessionEmpty} associationType={associationType} loading={loading} tableSettingsNameList={tableNameLists || []} animalLists={animalLists} criteriaLists={criteriaLists} criteriaDefinitions={bullCriteriaDefinitions} toggleAnimalListsVisibility={toggleBullsListsVisibility} toggleAddAnimalsVisibility={toggleAddBullsVisibility} toggleAddAnimalsFromDBVisibility={toggleAddBullsFromDBVisibility} toggleListsOverviewVisibility={toggleBullsListsOverviewVisibility} toggleCriteriaListsVisibility={toggleBullsCriteriaListsVisibility} toggleCriteriaListDetailsVisibility={toggleBullsCriteriaListDetailsVisibility} data-sentry-element="MatingBullsModals" data-sentry-source-file="index.tsx" />
        </Fragment>;
};
export const BullSelectionTab = memo(BullSelectionTabBase);