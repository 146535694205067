import { Loader } from '@fl/cmsch-fe-library';
import React, { FC, memo } from 'react';
import { Content } from 'app/content';
import { UserDetailWrapper } from 'app/user-detail/components/user-detail-wrapper';
import { useUserDetailSetup } from '../hooks/use-user-detail-setup';
const UserDetailBase: FC = _ => {
  const {
    associationsProps,
    breedersProps,
    contactsProps,
    memberOfSubjectsProps,
    organizationsProps,
    otherSubjectsProps,
    user,
    t,
    tCommon
  } = useUserDetailSetup();
  return user.caseOf(u => <Content browserTabTitle={t('pageTitle')}>
                <UserDetailWrapper user={u} breedersProps={breedersProps} associationsProps={associationsProps} contactsProps={contactsProps} memberOfSubjectsProps={memberOfSubjectsProps} organizationsProps={organizationsProps} otherSubjectsProps={otherSubjectsProps} isFromAdmin />
            </Content>, () => <Content browserTabTitle={tCommon('pageTitle')}>
                <Loader show size="large" text={tCommon('loading')} />
            </Content>);
};
export const UserDetail = memo(UserDetailBase);