import { TableDataRequest } from '@fl/cmsch-fe-library';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as routerActions from 'redux-first-history';
import { Opt } from 'ts-opt';
import { CorrectionReport } from 'api/gen/CorrectionReport';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { OverviewTable } from '../components';
import { analysisProtocolCorrectionAction } from '../model';
const OverviewBase: FC = () => {
  const {
    t
  } = useOurTranslation('apc/apc');
  const dispatch = useDispatch();
  const getCorrectionReports = useCallback((tableDataRequest: Opt<TableDataRequest<CorrectionReport>>) => {
    dispatch(analysisProtocolCorrectionAction.getCorrectionReports(tableDataRequest));
  }, [dispatch]);
  const deleteCorrectionReport = useCallback((id: number) => {
    dispatch(analysisProtocolCorrectionAction.deleteCorrectionReport(id, false));
  }, [dispatch]);
  const exportCorrectionReports = useCallback(() => {
    dispatch(analysisProtocolCorrectionAction.exportCorrectionReports());
  }, [dispatch]);
  const goToNewReport = useCallback(() => {
    dispatch(routerActions.push('/analysis-protocol-correction/new'));
  }, [dispatch]);
  const editCorrectionReport = useCallback((report: CorrectionReport) => {
    dispatch(routerActions.push(`/analysis-protocol-correction/${report.id}`));
  }, [dispatch]);
  const header = useMemo(() => <ScreenHeader title={t('title')} moduleForTutorials="APC" />, [t]);
  return <Content browserTabTitle={t('pageTitle')} data-sentry-element="Content" data-sentry-component="OverviewBase" data-sentry-source-file="overview.tsx">
            <Card title={header} titleSize="big" noPadding data-sentry-element="Card" data-sentry-source-file="overview.tsx">
                <OverviewTable getCorrectionReports={getCorrectionReports} deleteCorrectionReport={deleteCorrectionReport} exportCorrectionReports={exportCorrectionReports} editCorrectionReport={editCorrectionReport} goToNewReport={goToNewReport} data-sentry-element="OverviewTable" data-sentry-source-file="overview.tsx" />
            </Card>
        </Content>;
};
export const Overview = memo(OverviewBase);