import {convertAllErrorsFromBeToFormError, putAll, takeLatestF, validateSchema} from '@fl/cmsch-fe-library';
import {keys} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {isEmpty, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ValidateSubjectMember} from 'api/gen/ValidateSubjectMember';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {ValidateUserMembershipErrorsSchema} from '../../types/errors/validate-user-membership-errors';
import {userMemberSubjectFormName} from '../../types/user-member-form-values';
import {ValidateUserMembershipAction} from '../action';
import {simpleUserDetailSelector} from '../selector';

interface ApiTypes {
    approvedFrom: string | null;
    approvedTo: string | null;
    subjectId: number | null;
    subjectMemberId: number | null;
    userProfileId: number | null;
}

const getApiValues = ({
    approvedFrom,
    approvedTo,
    subjectMemberId,
    subjectId,
    userProfileId,
}: ApiTypes): ValidateSubjectMember => subjectId
    ? ({
        approvedFrom: opt(approvedFrom).orCrash('Users member of subjects form: approvedFrom not found'),
        approvedTo,
        tag: 'ValidateCreateSubjectMember',
        subjectId: opt(subjectId).orCrash('Users member of subjects form: subjectId not found'),
        userProfileId: opt(userProfileId).orCrash('Users member of subjects form: userProfileId not found'),
    })
    : ({
        approvedTo,
        tag: 'ValidateUpdateSubjectMember',
        subjectMemberId: opt(subjectMemberId).orCrash('Users member of subjects form: subjectMemberId not found'),
    });

// eslint-disable-next-line max-lines-per-function
function* execute(_: ValidateUserMembershipAction): SagaIterator {
    const {
        approvedFrom,
        approvedTo,
        subjectId,
        subjectMemberId,
        userProfileId,
    } = (
        yield* select(formHelpers.formValues(userMemberSubjectFormName))
    ).orCrash(`no ${userMemberSubjectFormName} form values`);

    const pristine = yield* select(formHelpers.isPristine(userMemberSubjectFormName));
    const subjectIdIsEmpty = isEmpty(subjectId);
    if (subjectIdIsEmpty) {
        yield* put(formHelpers.untouch(userMemberSubjectFormName, 'approvedFrom'));
        yield* put(formHelpers.untouch(userMemberSubjectFormName, 'approvedTo'));
    }

    if (
        pristine
        || subjectIdIsEmpty
        || userProfileId === null
        || approvedFrom === null
        || (approvedTo !== null && approvedFrom > approvedTo)
    ) return;

    yield* put(formHelpers.startAsyncValidation(userMemberSubjectFormName));

    const editIdExists = Boolean(yield* select(simpleUserDetailSelector.memberOfSubjectsEditingId));
    const newSubjectId = (editIdExists ? null : subjectId);

    const response = yield* call(
        Api.validateSubjectMember, getApiValues({
            approvedFrom, approvedTo, subjectMemberId, subjectId: newSubjectId, userProfileId,
        }),
    );

    if (!response.isSuccess) {
        if (response.isBadRequest) {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            const validErrors = validateSchema(ValidateUserMembershipErrorsSchema, 'FE')(errors.errors);
            yield* put(formHelpers.stopAsyncValidation(userMemberSubjectFormName, validErrors));
            const fieldsWithNewErrors = keys(validErrors) as Array<keyof typeof validErrors>;

            if (!isEmpty(fieldsWithNewErrors)) {
                yield* put(formHelpers.touch(userMemberSubjectFormName, ...fieldsWithNewErrors));
            }
        } else {
            yield putAll(showBeError(response, t('admin/sagas')('updateUserMembership')));
        }
    } else {
        yield* put(formHelpers.stopAsyncValidation(userMemberSubjectFormName, {}));
    }
}

export function* validateUserMembershipSaga(): SagaIterator {
    yield takeLatestF('userDetail/VALIDATE_USER_MEMBERSHIP', execute);
}
