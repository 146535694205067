import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {GetAvailableAnimalsAction, matingAction} from '../action';

function* execute({payload: {sex, label, associationType}}: GetAvailableAnimalsAction): SagaIterator {
    const response = yield* call(Api.getAvailableAnimals, {label}, {sex, associationType});

    if (response.isSuccess) {
        yield* put(matingAction.getAvailableAnimalsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('mating/sagas')('addAnimals')));
    }
    yield* put(matingAction.setLoading('getAvailableAnimals', false));
}

export function* getAvailableAnimalsSaga(): SagaIterator {
    yield takeLatestF('mating/GET_AVAILABLE_ANIMALS', execute);
}
