/* eslint-disable max-lines */
// todo split to multiple files

import {opt} from 'ts-opt';

import {Principal} from 'api/gen/Principal';
import {adminRoutePaths} from 'app/admin/routes/route-paths';
import {analysisProtocolCorrectionRoutePaths} from 'app/analysis-protocol-correction/routes/route-paths';
import {animalBrowserRoutePaths} from 'app/animal-browser/routes/route-paths';
import {matingRoutePaths} from 'app/mating/routes/route-paths';
import {milkabilityRoutePaths} from 'app/milkability/routes/route-paths';
import {userRoutePaths} from 'app/user/routes/route-paths';
import {getUserFullName} from 'app/user/utils/get-user-full-name';
import {IconName} from 'common/icons';
import {MenuItem} from 'common/layout/types/menu-item';

import {adminPermissions, legacyAdminPermissions} from './admin/constants/permissions';
import {apcPermissions} from './analysis-protocol-correction/constants/permissions';
import {
    basicAnimalBrowserPermissions,
    comprehensiveAnimalBrowserPermissions,
} from './animal-browser/constants/permissions';
import {
    legacyAaaSubjectPermissions,
    legacyBreedBookPermissions,
    legacyBullLinePermissions,
    legacyFileManagerPermissions,
    legacyFlmRequestsPermissions,
    legacySignsPermissions,
    legacyTagsPermissions,
} from './legacy/constants/permissions';
import {matingPermissions} from './mating/constants/permissions';
import {legacyMilkabilityPermissions, milkabilityPermissions} from './milkability/constants/permissions';
import {appRoutePaths} from './routes/route-paths';
import {TFunction, translations} from './translations';

type Key = keyof typeof translations.CS['common/menu'] | 'user' | 'home';

export interface NavigationItem {
    label: string; // menu button label
    key: Key; // key should be different across all nav items, Ant menu uses it to mark selected item
    path?: string; // path to screen on new FE
    isExternalLink?: boolean; // path outside of our FE
    openIn?: '_blank'; // if present, link will be opened in new tab
    icon?: IconName; // icon in menu
    subItems?: Array<NavigationItem>; // menu subitems
    pathsNotInMenu?: Array<string>; // menu subitems that are not in menu
    onlyForLoggedIn?: boolean; // if true, user must be logged in
    permissions?: Array<string>; // if present only logged user with given permission can see this item
    legacyPath?: string; // if this screen is not implemented in new FE this holds path to legacy BE
    exact?: boolean; // if true, item will only be active if the path is matched exactly
    pathFromFirstChild?: boolean; // if true, item will get path from its first visible child
}

export interface NavigationRestriction {
    path: string;
    permissions?: Array<string>;
    onlyForLoggedIn?: boolean;
}

export interface LegacyPath {
    path: string;
    legacyPath: string;
}

const nonNavigationItems: ReadonlyArray<NavigationRestriction> = [
    {
        path: analysisProtocolCorrectionRoutePaths.newReport,
        onlyForLoggedIn: true,
        permissions: [
            apcPermissions.updateTechnician,
            apcPermissions.updateLeaderTechnician,
            apcPermissions.updateAdmin,
        ],
    },
    {
        path: milkabilityRoutePaths.newReport,
        onlyForLoggedIn: true,
        permissions: [
            milkabilityPermissions.updateTechnician,
            milkabilityPermissions.updateLeaderTechnician,
            milkabilityPermissions.updateAdmin,
        ],
    },
];

/**
 * Site map extracted from legacy application, used to construct menu items depending on user.
 */
const appNavigationItems: ReadonlyArray<NavigationItem> = Object.freeze([
    {
        label: 'Administrace',
        key: 'administration',
        path: '/admin/users-new',
        icon: 'settingFilled',
        onlyForLoggedIn: true,
        subItems: [
            {
                label: 'Uživatelé',
                key: 'users',
                path: adminRoutePaths.users,
                icon: 'peopleIcon',
                permissions: [adminPermissions.users],
                legacyPath: adminRoutePaths.users,
                onlyForLoggedIn: true,
            },
            {
                label: 'role',
                key: 'roles',
                path: adminRoutePaths.roles,
                icon: 'roleIcon',
                legacyPath: adminRoutePaths.roles,
                permissions: [adminPermissions.roles],
                onlyForLoggedIn: true,
            },
            {
                label: 'Oprávnění',
                key: 'permissions',
                path: adminRoutePaths.permissions,
                icon: 'keyIcon',
                permissions: [adminPermissions.permissions],
                legacyPath: adminRoutePaths.permissions,
                onlyForLoggedIn: true,
            },
            {
                label: 'Tiskové dávky',
                key: 'bulkPrint',
                path: '/FlmExport/TiskoveDavky',
                icon: 'clusterOutlined',
                permissions: [legacyAdminPermissions.bulkPrint],
                legacyPath: '/FlmExport/TiskoveDavky',
                onlyForLoggedIn: true,
            },
            {
                label: 'Provozní sestavy',
                key: 'operationalAssemblies',
                path: '/FlmExport/ProvozniSestavy',
                icon: 'auditOutlined',
                permissions: [legacyAdminPermissions.operationalAssemblies],
                legacyPath: '/FlmExport/ProvozniSestavy',
                onlyForLoggedIn: true,
            },
            {
                label: 'Buildlog report',
                key: 'buildLogReport',
                path: '/Report/BuildLogReport',
                icon: 'areaChartOutlined',
                permissions: [legacyAdminPermissions.buildLogReport],
                legacyPath: '/Report/BuildLogReport',
                onlyForLoggedIn: true,
            },
            {
                label: 'Informace o eSkot',
                key: 'about',
                path: adminRoutePaths.appInfo,
                icon: 'infoCircleOutlined',
                permissions: [adminPermissions.appInfo],
                legacyPath: adminRoutePaths.appInfo,
                onlyForLoggedIn: true,
            },
        ],
    },
    {
        label: 'Hlášení',
        key: 'reports',
        icon: 'clipboardIcon',
        path: '',
        pathFromFirstChild: true,
        onlyForLoggedIn: true,
        subItems: [
            {
                label: 'Dojitelnost',
                key: 'milkability',
                icon: 'cowIcon',
                path: milkabilityRoutePaths.overview,
                permissions: [
                    milkabilityPermissions.listTechnician,
                    milkabilityPermissions.listLeaderTechnician,
                    milkabilityPermissions.listAdmin,
                ],
                onlyForLoggedIn: true,
            },
            {
                label: 'Oprava rozborového protokolu',
                key: 'analysisProtocolCorrection',
                icon: 'clipboardIcon',
                path: analysisProtocolCorrectionRoutePaths.overview,
                permissions: [
                    apcPermissions.listTechnician,
                    apcPermissions.listLeaderTechnician,
                    apcPermissions.listAdmin,
                ],
                onlyForLoggedIn: true,
            },
            {
                label: 'Reprodukce',
                key: 'reproductionReport',
                path: '/Reprodukce/SeznamDavekHlaseni',
                icon: 'clipboardIcon',
                permissions: [legacyMilkabilityPermissions.reportBatchList],
                legacyPath: '/Reprodukce/SeznamDavekHlaseni',
                onlyForLoggedIn: true,
            },
        ],
    },
    {
        label: 'SUPD',
        key: 'supd',
        path: '/FileManagerT/FileManagerUser',
        icon: 'dataIcon',
        onlyForLoggedIn: true,
        subItems: [
            {
                label: 'Úložiště souborů',
                key: 'fileManager',
                path: '/FileManagerT/FileManagerUser',
                icon: 'databaseOutlined',
                permissions: [legacyFileManagerPermissions.user],
                legacyPath: '/FileManagerT/FileManagerUser',
                onlyForLoggedIn: true,
            },
            {
                label: 'Požadavky na TDB',
                key: 'tbdRequest',
                path: '/FlmPozadavky/PozadavekSubjekt',
                icon: 'auditOutlined',
                permissions: [legacyFlmRequestsPermissions.request],
                legacyPath: '/FlmPozadavky/PozadavekSubjekt',
                onlyForLoggedIn: true,
            },
            {
                label: 'Schválit požadavky na TDB',
                key: 'tbdRequestApprove',
                path: '/FlmPozadavky/SchvalitPozadavek',
                icon: 'fileDoneOutlined',
                permissions: [legacyFlmRequestsPermissions.approveRequest],
                legacyPath: '/FlmPozadavky/SchvalitPozadavek',
                onlyForLoggedIn: true,
            },
            {
                label: 'Požadavky na sestavy',
                key: 'supdRequest',
                path: '/FlmPozadavky/SestavaSubjekt',
                icon: 'auditOutlined',
                permissions: [legacyFlmRequestsPermissions.report],
                legacyPath: '/FlmPozadavky/SestavaSubjekt',
                onlyForLoggedIn: true,
            },
            {
                label: 'Schválit požadavky sestavy',
                key: 'supdRequestApprove',
                path: '/FlmPozadavky/SchvalitSestava',
                icon: 'fileDoneOutlined',
                permissions: [legacyFlmRequestsPermissions.approveReport],
                legacyPath: '/FlmPozadavky/SchvalitSestava',
                onlyForLoggedIn: true,
            },
        ],
    },
    {
        label: 'Prohlížeč',
        key: 'browser',
        path: '/ProhlizecZvirat',
        icon: 'fileSearchOutlined',
        subItems: [
            {
                label: 'Prohlížeč plemenic',
                key: 'cowBrowser',
                path: animalBrowserRoutePaths.stablesBrowser,
                permissions: [
                    basicAnimalBrowserPermissions.superUser,
                    basicAnimalBrowserPermissions.breeder,
                    basicAnimalBrowserPermissions.authorizedOrganization,
                ],
                icon: 'cowIcon',
                pathsNotInMenu: ['/animal-browser/calf-browser', '/animal-browser/cow-browser'],
                onlyForLoggedIn: true,
            },
            {
                label: 'Komplexní seznam plemenic',
                key: 'comprehensiveBreedingFemaleBrowser',
                path: animalBrowserRoutePaths.comprehensiveBreedingFemaleBrowser,
                permissions: [
                    comprehensiveAnimalBrowserPermissions.superUser,
                    comprehensiveAnimalBrowserPermissions.breeder,
                    comprehensiveAnimalBrowserPermissions.authorizedOrganization,
                ],
                icon: 'cowIcon',
                onlyForLoggedIn: true,
            },
            {
                label: 'Komplexní seznam telat',
                key: 'comprehensiveCalfBrowser',
                path: animalBrowserRoutePaths.comprehensiveCalfBrowser,
                permissions: [
                    comprehensiveAnimalBrowserPermissions.superUser,
                    comprehensiveAnimalBrowserPermissions.breeder,
                    comprehensiveAnimalBrowserPermissions.authorizedOrganization,
                ],
                icon: 'cowIcon',
                onlyForLoggedIn: true,
            },
            {
                label: 'Vyhledání zvířete',
                key: 'animalSearch',
                path: '/ProhlizecZvirat',
                icon: 'animalSearchIcon',
                legacyPath: '/ProhlizecZvirat',
            },
            {
                label: 'Bullselector - H',
                key: 'bullselectorH',
                path: matingRoutePaths.bullSelectorH,
                icon: 'cowIcon',
                permissions: [basicAnimalBrowserPermissions.bullSelectorH],
                onlyForLoggedIn: true,
            },
            {
                label: 'Bullselector - C',
                key: 'bullselectorC',
                path: matingRoutePaths.bullSelectorC,
                icon: 'cowIcon',
                permissions: [basicAnimalBrowserPermissions.bullSelectorC],
                onlyForLoggedIn: true,
            },
            {
                label: 'Sestavy býků',
                key: 'bullsReport',
                icon: 'bullIcon',
                subItems: [
                    {
                        label: 'České strakaté',
                        key: 'czechBulls',
                        icon: 'bullIcon',
                        path: '/ProhlizecZvirat/SestavyZvirat/bykC',
                        legacyPath: '/ProhlizecZvirat/SestavyZvirat/bykC',
                        exact: true,
                    },
                    {
                        label: 'Holštýnské',
                        key: 'holsteinBulls',
                        icon: 'bullIcon',
                        path: '/ProhlizecZvirat/SestavyZvirat/bykH',
                        legacyPath: '/ProhlizecZvirat/SestavyZvirat/bykH',
                        exact: true,
                    },
                ],
            },
            {
                label: 'Sestavy krav',
                key: 'cowsReport',
                path: 'cowsreport',
                icon: 'cowIcon',
                subItems: [
                    {
                        label: 'České strakaté',
                        key: 'czechCows',
                        icon: 'cowIcon',
                        path: '/ProhlizecZvirat/SestavyZvirat/kravaC',
                        legacyPath: '/ProhlizecZvirat/SestavyZvirat/kravaC',
                        exact: true,
                    },
                    {
                        label: 'Holštýnské',
                        key: 'holsteinCows',
                        icon: 'cowIcon',
                        path: '/ProhlizecZvirat/SestavyZvirat/kravaH',
                        legacyPath: '/ProhlizecZvirat/SestavyZvirat/kravaH',
                        exact: true,
                    },
                ],
            },
        ],
    },
    {
        label: 'Jmenovky',
        key: 'enums',
        path: '/Jmenovky/InseminacniTechnici',
        icon: 'tagsOutlined',
        onlyForLoggedIn: true,
        subItems: [
            {
                label: 'Inseminační technici',
                key: 'inseminationTechnicians',
                path: '/Jmenovky/InseminacniTechnici',
                icon: 'techniciansIcon',
                permissions: [legacyTagsPermissions.inseminationTechnicians],
                legacyPath: '/Jmenovky/InseminacniTechnici',
                onlyForLoggedIn: true,
            },
            {
                label: 'Plemenné knihy',
                key: 'breedBooksEnums',
                path: '/PlemennaKniha/JmenovkaPK',
                icon: 'booksIcon',
                permissions: [legacyBreedBookPermissions.tagPK],
                legacyPath: '/PlemennaKniha/JmenovkaPK',
                onlyForLoggedIn: true,
            },
            {
                label: 'Subjekty',
                key: 'subjects',
                path: '/AaaSubjekt/SeznamSubjektu',
                icon: 'companyIcon',
                permissions: [legacyAaaSubjectPermissions.listSubjects],
                legacyPath: '/AaaSubjekt/SeznamSubjektu',
                onlyForLoggedIn: true,
            },
            {
                label: 'Linie býka',
                key: 'bullLine',
                path: '/JmeLinieByka/LinieByka',
                icon: 'bullLineIcon',
                permissions: [legacyBullLinePermissions.bullLine],
                legacyPath: '/JmeLinieByka/LinieByka',
                onlyForLoggedIn: true,
            },
            {
                label: 'Číselník znaků',
                key: 'signsEnum',
                path: '/Znaky/Znaky',
                icon: 'orderedListOutlined',
                permissions: [legacySignsPermissions.signs],
                legacyPath: '/Znaky/Znaky',
                onlyForLoggedIn: true,
            },
        ],
    },
    {
        label: 'Plemenné knihy',
        key: 'breedBooks',
        path: '/PlemennaKniha/SeznamZvirat',
        icon: 'readOutlined',
        onlyForLoggedIn: true,
        subItems: [
            {
                label: 'Seznam zvířat',
                key: 'animalList',
                path: '/PlemennaKniha/SeznamZvirat',
                icon: 'animalListIcon',
                permissions: [legacyBreedBookPermissions.animalList],
                legacyPath: '/PlemennaKniha/SeznamZvirat',
                onlyForLoggedIn: true,
            },
            {
                label: 'Hromadné generování sestav',
                key: 'bulkAssemblyGeneration',
                path: '/PlemennaKniha/GenerovaniSestav',
                icon: 'translationOutlined',
                permissions: [legacyBreedBookPermissions.bulkAssemblyGeneration],
                legacyPath: '/PlemennaKniha/GenerovaniSestav',
                onlyForLoggedIn: true,
            },
            {
                label: 'Přečíslování zvířete',
                key: 'animalRenumber',
                path: '/PlemennaKniha/PrecislovaniZvirete',
                icon: 'changeNumbers',
                permissions: [legacyBreedBookPermissions.animalRenumber],
                legacyPath: '/PlemennaKniha/PrecislovaniZvirete',
                onlyForLoggedIn: true,
            },
            {
                label: 'Kombinace ET',
                key: 'etCombination',
                path: '/PlemennaKniha/KombinaceET',
                icon: 'fileE',
                permissions: [legacyBreedBookPermissions.etCombination],
                legacyPath: '/PlemennaKniha/KombinaceET',
                onlyForLoggedIn: true,
            },
            {
                label: 'Historie zvířete',
                key: 'animalHistory',
                path: '/PlemennaKniha/HistorieZvirete',
                icon: 'switcherFilled',
                permissions: [legacyBreedBookPermissions.animalHistory],
                legacyPath: '/PlemennaKniha/HistorieZvirete',
                onlyForLoggedIn: true,
            },
            {
                label: 'Změna pohlaví',
                key: 'genderChange',
                path: '/PlemennaKniha/ZmenaPohlavi',
                icon: 'swapOutlined',
                permissions: [legacyBreedBookPermissions.genderChange],
                legacyPath: '/PlemennaKniha/ZmenaPohlavi',
                onlyForLoggedIn: true,
            },
            {
                label: 'Plemeno hra',
                key: 'breedingGame',
                path: '/PlemennaKniha/PlemenoHra',
                icon: 'slidersOutlined',
                permissions: [legacyBreedBookPermissions.breedingGame],
                legacyPath: '/PlemennaKniha/PlemenoHra',
                onlyForLoggedIn: true,
            },
        ],
    },
    {
        label: 'Mating',
        key: 'mating',
        path: matingRoutePaths.mating,
        icon: 'bullIcon',
        onlyForLoggedIn: true,
        subItems: [
            {
                label: 'Připařovací plán',
                key: 'calculation',
                icon: 'cowIcon',
                path: matingRoutePaths.mating,
                permissions: [matingPermissions.mating],
                onlyForLoggedIn: true,
            },
        ],
    },
    {
        label: 'Reporty',
        key: 'reporting',
        path: 'https://reporting.eskot.cz/?utm_source=eskot.cz&amp;utm_medium=menu&amp;utm_campaign=verejny_report',
        isExternalLink: true,
        openIn: '_blank',
        icon: 'dataIcon',
    },
]);

// eslint-disable-next-line max-lines-per-function
const getLoggedUserNavigationItems = (userName: string): ReadonlyArray<NavigationItem> => [
    {
        label: userName,
        key: 'user',
        path: '/account/profile',
        icon: 'userIcon',
        subItems: [
            {
                label: 'Změnit heslo',
                key: 'password',
                path: '/Account/ChangePassword',
                onlyForLoggedIn: true,
                icon: 'keyIcon',
                legacyPath: '/Account/ChangePassword',
            },
            {
                label: 'Profil uživatele',
                key: 'profile',
                path: userRoutePaths.profile,
                onlyForLoggedIn: true,
                icon: 'toolFilled',
            },
            {
                label: 'Odhlásit',
                key: 'logout',
                path: userRoutePaths.logout,
                icon: 'logOutOutlined',
                onlyForLoggedIn: true,
            },
        ],
    },
];

const anonymousUserNavigationItems: ReadonlyArray<NavigationItem> = Object.freeze([
    {
        label: 'Přihlásit',
        key: 'login',
        path: userRoutePaths.login,
        icon: 'logOutOutlined',
    },
]);

/**
 * Should hold all possible navigation items together.
 */
const allNavigationItems: ReadonlyArray<NavigationItem> = Object.freeze([
    {
        label: 'home_placeholder',
        key: 'home',
        path: appRoutePaths.home,
        legacyPath: '/',
    },
    ...appNavigationItems,
    ...getLoggedUserNavigationItems(''),
    ...anonymousUserNavigationItems,
]);

const userHasPermission = (permissions: Array<string>, userPermissions?: Array<string>): boolean =>
    // eslint-disable-next-line @fl/use-eta-reduction
    userPermissions?.some(p => permissions.includes(p)) || false;

const shouldItemRender = (
    permissions?: Array<string>,
    userPermissions?: Array<string>,
): boolean => (!permissions || userHasPermission(permissions, userPermissions));

const getPathFromFirstChild = (
    subItems?: Array<NavigationItem>,
    userPermissions?: Array<string>,
): string | undefined =>
    subItems?.filter(subItem => shouldItemRender(subItem.permissions, userPermissions))[0]?.path;

const prepareMenuItem = (
    navItem: NavigationItem,
    t: TFunction<'common/menu'>,
    userPermissions?: Array<string>,
): MenuItem => {
    const {
        path,
        label,
        key,
        icon,
        exact,
        pathFromFirstChild,
        subItems,
        pathsNotInMenu,
        isExternalLink,
        openIn,
    } = navItem;
    const translatedLabel = key === 'user' || key === 'home' ? label : t(key);

    const firstChildPath = pathFromFirstChild && getPathFromFirstChild(subItems, userPermissions);

    return {
        label: translatedLabel,
        key,
        path: firstChildPath || path,
        openIn,
        isExternalLink,
        exactPath: exact,
        icon,
        pathsNotInMenu,
    };
};

/**
 * Creates menu items list from navigation items, depending on currently logged user and his permissions.
 * If menu item doesn't have path and has only one subitem, the subitem replaces its position (gets squashed).
 * @param t translations function
 * @param user currently logged in user
 * @param userPermissions currently logged in user's permissions
 */
// eslint-disable-next-line max-lines-per-function
export function genMenuItems(
    t: TFunction<'common/menu'>,
    user?: Principal,
    userPermissions?: Array<string>,
): Array<MenuItem> {
    const extractMenuItems = (navItems: ReadonlyArray<NavigationItem>): Array<MenuItem> => {
        const result: Array<MenuItem> = [];

        for (const navItem of navItems) {
            const {path, subItems, permissions} = navItem;
            const subMenuItems = subItems ? extractMenuItems(subItems) : [];

            if (shouldItemRender(permissions, userPermissions)) {
                if (path !== undefined || subMenuItems.length > 1) {
                    if (!subItems || subItems.some(
                        item => shouldItemRender(item.permissions, userPermissions),
                    )) {
                        const menuItem = prepareMenuItem(navItem, t, userPermissions);

                        menuItem.subMenuItems = subMenuItems;
                        result.push(menuItem);
                    }
                } else if (subMenuItems[0]) {
                    result.push(subMenuItems[0]);
                }
            }
        }

        return result;
    };

    return extractMenuItems([
        ...appNavigationItems,
        ...user
            ? getLoggedUserNavigationItems(getUserFullName(user))
            : anonymousUserNavigationItems,
    ]);
}

/**
 * Generates path to permission map for blocking user from accessing a screen if he doesn't have permission for it.
 */
export function genRestrictionPairs(
    navItems: ReadonlyArray<NavigationItem>,
): Array<NavigationRestriction> {
    const result: Array<NavigationRestriction> = [];

    const putRestrictionPairsToResult = (items: ReadonlyArray<NavigationItem>): void =>
        items.forEach(item => {
            const {permissions, onlyForLoggedIn, subItems} = item;
            if (permissions || onlyForLoggedIn) {
                const path = opt(item.path).orCrash(`navItem: ${JSON.stringify(item)} has no path`);
                result.push({path, permissions, onlyForLoggedIn});
            }
            if (subItems) {
                putRestrictionPairsToResult(subItems);
            }
        });
    putRestrictionPairsToResult(navItems);

    return result;
}

/**
 * Creates pairs of new FE paths and old legacy paths for screens that are served from old BE via IFRAME.
 */
export function genLegacyPathPairs(
    navItems: ReadonlyArray<NavigationItem>,
): Array<LegacyPath> {
    const result: Array<LegacyPath> = [];

    const putLegacyPathPairsToResult = (items: ReadonlyArray<NavigationItem>): void =>
        items.forEach(item => {
            opt(item.legacyPath).onSome(legacyPath => {
                const path = opt(item.path).orCrash(`${legacyPath} has no path`);
                result.push({legacyPath, path});
            });
            opt(item.subItems).onSome(putLegacyPathPairsToResult);
        });
    putLegacyPathPairsToResult(navItems);

    return result;
}

export const pathRestrictions: Array<NavigationRestriction> = [
    ...genRestrictionPairs(allNavigationItems),
    ...nonNavigationItems,
];

const getSubitemsPaths = (navItem: NavigationItem): Array<string> => {
    if (navItem.subItems) return navItem.subItems.flatMap(getSubitemsPaths);
    if (navItem.onlyForLoggedIn) return opt(navItem.path).toArray();

    return [];
};
export const restrictedPaths = allNavigationItems.flatMap(getSubitemsPaths);

export const legacyPaths: Array<LegacyPath> = genLegacyPathPairs(allNavigationItems);
