import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    tablesAction,
    getRequestedTablePageParams,
    simpleTableSelector,
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
} from 'utils/tables';

import {adminAction, GetUsersAction} from '../action';

function* execute({payload: {tableDataRequest}}: GetUsersAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(adminAction.getUsersSuccess(none));
    }
    yield* put(tablesAction.tableDataFetchStart('users'));

    const tableState = yield* select(simpleTableSelector.tableState('users'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'users',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });
    const columnSettings = getRequestedTableColumnSettings<'users'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.getUsersPageV2, filters, params);

    if (response.isSuccess) {
        yield* put(adminAction.getUsersSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('users', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('admin/sagas')('loadUsersList')));
        yield* put(tablesAction.tableDataFetchFailure('users'));
    }
}

export function* getUsersSaga(): SagaIterator {
    yield takeLatestF('admin/GET_USERS', execute);
}
