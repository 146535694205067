import {NullableKeys} from '@fl/cmsch-fe-library';

export const cabFilterFormName = 'cabFilter';

export type CabFilterFormValues = NullableKeys<{
    breederIds: Array<number>,
    districtCodes: Array<number>,
    farmIds: Array<number>,
    organizationInsIds: Array<number>,
    organizationPtIds: Array<number>,
    organizationCenterInsIds: Array<number>,
    organizationCenterPtIds: Array<number>,
    stableIds: Array<number>,
}>;

export const cabInitialFormValues = {
    breederIds: null,
    districtCodes: null,
    farmIds: null,
    organizationInsIds: null,
    organizationPtIds: null,
    organizationCenterInsIds: null,
    organizationCenterPtIds: null,
    stableIds: null,
};
