import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {AddAnimalsFromDBAction, animalBrowserAction} from '../action';
import {updateAnimalSessionAfterAddition} from './update-animals-session';

const te = t('animalBrowserSagas');

function* execute({payload: {animalType, animalListId}}: AddAnimalsFromDBAction): SagaIterator {
    yield* put(formHelpers.startSubmit('addAnimalsFromDB'));
    const response = yield* call(Api.cabImportAnimalListToUserSession, {animalType, animalListId});

    const title = te('addAnimalsFromDb');

    if (response.isSuccess) {
        yield* put(showSuccess(title, te('animalsAddedFromDb'), false, 'add-animals-from-db'));
        yield* put(animalBrowserAction.toggleAddAnimalsFromDBVisibility());
        yield* put(formHelpers.stopSubmit('addAnimalsFromDB'));
        yield* call(updateAnimalSessionAfterAddition, animalType);
    } else {
        yield* put(formHelpers.stopSubmit('addAnimalsFromDB', extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, title));
    }
}

export function* addAnimalsFromDBSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/ADD_ANIMALS_FROM_DB', execute);
}
