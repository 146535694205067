import { debounceTime, FooterButtons } from '@fl/cmsch-fe-library';
import { debounce } from 'lodash/fp';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tCommon, useOurTranslation } from 'app/translations';
import { formHelpers, useForm } from 'utils/forms';
import { adminDiscriminatorOptions } from '../../constants/options';
import { adminAction } from '../../model';
import { simpleAdminSelector } from '../../model/selector';
import { roleFormName, roleInitialValues } from '../../types';
import { CreateOrEditMode } from '../../types/permission-form-mode';
import { validate } from './validate';
interface Props {
  roleId: number | null;
  mode: CreateOrEditMode;
  onSubmit(): void;
  onCancel(): void;
}
const RoleFormBase: FC<Props> = ({
  onSubmit,
  onCancel,
  mode,
  roleId
}) => {
  const tableData = useSelector(simpleAdminSelector.roleDetailsTableData);
  const dispatch = useDispatch();
  const {
    t
  } = useOurTranslation('admin/roles');
  const {
    Form,
    Fields,
    submitting,
    valid,
    pristine,
    asyncValidating
  } = useForm({
    form: roleFormName,
    initialValues: roleInitialValues,
    destroyOnUnmount: true,
    onSubmit,
    validate
  });
  const isEdit = mode === 'edit' && roleId !== null;
  const initFormData = useMemo(() => tableData.filter(role => role.id === roleId)[0], []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEdit && initFormData) {
      dispatch(formHelpers.initialize(roleFormName, initFormData));
    }
  }, [dispatch, initFormData, isEdit, tableData]);
  const validateData = useCallback(() => {
    dispatch(adminAction.validateRole(initFormData, mode));
  }, [dispatch, initFormData, mode]);
  const validateDebounced = useMemo(() => debounce(debounceTime)(validateData), [validateData]);
  return <Form data-sentry-element="Form" data-sentry-component="RoleFormBase" data-sentry-source-file="index.tsx">
            <Fields.Input name="code" type="text" label={t('code')} disabled={submitting} onFieldChange={validateDebounced} autoFocus isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.Input name="name" type="text" label={t('name')} disabled={submitting} onFieldChange={validateDebounced} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.StringSelect name="discriminator" label={t('discriminator')} options={adminDiscriminatorOptions} disabled={submitting} isRequired data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Fields.Checkbox name="userEnable" label={t('userEnableRole')} disabled={submitting} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <FooterButtons submitting={submitting} valid={valid} pristine={pristine} withoutDivider onCancel={onCancel} asyncValidating={asyncValidating} submitTitle={isEdit ? tCommon('edit') : tCommon('create')} data-sentry-element="FooterButtons" data-sentry-source-file="index.tsx" />
        </Form>;
};
export const RoleForm = memo(RoleFormBase);