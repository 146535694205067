import {formatDate, Options} from '@fl/cmsch-fe-library';

import {ApcDate} from 'api/gen/ApcDate';
import {ApcDateListWithStables} from 'api/gen/ApcDateListWithStables';
import {ApcStable} from 'api/gen/ApcStable';
import {ApcStableListWithDates} from 'api/gen/ApcStableListWithDates';
import {Date} from 'api/gen/Date';

export const genProtocolStableOptionsBasedOnDate = (relations: ApcDateListWithStables) =>
    (date: Date): Options<string> => relations.dateData
        ?.find(data => data.proficiencyTestDate === date)
        ?.stables
        ?.map(stable => ({
            label: stable.code,
            value: stable.code,
        })) || [];

export const genProtocolStableOptionsBasedOnStable = (relations: ApcDateListWithStables): Options<string> =>
    relations.stableData
        ?.map(stables => ({
            label: stables.stable.code,
            value: stables.stable.code,
        })) || [];

export const genProtocolDateOptionsBasedOnStable = (relations: ApcDateListWithStables) =>
    (stableName: string): Options<string> =>
        relations.stableData
            ?.find(data => data.stable.code === stableName)
            ?.proficiencyTestDates
            ?.map(date => ({
                label: formatDate(date).orElse(''),
                value: date,
            })) || [];

export const genNonProtocolDateOptionsBasedOnStable = (relations: ApcStableListWithDates) =>
    (stableName: string): Options<string> =>
        relations.data
            ?.find(data => data.stable.code === stableName)
            ?.proficiencyTestDates
            ?.map(date => ({
                label: formatDate(date).orElse(''),
                value: date,
            })) || [];

export const getDateOptions = (apcDateList: Array<ApcDate> | null): Options<string> =>
    apcDateList?.map(data => ({
        label: formatDate(data.proficiencyTestDate).orElse(''),
        value: data.proficiencyTestDate,
    })) || [];

export const getStableOptions = (apcStableList: Array<ApcStable> | null): Options<string> =>
    apcStableList?.map(data => ({
        label: data.stable.code,
        value: data.stable.code,
    })) || [];
