import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {adminAction, DeleteRoleAction} from '../action';

const te = t('admin/sagas');

function* execute({payload: {roleId}}: DeleteRoleAction): SagaIterator {
    const response = yield* call(Api.deleteRole, {roleId});

    const title = te('deleteRole');

    if (response.isSuccess) {
        yield* put(adminAction.getRoles(opt({action: 'refresh'})));
        yield* put(showSuccess(title, te('roleDeleted'), false, 'delete-role'));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* deleteRoleSaga(): SagaIterator {
    yield takeLatestF('admin/DELETE_ROLE', execute);
}
