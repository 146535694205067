import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetCabCalvesAction, animalBrowserAction} from '../action';

const tt = t('animalBrowserSagas');
function* execute(action: GetCabCalvesAction): SagaIterator {
    const {tableDataRequest} = action.payload;
    if (tableDataRequest.isEmpty) {
        return yield* put(animalBrowserAction.getCabCalvesSucces(none));
    }
    yield* put(tablesAction.tableDataFetchStart('comprehensiveCalves'));
    const tableState = yield* select(simpleTableSelector.tableState('comprehensiveCalves'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'comprehensiveCalves',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
        resetType: 'tableDataParams',
    });
    const columnSettings = getRequestedTableColumnSettings<'comprehensiveCalves'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.findCabCalves, filters, params);
    if (response.isSuccess) {
        yield* put(animalBrowserAction.getCabCalvesSucces(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('comprehensiveCalves', tableDataParams));
    } else {
        yield putAll(showBeError(response, tt('loadCabCalvesList')));
        yield* put(tablesAction.tableDataFetchFailure('comprehensiveCalves'));
    }
}
export function* getCabCalvesSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_CAB_CALVES', execute);
}
