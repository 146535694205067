import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {AddAnimalsFromDBAction, matingAction} from '../action';
import {updateAnimalSessionAfterSelection} from './update-animal-session-saga-utils';

const te = t('mating/sagas');

function* execute({payload: {sex, animalListId, associationType}}: AddAnimalsFromDBAction): SagaIterator {
    yield* put(formHelpers.startSubmit('addAnimalsFromDB'));
    const response = yield* call(Api.importAnimalsInListFromDb, {associationType}, {animalListId});

    const title = te('addAnimalsFromDb');

    if (response.isSuccess) {
        yield* put(showSuccess(title, te('animalsAddedFromDb'), false, 'add-animals-from-db'));
        yield* put(matingAction.toggleAddAnimalsFromDBVisibility(sex));
        yield* put(formHelpers.stopSubmit('addAnimalsFromDB'));
        yield* call(updateAnimalSessionAfterSelection, sex, associationType);
    } else {
        yield* put(formHelpers.stopSubmit('addAnimalsFromDB', extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, title));
    }
}

export function* addAnimalsFromDBSaga(): SagaIterator {
    yield takeLatestF('mating/ADD_ANIMALS_FROM_DB', execute);
}
