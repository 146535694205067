import { Loader, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { FC, memo, ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as routerActions from 'redux-first-history';
import { Opt } from 'ts-opt';
import { RolePermission } from 'api/gen/RolePermission';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import useCurrentUserHasAllPermissions from 'app/user/utils/use-current-user-has-all-permissions';
import { Ant } from 'common/ant';
import { BackButton } from 'common/buttons';
import { Card, Space } from 'common/layout';
import { BasicDetailInfo } from 'common/layout/components/basic-detail-info';
import { RolePermissionsTable } from '../components/role-permissions-table';
import { SubordinateRoleAssignedTable } from '../components/role-subordinate-role-tables/assigned-table';
import { SubordinateRoleUnassignedTable } from '../components/role-subordinate-role-tables/unassigned-table';
import { RoleUnassignedPermissionsTable } from '../components/role-unassigned-permissions-table';
import { adminPermissions } from '../constants/permissions';
import { adminAction } from '../model';
import { simpleAdminSelector } from '../model/selector';
import { getDiscriminatorName } from '../utils/get-discriminator-name';
const rolesHeader = (name: string): ReactNode => <Space align="start" data-sentry-element="Space" data-sentry-component="rolesHeader" data-sentry-source-file="role-detail.tsx">
        <span>{name}</span>
    </Space>;
interface MatchParams {
  id: string;
}
type Props = Pick<RouteComponentProps<MatchParams>, 'match'>;
type PermissionDataRequest = Opt<TableDataRequest<RolePermission>>;

// eslint-disable-next-line max-lines-per-function
const RoleDetailBase: FC<Props> = ({
  match: {
    params: {
      id
    }
  }
}) => {
  const dispatch = useDispatch();
  const canUpdateRole = useCurrentUserHasAllPermissions([adminPermissions.updateRole]);
  useEffect(() => {
    if (isNaN(Number(id))) {
      throw new Error(`Role detail id is not a number. ID: ${id}`);
    } else {
      dispatch(adminAction.getRole(Number(id)));
    }
  }, [id, dispatch]);
  useEffect(() => () => {
    dispatch(adminAction.clearRole());
  }, [dispatch]);
  const {
    t: tPermission,
    tCommon
  } = useOurTranslation('admin/permissions');
  const {
    t: tRole
  } = useOurTranslation('admin/roles');
  const role = useSelector(simpleAdminSelector.roleDetail);
  const goBackToList = useCallback(() => {
    dispatch(routerActions.push('/administrace/role'));
  }, [dispatch]);
  const getRolePermissions = useCallback((roleId: string, tableDataRequest: PermissionDataRequest) => {
    dispatch(adminAction.getRolePermissions(Number(roleId), tableDataRequest));
  }, [dispatch]);
  const removePermissionFromRole = useCallback((roleId: string, permissionId: number) => {
    dispatch(adminAction.removePermissionFromRole(Number(roleId), permissionId));
  }, [dispatch]);
  const getRoleUnassignedPermissions = useCallback((roleId: string, tableDataRequest: PermissionDataRequest) => {
    dispatch(adminAction.getRoleUnassignedPermissions(Number(roleId), tableDataRequest));
  }, [dispatch]);
  const addPermissionToRole = useCallback((roleId: string, permissionId: number) => {
    dispatch(adminAction.addPermissionToRole(Number(roleId), permissionId));
  }, [dispatch]);
  if (role.isEmpty) {
    return <Content browserTabTitle={tCommon('loading')}>
                <Loader show size="large" text={tCommon('loading')} />
            </Content>;
  }
  const {
    name,
    discriminator,
    code,
    id: parentRoleId,
    isSubordinate
  } = role.orCrash(`Role is not available. Role ID: ${id}`);
  return <Content browserTabTitle={tRole('pageTitle')} data-sentry-element="Content" data-sentry-component="RoleDetailBase" data-sentry-source-file="role-detail.tsx">
            <Card testId="screenTitle" title={rolesHeader(name)} titleSize="big" extra={<BackButton text={tCommon('backToList')} onClick={goBackToList} />} data-sentry-element="Card" data-sentry-source-file="role-detail.tsx">
                <BasicDetailInfo labels={[tPermission('code'), tPermission('discriminator')]} values={[code ? code : '-', getDiscriminatorName(discriminator)]} data-sentry-element="BasicDetailInfo" data-sentry-source-file="role-detail.tsx" />
                <Ant.Tabs type="card" data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                    <Ant.Tabs.TabPane key="permissions" tab={tPermission('permissions')} data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                        <Ant.Row gutter={16} data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                            <Ant.Col xs={24} lg={12} className="pb-2" data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                                <RolePermissionsTable roleId={id} getRolePermissions={getRolePermissions} removePermissionFromRole={removePermissionFromRole} canUpdateRole={canUpdateRole} data-sentry-element="RolePermissionsTable" data-sentry-source-file="role-detail.tsx" />
                            </Ant.Col>
                            <Ant.Col xs={24} lg={12} data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                                <RoleUnassignedPermissionsTable roleId={id} getRoleUnassignedPermissions={getRoleUnassignedPermissions} addPermissionToRole={addPermissionToRole} canUpdateRole={canUpdateRole} data-sentry-element="RoleUnassignedPermissionsTable" data-sentry-source-file="role-detail.tsx" />
                            </Ant.Col>
                        </Ant.Row>
                    </Ant.Tabs.TabPane>
                    <Ant.Tabs.TabPane key="users" tab={tRole('subordinateRoles')} disabled={isSubordinate} data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                        <Ant.Row gutter={16} data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                            <Ant.Col xs={24} lg={12} className="pb-2" data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                                <SubordinateRoleAssignedTable parentRoleId={parentRoleId} canUpdateRole={canUpdateRole} data-sentry-element="SubordinateRoleAssignedTable" data-sentry-source-file="role-detail.tsx" />
                            </Ant.Col>
                            <Ant.Col xs={24} lg={12} data-sentry-element="unknown" data-sentry-source-file="role-detail.tsx">
                                <SubordinateRoleUnassignedTable parentRoleId={parentRoleId} canUpdateRole={canUpdateRole} data-sentry-element="SubordinateRoleUnassignedTable" data-sentry-source-file="role-detail.tsx" />
                            </Ant.Col>
                        </Ant.Row>
                    </Ant.Tabs.TabPane>
                </Ant.Tabs>
            </Card>
        </Content>;
};
export const RoleDetail = memo(RoleDetailBase);