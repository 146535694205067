import { Modal } from '@fl/cmsch-fe-library';
import React, { FC, Fragment, memo } from 'react';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { useBreedersTabSetup } from '../../hooks/use-breeders-tab-setup';
import { BreedersTabProps } from '../../types/breeders-tab-props';
import { SubjectFacilityForm } from '../subject-facility-form';
import { columns } from './columns';
import { rowMapper } from './row-mapper';
const BreedersTableBase: FC<BreedersTabProps> = props => {
  const {
    userProfileId,
    visibleModal,
    formDataForInit,
    headerButtons,
    actionSettings,
    breederOptions,
    roleOptions,
    t,
    actionMode,
    breederForm,
    farmsSettings,
    getTableData,
    handleOpenCreateModal,
    handleCloseModal,
    handleOrganizationSearch,
    validateDebounced,
    handleSubmitModal,
    validate,
    isFromAdminModule,
    pathnamesForRefresh,
    breedersSelector
  } = useBreedersTabSetup(props);
  return <Fragment>
            <ConnectedTable tableId={isFromAdminModule ? 'userBreeders' : 'userBreedersProfile'} columns={columns} onFetchTableData={getTableData} rowDataPageSelector={breedersSelector} withoutVerticalScrollbar pathnamesForRefresh={pathnamesForRefresh} lazyLoadingLimit={20} paginationPosition="both" showFulltextFilter enableTableSettings enableTableUserSettings rowDataToTableRowMapper={rowMapper} actionSettings={actionSettings} headerButtons={headerButtons} highlightOnEdit emptyTableContent={isFromAdminModule ? <Button role="create" onClick={handleOpenCreateModal}>
                        {t('addBreeder')}
                    </Button> : undefined} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
            {isFromAdminModule && <Modal title={t(actionMode === 'create' ? 'createRoleToUser' : 'updateRoleToUser')} visible={visibleModal === breederForm} onCancel={handleCloseModal} footer={null} width={768}>
                        <Ant.Row>
                            <Ant.Col xs={24}>
                                <SubjectFacilityForm data={formDataForInit} onCancel={handleCloseModal} onSubmit={handleSubmitModal} subjectOptions={breederOptions} roleOptions={roleOptions} userProfileId={userProfileId} onOrganizationSearch={handleOrganizationSearch} onSyncValidate={validateDebounced} subjectTitle={t('breederNumber')} farmsSettings={farmsSettings} validate={validate} subjectFacilityFormType="BREEDER" />
                            </Ant.Col>
                        </Ant.Row>
                    </Modal>}
        </Fragment>;
};
export const BreedersTable = memo(BreedersTableBase);