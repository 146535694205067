import {TableColumn} from '@fl/cmsch-fe-library';

import {CorrectionReportDetail} from 'api/gen/CorrectionReportDetail';
import {TFunction} from 'app/translations';

import {correctionTypeOptions} from '../overview-table/columns';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    useVerticalNames: boolean,
    t: TFunction<'apc/createdReportsTable'>,
    tOverview: TFunction<'apc/overviewTable'>,
): Array<TableColumn<CorrectionReportDetail>> => [
    {
        field: 'correctionType',
        column: t('correctionType'),
        type: 'option',
        options: correctionTypeOptions(tOverview),
        width: 185,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'technicianNumber',
        column: t('technicianNumber'),
        type: 'string',
        width: 185,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'earTag',
        column: t('earTag'),
        filterTypes: ['betweenEarTags'],
        type: 'string',
        width: 160,
        disableFilter: true,
    },
    {
        field: 'stableCode',
        column: t('stableNumber'),
        type: 'string',
        width: 160,
        disableFilter: true,
    },
    {
        field: 'proficiencyTestDate',
        column: t('proficiencyTestDate'),
        type: 'date',
        dateFormat: 'date',
        width: 160,
        disableFilter: true,
    },
    {
        field: 'milkingDayTime',
        column: t('milkingDayTime'),
        type: 'number',
        width: 60,
        disableFilter: true,

    },
    {
        field: 'milkingInterval',
        column: t('milkingInterval'),
        type: 'number',
        width: 60,
        disableFilter: true,

    },
    {
        field: 'milkingCount',
        column: t('milkingCount'),
        type: 'number',
        width: 60,
        disableFilter: true,
    },
    {
        field: 'milkKg',
        column: t('milkKg'),
        type: 'decimal',
        fixedDecimals: 2,
        width: 60,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'fatPercentage',
        column: t('fatPercentage'),
        type: 'decimal',
        fixedDecimals: 2,
        width: 60,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'proteinPercentage',
        column: t('proteinPercentage'),
        type: 'decimal',
        fixedDecimals: 2,
        width: 60,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'lactosePercentage',
        column: t('lactosePercentage'),
        type: 'decimal',
        fixedDecimals: 2,
        width: 60,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'somaticCells',
        column: t('somaticCells'),
        type: 'decimal',
        fixedDecimals: 0,
        width: 60,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'urea',
        column: t('urea'),
        type: 'decimal',
        fixedDecimals: 2,
        width: 60,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'updatedEarTag',
        column: t('updatedEarTag'),
        filterTypes: ['betweenEarTags'],
        type: 'string',
        width: 160,
        disableFilter: true,
    },
];
