import {downloadFileFromResponse, putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {split} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {opt, orCrash, pipe, last} from 'ts-opt';
import {call, select, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {simpleTableSelector, getRequestedTableCsvExportParams} from 'utils/tables';

import {animalBrowserAction, ExportToCsvAction} from '../action';

const getIdFromUrl = ({router: {location}}: State): string =>
    pipe(location?.pathname, split('/'), last, orCrash('invalid url'));

function* execute(_action: ExportToCsvAction): SagaIterator {
    yield* put(animalBrowserAction.startExport());
    const tableState = opt(yield* select(simpleTableSelector.tableState('cowBrowser'))).orCrash('no tableState');

    const {sort, fieldParams} = getRequestedTableCsvExportParams(tableState);

    const id = yield* select(getIdFromUrl);
    const pathParams = {stableId: parseInt(id, 10)};

    const response = yield* call(Api.exportBreedingFemales, fieldParams, {sort}, pathParams);
    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('cowBrowserTable')('export')));
    }
    yield* put(animalBrowserAction.exportFinished());
}

export function* getCowsCsvSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/EXPORT_TO_CSV', execute);
}
