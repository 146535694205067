import {Options} from '@fl/cmsch-fe-library';
import {none, Opt} from 'ts-opt';

import {AnimalList} from 'api/gen/AnimalList';
import {AnimalType} from 'api/gen/AnimalType';
import {AnimalsBrowserFarm} from 'api/gen/AnimalsBrowserFarm';
import {AnimalsBrowserStable} from 'api/gen/AnimalsBrowserStable';
import {AvailableAnimal} from 'api/gen/AvailableAnimal';
import {PagedModelBreedingFemale} from 'api/gen/PagedModelBreedingFemale';
import {PagedModelCabBreedingFemale} from 'api/gen/PagedModelCabBreedingFemale';
import {PagedModelCabCalf} from 'api/gen/PagedModelCabCalf';
import {PagedModelCalf} from 'api/gen/PagedModelCalf';
import {PagedModelStableToChoose} from 'api/gen/PagedModelStableToChoose';

import {BrowserLoadingId} from '../types/browser-loading-id';

export interface AnimalBrowserState {
    stablesPage: Opt<PagedModelStableToChoose>;
    cowBrowserPage: Opt<PagedModelBreedingFemale>;
    cabFemalePage: Opt<PagedModelCabBreedingFemale>;
    cabFemalesExistInSession: boolean;
    stableInfo: Opt<AnimalsBrowserStable>;
    farmInfo: Opt<AnimalsBrowserFarm>;
    calfBrowserPage: Opt<PagedModelCalf>;
    productionAreasInCentralEvidenceOptions: Opt<Options<string>>;
    exportInProgress: boolean;
    loading: Array<BrowserLoadingId>;
    cabCalfPage: Opt<PagedModelCabCalf>;
    cabCalvesExistInSession: boolean;
    organizationsPtOptions: Opt<Options<number>>;
    organizationsInsOptions: Opt<Options<number>>;
    districtCodesOptions: Opt<Options<number>>;
    breederOptions: Opt<Options<number>>;
    farmOptions: Opt<Options<number>>;
    stableOptions: Opt<Options<number>>;
    organizationsCenterInsOptions: Opt<Options<number>>;
    organizationsCenterPtOptions: Opt<Options<number>>;
    firstCabCalvesLoad: boolean;
    firstCabBreedingFemalesLoad: boolean;
    createAnimalListsModalVisible: boolean;
    overviewListModalVisible: boolean;
    animalLists: Opt<Array<AnimalList>>;
    animalListsTag: Opt<AnimalType>;
    overwriteListNameModalVisible: boolean;
    overwriteAnimalListId: Opt<number>;
    createAnimalListsFromIdsModalVisible: boolean;
    overwriteListFromIdsNameModalVisible: boolean;
    overwriteAnimalListFromIdsId: Opt<number>;
    selectedAnimalIds: Array<number>;
    addAnimalsModalVisible: boolean;
    availableAnimals: Opt<Array<AvailableAnimal>>;
    addAnimalsFromDBModalVisible: boolean;
}

export const initialAnimalBrowserState: AnimalBrowserState = {
    stablesPage: none,
    cowBrowserPage: none,
    stableInfo: none,
    farmInfo: none,
    calfBrowserPage: none,
    cabFemalePage: none,
    cabFemalesExistInSession: false,
    productionAreasInCentralEvidenceOptions: none,
    exportInProgress: false,
    loading: [],
    cabCalfPage: none,
    cabCalvesExistInSession: false,
    organizationsPtOptions: none,
    organizationsInsOptions: none,
    districtCodesOptions: none,
    breederOptions: none,
    farmOptions: none,
    stableOptions: none,
    organizationsCenterInsOptions: none,
    organizationsCenterPtOptions: none,
    firstCabCalvesLoad: false,
    firstCabBreedingFemalesLoad: false,
    createAnimalListsModalVisible: false,
    overviewListModalVisible: false,
    animalLists: none,
    animalListsTag: none,
    overwriteListNameModalVisible: false,
    overwriteAnimalListId: none,
    createAnimalListsFromIdsModalVisible: false,
    overwriteAnimalListFromIdsId: none,
    overwriteListFromIdsNameModalVisible: false,
    selectedAnimalIds: [],
    addAnimalsModalVisible: false,
    availableAnimals: none,
    addAnimalsFromDBModalVisible: false,
};
