import {Nullable} from '@fl/cmsch-fe-library';

export const addAnimalsFormName = 'addAnimals';

export interface AddAnimalsFormValues {
    addAnimalsToList: Nullable<Array<number>>;
}

export const addAnimalsFormInitialValues: AddAnimalsFormValues = {
    addAnimalsToList: null,
};
