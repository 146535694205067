import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {AppModule} from 'api/gen/AppModule';
import {GridSettings} from 'api/gen/GridSettings';
import {Language} from 'api/gen/Language';
import {ModuleGridSettings} from 'api/gen/ModuleGridSettings';
import {PagedModelAssociationRole} from 'api/gen/PagedModelAssociationRole';
import {PagedModelBreederRole} from 'api/gen/PagedModelBreederRole';
import {PagedModelOrganizationRole} from 'api/gen/PagedModelOrganizationRole';
import {PagedModelOtherSubjectRole} from 'api/gen/PagedModelOtherSubjectRole';
import {PagedModelUserProfileContact} from 'api/gen/PagedModelUserProfileContact';
import {PagedModelUserSubjectView} from 'api/gen/PagedModelUserSubjectView';
import {Principal} from 'api/gen/Principal';
import {UserProfile} from 'api/gen/UserProfile';
import {AssociationRoleTableType} from 'app/user-detail/types/association-role-table-type';
import {BreederRoleTableType} from 'app/user-detail/types/breeder-role-table-type';
import {MemberOfSubjectsTableType} from 'app/user-detail/types/member-of-subjects-table-type';
import {OrganizationTableType} from 'app/user-detail/types/organization-table-type';
import {OtherSubjectRoleTableType} from 'app/user-detail/types/other-subject-role-table-type';
import {UserContactsTableType} from 'app/user-detail/types/user-contacts-table-type';
import {TableId} from 'common/types/table-id';

interface SetUserSettingsOptions {
    tableId: TableId;
    idWithPrefix?: string;
    dontSetActive?: boolean;
}

export const userAction = {
    initialize: (isRestrictedPath: boolean) => ({
        type: 'user/INITIALIZE',
        payload: {isRestrictedPath},
    }) as const,
    initializeFinish: () => ({
        type: 'user/INITIALIZE_FINISH',
    }) as const,
    logIn: () => ({
        type: 'user/LOGIN',
    }) as const,
    logOut: (goToLogin?: boolean) => ({
        type: 'user/LOGOUT',
        payload: {goToLogin},
    }) as const,
    logOutClear: () => ({
        type: 'user/LOGOUT_CLEAR',
    }) as const,
    getCurrentUser: () => ({
        type: 'user/GET_CURRENT_USER',
    }) as const,
    getCurrentUserSuccess: (currentUser: Principal) => ({
        type: 'user/GET_CURRENT_USER_SUCCESS',
        payload: currentUser,
    }) as const,
    resetCurrentUser: () => ({
        type: 'user/RESET_CURRENT_USER',
    }) as const,
    changeAndSaveLanguage: (language: Language) => ({
        type: 'user/CHANGE_AND_SAVE_LANGUAGE',
        payload: {language},
    }) as const,
    setUiLanguage: (language: Language) => ({
        type: 'user/SET_UI_LANGUAGE',
        payload: language,
    }) as const,
    getAppSettingsList: (module: AppModule) => ({
        type: 'user/GET_APP_SETTINGS_LIST',
        payload: {module},
    }) as const,
    getAppSettingsListSuccess: (appSettingsList: Array<ModuleGridSettings>) => ({
        type: 'user/GET_APP_SETTINGS_LIST_SUCCESS',
        payload: {appSettingsList},
    }) as const,
    setUserSettings: (
        userSettings: GridSettings,
        defaultSettingsId: number,
        options?: SetUserSettingsOptions,
    ) => ({
        type: 'user/SET_USER_SETTINGS',
        payload: {defaultSettingsId, userSettings, options},
    }) as const,
    deleteUserSettings: (userSettingsId: number) => ({
        type: 'user/DELETE_USER_SETTINGS',
        payload: {userSettingsId},
    }) as const,
    resetAppSettings: () => ({
        type: 'user/RESET_APP_SETTINGS',
    }) as const,
    setPreviousAndCurrentPathname: (previous: Opt<string>, current: Opt<string>) => ({
        type: 'user/SET_PREVIOUS_AND_CURRENT_PATHNAME',
        payload: {previous, current},
    }) as const,
    getUserProfile: (userProfileId: number, isFromUserProfile?: boolean) => ({
        type: 'user/GET_USER_PROFILE',
        payload: {userProfileId, isFromUserProfile},
    }) as const,
    getUserProfileSuccess: (userProfile: UserProfile) => ({
        type: 'user/GET_USER_PROFILE_SUCCESS',
        payload: userProfile,
    }) as const,
    getUserMemberOfSubjects: (
        tableDataRequest: Opt<TableDataRequest<MemberOfSubjectsTableType>>,
        userProfileId: number,
    ) => ({
        type: 'user/GET_USER_MEMBER_OF_SUBJECTS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getUserMemberOfSubjectsSuccess: (rolesPage: Opt<PagedModelUserSubjectView>) => ({
        type: 'user/GET_USER_MEMBER_OF_SUBJECTS_SUCCESS',
        payload: rolesPage,
    }) as const,
    getUserContacts: (
        tableDataRequest: Opt<TableDataRequest<UserContactsTableType>>,
        userProfileId: number,
    ) => ({
        type: 'user/GET_USER_CONTACTS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getUserContactsSuccess: (rolesPage: Opt<PagedModelUserProfileContact>) => ({
        type: 'user/GET_USER_CONTACTS_SUCCESS',
        payload: rolesPage,
    }) as const,
    getUserOrganizations: (
        tableDataRequest: Opt<TableDataRequest<OrganizationTableType>>,
        userProfileId: number,
    ) => ({
        type: 'user/GET_USER_ORGANIZATIONS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getUserOrganizationsSuccess: (organizationsPage: Opt<PagedModelOrganizationRole>) => ({
        type: 'user/GET_USER_ORGANIZATIONS_SUCCESS',
        payload: organizationsPage,
    }) as const,
    getUserBreeders: (
        tableDataRequest: Opt<TableDataRequest<BreederRoleTableType>>,
        userProfileId: number,
    ) => ({
        type: 'user/GET_USER_BREEDERS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getUserBreedersSuccess: (authorizedAssociationsPage: Opt<PagedModelBreederRole>) => ({
        type: 'user/GET_USER_BREEDERS_SUCCESS',
        payload: authorizedAssociationsPage,
    }) as const,
    getUserAssociations: (
        tableDataRequest: Opt<TableDataRequest<AssociationRoleTableType>>,
        userProfileId: number,
    ) => ({
        type: 'user/GET_USER_ASSOCIATIONS',
        payload: {tableDataRequest, userProfileId},
    }) as const,
    getUserAssociationsSuccess: (associationsPage: Opt<PagedModelAssociationRole>) => ({
        type: 'user/GET_USER_ASSOCIATIONS_SUCCESS',
        payload: associationsPage,
    }) as const,
    getOtherSubjects: (request: Opt<TableDataRequest<OtherSubjectRoleTableType>>, userProfileId: number) => ({
        type: 'user/GET_USER_OTHER_SUBJECTS',
        payload: {request, userProfileId},
    }) as const,
    getOtherSubjectsSuccess: (otherSubjectRolesPage: Opt<PagedModelOtherSubjectRole>) => ({
        type: 'user/GET_USER_OTHER_SUBJECTS_SUCCESS',
        payload: otherSubjectRolesPage,
    }) as const,
};

export type InitializeAction = ReturnType<typeof userAction.initialize>;
export type InitializeFinishAction = ReturnType<typeof userAction.initializeFinish>;
export type LogInAction = ReturnType<typeof userAction.logIn>;
export type LogOutAction = ReturnType<typeof userAction.logOut>;
export type GetCurrentUserAction = ReturnType<typeof userAction.getCurrentUser>;
export type GetCurrentUserSuccessAction = ReturnType<typeof userAction.getCurrentUserSuccess>;
export type ResetCurrentUserAction = ReturnType<typeof userAction.resetCurrentUser>;
export type ChangeAndSaveLanguage = ReturnType<typeof userAction.changeAndSaveLanguage>;
export type SetUiLanguageAction = ReturnType<typeof userAction.setUiLanguage>;
export type GetAppSettingsListAction = ReturnType<typeof userAction.getAppSettingsList>;
export type GetAppSettingsListSuccessAction = ReturnType<typeof userAction.getAppSettingsListSuccess>;
export type SetUserSettingsAction = ReturnType<typeof userAction.setUserSettings>;
export type DeleteUserSettingsAction = ReturnType<typeof userAction.deleteUserSettings>;
export type ResetAppSettingsAction = ReturnType<typeof userAction.resetAppSettings>;
export type SetPreviousAndCurrentPathnameAction = ReturnType<typeof userAction.setPreviousAndCurrentPathname>;
export type GetUserProfileSuccessAction = ReturnType<typeof userAction.getUserProfileSuccess>;
export type GetUserMemberOfSubjectsAction = ReturnType<typeof userAction.getUserMemberOfSubjects>;
export type GetUserMemberOfSubjectsSuccessAction = ReturnType<typeof userAction.getUserMemberOfSubjectsSuccess>;
export type GetUserContactsAction = ReturnType<typeof userAction.getUserContacts>;
export type GetUserContactsSuccessAction = ReturnType<typeof userAction.getUserContactsSuccess>;
export type GetUserOrganizationsAction = ReturnType<typeof userAction.getUserOrganizations>;
export type GetUserOrganizationsSuccessAction = ReturnType<typeof userAction.getUserOrganizationsSuccess>;
export type GetUserBreedersAction = ReturnType<typeof userAction.getUserBreeders>;
export type GetUserBreedersActionSuccess = ReturnType<typeof userAction.getUserBreedersSuccess>;
export type GetUserAssociationsAction = ReturnType<typeof userAction.getUserAssociations>;
export type GetUserAssociationsActionSuccess = ReturnType<typeof userAction.getUserAssociationsSuccess>;
export type GetUserOtherSubjectsAction = ReturnType<typeof userAction.getOtherSubjects>;
export type GetUserOtherSubjectsSuccessAction = ReturnType<typeof userAction.getOtherSubjectsSuccess>;
export type GetUserProfileAction = ReturnType<typeof userAction.getUserProfile>;
export type LogOutClearAction = ReturnType<typeof userAction.logOutClear>;

export type UserAction =
    | InitializeAction
    | InitializeFinishAction
    | LogInAction
    | LogOutAction
    | GetCurrentUserAction
    | GetCurrentUserSuccessAction
    | ResetCurrentUserAction
    | ChangeAndSaveLanguage
    | SetUiLanguageAction
    | GetAppSettingsListAction
    | GetAppSettingsListSuccessAction
    | SetUserSettingsAction
    | DeleteUserSettingsAction
    | ResetAppSettingsAction
    | SetPreviousAndCurrentPathnameAction
    | GetUserProfileSuccessAction
    | GetUserMemberOfSubjectsAction
    | GetUserMemberOfSubjectsSuccessAction
    | GetUserContactsAction
    | GetUserContactsSuccessAction
    | GetUserOrganizationsAction
    | GetUserOrganizationsSuccessAction
    | GetUserBreedersAction
    | GetUserBreedersActionSuccess
    | GetUserAssociationsAction
    | GetUserAssociationsActionSuccess
    | GetUserOtherSubjectsAction
    | GetUserOtherSubjectsSuccessAction
    | GetUserProfileAction
    | LogOutClearAction
    ;
