import React, { Fragment, FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { AssociationType } from 'api/gen/AssociationType';
import { CreateOrUpdateGridUserSettings } from 'api/gen/CreateOrUpdateGridUserSettings';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { CriteriaList } from 'api/gen/CriteriaList';
import { WriteCriteria } from 'api/gen/WriteCriteria';
import { MatingLoadingId } from 'app/mating/types';
import { ModalListConfirmName, ModalListsOverview, ModalAddAnimals, ModalAddAnimalsFromDb } from 'app/modals/components';
import { useOurTranslation } from 'app/translations';
import { matingAction, matingSelector } from '../../model';
import { ModalCriteriaListName } from '../modal-criteria-list-name';
import { ModalCriteriaListNameConfirm } from '../modal-criteria-list-name-confirm';
import { ModalGetCriteriaDetails } from '../modal-get-criteria-details';
import { ModalMultipleSaveList } from '../modal-multiple-save-list';
const MALE = 'MALE';
interface Props {
  associationType: AssociationType;
  isAnimalsPageEmpty: boolean;
  loading: Array<MatingLoadingId>;
  animalLists: Opt<Array<AnimalList>>;
  criteriaLists: Opt<Array<CriteriaList>>;
  criteriaDefinitions: Opt<Array<CriteriaDefinition>>;
  tableSettingsNameList: Array<string>;
  toggleAnimalListsVisibility(): void;
  toggleAddAnimalsVisibility(): void;
  toggleAddAnimalsFromDBVisibility(): void;
  toggleListsOverviewVisibility(): void;
  toggleCriteriaListsVisibility(): void;
  toggleCriteriaListDetailsVisibility(): void;
}

// eslint-disable-next-line max-lines-per-function
const MatingBullsModalsBase: FC<Props> = ({
  associationType,
  isAnimalsPageEmpty,
  loading,
  animalLists,
  criteriaLists,
  tableSettingsNameList,
  criteriaDefinitions,
  toggleAnimalListsVisibility,
  toggleAddAnimalsVisibility,
  toggleAddAnimalsFromDBVisibility,
  toggleListsOverviewVisibility,
  toggleCriteriaListsVisibility,
  toggleCriteriaListDetailsVisibility
}) => {
  const dispatch = useDispatch();
  const animalListId = useSelector(matingSelector.animalListId);
  const availableAnimals = useSelector(matingSelector.availableAnimals);
  const criteriaListId = useSelector(matingSelector.criteriaListId);
  const animalListsModalVisible = useSelector(matingSelector.maleAnimalListsModalVisible);
  const maleAnimalListsConfirmModalVisible = useSelector(matingSelector.maleAnimalListsConfirmModalVisible);
  const addBullsModalVisible = useSelector(matingSelector.addBullsModalVisible);
  const bullsFromDBVisibilityModalVisible = useSelector(matingSelector.bullsFromDBVisibilityModalVisible);
  const bullsCriteriaListsModalVisible = useSelector(matingSelector.bullsCriteriaListsModalVisible);
  const bullsCriteriaListsConfirmModalVisible = useSelector(matingSelector.bullsCriteriaListsConfirmModalVisible);
  const bullsCriteriaListDetailsModalVisible = useSelector(matingSelector.bullsCriteriaListDetailsModalVisible);
  const cowListsOverviewModalVisible = useSelector(matingSelector.bullsListsOverviewModalVisible);
  const createBullsCriteriaList = useCallback((name: string): void => {
    dispatch(matingAction.createCriteriaList(MALE, name, criteriaDefinitions, associationType));
  }, [dispatch, criteriaDefinitions, associationType]);
  const confirmBullsCriteriaList = useCallback((criteriaList: Array<WriteCriteria>, gridUserSettings: CreateOrUpdateGridUserSettings | null): void => {
    const listId = criteriaListId.orCrash('criteriaListId not available');
    dispatch(matingAction.confirmCriteriaList({
      bookmark: MALE,
      criteriaListId: listId,
      criteria: criteriaList,
      gridUserSettings,
      associationType
    }));
  }, [criteriaListId, associationType, dispatch]);
  const createBullsList = useCallback((name: string): void => {
    dispatch(matingAction.createAnimalList(MALE, name, associationType));
  }, [dispatch, associationType]);
  const renameBullsList = useCallback((id: number, name: string): void => {
    dispatch(matingAction.renameAnimalList(MALE, id, name, associationType));
  }, [dispatch, associationType]);
  const renameBullsCriteriaList = useCallback((id: number, name: string, gridUserSettingsId: number | null): void => {
    dispatch(matingAction.renameCriteriaList({
      bookmark: MALE,
      id,
      gridUserSettingsId,
      name,
      associationType
    }));
  }, [dispatch, associationType]);
  const confirmBullsList = useCallback((listId: number): void => {
    dispatch(matingAction.confirmAnimalList(MALE, listId, associationType));
  }, [dispatch, associationType]);
  const deleteBullsList = useCallback((listId: number): void => {
    dispatch(matingAction.deleteAnimalList(listId, MALE, associationType));
  }, [dispatch, associationType]);
  const deleteBullsCriteriaList = useCallback((listId: number, gridUserSettingsId: number): void => {
    dispatch(matingAction.deleteCriteriaList(listId, MALE, associationType, gridUserSettingsId));
  }, [dispatch, associationType]);
  const getAvailableBulls = useCallback((input: string): void => {
    dispatch(matingAction.getAvailableAnimals(MALE, input, associationType));
  }, [dispatch, associationType]);
  const setGetAvailableAnimalsLoading = useCallback((): void => {
    dispatch(matingAction.setLoading('getAvailableAnimals', true));
  }, [dispatch]);
  const addBulls = useCallback((animalIds: Array<number>): void => {
    dispatch(matingAction.addAnimals(MALE, animalIds, associationType));
  }, [dispatch, associationType]);
  const addBullsFromDB = useCallback((listId: number): void => {
    dispatch(matingAction.addAnimalsFromDB(MALE, listId, associationType));
  }, [dispatch, associationType]);
  const getBullsCsv = useCallback((fileName: string): void => {
    dispatch(matingAction.getAnimalsCsv(MALE, fileName, associationType));
  }, [dispatch, associationType]);
  const toggleOverwriteBullsListVisibility = useCallback((listId: Opt<number>): void => {
    dispatch(matingAction.toggleOverwriteAnimalListVisibility(MALE, listId));
  }, [dispatch]);
  const toggleBullsOverwriteCriteriaListVisibility = useCallback((gridUserSettings: CreateOrUpdateGridUserSettings | null, writeCriteria?: Array<WriteCriteria>): void => {
    dispatch(matingAction.toggleOverwriteCriteriaListVisibility(MALE, gridUserSettings, writeCriteria));
  }, [dispatch]);
  const getBullsCriteriaListDetails = useCallback((listId: number): void => {
    dispatch(matingAction.getCriteriaListDetails(MALE, associationType, listId));
  }, [dispatch, associationType]);
  const confirmAnimalListNameLoading = loading.includes('confirmAnimalListNameInProgress');
  const confirmCriteriaListNameLoading = loading.includes('confirmCriteriaListNameInProgress');
  const animalListsLoading = loading.includes('animalLists');
  const {
    t,
    tCommon
  } = useOurTranslation('mating/modalAddAnimals');
  const {
    t: tOverview
  } = useOurTranslation('mating/modalListsOverview');
  const {
    t: tListName
  } = useOurTranslation('mating/modalListName');
  return <Fragment>
            <ModalListConfirmName visible={maleAnimalListsConfirmModalVisible} animalListId={animalListId} onSubmit={confirmBullsList} onCancel={toggleOverwriteBullsListVisibility} loading={confirmAnimalListNameLoading} listAlreadyExistsMessage={tListName('listAlreadyExists')} data-sentry-element="ModalListConfirmName" data-sentry-source-file="bulls-modals.tsx" />
            <ModalMultipleSaveList visible={animalListsModalVisible} isAnimalsPageEmpty={isAnimalsPageEmpty} animalListEmptyMessage={tListName('atLeastOneAnimal')} onSubmitSaveToDb={createBullsList} onSubmitSaveToFile={getBullsCsv} onCancel={toggleAnimalListsVisibility} animalLists={animalLists} title={tListName('saveBulls')} data-sentry-element="ModalMultipleSaveList" data-sentry-source-file="bulls-modals.tsx" />
            <ModalAddAnimals onCancel={toggleAddAnimalsVisibility} onSearch={getAvailableBulls} onSubmit={addBulls} visible={addBullsModalVisible} title={t('maleTitle')} fieldLabel={tCommon('linReg')} submitLabel={tCommon('add')} availableAnimals={availableAnimals} loadingAvailableAnimals={loading.includes('getAvailableAnimals')} setAvailableAnimalsLoading={setGetAvailableAnimalsLoading} data-sentry-element="ModalAddAnimals" data-sentry-source-file="bulls-modals.tsx" />
            <ModalAddAnimalsFromDb visible={bullsFromDBVisibilityModalVisible} animalLists={animalLists} title={t('chooseAnimalList')} submitLabel={tCommon('add')} selectLabel={t('animalList')} animalListsLoading={animalListsLoading} onCancel={toggleAddAnimalsFromDBVisibility} onSubmit={addBullsFromDB} data-sentry-element="ModalAddAnimalsFromDb" data-sentry-source-file="bulls-modals.tsx" />
            <ModalListsOverview visible={cowListsOverviewModalVisible} animalLists={animalLists} criteriaLists={criteriaLists} onCancel={toggleListsOverviewVisibility} renameAnimalList={renameBullsList} renameCriteriaList={renameBullsCriteriaList} deleteAnimalList={deleteBullsList} deleteCriteriaList={deleteBullsCriteriaList} noListsSaved={tOverview('noSavedLists')} title={tOverview('managementOfSavedLists')} areYouSureToDeleteMessage={tOverview('areYouSure')} listAlreadyExistsMessage={tOverview('listAlreadyExists')} data-sentry-element="ModalListsOverview" data-sentry-source-file="bulls-modals.tsx" />
            <ModalCriteriaListName criteriaLists={criteriaLists} tableSettingsNameList={tableSettingsNameList} visible={bullsCriteriaListsModalVisible} onSubmit={createBullsCriteriaList} onCancel={toggleCriteriaListsVisibility} data-sentry-element="ModalCriteriaListName" data-sentry-source-file="bulls-modals.tsx" />
            <ModalCriteriaListNameConfirm visible={bullsCriteriaListsConfirmModalVisible} onSubmit={confirmBullsCriteriaList} onCancel={toggleBullsOverwriteCriteriaListVisibility} loading={confirmCriteriaListNameLoading} data-sentry-element="ModalCriteriaListNameConfirm" data-sentry-source-file="bulls-modals.tsx" />
            <ModalGetCriteriaDetails visible={bullsCriteriaListDetailsModalVisible} criteriaLists={criteriaLists} onSubmit={getBullsCriteriaListDetails} onCancel={toggleCriteriaListDetailsVisibility} data-sentry-element="ModalGetCriteriaDetails" data-sentry-source-file="bulls-modals.tsx" />
        </Fragment>;
};
export const MatingBullsModals = memo(MatingBullsModalsBase);