import React, { memo, FC, useMemo, Fragment, ReactNode } from 'react';
import { Opt } from 'ts-opt';
import { CriteriaList } from 'api/gen/CriteriaList';
import { useOurTranslation } from 'app/translations';
import { ListRow } from './list-row';
import { SectionHeader } from './section-header';
interface Props {
  criteriaLists: Opt<Array<CriteriaList>>;
  listHeader: ReactNode;
  emptyList: ReactNode;
  listAlreadyExistsMessage: string;
  areYouSureToDeleteMessage: string;
  renameCriteriaList(id: number | null, name: string, gridUserSettingsId?: number | null): void;
  deleteCriteriaList(criteriaListId: number, gridUserSettingsId?: number | null): void;
}
const CriteriaListSectionBase: FC<Props> = props => {
  const {
    criteriaLists,
    listHeader,
    emptyList,
    listAlreadyExistsMessage,
    areYouSureToDeleteMessage,
    renameCriteriaList,
    deleteCriteriaList
  } = props;
  const {
    t
  } = useOurTranslation('animalListModals');
  const criteriaListsPresent: Array<CriteriaList> = useMemo(() => criteriaLists.orElse([]), [criteriaLists]);
  const criteriaListSectionBody = useMemo(() => criteriaListsPresent.some(x => x.editable) ? <Fragment>
                {listHeader}
                {criteriaListsPresent.map(item => item.editable && <ListRow item={item} list={criteriaListsPresent} key={item.id} editList={renameCriteriaList} deleteList={deleteCriteriaList} listAlreadyExistsMessage={listAlreadyExistsMessage} areYouSureToDeleteMessage={areYouSureToDeleteMessage} />)}
            </Fragment> : emptyList, [criteriaListsPresent, deleteCriteriaList, emptyList, listHeader, listAlreadyExistsMessage, areYouSureToDeleteMessage, renameCriteriaList]);
  return <Fragment>
            <SectionHeader title={t('criteriaLists')} data-sentry-element="SectionHeader" data-sentry-source-file="criteria-list-section.tsx" />
            {criteriaListSectionBody}
        </Fragment>;
};
export const CriteriaListSection = memo(CriteriaListSectionBase);