import {Requester, ResponseError} from '@fl/cmsch-fe-library';
import {noop} from 'lodash/fp';
import {Store} from 'redux';

import {Config} from 'app/config';
import {defaultLanguage} from 'app/consts';
import {restrictedPaths} from 'app/navigation';
import {userAction} from 'app/user/model/action';
import {languageStore} from 'utils/language-store';

import {logger} from './sentry-logger';
import {State} from './setup';
import {systemAction} from './system/model/action';

const getAcceptLanguage = (): string => languageStore.get() || defaultLanguage;

const reportValidationError = (error: Error): void => {
    logger.logError(error);
};

let store: Store<State>;
export const injectStore = (_store: Store<State>): void => {
    store = _store;
};

const onVersionMismatch = (): void => void store.dispatch(systemAction.checkFEVersion(true));
const onServerError = (error: ResponseError): void => {
    logger.logError(error);
    store.dispatch(systemAction.changeServerError(true));
};

const handleUnauthorized = (): void => {
    store.dispatch(userAction.logOut(
        // eslint-disable-next-line @fl/use-eta-reduction
        restrictedPaths.some(p => location.pathname.startsWith(p))
        || location.pathname === '/login'),
    );
};

// eslint-disable-next-line import/no-unused-modules
export const apiRequester = new Requester({
    baseUrl: Config.apiUrl,
    devMode: Config.isDevelopment,
    withCredentials: Config.isDevelopment,
    timeout: 60000,
    versionInfo: {
        key: 'X-eSkot-FE-version',
        version: Config.version,
    },
    authType: 'jwt',
    onUnauthorized: handleUnauthorized,
    onForbidden: noop, // remove
    getAcceptLanguage,
    onValidationError: reportValidationError,
    onVersionMismatch,
    onServerError,
});

export const legacyApiRequester = new Requester({
    baseUrl: Config.legacyEskotUrl,
    devMode: Config.isDevelopment,
    withCredentials: true,
    timeout: 60000,
    xsrf: false,
    noCache: false,
    onUnauthorized: noop,
    onForbidden: noop, // remove
    onValidationError: reportValidationError,
    onServerError,
});

// eslint-disable-next-line import/no-unused-modules
export const staticRequester = new Requester({
    baseUrl: Config.url === 'http://localhost:8080' ? `${Config.url}/dist` : Config.url,
    devMode: Config.isDevelopment,
    xsrf: false,
    onForbidden: noop,
    onUnauthorized: handleUnauthorized,
    onServerError,
});
