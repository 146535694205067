import {NullableKeys} from '@fl/cmsch-fe-library';

import {Criterias} from './criterias';

export const matingBullsFilterFormName = 'matingBullsFilter';

export type MatingBullsFilterFormValues = NullableKeys<{
    organizationIds: Array<number>,
    criterias: Criterias,
    validationHack: number,
}>;

export const matingBullsInitialFormValues: MatingBullsFilterFormValues = {
    organizationIds: null,
    criterias: null,
    validationHack: null,
};
