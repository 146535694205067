import {TableColumn} from '@fl/cmsch-fe-library';

import {PermissionDetail} from 'api/gen/PermissionDetail';

import {adminDiscriminatorOptions} from '../../constants/options';

export const permissionsColumns: Array<TableColumn<PermissionDetail>> = [
    {
        field: 'actionName',
        type: 'string',
    },
    {
        field: 'comment',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'code',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'controllerName',
        type: 'string',
    },
    {
        field: 'discriminator',
        type: 'option',
        options: adminDiscriminatorOptions,
    },
    {
        field: 'userEnable',
        type: 'boolean',
    },
];
