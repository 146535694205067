import { ActionSettings, beDateFormat, Table, useWindowDimensions, LG } from '@fl/cmsch-fe-library';
import { orderBy } from 'lodash/fp';
import moment from 'moment';
import React, { FC, memo, useMemo } from 'react';
import { MilkabilityDetail } from 'api/gen/MilkabilityDetail';
import { isDateWithinTwoControlYears, getControlInterval } from 'app/milkability/utils/is-date-within-two-control-years';
import { useOurTranslation } from 'app/translations';
import { getColumns } from './columns';
import tableStyles from './styles.sass';
interface Props {
  createdMilkabilityReports: Array<MilkabilityDetail>;
  isMilkabilityReportFormEmpty: boolean;
  deleteCreatedReportInProgress?: boolean;
  editCreatedMilkabilityReport(id: string | number, row: MilkabilityDetail): void;
  deleteMilkabilityReport(id: number): void;
  resetCreatedMilkabilityReports(): void;
}
const CreatedMilkabilityReportsTableBase: FC<Props> = props => {
  const {
    createdMilkabilityReports,
    isMilkabilityReportFormEmpty,
    deleteCreatedReportInProgress,
    editCreatedMilkabilityReport,
    deleteMilkabilityReport,
    resetCreatedMilkabilityReports
  } = props;
  const today = useMemo(moment, []);
  const {
    startDate,
    endDate
  } = useMemo(() => getControlInterval(today), [today]);
  const {
    t,
    tCommon
  } = useOurTranslation('milkability/overviewTable');
  const {
    width
  } = useWindowDimensions();
  const useVerticalNames = width < LG;
  const actionSettings = useMemo((): ActionSettings<MilkabilityDetail> => ({
    extraActions: [{
      id: 'delete',
      role: 'delete',
      callback: deleteMilkabilityReport,
      disabled: (_id: number | string, row: MilkabilityDetail): boolean => !isDateWithinTwoControlYears(moment(row.examinationDate, beDateFormat, true), today),
      disabledTitle: t('deleteDisabledTitle', {
        fromYear: startDate.year(),
        toYear: endDate.year()
      })
    }, {
      id: 'edit',
      role: 'edit',
      disabled: (_id: number | string, row: MilkabilityDetail): boolean => !isDateWithinTwoControlYears(moment(row.examinationDate, beDateFormat, true), today),
      callback: editCreatedMilkabilityReport,
      confirmClick: !isMilkabilityReportFormEmpty,
      confirmTitle: t('rewriteMilkabilityReportFormData'),
      disabledTitle: t('editDisabledTitle', {
        fromYear: startDate.year(),
        toYear: endDate.year()
      }),
      confirmPlacement: 'topLeft'
    }]
  }), [deleteMilkabilityReport, editCreatedMilkabilityReport, isMilkabilityReportFormEmpty, t, today, startDate, endDate]);
  const sortedCreatedMilkabilityReports = useMemo(() => orderBy(x => x.id, 'desc', createdMilkabilityReports), [createdMilkabilityReports]);
  return <div className={tableStyles.reportsTable} data-sentry-component="CreatedMilkabilityReportsTableBase" data-sentry-source-file="index.tsx">
            <Table tableId="created-milkability-reports-table" columns={getColumns(useVerticalNames, t, tCommon)} rows={sortedCreatedMilkabilityReports} actionSettings={actionSettings} scroll={{
      x: true
    }} emptyTableMessages={{
      noRows: t('noMilkabilityReports')
    }} loading={deleteCreatedReportInProgress} onUnmount={resetCreatedMilkabilityReports} size="small" data-sentry-element="Table" data-sentry-source-file="index.tsx" />
        </div>;
};
export const CreatedMilkabilityReportsTable = memo(CreatedMilkabilityReportsTableBase);