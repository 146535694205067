import {takeLatestF, putAll, showBeError, downloadFileFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {AssociationType} from 'api/gen/AssociationType';
import {Sex} from 'api/gen/Sex';
import {t} from 'app/translations';
import {getRequestedTableCsvExportParams, simpleTableSelector} from 'utils/tables';

import {ExportMatingAnimalsAction, matingAction} from '../action';

type TableId = 'matingCows' | 'matingHolsteinBulls' | 'matingFleckviehBulls';

const getTableId = (sex: Sex, associationType: AssociationType): TableId => {
    if (sex === 'FEMALE') {
        return 'matingCows';
    } else if (associationType === 'HOLSTEIN') {
        return 'matingHolsteinBulls';
    } else {
        return 'matingFleckviehBulls';
    }
};

function* execute({payload: {sex, associationType}}: ExportMatingAnimalsAction): SagaIterator {
    yield* put(matingAction.setLoading('exportInProgress', true));
    const tableId = getTableId(sex, associationType);
    const tableState = opt(yield* select(simpleTableSelector.tableState(tableId))).orCrash('no tableState');

    const {sort, fieldParams} = getRequestedTableCsvExportParams(tableState);

    const response = yield* call(Api.exportAnimals, fieldParams, {sort}, {sex, associationType});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('common')('downloadCsv')));
    }
    yield* put(matingAction.setLoading('exportInProgress', false));
}

export function* exportMatingAnimalsSaga(): SagaIterator {
    yield takeLatestF('mating/EXPORT_MATING_ANIMALS', execute);
}
