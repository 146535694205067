export const getDiscriminatorName = (discriminator: string): string => {
    switch (discriminator) {
        case 'APP_ROLE':
            return 'AppRole';
        case 'SYS_ADMIN':
            return 'SysAdmin';
        case 'REP_ROLE':
            return 'RepRole';
        default:
            return 'DBRole';
    }
};
