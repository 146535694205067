import React, { ReactNode } from 'react';
import { FarmRow } from 'api/gen/FarmRow';
import { OrganizationCenterRow } from 'api/gen/OrganizationCenterRow';
import { OrganizationTableType } from '../../types/organization-table-type';
interface ExtractorParams<T> {
  accessToAllItems: boolean | null;
  items: Array<T> | null;
}
function getCodeFormat<T extends (FarmRow | OrganizationCenterRow)>(value: string, {
  accessToAllItems,
  items
}: ExtractorParams<T>, extractCode: (item: T) => string): ReactNode {
  const title = items && accessToAllItems ? items.map(extractCode).join(', ') : undefined;
  return <span title={title} data-sentry-component="getCodeFormat" data-sentry-source-file="get-code-format.tsx">{value}</span>;
}
export const getCenterCodeFormat = (value: string, organizationData: OrganizationTableType): ReactNode => getCodeFormat<OrganizationCenterRow>(value, {
  accessToAllItems: organizationData.accessToAllOrganizationCenters,
  items: organizationData.organizationCenters
}, oc => oc.organizationCenterCode);
export const getFarmCodeFormat = (value: string, organizationData: OrganizationTableType): ReactNode => getCodeFormat<FarmRow>(value, {
  accessToAllItems: organizationData.accessToAllFarms,
  items: organizationData.farms
}, f => f.farmCode);