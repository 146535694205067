import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put} from 'typed-redux-saga';

import {formHelpers} from 'utils/forms';

import {matingBullPreferencesFormName} from '../../types/mating-bull-preferences-form-values';
import {ResetScoringResultsAction} from '../action';

function* execute(_: ResetScoringResultsAction): SagaIterator {
    yield* put(formHelpers.reset(matingBullPreferencesFormName));
}

export function* resetScoringResultsSaga(): SagaIterator {
    yield takeLatestF('mating/RESET_SCORING_RESULTS', execute);
}
