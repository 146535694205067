import {
    takeLatestF,
    SuccessResponse,
    putAll,
    showSuccess,
    toPairs,
    extractFormErrorsFromResponse,
} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {AssociationType} from 'api/gen/AssociationType';
import {Bookmark} from 'api/gen/Bookmark';
import {CriteriaListDetails} from 'api/gen/CriteriaListDetails';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';
import {getTableConfig} from 'utils/table-config';
import {simpleTableSelector, tablesAction} from 'utils/tables';

import {matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';
import {matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';
import {matingParamsSelectionFormName} from '../../types/mating-params-selection-form-values';
import {getComputationParamsFormValues, getCriteriaFormValues} from '../../utils/get-criteria-form-values';
import {GetCriteriaListDetailsAction, matingAction} from '../action';
import {matingSelector} from '../selector';
import {getMatingBullsFilterFormValues} from './get-mating-bulls-filter-form-values';
import {getMatingCowsFilterFormValues} from './get-mating-cows-filter-form-values';
import {getMatingTableId} from './get-mating-table-id';

const te = t('mating/sagas');

function* setTableSettings(
    bookmark: Bookmark,
    associationType: AssociationType,
    gridUserSettingsId: number,
): SagaIterator {
    const tableId = getMatingTableId(bookmark, associationType);

    if (tableId) {
        const {serverUserSettingsIds} = getTableConfig(tableId) || {};
        const tableConfig = yield* select(simpleTableSelector.tableConfig(
            serverUserSettingsIds?.module,
            serverUserSettingsIds?.code,
        ));

        const gridUserSettingsName = tableConfig?.gridUserSettings?.find(x => x.id === gridUserSettingsId)?.name;

        yield* put(tablesAction.getTableSettings(tableId, 'LOAD_FROM_CACHE', false, gridUserSettingsName));
    }
}

// eslint-disable-next-line max-lines-per-function
function* changeValues(
    bookmark: Bookmark,
    associationType: AssociationType,
    response: SuccessResponse<CriteriaListDetails>,
): SagaIterator {
    const criteriaListDetails = response.data;

    if (bookmark === 'FEMALE') {
        yield* put(formHelpers.resetSection(matingCowsFilterFormName, 'criterias'));

        const matingCowsFilterFormValues = yield* call(getMatingCowsFilterFormValues);

        yield* put(formHelpers.change(
            matingCowsFilterFormName,
            'criterias',
            getCriteriaFormValues(
                opt(matingCowsFilterFormValues.criterias).orCrash('Wrong data in mating form'),
                criteriaListDetails,
            ),
        ));
        const {gridUserSettingsId} = criteriaListDetails;
        if (gridUserSettingsId) {
            yield* call(setTableSettings, bookmark, associationType, gridUserSettingsId);
            const isCowsSessionEmpty = yield* select(matingSelector.isCowsSessionEmpty);
            if (!isCowsSessionEmpty) yield* put(matingAction.getMatingCows(opt({action: 'refresh'})));
        }
    } else if (bookmark === 'MALE') {
        yield* put(formHelpers.resetSection(matingBullsFilterFormName, 'criterias'));

        const matingBullsFilterFormValues = yield* call(getMatingBullsFilterFormValues);

        yield* put(formHelpers.change(
            matingBullsFilterFormName,
            'criterias',
            getCriteriaFormValues(
                opt(matingBullsFilterFormValues.criterias).orCrash('Wrong data in mating form'),
                criteriaListDetails,
            ),
        ));
        const {gridUserSettingsId} = criteriaListDetails;
        if (gridUserSettingsId) {
            yield* call(setTableSettings, bookmark, associationType, gridUserSettingsId);
            const isBullsSessionEmpty = yield* select(matingSelector.isBullsSessionEmpty);
            const getBulls = associationType === 'FLECKVIEH'
                ? matingAction.getFleckviehMatingBulls
                : matingAction.getHolsteinMatingBulls;
            if (!isBullsSessionEmpty) yield* put(getBulls(opt({action: 'refresh'})));
        }
    } else {
        const matingParamsSelectionFormValues =
            (yield* select(formHelpers.formValues(matingParamsSelectionFormName)))
                .orCrash('no matingParamsSelectionFormValues');
        const updatedFormValues = getComputationParamsFormValues(matingParamsSelectionFormValues, criteriaListDetails);
        const changeActions = toPairs(updatedFormValues)
            .map(([key, value]) => formHelpers.change(matingParamsSelectionFormName, key, value));

        yield putAll(changeActions);
    }
    const title = te('loadCriteriaList');
    yield* put(showSuccess(title, te('criteriaLoaded')));
}

function* execute({payload: {bookmark, criteriaListId, associationType}}: GetCriteriaListDetailsAction): SagaIterator {
    yield* put(formHelpers.startSubmit('getCriteriaListDetails'));
    yield* put(matingAction.setLoading('criteriaListDetails', true));

    const response = yield* call(Api.getCriteriaList, {criteriaListId});

    const title = te('loadCriteriaList');

    if (response.isSuccess) {
        yield* put(matingAction.toggleCriteriaListDetailsVisibility(bookmark));
        yield* put(formHelpers.stopSubmit('getCriteriaListDetails'));
        yield* call(changeValues, bookmark, associationType, response);
    } else {
        yield* put(formHelpers.stopSubmit('getCriteriaListDetails', extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, title));
    }

    yield* put(matingAction.setLoading('criteriaListDetails', false));
}

export function* getCriteriaListDetailsSaga(): SagaIterator {
    yield takeLatestF('mating/GET_CRITERIA_LIST_DETAILS', execute);
}
