import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CreateOrUpdateGridUserSettings } from 'api/gen/CreateOrUpdateGridUserSettings';
import { WriteCriteria } from 'api/gen/WriteCriteria';
import { matingSelector } from 'app/mating/model/selector';
import { useOurTranslation } from 'app/translations';
import { Modal } from 'common/layout/components/modal';
interface Props {
  visible: boolean;
  loading: boolean;
  onSubmit(criteriaList: Array<WriteCriteria>, gridUserSettings: CreateOrUpdateGridUserSettings | null): void;
  onCancel(gridUserSettings: CreateOrUpdateGridUserSettings | null): void;
}
const ModalCriteriaListNameConfirmBase: FC<Props> = ({
  visible,
  onSubmit,
  onCancel,
  loading
}) => {
  const {
    t,
    tCommon
  } = useOurTranslation('mating/modalListName');
  const writeCriteria = useSelector(matingSelector.writeCriteria);
  const gridUserSettings = useSelector(matingSelector.gridUserSettings);
  const listPresent = writeCriteria.orElse([]);
  const handleSubmit = useCallback(() => {
    onSubmit(listPresent, gridUserSettings.orNull());
  }, [gridUserSettings, listPresent, onSubmit]);
  const handleCancel = useCallback(() => {
    onCancel(null);
  }, [onCancel]);
  const footer = useMemo(() => ({
    onSubmit: handleSubmit,
    cancelText: tCommon('cancel'),
    submitText: tCommon('confirm'),
    loading
  }), [handleSubmit, tCommon, loading]);
  return <Modal title={t('confirmListName')} visible={visible} onCancel={handleCancel} defaultFooter={footer} data-sentry-element="Modal" data-sentry-component="ModalCriteriaListNameConfirmBase" data-sentry-source-file="index.tsx">
            {t('listAlreadyExists')}
        </Modal>;
};
export const ModalCriteriaListNameConfirm = memo(ModalCriteriaListNameConfirmBase);