import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetMatingCowsAction, matingAction} from '../action';

const te = t('mating/sagas');

function* execute({payload: {tableDataRequest}}: GetMatingCowsAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(matingAction.getFemaleAnimalsSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('matingCows'));

    const tableState = yield* select(simpleTableSelector.tableState('matingCows'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'matingCows',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
        resetType: 'tableDataParams',
    });

    const columnSettings = getRequestedTableColumnSettings<'matingCows'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.getFemaleHolsteinAnimalsInList, filters, params);

    if (response.isSuccess) {
        const matingFemaleAnimalsPage = response.data;

        yield* put(matingAction.getFemaleAnimalsSuccess(opt(matingFemaleAnimalsPage)));
        yield* put(tablesAction.tableDataFetchSuccess('matingCows', tableDataParams));
    } else {
        yield putAll(showBeError(response, te('loadCowList')));
        yield* put(tablesAction.tableDataFetchFailure('matingCows'));
    }
}

export function* getMatingCowsSaga(): SagaIterator {
    yield takeLatestF('mating/GET_MATING_COWS', execute);
}
