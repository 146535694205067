import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {AnimalType} from 'api/gen/AnimalType';
import {Api} from 'api/gen/Api';
import {replaceHuidAndGetBrowserLink} from 'app/animal-browser/utils/replace-huid-and-get-browser-link';
import {middlewareAction} from 'app/setup/middleware/middleware-action';
import {t} from 'app/translations';
import {TableId} from 'common/types/table-id';
import {showBeError} from 'utils/show-be-error';
import {getRequestedTablePageParams, simpleTableSelector, tablesAction} from 'utils/tables';

import {BrowserType} from '../../types/browser-type';
import {GetMotherHuidAction} from '../action';

const getTableId = (animalType: AnimalType, comprehensive: BrowserType): TableId => {
    switch (animalType) {
        case 'BREEDING_FEMALES':
            return comprehensive === 'COMPREHENSIVE' ? 'comprehensiveBreedingFemale' : 'cowBrowser';
        case 'CALVES':
            return comprehensive === 'COMPREHENSIVE' ? 'comprehensiveCalves' : 'cowBrowser';

        default:
            throw new Error(`Unknown animal type`);
    }
};

function* execute({payload: {earTag, huid, animalType, comprehensive}}: GetMotherHuidAction): SagaIterator {
    const tableId: TableId = getTableId(animalType, comprehensive);

    yield* put(tablesAction.tableDataFetchStart(tableId));

    const tableState = yield* select(simpleTableSelector.tableState(tableId));
    const tableDataParams = getRequestedTablePageParams({
        tableId,
        tableDataRequest: {action: 'refresh'},
        tableState,
    });

    const response = yield* call(Api.getHuid, {earTag});

    if (response.isSuccess) {
        const url = replaceHuidAndGetBrowserLink({
            childHuid: huid,
            earTag,
            huid: response.data.huid,
        });

        yield* put(middlewareAction.openWindow(url, '_blank', 'noopener,noreferrer'));
    } else {
        yield putAll(showBeError(response, t('animalBrowserSagas')('motherLink')));
    }
    yield* put(tablesAction.tableDataFetchSuccess(tableId, tableDataParams));
}

export function* getMotherHuidSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_MOTHER_HUID', execute);
}
