import { FooterButtons, NullableKeys, Options, superUniqueClassName } from '@fl/cmsch-fe-library';
import { isNull } from 'lodash/fp';
import moment from 'moment';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { tCommon, useOurTranslation } from 'app/translations';
import { formHelpers, useForm } from 'utils/forms';
import { userMemberSubjectFormName, UserMemberSubjectForm, userMemberSubjectInitialValues } from '../../types/user-member-form-values';
import { validate } from './validate';
import styles from './styles.sass';
interface Props {
  userProfileId: number;
  data: UserMemberSubjectForm | null;
  subjectOptions: Options<number>;
  onSubmit(): void;
  onCancel(): void;
  onSyncValidate(): void;
  onSubjectSearch(searchText: string): void;
}
const UserMemberFormBase: FC<Props> = ({
  onSubmit,
  onCancel,
  onSubjectSearch,
  onSyncValidate,
  data,
  userProfileId,
  subjectOptions
}) => {
  const dispatch = useDispatch();
  const {
    t
  } = useOurTranslation('admin/users');
  const initialValues = useMemo(() => userMemberSubjectInitialValues(userProfileId), [userProfileId]);
  const {
    Form,
    Fields,
    submitting,
    valid,
    pristine,
    asyncValidating,
    change
  } = useForm({
    form: userMemberSubjectFormName,
    initialValues,
    destroyOnUnmount: true,
    onSubmit,
    validate
  });
  const subjectValueIsEmpty = useSelector(formHelpers.formValues(userMemberSubjectFormName)).prop('subjectId').isEmpty;
  const isEdit = !isNull(data);
  useEffect(() => {
    if (isEdit) {
      const initFormData: NullableKeys<UserMemberSubjectForm> = {
        subjectMemberId: data.subjectMemberId,
        userProfileId,
        approvedFrom: data.approvedFrom,
        approvedTo: data.approvedTo,
        subjectId: data.subjectId
      };
      dispatch(formHelpers.initialize(userMemberSubjectFormName, initFormData));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const approvedToRef = useRef<HTMLInputElement>(null);
  const approveFrom = useSelector(formHelpers.formValues(userMemberSubjectFormName)).prop('approvedFrom').orUndef();
  const approveFromMoment = useMemo(() => moment(approveFrom), [approveFrom]);
  const handleApproveFromChange = useCallback((value: Opt<string>) => {
    onSyncValidate();
    value.onSome(date => {
      const [element] = document.getElementsByClassName(superUniqueClassName);
      element?.classList.remove('ant-picker-focused');
      setTimeout(() => approvedToRef.current?.focus());
      change('approvedFrom', date);
    });
  }, [onSyncValidate, approvedToRef, change]);
  return <Form data-sentry-element="Form" data-sentry-component="UserMemberFormBase" data-sentry-source-file="index.tsx">
            <div className={styles.formContainer}>
                <div className={styles.select}>
                    <Fields.NumberSelect name="subjectId" label={t('subject')} onFieldChange={onSyncValidate} onSearch={onSubjectSearch} isRequired searchable asyncSearch options={subjectOptions} fullWidth ignoreSearchMinLength searchOnMount={!isEdit} disabled={submitting || isEdit} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div>
                    <Fields.DateInput name="approvedFrom" label={t('approvedFrom')} onFieldChange={handleApproveFromChange} disabled={subjectValueIsEmpty || isEdit} isRequired clearable dontIncludePast data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div>
                    <Fields.DateInput name="approvedTo" label={t('approvedTo')} onFieldChange={onSyncValidate} disabled={subjectValueIsEmpty} clearable dontIncludePast inputRef={approvedToRef} minDate={approveFromMoment} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
            </div>
            <FooterButtons submitting={submitting} valid={valid} pristine={pristine} onCancel={onCancel} asyncValidating={asyncValidating} submitTitle={isEdit ? tCommon('edit') : tCommon('add')} data-sentry-element="FooterButtons" data-sentry-source-file="index.tsx" />
        </Form>;
};
export const UserMemberForm = memo(UserMemberFormBase);