import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {MatingCowsFilterFormValues} from '../../types';

const tm = t('mating/matingCowsFilterForm');

export const validate = (values: MatingCowsFilterFormValues): Errors<MatingCowsFilterFormValues> => {
    const validator = new EskotValidator(values);

    validator.nonEmpty('breederId', tm('breeder'));
    validator.nonEmpty('farmIds', tm('farms'));

    return validator.generateErrorsObject();
};
