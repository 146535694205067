import {cloneDeep, isEmpty, sortBy} from 'lodash/fp';

import {ScoringResult} from 'api/gen/ScoringResult';

import {BullPreference} from '../types/bull-counts-and-shares';

const hundredPercent = 100;

interface CowWithBullInfo {
    cowIndex: number;
    bullIndex: number;
    score: number;
}

interface RecalculatedScoringResult extends ScoringResult {
    alreadyRecalculated: boolean;
}

const findBullInfosAvailableToMove = (
    recalculatedScoringResults: Array<RecalculatedScoringResult>,
    bullPreference: BullPreference,
): Array<CowWithBullInfo> => {
    const cowsWithBullInfos: Array<CowWithBullInfo> = [];

    recalculatedScoringResults.forEach((cowRow: RecalculatedScoringResult, cowIndex: number) => {
        const bulls = cowRow.bulls ?? [];
        if (!isEmpty(bulls)) {
            const bullIndex = bulls.findIndex(x => x.lineRegistry === bullPreference.lineRegistry);
            const bull = bulls[bullIndex];

            if (!cowRow.alreadyRecalculated && bullIndex >= 0 && bull) {
                cowsWithBullInfos.push({
                    cowIndex,
                    bullIndex,
                    score: Number(bull.score),
                });
            }
        }
    });

    return cowsWithBullInfos;
};

const moveBullToStartAndLockRow = (cowRow: RecalculatedScoringResult, bullIndex: number): void => {
    const bulls = cowRow.bulls ?? [];
    const [movedBull] = bulls.splice(bullIndex, 1);

    if (movedBull) {
        bulls.unshift(movedBull);
    }

    cowRow.alreadyRecalculated = true;
};

/**
 * For every bull preference sorted ascending by percents does this on the scoring results table:
 *    1. finds all cows with given bull and sorts them descending by the score the bull has for them
 *    2. for first X percent of all cows count (can be more than the count of cows found for the bull)
 *    moves this bull from its position to the first, effectively overtaking bulls with better score
 * @param scoringResults
 * @param bullPreferences
 */
export const getRecalculatedScoringResults = (
    scoringResults: Array<ScoringResult>,
    bullPreferences: Array<BullPreference>,
): Array<ScoringResult> => {
    const scoringResultsCopy = cloneDeep(scoringResults);

    const countOfResultsWithBulls = scoringResultsCopy.filter(({bulls}) => !isEmpty(bulls)).length;
    const percentageOfResultsWithBulls = countOfResultsWithBulls / hundredPercent;

    // Add alreadyRecalculated utility flag
    const recalculatedScoringResults: Array<RecalculatedScoringResult> = scoringResultsCopy.map(scoringResult => ({
        alreadyRecalculated: false,
        earTag: scoringResult.earTag,
        bulls: scoringResult.bulls,
    }));

    const ascendingBullPreferences = sortBy<BullPreference>('percentage')(bullPreferences);

    ascendingBullPreferences.forEach(bullPreference => {
        const availableBullInfos = findBullInfosAvailableToMove(recalculatedScoringResults, bullPreference);
        const sortedCowsWithBullInfo = sortBy<CowWithBullInfo>('score')(availableBullInfos).reverse();
        const numberOfCowsToAdjust = Math.round(percentageOfResultsWithBulls * bullPreference.percentage);
        const slicedCowsWithBullInfo = sortedCowsWithBullInfo.slice(0, numberOfCowsToAdjust);

        slicedCowsWithBullInfo.forEach(({cowIndex, bullIndex}) => {
            const cowRow = recalculatedScoringResults[cowIndex];

            if (cowRow) {
                moveBullToStartAndLockRow(cowRow, bullIndex);
            }
        });
    });

    // Strip alreadyRecalculated utility flag
    return recalculatedScoringResults.map((results): ScoringResult => ({
        earTag: results.earTag,
        bulls: results.bulls,
    }));
};
