import {setItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {initialUserDetailState, UserDetailState} from './state';

const userDetailStateO = O.optic<UserDetailState>();

export const userDetailReducer = (
    state: UserDetailState = initialUserDetailState,
    action: AppAction,
): UserDetailState => {
    switch (action.type) {
        case 'userDetail/SET_EDITED_USER_MEMBER_ID':
            return O.set(userDetailStateO.prop('memberOfSubjectsEditingId'))(action.payload)(state);

        case 'userDetail/SET_MEMBER_OF_SUBJECTS_CREATE_VISIBLE':
            return O.set(userDetailStateO.prop('memberOfSubjectsModalVisible'))(action.payload)(state);

        case 'userDetail/SET_SUBJECTS_OPTIONS':
            return O.set(userDetailStateO.prop('subjectOptions'))(action.payload)(state);

        case 'userDetail/SET_ASSOCIATION_OPTIONS':
            return O.set(userDetailStateO.prop('associationOptions'))(action.payload)(state);

        case 'userDetail/SET_USER_CONTACTS_MODAL_VISIBILITY':
            return O.set(userDetailStateO.prop('userContactsModalVisible'))(action.payload)(state);

        case 'userDetail/SET_ASSOCIATIONS_MODAL_VISIBILE':
            return O.set(userDetailStateO.prop('associationsModalVisible'))(action.payload)(state);

        case 'userDetail/SET_OTHER_SUBJECT_ROLE_OPTIONS':
            return O.set(userDetailStateO.prop('otherSubjectRoleOptions'))(action.payload)(state);

        case 'userDetail/SET_ASSOCIATION_ROLE_OPTIONS':
            return O.set(userDetailStateO.prop('associationRoleOptions'))(action.payload)(state);

        case 'userDetail/SET_BREEDER_ROLE_OPTIONS':
            return O.set(userDetailStateO.prop('breederRoleOptions'))(action.payload)(state);

        case 'userDetail/SET_ORGANIZATION_ROLE_OPTIONS':
            return O.set(userDetailStateO.prop('organizationRoleOptions'))(action.payload)(state);

        case 'userDetail/SET_OTHER_SUBJECTS_OPTIONS':
            return O.set(userDetailStateO.prop('otherSubjectsOptions'))(action.payload)(state);

        case 'userDetail/SET_FACILITY_MODAL_VISIBILE':
            return O.set(userDetailStateO.prop('facilityModalVisible'))(action.payload)(state);

        case 'userDetail/SET_ORGANIZATIONS_OPTIONS':
            return O.set(userDetailStateO.prop('organizationOptions'))(action.payload)(state);

        case 'userDetail/SET_CENTERS_OPTIONS':
            return O.set(userDetailStateO.prop('centerOptions'))(action.payload)(state);

        case 'userDetail/SET_ORGANIZATION_FARM_OPTIONS':
            return O.set(userDetailStateO.prop('organizationFarmOptions'))(action.payload)(state);

        case 'userDetail/SET_BREEDERS':
            return O.set(userDetailStateO.prop('breederOptions'))(action.payload)(state);

        case 'userDetail/SET_FARMS':
            return O.set(userDetailStateO.prop('farmOptions'))(action.payload)(state);

        case 'userDetail/SET_LOADING': {
            const {id, loading} = action.payload;

            return O.modify(userDetailStateO.prop('loading'))(setItem(loading)(id))(state);
        }

        default:
            return state;
    }
};
