import {MatingResult} from 'api/gen/MatingResult';
import {ScoringResult} from 'api/gen/ScoringResult';

import {BullPreference} from '../types/bull-counts-and-shares';
import {getRecalculatedScoringResults} from './get-recalculated-scoring-results';
import {getSortedBullRankCountsFromScoringResults} from './get-sorted-bull-rank-counts-from-scoring-results';
import {getSortedMatingResult} from './get-sorted-mating-result';

// Default bull preferences to recalculate results as requested by customer
// eslint-disable-next-line no-magic-numbers
const defaultBullPreferences = [50, 25, 12, 6, 3, 2, 1];

/*
* algo doc. in https://id.atlassian.com/request-access?site=favorlogic.atlassian.net&resource=
* YXJpOmNsb3VkOmNvbmZsdWVuY2U6OnNpdGUvNGM3NmNkOGYtN2ZiZC00NDZmLWE1MTUtZjQ3NjcyYzQwY2Y5&continue=https%3A%2F%2Ffavorlogic
* .atlassian.net%2Fwiki%2Fspaces%2FCMSCH%2Fpages%2F2313420804%2FMating%20algoritmus
* */
export const getAdjustedMatingScoreResult = (matingResult: MatingResult): MatingResult => {
    const sortedMatingResult = getSortedMatingResult(matingResult);
    // temporary disabled rest of code
    // eslint-disable-next-line no-constant-condition, @typescript-eslint/no-unnecessary-condition
    if (true) {
        return {
            sumOfRelatedPairs: sortedMatingResult.sumOfRelatedPairs,
            results: sortedMatingResult.results,
        };
    }
    const bullRankingsAndCounts = getSortedBullRankCountsFromScoringResults(sortedMatingResult.results);

    const bullPreferences: Array<BullPreference> = [];
    defaultBullPreferences.forEach((percentage, index) => {
        const bullRankingOnIndex = bullRankingsAndCounts.bullRankings[index];
        if (bullRankingOnIndex) {
            bullPreferences.push({
                percentage,
                lineRegistry: bullRankingOnIndex.lineRegistry,
            });
        }
    });

    const recalculatedScoringResults: Array<ScoringResult> =
        getRecalculatedScoringResults(matingResult.results, bullPreferences);

    return {
        sumOfRelatedPairs: matingResult.sumOfRelatedPairs,
        results: recalculatedScoringResults,
    };
};
