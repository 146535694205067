import {takeLatestF, putAll, showBeError, downloadFileFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {getRequestedTableCsvExportParams, simpleTableSelector} from 'utils/tables';

import {ExportCabAnimalsAction, animalBrowserAction} from '../action';

function* execute({payload: {animalType}}: ExportCabAnimalsAction): SagaIterator {
    yield* put(animalBrowserAction.setLoading('exportInProgress', true));
    const tableId = animalType === 'BREEDING_FEMALES' ? 'comprehensiveBreedingFemale' : 'comprehensiveCalves';
    const tableState = opt(yield* select(simpleTableSelector.tableState(tableId))).orCrash('no tableState');

    const {sort, fieldParams} = getRequestedTableCsvExportParams(tableState);

    const response = yield* call(Api.exportCabAnimals, fieldParams, {sort}, {animalType});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('common')('downloadCsv')));
    }
    yield* put(animalBrowserAction.setLoading('exportInProgress', false));
}

export function* exportCabAnimalsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/EXPORT_CAB_ANIMALS', execute);
}
