import {listNameMaxLength} from 'api/gen/ListName';
import {safeCharactersRegexGen} from 'api/gen/SafeCharacters';
import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {ExportAnimalsFormValues} from './export-animals-form-values';

const te = t('mating/modalExportAnimals');
const tModals = t('animalListModals');

export const getValidate = (isAnimalsPageEmpty: boolean) =>
    (values: ExportAnimalsFormValues): Errors<ExportAnimalsFormValues> => {
        const validator = new EskotValidator(values);

        validator.nonEmpty('exportAnimalsInList', tModals('name'));
        validator.pattern('exportAnimalsInList', safeCharactersRegexGen(), tModals('name'));
        validator.maxStringLength('exportAnimalsInList', listNameMaxLength, tModals('name'));
        const errors = validator.generateErrorsObject();
        if (isAnimalsPageEmpty) {
            errors.exportAnimalsInList = te('atLeastOneAnimal');
        }

        return errors;
    };
