import {TableColumn} from '@fl/cmsch-fe-library';

import {RolePermission} from 'api/gen/RolePermission';

export const getPermissionTableColumns: Array<TableColumn<RolePermission>> = [
    {
        field: 'code',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'controllerName',
        type: 'string',
    },
    {
        field: 'actionName',
        type: 'string',
    },
    {
        field: 'comment',
        type: 'string',
        filterTypes: ['null'],
    },
];
