import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse, NullableKeys} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {genNakedPropOrCrash, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {CreateSubjectMember} from 'api/gen/CreateSubjectMember';
import {t} from 'app/translations';
import {userDetailAction} from 'app/user-detail';
import {UserMemberSubjectForm, userMemberSubjectFormName} from 'app/user-detail/types/user-member-form-values';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {adminAction, CreateUserMembershipAction} from '../action';

const te = t('admin/sagas');

const buildValuesForApi = (formValues: NullableKeys<UserMemberSubjectForm>): CreateSubjectMember => {
    const get = genNakedPropOrCrash(formValues);

    return {
        approvedFrom: get('approvedFrom'),
        approvedTo: opt(formValues).prop('approvedTo').orNull(),
        subjectId: get('subjectId'),
        userProfileId: get('userProfileId'),
    };
};

function* execute(_: CreateUserMembershipAction): SagaIterator {
    const formValue = yield* select(state => formHelpers.formValues(userMemberSubjectFormName)(state)
        .orCrash('no role form values'));
    yield* put(formHelpers.startSubmit(userMemberSubjectFormName));

    const response = yield* call(Api.assignUserToSubject, buildValuesForApi(formValue));

    const title = te('createUserMembership');

    if (response.isSuccess) {
        const userProfileId = opt(formValue.userProfileId).orCrash('No userProfileId found');
        yield* put(adminAction.getMemberOfSubjects(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('userMembershipCreated'), false, 'createMemberOfSubject'));
        yield* put(userDetailAction.setEditedUserMemberId(null));
        yield* put(userDetailAction.setMemberOfSubjectsModalVisible(false));
        yield* put(formHelpers.reset(userMemberSubjectFormName));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(userMemberSubjectFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* createUserMembershipSaga(): SagaIterator {
    yield takeLatestF('admin/CREATE_USER_MEMBERSHIP', execute);
}
