import {t} from 'app/translations';

export const lineBullCount = 5;
export const csvColumnSeparator = ';';
export const csvNewLine = '\n';

export const buildCsvHeader = (): string => {
    const headerStringArray: Array<string> = [t('mating/matingCowsTable')('earTag')];

    const tm = t('mating/resultTab');
    for (let i = 0; i < lineBullCount; i++) {
        headerStringArray.push(
            `${t('mating/matingBullsTable')('lineRegistry')} (${i + 1})`,
            `${t('mating/matingBullsTable')('name')} (${i + 1})`,
            `${tm('score')} (${i + 1})`,
            `${tm('bullInbreeding')} (${i + 1})`,
        );
    }

    return headerStringArray
        .join(csvColumnSeparator)
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');
};
