import {calculateApmvSaga} from './calculate-apmv-saga';
import {createMilkabilityReportSaga} from './create-milkability-report-saga';
import {deleteMilkabilityReportSaga} from './delete-milkability-report-saga';
import {exportMilkabilityReportsSaga} from './export-milkability-reports-saga';
import {getMilkabilityReportsSaga} from './get-milkability-reports-saga';
import {loadMilkabilityReportDetailSaga} from './load-milkability-report-detail-saga';
import {validateMilkabilityDataSaga} from './validate-milkability-data-saga';

export const milkabilitySagas = [
    getMilkabilityReportsSaga,
    exportMilkabilityReportsSaga,
    deleteMilkabilityReportSaga,
    validateMilkabilityDataSaga,
    createMilkabilityReportSaga,
    calculateApmvSaga,
    loadMilkabilityReportDetailSaga,
];
