import { KeysOfType, Nullable, TypedFormFieldComponents } from '@fl/cmsch-fe-library';
import { isNil } from 'lodash/fp';
import React, { Fragment, ReactNode } from 'react';
import { map, Opt } from 'ts-opt';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { inbreedingMaximum, inbreedingMinimum, InbreedingSchema } from 'api/gen/Inbreeding';
import { scoringTraitWeightMaximum, scoringTraitWeightMinimum } from 'api/gen/ScoringTraitWeight';
import { MatingParamsSelectionFormValues } from 'app/mating/types/mating-params-selection-form-values';
import { NullableNumberPair } from 'app/mating/types/nullable-number-pair';
import { TFunction } from 'app/translations';
import { Ant } from 'common/ant';
import { ICONS } from 'common/icons';
import { isWholeNumber } from 'utils/is-whole-number';
import styles from './styles.sass';
type ModuleName = 'mating/matingParamsSelectionForm';
type FormSliders = (criteriaDefinitions: Opt<Array<CriteriaDefinition>>, Fields: TypedFormFieldComponents<MatingParamsSelectionFormValues>, t: TFunction<ModuleName>) => ReactNode;
export const validateInbreeding = (t: TFunction<ModuleName>) => (value: number | null | undefined): string | undefined => {
  if (isNil(value)) return t('mustBeFilledIn');
  if (value <= inbreedingMinimum || value >= inbreedingMaximum) {
    return t('inbreedingCoefficientOutsideAllowedRange');
  }
  if (!InbreedingSchema.is(value)) return t('wrongCoefficientFormat');
  return undefined;
};
export const validateScoringWeight = (t: TFunction<ModuleName>) => (value: number | null | undefined): string | undefined => {
  if (isNil(value)) return t('mustBeFilledIn');
  if (value < scoringTraitWeightMinimum || value > scoringTraitWeightMaximum) {
    return t('weightOutsideAllowedRange');
  }
  if (!isWholeNumber(value)) return t('mustBeWholeNumber');
  return undefined;
};
export const validateTraitIdealWeight = (t: TFunction<ModuleName>) => (value: NullableNumberPair | null | undefined): string | undefined => {
  if (isNil(value) || value[1] === null) return t('mustBeFilledIn');
  if (value[1] < scoringTraitWeightMinimum || value[1] > scoringTraitWeightMaximum) {
    return t('idealWeightOutsideAllowedRange');
  }
  if (!isWholeNumber(value[1])) return t('mustBeWholeNumber');
  return undefined;
};
const exteriorTraitWeightsFieldTypes: Array<KeysOfType<MatingParamsSelectionFormValues, Nullable<NullableNumberPair>>> = ['TRA', 'SHR', 'HLT', 'HRA', 'SKZ', 'SRZ', 'PZZ', 'PZB', 'PAZ', 'PUV', 'RPS', 'DST', 'HLV', 'VZU', 'ZAV', 'RZS', 'SZU', 'KVK', 'CHO', 'KND'];
export const initialValues: MatingParamsSelectionFormValues = {
  RxyMaxU: null,
  VhMlk: null,
  VhTuk: null,
  VhBlk: null,
  VhSB: null,
  VhKM: null,
  VhOCD: null,
  VhDlh: null,
  VhOOP1: null,
  VhDoj: null,
  VhPld: null,
  TRA: null,
  SHR: null,
  HLT: null,
  HRA: null,
  SKZ: null,
  SRZ: null,
  PZZ: null,
  PZB: null,
  PAZ: null,
  PUV: null,
  RPS: null,
  DST: null,
  HLV: null,
  VZU: null,
  ZAV: null,
  RZS: null,
  SZU: null,
  KVK: null,
  CHO: null,
  KND: null,
  VhProd: null,
  VhExt: null,
  VhFnkcn: null,
  VhZdr: null
};

// eslint-disable-next-line no-magic-numbers
export const formSectionsRowGutter: [number, number] = [0, 32];
// eslint-disable-next-line no-magic-numbers
export const actionPanelRowGutter: [number, number] = [8, 8];
const span7Offset1 = {
  span: 7,
  offset: 1
};
const span8Offset11 = {
  span: 8,
  offset: 11
};
const span8Offset16 = {
  span: 8,
  offset: 16
};
const span11 = {
  span: 11
};
const span13 = {
  span: 13
};
const span20 = {
  span: 20
};
const span24 = {
  span: 24
};
export const labelCol = {
  xs: span24,
  sm: span11
};
export const wrapperCol = {
  xs: span24,
  sm: span13
};
const getFieldLabel = (fieldCode: string, criteriaDefinitions: Opt<Array<CriteriaDefinition>>, t: TFunction<ModuleName>): string => criteriaDefinitions.findIn(x => x.code === fieldCode).map(x => x.name).orElse(`${t('code')} ${fieldCode}`);
const renderCoefficientField = (fieldCode: KeysOfType<MatingParamsSelectionFormValues, Nullable<number>>, criteriaDefinitions: Opt<Array<CriteriaDefinition>>, Fields: TypedFormFieldComponents<MatingParamsSelectionFormValues>, t: TFunction<ModuleName>): ReactNode => <Fields.NumberInput label={getFieldLabel(fieldCode, criteriaDefinitions, t)} name={fieldCode} step={0.001} labelCol={{
  xs: span24,
  sm: span20
}} validate={validateInbreeding(t)} data-sentry-element="unknown" data-sentry-component="renderCoefficientField" data-sentry-source-file="params-helpers.tsx" />;
const renderScoringWeightSliderField = (fieldCode: KeysOfType<MatingParamsSelectionFormValues, Nullable<number>>, disabled: boolean, criteriaDefinitions: Opt<Array<CriteriaDefinition>>, Fields: TypedFormFieldComponents<MatingParamsSelectionFormValues>, t: TFunction<ModuleName>): ReactNode => <Fields.NumberWithSlider label={getFieldLabel(fieldCode, criteriaDefinitions, t)} name={fieldCode} minValue={scoringTraitWeightMinimum} maxValue={scoringTraitWeightMaximum} step={1} hideHelpInitially validate={validateScoringWeight(t)} disabled={disabled} data-sentry-element="unknown" data-sentry-component="renderScoringWeightSliderField" data-sentry-source-file="params-helpers.tsx" />;
const renderExteriorTraitWeightsField = (criteriaDefinitions: Opt<Array<CriteriaDefinition>>, Fields: TypedFormFieldComponents<MatingParamsSelectionFormValues>, t: TFunction<ModuleName>) => (fieldCode: KeysOfType<MatingParamsSelectionFormValues, Nullable<NullableNumberPair>>): ReactNode => <Fields.NumberPairWithSlider key={fieldCode} label={getFieldLabel(fieldCode, criteriaDefinitions, t)} name={fieldCode} minValue={scoringTraitWeightMinimum} maxValue={scoringTraitWeightMaximum} step={1} hideHelpInitially validate={validateTraitIdealWeight(t)} />;
export const submitButtonLabel = (valid: boolean, animalsAvailableInSession: boolean, t: TFunction<ModuleName>): string | undefined => {
  if (!valid) return t('formInvalid');else if (!animalsAvailableInSession) return t('noBullsOrCowsSelected');
  return;
};
const productionSignsWeightBlock: FormSliders = (criteriaDefinitions, Fields, t) => <Fragment>
        <h5 className="text-center">{t('productionSignsWeight')}</h5>
        {renderScoringWeightSliderField('VhMlk', false, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhTuk', false, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhBlk', false, criteriaDefinitions, Fields, t)}
    </Fragment>;
const medicalSignsWeightBlock: FormSliders = (criteriaDefinitions, Fields, t) => <span className={styles.section} data-sentry-component="medicalSignsWeightBlock" data-sentry-source-file="params-helpers.tsx">
        <h5 className="text-center mt-3">{t('medicalSignsWeight')}</h5>
        {renderScoringWeightSliderField('VhSB', true, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhKM', true, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhOCD', true, criteriaDefinitions, Fields, t)}
    </span>;
const functionalSignsWeightBlock: FormSliders = (criteriaDefinitions, Fields, t) => <span className={styles.section} data-sentry-component="functionalSignsWeightBlock" data-sentry-source-file="params-helpers.tsx">
        <h5 className="text-center mt-3">{t('functionalSignsWeight')}</h5>
        {renderScoringWeightSliderField('VhDoj', true, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhPld', true, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhDlh', true, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhOOP1', true, criteriaDefinitions, Fields, t)}
    </span>;
export const leftFormSide: FormSliders = (criteriaDefinitions, Fields, t) => <Ant.Col xs={span24} lg={8} data-sentry-element="unknown" data-sentry-component="leftFormSide" data-sentry-source-file="params-helpers.tsx">
        {renderCoefficientField('RxyMaxU', criteriaDefinitions, Fields, t)}
        {productionSignsWeightBlock(criteriaDefinitions, Fields, t)}
        {medicalSignsWeightBlock(criteriaDefinitions, Fields, t)}
        {functionalSignsWeightBlock(criteriaDefinitions, Fields, t)}
    </Ant.Col>;
export const middleFormArea: FormSliders = (criteriaDefinitions, Fields, t) => <Ant.Col xs={span24} lg={span7Offset1} data-sentry-element="unknown" data-sentry-component="middleFormArea" data-sentry-source-file="params-helpers.tsx">
        <Ant.Row data-sentry-element="unknown" data-sentry-source-file="params-helpers.tsx">
            <Ant.Col span={10} data-sentry-element="unknown" data-sentry-source-file="params-helpers.tsx">
                {t('inPoints')}
                <div className={styles.tooltip}>
                    <Ant.Tooltip title={t('upToFourExterior')} data-sentry-element="unknown" data-sentry-source-file="params-helpers.tsx">{ICONS.questionCircleOutlined}</Ant.Tooltip>
                </div>
            </Ant.Col>
            <Ant.Col span={14} data-sentry-element="unknown" data-sentry-source-file="params-helpers.tsx">
                <Ant.Row data-sentry-element="unknown" data-sentry-source-file="params-helpers.tsx">
                    <Ant.Col xs={span8Offset11} sm={span8Offset16} data-sentry-element="unknown" data-sentry-source-file="params-helpers.tsx">
                        <h6 className="text-left text-lg-center">{t('signWeight')}</h6>
                    </Ant.Col>
                </Ant.Row>
            </Ant.Col>
        </Ant.Row>
        {map(renderExteriorTraitWeightsField(criteriaDefinitions, Fields, t))(exteriorTraitWeightsFieldTypes)}
    </Ant.Col>;
export const rightFormSide: FormSliders = (criteriaDefinitions, Fields, t) => <Ant.Col xs={span24} lg={span7Offset1} data-sentry-element="unknown" data-sentry-component="rightFormSide" data-sentry-source-file="params-helpers.tsx">
        <h5 className="text-center">{t('shareOfSignsInTotalScore')}</h5>
        {renderScoringWeightSliderField('VhProd', false, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhExt', false, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhZdr', true, criteriaDefinitions, Fields, t)}
        {renderScoringWeightSliderField('VhFnkcn', true, criteriaDefinitions, Fields, t)}
    </Ant.Col>;