import { BasicInput } from '@fl/cmsch-fe-library';
import React, { useState, memo, useMemo, useCallback, FC, KeyboardEvent, ChangeEvent } from 'react';
import { Opt, none, opt } from 'ts-opt';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Modal } from 'common/layout/components/modal';
interface Props {
  visible: boolean;
  onSubmit(fileName: string): void;
  onCancel(): void;
}
const maxFilenameLength = 100;
const defaultName: Opt<string> = none;
const ModalSaveScoringResultsBase: FC<Props> = props => {
  const {
    visible,
    onSubmit,
    onCancel
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('mating/modalSaveScoringResults');
  const [fileName, setFileName] = useState<Opt<string>>(defaultName);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const isInvalidLength = fileName.orElse('').length > maxFilenameLength;
  const isError = touched && !isValid || isInvalidLength;
  const helpMessage: string = useMemo(() => isInvalidLength ? t('maxFileNameLength') : t('enterFileName'), [isInvalidLength, t]);
  const handleSubmit = useCallback(() => {
    if (!isError) {
      fileName.onSome(fileNamePresent => {
        onSubmit(fileNamePresent);
        setFileName(defaultName);
        setIsValid(false);
        setTouched(false);
      }).orCrash('should be able to send list name');
    }
  }, [isError, fileName, onSubmit]);
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = opt(event.target.value);
    if (!touched) setTouched(true);
    setFileName(value);
    setIsValid(value.nonEmpty && value.orElse('').length <= maxFilenameLength);
  }, [touched]);
  const handleKeyUp = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') handleSubmit();
  }, [handleSubmit]);
  const handleCancel = useCallback(() => {
    setFileName(defaultName);
    setIsValid(false);
    setTouched(false);
    onCancel();
  }, [onCancel]);
  return <div onKeyUp={handleKeyUp} data-sentry-component="ModalSaveScoringResultsBase" data-sentry-source-file="index.tsx">
            <Modal title={t('enterFileNameForMatingPlan')} visible={visible} onCancel={handleCancel} defaultFooter={{
      submitText: tCommon('submit'),
      cancelText: tCommon('cancel'),
      onSubmit: handleSubmit,
      disabled: !isValid
    }} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
                <Ant.Form.Item validateStatus={isError ? 'error' : undefined} hasFeedback help={isError ? helpMessage : null} data-test-id="field-scoringResultsFileName" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <BasicInput type="text" value={fileName} onFieldChange={handleChange} autoFocus data-sentry-element="BasicInput" data-sentry-source-file="index.tsx" />
                </Ant.Form.Item>
            </Modal>
        </div>;
};
export const ModalSaveScoringResults = memo(ModalSaveScoringResultsBase);