import {NullableKeys, TripleAttributeValue} from '@fl/cmsch-fe-library';

import {CorrectionType} from 'api/gen/CorrectionType';
import {Date} from 'api/gen/Date';

export const apcReportFormName = 'apcReport';

export type ApcReportFormValues = NullableKeys<{
    correctionType: CorrectionType,
    technicianNumber: string,
    earTag: string,
    stableNumber: string,
    proficiencyTestDate: Date,
    updatedEarTag: string,
    milkingTripleAttribute: TripleAttributeValue,
    milkKg: number,
    fatPercentage: number,
    proteinPercentage: number,
    lactosePercentage: number,
    somaticCells: number,
    urea: number,
}>;

export const initialApcReportFormValues: ApcReportFormValues = {
    correctionType: 'UPDATE',
    technicianNumber: null,
    earTag: null,
    stableNumber: null,
    proficiencyTestDate: null,
    updatedEarTag: null,
    milkingTripleAttribute: null,
    milkKg: null,
    fatPercentage: null,
    proteinPercentage: null,
    lactosePercentage: null,
    somaticCells: null,
    urea: null,
};
