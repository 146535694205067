import React, { memo, FC, useMemo, Fragment, ReactNode, useCallback } from 'react';
import { Opt, isEmpty } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { useOurTranslation } from 'app/translations';
import { ListRow } from './list-row';
import { SectionHeader } from './section-header';
interface Props {
  showSectionTitle: boolean;
  animalLists: Opt<Array<AnimalList>>;
  emptyList: ReactNode;
  listHeader: ReactNode;
  listAlreadyExistsMessage: string;
  areYouSureToDeleteMessage: string;
  renameAnimalList?(id: number, name: string): void;
  deleteAnimalList?(animalListId: number): void;
}
const AnimalListSectionBase: FC<Props> = props => {
  const {
    showSectionTitle,
    animalLists,
    listHeader,
    emptyList,
    listAlreadyExistsMessage,
    areYouSureToDeleteMessage,
    renameAnimalList,
    deleteAnimalList
  } = props;
  const {
    t
  } = useOurTranslation('animalListModals');
  const animalListsPresent: Array<AnimalList> = useMemo(() => animalLists.orElse([]), [animalLists]);
  const handleRenameAnimalList = useCallback((id: number, name: string) => renameAnimalList?.(id, name), [renameAnimalList]);
  const handleDeleteAnimalList = useCallback((animalListId: number) => deleteAnimalList?.(animalListId), [deleteAnimalList]);
  const animalListBody = useMemo(() => animalListsPresent.map(item => <ListRow item={item} list={animalListsPresent} key={item.id} editList={handleRenameAnimalList} deleteList={handleDeleteAnimalList} listAlreadyExistsMessage={listAlreadyExistsMessage} areYouSureToDeleteMessage={areYouSureToDeleteMessage} />), [animalListsPresent, listAlreadyExistsMessage, areYouSureToDeleteMessage, handleDeleteAnimalList, handleRenameAnimalList]);
  return <Fragment>
            {showSectionTitle && <SectionHeader title={t('animalLists')} />}
            {isEmpty(animalListsPresent) ? emptyList : <Fragment>
                    {listHeader}
                    {animalListBody}
                </Fragment>}
        </Fragment>;
};
export const AnimalListSection = memo(AnimalListSectionBase);