import {ActionSettings, Options, TableDataRequest, ToolbarButton, debounceTime} from '@fl/cmsch-fe-library';
import {debounce, isEmpty} from 'lodash/fp';
import {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {BasicRole} from 'api/gen/BasicRole';
import {Farm} from 'api/gen/Farm';
import {Organization} from 'api/gen/Organization';
import {OrganizationCenter} from 'api/gen/OrganizationCenter';
import {PagedModelOrganizationRole} from 'api/gen/PagedModelOrganizationRole';
import {State} from 'app/setup';
import {TFunction, useOurTranslation} from 'app/translations';
import {Errors} from 'utils/validator';

import {createValidate} from '../components/organizations-tab/validate';
import {allOptionValue, defaultProps, thirdLevel} from '../constants/consts';
import {userDetailAction} from '../model/action';
import {
    userOrganizationOptionsSelector,
    userCenterOptionsSelector,
    simpleUserDetailSelector,
    organizationRoleOptionsSelector,
    userOrganizationFarmOptionsSelector,
} from '../model/selector';
import {CreateOrEditOrNull} from '../types/create-or-edit-or-null';
import {CentersSettings, FarmsSettings} from '../types/farms-settings';
import {OrganizationTableType} from '../types/organization-table-type';
import {OrganizationsTabProps} from '../types/organizations-tab-props';
import {SubjectFacilityFormEntryData, SubjectFacilityFormValues} from '../types/subject-facility-form-values';
import {getMatchingApprovedValues} from '../utils/get-matching-approved-values';

const orgForm = 'organizations';

interface UseOrganizationsTabSetup {
    headerButtons: Array<ToolbarButton>;
    actionSettings: ActionSettings<OrganizationTableType>;
    visibleModal: string | null;
    roleOptions: Options<number>;
    organizationOptions: Options<number>;
    formDataForInit: SubjectFacilityFormEntryData | null;
    actionMode: CreateOrEditOrNull;
    rowForEdit: OrganizationTableType | null;
    orgForm: string;
    userProfileId: number;
    isFromAdminModule: boolean;
    pathnamesForRefresh: Array<RegExp>;
    farmsSettings: FarmsSettings;
    centersSettings: CentersSettings;
    t: TFunction<'userDetail'>;
    handleOpenCreateModal(): void;
    getOrganizations(tableDataRequest: Opt<TableDataRequest<OrganizationTableType>>): void;
    handleSubmitModal(): void;
    handleOrganizationSearch(searchText: string): void;
    handleCloseModal(): void;
    validateAsync(): void;
    validate(values: SubjectFacilityFormValues): Errors<SubjectFacilityFormValues>;
    organizationsSelector(state: State): Opt<PagedModelOrganizationRole>;}

const getFormDataForInit = (
    rowForEdit: OrganizationTableType,
    userProfileId: number,
): SubjectFacilityFormEntryData => {
    const matchingApprovedValues = getMatchingApprovedValues(rowForEdit);

    return {
        userProfileId,
        subjectId: rowForEdit.organizationId,
        userProfileRoleIds: rowForEdit.userProfileOrganizationRoleIds,
        roleIds: rowForEdit.roleIds,
        centerIds: rowForEdit.accessToAllOrganizationCenters
            ? [allOptionValue]
            : rowForEdit.organizationCenterIds,
        farmIds: rowForEdit.accessToAllFarms ? [allOptionValue] : rowForEdit.farmIds,
        approvedFrom: matchingApprovedValues.approvedFrom,
        approvedTo: matchingApprovedValues.approvedTo,
    };
};

// eslint-disable-next-line max-lines-per-function
export const useOrganizationsTabSetup = ({
    getTableData,
    pathnamesForRefresh,
    selector,
    adminProps,
}: OrganizationsTabProps): UseOrganizationsTabSetup => {
    const isFromAdminModule = !isEmpty(adminProps);
    const {
        canUpdate,
        userProfileId,
        createEntry,
        updateEntry,
    } = isFromAdminModule ? adminProps : defaultProps;

    const dispatch = useDispatch();
    const {t} = useOurTranslation('userDetail');

    const [actionMode, setActionMode] = useState<CreateOrEditOrNull>(null);
    const [rowForEdit, setRowForEdit] = useState<OrganizationTableType | null>(null);

    const visibleModal = useSelector(simpleUserDetailSelector.facilityModalVisible);
    const roleOptions = useSelector(organizationRoleOptionsSelector);
    const organizationOptions = useSelector(userOrganizationOptionsSelector);
    const centerOptions = useSelector(userCenterOptionsSelector);
    const farmOptions = useSelector(userOrganizationFarmOptionsSelector);

    const centersAreEmpty = useMemo(() => isEmpty(centerOptions), [centerOptions]);
    const farmsAreEmpty = useMemo(() => isEmpty(farmOptions), [farmOptions]);

    const farmsRequired = useMemo(() => centersAreEmpty && !farmsAreEmpty, [centersAreEmpty, farmsAreEmpty]);
    const centersRequired = useMemo(() => farmsAreEmpty && !centersAreEmpty, [centersAreEmpty, farmsAreEmpty]);

    const validate = useMemo(() => createValidate({
        centersRequired,
        farmsRequired,
        isEdit: rowForEdit !== null,
    }), [centersRequired, farmsRequired, rowForEdit]);

    const formDataForInit = useMemo(() => {
        if (rowForEdit && userProfileId) {
            return getFormDataForInit(rowForEdit, userProfileId);
        }
        return null;
    }, [rowForEdit, userProfileId]);

    // eslint-disable-next-line max-lines-per-function
    const handleOpenEditModal = useCallback((_rowId: string, row: OrganizationTableType) => {
        const organizations: Array<Organization> = [
            {
                id: row.organizationId,
                name: row.organizationName,
                code: row.organizationCode,
            },
        ];
        const centers: Array<OrganizationCenter> = row.roles.flatMap(
            role => role.organizationCenters?.map(oc => ({
                id: oc.organizationCenterId,
                code: oc.organizationCenterCode,
                name: null,
            })) ?? [],
        );

        const farms: Array<Farm> = row.roles.flatMap(
            role => role.farms?.map(f => ({
                id: f.farmId,
                code: f.farmCode,
                name: f.farmName || '',
            })) ?? [],
        );

        const roles: Array<BasicRole> = row.roles.map((r): BasicRole => ({
            id: r.roleId,
            name: r.roleName,
            code: r.roleCode,
        }));
        setActionMode('edit');
        dispatch(userDetailAction.setFacilityModalVisible(orgForm));
        dispatch(userDetailAction.setOrganizationOptions(organizations));
        dispatch(userDetailAction.setCentersOptions(centers));
        dispatch(userDetailAction.setOrganizationFarmOptions(farms));
        dispatch(userDetailAction.setOrganizationRoleOptions(roles));
        setRowForEdit(row);
    }, [dispatch]);

    const handleOpenCreateModal = useCallback(() => {
        setActionMode('create');
        dispatch(userDetailAction.setFacilityModalVisible(orgForm));
        setRowForEdit(null);
    }, [dispatch]);

    const handleCloseModal = useCallback(() => {
        setActionMode(null);
        dispatch(userDetailAction.setFacilityModalVisible(null));
        dispatch(userDetailAction.setOrganizationOptions([]));
        dispatch(userDetailAction.setOrganizationRoleOptions([]));
        dispatch(userDetailAction.setCentersOptions([]));
        dispatch(userDetailAction.setOrganizationFarmOptions([]));
        setRowForEdit(null);
    }, [dispatch]);

    const headerButtons: Array<ToolbarButton> = useMemo(() => isFromAdminModule ? [
        {
            id: 'createRole',
            role: 'create',
            onClick: handleOpenCreateModal,
            title: t('organization'),
            hidden: !canUpdate,
        },
    ] : [], [handleOpenCreateModal, t, canUpdate, isFromAdminModule]);

    const actionSettings: ActionSettings<OrganizationTableType> = useMemo(() => canUpdate ? ({
        extraActions: [
            {
                id: 'edit',
                role: 'edit',
                callback: handleOpenEditModal,
                hidden: (_id, row) => row.level === thirdLevel,
            },
        ],
    }) : {}, [handleOpenEditModal, canUpdate]);

    const handleSubmitModal = useCallback(() => actionMode === 'edit' ? updateEntry?.() : createEntry?.(),
        [actionMode, createEntry, updateEntry]);

    const handleOrganizationSearch = useCallback((searchText: string): void => {
        dispatch(userDetailAction.searchOrganizations(searchText));
    }, [dispatch]);

    const getOrganizationCenters = useCallback((organizationId: Opt<number>): void => {
        organizationId.onSome(id => dispatch(userDetailAction.getOrganizationCenters(id)));
    }, [dispatch]);

    const getOrganizationFarms = useCallback((organizationId: Opt<number>): void => {
        organizationId.onSome(id => dispatch(userDetailAction.getOrganizationFarms(id)));
    }, [dispatch]);

    const validateData = useCallback(() => {
        dispatch(userDetailAction.validateOrganizations());
    }, [dispatch]);

    const validateDebounced = useMemo(() => debounce(debounceTime)(validateData), [validateData]);

    const farmsSettings: FarmsSettings = useMemo(() => ({
        allFarmTitle: t('allFarms'),
        farmOptions,
        farmsAreEmpty,
        isRequired: farmsRequired,
        farmTitle: t('farms'),
        getFarms: getOrganizationFarms,
    }), [farmsAreEmpty, farmsRequired, farmOptions, getOrganizationFarms, t]);

    const centersSettings: CentersSettings = useMemo(() => ({
        allCenterTitle: t('allCenters'),
        centerOptions,
        centersAreEmpty,
        isRequired: centersRequired,
        centerTitle: t('centers'),
        getCenters: getOrganizationCenters,
    }), [centersAreEmpty, centerOptions, centersRequired, t, getOrganizationCenters]);

    return {
        getOrganizations: getTableData,
        organizationsSelector: selector,
        handleSubmitModal,
        handleOrganizationSearch,
        handleOpenCreateModal,
        userProfileId,
        handleCloseModal,
        headerButtons,
        actionSettings,
        visibleModal,
        roleOptions,
        organizationOptions,
        formDataForInit,
        actionMode,
        rowForEdit,
        orgForm,
        t,
        validateAsync: validateDebounced,
        validate,
        isFromAdminModule,
        pathnamesForRefresh,
        farmsSettings,
        centersSettings,
    };
};
