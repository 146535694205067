import {Opt} from 'ts-opt';

import {NavigationRestriction} from 'app/navigation';

export const userHasAccessComputation = (
    currentUserPermissions: Opt<Array<string>>,
    currentPathRestriction: NavigationRestriction | undefined,
): boolean => {
    const pathPermissions = currentPathRestriction?.permissions;

    // eslint-disable-next-line @fl/use-eta-reduction
    return !pathPermissions || currentUserPermissions.existsIn(p => pathPermissions.includes(p)).orFalse();
};
