import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {Accordion} from 'api/gen/Accordion';
import {AnimalList} from 'api/gen/AnimalList';
import {AssociationType} from 'api/gen/AssociationType';
import {AvailableAnimal} from 'api/gen/AvailableAnimal';
import {Bookmark} from 'api/gen/Bookmark';
import {BreederToChoose} from 'api/gen/BreederToChoose';
import {CreateOrUpdateGridUserSettings} from 'api/gen/CreateOrUpdateGridUserSettings';
import {CriteriaDefinition} from 'api/gen/CriteriaDefinition';
import {CriteriaList} from 'api/gen/CriteriaList';
import {FarmToChoose} from 'api/gen/FarmToChoose';
import {FemaleHolsteinAnimalInList} from 'api/gen/FemaleHolsteinAnimalInList';
import {MaleFleckviehAnimalInList} from 'api/gen/MaleFleckviehAnimalInList';
import {MaleHolsteinAnimalInList} from 'api/gen/MaleHolsteinAnimalInList';
import {MatingResult} from 'api/gen/MatingResult';
import {OrganizationToChoose} from 'api/gen/OrganizationToChoose';
import {PagedModelFemaleHolsteinAnimalInList} from 'api/gen/PagedModelFemaleHolsteinAnimalInList';
import {PagedModelMaleFleckviehAnimalInList} from 'api/gen/PagedModelMaleFleckviehAnimalInList';
import {PagedModelMaleHolsteinAnimalInList} from 'api/gen/PagedModelMaleHolsteinAnimalInList';
import {ScoringResult} from 'api/gen/ScoringResult';
import {Sex} from 'api/gen/Sex';
import {WriteCriteria} from 'api/gen/WriteCriteria';

import {BullPreference} from '../types/bull-counts-and-shares';
import {ConfirmCriteriaListProps} from '../types/confirm-criteria-list-props';
import {MatingLoadingId} from '../types/mating-loading-id';
import {MatingTabKey} from '../types/mating-tab-key';
import {RenameCriteriaListProps} from '../types/rename-criteria-list-props';
import {SessionState} from './state';

export const matingAction = {
    resetScreen: () => ({
        type: 'mating/RESET_SCREEN',
    }) as const,
    setActiveTab: (tab: MatingTabKey) => ({
        type: 'mating/SET_ACTIVE_TAB',
        payload: {tab},
    }) as const,
    setLoading: (id: MatingLoadingId, loading: boolean) => ({
        type: 'mating/SET_LOADING',
        payload: {id, loading},
    }) as const,
    getBreeders: () => ({
        type: 'mating/GET_BREEDERS',
    }) as const,
    getBreedersSuccess: (breeders: Array<BreederToChoose>) => ({
        type: 'mating/GET_BREEDERS_SUCCESS',
        payload: {breeders},
    }) as const,
    getFarms: () => ({
        type: 'mating/GET_FARMS',
    }) as const,
    getFarmsSuccess: (farms: Array<FarmToChoose>) => ({
        type: 'mating/GET_FARMS_SUCCESS',
        payload: {farms},
    }) as const,
    filterAnimals: (sex: Sex, associationType: AssociationType) => ({
        type: 'mating/FILTER_ANIMALS',
        payload: {sex, associationType},
    }) as const,
    getMatingCows: (
        tableDataRequest: Opt<TableDataRequest<FemaleHolsteinAnimalInList>>,
    ) => ({
        type: 'mating/GET_MATING_COWS',
        payload: {tableDataRequest},
    }) as const,
    getFleckviehMatingBulls: (
        tableDataRequest: Opt<TableDataRequest<MaleFleckviehAnimalInList>>,
    ) => ({
        type: 'mating/GET_FLECKVIEH_MATING_BULLS',
        payload: {tableDataRequest, associationType: 'FLECKVIEH'},
    }) as const,
    getHolsteinMatingBulls: (
        tableDataRequest: Opt<TableDataRequest<MaleHolsteinAnimalInList>>,
    ) => ({
        type: 'mating/GET_HOLSTEIN_MATING_BULLS',
        payload: {tableDataRequest, associationType: 'HOLSTEIN'},
    }) as const,
    getFemaleAnimalsSuccess: (femaleAnimalsPage: Opt<PagedModelFemaleHolsteinAnimalInList>) => ({
        type: 'mating/GET_FEMALE_ANIMALS_SUCCESS',
        payload: {femaleAnimalsPage},
    }) as const,
    setFemaleAnimalExistInSession: (isFull: boolean) => ({
        type: 'mating/SET_FEMALE_ANIMAL_EXIST_IN_SESSION',
        payload: {isFull},
    }) as const,
    setFemaleSessionState: (cowsState: SessionState) => ({
        type: 'mating/SET_FEMALE_SESSION_STATE',
        payload: {cowsState},
    }) as const,
    getMaleFleckviehAnimalsSuccess: (maleAnimalsPage: Opt<PagedModelMaleFleckviehAnimalInList>) => ({
        type: 'mating/GET_MALE_FLECKVIEH_ANIMALS_SUCCESS',
        payload: {maleAnimalsPage},
    }) as const,
    getMaleHolsteinAnimalsSuccess: (maleAnimalsPage: Opt<PagedModelMaleHolsteinAnimalInList>) => ({
        type: 'mating/GET_MALE_HOLSTEIN_ANIMALS_SUCCESS',
        payload: {maleAnimalsPage},
    }) as const,
    setMaleAnimalExistInSession: (isFull: boolean) => ({
        type: 'mating/SET_MALE_ANIMAL_EXIST_IN_SESSION',
        payload: {isFull},
    }) as const,
    setMaleSessionState: (bullsState: SessionState) => ({
        type: 'mating/SET_MALE_SESSION_STATE',
        payload: {bullsState},
    }) as const,
    addAnimalsFromCsv: (sex: Sex, file: File, associationType: AssociationType) => ({
        type: 'mating/ADD_ANIMALS_FROM_CSV',
        payload: {sex, file, associationType},
    }) as const,
    toggleAnimalListsVisibility: (sex: Sex) => ({
        type: 'mating/TOGGLE_ANIMAL_LISTS_VISIBILITY',
        payload: {sex},
    }) as const,
    toggleCriteriaListsVisibility: (bookmark: Bookmark) => ({
        type: 'mating/TOGGLE_CRITERIA_LISTS_VISIBILITY',
        payload: {bookmark},
    }) as const,
    toggleOverwriteAnimalListVisibility: (sex: Sex, animalListId: Opt<number>) => ({
        type: 'mating/TOGGLE_OVERWRITE_ANIMAL_LIST_VISIBILITY',
        payload: {sex, animalListId},
    }) as const,
    toggleOverwriteCriteriaListVisibility: (
        bookmark: Bookmark,
        gridUserSettings?: CreateOrUpdateGridUserSettings | null,
        writeCriteria?: Array<WriteCriteria>,
        criteriaListId?: number,
    ) => ({
        type: 'mating/TOGGLE_OVERWRITE_CRITERIA_LIST_VISIBILITY',
        payload: {bookmark, writeCriteria, criteriaListId, gridUserSettings},
    }) as const,
    createAnimalList: (sex: Sex, name: string, associationType: AssociationType) => ({
        type: 'mating/VALIDATE_ANIMAL_LIST_NAME',
        payload: {sex, name, associationType},
    }) as const,
    createCriteriaList: (
        bookmark: Bookmark,
        name: string,
        criteriaDefinitions: Opt<Array<CriteriaDefinition>>,
        associationType: AssociationType,
    ) => ({
        type: 'mating/VALIDATE_CRITERIA_LIST_NAME',
        payload: {bookmark, name, criteriaDefinitions, associationType},
    }) as const,
    renameAnimalList: (sex: Sex, id: number, name: string, associationType: AssociationType) => ({
        type: 'mating/RENAME_ANIMAL_LIST',
        payload: {sex, id, name, associationType},
    }) as const,
    renameCriteriaList: ({associationType, bookmark, gridUserSettingsId, id, name}: RenameCriteriaListProps) => ({
        type: 'mating/RENAME_CRITERIA_LIST',
        payload: {bookmark, id, name, associationType, gridUserSettingsId},
    }) as const,
    confirmAnimalList: (sex: Sex, animalListId: number, associationType: AssociationType) => ({
        type: 'mating/CONFIRM_ANIMAL_LIST',
        payload: {sex, animalListId, associationType},
    }) as const,
    confirmCriteriaList: ({
        associationType,
        bookmark,
        criteria,
        criteriaListId,
        gridUserSettings,
    }: ConfirmCriteriaListProps)=> ({
        type: 'mating/CONFIRM_CRITERIA_LIST',
        payload: {bookmark, criteriaListId, criteria, gridUserSettings, associationType},
    }) as const,
    deleteAnimalsFromList: (sex: Sex, associationType: AssociationType, silentMode?: boolean) => ({
        type: 'mating/DELETE_ANIMALS_FROM_LIST',
        payload: {sex, associationType, silentMode},
    }) as const,
    getAnimalsCsv: (sex: Sex, fileName: string, associationType: AssociationType) => ({
        type: 'mating/GET_ANIMALS_CSV',
        payload: {sex, fileName, associationType},
    }) as const,
    getAvailableAnimals: (sex: Sex, label: string, associationType: AssociationType) => ({
        type: 'mating/GET_AVAILABLE_ANIMALS',
        payload: {sex, label, associationType},
    }) as const,
    getAvailableAnimalsSuccess: (availableAnimals: Array<AvailableAnimal>) => ({
        type: 'mating/GET_AVAILABLE_ANIMALS_SUCCESS',
        payload: {availableAnimals},
    }) as const,
    addAnimals: (sex: Sex, animalIds: Array<number>, associationType: AssociationType) => ({
        type: 'mating/ADD_ANIMALS',
        payload: {sex, animalIds, associationType},
    }) as const,
    removeAnimal: (sex: Sex, animalId: number, associationType: AssociationType) => ({
        type: 'mating/REMOVE_ANIMAL',
        payload: {sex, animalId, associationType},
    }) as const,
    toggleAddAnimalsVisibility: (sex: Sex) => ({
        type: 'mating/TOGGLE_ADD_ANIMALS_VISIBILITY',
        payload: {sex},
    }) as const,
    toggleAddAnimalsFromDBVisibility: (sex: Sex) => ({
        type: 'mating/TOGGLE_ADD_ANIMALS_FROM_DB_VISIBILITY',
        payload: {sex},
    }) as const,
    getAnimalLists: (sex: Sex, associationType: AssociationType, withoutLoading?: boolean) => ({
        type: 'mating/GET_ANIMAL_LISTS',
        payload: {sex, withoutLoading, associationType},
    }) as const,
    getAnimalListsSuccess: (animalLists: Array<AnimalList>, sex: Sex) => ({
        type: 'mating/GET_ANIMAL_LISTS_SUCCESS',
        payload: {animalLists, sex},
    }) as const,
    addAnimalsFromDB: (sex: Sex, animalListId: number, associationType: AssociationType) => ({
        type: 'mating/ADD_ANIMALS_FROM_DB',
        payload: {sex, animalListId, associationType},
    }) as const,
    getCriteriaLists: (bookmark: Bookmark, associationType: AssociationType, withoutLoading?: boolean) => ({
        type: 'mating/GET_CRITERIA_LISTS',
        payload: {bookmark, associationType, withoutLoading},
    }) as const,
    getCriteriaListsSuccess: (criteriaLists: Array<CriteriaList>, bookmark: Bookmark) => ({
        type: 'mating/GET_CRITERIA_LISTS_SUCCESS',
        payload: {criteriaLists, bookmark},
    }) as const,
    toggleListsOverviewVisibility: (bookmark: Bookmark) => ({
        type: 'mating/TOGGLE_LISTS_OVERVIEW_VISIBILITY',
        payload: {bookmark},
    }) as const,
    deleteAnimalList: (animalListId: number, sex: Sex, associationType: AssociationType) => ({
        type: 'mating/DELETE_ANIMAL_LIST',
        payload: {animalListId, sex, associationType},
    }) as const,
    deleteCriteriaList: (
        criteriaListId: number,
        bookmark: Bookmark,
        associationType: AssociationType,
        gridUserSettingsId: number | null,
    ) => ({
        type: 'mating/DELETE_CRITERIA_LIST',
        payload: {criteriaListId, bookmark, associationType, gridUserSettingsId},
    }) as const,
    getCriteriaAccordionsSuccess: (criteriaAccordions: Array<Accordion>) => ({
        type: 'mating/GET_CRITERIA_ACCORDIONS_SUCCESS',
        payload: {criteriaAccordions},
    }) as const,
    getCriteriaDefinitionsSuccess: (bookmark: Bookmark, criteriaDefinitions: Array<CriteriaDefinition>) => ({
        type: 'mating/GET_CRITERIA_DEFINITIONS_SUCCESS',
        payload: {bookmark, criteriaDefinitions},
    }) as const,
    setMatingFormInitialValues: (bookmark: Bookmark) => ({
        type: 'mating/SET_MATING_FORM_INITIAL_VALUES',
        payload: {bookmark},
    }) as const,
    resetAccordionValues: (sex: Sex, accordionId: number) => ({
        type: 'mating/RESET_ACCORDION_VALUES',
        payload: {accordionId, sex},
    }) as const,
    resetSingleCriteriaValue: (sex: Sex, criteriaCode: string) => ({
        type: 'mating/RESET_SINGLE_CRITERIA_VALUE',
        payload: {sex, criteriaCode},
    }) as const,
    getCriteriaListDetails: (bookmark: Bookmark, associationType: AssociationType, criteriaListId: number) => ({
        type: 'mating/GET_CRITERIA_LIST_DETAILS',
        payload: {bookmark, associationType, criteriaListId},
    }) as const,
    toggleCriteriaListDetailsVisibility: (bookmark: Bookmark) => ({
        type: 'mating/TOGGLE_CRITERIA_LIST_DETAILS_VISIBILITY',
        payload: {bookmark},
    }) as const,
    toggleSaveScoringResultsVisibility: () => ({
        type: 'mating/TOGGLE_SAVE_SCORING_RESULTS_VISIBILITY',
    }) as const,
    saveScoringResultsToFile: (fileName: string) => ({
        type: 'mating/SAVE_SCORING_RESULTS_TO_FILE',
        payload: {fileName},
    }) as const,
    getOrganizations: (associationType: AssociationType) => ({
        type: 'mating/GET_ORGANIZATIONS',
        payload: {associationType},
    }) as const,
    getOrganizationsSuccess: (organizations: Array<OrganizationToChoose>) => ({
        type: 'mating/GET_ORGANIZATIONS_SUCCESS',
        payload: {organizations},
    }) as const,
    getMatingScore: () => ({
        type: 'mating/GET_MATING_SCORE',
    }) as const,
    getMatingScoreSuccess: (matingResult: MatingResult) => ({
        type: 'mating/GET_MATING_SCORE_SUCCESS',
        payload: {matingResult},
    }) as const,
    recalculateBullPreferences: (bullPreferences: Array<BullPreference>) => ({
        type: 'mating/RECALCULATE_BULL_PREFERENCES',
        payload: {bullPreferences},
    }) as const,
    setRecalculatedScoringResults: (scoringResults: Array<ScoringResult>, bullPreferences: Array<BullPreference>) => ({
        type: 'mating/SET_RECALCULATED_SCORING_RESULTS',
        payload: {scoringResults, bullPreferences},
    }) as const,
    resetScoringResults: () => ({
        type: 'mating/RESET_SCORING_RESULTS',
    }) as const,
    initializeForms: (bullSelector: boolean, associationType: AssociationType) => ({
        type: 'mating/INITIALIZE_FORMS',
        payload: {bullSelector, associationType},
    }) as const,
    resetForms: (associationType: AssociationType) => ({
        type: 'mating/RESET_FORMS',
        payload: {associationType},
    }) as const,
    resetAnimalAndCriteriaTags: () => ({
        type: 'mating/RESET_ANIMALS_AND_CRITERIA_TAGS',
    }) as const,
    toggleExportBullselectorResultModal: () => ({
        type: 'mating/TOGGLE_EXPORT_BULLSELECTOR_RESULT_MODAL',
    }) as const,
    exportBullselectorModalResult: (fileName: string, associationType: AssociationType) => ({
        type: 'mating/EXPORT_BULLSELECTOR_MODAL_RESULT',
        payload: {fileName, associationType},
    }) as const,
    exportMatingAnimals: (sex: Sex, associationType: AssociationType) => ({
        type: 'mating/EXPORT_MATING_ANIMALS',
        payload: {sex, associationType},
    }) as const,
    getAnimalsCount: (sex: Sex, associationType: AssociationType) => ({
        type: 'mating/GET_ANIMALS_COUNT',
        payload: {sex, associationType},
    }) as const,
};

export type ResetMatingScreenAction = ReturnType<typeof matingAction.resetScreen>;
export type ExportBullselectorModalResultAction = ReturnType<typeof matingAction.exportBullselectorModalResult>;
export type SetActiveTabAction = ReturnType<typeof matingAction.setActiveTab>;
export type MatingSelLoadingAction = ReturnType<typeof matingAction.setLoading>;
export type ResetAnimalAndCriteriaTagsAction = ReturnType<typeof matingAction.resetAnimalAndCriteriaTags>;
export type GetBreedersAction = ReturnType<typeof matingAction.getBreeders>;
export type GetBreedersSuccessAction = ReturnType<typeof matingAction.getBreedersSuccess>;
export type GetFarmsAction = ReturnType<typeof matingAction.getFarms>;
export type GetFarmsSuccessAction = ReturnType<typeof matingAction.getFarmsSuccess>;
export type GetMatingCowsAction = ReturnType<typeof matingAction.getMatingCows>;
export type SetFemaleAnimalExistInSessionAction = ReturnType<typeof matingAction.setFemaleAnimalExistInSession>;
export type GetFleckviehMatingBullsAction = ReturnType<typeof matingAction.getFleckviehMatingBulls>;
export type GetHolsteinMatingBullsAction = ReturnType<typeof matingAction.getHolsteinMatingBulls>;
export type SetMaleAnimalExistInSessionAction = ReturnType<typeof matingAction.setMaleAnimalExistInSession>;
export type GetFemaleAnimalsSuccessAction = ReturnType<typeof matingAction.getFemaleAnimalsSuccess>;
export type GetMaleFleckviehAnimalsSuccessAction = ReturnType<typeof matingAction.getMaleFleckviehAnimalsSuccess>;
export type GetMaleHolsteinAnimalsSuccessAction = ReturnType<typeof matingAction.getMaleHolsteinAnimalsSuccess>;
export type FilterAnimalsAction = ReturnType<typeof matingAction.filterAnimals>;
export type AddAnimalsFromCsvAction = ReturnType<typeof matingAction.addAnimalsFromCsv>;
export type ToggleAnimalListsVisibilityAction = ReturnType<typeof matingAction.toggleAnimalListsVisibility>;
export type ToggleCriteriaListsVisibilityAction = ReturnType<typeof matingAction.toggleCriteriaListsVisibility>;
export type ToggleOverwriteAnimalListVisibilityAction =
    ReturnType<typeof matingAction.toggleOverwriteAnimalListVisibility>;
export type ToggleOverwriteCriteriaListVisibilityAction =
    ReturnType<typeof matingAction.toggleOverwriteCriteriaListVisibility>;
export type CreateAnimalListAction = ReturnType<typeof matingAction.createAnimalList>;
export type CreateCriteriaListAction = ReturnType<typeof matingAction.createCriteriaList>;
export type RenameAnimalListAction = ReturnType<typeof matingAction.renameAnimalList>;
export type RenameCriteriaListAction = ReturnType<typeof matingAction.renameCriteriaList>;
export type ConfirmCreateListAction = ReturnType<typeof matingAction.confirmAnimalList>;
export type ConfirmCreateCriteriaListAction = ReturnType<typeof matingAction.confirmCriteriaList>;
export type DeleteAnimalsFromListAction = ReturnType<typeof matingAction.deleteAnimalsFromList>;
export type GetAnimalsCsvAction = ReturnType<typeof matingAction.getAnimalsCsv>;
export type GetAvailableAnimalsAction = ReturnType<typeof matingAction.getAvailableAnimals>;
export type GetAvailableAnimalsSuccessAction = ReturnType<typeof matingAction.getAvailableAnimalsSuccess>;
export type AddAnimalsAction = ReturnType<typeof matingAction.addAnimals>;
export type RemoveAnimalAction = ReturnType<typeof matingAction.removeAnimal>;
export type ToggleAddAnimalsVisibilityAction = ReturnType<typeof matingAction.toggleAddAnimalsVisibility>;
export type ToggleAddAnimalsFromDBVisibilityAction = ReturnType<typeof matingAction.toggleAddAnimalsFromDBVisibility>;
export type GetAnimalListsAction = ReturnType<typeof matingAction.getAnimalLists>;
export type GetAnimalListsSuccessAction = ReturnType<typeof matingAction.getAnimalListsSuccess>;
export type AddAnimalsFromDBAction = ReturnType<typeof matingAction.addAnimalsFromDB>;
export type GetCriteriaListsAction = ReturnType<typeof matingAction.getCriteriaLists>;
export type GetCriteriaListsSuccessAction = ReturnType<typeof matingAction.getCriteriaListsSuccess>;
export type ToggleListsOverviewVisibilityAction = ReturnType<typeof matingAction.toggleListsOverviewVisibility>;
export type DeleteAnimalListAction = ReturnType<typeof matingAction.deleteAnimalList>;
export type DeleteCriteriaListAction = ReturnType<typeof matingAction.deleteCriteriaList>;
export type GetCriteriaAccordionsSuccessAction = ReturnType<typeof matingAction.getCriteriaAccordionsSuccess>;
export type GetCriteriaDefinitionsSuccessAction = ReturnType<typeof matingAction.getCriteriaDefinitionsSuccess>;
export type SetMatingFormInitialValuesAction = ReturnType<typeof matingAction.setMatingFormInitialValues>;
export type ResetAccordionValuesAction = ReturnType<typeof matingAction.resetAccordionValues>;
export type ResetSingleCriteriaValueAction = ReturnType<typeof matingAction.resetSingleCriteriaValue>;
export type GetCriteriaListDetailsAction = ReturnType<typeof matingAction.getCriteriaListDetails>;
export type ToggleCriteriaListDetailsVisibilityAction =
    ReturnType<typeof matingAction.toggleCriteriaListDetailsVisibility>;
export type ToggleSaveScoringResultsVisibilityAction =
    ReturnType<typeof matingAction.toggleSaveScoringResultsVisibility>;
export type SaveScoringResultsToFileAction = ReturnType<typeof matingAction.saveScoringResultsToFile>;
export type GetOrganizationsAction = ReturnType<typeof matingAction.getOrganizations>;
export type GetOrganizationsSuccessAction = ReturnType<typeof matingAction.getOrganizationsSuccess>;
export type GetMatingScoreAction = ReturnType<typeof matingAction.getMatingScore>;
export type GetMatingScoreSuccessAction = ReturnType<typeof matingAction.getMatingScoreSuccess>;
export type RecalculateBullPreferencesAction = ReturnType<typeof matingAction.recalculateBullPreferences>;
export type SetRecalculatedScoringResultsAction = ReturnType<typeof matingAction.setRecalculatedScoringResults>;
export type ResetScoringResultsAction = ReturnType<typeof matingAction.resetScoringResults>;
export type InitializeFormsAction = ReturnType<typeof matingAction.initializeForms>;
export type ResetFormsAction = ReturnType<typeof matingAction.resetForms>;
export type ToggleExportBullselectorResultModalAction =
    ReturnType<typeof matingAction.toggleExportBullselectorResultModal>;
export type ExportMatingAnimalsAction = ReturnType<typeof matingAction.exportMatingAnimals>;
export type GetAnimalsCountAction = ReturnType<typeof matingAction.getAnimalsCount>;
export type SetFemaleSessionStateAction = ReturnType<typeof matingAction.setFemaleSessionState>;
export type SetMaleSessionStateAction = ReturnType<typeof matingAction.setMaleSessionState>;

export type MatingAction =
    | ResetMatingScreenAction
    | ResetAnimalAndCriteriaTagsAction
    | SetActiveTabAction
    | MatingSelLoadingAction
    | GetBreedersAction
    | ExportBullselectorModalResultAction
    | GetBreedersSuccessAction
    | GetFarmsAction
    | GetFarmsSuccessAction
    | GetMatingCowsAction
    | SetFemaleAnimalExistInSessionAction
    | GetFleckviehMatingBullsAction
    | GetHolsteinMatingBullsAction
    | SetMaleAnimalExistInSessionAction
    | GetFemaleAnimalsSuccessAction
    | GetMaleFleckviehAnimalsSuccessAction
    | GetMaleHolsteinAnimalsSuccessAction
    | FilterAnimalsAction
    | AddAnimalsFromCsvAction
    | ToggleAnimalListsVisibilityAction
    | ToggleCriteriaListsVisibilityAction
    | ToggleOverwriteAnimalListVisibilityAction
    | ToggleOverwriteCriteriaListVisibilityAction
    | CreateAnimalListAction
    | CreateCriteriaListAction
    | RenameAnimalListAction
    | RenameCriteriaListAction
    | ConfirmCreateListAction
    | ConfirmCreateCriteriaListAction
    | DeleteAnimalsFromListAction
    | GetAnimalsCsvAction
    | GetAvailableAnimalsAction
    | GetAvailableAnimalsSuccessAction
    | ToggleAddAnimalsVisibilityAction
    | AddAnimalsAction
    | RemoveAnimalAction
    | ToggleAddAnimalsFromDBVisibilityAction
    | GetAnimalListsAction
    | GetAnimalListsSuccessAction
    | AddAnimalsFromDBAction
    | GetCriteriaListsAction
    | GetCriteriaListsSuccessAction
    | ToggleListsOverviewVisibilityAction
    | DeleteAnimalListAction
    | DeleteCriteriaListAction
    | GetCriteriaAccordionsSuccessAction
    | GetCriteriaDefinitionsSuccessAction
    | SetMatingFormInitialValuesAction
    | ResetAccordionValuesAction
    | ResetSingleCriteriaValueAction
    | GetCriteriaListDetailsAction
    | ToggleCriteriaListDetailsVisibilityAction
    | ToggleSaveScoringResultsVisibilityAction
    | SaveScoringResultsToFileAction
    | GetOrganizationsAction
    | GetOrganizationsSuccessAction
    | GetMatingScoreAction
    | GetMatingScoreSuccessAction
    | RecalculateBullPreferencesAction
    | SetRecalculatedScoringResultsAction
    | ResetScoringResultsAction
    | InitializeFormsAction
    | ResetFormsAction
    | ToggleExportBullselectorResultModalAction
    | ExportMatingAnimalsAction
    | GetAnimalsCountAction
    | SetFemaleSessionStateAction
    | SetMaleSessionStateAction
    ;
