import {SagaIterator} from 'redux-saga';
import {select} from 'typed-redux-saga';

import {State} from 'app/setup';

export function* getCriteriaListVersion(id: number): SagaIterator<string> {
    const criteriaLists = yield* select((state: State) => state.mating.criteriaLists);
    const criteriaList = criteriaLists
        .findIn(y => y.id === id)
        .orCrash('criteriaLists not available');

    return criteriaList.version;
}
