import {takeLatestF, downloadFileFromString} from '@fl/cmsch-fe-library';
import {join, range} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {opt, pipe, flatMap} from 'ts-opt';
import {put, select, call} from 'typed-redux-saga';

import {BullResult} from 'api/gen/BullResult';
import {ScoringResult} from 'api/gen/ScoringResult';
import {State} from 'app/setup';

import {buildCsvHeader, csvColumnSeparator, lineBullCount, csvNewLine} from '../../utils/build-csv-header';
import {matingAction, SaveScoringResultsToFileAction} from '../action';

type CsvRow = [string, string, string, string];

const buildBullCsvRow = (bull: BullResult): CsvRow => [
    bull.lineRegistry,
    opt(bull.name).orElse(''),
    String(bull.score),
    opt(bull.inbreeding).map(String).orElse(''),
];

const getBullCsvRow = (bulls: Array<BullResult> | null) => (index: number): CsvRow =>
    opt(bulls).prop(index).map(buildBullCsvRow).orElse(['', '', '', '']);

export const buildCsvRowString = ({earTag, bulls}: ScoringResult): string =>
    pipe(
        range(0, lineBullCount),
        flatMap(getBullCsvRow(bulls)),
        xs => [earTag, ...xs],
        join(csvColumnSeparator),
    );

function* execute({payload: {fileName}}: SaveScoringResultsToFileAction): SagaIterator {
    const matingResult = (yield* select((state: State) => state.mating.matingResult))
        .orCrash('scoringResults not available');
    const recalculatedScoringResults = yield* select((state: State) => state.mating.recalculatedScoringResults);
    const csvRows = recalculatedScoringResults.orElse(matingResult.results).map(buildCsvRowString).join(csvNewLine);

    yield* call(downloadFileFromString, [buildCsvHeader(), csvRows].join(csvNewLine), `${fileName}.csv`);
    yield* put(matingAction.toggleSaveScoringResultsVisibility());
}

export function* saveScoringResultsToFileSaga(): SagaIterator {
    yield takeLatestF('mating/SAVE_SCORING_RESULTS_TO_FILE', execute);
}
