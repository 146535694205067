import React, { memo } from 'react';
import { Ant } from 'common/ant';
import styles from './styles.sass';
interface OuterProps {
  rowName: React.ReactNode;
  rowValue: string | undefined;
}
type Props = OuterProps;
const AppInfoRowBase: React.FC<Props> = ({
  rowName,
  rowValue
}) => <Ant.Row gutter={16} data-sentry-element="unknown" data-sentry-component="AppInfoRowBase" data-sentry-source-file="index.tsx">
        <Ant.Col xs={13} sm={9} lg={5} xl={4} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            {rowName}
        </Ant.Col>
        <Ant.Col xs={11} sm={15} lg={19} xl={20} className={styles.versionColumn} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            <Ant.Typography.Text strong data-sentry-element="unknown" data-sentry-source-file="index.tsx">{rowValue}</Ant.Typography.Text>
        </Ant.Col>
    </Ant.Row>;
export const AppInfoRow = memo(AppInfoRowBase);