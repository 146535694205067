import {putAll, showBeError, takeEveryF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {GetAnimalsCountAction, matingAction} from '../action';

const tt = t('mating/sagas');

function* execute({payload: {sex, associationType}}: GetAnimalsCountAction): SagaIterator {
    const response = yield* call(Api.getMatingAnimalSessionStatus, {sex, associationType});

    if (response.isSuccess) {
        const {areAnimalsInSession} = response.data;

        yield* sex === 'FEMALE'
            ? put(matingAction.setFemaleAnimalExistInSession(areAnimalsInSession))
            : put(matingAction.setMaleAnimalExistInSession(areAnimalsInSession));
    } else {
        yield putAll(showBeError(response, sex === 'FEMALE' ? tt('loadCowList') : tt('loadingBullList')));
    }
}

export function* getAnimalsCountSaga(): SagaIterator {
    yield takeEveryF('mating/GET_ANIMALS_COUNT', execute);
}
