// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-user-detail-components-subject-form-___styles__formContainer___jC6md{display:grid;grid-template-columns:repeat(2,1fr);gap:20px}.src-app-user-detail-components-subject-form-___styles__formContainer___jC6md .src-app-user-detail-components-subject-form-___styles__doubleWidth___Gx0Kf{grid-column:span 2}.src-app-user-detail-components-subject-form-___styles__formContainer___jC6md .src-app-user-detail-components-subject-form-___styles__roleSelect___aZ_3J{overflow:hidden!important;width:100%!important}@media (max-width: 767px){.src-app-user-detail-components-subject-form-___styles__formContainer___jC6md{grid-template-columns:1fr}.src-app-user-detail-components-subject-form-___styles__formContainer___jC6md .src-app-user-detail-components-subject-form-___styles__doubleWidth___Gx0Kf{grid-column:span 1}}
`, "",{"version":3,"sources":["webpack://./src/app/user-detail/components/subject-form/styles.sass"],"names":[],"mappings":"AAAA,8EAAe,YAAA,CAAa,mCAAA,CAAqC,QAAA,CAAS,0JAA4B,kBAAA,CAAmB,yJAA2B,yBAAA,CAA2B,oBAAA,CAAsB,0BAAyB,8EAAe,yBAAA,CAA0B,0JAA4B,kBAAA,CAAA","sourcesContent":[".formContainer{display:grid;grid-template-columns:repeat(2, 1fr);gap:20px}.formContainer .doubleWidth{grid-column:span 2}.formContainer .roleSelect{overflow:hidden !important;width:100% !important}@media(max-width: 767px){.formContainer{grid-template-columns:1fr}.formContainer .doubleWidth{grid-column:span 1}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `src-app-user-detail-components-subject-form-___styles__formContainer___jC6md`,
	"doubleWidth": `src-app-user-detail-components-subject-form-___styles__doubleWidth___Gx0Kf`,
	"roleSelect": `src-app-user-detail-components-subject-form-___styles__roleSelect___aZ_3J`
};
export default ___CSS_LOADER_EXPORT___;
