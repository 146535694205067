export const middlewareAction = {
    openWindow: (
        url: string,
        name: string,
        features: string,
    ) => ({
        type: 'middleware/OPEN_WINDOW',
        payload: {url, name, features},
    }) as const,
};
type OpenWindowAction = ReturnType<typeof middlewareAction.openWindow>;

export type MiddlewareAction = OpenWindowAction;
