import {setItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {none, opt, some, pipe} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialMatingState, MatingState} from './state';

const matingStateO = O.optic<MatingState>();

export const matingReducer = (state: MatingState = initialMatingState, action: AppAction): MatingState => {
    switch (action.type) {
        case 'mating/RESET_SCREEN': {
            return initialMatingState;
        }

        case 'mating/RESET_FORMS': {
            return O.set(matingStateO.prop('toggleResetScreen'))(!state.toggleResetScreen)(state);
        }

        case 'mating/SET_ACTIVE_TAB': {
            const {tab} = action.payload;

            return O.set(matingStateO.prop('activeTab'))(tab)(state);
        }

        case 'mating/SET_LOADING': {
            const {id, loading} = action.payload;

            return O.modify(matingStateO.prop('loading'))(setItem(loading)(id))(state);
        }

        case 'mating/GET_BREEDERS_SUCCESS': {
            const {breeders} = action.payload;

            return O.set(matingStateO.prop('breeders'))(opt(breeders))(state);
        }

        case 'mating/GET_FARMS_SUCCESS': {
            const {farms} = action.payload;

            return O.set(matingStateO.prop('farms'))(opt(farms))(state);
        }

        case 'mating/GET_FEMALE_ANIMALS_SUCCESS': {
            const {femaleAnimalsPage} = action.payload;

            return O.set(matingStateO.prop('femaleAnimalsPage'))(femaleAnimalsPage)(state);
        }

        case 'mating/SET_FEMALE_ANIMAL_EXIST_IN_SESSION': {
            const {isFull} = action.payload;

            return O.set(matingStateO.prop('cowsExistInSession'))(isFull)(state);
        }

        case 'mating/SET_FEMALE_SESSION_STATE': {
            const {cowsState} = action.payload;

            return O.set(matingStateO.prop('cowsSessionState'))(cowsState)(state);
        }

        case 'mating/GET_MALE_FLECKVIEH_ANIMALS_SUCCESS': {
            const {maleAnimalsPage} = action.payload;

            return O.set(matingStateO.prop('maleFleckviehAnimalsPage'))(maleAnimalsPage)(state);
        }

        case 'mating/GET_MALE_HOLSTEIN_ANIMALS_SUCCESS': {
            const {maleAnimalsPage} = action.payload;

            return O.set(matingStateO.prop('maleHolsteinAnimalsPage'))(maleAnimalsPage)(state);
        }

        case 'mating/SET_MALE_ANIMAL_EXIST_IN_SESSION': {
            const {isFull} = action.payload;

            return O.set(matingStateO.prop('bullsExistInSession'))(isFull)(state);
        }

        case 'mating/SET_MALE_SESSION_STATE': {
            const {bullsState} = action.payload;

            return O.set(matingStateO.prop('bullsSessionState'))(bullsState)(state);
        }

        case 'mating/TOGGLE_ANIMAL_LISTS_VISIBILITY': {
            const {sex} = action.payload;
            const visibility = state.animalListsModalData === sex ? null : sex;

            return O.set(matingStateO.prop('animalListsModalData'))(visibility)(state);
        }

        case 'mating/TOGGLE_OVERWRITE_ANIMAL_LIST_VISIBILITY': {
            const {sex, animalListId} = action.payload;
            if (!state.animalListsConfirmModalData) {
                return pipe(
                    state,
                    O.set(matingStateO.prop('animalListsConfirmModalData'))(sex),
                    O.set(matingStateO.prop('animalListId'))(animalListId),
                );
            } else {
                return pipe(
                    state,
                    O.set(matingStateO.prop('animalListsConfirmModalData'))(null),
                    O.set(matingStateO.prop('animalListId'))(none),
                );
            }
        }

        case 'mating/GET_AVAILABLE_ANIMALS_SUCCESS': {
            const {availableAnimals} = action.payload;

            return O.set(matingStateO.prop('availableAnimals'))(opt(availableAnimals))(state);
        }

        case 'mating/TOGGLE_ADD_ANIMALS_VISIBILITY': {
            const {sex} = action.payload;
            const visibility = state.addAnimalsModalData === sex ? null : sex;
            if (!visibility) {
                return pipe(
                    state,
                    O.set(matingStateO.prop('addAnimalsModalData'))(visibility),
                    O.set(matingStateO.prop('availableAnimals'))(none),
                );
            }

            return O.set(matingStateO.prop('addAnimalsModalData'))(visibility)(state);
        }

        case 'mating/TOGGLE_ADD_ANIMALS_FROM_DB_VISIBILITY': {
            const {sex} = action.payload;
            const visibility = state.animalsFromDBVisibilityModalData === sex ? null : sex;

            return O.set(matingStateO.prop('animalsFromDBVisibilityModalData'))(visibility)(state);
        }

        case 'mating/GET_ANIMAL_LISTS_SUCCESS': {
            const {animalLists, sex} = action.payload;

            return pipe(
                state,
                O.set(matingStateO.prop('animalLists'))(opt(animalLists)),
                O.set(matingStateO.prop('animalListsTag'))(opt(sex)),
            );
        }

        case 'mating/TOGGLE_LISTS_OVERVIEW_VISIBILITY': {
            const {bookmark} = action.payload;
            const visibility = state.listsOverviewModalData === bookmark ? null : bookmark;

            return O.set(matingStateO.prop('listsOverviewModalData'))(visibility)(state);
        }

        case 'mating/GET_CRITERIA_LISTS_SUCCESS': {
            const {criteriaLists, bookmark} = action.payload;

            return pipe(
                state,
                O.set(matingStateO.prop('criteriaLists'))(opt(criteriaLists)),
                O.set(matingStateO.prop('criteriaListsTag'))(opt(bookmark)),
            );
        }

        case 'mating/RESET_ANIMALS_AND_CRITERIA_TAGS':
            return pipe(
                state,
                O.set(matingStateO.prop('animalListsTag'))(none),
                O.set(matingStateO.prop('criteriaListsTag'))(none),
            );

        case 'mating/GET_CRITERIA_ACCORDIONS_SUCCESS': {
            const {criteriaAccordions} = action.payload;

            return O.set(matingStateO.prop('criteriaAccordions'))(opt(criteriaAccordions))(state);
        }

        case 'mating/GET_CRITERIA_DEFINITIONS_SUCCESS': {
            const {bookmark, criteriaDefinitions} = action.payload;

            if (bookmark === 'FEMALE') {
                return O.set(matingStateO.prop('cowCriteriaDefinitions'))(opt(criteriaDefinitions))(state);
            } else if (bookmark === 'MALE') {
                return O.set(matingStateO.prop('bullCriteriaDefinitions'))(opt(criteriaDefinitions))(state);
            } else {
                return O.set(matingStateO.prop('paramsCriteriaDefinitions'))(opt(criteriaDefinitions))(state);
            }
        }

        case 'mating/TOGGLE_CRITERIA_LISTS_VISIBILITY': {
            const {bookmark} = action.payload;
            const visibility = state.criteriaListsModalData === bookmark ? null : bookmark;

            return O.set(matingStateO.prop('criteriaListsModalData'))(visibility)(state);
        }

        case 'mating/TOGGLE_OVERWRITE_CRITERIA_LIST_VISIBILITY': {
            const {bookmark, criteriaListId, writeCriteria, gridUserSettings} = action.payload;

            if (!state.criteriaListsConfirmModalData) {
                return pipe(
                    state,
                    O.set(matingStateO.prop('criteriaListsConfirmModalData'))(bookmark),
                    O.set(matingStateO.prop('criteriaListId'))(opt(criteriaListId)),
                    O.set(matingStateO.prop('writeCriteria'))(opt(writeCriteria)),
                    O.set(matingStateO.prop('gridUserSettings'))(opt(gridUserSettings)),
                );
            } else {
                return pipe(
                    state,
                    O.set(matingStateO.prop('criteriaListsConfirmModalData'))(null),
                    O.set(matingStateO.prop('criteriaListId'))(none),
                    O.set(matingStateO.prop('writeCriteria'))(none),
                    O.set(matingStateO.prop('gridUserSettings'))(none),
                );
            }
        }

        case 'mating/TOGGLE_CRITERIA_LIST_DETAILS_VISIBILITY': {
            const {bookmark} = action.payload;
            const visibility = state.criteriaListDetailsModalData === bookmark ? null : bookmark;

            return O.set(matingStateO.prop('criteriaListDetailsModalData'))(visibility)(state);
        }

        case 'mating/TOGGLE_SAVE_SCORING_RESULTS_VISIBILITY': {
            return O.modify(matingStateO.prop('saveScoringResultsModalVisible'))(x => !x)(state);
        }

        case 'mating/GET_ORGANIZATIONS_SUCCESS': {
            const {organizations} = action.payload;

            return O.set(matingStateO.prop('organizations'))(opt(organizations))(state);
        }

        case 'mating/GET_MATING_SCORE_SUCCESS': {
            const {matingResult} = action.payload;

            return O.set(matingStateO.prop('matingResult'))(opt(matingResult))(state);
        }

        case 'mating/SET_RECALCULATED_SCORING_RESULTS': {
            const {scoringResults, bullPreferences} = action.payload;

            return pipe(
                state,
                O.set(matingStateO.prop('recalculatedScoringResults'))(some(scoringResults)),
                O.set(matingStateO.prop('bullPreferences'))(some(bullPreferences)),
            );
        }

        case 'mating/RESET_SCORING_RESULTS': {
            return pipe(
                state,
                O.set(matingStateO.prop('recalculatedScoringResults'))(none),
                O.set(matingStateO.prop('bullPreferences'))(none),
            );
        }

        default:
            return state;
    }
};
