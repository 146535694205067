import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {LoginFormValues} from '../../types/login-form-values';

const te = t('user/login');

export const validate = (values: LoginFormValues): Errors<LoginFormValues> => {
    const validator = new EskotValidator(values);

    validator.nonEmpty('username', te('username'));
    validator.nonEmpty('password', te('password'));

    return validator.generateErrorsObject();
};
