import {CorrectionReportDetail} from 'api/gen/CorrectionReportDetail';

import {ApcReportFormValues} from '../components/apc-report-form/form-values';
import {convertApcReportToTripleAttributeValue} from './convert-apc-report-to-triple-attribute-value';

export const convertCorrectionReportToFormValues = (report: CorrectionReportDetail): ApcReportFormValues => ({
    correctionType: report.correctionType,
    earTag: report.earTag,
    technicianNumber: report.technicianNumber,
    milkKg: report.milkKg,
    proficiencyTestDate: report.proficiencyTestDate,
    updatedEarTag: report.updatedEarTag,
    stableNumber: report.stableCode,
    milkingTripleAttribute: convertApcReportToTripleAttributeValue(report),
    lactosePercentage: report.lactosePercentage,
    somaticCells: report.somaticCells,
    urea: report.urea,
    fatPercentage: report.fatPercentage,
    proteinPercentage: report.proteinPercentage,
});
