import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {simpleUserSelector} from 'app/user';

const useCurrentUserHasAnyPermission = (requiredPermissions: Array<string>): boolean => {
    const userPermissions = useSelector(simpleUserSelector.currentUserPermissions);
    const hasAnyPermissions = useMemo(() =>
        // eslint-disable-next-line @fl/use-eta-reduction
        requiredPermissions.some(permission => userPermissions.includes(permission)),
    [requiredPermissions, userPermissions]);

    return (requiredPermissions.length === 0)
        ? true
        : hasAnyPermissions;
};

export default useCurrentUserHasAnyPermission;
