import {takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';

import {State} from 'app/setup';
import {formHelpers} from 'utils/forms';

import {matingBullPreferencesFormName} from '../../types/mating-bull-preferences-form-values';
import {getRecalculatedScoringResults} from '../../utils/get-recalculated-scoring-results';
import {matingAction, RecalculateBullPreferencesAction} from '../action';

function* execute({payload: {bullPreferences}}: RecalculateBullPreferencesAction): SagaIterator {
    yield* put(formHelpers.startSubmit(matingBullPreferencesFormName));

    const matingResult = (yield* select((state: State) => state.mating.matingResult))
        .orCrash('scoringResults must be present');
    const recalculatedScoringResults = getRecalculatedScoringResults(matingResult.results, bullPreferences);

    yield* put(matingAction.setRecalculatedScoringResults(recalculatedScoringResults, bullPreferences));
    yield* put(formHelpers.stopSubmit(matingBullPreferencesFormName, {}));
}

export function* recalculateBullPreferencesSaga(): SagaIterator {
    yield takeLatestF('mating/RECALCULATE_BULL_PREFERENCES', execute);
}
