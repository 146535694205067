import {KeysOfType, ValueOfArray, Nullable, NullableKeys} from '@fl/cmsch-fe-library';
import {includes} from 'lodash/fp';

import {NullableNumberPair} from 'app/mating/types/nullable-number-pair';

export const matingParamsSelectionFormName = 'matingParamsSelection';

export type MatingParamsSelectionFormValues = NullableKeys<{
    // left column
    RxyMaxU: number,

    VhMlk: number,
    VhTuk: number,
    VhBlk: number,
    VhSB: number,
    VhKM: number,
    VhOCD: number,
    VhDlh: number,
    VhOOP1: number,
    VhDoj: number,
    VhPld: number,

    // middle column
    TRA: NullableNumberPair,
    SHR: NullableNumberPair,
    HLT: NullableNumberPair,
    HRA: NullableNumberPair,
    SKZ: NullableNumberPair,
    SRZ: NullableNumberPair,
    PZZ: NullableNumberPair,
    PZB: NullableNumberPair,
    PAZ: NullableNumberPair,
    PUV: NullableNumberPair,
    RPS: NullableNumberPair,
    DST: NullableNumberPair,
    HLV: NullableNumberPair,
    VZU: NullableNumberPair,
    ZAV: NullableNumberPair,
    RZS: NullableNumberPair,
    SZU: NullableNumberPair,
    KVK: NullableNumberPair,
    CHO: NullableNumberPair,
    KND: NullableNumberPair,

    // right column
    VhProd: number,
    VhExt: number,
    VhFnkcn: number,
    VhZdr: number,
}>;

export const matingParamsCoefficientCodes: Array<KeysOfType<MatingParamsSelectionFormValues, Nullable<number>>> =
    ['RxyMaxU'];
export const isMatingParamsCoefficient = (x: string): x is ValueOfArray<typeof matingParamsCoefficientCodes> =>
    includes(x, matingParamsCoefficientCodes);

export const matingParamsScoringWeightsCodes: Array<KeysOfType<MatingParamsSelectionFormValues, Nullable<number>>> = [
    'VhMlk',
    'VhTuk',
    'VhBlk',
    'VhSB',
    'VhKM',
    'VhOCD',
    'VhDlh',
    'VhOOP1',
    'VhDoj',
    'VhPld',
    'VhProd',
    'VhExt',
    'VhFnkcn',
    'VhZdr',
];
export const isMatingParamsScoringWeights = (x: string): x is ValueOfArray<typeof matingParamsScoringWeightsCodes> =>
    includes(x, matingParamsScoringWeightsCodes);

export const matingParamsExteriorTraitWeightsCodes:
Array<KeysOfType<MatingParamsSelectionFormValues, Nullable<NullableNumberPair>>> = [
    'TRA',
    'SHR',
    'HLT',
    'HRA',
    'SKZ',
    'SRZ',
    'PZZ',
    'PZB',
    'PAZ',
    'PUV',
    'RPS',
    'DST',
    'HLV',
    'VZU',
    'ZAV',
    'RZS',
    'SZU',
    'KVK',
    'CHO',
    'KND',
];
export const isMatingParamsExteriorTraitWeights =
    (x: string): x is ValueOfArray<typeof matingParamsExteriorTraitWeightsCodes> =>
        includes(x, matingParamsExteriorTraitWeightsCodes);
