import {TableColumn} from '@fl/cmsch-fe-library';

import {BasicRole} from 'api/gen/BasicRole';

export const subordinateRoleTableColumns: Array<TableColumn<BasicRole>> = [
    {
        field: 'name',
        type: 'string',
    },
    {
        field: 'code',
        type: 'string',
        filterTypes: ['null'],
    },
];
