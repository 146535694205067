import {excess} from '@fl/cmsch-fe-library';
import * as t from 'io-ts';

export const ValidateAsociationErrorsSchema = excess(t.partial({
    approvedFrom: t.string,
    approvedTo: t.string,
    roleIds: t.string,
    associationId: t.string,
}));

