import {isEmpty} from 'lodash/fp';
import {MouseEvent, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AnimalType} from 'api/gen/AnimalType';
import {TFunction, useOurTranslation} from 'app/translations';

import {animalBrowserAction, simpleAnimalBrowserSelector} from '../model';
import {MotherLinkProps} from '../types/mother-link-props';

interface UseAnimalsBrowserBasicSetup {
    tCommon: TFunction<'common'>;
    columnsT: TFunction<'cowBrowserTable'>;
    tooltipT: TFunction<'cowBrowserTableTooltip'>;
    exportTooltip: string;
    exportDisabled: boolean;
    exportToCSV(): void;
    handleMotherLink(row: MotherLinkProps): (e: MouseEvent) => void;
}

// eslint-disable-next-line max-lines-per-function
export const useAnimalsBrowserBasicSetup = (animalType: AnimalType): UseAnimalsBrowserBasicSetup => {
    const dispatch = useDispatch();
    const isFromCows = animalType === 'BREEDING_FEMALES';
    const exportToCSV = useCallback(() => {
        dispatch(isFromCows
            ? animalBrowserAction.exportToCsv()
            : animalBrowserAction.getCalvesCsv(),
        );
    }, [dispatch, isFromCows]);

    const handleMotherLink = useCallback((row: MotherLinkProps) => (e: MouseEvent) => {
        e.preventDefault();
        dispatch(animalBrowserAction.getMotherHuid(row.motherEarTag ?? '', row.huid ?? '', animalType, 'BASIC'));
    }, [dispatch, animalType]);

    const {t: actionsT, tCommon} = useOurTranslation('tableActions');
    const {t: columnsT} = useOurTranslation('cowBrowserTable');
    const {t: tooltipT} = useOurTranslation('cowBrowserTableTooltip');
    const animalBrowserState = useSelector(simpleAnimalBrowserSelector.animalBrowser);

    const {exportInProgress, cowBrowserPage, calfBrowserPage} = animalBrowserState;
    const page = isFromCows ? cowBrowserPage : calfBrowserPage;
    const content = page.prop('content').orElse([]);
    const exportDisabled = isEmpty(content) || exportInProgress;
    const exportTooltip = exportDisabled
        ? (actionsT(exportInProgress ? 'exportInProgress' : 'exportDisabled'))
        : '';

    return {
        tCommon,
        columnsT,
        tooltipT,
        exportTooltip,
        exportDisabled,
        exportToCSV,
        handleMotherLink,
    };
};
