// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-animal-browser-components-comprehensive-form-___styles__filterContainer___IlkMZ{display:grid;grid-template-columns:1fr 1fr;grid-column-gap:20px}@media (max-width: 991px){.src-app-animal-browser-components-comprehensive-form-___styles__filterContainer___IlkMZ{grid-template-columns:1fr;grid-column-gap:40px}}
`, "",{"version":3,"sources":["webpack://./src/app/animal-browser/components/comprehensive-form/styles.sass"],"names":[],"mappings":"AAAA,yFAAiB,YAAA,CAAa,6BAAA,CAA8B,oBAAA,CAAqB,0BAAyB,yFAAiB,yBAAA,CAA0B,oBAAA,CAAA","sourcesContent":[".filterContainer{display:grid;grid-template-columns:1fr 1fr;grid-column-gap:20px}@media(max-width: 991px){.filterContainer{grid-template-columns:1fr;grid-column-gap:40px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": `src-app-animal-browser-components-comprehensive-form-___styles__filterContainer___IlkMZ`
};
export default ___CSS_LOADER_EXPORT___;
