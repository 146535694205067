import {addAnimalsFromCsvSaga} from './add-animals-from-csv-saga';
import {addAnimalsFromDBSaga} from './add-animals-from-db-saga';
import {addAnimalsSaga} from './add-animals-saga';
import {confirmAnimalListSaga} from './confirm-animal-list-saga';
import {confirmCriteriaListSaga} from './confirm-criteria-list-saga';
import {createAnimalListSaga} from './create-animal-list-saga';
import {createCriteriaListSaga} from './create-criteria-list-saga';
import {deleteAnimalListSaga} from './delete-animal-list-saga';
import {deleteAnimalsFromListSaga} from './delete-animals-from-list-saga';
import {deleteCriteriaListSaga} from './delete-criteria-list-saga';
import {exportBullselectorModalResultSaga} from './export-bullselector-modal-result-saga';
import {exportMatingAnimalsSaga} from './export-mating-animals-saga';
import {filterAnimalsSaga} from './filter-animals-saga';
import {getAnimalListsSaga} from './get-animal-lists-saga';
import {getAnimalsCountSaga} from './get-animals-count';
import {getAnimalsCsvSaga} from './get-animals-csv-saga';
import {getAvailableAnimalsSaga} from './get-available-animals-saga';
import {getBreedersSaga} from './get-breeders-saga';
import {getCriteriaListDetailsSaga} from './get-criteria-list-details';
import {getCriteriaListsSaga} from './get-criteria-lists-saga';
import {getFarmsSaga} from './get-farms-saga';
import {getMatingCowsSaga} from './get-mating-cows-saga';
import {getMatingFleckviehBullsSaga} from './get-mating-fleckvieh-bulls-saga';
import {getMatingHolsteinBullsSaga} from './get-mating-holstein-bulls-saga';
import {getMatingScoreSaga} from './get-mating-score-saga';
import {getOrganizationsSaga} from './get-organizations-saga';
import {initializeFormsSaga} from './initialize-forms-saga';
import {recalculateBullPreferencesSaga} from './recalculate-bull-preferences-saga';
import {removeAnimalSaga} from './remove-animal-saga';
import {renameAnimalListSaga} from './rename-animal-list-saga';
import {renameCriteriaListSaga} from './rename-criteria-list-saga';
import {resetAccordionValuesSaga} from './reset-accordion-values-saga';
import {resetFormsSaga} from './reset-forms-saga';
import {resetScoringResultsSaga} from './reset-scoring-results-saga';
import {resetSingleCriteriaValueSaga} from './reset-single-criteria-value-saga';
import {saveScoringResultsToFileSaga} from './save-scoring-results-to-file-saga';
import {setMatingFormInitialValuesSaga} from './set-mating-form-initial-values-saga';

export const matingSagas = [
    getBreedersSaga,
    getFarmsSaga,
    getMatingCowsSaga,
    getMatingFleckviehBullsSaga,
    getMatingHolsteinBullsSaga,
    filterAnimalsSaga,
    addAnimalsFromCsvSaga,
    createAnimalListSaga,
    createCriteriaListSaga,
    renameAnimalListSaga,
    renameCriteriaListSaga,
    confirmAnimalListSaga,
    confirmCriteriaListSaga,
    deleteAnimalsFromListSaga,
    getAnimalsCsvSaga,
    getAvailableAnimalsSaga,
    addAnimalsSaga,
    addAnimalsFromDBSaga,
    removeAnimalSaga,
    getAnimalListsSaga,
    getCriteriaListsSaga,
    deleteAnimalListSaga,
    deleteCriteriaListSaga,
    setMatingFormInitialValuesSaga,
    resetAccordionValuesSaga,
    resetSingleCriteriaValueSaga,
    getCriteriaListDetailsSaga,
    saveScoringResultsToFileSaga,
    getOrganizationsSaga,
    getMatingScoreSaga,
    recalculateBullPreferencesSaga,
    initializeFormsSaga,
    resetFormsSaga,
    resetScoringResultsSaga,
    exportBullselectorModalResultSaga,
    exportMatingAnimalsSaga,
    getAnimalsCountSaga,
];
