import {DateString, DateStringNullable, Dictionary, NullableKeys} from '@fl/cmsch-fe-library';

import {RoleApproval} from 'api/gen/RoleApproval';

export const subjectFormName = 'SubjectForm';

export interface SubjectFormValues {
    approvedFrom: DateStringNullable;
    approvedTo: DateStringNullable;
    subjectId: number;
    roleIds: Array<number>;
    userProfileId: number;
    userProfileAssociationRoleIds: Array<number>;
}

export const subjectFormInitialValues: NullableKeys<SubjectFormValues> = {
    approvedFrom: null,
    approvedTo: null,
    subjectId: null,
    roleIds: [],
    userProfileId: null,
    userProfileAssociationRoleIds: [],
};

export interface SubjectFormEntryData {
    roleId: number | null;
    subjectId: number;
    userProfileSubjectRoleId: number | null;
    approvedFrom: DateString;
    approvedTo: DateStringNullable;
    roles: Array<RoleApproval>;
}

export type SubjectFormValuesErrors = Dictionary<keyof SubjectFormValues, string>;

export type SubjectFormType = 'ASSOCIATION' | 'OTHER_SUBJECT';
