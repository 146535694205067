import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {MatingBullsFilterFormValues} from '../../types';

export const validate = (values: MatingBullsFilterFormValues): Errors<MatingBullsFilterFormValues> => {
    const validator = new EskotValidator(values);

    validator.nonEmpty('organizationIds', t('mating/matingBullsTable')('organization'));

    return validator.generateErrorsObject();
};
