import {AnyTableColumn} from '@fl/cmsch-fe-library';
import {map} from 'ts-opt';

import {AnimalCodeName} from 'api/gen/AnimalCodeName';
import {EarTag} from 'api/gen/EarTag';
import {Inbreeding} from 'api/gen/Inbreeding';
import {LineRegistry} from 'api/gen/LineRegistry';
import {MatingScore} from 'api/gen/MatingScore';
import {TFunction} from 'app/translations';

import {getAnimalBrowserLink} from '../../utils';

const bullNumbers = [1, 2, 3, 4, 5] as const; // eslint-disable-line no-magic-numbers
type BullNumber = typeof bullNumbers[number];

type BullMappedTypes = {
    [key in `bull${BullNumber}LineRegistry`]: LineRegistry | undefined;
} & {
    [key in `bull${BullNumber}Score`]: MatingScore | undefined;
} & {
    [key in `bull${BullNumber}Inbreeding`]: Inbreeding | undefined;
} & {
    [key in `bull${BullNumber}Name`]: AnimalCodeName | undefined;
};

export interface ScoringResultsRow extends BullMappedTypes {
    id: number;
    earTag: EarTag;
}

const createBullColumn =
    // eslint-disable-next-line max-lines-per-function
    (t: TFunction<'mating/resultTab'>) => (bullNumber: BullNumber): AnyTableColumn<ScoringResultsRow> => ({
        column: t('bull', {number: bullNumber}),
        width: 150,
        type: 'grouping',
        subColumns: [
            {
                field: `bull${bullNumber}LineRegistry` as const,
                column: t('lineRegistry'),
                type: 'hyperlink',
                valueToUrl: getAnimalBrowserLink,
                openInNewTab: true,
                disableTooltip: true,
                disableFilter: true,
            },
            {
                field: `bull${bullNumber}Name` as const,
                column: t('bullName'),
                type: 'string',
                disableTooltip: true,
                disableFilter: true,
            },
            {
                field: `bull${bullNumber}Score` as const,
                column: t('score'),
                type: 'decimal',
                fixedDecimals: 2,
                disableTooltip: true,
                disableFilter: true,
            },
            {
                field: `bull${bullNumber}Inbreeding` as const,
                column: t('bullInbreeding'),
                type: 'decimal',
                fixedDecimals: 3,
                disableTooltip: true,
                disableFilter: true,
            },
        ],
    });

export const getScoringResultsColumns = (
    t: TFunction<'mating/resultTab'>,
    tCommon: TFunction<'common'>,
): Array<AnyTableColumn<ScoringResultsRow>> => [
    {
        field: 'earTag',
        column: tCommon('earTag'),
        tooltip: tCommon('cowEarTagTooltip'),
        type: 'hyperlink',
        valueToUrl: getAnimalBrowserLink,
        openInNewTab: true,
        width: 150,
        filterTypes: ['betweenEarTags'],
        disableFilter: true,
    },
    ...map(createBullColumn(t))(bullNumbers),
];
