import {notificationReducer} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {AnyAction, CombinedState, combineReducers, Reducer, ReducersMapObject} from 'redux';
import {RouterState} from 'redux-first-history';
import {reducer as formReducer} from 'redux-form';
import {pipe} from 'ts-opt';

import {adminReducer} from 'app/admin/model';
import {analysisProtocolCorrectionReducer} from 'app/analysis-protocol-correction/model/reducer';
import {animalBrowserReducer} from 'app/animal-browser';
import {legacyReducer} from 'app/legacy/model';
import {matingReducer} from 'app/mating/model';
import {milkabilityReducer} from 'app/milkability/model';
import {systemReducer} from 'app/system/model';
import {userDetailReducer} from 'app/user-detail/model';
import {userReducer} from 'app/user/model';
import {layoutReducer} from 'common/layout/model';
import {formsReducer} from 'utils/forms';
import {tablesReducer} from 'utils/tables';

import {AppAction} from './app-action';
import {defaultState} from './default-state';
import {State} from './state';

type StateWithoutRouter = Omit<State, 'router'>;

const reducers: ReducersMapObject<StateWithoutRouter, AnyAction> = {
    toastr: notificationReducer<AnyAction>(),
    // @ts-expect-error TODO: improve type
    form: (state, action) => formsReducer(formReducer(state, action), action),
    layout: layoutReducer,
    tables: tablesReducer,
    user: userReducer,
    admin: adminReducer,
    legacy: legacyReducer,
    mating: matingReducer,
    animalBrowser: animalBrowserReducer,
    milkability: milkabilityReducer,
    analysisProtocolCorrection: analysisProtocolCorrectionReducer,
    system: systemReducer,
    userDetail: userDetailReducer,
};

const stateO = O.optic<State>();

// Need access to the complete Redux state; individual reducers can manipulate their respective partial states.
const combinedReducersWithRootReducer = (combinedReducer: Reducer<CombinedState<State>, AnyAction>) =>
    (state: State | undefined, action: AppAction): State => {
        if (action.type === 'user/LOGOUT_CLEAR') {
            const clearedState: State = state
                ? pipe(
                    defaultState,
                    O.set(stateO.prop('router'))(state.router),
                    O.set(stateO.prop('layout')
                        .prop('statisticalCookieEnabled'))(state.layout.statisticalCookieEnabled),
                ) : defaultState;

            return combinedReducer(clearedState, {type: '@@INIT'});
        }

        return combinedReducer(state, action);
    };

export const createReducer = (routerReducer: Reducer<RouterState>): Reducer<State> => {
    const combinedReducer = combineReducers<State>({
        router: routerReducer,
        ...reducers,
    });

    return combinedReducersWithRootReducer(combinedReducer);
};
