import {Nullable, NullableKeys, TimeInputValue} from '@fl/cmsch-fe-library';

import {CreateMilkability} from 'api/gen/CreateMilkability';

export const milkabilityReportFormName = 'milkabilityReport';

export interface MilkabilityFormValues extends Omit<NullableKeys<CreateMilkability>, 'milkingTime'> {
    apmv: Nullable<number>;
    milkingTime: Nullable<TimeInputValue>;
}

export const initialMilkabilityFormValues: MilkabilityFormValues = {
    apmv: null,
    earTag: null,
    examinationDate: null,
    milkedTotal: null,
    milkingTime: null,
    technicianNumber: null,
};
