import { RefSelect, classNames, Options } from '@fl/cmsch-fe-library';
import React, { FC, memo, RefObject } from 'react';
import { Opt } from 'ts-opt';
import { ApcStableDate } from 'api/gen/ApcStableDate';
import { CorrectionType } from 'api/gen/CorrectionType';
import { Date } from 'api/gen/Date';
import { decimal52DecimalPlaces } from 'api/gen/Decimal52';
import { EarTag } from 'api/gen/EarTag';
import { StableCode } from 'api/gen/StableCode';
import { TechnicianNumber } from 'api/gen/TechnicianNumber';
import { Button } from 'common/buttons';
import { useNewApcReportFormSetup } from '../../hooks/use-new-apc-report-form-setup';
import { ApcReportFormValues } from './form-values';
import styles from './styles.sass';
interface Props {
  correctionType: CorrectionType | undefined;
  proficiencyTestDateOptions: Opt<Options<string>>;
  stableOptions: Opt<Options<string>>;
  stableDateRelations: Opt<ApcStableDate>;
  technicianOptions: Opt<Options<TechnicianNumber>>;
  technicianOptionsLoading: boolean;
  stableDateOptionsLoading: boolean;
  analysisProtocolLoading: boolean;
  stableNumber: StableCode | null;
  proficiencyTestDateValue: Date | null;
  earTagValue: EarTag | null;
  technicianNumberValue: string | null;
  earTagRef: RefObject<HTMLInputElement>;
  technicianNumberRef: RefObject<HTMLInputElement>;
  correctionTypeRef: RefSelect;
  editingCorrectionReport: boolean;
  setProficiencyTestDateOptions(options: Options<string>): void;
  setStableOptions(options: Options<string>): void;
  getAnalysisProtocol(earTag: EarTag, proficiencyTestDate: Date): void;
  getStableDateRelation(stableCode: StableCode, proficiencyTestDate: Date): void;
  asyncValidate(focusSubmit: () => void, getStableDateRelations?: boolean): void;
  onSubmit(formValues: ApcReportFormValues): void;
  onCancel(): void;
  resetWithoutCorrectionTypeAndTechnicianNumber(correctionType: CorrectionType | null, technicianNumber: string | null): void;
  clearAdditionalDataInProtocol(changedField: 'proficiencyTestDate' | 'stableNumber', value: string | null): void;
}

// eslint-disable-next-line max-lines-per-function
const ApcReportFormBase: FC<Props> = props => {
  const {
    correctionType,
    proficiencyTestDateOptions,
    stableOptions,
    stableDateRelations,
    // TODO temporarily disabled due to bad data in the database.
    //  Tag TECHNICIANS (https://redmine.favorlogic.com/issues/13226)
    // technicianOptions,
    stableDateOptionsLoading,
    technicianOptionsLoading,
    analysisProtocolLoading,
    stableNumber,
    proficiencyTestDateValue,
    earTagValue,
    earTagRef,
    editingCorrectionReport,
    correctionTypeRef,
    technicianNumberValue,
    technicianNumberRef,
    setProficiencyTestDateOptions,
    setStableOptions,
    getAnalysisProtocol,
    getStableDateRelation,
    asyncValidate,
    onSubmit,
    onCancel,
    resetWithoutCorrectionTypeAndTechnicianNumber,
    clearAdditionalDataInProtocol
  } = props;
  const {
    Form,
    Fields,
    correctionTypeOptions,
    earTagChangeRef,
    milkKgRef,
    proficiencyTestDateRef,
    stableNumberRef,
    t,
    tCommon,
    proficiencyTestDateDisabled,
    stableNumberDisabled,
    processingForm,
    isUpdateCorrection,
    submitDisabled,
    fatPercentageRef,
    proteinPercentageRef,
    lactosePercentageRef,
    somaticCellsRef,
    ureaRef,
    submitRef,
    onTechnicianNumberChange,
    onEarTagChange,
    onUpdatedEarTagChange,
    onProficiencyTestDateChange,
    onStableNumberChange,
    onCorrectionTypeChange,
    onMilkKgInput,
    onFatPercentageInput,
    onProteinPercentageInput,
    onLactosePercentageInput,
    onSomaticCellsInput,
    onUreaInput,
    isAdditionalFieldDisabled
  } = useNewApcReportFormSetup({
    stableDateRelations,
    stableNumber,
    proficiencyTestDateValue,
    earTagRef,
    technicianNumberRef,
    correctionType,
    earTagValue,
    technicianOptionsLoading,
    stableDateOptionsLoading,
    analysisProtocolLoading,
    editingCorrectionReport,
    stableOptions,
    proficiencyTestDateOptions,
    technicianNumberValue,
    setStableOptions,
    setProficiencyTestDateOptions,
    onSubmit,
    asyncValidate,
    getAnalysisProtocol,
    getStableDateRelation,
    resetWithoutCorrectionTypeAndTechnicianNumber,
    clearAdditionalDataInProtocol
  });
  return <Form data-sentry-element="Form" data-sentry-component="ApcReportFormBase" data-sentry-source-file="index.tsx">
            <div className={styles.formContainer}>
                <Fields.StringSelect name="correctionType" label={t('correctionType')} options={correctionTypeOptions} disabled={editingCorrectionReport} isRequired onFieldChange={onCorrectionTypeChange} autoFocus inputRef={correctionTypeRef} clearable data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <Fields.Input name="technicianNumber" label={t('technicianNumber')} onFieldChange={onTechnicianNumberChange} isRequired inputRef={technicianNumberRef} type="number" data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                {/* TODO temporarily disabled due to bad data in the database. Change for above
                 {/*  Tag TECHNICIANS (https://redmine.favorlogic.com/issues/13226)*/}
                {/* <Fields.StringSelect */}
                {/*    name="technicianNumber"*/}
                {/*    label={t('technicianNumber')}*/}
                {/*    disabled={!isAllowedToEditTechnicianNumber}*/}
                {/*    options={technicianOptions.orElse([])}*/}
                {/*    fieldIsLoading={technicianOptionsLoading}*/}
                {/*    onFieldChange={onTechnicianNumberChange}*/}
                {/*    isRequired*/}
                {/*    inputRef={technicianNumberRef}*/}
                {/*    searchable*/}
                {/*    clearable*/}
                {/* /> */}
                <div className={styles.doubleWidth}>
                    <Fields.EarTagInput name="earTag" label={t('earTag')} inputRef={earTagRef} isRequired disabled={editingCorrectionReport} onFieldChange={onEarTagChange} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div className={styles.doubleWidth}>
                    <Fields.StringSelect name="stableNumber" label={t('stableNumber')} isRequired options={stableOptions.orElse([])} fieldIsLoading={stableDateOptionsLoading} searchable disabled={stableNumberDisabled} inputRef={stableNumberRef} onFieldChange={onStableNumberChange} clearable data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div className={styles.doubleWidth}>
                    <Fields.StringSelect name="proficiencyTestDate" label={t('proficiencyTestDate')} isRequired options={proficiencyTestDateOptions.orElse([])} fieldIsLoading={stableDateOptionsLoading} onFieldChange={onProficiencyTestDateChange} inputRef={proficiencyTestDateRef} disabled={proficiencyTestDateDisabled()} searchable clearable data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div className={styles.doubleWidth}>
                    <Fields.EarTagInput name="updatedEarTag" label={t('earTagChange')} disabled={correctionType !== 'EAR_TAG_UPDATE'} isRequired={correctionType === 'EAR_TAG_UPDATE'} inputRef={earTagChangeRef} onFieldChange={onUpdatedEarTagChange} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <div className={styles.doubleWidth}>
                    <Fields.TripleAttribute name="milkingTripleAttribute" label={t('milkingTripleAttribute')} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </div>
                <Fields.NumberInput name="milkKg" label={t('milkKg')} disabled={!isUpdateCorrection} isRequired inputRef={milkKgRef} precision={decimal52DecimalPlaces} onFieldInput={onMilkKgInput} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <Fields.NumberInput name="fatPercentage" label={t('fatPercentage')} disabled={isAdditionalFieldDisabled('fatPercentage')} precision={decimal52DecimalPlaces} inputRef={fatPercentageRef} onFieldInput={onFatPercentageInput} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <Fields.NumberInput name="proteinPercentage" label={t('proteinPercentage')} disabled={isAdditionalFieldDisabled('proteinPercentage')} precision={decimal52DecimalPlaces} inputRef={proteinPercentageRef} onFieldInput={onProteinPercentageInput} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <Fields.NumberInput name="lactosePercentage" label={t('lactosePercentage')} disabled={isAdditionalFieldDisabled('lactosePercentage')} precision={decimal52DecimalPlaces} inputRef={lactosePercentageRef} onFieldInput={onLactosePercentageInput} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <Fields.NumberInput name="somaticCells" label={t('somaticCells')} disabled={isAdditionalFieldDisabled('somaticCells')} precision={0} inputRef={somaticCellsRef} onFieldInput={onSomaticCellsInput} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <Fields.NumberInput name="urea" label={t('urea')} disabled={isAdditionalFieldDisabled('urea')} precision={decimal52DecimalPlaces} inputRef={ureaRef} onFieldInput={onUreaInput} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <div className={classNames(styles.doubleWidth, styles.buttons)}>
                    <div>
                        <label className="invisible">.</label>
                        <Button onClick={onCancel} data-sentry-element="Button" data-sentry-source-file="index.tsx">{tCommon('cancel')}</Button>
                    </div>
                    <div>
                        <label className="invisible">.</label>
                        <Button type="submit" visuals="primary" loading={processingForm} testId="submit-button" disabled={submitDisabled} buttonRef={submitRef} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                            {tCommon(processingForm ? 'validating' : 'save')}
                        </Button>
                    </div>
                </div>
            </div>
        </Form>;
};
export const ApcReportForm = memo(ApcReportFormBase);