import {NullableKeys} from '@fl/cmsch-fe-library';

import {CreateOrUpdateRole} from 'api/gen/CreateOrUpdateRole';

export const roleFormName = 'role';

export const roleInitialValues: NullableKeys<CreateOrUpdateRole> = {
    code: '',
    discriminator: null,
    name: '',
    userEnable: false,
};
