import {TableDataRequest, ToolbarButton, ActionSettings} from '@fl/cmsch-fe-library';
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {PermissionDetail} from 'api/gen/PermissionDetail';
import {TFunction, useOurTranslation} from 'app/translations';
import {simpleUserSelector} from 'app/user/model/selector';
import useCurrentUserHasAnyPermission from 'app/user/utils/use-current-user-has-any-permission';

import {adminPermissions} from '../constants/permissions';
import {adminAction} from '../model/action';
import {simpleAdminSelector} from '../model/selector';

interface UsePermissionsScreenSetup {
    tTable: TFunction<'tables/table'>;
    t: TFunction<'admin/permissions'>;
    id: number | null;
    isCreateModalVisible: boolean;
    actionSettings: ActionSettings<PermissionDetail>;
    headerButtons: Array<ToolbarButton>;
    getPermissionDetails(tableDataRequest: Opt<TableDataRequest<PermissionDetail>>): void;
    handleCancelEditPermission(): void;
    handleCancelCreatePermission(): void;
    handleCreatePermission(): void;
    openCreatePermissionModal(): void;
    handleSubmitEditPermission(): void;
}

// eslint-disable-next-line max-lines-per-function
export const usePermissionsScreenSetup = (): UsePermissionsScreenSetup => {
    const dispatch = useDispatch();

    const {t: tTable} = useOurTranslation('tables/table');
    const {t} = useOurTranslation('admin/permissions');
    const id = useSelector(simpleAdminSelector.editedPermissionId);
    const isCreateModalVisible = useSelector(simpleAdminSelector.permissionCreateVisible);
    const userPermissions = useSelector(simpleUserSelector.currentUserPermissions);

    const getPermissionDetails = useCallback(
        (tableDataRequest: Opt<TableDataRequest<PermissionDetail>>): void => {
            dispatch(adminAction.getPermissionDetails(tableDataRequest));
        }, [dispatch]);

    const handleDeletePermission = useCallback((rowId: number) => {
        dispatch(adminAction.deletePermission(rowId));
    }, [dispatch]);

    const handleCancelEditPermission = useCallback(() => {
        dispatch(adminAction.setEditedPermissionId(null));
    }, [dispatch]);

    const handleCancelCreatePermission = useCallback(() => {
        dispatch(adminAction.setPermissionCreateVisibility(false));
    }, [dispatch]);

    const handleCreatePermission = useCallback(() => {
        dispatch(adminAction.createPermission());
    }, [dispatch]);

    const openCreatePermissionModal = useCallback(() => {
        dispatch(adminAction.setPermissionCreateVisibility(true));
    }, [dispatch]);

    const handleSubmitEditPermission = useCallback(() => {
        if (id) dispatch(adminAction.updatePermission(id));
    }, [dispatch, id]);

    const handleEditCallback = useCallback((rowId: number) => {
        dispatch(adminAction.setEditedPermissionId(rowId));
    }, [dispatch]);

    useEffect(() => {
        dispatch(adminAction.setEditedPermissionId(null));
        return () => {
            dispatch(adminAction.setEditedPermissionId(null));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const hasTableActionPermissions = useCurrentUserHasAnyPermission([
        adminPermissions.deletePermissons,
        adminPermissions.updatePermissions,
    ]);
    const actionSettings: ActionSettings<PermissionDetail> = useMemo(() => hasTableActionPermissions ? ({
        extraActions: [
            {
                id: 'delete',
                role: 'delete',
                callback: handleDeletePermission,
                hidden: !userPermissions.includes(adminPermissions.deletePermissons),
                confirmTitle: t('confirmDeleteRow'),
            },
            {
                id: 'edit',
                role: 'edit',
                callback: handleEditCallback,
                hidden: !userPermissions.includes(adminPermissions.updatePermissions),
            },
        ],
    }) : {}, [handleDeletePermission, handleEditCallback, t, userPermissions, hasTableActionPermissions]);

    const headerButtons: Array<ToolbarButton> = useMemo(() => [
        {
            title: t('newPermission'),
            role: 'create',
            onClick: openCreatePermissionModal,
            hidden: !userPermissions.includes(adminPermissions.createPermissions),
        },
    ], [openCreatePermissionModal, t, userPermissions]);

    return {
        tTable,
        t,
        id,
        isCreateModalVisible,
        getPermissionDetails,
        handleCancelEditPermission,
        handleCancelCreatePermission,
        handleCreatePermission,
        openCreatePermissionModal,
        handleSubmitEditPermission,
        actionSettings,
        headerButtons,
    };
};
