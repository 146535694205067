/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { TableColumn } from '@fl/cmsch-fe-library';
import React, { MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { MotherLinkProps } from 'app/animal-browser/types/mother-link-props';
import { getBullBrowserLink } from 'app/animal-browser/utils/get-bull-browser-link';
import { CabBreedingFemaleRow } from '../../types/cab-breeding-female-row';
import { getAnimalBrowserLinkForTable } from '../../utils/get-animal-browser-link';
export const getCabFemaleColumns = (handleMotherLink: (row: MotherLinkProps) => (e: MouseEvent) => void): Array<TableColumn<CabBreedingFemaleRow>> => [{
  field: 'earTag',
  type: 'hyperlink',
  filterTypes: ['betweenEarTags', 'null'],
  valueToUrl: getAnimalBrowserLinkForTable,
  openInNewTab: true
}, {
  field: 'name',
  type: 'string',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'breederRegistrationNumber',
  type: 'number',
  formatValue: String
}, {
  field: 'subjectBusinessName',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'farmCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'farmName',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'stableCode',
  type: 'string'
}, {
  field: 'stableName',
  type: 'string',
  filterTypes: ['null']
}, {
  field: 'districtCode',
  type: 'number',
  formatValue: String,
  filterTypes: ['null']
}, {
  field: 'organizationPtCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'organizationCenterPtCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'organizationInsCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'organizationCenterInsCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'fatherLineRegistry',
  type: 'router',
  filterTypes: ['null'],
  verticalName: true,
  openInNewTab: true,
  valueToLink: getBullBrowserLink('fatherLineRegistry')
}, {
  field: 'fatherName',
  type: 'string',
  verticalName: true,
  locked: false,
  filterTypes: ['null']
}, {
  field: 'breedAlpha6',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'studbookSection',
  type: 'string',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'birthDate',
  type: 'date',
  verticalName: true,
  dateFormat: 'date',
  filterTypes: ['null']
}, {
  field: 'currentLactationOrder',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'maxLactationOrder',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'maxLactationDays',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true,
  locked: false
}, {
  field: 'maxLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'maxLactationFatPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'maxLactationFatKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'maxLactationProteinPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'maxLactationProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'maxLactationFatProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'avgLactationCount',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'avgLactationDays',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'avgLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'avgLactationFatPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'avgLactationFatKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'avgLactationProteinPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'avgLactationProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationMilkKgSum',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationDays',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationFatPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationFatKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationProteinPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationLactosePercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationLactoseKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'proficiencyTestDate',
  type: 'date',
  dateFormat: 'date',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastMilkYield',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationSomaticCells',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'currentLactationUrea',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'calveDate',
  type: 'date',
  dateFormat: 'date',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationDate',
  type: 'date',
  dateFormat: 'date',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationOrder',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationDays',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationFatPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationFatKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationProteinPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastLactationFatProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lifelongLactationDaysSum',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lifelongStandardizedLactationDays',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lifelongLactationMilkKgSum',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lifelongLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lifelongLactationFatPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lifelongLactationFatKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lifelongLactationProteinPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lifelongLactationProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'daysFromBirth',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'milkKgAvgPerDay',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'milkKgAvgPerLactationDay',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'proficiencyTest',
  type: 'boolean',
  verticalName: true
}, {
  field: 'genotype',
  type: 'string',
  verticalName: true
}, {
  field: 'sihIndex',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'totalBreedingValue',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherEarTag',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true,
  formatValue: (value: string, row: MotherLinkProps): ReactNode => {
    const handleClick = handleMotherLink(row);
    return <Link to="#" onClick={handleClick} onAuxClick={handleClick}>
                    {value}
                </Link>;
  }
}, {
  field: 'mothersFatherLineRegistry',
  type: 'router',
  filterTypes: ['null'],
  verticalName: true,
  openInNewTab: true,
  valueToLink: getBullBrowserLink('mothersFatherLineRegistry')
}, {
  field: 'mothersFatherName',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'milkStrength',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'frame',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'bodyStructure',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'musculature',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'extremities',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'udder',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'exterior',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'lastMatingDate',
  type: 'date',
  dateFormat: 'date',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'inseminationOrder',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'servicePeriod',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'pregnant',
  type: 'boolean',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'changeCode',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'alive',
  type: 'boolean',
  verticalName: true
}];