import {opt, Opt} from 'ts-opt';

import {CriteriaDefinition} from 'api/gen/CriteriaDefinition';
import {WriteCriteria} from 'api/gen/WriteCriteria';

import {Criterias} from '../types/criterias';
import {
    MatingParamsSelectionFormValues,
    matingParamsCoefficientCodes,
    matingParamsExteriorTraitWeightsCodes,
    matingParamsScoringWeightsCodes,
} from '../types/mating-params-selection-form-values';

const getCriteriaDefinitionId = (criteriaDefinitions: Opt<Array<CriteriaDefinition>>, key: string): number =>
    criteriaDefinitions
        .filterIn(y => y.code === key)
        .headIn()
        .prop('id')
        .orCrash('Wrong criteria data');

// eslint-disable-next-line max-lines-per-function
export const getParsedWriteCriterias = (
    criterias: Criterias,
    criteriaDefinitions: Opt<Array<CriteriaDefinition>>,
): Array<WriteCriteria> => {
    const writeCriteria: Array<WriteCriteria> = [];

    for (const [key, value] of Object.entries(criterias.radios)) {
        if (value !== null && value !== undefined) {
            writeCriteria.push({
                criteriaDefinitionId: getCriteriaDefinitionId(criteriaDefinitions, key),
                operator: 'IS',
                values: {
                    tag: 'BOOL',
                    value,
                },
            });
        }
    }

    for (const [key, value] of Object.entries(criterias.dateRanges)) {
        if (value && (value[0] || value[1])) {
            writeCriteria.push({
                criteriaDefinitionId: getCriteriaDefinitionId(criteriaDefinitions, key),
                operator: 'BETWEEN',
                values: {
                    tag: 'DATE',
                    leftValue: value[0],
                    rightValue: value[1],
                },
            });
        }
    }

    for (const [key, value] of Object.entries(criterias.numberRanges)) {
        if (value?.selectedType === 'percentage') {
            writeCriteria.push({
                criteriaDefinitionId: getCriteriaDefinitionId(criteriaDefinitions, key),
                operator: 'TOP',
                values: {
                    tag: 'SINGLE_INT',
                    value: value.percentage,
                },
            });
        } else if (value?.selectedType === 'number' && (value.maximum !== null || value.minimum !== null)) {
            writeCriteria.push({
                criteriaDefinitionId: getCriteriaDefinitionId(criteriaDefinitions, key),
                operator: 'BETWEEN',
                values: {
                    tag: value.criteriaType,
                    leftValue: value.minimum,
                    rightValue: value.maximum,
                },
            });
        }
    }

    return writeCriteria;
};

// eslint-disable-next-line max-lines-per-function
export const getComputationParamsWriteCriterias = (
    formValues: MatingParamsSelectionFormValues,
    criteriaDefinitions: Opt<Array<CriteriaDefinition>>,
    coefficientCodes: typeof matingParamsCoefficientCodes,
    scoringWeightsCodes: typeof matingParamsScoringWeightsCodes,
    exteriorTraitWeightsCodes: typeof matingParamsExteriorTraitWeightsCodes,
): Array<WriteCriteria> => {
    const writeCriteria: Array<WriteCriteria> = [];

    coefficientCodes.forEach(coefficientFieldCode => {
        opt(formValues[coefficientFieldCode]).onSome(value => {
            writeCriteria.push({
                criteriaDefinitionId: getCriteriaDefinitionId(criteriaDefinitions, coefficientFieldCode),
                operator: 'LESS_THAN',
                values: {
                    tag: 'SINGLE_DECIMAL_4_3',
                    value,
                },
            });
        });
    });

    scoringWeightsCodes.forEach(scoringWeightsFieldCode => {
        opt(formValues[scoringWeightsFieldCode]).onSome(value => {
            writeCriteria.push({
                criteriaDefinitionId: getCriteriaDefinitionId(criteriaDefinitions, scoringWeightsFieldCode),
                operator: 'PERCENTAGE',
                values: {
                    tag: 'SINGLE_INT',
                    value,
                },
            });
        });
    });

    exteriorTraitWeightsCodes.forEach(exteriorTraitWeightsFieldCode => {
        opt(formValues[exteriorTraitWeightsFieldCode]).onSome(value => {
            writeCriteria.push({
                criteriaDefinitionId: getCriteriaDefinitionId(criteriaDefinitions, exteriorTraitWeightsFieldCode),
                operator: 'WEIGHT',
                values: {
                    tag: 'INT',
                    leftValue: value[0],
                    rightValue: value[1],
                },
            });
        });
    });

    return writeCriteria;
};
