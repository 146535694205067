import {Dictionary, convertAllErrorsFromBeToFormError, putAll, takeLatestF, validateSchema} from '@fl/cmsch-fe-library';
import {isUndefined, keys, omitBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {isEmpty, isFull, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ValidateCreateOrganizationRole} from 'api/gen/ValidateCreateOrganizationRole';
import {ValidateOrganizationRole} from 'api/gen/ValidateOrganizationRole';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {ValidateOrganizationErrorsSchema} from '../../types/errors/validate-organization-errors';
import {SubjectFacilityFormValuesErrors, subjectFacilityForm} from '../../types/subject-facility-form-values';
import {ValidateOrganizationsAction} from '../action';
import {simpleUserDetailSelector} from '../selector';
import {BuildCenters, buildCenterOptions} from './build-center-options';

interface ApiTypes {
    approvedFrom: string;
    approvedTo: string | null;
    subjectId: number;
    userProfileId: number;
    centerIds: Array<number> | null;
    farmIds: Array<number> | null;
    roleIds: Array<number> | null;
    userProfileRoleIds: Array<number> | null;
}
const getApiValues = ({
    subjectId,
    centerIds,
    userProfileRoleIds,
    roleIds,
    userProfileId,
    approvedFrom,
    approvedTo,
    farmIds,
}: ApiTypes): ValidateOrganizationRole => isEmpty(userProfileRoleIds)
    ? ({
        tag: 'ValidateCreateOrganizationRole',
        roleIds: opt(roleIds).orCrash('No roleIds found'),
        organizationId: subjectId,
        userProfileId,
        approvedFrom,
        approvedTo,
        organizationCenterIds: opt(centerIds).orCrash('No organizationCenterIds found'),
        farmIds: opt(farmIds).orCrash('No organizationFarmIds found'),
    })
    : ({
        tag: 'ValidateUpdateOrganizationRole',
        userProfileOrganizationRoleIds: opt(userProfileRoleIds)
            .orCrash('No userProfileOrganizationRoleIds found'),
        approvedTo,
    });

const mapErrors = (
    errors: Dictionary<keyof ValidateCreateOrganizationRole, string>,
): SubjectFacilityFormValuesErrors => {
    const validErrors = validateSchema(ValidateOrganizationErrorsSchema, 'FE')(errors);

    return omitBy(isUndefined)<SubjectFacilityFormValuesErrors>({
        approvedFrom: validErrors.approvedFrom,
        approvedTo: validErrors.approvedTo,
        centerIds: validErrors.organizationCenterIds,
        farmIds: validErrors.farmIds,
        roleIds: validErrors.roleIds,
        subjectId: validErrors.organizationId,
    });
};

// eslint-disable-next-line max-lines-per-function
function* execute(_: ValidateOrganizationsAction): SagaIterator {
    const {
        approvedFrom,
        approvedTo,
        subjectId,
        roleIds,
        centerIds,
        farmIds,
        userProfileId,
        userProfileRoleIds,
    } = (
        yield* select(formHelpers.formValues(subjectFacilityForm))
    ).orCrash(`no ${subjectFacilityForm} form values`);

    const organizationCenters = yield* select(simpleUserDetailSelector.centersOptions);
    const organizationFarms = yield* select(simpleUserDetailSelector.organizationFarmOptions);

    const pristine = yield* select(formHelpers.isPristine(subjectFacilityForm));

    const bothExists = isFull(organizationCenters) && isFull(organizationFarms);

    if (
        approvedFrom === null
        || subjectId === null
        || userProfileId === null
        || isEmpty(roleIds)
        || !bothExists && !isEmpty(organizationCenters) && isEmpty(centerIds)
        || !bothExists && !isEmpty(organizationFarms) && isEmpty(farmIds)
        || pristine
        || (approvedTo !== null && approvedFrom > approvedTo)
    ) return; // synchronously not valid

    yield* put(formHelpers.startAsyncValidation(subjectFacilityForm));

    const buildCenters: BuildCenters = {centerIds, type: 'ORGANIZATION_CENTER'};
    const buildFarms: BuildCenters = {centerIds: farmIds, type: 'ORGANIZATION_FARM'};

    const ocIds = yield* call(buildCenterOptions, buildCenters);
    const fIds = yield* call(buildCenterOptions, buildFarms);

    const response = yield* call(
        Api.validateUserProfileOrganizationRoleBinding,
        getApiValues({
            subjectId,
            roleIds,
            userProfileId,
            approvedFrom,
            approvedTo,
            userProfileRoleIds,
            centerIds: ocIds,
            farmIds: fIds,
        }),
    );

    if (!response.isSuccess) {
        if (response.isBadRequest) {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            const organizationErrors = mapErrors(errors.errors);
            yield* put(formHelpers.stopAsyncValidation(subjectFacilityForm, organizationErrors));

            const fieldsWithNewErrors = keys(organizationErrors) as Array<keyof typeof organizationErrors>;

            if (!isEmpty(fieldsWithNewErrors)) {
                yield* put(formHelpers.touch(subjectFacilityForm, ...fieldsWithNewErrors));
            }
        } else {
            yield putAll(showBeError(response, t('common')('validateEntry')));
        }
    } else {
        yield* put(formHelpers.stopAsyncValidation(subjectFacilityForm, {}));
    }
}

export function* validateOrganizationsSaga(): SagaIterator {
    yield takeLatestF('userDetail/VALIDATE_ORGANIZATIONS', execute);
}
