import {Nullable} from '@fl/cmsch-fe-library';
import {isEqual} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {select} from 'typed-redux-saga';

import {Farm} from 'api/gen/Farm';
import {OrganizationCenter} from 'api/gen/OrganizationCenter';
import {State} from 'app/setup';

import {allOptionValue} from '../../constants/consts';
import {simpleUserDetailSelector} from '../selector';

type BuildCentersType = 'ORGANIZATION_CENTER' | 'FARM' | 'ORGANIZATION_FARM';

type GetOptionsSelectorReturnType =
    | Array<OrganizationCenter>
    | Array<Farm>;

export interface BuildCenters {
    type: BuildCentersType;
    centerIds: Nullable<Array<number>>;
}

const getOptionsSelector = (type: BuildCentersType): (state: State) => GetOptionsSelectorReturnType => {
    switch (type) {
        case 'ORGANIZATION_CENTER':
            return simpleUserDetailSelector.centersOptions;
        case 'FARM':
            return simpleUserDetailSelector.farmOptions;
        case 'ORGANIZATION_FARM':
            return simpleUserDetailSelector.organizationFarmOptions;
    }
};

export function* buildCenterOptions({centerIds, type}: BuildCenters): SagaIterator<BuildCenters['centerIds']> {
    if (isEqual(centerIds, [allOptionValue])) {
        const optionsSelector = getOptionsSelector(type);
        const allCenters = opt((yield* select(optionsSelector))).orCrash(`Centers for ${type} not found`);

        return allCenters.map(c => c.id);
    }

    return centerIds ?? [];
}
