import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetUserBreedersAction, userAction} from '../action';

const tt = t('userDetail/sagas');
function* execute({payload: {tableDataRequest, userProfileId}}: GetUserBreedersAction): SagaIterator {
    if (tableDataRequest.isEmpty) return yield* put(userAction.getUserBreedersSuccess(none));
    yield* put(tablesAction.tableDataFetchStart('userBreedersProfile'));

    const tableState = yield* select(simpleTableSelector.tableState('userBreedersProfile'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'userBreedersProfile',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest for roles.'),
        tableState,
        resetType: 'tableDataParams',
    });

    const columnSettings = getRequestedTableColumnSettings<'userBreedersProfile'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.findLoggedInUserBreederRoles, filters, params, {userProfileId});

    if (response.isSuccess) {
        yield* put(userAction.getUserBreedersSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('userBreedersProfile', tableDataParams));
    } else {
        yield putAll(showBeError(response, tt('loadBreeders')));
        yield* put(tablesAction.tableDataFetchFailure('userBreedersProfile'));
    }
}
export function* getUserBreedersSaga(): SagaIterator {
    yield takeLatestF('user/GET_USER_BREEDERS', execute);
}
