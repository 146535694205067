const controller = 'HlaseniDojitelnostiKU';
export const milkabilityPermissions = {
    updateTechnician: `${controller}/EditHlaseniTechnik`,
    updateLeaderTechnician: `${controller}/EditHlaseniVedouciTechnik`,
    updateAdmin: `${controller}/EditHlaseniAdmin`,
    listTechnician: `${controller}/SeznamHlaseniTechnik`,
    listLeaderTechnician: `${controller}/SeznamHlaseniVedouciTechnik`,
    listAdmin: `${controller}/SeznamHlaseniAdmin`,
};

const reproductionController = 'Reprodukce';
export const legacyMilkabilityPermissions = {
    reportBatchList: `${reproductionController}/SeznamDavekHlaseni`,
};
