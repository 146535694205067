import {NullableKeys} from '@fl/cmsch-fe-library';
import {genNakedPropOrCrash, opt, optEmptyString} from 'ts-opt';

import {CreatePermission} from 'api/gen/CreatePermission';
import {UpdatePermission} from 'api/gen/UpdatePermission';
import {State} from 'app/setup';
import {formHelpers} from 'utils/forms';

import {createOrUpdatePermissionFormName, CreateOrUpdatePermissionFormValues} from '../types/permission-form-values';

export const buildPermissionCreateValuesForApi =
(values: NullableKeys<CreateOrUpdatePermissionFormValues>): CreatePermission => {
    const get = genNakedPropOrCrash(values);

    return {
        actionName: get('actionName'),
        code: get('code'),
        controllerName: get('controllerName'),
        comment: optEmptyString(values.comment).orNull(),
        discriminator: get('discriminator'),
        userEnable: opt(values.userEnable).orFalse(),
    };
};

export const buildPermissionUpdateValuesForApi =
    (values: NullableKeys<CreateOrUpdatePermissionFormValues>): UpdatePermission => ({
        code: opt(values.code).orCrash('missing code'),
        comment: optEmptyString(values.comment).orNull(),
        discriminator: opt(values.discriminator).orCrash('missing discriminator'),
        userEnable: opt(values.userEnable).orFalse(),
    });

export const getPermissionFormValues = (state: State): NullableKeys<CreateOrUpdatePermissionFormValues> =>
    formHelpers.formValues(createOrUpdatePermissionFormName)(state).orCrash('no permission form values');
