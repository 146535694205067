import {Options} from '@fl/cmsch-fe-library';

import {OrganizationCenterAutocomplete} from 'api/gen/OrganizationCenterAutocomplete';

export const genCentersOptions = (elements: Array<OrganizationCenterAutocomplete>): Options<number> =>
    elements.map(option => ({
        value: option.id,
        label: option.organizationCenterCode
            .concat(option.organizationName ? ` - ${option.organizationName}` : '')
            .concat(option.organizationCode ? ` (${option.organizationCode})` : ''),
    }));
