/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { beDateFormat, classNames, usePrevious, validateKeyGen, superUniqueClassName, useWindowDimensions, LG } from '@fl/cmsch-fe-library';
import { debounce, replace } from 'lodash/fp';
import moment, { Moment } from 'moment';
import React, { FC, memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { earTagRegexGen } from 'api/gen/EarTag';
import { milkedTotalRangeMaximum, milkedTotalRangeMinimum } from 'api/gen/MilkedTotalRange';
import { technicianNumberRegexGen } from 'api/gen/TechnicianNumber';
import { doubleGutter, gutter } from 'app/consts';
import { isDateWithinTwoControlYears } from 'app/milkability/utils/is-date-within-two-control-years';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { countDecimalPlaces } from 'utils/count-decimal-places';
import { useForm, formHelpers } from 'utils/forms';
import { insertedDateOnKeyboard } from 'utils/inserted-date-on-keyboard';
import { initialMilkabilityFormValues, milkabilityReportFormName, MilkabilityFormValues } from './form-values';
import { validate } from './validate';
import styles from './styles.sass';

/**
 * <h3>Milkability report form</h3>
 *
 * <ul>
 *  <li>When there is a synchronously valid value entered into a field, the next field gets focused.</li>
 *  <li>On change of any field, the <i>apmv</i> value is reset and async validation runs.</li>
 *  <li>When editing an existing milkability report, the ear tag input is disabled.</li>
 *  <li>When a new report is submitted, it is added to the created reports table.</li>
 *  <li>When a report is successfully created/updated, the form is reset to default state.</li>
 *  <li>When creating a new milkability report, the initial focus should be on technician number.</li>
 * </ul>
 */

const waitTime = 1000;
const earTagLastCharIndex = 13;
interface Props {
  /** Is async validation in progress */
  validatingMilkabilityReportForm?: boolean;
  editingMilkabilityReport: boolean;
  earTagInputRef: RefObject<HTMLInputElement>;
  technicianNumberInputRef: RefObject<HTMLInputElement>;
  validateMilkabilityData(): void;
  onSubmit(data: MilkabilityFormValues): void;
  /** Gets called on change of any field */
  calculateApmvAndValidateReport(): void;
  /** Resets form to initial state */
  cancelEditMilkabilityReport(): void;
}
const isMilkedTotalValid = (value: number): boolean => milkedTotalRangeMinimum <= value && value <= milkedTotalRangeMaximum;
const isDateValidForReport = (date: Moment): boolean => {
  const today = moment();
  return date.isValid() && isDateWithinTwoControlYears(date, today) && today.isAfter(date);
};
const validateKey = validateKeyGen<MilkabilityFormValues>();
const numberWithOneDecimalRegex = /^\d{0,2}[,.]\d$/;
const MilkabilityReportFormBase: FC<Props> = props => {
  const {
    validatingMilkabilityReportForm,
    editingMilkabilityReport,
    earTagInputRef,
    technicianNumberInputRef,
    validateMilkabilityData,
    calculateApmvAndValidateReport,
    cancelEditMilkabilityReport,
    onSubmit
  } = props;
  const examinationDateRef = useRef<HTMLInputElement>(null);
  const milkedTotalRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);
  const {
    t,
    tCommon
  } = useOurTranslation('milkability/overviewTable');
  const [dateWasEmpty, setDateWasEmpty] = useState(true);
  const dateValue = useSelector(formHelpers.formValues(milkabilityReportFormName)).prop('examinationDate');
  const earTagValue = useSelector(formHelpers.formValues(milkabilityReportFormName)).prop('earTag').orNull();
  const earTagPreviousValue = usePrevious(earTagValue);
  const [savedEarTag, setSavedEarTag] = useState(earTagPreviousValue);
  const {
    width
  } = useWindowDimensions();
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const {
    Form,
    Fields,
    change,
    blur,
    touch,
    submitting,
    initialized
  } = useForm({
    form: milkabilityReportFormName,
    initialValues: initialMilkabilityFormValues,
    onSubmit,
    validate
  });
  const previousSubmitting = usePrevious(submitting);
  useEffect(() => {
    if (earTagPreviousValue && submitting !== previousSubmitting) {
      setSavedEarTag(earTagPreviousValue);
    }
  }, [earTagPreviousValue, previousSubmitting, submitting]);

  // If date was not empty, and it was typed manually, don't automatically focus next field
  useEffect(() => {
    if (earTagPreviousValue) {
      setSavedEarTag(earTagPreviousValue);
    }
    if (!dateValue.isEmpty) {
      setDateWasEmpty(false);
    }
    return (): void => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (savedEarTag && submitting !== previousSubmitting) {
      change('earTag', savedEarTag);
    }
  }, [change, previousSubmitting, savedEarTag, submitting]);
  useEffect(() => {
    if (dateValue.isEmpty) {
      setDateWasEmpty(true);
    }
  }, [dateValue]);
  useEffect(() => {
    if (initialized) {
      technicianNumberInputRef.current?.focus();
    }
  }, [editingMilkabilityReport, initialized, technicianNumberInputRef]);
  const validateMilkabilityDataDebounced = useMemo(() => debounce(waitTime, validateMilkabilityData), [validateMilkabilityData]);
  const calculateApmvAndValidateReportDebounced = useMemo(() => debounce(waitTime, calculateApmvAndValidateReport), [calculateApmvAndValidateReport]);
  const onChange = useCallback(() => {
    change('apmv', null);
    calculateApmvAndValidateReportDebounced();
  }, [calculateApmvAndValidateReportDebounced, change]);
  const handleCancel = useCallback(() => {
    cancelEditMilkabilityReport();
    setDateWasEmpty(true);
  }, [cancelEditMilkabilityReport]);
  const onDateChange = useCallback((value: Opt<string>) => {
    onChange();
    setDateWasEmpty(false);
    value.onSome(date => {
      const momentDate = moment(date, beDateFormat);
      if (isDateValidForReport(momentDate)) {
        /* HACK for issue #11076 http://redmine.favorlogic.com/issues/11076 ant is adding ant-picker-focused
         * and we need to remove it
         */
        const [element] = document.getElementsByClassName(superUniqueClassName);
        element?.classList.remove('ant-picker-focused');
        examinationDateRef.current?.blur();
        (editingMilkabilityReport ? milkedTotalRef : earTagInputRef).current?.focus();
        blur('examinationDate', date);
      }
    });
  }, [onChange, blur, editingMilkabilityReport, earTagInputRef]);
  const onTechnicianNumberChange = useCallback((value: Opt<string>) => {
    validateMilkabilityDataDebounced();
    if (value.map(val => val.match(technicianNumberRegexGen())).orFalse()) {
      setTimeout(() => examinationDateRef.current?.focus());
    }
  }, [validateMilkabilityDataDebounced]);

  // If last character of ear tag was inputted, focus next field
  const onEarTagChange = useCallback((value: Opt<string>) => {
    onChange();
    value.onSome(val => {
      if (earTagValue?.indexOf('_') === earTagLastCharIndex && !val.includes('_')) {
        setTimeout(() => milkedTotalRef.current?.focus());
      }
    });
  }, [earTagValue, onChange]);
  const onEarTagPaste = useCallback((value: Opt<string>) => {
    onChange();
    if (value.orElse('').match(earTagRegexGen())) {
      if (timeoutId) clearTimeout(timeoutId);
      setTimeoutId(setTimeout(() => milkedTotalRef.current?.focus()));
    }
  }, [onChange, timeoutId]);

  // If inputted value matches number with one decimal pattern and is valid, focus next field
  const handleMilkedTotalInput = useCallback((text: string) => {
    if (text.match(numberWithOneDecimalRegex)) {
      const numberValue = parseFloat(replace(',', '.')(text));
      if (!isNaN(numberValue) && isMilkedTotalValid(numberValue)) {
        // order is important
        timeInputRef.current?.focus();
        change('milkedTotal', numberValue);
      } else {
        touch('milkedTotal');
      }
    } else {
      if (countDecimalPlaces(text) > 1) {
        timeInputRef.current?.focus();
      }
    }
  }, [change, touch]);
  const handleDatePickerKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    // Change tab behavior from focusing date picker dropdown to focusing form fields
    const previousDate = e.currentTarget.value;
    const position = e.currentTarget.selectionStart;
    const {
      key,
      shiftKey
    } = e;
    if (key === 'Tab' && !editingMilkabilityReport) {
      e.preventDefault();
      if (shiftKey) {
        technicianNumberInputRef.current?.focus();
      } else {
        earTagInputRef.current?.focus();
      }
    }
    const {
      date,
      formattedDate,
      isValid,
      isEmpty
    } = insertedDateOnKeyboard(previousDate, key, position);
    // Every time, when is Date in valid format, set value of examinationDate, cause of showing errors message
    if (isValid) {
      change('examinationDate', formattedDate);
      if (isDateValidForReport(date) && dateWasEmpty) {
        setDateWasEmpty(false);
        // If a valid date is inputted, focus next, non-disabled field
        setTimeout(() => (editingMilkabilityReport ? milkedTotalRef : earTagInputRef).current?.focus());
      }
    } else if (isEmpty) {
      change('examinationDate', null);
    }
  }, [change, dateWasEmpty, earTagInputRef, editingMilkabilityReport, technicianNumberInputRef]);
  const classes = classNames(styles.reportForm, width < LG && styles.disabledHover);
  return <Form data-sentry-element="Form" data-sentry-component="MilkabilityReportFormBase" data-sentry-source-file="index.tsx">
            <Ant.Row gutter={[doubleGutter, gutter]} justify="end" className={classes} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Ant.Col xs={24} md={12} lg={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Fields.Input type="number" name={validateKey('technicianNumber')} label={t('technicianNumber')} onFieldChange={onTechnicianNumberChange} inputRef={technicianNumberInputRef} isRequired autoFocus={initialized && editingMilkabilityReport} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </Ant.Col>
                <Ant.Col xs={24} md={12} lg={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Fields.DateInput name={validateKey('examinationDate')} label={t('examinationDate')} onFieldChange={onDateChange} dontIncludeFuture inputRef={examinationDateRef} onKeyDown={handleDatePickerKeyDown} isRequired clearable data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </Ant.Col>
                <Ant.Col xs={24} md={12} lg={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Fields.EarTagInput name={validateKey('earTag')} label={tCommon('earTagTooltip')} placeholder={tCommon('earTagPlaceholder')} onFieldChange={onEarTagChange} disabled={editingMilkabilityReport} inputRef={earTagInputRef} isRequired onPasting={onEarTagPaste} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </Ant.Col>
                <Ant.Col xs={24} md={12} lg={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Fields.NumberInput name={validateKey('milkedTotal')} label={t('milkedTotal')} onFieldChange={onChange} inputRef={milkedTotalRef} fullWidth isRequired disableArrowControls onFieldInput={handleMilkedTotalInput} precision={1} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </Ant.Col>
                <Ant.Col xs={24} md={12} lg={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Fields.TimeInput name={validateKey('milkingTime')} label={t('milkingTime')} isRequired onFieldChange={onChange} inputRef={timeInputRef} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </Ant.Col>
                <Ant.Col xs={24} md={12} lg={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Fields.NumberInput name={validateKey('apmv')} label={t('apmv')} fullWidth disabled precision={2} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                </Ant.Col>
                <Ant.Col xs={24} lg={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <label className="invisible">.</label>
                    <Button type="button" loading={submitting || validatingMilkabilityReportForm} onClick={handleCancel} block data-sentry-element="Button" data-sentry-source-file="index.tsx">
                        {tCommon('cancel')}
                    </Button>
                </Ant.Col>
                <Ant.Col xs={24} lg={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <label className="invisible">.</label>
                    <Button type="submit" visuals="primary" key="submit" loading={submitting || validatingMilkabilityReportForm} block testId="submit-button" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                        {tCommon(validatingMilkabilityReportForm ? 'validating' : 'save')}
                    </Button>
                </Ant.Col>
            </Ant.Row>
        </Form>;
};
export const MilkabilityReportForm = memo(MilkabilityReportFormBase);