import { ActionSettings, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo, useCallback, useMemo } from 'react';
import { Opt } from 'ts-opt';
import { RolePermission } from 'api/gen/RolePermission';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { ConnectedTable } from 'utils/tables';
import { simpleAdminSelector } from '../../model/selector';
import { getPermissionTableColumns } from '../../utils/get-permissions-table-columns';
interface Props {
  roleId: string;
  canUpdateRole: boolean;
  getRoleUnassignedPermissions(roleId: string, tableDataRequest: Opt<TableDataRequest<RolePermission>>): void;
  addPermissionToRole(roleId: string, permissionId: number): void;
}
const RoleUnassignedPermissionsTableBase: FC<Props> = ({
  addPermissionToRole,
  getRoleUnassignedPermissions,
  roleId,
  canUpdateRole
}) => {
  const onTableFetch = useCallback((tableDataRequest: Opt<TableDataRequest<RolePermission>>) => {
    getRoleUnassignedPermissions(roleId, tableDataRequest);
  }, [roleId, getRoleUnassignedPermissions]);
  const onAddClick = useCallback((_roleId: number, permission: RolePermission) => {
    addPermissionToRole(roleId, permission.id);
  }, [roleId, addPermissionToRole]);
  const {
    t,
    tCommon
  } = useOurTranslation('admin/roleUnassignedPermissionsTable');
  const actionSettings: ActionSettings<RolePermission> = useMemo(() => ({
    extraActions: [{
      id: 'addPermission',
      title: tCommon('add'),
      icon: 'plusSquareFilled',
      callback: onAddClick,
      hidden: !canUpdateRole
    }]
  }), [canUpdateRole, onAddClick, tCommon]);
  return <Fragment>
            <div className="mx-2">
                <Ant.Typography.Title level={5} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {t('unassignedPermissions')}
                </Ant.Typography.Title>
            </div>
            <ConnectedTable tableId="roleUnassignedPermissions" columns={getPermissionTableColumns} onFetchTableData={onTableFetch} rowDataPageSelector={simpleAdminSelector.roleUnassignedPermissionsPage} actionSettings={actionSettings} withoutVerticalScrollbar lazyLoadingLimit={20} showFulltextFilter enableTableSettings enableTableUserSettings data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
        </Fragment>;
};
export const RoleUnassignedPermissionsTable = memo(RoleUnassignedPermissionsTableBase);