import {takeLatestF, showAppError} from '@fl/cmsch-fe-library';
import {cloneDeep} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Bookmark} from 'api/gen/Bookmark';
import {logger} from 'app/sentry-logger';
import {formHelpers} from 'utils/forms';

import {initialValues} from '../../components/params-selection-tab/params-helpers';
import {matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';
import {matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';
import {
    MatingParamsSelectionFormValues,
    matingParamsSelectionFormName,
    matingParamsCoefficientCodes,
    matingParamsScoringWeightsCodes,
    matingParamsExteriorTraitWeightsCodes,
} from '../../types/mating-params-selection-form-values';
import {getDefaultCriteriaValues} from '../../utils/get-default-criteria-values';
import {SetMatingFormInitialValuesAction} from '../action';
import {matingSelector} from '../selector';

const processFieldError = (fieldCode: string, errorType: 'missing' | 'wrongType'): void => {
    const errorMsg = errorType === 'missing'
        ? `Missing mating params field definition for code: ${fieldCode}`
        : `Field code ${fieldCode} has wrong type`;
    logger.logError(new Error(errorMsg));
    showAppError({
        title: 'Načtení formuláře parametrů',
        message: errorType === 'missing'
            ? `V databázi chybí definice pole s kódem ${fieldCode}.`
            : `Pole s kódem ${fieldCode} má v databázi špatnou definici.`,
    });
};

function* initializeCowsFilterForm(): SagaIterator {
    const cowCriteriaDefinitions = (yield* select(matingSelector.cowCriteriaDefinitions))
        .orCrash('criteria definitions not available');

    yield* put(
        formHelpers.initialize(matingCowsFilterFormName, {
            criterias: getDefaultCriteriaValues(cowCriteriaDefinitions),
            farmIds: null,
            breederId: null,
            validationHack: null,
        }),
    );
}

function* initializeBullsFilterForm(): SagaIterator {
    const bullCriteriaDefinitions = (yield* select(matingSelector.bullCriteriaDefinitions))
        .orCrash('criteria definitions not available');

    yield* put(
        formHelpers.initialize(matingBullsFilterFormName, {
            criterias: getDefaultCriteriaValues(bullCriteriaDefinitions),
            organizationIds: null,
            validationHack: null,
        }),
    );
}

// eslint-disable-next-line max-lines-per-function
function* initializeParamsFilterForm(): SagaIterator {
    const clonedInitialValues: MatingParamsSelectionFormValues = cloneDeep(initialValues);

    const paramsCriteriaDefinitions = (yield* select(matingSelector.paramsCriteriaDefinitions))
        .orCrash('criteria definitions not available');

    matingParamsCoefficientCodes.forEach(fieldCode => {
        const definition = paramsCriteriaDefinitions.find(x => x.code === fieldCode);
        if (definition) {
            if (definition.tag === 'DECIMAL_4_3') {
                clonedInitialValues[fieldCode] = definition.values.leftValue;
            } else {
                processFieldError(fieldCode, 'missing');
            }
        } else {
            processFieldError(fieldCode, 'wrongType');
        }
    });

    matingParamsScoringWeightsCodes.forEach(fieldCode => {
        const definition = paramsCriteriaDefinitions.find(x => x.code === fieldCode);
        if (definition) {
            if (definition.tag === 'INT') {
                clonedInitialValues[fieldCode] = definition.values.leftValue;
            } else {
                processFieldError(fieldCode, 'missing');
            }
        } else {
            processFieldError(fieldCode, 'wrongType');
        }
    });

    matingParamsExteriorTraitWeightsCodes.forEach(fieldCode => {
        const definition = paramsCriteriaDefinitions.find(x => x.code === fieldCode);
        if (definition) {
            if (definition.tag === 'INT') {
                clonedInitialValues[fieldCode] = [
                    definition.values.leftValue,
                    definition.values.rightValue,
                ];
            } else {
                processFieldError(fieldCode, 'missing');
            }
        } else {
            processFieldError(fieldCode, 'wrongType');
        }
    });

    yield* put(formHelpers.initialize(matingParamsSelectionFormName, clonedInitialValues));
}

export function* setMatingFormInitialValues(bookmark: Bookmark): SagaIterator {
    if (bookmark === 'FEMALE') {
        yield* call(initializeCowsFilterForm);
    } else if (bookmark === 'MALE') {
        yield* call(initializeBullsFilterForm);
    } else {
        yield* call(initializeParamsFilterForm);
    }
}

function* execute({payload: {bookmark}}: SetMatingFormInitialValuesAction): SagaIterator {
    yield* call(setMatingFormInitialValues, bookmark);
}

export function* setMatingFormInitialValuesSaga(): SagaIterator {
    yield takeLatestF('mating/SET_MATING_FORM_INITIAL_VALUES', execute);
}
