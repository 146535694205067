import {putAll, takeLatestF, AuthData, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appRoutePaths} from 'app/routes/route-paths';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {loginFormName} from '../../types/login-form-values';
import {userAction} from '../action';
import {LocationState, afterBeLoginSetup} from './saga-utils';

const getLoginRouteState = (state: State): LocationState | undefined =>
    opt(state.router.location)
        .prop('state')
        .orUndef();

function* execute(): SagaIterator {
    yield* put(formHelpers.startSubmit(loginFormName));

    const loginFormValues = (yield* select(formHelpers.formValues(loginFormName)))
        .orCrash('Chybí přihlašovací údaje');
    const authData: AuthData = {
        username: opt(loginFormValues.username).orCrash('missing username'),
        password: opt(loginFormValues.password).orCrash('missing password'),
    };

    const routeState = yield* select(getLoginRouteState);

    const response = yield* call(Api.login, authData);

    if (response.isSuccess) {
        const redirectRoute = !routeState?.redirectTo || routeState.redirectTo === '/login'
            ? appRoutePaths.home
            : routeState.redirectTo;
        yield* put(routerActions.push(redirectRoute));
        yield* call(afterBeLoginSetup, true, authData);
        yield* put(formHelpers.reset('login'));
        yield* put(formHelpers.stopSubmit(loginFormName, {}));
    } else {
        yield putAll(showBeError(response, t('user/sagas')('login'), {isLogin: true}));
        yield* put(formHelpers.stopSubmit(loginFormName, extractFormErrorsFromResponse(response)));
    }

    yield* put(userAction.initializeFinish());
}

export function* loginSaga(): SagaIterator {
    yield takeLatestF('user/LOGIN', execute);
}
