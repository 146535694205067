import {isEmpty} from 'lodash/fp';
import {createSelector} from 'reselect';
import {Opt} from 'ts-opt';

import {Accordion} from 'api/gen/Accordion';
import {AnimalList} from 'api/gen/AnimalList';
import {AvailableAnimal} from 'api/gen/AvailableAnimal';
import {Bookmark} from 'api/gen/Bookmark';
import {BreederToChoose} from 'api/gen/BreederToChoose';
import {CreateOrUpdateGridUserSettings} from 'api/gen/CreateOrUpdateGridUserSettings';
import {CriteriaDefinition} from 'api/gen/CriteriaDefinition';
import {CriteriaList} from 'api/gen/CriteriaList';
import {FarmToChoose} from 'api/gen/FarmToChoose';
import {MatingResult} from 'api/gen/MatingResult';
import {OrganizationToChoose} from 'api/gen/OrganizationToChoose';
import {PagedModelFemaleHolsteinAnimalInList} from 'api/gen/PagedModelFemaleHolsteinAnimalInList';
import {PagedModelMaleFleckviehAnimalInList} from 'api/gen/PagedModelMaleFleckviehAnimalInList';
import {PagedModelMaleHolsteinAnimalInList} from 'api/gen/PagedModelMaleHolsteinAnimalInList';
import {ScoringResult} from 'api/gen/ScoringResult';
import {Sex} from 'api/gen/Sex';
import {WriteCriteria} from 'api/gen/WriteCriteria';
import {State} from 'app/setup';

import {BullPreference, MatingLoadingId, MatingTabKey} from '../types';
import {SessionState} from './state';

const female: Sex = 'FEMALE';
const male: Sex = 'MALE';

export const matingSelector = {
    maleFleckviehAnimalsPage: ({mating}: State): Opt<PagedModelMaleFleckviehAnimalInList> =>
        mating.maleFleckviehAnimalsPage,
    maleHolsteinAnimalsPage: ({mating}: State): Opt<PagedModelMaleHolsteinAnimalInList> =>
        mating.maleHolsteinAnimalsPage,
    femaleAnimalsPage: ({mating}: State): Opt<PagedModelFemaleHolsteinAnimalInList> => mating.femaleAnimalsPage,
    writeCriteria: ({mating}: State): Opt<Array<WriteCriteria>> => mating.writeCriteria,
    loading: ({mating}: State): Array<MatingLoadingId> => mating.loading,
    activeTab: ({mating}: State): MatingTabKey => mating.activeTab,
    animalListsTag: ({mating}: State): Opt<Sex> => mating.animalListsTag,
    animalLists: ({mating}: State): Opt<Array<AnimalList>> => mating.animalLists,
    criteriaListsTag: ({mating}: State): Opt<Bookmark> => mating.criteriaListsTag,
    breeders: ({mating}: State): Opt<Array<BreederToChoose>> => mating.breeders,
    farms: ({mating}: State): Opt<Array<FarmToChoose>> => mating.farms,
    organizations: ({mating}: State): Opt<Array<OrganizationToChoose>> => mating.organizations,
    paramsCriteriaDefinitions: ({mating}: State): Opt<Array<CriteriaDefinition>> => mating.paramsCriteriaDefinitions,
    criteriaLists: ({mating}: State): Opt<Array<CriteriaList>> => mating.criteriaLists,
    listsOverviewModalData: ({mating}: State): Bookmark | null => mating.listsOverviewModalData,
    criteriaListsModalData: ({mating}: State): Bookmark | null => mating.criteriaListsModalData,
    criteriaListsConfirmModalData: ({mating}: State): Bookmark | null => mating.criteriaListsConfirmModalData,
    criteriaListDetailsModalData: ({mating}: State): Bookmark | null => mating.criteriaListDetailsModalData,
    criteriaListId: ({mating}: State): Opt<number> => mating.criteriaListId,
    toggleResetForm: ({mating}: State): boolean => mating.toggleResetScreen,
    matingResult: ({mating}: State): Opt<MatingResult> => mating.matingResult,
    bullPreferences: ({mating}: State): Opt<Array<BullPreference>> => mating.bullPreferences,
    recalculatedScoringResults: ({mating}: State): Opt<Array<ScoringResult>> => mating.recalculatedScoringResults,
    saveScoringResultsModalVisible: ({mating}: State): boolean => mating.saveScoringResultsModalVisible,
    animalListId: ({mating}: State): Opt<number> => mating.animalListId,
    availableAnimals: ({mating}: State): Opt<Array<AvailableAnimal>> => mating.availableAnimals,
    criteriaAccordions: ({mating}: State): Opt<Array<Accordion>> => mating.criteriaAccordions,
    cowCriteriaDefinitions: ({mating}: State): Opt<Array<CriteriaDefinition>> => mating.cowCriteriaDefinitions,
    bullCriteriaDefinitions: ({mating}: State): Opt<Array<CriteriaDefinition>> => mating.bullCriteriaDefinitions,
    exportBullselectorResultModalVisible: ({mating}: State): boolean =>
        mating.exportBullselectorResultModalVisible,
    isCowsSessionEmpty: ({mating}: State): boolean => !mating.cowsExistInSession,
    cowsSessionState: ({mating}: State): SessionState => mating.cowsSessionState,
    isBullsSessionEmpty: ({mating}: State): boolean => !mating.bullsExistInSession,
    bullsSessionState: ({mating}: State): SessionState => mating.bullsSessionState,
    gridUserSettings: ({mating}: State): Opt<CreateOrUpdateGridUserSettings> => mating.gridUserSettings,

    isHolsteinPageEmpty: createSelector(
        ({mating}: State) => mating.maleHolsteinAnimalsPage.prop('content'),
        data => isEmpty(data.orElse([])),
    ),
    isFleckviehPageEmpty: createSelector(
        ({mating}: State) => mating.maleFleckviehAnimalsPage.prop('content'),
        data => isEmpty(data.orElse([])),
    ),
    isCowsPageEmpty: createSelector(
        ({mating}: State) => mating.femaleAnimalsPage.prop('content'),
        data => isEmpty(data.orElse([])),
    ),
    femaleAnimalListsModalVisible: createSelector(
        ({mating}: State) => mating.animalListsModalData,
        data => data === female,
    ),
    maleAnimalListsModalVisible: createSelector(
        ({mating}: State) => mating.animalListsModalData,
        data => data === male,
    ),
    femaleAnimalListsConfirmModalVisible: createSelector(
        ({mating}: State) => mating.animalListsConfirmModalData,
        data => data === female,
    ),
    maleAnimalListsConfirmModalVisible: createSelector(
        ({mating}: State) => mating.animalListsConfirmModalData,
        data => data === male,
    ),
    addCowsModalVisible: createSelector(
        ({mating}: State) => mating.addAnimalsModalData,
        data => data === female,
    ),
    addBullsModalVisible: createSelector(
        ({mating}: State) => mating.addAnimalsModalData,
        data => data === male,
    ),
    cowsFromDBVisibilityModalVisible: createSelector(
        ({mating}: State) => mating.animalsFromDBVisibilityModalData,
        data => data === female,
    ),
    bullsFromDBVisibilityModalVisible: createSelector(
        ({mating}: State) => mating.animalsFromDBVisibilityModalData,
        data => data === male,
    ),
    cowsListsOverviewModalVisible: createSelector(
        ({mating}: State) => mating.listsOverviewModalData,
        data => data === female,
    ),
    bullsListsOverviewModalVisible: createSelector(
        ({mating}: State) => mating.listsOverviewModalData,
        data => data === male,
    ),
    cowsCriteriaListsModalVisible: createSelector(
        ({mating}: State) => mating.criteriaListsModalData,
        data => data === female,
    ),
    bullsCriteriaListsModalVisible: createSelector(
        ({mating}: State) => mating.criteriaListsModalData,
        data => data === male,
    ),
    cowsCriteriaListsConfirmModalVisible: createSelector(
        ({mating}: State) => mating.criteriaListsConfirmModalData,
        data => data === female,
    ),
    bullsCriteriaListsConfirmModalVisible: createSelector(
        ({mating}: State) => mating.criteriaListsConfirmModalData,
        data => data === male,
    ),
    cowsCriteriaListDetailsModalVisible: createSelector(
        ({mating}: State) => mating.criteriaListDetailsModalData,
        data => data === female,
    ),
    bullsCriteriaListDetailsModalVisible: createSelector(
        ({mating}: State) => mating.criteriaListDetailsModalData,
        data => data === male,
    ),
};
