import {none, Opt} from 'ts-opt';

import {Accordion} from 'api/gen/Accordion';
import {AnimalList} from 'api/gen/AnimalList';
import {AvailableAnimal} from 'api/gen/AvailableAnimal';
import {Bookmark} from 'api/gen/Bookmark';
import {BreederToChoose} from 'api/gen/BreederToChoose';
import {CreateOrUpdateGridUserSettings} from 'api/gen/CreateOrUpdateGridUserSettings';
import {CriteriaDefinition} from 'api/gen/CriteriaDefinition';
import {CriteriaList} from 'api/gen/CriteriaList';
import {FarmToChoose} from 'api/gen/FarmToChoose';
import {MatingResult} from 'api/gen/MatingResult';
import {OrganizationToChoose} from 'api/gen/OrganizationToChoose';
import {PagedModelFemaleHolsteinAnimalInList} from 'api/gen/PagedModelFemaleHolsteinAnimalInList';
import {PagedModelMaleFleckviehAnimalInList} from 'api/gen/PagedModelMaleFleckviehAnimalInList';
import {PagedModelMaleHolsteinAnimalInList} from 'api/gen/PagedModelMaleHolsteinAnimalInList';
import {ScoringResult} from 'api/gen/ScoringResult';
import {Sex} from 'api/gen/Sex';
import {WriteCriteria} from 'api/gen/WriteCriteria';

import {BullPreference} from '../types/bull-counts-and-shares';
import {MatingLoadingId} from '../types/mating-loading-id';
import {MatingTabKey} from '../types/mating-tab-key';

export type SessionState = 'initial' | 'filter' | 'selection';

export interface MatingState {
    activeTab: MatingTabKey;
    loading: Array<MatingLoadingId>;
    breeders: Opt<Array<BreederToChoose>>;
    farms: Opt<Array<FarmToChoose>>;
    femaleAnimalsPage: Opt<PagedModelFemaleHolsteinAnimalInList>;
    cowsExistInSession: boolean;
    cowsSessionState: SessionState;
    maleFleckviehAnimalsPage: Opt<PagedModelMaleFleckviehAnimalInList>;
    maleHolsteinAnimalsPage: Opt<PagedModelMaleHolsteinAnimalInList>;
    bullsExistInSession: boolean;
    bullsSessionState: SessionState;
    animalListsModalData: Sex | null;
    animalListsConfirmModalData: Sex | null;
    animalListId: Opt<number>;
    availableAnimals: Opt<Array<AvailableAnimal>>;
    addAnimalsModalData: Sex | null;
    animalLists: Opt<Array<AnimalList>>;
    animalListsTag: Opt<Sex>;
    animalsFromDBVisibilityModalData: Sex | null;
    listsOverviewModalData: Bookmark | null;
    criteriaLists: Opt<Array<CriteriaList>>;
    criteriaListsTag: Opt<Bookmark>;
    criteriaAccordions: Opt<Array<Accordion>>;
    cowCriteriaDefinitions: Opt<Array<CriteriaDefinition>>;
    bullCriteriaDefinitions: Opt<Array<CriteriaDefinition>>;
    paramsCriteriaDefinitions: Opt<Array<CriteriaDefinition>>;
    writeCriteria: Opt<Array<WriteCriteria>>;
    criteriaListId: Opt<number>;
    criteriaListsModalData: Bookmark | null;
    criteriaListsConfirmModalData: Bookmark | null;
    criteriaListDetailsModalData: Bookmark | null;
    saveScoringResultsModalVisible: boolean;
    organizations: Opt<Array<OrganizationToChoose>>;
    matingResult: Opt<MatingResult>;
    bullPreferences: Opt<Array<BullPreference>>;
    recalculatedScoringResults: Opt<Array<ScoringResult>>;
    exportBullselectorResultModalVisible: boolean;
    toggleResetScreen: boolean;
    gridUserSettings: Opt<CreateOrUpdateGridUserSettings>;
}

export const initialMatingState: MatingState = {
    activeTab: 'cowSelection',
    loading: [],
    breeders: none,
    farms: none,
    femaleAnimalsPage: none,
    cowsExistInSession: false,
    cowsSessionState: 'initial',
    maleFleckviehAnimalsPage: none,
    maleHolsteinAnimalsPage: none,
    bullsExistInSession: false,
    bullsSessionState: 'initial',
    animalListsModalData: null,
    animalListsConfirmModalData: null,
    animalListId: none,
    availableAnimals: none,
    addAnimalsModalData: null,
    animalLists: none,
    animalListsTag: none,
    animalsFromDBVisibilityModalData: null,
    listsOverviewModalData: null,
    criteriaLists: none,
    criteriaListsTag: none,
    criteriaAccordions: none,
    cowCriteriaDefinitions: none,
    bullCriteriaDefinitions: none,
    paramsCriteriaDefinitions: none,
    writeCriteria: none,
    criteriaListId: none,
    criteriaListsModalData: null,
    criteriaListsConfirmModalData: null,
    criteriaListDetailsModalData: null,
    saveScoringResultsModalVisible: false,
    organizations: none,
    matingResult: none,
    bullPreferences: none,
    recalculatedScoringResults: none,
    exportBullselectorResultModalVisible: false,
    toggleResetScreen: false,
    gridUserSettings: none,
};
