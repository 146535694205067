import { TypedFormFieldComponents } from '@fl/cmsch-fe-library';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { BreederToChoose } from 'api/gen/BreederToChoose';
import { FarmToChoose } from 'api/gen/FarmToChoose';
import { matingSelector } from 'app/mating/model';
import { MatingCowsFilterFormValues, matingCowsFilterFormName } from 'app/mating/types';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { formHelpers } from 'utils/forms';
import { computeBreederOptions, computeFarmOptions } from './cow-selection-helper';
import styles from '../bulls-filter-form/styles.sass';
const span0 = 0;
const span5 = 5;
const span34 = 34;
interface Props {
  breeders: Opt<Array<BreederToChoose>>;
  farms: Opt<Array<FarmToChoose>>;
  Fields: TypedFormFieldComponents<MatingCowsFilterFormValues>;
  getFarms(): void;
}
export const ExtraFirstAccordionNodes: FC<Props> = ({
  breeders,
  farms,
  Fields,
  getFarms
}) => {
  const {
    t
  } = useOurTranslation('mating/matingCowsFilterForm');
  const breederOptions = useMemo(() => computeBreederOptions(breeders, t), [breeders, t]);
  const farmOptions = useMemo(() => computeFarmOptions(farms, t), [farms, t]);
  const isBreederSelected = useSelector(formHelpers.formValues(matingCowsFilterFormName)).prop('breederId').isFull;
  const loadingBreeders = useSelector(matingSelector.loading).includes('breeders');
  return <Ant.Row gutter={[{
    xs: span0,
    xl: span34
  }, span5]} className={styles.extraNodes} data-sentry-element="unknown" data-sentry-component="ExtraFirstAccordionNodes" data-sentry-source-file="extra-first-accordion-nodes.tsx">
            <Ant.Col xs={24} xl={12} data-sentry-element="unknown" data-sentry-source-file="extra-first-accordion-nodes.tsx">
                <Fields.NumberSelect label={t('breeder')} name="breederId" options={breederOptions} onFieldChange={getFarms} isRequired hideHelpInitially fieldIsLoading={loadingBreeders} data-sentry-element="unknown" data-sentry-source-file="extra-first-accordion-nodes.tsx" />
                <Fields.NumberMultiSelect label={t('farms')} name="farmIds" options={farmOptions} isRequired disabled={!isBreederSelected} hideHelpInitially data-sentry-element="unknown" data-sentry-source-file="extra-first-accordion-nodes.tsx" />
            </Ant.Col>
        </Ant.Row>;
};