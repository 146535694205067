import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    tablesAction,
    getRequestedTablePageParams,
    simpleTableSelector,
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
} from 'utils/tables';

import {userAction, GetUserContactsAction} from '../action';

function* execute({payload: {tableDataRequest, userProfileId}}: GetUserContactsAction): SagaIterator {
    if (tableDataRequest.isEmpty) return yield* put(userAction.getUserContactsSuccess(none));

    yield* put(tablesAction.tableDataFetchStart('userContactsProfile'));

    const tableState = yield* select(simpleTableSelector.tableState('userContactsProfile'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'userContactsProfile',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest: user detail contacts'),
        tableState,
    });
    const columnSettings = getRequestedTableColumnSettings<'userContactsProfile'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.findLoggedInUserContacts, filters, params, {userProfileId});

    if (response.isSuccess) {
        yield* put(userAction.getUserContactsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('userContactsProfile', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('userDetail/sagas')('loadUserContacts')));
        yield* put(tablesAction.tableDataFetchFailure('userContactsProfile'));
    }
}

export function* getUserContactsSaga(): SagaIterator {
    yield takeLatestF('user/GET_USER_CONTACTS', execute);
}
