// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-mating-components-params-selection-tab-___styles__tooltip___fttEZ{vertical-align:top;font-size:14px;display:inline;margin-left:5px}.src-app-mating-components-params-selection-tab-___styles__tooltip___fttEZ span{vertical-align:text-bottom;margin-bottom:1px}.src-app-mating-components-params-selection-tab-___styles__buttonWrapper___J88db{margin-bottom:1rem!important;text-align:right}.src-app-mating-components-params-selection-tab-___styles__section___oyCZ3{display:grid}.src-app-mating-components-params-selection-tab-___styles__container___K57UI{display:grid;padding:1rem}.src-app-mating-components-params-selection-tab-___styles__signWeight___qbYsX{width:100%;display:flex;justify-content:flex-end}@media (max-width: 1199px){.src-app-mating-components-params-selection-tab-___styles__section___oyCZ3{gap:10px;margin-top:10px}}@media (max-width: 991px){.src-app-mating-components-params-selection-tab-___styles__section___oyCZ3{gap:0;margin-top:0}.src-app-mating-components-params-selection-tab-___styles__container___K57UI{margin-left:15%;margin-right:15%}.src-app-mating-components-params-selection-tab-___styles__buttonWrapper___J88db{margin-top:10px;width:100%}.src-app-mating-components-params-selection-tab-___styles__buttonWrapper___J88db button{width:100%}}@media (max-width: 767px){.src-app-mating-components-params-selection-tab-___styles__container___K57UI{margin-left:5%;margin-right:5%}}@media (max-width: 479px){.src-app-mating-components-params-selection-tab-___styles__container___K57UI{margin-left:1px;margin-right:1px}}@media (max-width: 575px){.src-app-mating-components-params-selection-tab-___styles__container___K57UI .ant-form-horizontal .form-group .ant-form-item-has-error .ant-form-item-label-wrap label{margin-bottom:0!important}}
`, "",{"version":3,"sources":["webpack://./src/app/mating/components/params-selection-tab/styles.sass"],"names":[],"mappings":"AAAA,2EAAS,kBAAA,CAAmB,cAAA,CAAe,cAAA,CAAe,eAAA,CAAgB,gFAAc,0BAAA,CAA2B,iBAAA,CAAkB,iFAAe,4BAAA,CAA8B,gBAAA,CAAiB,2EAAS,YAAA,CAAa,6EAAW,YAAA,CAApO,YAAA,CAA8P,8EAAY,UAAA,CAAW,YAAA,CAAa,wBAAA,CAAyB,2BAA0B,2EAAS,QAAA,CAAS,eAAA,CAAA,CAAiB,0BAAyB,2EAAS,KAAA,CAAM,YAAA,CAAa,6EAAW,eAAA,CAAgB,gBAAA,CAAiB,iFAAe,eAAA,CAAgB,UAAA,CAAW,wFAA8B,UAAA,CAAA,CAAY,0BAAyB,6EAAW,cAAA,CAAe,eAAA,CAAA,CAAiB,0BAAyB,6EAAW,eAAA,CAAgB,gBAAA,CAAA,CAAkB,0BAAyB,uKAA6G,yBAAA,CAAA","sourcesContent":[".tooltip{vertical-align:top;font-size:14px;display:inline;margin-left:5px}.tooltip span{vertical-align:text-bottom;margin-bottom:1px}.buttonWrapper{margin-bottom:1rem !important;text-align:right}.section{display:grid}.container{display:grid;padding:1rem}.signWeight{width:100%;display:flex;justify-content:flex-end}@media(max-width: 1199px){.section{gap:10px;margin-top:10px}}@media(max-width: 991px){.section{gap:0;margin-top:0}.container{margin-left:15%;margin-right:15%}.buttonWrapper{margin-top:10px;width:100%}.buttonWrapper :global button{width:100%}}@media(max-width: 767px){.container{margin-left:5%;margin-right:5%}}@media(max-width: 479px){.container{margin-left:1px;margin-right:1px}}@media(max-width: 575px){.container :global .ant-form-horizontal .form-group .ant-form-item-has-error .ant-form-item-label-wrap label{margin-bottom:0 !important}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `src-app-mating-components-params-selection-tab-___styles__tooltip___fttEZ`,
	"buttonWrapper": `src-app-mating-components-params-selection-tab-___styles__buttonWrapper___J88db`,
	"section": `src-app-mating-components-params-selection-tab-___styles__section___oyCZ3`,
	"container": `src-app-mating-components-params-selection-tab-___styles__container___K57UI`,
	"signWeight": `src-app-mating-components-params-selection-tab-___styles__signWeight___qbYsX`
};
export default ___CSS_LOADER_EXPORT___;
