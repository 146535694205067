import { Locale } from 'antd/es/locale-provider';
import antdLocaleCsCZ from 'antd/es/locale/cs_CZ';
import antdLocaleEnGB from 'antd/es/locale/en_GB';
import React from 'react';
import { Language } from 'api/gen/Language';
import { SvgImage } from 'common/icons/svg-image';
import CzFlag from './cz.svg';
import GbFlag from './gb.svg';
interface LanguageDetails {
  legacyBeTag: string;
  antdLocalizationFile: Locale;
  label: string;
  icon: React.ReactElement;
}
export const LANGUAGES: Record<Language, LanguageDetails> = {
  CS: {
    legacyBeTag: 'cs-CZ',
    antdLocalizationFile: antdLocaleCsCZ,
    label: 'Česky',
    icon: <SvgImage image={CzFlag} />
  },
  EN: {
    legacyBeTag: 'en-GB',
    antdLocalizationFile: antdLocaleEnGB,
    label: 'English',
    icon: <SvgImage image={GbFlag} />
  }
};