const controller = 'FileManagerT';
export const legacyFileManagerPermissions = {
    user: `${controller}/FileManagerUser`,
};

const flmRequestsController = 'FlmPozadavky';
export const legacyFlmRequestsPermissions = {
    request: `${flmRequestsController}/PozadavekSubjekt`,
    approveRequest: `${flmRequestsController}/SchvalitPozadavek`,
    report: `${flmRequestsController}/SestavaSubjekt`,
    approveReport: `${flmRequestsController}/SchvalitSestava`,
};

const tagsController = 'Jmenovky';
export const legacyTagsPermissions = {
    inseminationTechnicians: `${tagsController}/InseminacniTechnici`,
};

const breedBookController = 'PlemennaKniha';
export const legacyBreedBookPermissions = {
    tagPK: `${breedBookController}/JmenovkaPK`,
    animalList: `${breedBookController}/SeznamZvirat`,
    bulkAssemblyGeneration: `${breedBookController}/GenerovaniSestav`,
    animalRenumber: `${breedBookController}/PrecislovaniZvirete`,
    etCombination: `${breedBookController}/KombinaceET`,
    animalHistory: `${breedBookController}/HistorieZvirete`,
    genderChange: `${breedBookController}/ZmenaPohlavi`,
    breedingGame: `${breedBookController}/PlemenoHra`,
};

const AaaSubjectController = 'AaaSubjekt';
export const legacyAaaSubjectPermissions = {
    listSubjects: `${AaaSubjectController}/SeznamSubjektu`,
};

const legacyBullLineController = 'JmeLinieByka';
export const legacyBullLinePermissions = {
    bullLine: `${legacyBullLineController}/LinieByka`,
};

const signsController = 'Znaky';
export const legacySignsPermissions = {
    signs: `${signsController}/Znaky`,
};
