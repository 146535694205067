import {Dictionary, convertAllErrorsFromBeToFormError, putAll, takeLatestF, validateSchema} from '@fl/cmsch-fe-library';
import {isUndefined, keys, omitBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {isEmpty, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ValidateBreederRole} from 'api/gen/ValidateBreederRole';
import {ValidateCreateBreederRole} from 'api/gen/ValidateCreateBreederRole';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {ValidateBreederErrorsSchema} from '../../types/errors/validate-breeder-errors';
import {SubjectFacilityFormValuesErrors, subjectFacilityForm} from '../../types/subject-facility-form-values';
import {ValidateBreederAction} from '../action';
import {simpleUserDetailSelector} from '../selector';
import {BuildCenters, buildCenterOptions} from './build-center-options';

interface ApiTypes {
    approvedFrom: string;
    approvedTo: string | null;
    subjectId: number;
    userProfileId: number;
    farmIds: Array<number> | null;
    roleIds: Array<number> | null;
    userProfileRoleIds: Array<number> | null;
}
const getApiValues = ({
    userProfileId,
    subjectId,
    farmIds,
    roleIds,
    approvedFrom,
    approvedTo,
    userProfileRoleIds,
}: ApiTypes): ValidateBreederRole => isEmpty(userProfileRoleIds)
    ? ({
        tag: 'ValidateCreateBreederRole',
        roleIds: opt(roleIds).orCrash('No roleIds found'),
        userProfileId,
        approvedFrom,
        approvedTo,
        farmIds: opt(farmIds).orCrash('No farmIds found'),
        breederId: subjectId,
    })
    : ({
        tag: 'ValidateUpdateBreederRole',
        userProfileBreederRoleIds: opt(userProfileRoleIds)
            .orCrash('No userProfileOrganizationRoleIds found'),
        approvedTo,
    });

const mapErrors = (errors: Dictionary<keyof ValidateCreateBreederRole, string>): SubjectFacilityFormValuesErrors => {
    const validErrors = validateSchema(ValidateBreederErrorsSchema, 'FE')(errors);

    return omitBy(isUndefined)<SubjectFacilityFormValuesErrors>({
        approvedFrom: validErrors.approvedFrom,
        approvedTo: validErrors.approvedTo,
        farmIds: validErrors.farmIds,
        roleIds: validErrors.roleIds,
        subjectId: validErrors.breederId,
    });
};

// eslint-disable-next-line max-lines-per-function
function* execute(_: ValidateBreederAction): SagaIterator {
    const {
        approvedFrom,
        approvedTo,
        farmIds,
        userProfileRoleIds,
        subjectId,
        roleIds,
        userProfileId,
    } = (
        yield* select(formHelpers.formValues(subjectFacilityForm))
    ).orCrash(`no ${subjectFacilityForm} form values`);

    const farms = yield* select(simpleUserDetailSelector.farmOptions);

    const pristine = yield* select(formHelpers.isPristine(subjectFacilityForm));
    if (
        approvedFrom === null
        || subjectId === null
        || userProfileId === null
        || isEmpty(roleIds)
        || isEmpty(farmIds) && !isEmpty(farms)
        || pristine
        || (approvedTo !== null && approvedFrom > approvedTo)
    ) return; // synchronously not valid

    yield* put(formHelpers.startAsyncValidation(subjectFacilityForm));

    const buildCenters: BuildCenters = {centerIds: farmIds, type: 'FARM'};

    const fIds = yield* call(buildCenterOptions, buildCenters);

    const response = yield* call(
        Api.validateUserProfileBreederRoleBinding,
        getApiValues({
            subjectId,
            roleIds,
            userProfileId,
            approvedFrom,
            approvedTo,
            userProfileRoleIds,
            farmIds: fIds,
        }),
    );

    if (!response.isSuccess) {
        if (response.isBadRequest) {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            const breederErrors = mapErrors(errors.errors);
            yield* put(formHelpers.stopAsyncValidation(subjectFacilityForm, breederErrors));
            const fieldsWithNewErrors = keys(breederErrors) as Array<keyof typeof breederErrors>;

            if (!isEmpty(fieldsWithNewErrors)) {
                yield* put(formHelpers.touch(subjectFacilityForm, ...fieldsWithNewErrors));
            }
        } else {
            yield putAll(showBeError(response, t('common')('validateEntry')));
        }
    } else {
        yield* put(formHelpers.stopAsyncValidation(subjectFacilityForm, {}));
    }
}

export function* validateBreederSaga(): SagaIterator {
    yield takeLatestF('userDetail/VALIDATE_BREEDER', execute);
}
