import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse, NullableKeys} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {UpdateSubjectMember} from 'api/gen/UpdateSubjectMember';
import {t} from 'app/translations';
import {userDetailAction} from 'app/user-detail';
import {UserMemberSubjectForm, userMemberSubjectFormName} from 'app/user-detail/types/user-member-form-values';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {adminAction, UpdateUserMembershipAction} from '../action';

const te = t('admin/sagas');

const buildValuesForApi =
    ({
        approvedTo,
    }: NullableKeys<UserMemberSubjectForm>): UpdateSubjectMember => ({
        approvedTo: opt(approvedTo).orNull(),
    });

function* execute(_: UpdateUserMembershipAction): SagaIterator {
    const formValue = yield* select(state => formHelpers.formValues(userMemberSubjectFormName)(state)
        .orCrash('no role form values'));
    yield* put(formHelpers.startSubmit(userMemberSubjectFormName));

    const response = yield* call(
        Api.updateSubjectMemberApproval,
        buildValuesForApi(formValue),
        {subjectMemberId: opt(formValue.subjectMemberId).orCrash('No subjectMemberId found')},
    );

    const title = te('updateUserMembership');

    if (response.isSuccess) {
        const userProfileId = opt(formValue.userProfileId).orCrash('No userProfileId found');

        yield* put(adminAction.getMemberOfSubjects(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('userMembershipEdited'), false, 'updateMemberOfSubject'));
        yield* put(userDetailAction.setEditedUserMemberId(null));
        yield* put(userDetailAction.setMemberOfSubjectsModalVisible(false));
        yield* put(formHelpers.reset(userMemberSubjectFormName));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(userMemberSubjectFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* updateUserMembershipSaga(): SagaIterator {
    yield takeLatestF('admin/UPDATE_USER_MEMBERSHIP', execute);
}
