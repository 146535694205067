import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    tablesAction,
    getRequestedTablePageParams,
    simpleTableSelector,
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
} from 'utils/tables';

import {adminAction, GetRoleUnassignedPermissionsAction} from '../action';

function* execute({payload: {roleId, tableDataRequest}}: GetRoleUnassignedPermissionsAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(adminAction.getRoleUnassignedPermissionsSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('roleUnassignedPermissions'));

    const tableState = yield* select(simpleTableSelector.tableState('roleUnassignedPermissions'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'roleUnassignedPermissions',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    });
    const columnSettings = getRequestedTableColumnSettings<'roleUnassignedPermissions'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.getPermissionsUnassignedToRole, filters, params, {roleId});

    if (response.isSuccess) {
        yield* put(adminAction.getRoleUnassignedPermissionsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('roleUnassignedPermissions', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('admin/sagas')('loadUnassignedPermissions')));
        yield* put(tablesAction.tableDataFetchFailure('roleUnassignedPermissions'));
    }
}

export function* getRoleUnassignedPermissionsV2Saga(): SagaIterator {
    yield takeLatestF('admin/GET_ROLE_UNASSIGNED_PERMISSIONS', execute);
}
