import {SagaIterator} from 'redux-saga';
import {select} from 'typed-redux-saga';

import {formHelpers} from 'utils/forms';

import {MatingBullsFilterFormValues, matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';

export function* getMatingBullsFilterFormValues(): SagaIterator<MatingBullsFilterFormValues> {
    return (yield* select(formHelpers.formValues(matingBullsFilterFormName)))
        .orCrash('no matingBullsFilterFormValues');
}
