import { Options } from '@fl/cmsch-fe-library';
import React, { memo, FC, useMemo, useCallback } from 'react';
import { Opt } from 'ts-opt';
import { CriteriaList } from 'api/gen/CriteriaList';
import { useOurTranslation } from 'app/translations';
import { Modal } from 'common/layout/components/modal';
import { GetCriteriaListDetailsForm } from './form';
import { GetCriteriaListDetailsFormValues } from './get-criteria-list-details-form-values';
interface Props {
  visible: boolean;
  criteriaLists: Opt<Array<CriteriaList>>;
  onSubmit(animalListId: number): void;
  onCancel(): void;
}
const ModalGetCriteriaDetailsBase: FC<Props> = props => {
  const {
    visible,
    criteriaLists,
    onSubmit,
    onCancel
  } = props;
  const {
    t
  } = useOurTranslation('mating/modalGetCriteriaDetails');
  const listsOptions: Options<number> = useMemo(() => criteriaLists.mapIn(y => ({
    value: y.id,
    label: y.editable ? y.name : `${y.name} (${t('defaultList')})`
  })).orElse([]), [criteriaLists, t]);
  const handleSubmit = useCallback(({
    criteriaListId
  }: GetCriteriaListDetailsFormValues) => {
    if (criteriaListId) onSubmit(criteriaListId);
  }, [onSubmit]);
  return <Modal title={t('chooseCriteriaList')} visible={visible} onCancel={onCancel} footer={null} data-sentry-element="Modal" data-sentry-component="ModalGetCriteriaDetailsBase" data-sentry-source-file="index.tsx">
            <GetCriteriaListDetailsForm listsOptions={listsOptions} onCancel={onCancel} onSubmit={handleSubmit} data-sentry-element="GetCriteriaListDetailsForm" data-sentry-source-file="index.tsx" />
        </Modal>;
};
export const ModalGetCriteriaDetails = memo(ModalGetCriteriaDetailsBase);