import {Options, TableColumn} from '@fl/cmsch-fe-library';

import {CorrectionReport} from 'api/gen/CorrectionReport';
import {CorrectionType} from 'api/gen/CorrectionType';

import {TFunction} from '../../../translations';

export const correctionTypeOptions = (t: TFunction<'apc/overviewTable'>): Options<CorrectionType> => [
    {label: t('correctionTypes.DISCARD'), value: 'DISCARD'},
    {label: t('correctionTypes.UPDATE'), value: 'UPDATE'},
    {label: t('correctionTypes.EAR_TAG_UPDATE'), value: 'EAR_TAG_UPDATE'},
];

// eslint-disable-next-line max-lines-per-function
export const getColumns = (t: TFunction<'apc/overviewTable'>): Array<TableColumn<CorrectionReport>> => [
    {
        field: 'proficiencyTestDate',
        type: 'date',
        dateFormat: 'date',
        sortable: true,
    },
    {
        field: 'earTag',
        type: 'string',
        filterTypes: ['betweenEarTags'],
        sortable: true,
    },
    {
        field: 'updatedEarTag',
        type: 'string',
        filterTypes: ['betweenEarTags', 'null'],
        sortable: true,
    },
    {
        field: 'correctionType',
        type: 'option',
        options: correctionTypeOptions(t),
        sortable: true,
    },
    {
        field: 'breederName',
        type: 'string',
        sortable: true,
    },
    {
        field: 'stableCode',
        type: 'string',
        sortable: true,
    },
    {
        field: 'stableName',
        type: 'string',
        sortable: true,
    },
    {
        field: 'organizationName',
        type: 'string',
        sortable: true,
    },
    {
        field: 'technicianNumber',
        type: 'string',
        sortable: true,
    },
    {
        field: 'lactationOrder',
        type: 'number',
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'calveDate',
        type: 'date',
        dateFormat: 'date',
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'milkingDayTime',
        type: 'number',
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'milkingInterval',
        type: 'number',
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'milkingCount',
        type: 'number',
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'milkKg',
        type: 'decimal',
        fixedDecimals: 2,
        sortable: true,
    },
    {
        field: 'fatPercentage',
        type: 'decimal',
        fixedDecimals: 2,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'proteinPercentage',
        type: 'decimal',
        fixedDecimals: 2,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'lactosePercentage',
        type: 'decimal',
        fixedDecimals: 2,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'somaticCells',
        type: 'decimal',
        fixedDecimals: 0,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'urea',
        type: 'decimal',
        fixedDecimals: 2,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'methodOfProficiencyTest',
        type: 'number',
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'currentMethodOfProficiencyTest',
        type: 'number',
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'methodOfProficiencyTestChanged',
        type: 'optionalBoolean',
        sortable: true,
    },
    {
        field: 'lastChangedBy',
        type: 'string',
        sortable: true,
    },
    {
        field: 'updated',
        type: 'date',
        dateFormat: 'datetime',
        sortable: true,
        filterTypes: ['null'],
    },
];
