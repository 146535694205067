import {opt} from 'ts-opt';

import {OtherSubjectRole} from 'api/gen/OtherSubjectRole';
import {RoleApproval} from 'api/gen/RoleApproval';

import {OtherSubjectRoleTableType} from '../../types/other-subject-role-table-type';

const hasOnlyOneChild = (roles: Array<RoleApproval>): boolean => roles.length === 1;

// eslint-disable-next-line max-lines-per-function
export const rowMapper = ({
    rowId,
    roles,
    otherSubjectId,
    otherSubjectName,
}: OtherSubjectRole): OtherSubjectRoleTableType => {
    const getFirstLevelProp = <P extends keyof RoleApproval>(prop: P): RoleApproval[P] | null =>
        opt(roles).filter(hasOnlyOneChild).headIn().prop(prop).orNull();

    return ({
        id: rowId,
        rowId,
        otherSubjectId,
        otherSubjectName,
        roles,
        children: hasOnlyOneChild(roles)
            ? undefined
            : roles.map((role: RoleApproval): OtherSubjectRoleTableType => ({
                rowId: role.rowId,
                userProfileSubjectRoleId: role.userProfileSubjectRoleId,
                roleName: role.roleName,
                roleCode: role.roleCode,
                approvedTo: role.approvedTo,
                approvedFrom: role.approvedFrom,
                roleId: role.roleId,
                roles: [role],
                otherSubjectId,
                otherSubjectName,
                id: role.rowId,
            })),
        roleId: getFirstLevelProp('roleId'),
        approvedFrom: getFirstLevelProp('approvedFrom'),
        approvedTo: getFirstLevelProp('approvedTo'),
        roleCode: getFirstLevelProp('roleCode'),
        roleName: getFirstLevelProp('roleName'),
        userProfileSubjectRoleId: getFirstLevelProp('userProfileSubjectRoleId'),
    });
};
