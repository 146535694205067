import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {userAction, GetUserOrganizationsAction} from '../action';

const tt = t('userDetail/sagas');

function* execute({payload: {tableDataRequest, userProfileId}}: GetUserOrganizationsAction): SagaIterator {
    if (tableDataRequest.isEmpty) return yield* put(userAction.getUserOrganizationsSuccess(none));
    yield* put(tablesAction.tableDataFetchStart('organizationsProfile'));

    const tableState = yield* select(simpleTableSelector.tableState('organizationsProfile'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'organizationsProfile',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest for organizations profile.'),
        tableState,
        resetType: 'tableDataParams',
    });

    const columnSettings = getRequestedTableColumnSettings<'organizationsProfile'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.findLoggedInUserOrganizationRoles, filters, params, {userProfileId});

    if (response.isSuccess) {
        yield* put(userAction.getUserOrganizationsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('organizationsProfile', tableDataParams));
    } else {
        yield putAll(showBeError(response, tt('loadOrganizations')));
        yield* put(tablesAction.tableDataFetchFailure('organizationsProfile'));
    }
}
export function* getUserOrganizationsSaga(): SagaIterator {
    yield takeLatestF('user/GET_USER_ORGANIZATIONS', execute);
}
