export const loginFormName = 'login';

export interface LoginFormValues {
    username: string;
    password: string;
}

export const initialLoginFormValues: LoginFormValues = {
    username: '',
    password: '',
};
