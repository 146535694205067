import {Options} from '@fl/cmsch-fe-library';

import {t} from 'app/translations';

export const getCalfSexOptions = (): Options<string> => [
    {
        value: 'MALE',
        label: t('calfBrowserTable')('maleCalf'),
    },
    {
        value: 'FEMALE',
        label: t('calfBrowserTable')('femaleCalf'),
    },
];
