import {CreateOrUpdateRole} from 'api/gen/CreateOrUpdateRole';
import {roleCodeMaxLength, roleCodeMinLength} from 'api/gen/RoleCode';
import {roleNameMaxLength, roleNameMinLength} from 'api/gen/RoleName';
import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

const te = t('admin/roles');

export const validate =
    (values: CreateOrUpdateRole): Errors<CreateOrUpdateRole> => {
        const validator = new EskotValidator(values);

        validator.nonEmpty('code', te('code'));
        validator.maxStringLength('code', roleCodeMaxLength, te('code'));
        validator.minStringLength('code', roleCodeMinLength, te('code'));

        validator.nonEmpty('name', te('name'));
        validator.maxStringLength('name', roleNameMaxLength, te('name'));
        validator.minStringLength('name', roleNameMinLength, te('name'));

        validator.nonEmpty('discriminator', te('discriminator'));

        validator.nonEmpty('userEnable', te('userEnableRole'));

        return validator.generateErrorsObject();
    };
