import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {uniqBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {simpleAnimalBrowserSelector} from 'app/animal-browser/model/selector';
import {cabFilterFormName} from 'app/animal-browser/types/cab-filter-form-values';
import {genStableOptions} from 'app/animal-browser/utils/gen-options';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {animalBrowserAction, GetStableOptionsAction} from '../action';

const tt = t('comprehensiveOverview');

function* execute({payload}: GetStableOptionsAction): SagaIterator {
    const selectedValues = (yield* select(formHelpers.formValues(cabFilterFormName))).prop('stableIds').orElse([]);
    const oldOptions = (yield* select(simpleAnimalBrowserSelector.stableOptions)).orElse([]);
    const selectedOptions = oldOptions.filter(option => selectedValues.includes(option.value));

    yield* put(animalBrowserAction.setLoading('stablesSearch', true));

    const response = yield* call(Api.getCabStableAutocompleteList, {text: payload});
    if (response.isSuccess) {
        yield* put(animalBrowserAction.getStableOptionsSuccess(
            uniqBy('value', [...selectedOptions, ...genStableOptions(response.data)]),
        ));
    } else {
        yield putAll(showBeError(response, tt('filter')));
    }

    yield* put(animalBrowserAction.setLoading('stablesSearch', false));
}
export function* getStableOptionsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_STABLE_OPTIONS', execute);
}
