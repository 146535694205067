import { Button, classNames, ICONS } from '@fl/cmsch-fe-library';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as routerActions from 'redux-first-history';
import { UserDetail, UserDetailSchema } from 'api/gen/UserDetail';
import { UserProfile } from 'api/gen/UserProfile';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { BackButton } from 'common/buttons';
import { Card, CookieModal, Space } from 'common/layout';
import { BasicDetailInfo } from 'common/layout/components/basic-detail-info';
import { AssociationTabProps } from '../../types/associaton-tab-props';
import { BreedersTabProps } from '../../types/breeders-tab-props';
import { ContactsTabProps } from '../../types/contacts-tab-props';
import { MemberOfSubjectsTabProps } from '../../types/member-of-subjects-tab-props';
import { OrganizationsTabProps } from '../../types/organizations-tab-props';
import { OtherSubjectsTabProps } from '../../types/other-subjects-tab-props';
import { AssociationsTable } from '../associations-tab';
import { BreedersTable } from '../breeders-tab';
import { UserContactsTable } from '../contacts-tab';
import { SubjectTable } from '../member-of-subjects-tab';
import { OrganizationsTable } from '../organizations-tab';
import { OtherSubjectsTable } from '../other-subjects-tab';
import styles from './styles.sass';
interface Props {
  user: UserDetail | UserProfile;
  breedersProps: BreedersTabProps;
  associationsProps: AssociationTabProps;
  organizationsProps: OrganizationsTabProps;
  otherSubjectsProps: OtherSubjectsTabProps;
  memberOfSubjectsProps: MemberOfSubjectsTabProps;
  contactsProps: ContactsTabProps;
  isFromAdmin?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const UserDetailWrapperBase: FC<Props> = ({
  user,
  breedersProps,
  associationsProps,
  contactsProps,
  memberOfSubjectsProps,
  organizationsProps,
  otherSubjectsProps,
  isFromAdmin
}) => {
  const {
    registrationEmail,
    firstName,
    lastName,
    username
  } = user;
  const isAdmin = UserDetailSchema.is(user);
  const {
    active: isActive
  } = isAdmin ? user : {
    active: true
  };
  const dispatch = useDispatch();
  const {
    t,
    tCommon
  } = useOurTranslation('userDetail');
  const classes = classNames(styles.marker, isActive ? styles.active : styles.inactive);
  const statusIcon = useMemo(() => isActive ? ICONS.checkCircleOutlined : ICONS.closeCircleOutlined, [isActive]);
  const userHeader = useMemo(() => <Space align="end">
            <span>{username}</span>
            {isFromAdmin && <span className={classes}>
                <span className={styles.icon}>{statusIcon}</span>
                <span>{tCommon(isActive ? 'active' : 'inactive')}</span>
            </span>}
        </Space>, [isActive, classes, statusIcon, tCommon, username, isFromAdmin]);
  const goBackToList = useCallback(() => {
    dispatch(routerActions.push('/administrace/uzivatele'));
  }, [dispatch]);
  const [cookieModalVisible, setCookieModalVisible] = useState(false);
  const handleOpenCookieSettings = useCallback(() => {
    setCookieModalVisible(true);
  }, []);
  return <Card testId="screenTitle" title={userHeader} titleSize="big" extra={isFromAdmin ? <BackButton text={tCommon('backToList')} onClick={goBackToList} /> : <Button icon="cookieOutlined" onClick={handleOpenCookieSettings} testId="cookiesButton">
                    {t('cookieSettings')}
                </Button>} data-sentry-element="Card" data-sentry-component="UserDetailWrapperBase" data-sentry-source-file="index.tsx">
            <BasicDetailInfo labels={[tCommon('firstName'), tCommon('lastName'), t('email')]} values={[firstName || '', lastName || '', registrationEmail || '']} data-sentry-element="BasicDetailInfo" data-sentry-source-file="index.tsx" />
            <div className={styles.tabs}>
                <Ant.Tabs type="card" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Ant.Tabs.TabPane key="breeders" tab={t('breeders')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <BreedersTable getTableData={breedersProps.getTableData} selector={breedersProps.selector} adminProps={breedersProps.adminProps} pathnamesForRefresh={breedersProps.pathnamesForRefresh} data-sentry-element="BreedersTable" data-sentry-source-file="index.tsx" />
                    </Ant.Tabs.TabPane>
                    <Ant.Tabs.TabPane key="associations" tab={t('associations')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <AssociationsTable getTableData={associationsProps.getTableData} selector={associationsProps.selector} pathnamesForRefresh={associationsProps.pathnamesForRefresh} adminProps={associationsProps.adminProps} data-sentry-element="AssociationsTable" data-sentry-source-file="index.tsx" />
                    </Ant.Tabs.TabPane>
                    <Ant.Tabs.TabPane key="authorizedOrganizations" tab={t('authorizedOrganizations')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <OrganizationsTable getTableData={organizationsProps.getTableData} selector={organizationsProps.selector} pathnamesForRefresh={organizationsProps.pathnamesForRefresh} adminProps={organizationsProps.adminProps} data-sentry-element="OrganizationsTable" data-sentry-source-file="index.tsx" />
                    </Ant.Tabs.TabPane>
                    <Ant.Tabs.TabPane key="otherSubjects" tab={t('otherSubjects')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <OtherSubjectsTable getTableData={otherSubjectsProps.getTableData} selector={otherSubjectsProps.selector} pathnamesForRefresh={otherSubjectsProps.pathnamesForRefresh} adminProps={otherSubjectsProps.adminProps} data-sentry-element="OtherSubjectsTable" data-sentry-source-file="index.tsx" />
                    </Ant.Tabs.TabPane>
                    <Ant.Tabs.TabPane key="memberOfSubjects" tab={t('memberOfSubjects')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <SubjectTable getTableData={memberOfSubjectsProps.getTableData} selector={memberOfSubjectsProps.selector} pathnamesForRefresh={memberOfSubjectsProps.pathnamesForRefresh} adminProps={memberOfSubjectsProps.adminProps} data-sentry-element="SubjectTable" data-sentry-source-file="index.tsx" />
                    </Ant.Tabs.TabPane>
                    <Ant.Tabs.TabPane key="contactInformation" tab={t('contactInformation')} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <UserContactsTable getTableData={contactsProps.getTableData} selector={contactsProps.selector} pathnamesForRefresh={contactsProps.pathnamesForRefresh} adminProps={contactsProps.adminProps} data-sentry-element="UserContactsTable" data-sentry-source-file="index.tsx" />
                    </Ant.Tabs.TabPane>
                </Ant.Tabs>
            </div>
            <CookieModal visible={cookieModalVisible} setVisible={setCookieModalVisible} data-sentry-element="CookieModal" data-sentry-source-file="index.tsx" />
        </Card>;
};
export const UserDetailWrapper = memo(UserDetailWrapperBase);