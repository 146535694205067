import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {simpleUserSelector} from 'app/user';

const useCurrentUserHasAllPermissions = (requiredPermissions: Array<string>): boolean => {
    const userPermissions = useSelector(simpleUserSelector.currentUserPermissions);

    return useMemo(() =>
        // eslint-disable-next-line @fl/use-eta-reduction
        requiredPermissions.every(permission => userPermissions.includes(permission)),
    [requiredPermissions, userPermissions]);
};

export default useCurrentUserHasAllPermissions;
