import {changeAndSaveLanguageSaga} from './change-and-save-language-saga';
import {deleteUserSettingsSaga} from './delete-user-settings-saga';
import {getAppSettingsListSaga} from './get-app-settings-list-saga';
import {getUserAssociationsSaga} from './get-user-associations-saga';
import {getUserBreedersSaga} from './get-user-breeders';
import {getUserContactsSaga} from './get-user-contacts-saga';
import {getUserMemberOfSubjectsSaga} from './get-user-member-of-subjects-saga';
import {getUserOrganizationsSaga} from './get-user-organizations-saga';
import {getUserOtherSubjectsSaga} from './get-user-other-subject-roles-saga';
import {getUserProfileSaga} from './get-user-profile-saga';
import {initializeSaga} from './initialize-saga';
import {loginSaga} from './login-saga';
import {logoutSaga} from './logout-saga';
import {setUserSettingsSaga} from './set-user-settings-saga';

export const userSagas = [
    loginSaga,
    logoutSaga,
    initializeSaga,
    changeAndSaveLanguageSaga,
    getAppSettingsListSaga,
    setUserSettingsSaga,
    deleteUserSettingsSaga,
    getUserAssociationsSaga,
    getUserOtherSubjectsSaga,
    getUserBreedersSaga,
    getUserContactsSaga,
    getUserMemberOfSubjectsSaga,
    getUserOrganizationsSaga,
    getUserProfileSaga,
];
