import React, { FC, memo, useMemo } from 'react';
import { Field as ReduxFormField } from 'redux-form';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button, UploadButton } from 'common/buttons';
import { SaveCriteriasButton } from '../../utils';
const actionPanelRowGutter = 8;
interface Props {
  valid: boolean;
  submitting: boolean;
  criteriaListsEmpty: boolean;
  animalListsEmpty: boolean;
  isAnimalsPageEmpty: boolean;
  handleBullsCsvUpload(file: File): void;
  toggleAnimalListsVisibility(): void;
  handleDeleteBullsFromList(): void;
  toggleAddAnimalsVisibility(): void;
  toggleAddAnimalsFromDBVisibility(): void;
  toggleListsOverviewVisibility(): void;
  toggleCriteriaListsVisibility(): void;
  toggleCriteriaListDetailsVisibility(): void;
}

// eslint-disable-next-line max-lines-per-function
const BottomButtonsBase: FC<Props> = props => {
  const {
    valid,
    submitting,
    criteriaListsEmpty,
    animalListsEmpty,
    isAnimalsPageEmpty,
    handleBullsCsvUpload,
    toggleAnimalListsVisibility,
    handleDeleteBullsFromList,
    toggleAddAnimalsVisibility,
    toggleAddAnimalsFromDBVisibility,
    toggleListsOverviewVisibility,
    toggleCriteriaListsVisibility,
    toggleCriteriaListDetailsVisibility
  } = props;
  const {
    t
  } = useOurTranslation('mating/matingBullsFilterForm');
  const filterBullsButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button type="submit" visuals="primary" disabled={!valid || submitting} loading={submitting} title={valid ? t('actionRewritesAnimalList') : t('formIsInvalid')} block testId="filterBulls">
                {t('filter')}
            </Button>
        </Ant.Col>, [submitting, t, valid]);
  const addBullsButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleAddAnimalsVisibility} testId="addBulls" block>{t('addBulls')}</Button>
        </Ant.Col>, [t, toggleAddAnimalsVisibility]);
  const addBullsFromFileButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <UploadButton id="addBullsFromFile" title={t('addBullsFromFile')} fileTypes=".csv" uploadFile={handleBullsCsvUpload} block testId="addBullsFromFile" />
        </Ant.Col>, [handleBullsCsvUpload, t]);
  const addBullsFromListButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleAddAnimalsFromDBVisibility} disabled={animalListsEmpty} title={animalListsEmpty ? t('activeWithAtLeastOneBullList') : ''} block testId="addBullsFromList">
                {t('addBullsFromList')}
            </Button>
        </Ant.Col>, [animalListsEmpty, t, toggleAddAnimalsFromDBVisibility]);
  const saveBullListButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleAnimalListsVisibility} disabled={isAnimalsPageEmpty} title={isAnimalsPageEmpty ? t('activeWithAtLeastOneAddedBull') : ''} block testId="saveBullsList">
                {t('saveBullList')}
            </Button>
        </Ant.Col>, [isAnimalsPageEmpty, t, toggleAnimalListsVisibility]);
  const deleteBullsButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={handleDeleteBullsFromList} disabled={isAnimalsPageEmpty} title={isAnimalsPageEmpty ? t('activeWithAtLeastOneAddedBull') : ''} block testId="deleteBulls">
                {t('deleteBulls')}
            </Button>
        </Ant.Col>, [handleDeleteBullsFromList, isAnimalsPageEmpty, t]);
  const loadCriteriaFromList = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleCriteriaListDetailsVisibility} disabled={criteriaListsEmpty} title={criteriaListsEmpty ? t('activeWithAtLeastOneCriteriaList') : ''} block testId="loadCriteria">
                {t('loadCriteriaFromList')}
            </Button>
        </Ant.Col>, [criteriaListsEmpty, t, toggleCriteriaListDetailsVisibility]);
  const saveCriteriasButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <ReduxFormField name="criterias" component={SaveCriteriasButton} title={t('saveCriterias')} toggleCriteriaListsVisibility={toggleCriteriaListsVisibility} />
        </Ant.Col>, [t, toggleCriteriaListsVisibility]);
  const manageSavedLists = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleListsOverviewVisibility} block testId="manageBullLists">
                {t('manageSavedLists')}
            </Button>
        </Ant.Col>, [t, toggleListsOverviewVisibility]);
  return <Ant.Row justify="center" gutter={[actionPanelRowGutter, actionPanelRowGutter]} className="mt-3 mb-5 mx-2" data-sentry-element="unknown" data-sentry-component="BottomButtonsBase" data-sentry-source-file="bottom-buttons.tsx">
            {filterBullsButton}
            {addBullsButton}
            {addBullsFromFileButton}
            {addBullsFromListButton}
            {saveBullListButton}
            {deleteBullsButton}
            {loadCriteriaFromList}
            {saveCriteriasButton}
            {manageSavedLists}
        </Ant.Row>;
};
export const BottomButtons = memo(BottomButtonsBase);