import {putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {DeleteAnimalsAction, animalBrowserAction} from '../action';

const te = t('animalBrowserSagas');

function* execute(action: DeleteAnimalsAction): SagaIterator {
    const {animalType} = action.payload;

    yield* put(animalBrowserAction.setLoading('deleteAnimals', true));

    const response = yield* call(Api.deleteCabAnimalsInSession, {animalType});
    const title = te('deleteAnimals');

    if (response.isSuccess) {
        yield* put(showSuccess(title, te('animalsDeleted')));
        if (animalType === 'BREEDING_FEMALES') {
            yield* put(animalBrowserAction.getCabFemales(opt({action: 'refresh'})));
            yield* put(animalBrowserAction.setCabFemalesExistInSession(false));
        } else {
            yield* put(animalBrowserAction.getCabCalves(opt({action: 'refresh'})));
            yield* put(animalBrowserAction.setCabCalvesExistInSession(false));
        }
    } else {
        yield putAll(showBeError(response, title));
    }

    yield* put(animalBrowserAction.setLoading('deleteAnimals', false));
}

export function* deleteAnimalsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/DELETE_ANIMALS', execute);
}
