/* eslint-disable max-lines */
import { TableColumn } from '@fl/cmsch-fe-library';
import React, { MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { MotherLinkProps } from 'app/animal-browser/types/mother-link-props';
import { getBullBrowserLink } from 'app/animal-browser/utils/get-bull-browser-link';
import { getCalfSexOptions } from 'app/animal-browser/utils/get-sex-options';
import { CabCalfRow } from '../../types/cab-calf-row';
import { getCalfBrowserLink } from '../../utils/get-calf-browser-link';

// eslint-disable-next-line max-lines-per-function
export const getCabCalfColumns = (handleMotherLink: (row: MotherLinkProps) => (event: MouseEvent) => void): Array<TableColumn<CabCalfRow>> => [{
  field: 'earTag',
  type: 'hyperlink',
  filterTypes: ['betweenEarTags', 'null'],
  valueToUrl: getCalfBrowserLink,
  openInNewTab: true
}, {
  field: 'genotype',
  type: 'string',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'sihIndex',
  type: 'decimal',
  fixedDecimals: 1,
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'totalBreedingValue',
  type: 'decimal',
  fixedDecimals: 1,
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'breederRegistrationNumber',
  type: 'number',
  verticalName: true,
  formatValue: String
}, {
  field: 'subjectBusinessName',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'farmCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'farmName',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'districtCode',
  type: 'number',
  formatValue: String,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'organizationPtCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'organizationCenterPtCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'organizationInsCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'organizationCenterInsCode',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'sex',
  type: 'option',
  filterTypes: ['select'],
  verticalName: true,
  options: getCalfSexOptions()
}, {
  field: 'birthDate',
  type: 'date',
  verticalName: true,
  dateFormat: 'date',
  filterTypes: ['null']
}, {
  field: 'breedAlpha6',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'studbookSection',
  type: 'string',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'firstInbreedingAt',
  type: 'date',
  dateFormat: 'date',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'firstPregnancyAt',
  type: 'date',
  dateFormat: 'date',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'pregnant',
  type: 'boolean',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'inbreedingBullLineRegistry',
  type: 'router',
  verticalName: true,
  filterTypes: ['null'],
  valueToLink: getBullBrowserLink('inbreedingBullLineRegistry'),
  openInNewTab: true
}, {
  field: 'inbreedingBullEarTag',
  type: 'router',
  filterTypes: ['null'],
  verticalName: true,
  valueToLink: getBullBrowserLink('inbreedingBullEarTag'),
  openInNewTab: true
}, {
  field: 'inbreedingBullName',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'fatherLineRegistry',
  type: 'router',
  filterTypes: ['null'],
  verticalName: true,
  valueToLink: getBullBrowserLink('fatherLineRegistry'),
  openInNewTab: true
}, {
  field: 'fatherEarTag',
  type: 'router',
  filterTypes: ['null'],
  verticalName: true,
  valueToLink: getBullBrowserLink('fatherEarTag'),
  openInNewTab: true
}, {
  field: 'fatherName',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'genotypeIndex',
  type: 'boolean',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'fatherSihIndex',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'fatherTotalBreedingValue',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherEarTag',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true,
  formatValue: (value: string, row: MotherLinkProps): ReactNode => {
    const handleClick = handleMotherLink(row);
    return <Link to="#" onClick={handleClick} onAuxClick={handleClick}>
                    {value}
                </Link>;
  }
}, {
  field: 'motherSihIndex',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherTotalBreedingValue',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherName',
  type: 'string',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherStandardizedLactationCount',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherAvgLactationDays',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherAvgLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherMaxLactationOrder',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherMaxLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherMaxLactationProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherMaxLactationProteinPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherMaxLactationFatKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherMaxLactationFatPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherFirstLactationDays',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherFirstLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherFirstLactationProteinKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherFirstLactationProteinPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherFirstLactationFatKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherFirstLactationFatPercentage',
  type: 'decimal',
  fixedDecimals: 2,
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherLifelongLactationMilkKg',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherExterior',
  type: 'string',
  verticalName: true
}, {
  field: 'motherMilkStrength',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherFrame',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherBodyStructure',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherMusculature',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherExtremities',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}, {
  field: 'motherUdder',
  type: 'number',
  filterTypes: ['null'],
  verticalName: true
}];