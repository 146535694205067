import {putAll, showSuccess, takeEveryF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {Opt, opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {PagedModelFemaleHolsteinAnimalInList} from 'api/gen/PagedModelFemaleHolsteinAnimalInList';
import {PagedModelMaleFleckviehAnimalInList} from 'api/gen/PagedModelMaleFleckviehAnimalInList';
import {PagedModelMaleHolsteinAnimalInList} from 'api/gen/PagedModelMaleHolsteinAnimalInList';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {DeleteAnimalsFromListAction, matingAction} from '../action';

const te = t('mating/sagas');

const createEmptyPage = (): Opt<
PagedModelMaleHolsteinAnimalInList & PagedModelFemaleHolsteinAnimalInList & PagedModelMaleFleckviehAnimalInList
> => opt({
    content: [],
    page: {
        totalElements: 0,
        size: 0,
        number: 0,
        totalPages: 1,
    },
});

function* execute({payload: {sex, associationType, silentMode}}: DeleteAnimalsFromListAction): SagaIterator {
    const response = yield* call(Api.resetList, {sex, associationType});

    const title = te('deleteAnimals');

    if (response.isSuccess) {
        if (!silentMode) {
            yield* put(showSuccess(title, te('animalsDeleted'), false, 'delete-animals-from-list'));
        }

        if (sex === 'FEMALE') {
            yield* put(matingAction.getFemaleAnimalsSuccess(createEmptyPage()));
            yield* put(matingAction.setFemaleAnimalExistInSession(false));
            yield* put(matingAction.setFemaleSessionState('initial'));
        } else {
            yield* put(
                associationType === 'FLECKVIEH'
                    ? matingAction.getMaleFleckviehAnimalsSuccess(createEmptyPage())
                    : matingAction.getMaleHolsteinAnimalsSuccess(createEmptyPage()),
            );
            yield* put(matingAction.setMaleAnimalExistInSession(false));
            yield* put(matingAction.setMaleSessionState('initial'));
        }
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* deleteAnimalsFromListSaga(): SagaIterator {
    yield takeEveryF('mating/DELETE_ANIMALS_FROM_LIST', execute);
}
