import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {Opt, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {AssociationType} from 'api/gen/AssociationType';
import {Bookmark} from 'api/gen/Bookmark';
import {CreateCriteriaList} from 'api/gen/CreateCriteriaList';
import {CriteriaDefinition} from 'api/gen/CriteriaDefinition';
import {t} from 'app/translations';
import {TableId} from 'common/types/table-id';
import {TableRowMap} from 'types/table-row-map';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';
import {getTableConfig} from 'utils/table-config';
import {createTableUserSettings, simpleTableSelector, tablesAction} from 'utils/tables';

import {initialCriterias} from '../../types/criterias';
import {
    matingParamsSelectionFormName,
    matingParamsCoefficientCodes,
    matingParamsScoringWeightsCodes,
    matingParamsExteriorTraitWeightsCodes,
} from '../../types/mating-params-selection-form-values';
import {getComputationParamsWriteCriterias, getParsedWriteCriterias} from '../../utils/get-parsed-write-criterias';
import {matingAction, CreateCriteriaListAction} from '../action';
import {getCriteriaListId} from './get-criteria-list-id';
import {getMatingBullsFilterFormValues} from './get-mating-bulls-filter-form-values';
import {getMatingCowsFilterFormValues} from './get-mating-cows-filter-form-values';
import {getMatingTableId} from './get-mating-table-id';

const te = t('mating/sagas');
function* buildWriteFemaleCriteriaList(
    name: string,
    criteriaDefinitions: Opt<Array<CriteriaDefinition>>,
): SagaIterator<CreateCriteriaList> {
    const matingCowsFilterFormValues = yield* call(getMatingCowsFilterFormValues);
    const criterias = opt(matingCowsFilterFormValues.criterias).orElse(initialCriterias);

    const {serverUserSettingsIds} = getTableConfig('matingCows') || {};

    const tableState = yield* select(simpleTableSelector.tableState('matingCows'));

    const tableConfig = yield* select(simpleTableSelector.tableConfig(
        serverUserSettingsIds?.module, serverUserSettingsIds?.code,
    ));

    const gridUserSettings = tableState && tableConfig
        ? createTableUserSettings<'matingCows'>(name, tableState, tableConfig)
        : null;

    return {
        gridUserSettings,
        name,
        criteria: getParsedWriteCriterias(criterias, criteriaDefinitions),
    };
}

function* buildWriteMaleCriteriaList(
    name: string,
    criteriaDefinitions: Opt<Array<CriteriaDefinition>>,
    associationType: AssociationType,
): SagaIterator<CreateCriteriaList> {
    const matingCowsFilterFormValues = yield* call(getMatingBullsFilterFormValues);
    const criterias = opt(matingCowsFilterFormValues.criterias).orElse(initialCriterias);

    const tableId: TableId = associationType === 'FLECKVIEH' ? 'matingFleckviehBulls' : 'matingHolsteinBulls' as const;

    const {serverUserSettingsIds} = getTableConfig(tableId) || {};

    const tableState = yield* select(simpleTableSelector.tableState(tableId));

    const tableConfig = yield* select(simpleTableSelector.tableConfig(
        serverUserSettingsIds?.module, serverUserSettingsIds?.code,
    ));

    const gridUserSettings = tableState && tableConfig
        ? createTableUserSettings<typeof tableId>(name, tableState, tableConfig)
        : null;

    return {
        gridUserSettings,
        name,
        criteria: getParsedWriteCriterias(criterias, criteriaDefinitions),
    };
}

function* buildWriteParamsCriteriaList(
    name: string,
    criteriaDefinitions: Opt<Array<CriteriaDefinition>>,
): SagaIterator<CreateCriteriaList> {
    const matingParamsSelectionFormValues = (yield* select(formHelpers.formValues(matingParamsSelectionFormName)))
        .orCrash('no matingParamsSelectionFormValues');

    return {
        gridUserSettings: null,
        name,
        criteria: getComputationParamsWriteCriterias(
            matingParamsSelectionFormValues,
            criteriaDefinitions,
            matingParamsCoefficientCodes,
            matingParamsScoringWeightsCodes,
            matingParamsExteriorTraitWeightsCodes,
        ),
    };
}

function* buildWriteCriteriaList(
    bookmark: Bookmark,
    name: string,
    criteriaDefinitions: Opt<Array<CriteriaDefinition>>,
    associationType: AssociationType,
): SagaIterator<CreateCriteriaList> {
    if (bookmark === 'FEMALE') {
        return yield* call(buildWriteFemaleCriteriaList, name, criteriaDefinitions);
    } else if (bookmark === 'MALE') {
        return yield* call(buildWriteMaleCriteriaList, name, criteriaDefinitions, associationType);
    } else {
        return yield* call(buildWriteParamsCriteriaList, name, criteriaDefinitions);
    }
}

interface CriteriaCreate {
    bookmark: Bookmark;
    name: string;
    writeCriteriaList: CreateCriteriaList;
    associationType: AssociationType;
    tableId: keyof TableRowMap | null;
    title: string;
}

function* createCriteriaList({
    bookmark,
    name,
    writeCriteriaList,
    associationType,
    tableId,
    title,
}: CriteriaCreate): SagaIterator<void> {
    const storingResponse = yield* call(Api.createCriteriaList, writeCriteriaList, {bookmark, associationType});

    yield* put(matingAction.toggleCriteriaListsVisibility(bookmark));
    yield* put(formHelpers.stopSubmit('createCriteriaList'));

    if (storingResponse.isSuccess) {
        if (tableId) {
            yield* put(tablesAction.getTableSettings(
                tableId,
                'FORCE_LOAD',
                false,
                name,
            ));
        }
        yield* put(matingAction.getCriteriaLists(bookmark, associationType, true));
        yield* put(showSuccess(title, te('criteriaListCreated'), false, 'create-criteria-list'));
    } else {
        yield putAll(showBeError(storingResponse, title, {includeFieldErrors: true}));
    }
}

// eslint-disable-next-line max-lines-per-function
function* execute(action: CreateCriteriaListAction): SagaIterator {
    yield* put(formHelpers.startSubmit('createCriteriaList'));

    const {bookmark, name, criteriaDefinitions, associationType} = action.payload;
    const writeCriteriaList = yield* call(buildWriteCriteriaList, bookmark, name, criteriaDefinitions, associationType);
    const title = te('createCriteriaList');
    const tableId = getMatingTableId(bookmark, associationType);
    const validateCriteriaResponse = yield* call(Api.validateCriteriaList, {
        criteriaListId: null,
        name,
        gridSettingsId: writeCriteriaList.gridUserSettings?.gridSettingsId ?? null,
        gridUserSettingsId: null,
    }, {bookmark, associationType});

    if (validateCriteriaResponse.isSuccess) {
        yield* call(createCriteriaList, {
            bookmark,
            name,
            writeCriteriaList,
            associationType,
            tableId,
            title,
        });
    } else if (validateCriteriaResponse.isBadRequest) {
        // TODO - this is hack, correct fix in https://redmine.favorlogic.com/issues/16756
        const errorIsFromTable =
        // @ts-expect-error correct resolve in TODO above
            validateCriteriaResponse.data[0].message === 'Uživatelská konfigurace tabulky s daným názvem již existuje.';

        if (errorIsFromTable) {
            yield* call(createCriteriaList, {
                bookmark,
                name,
                writeCriteriaList,
                associationType,
                tableId,
                title,
            });
        } else {
            yield* put(matingAction.toggleCriteriaListsVisibility(bookmark));
            yield* put(formHelpers.stopSubmit('createCriteriaList'));
            const id = yield* call(getCriteriaListId, name);

            yield* put(matingAction.toggleOverwriteCriteriaListVisibility(
                bookmark,
                writeCriteriaList.gridUserSettings,
                writeCriteriaList.criteria,
                id,
            ));
        }
    } else {
        yield putAll(showBeError(validateCriteriaResponse, title));
        yield* put(formHelpers.stopSubmit(
            'createCriteriaList',
            extractFormErrorsFromResponse(validateCriteriaResponse),
        ));
    }
}

export function* createCriteriaListSaga(): SagaIterator {
    yield takeLatestF('mating/VALIDATE_CRITERIA_LIST_NAME', execute);
}
