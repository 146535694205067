import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {matingAction, RenameAnimalListAction} from '../action';
import {getAnimalListVersion} from './get-animal-list-version';
import {getTableInfo} from './get-table-info';

const te = t('mating/sagas');

function* execute({payload: {sex, id, name, associationType}}: RenameAnimalListAction): SagaIterator {
    const {module, code} = getTableInfo(sex, associationType);
    const version = yield* call(getAnimalListVersion, id);
    const response = yield* call(Api.validateAnimalList, {id, name, module, code});

    const title = te('renameAnimalList');

    if (response.isSuccess) {
        const storingResponse = yield* call(Api.renameAnimalList, {name, version}, {animalListId: id});

        if (storingResponse.isSuccess) {
            yield* put(matingAction.getAnimalLists(sex, associationType, true));
            yield* put(showSuccess(title, te('animalListRenamed')));
        } else {
            yield putAll(showBeError(storingResponse, title));
        }
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* renameAnimalListSaga(): SagaIterator {
    yield takeLatestF('mating/RENAME_ANIMAL_LIST', execute);
}
