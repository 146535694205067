import {TableColumn} from '@fl/cmsch-fe-library';

import {MilkabilityDetail} from 'api/gen/MilkabilityDetail';
import {TFunction} from 'app/translations';

import {formatMilkingTime} from '../../utils/format-milking-time';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    useVerticalNames: boolean,
    t: TFunction<'milkability/overviewTable'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<MilkabilityDetail>> => [
    {
        field: 'technicianNumber',
        column: t('technicianNumber'),
        tooltip: t('technicianNumberTooltip'),
        type: 'string',
        width: 185,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'examinationDate',
        column: t('examinationDate'),
        tooltip: t('examinationDate'),
        type: 'date',
        dateFormat: 'date',
        width: 160,
        disableFilter: true,
    },
    {
        field: 'earTag',
        column: tCommon('earTagTooltip'),
        tooltip: tCommon('earTagTooltip'),
        filterTypes: ['betweenEarTags'],
        type: 'string',
        width: 160,
        disableFilter: true,
    },
    {
        field: 'milkedTotal',
        column: t('milkedTotal'),
        tooltip: t('milkedTotal'),
        type: 'decimal',
        fixedDecimals: 2,
        width: 60,
        verticalName: useVerticalNames,
        disableFilter: true,
    },
    {
        field: 'milkingTime',
        column: t('milkingTime'),
        tooltip: t('milkingTime'),
        type: 'decimal',
        fixedDecimals: 2,
        width: 60,
        verticalName: useVerticalNames,
        formatValue: formatMilkingTime,
        disableFilter: true,
    },
    {
        field: 'apmv',
        column: t('apmv'),
        tooltip: t('apmv'),
        type: 'decimal',
        fixedDecimals: 2,
        width: 60,
        disableFilter: true,
    },
];
