import React, { FC, memo, useMemo } from 'react';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { RolesTable } from '../components/roles-table';
const RolesBase: FC = _ => {
  const {
    t
  } = useOurTranslation('admin/roles');
  const title = t('pageTitle');
  const header = useMemo(() => <ScreenHeader title={title} />, [title]);
  return <Content browserTabTitle={title} data-sentry-element="Content" data-sentry-component="RolesBase" data-sentry-source-file="roles.tsx">
            <Card title={header} titleSize="big" noPadding data-sentry-element="Card" data-sentry-source-file="roles.tsx">
                <RolesTable data-sentry-element="RolesTable" data-sentry-source-file="roles.tsx" />
            </Card>
        </Content>;
};
export const Roles = memo(RolesBase);