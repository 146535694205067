import { Options, Option, FooterButtons } from '@fl/cmsch-fe-library';
import { unionBy } from 'lodash/fp';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Opt } from 'ts-opt';
import { AvailableAnimal } from 'api/gen/AvailableAnimal';
import { useForm } from 'utils/forms';
import { addAnimalsFormName, addAnimalsFormInitialValues, AddAnimalsFormValues } from './add-animals-form-values';
import { getValidate } from './validate';
interface AddAnimalsProps {
  availableAnimals: Opt<Array<AvailableAnimal>>;
  fieldLabel: string;
  loadingAvailableAnimals: boolean;
  submitLabel: string;
  onSubmit(animalIds: AddAnimalsFormValues): void;
  onCancel(): void;
  onSearch(value: string): void;
}
const AddAnimalsFormBase: FC<AddAnimalsProps> = props => {
  const {
    availableAnimals,
    fieldLabel,
    loadingAvailableAnimals,
    submitLabel,
    onSubmit,
    onCancel,
    onSearch
  } = props;
  const validate = useMemo(() => getValidate(fieldLabel), [fieldLabel]);
  const {
    Form,
    Fields,
    submitting,
    valid,
    pristine
  } = useForm({
    form: addAnimalsFormName,
    initialValues: addAnimalsFormInitialValues,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate,
    onSubmit
  });
  const [selectedAnimals, setSelectedAnimals] = useState<Options<number>>([]);
  const animalsOptions: Options<number> = useMemo(() => availableAnimals.mapIn(x => ({
    value: x.id,
    label: x.label
  })).map(unionBy((o: Option<number>) => o.value)(selectedAnimals)).orElse([]), [availableAnimals, selectedAnimals]);
  const onAnimalsChange = useCallback((animalIds: Opt<Array<number>>) => {
    const selectedOptions = animalsOptions.filter(option => animalIds.containsIn(option.value));
    setSelectedAnimals(selectedOptions);
  }, [animalsOptions]);
  return <Form data-sentry-element="Form" data-sentry-component="AddAnimalsFormBase" data-sentry-source-file="add-animals-form.tsx">
            <Fields.NumberMultiSelect name="addAnimalsToList" label={fieldLabel} options={animalsOptions} onSearch={onSearch} disabled={submitting} fieldIsLoading={loadingAvailableAnimals} onFieldChange={onAnimalsChange} autoFocus data-sentry-element="unknown" data-sentry-source-file="add-animals-form.tsx" />
            <FooterButtons valid={valid} submitting={submitting} pristine={pristine} submitTitle={submitLabel} onCancel={onCancel} data-sentry-element="FooterButtons" data-sentry-source-file="add-animals-form.tsx" />
        </Form>;
};
export const AddAnimalsForm = memo(AddAnimalsFormBase);