import { debounce } from 'lodash/fp';
import React, { FC, memo, useCallback } from 'react';
import { opt, Opt } from 'ts-opt';
import { AvailableAnimal } from 'api/gen/AvailableAnimal';
import { Modal } from 'common/layout/components/modal';
import { AddAnimalsForm } from './add-animals-form';
import { AddAnimalsFormValues } from './add-animals-form-values';
interface Props {
  title: string;
  fieldLabel: string;
  visible: boolean;
  availableAnimals: Opt<Array<AvailableAnimal>>;
  loadingAvailableAnimals: boolean;
  submitLabel: string;
  onSubmit(animalIds: Array<number>): void;
  onCancel(): void;
  onSearch(input: string): void;
  setAvailableAnimalsLoading(): void;
}
const waitTime = 600;
const ModalAddAnimalsBase: FC<Props> = props => {
  const {
    title,
    fieldLabel,
    visible,
    availableAnimals,
    loadingAvailableAnimals,
    submitLabel,
    onSubmit,
    onCancel,
    onSearch,
    setAvailableAnimalsLoading
  } = props;
  const handleSearch = useCallback((value: string): void => {
    if (value.length > 1) {
      setAvailableAnimalsLoading();
      debounce(waitTime)(onSearch)(value);
    }
  }, [onSearch, setAvailableAnimalsLoading]);
  const handleSubmit = useCallback((values: AddAnimalsFormValues): void => {
    opt(values).prop('addAnimalsToList').onSome(onSubmit);
  }, [onSubmit]);
  return <Modal title={title} visible={visible} onCancel={onCancel} footer={null} data-sentry-element="Modal" data-sentry-component="ModalAddAnimalsBase" data-sentry-source-file="index.tsx">
            <AddAnimalsForm availableAnimals={availableAnimals} loadingAvailableAnimals={loadingAvailableAnimals} fieldLabel={fieldLabel} submitLabel={submitLabel} onCancel={onCancel} onSearch={handleSearch} onSubmit={handleSubmit} data-sentry-element="AddAnimalsForm" data-sentry-source-file="index.tsx" />
        </Modal>;
};
export const ModalAddAnimals = memo(ModalAddAnimalsBase);