import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';

import {apcReportFormName} from '../../components/apc-report-form/form-values';
import {convertCorrectionReportToFormValues} from '../../utils/convert-correction-report-to-form-values';
import {analysisProtocolCorrectionAction, GetCorrectionReportDetailAction} from '../action';

function* execute({payload: {focus, apcId}}: GetCorrectionReportDetailAction): SagaIterator {
    yield* put(analysisProtocolCorrectionAction.setLoading('correctionReportDetailLoading', true));
    yield* put(analysisProtocolCorrectionAction.setEditingCorrectionReportId(apcId));

    const response = yield* call(Api.getCorrectionReport, {apcId});

    if (response.isSuccess) {
        yield* put(formHelpers.initialize(apcReportFormName, convertCorrectionReportToFormValues(response.data)));
        yield* put(analysisProtocolCorrectionAction.getStableDateRelations(
            focus, response.data.earTag, response.data.correctionType,
        ));
    } else {
        yield putAll(showBeError(response, t('apc/apc')('title')));
    }

    yield* put(analysisProtocolCorrectionAction.setLoading('correctionReportDetailLoading', false));
}

export function* getCorrectionReportDetailSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/GET_CORRECTION_REPORT_DETAIL', execute);
}
