import {createSelector} from 'reselect';

import {BasicRole} from 'api/gen/BasicRole';
import {Breeder} from 'api/gen/Breeder';
import {Farm} from 'api/gen/Farm';
import {Organization} from 'api/gen/Organization';
import {OrganizationCenter} from 'api/gen/OrganizationCenter';
import {State} from 'app/setup';

import {UserDetailLoadingId} from '../types/user-detail-loading-id';

export const simpleUserDetailSelector = {
    memberOfSubjectsEditingId: ({userDetail}: State): number | null => userDetail.memberOfSubjectsEditingId,
    memberOfSubjectsModalVisible: ({userDetail}: State): boolean => userDetail.memberOfSubjectsModalVisible,
    userContactsModalVisible: ({userDetail}: State): boolean => userDetail.userContactsModalVisible,
    associationsModalVisible: ({userDetail}: State): string | null => userDetail.associationsModalVisible,
    breederOptions: ({userDetail}: State): Array<Breeder> => userDetail.breederOptions,
    farmOptions: ({userDetail}: State): Array<Farm> => userDetail.farmOptions,
    organizationOptions: ({userDetail}: State): Array<Organization> => userDetail.organizationOptions,
    facilityModalVisible: ({userDetail}: State): string | null => userDetail.facilityModalVisible,
    centersOptions: ({userDetail}: State): Array<OrganizationCenter> => userDetail.centerOptions,
    otherSubjectRoleOptions: ({userDetail}: State): Array<BasicRole> => userDetail.otherSubjectRoleOptions,
    associationRoleOptions: ({userDetail}: State): Array<BasicRole> => userDetail.associationRoleOptions,
    breederRoleOptions: ({userDetail}: State): Array<BasicRole> => userDetail.breederRoleOptions,
    organizationRoleOptions: ({userDetail}: State): Array<BasicRole> => userDetail.organizationRoleOptions,
    organizationFarmOptions: ({userDetail}: State): Array<OrganizationCenter> => userDetail.organizationFarmOptions,
    loading: ({userDetail}: State): Array<UserDetailLoadingId> => userDetail.loading,
};

export const userSubjectOptionsSelector = createSelector(
    (state: State) => state.userDetail.subjectOptions,
    subjects => subjects.map(s => ({value: s.id, label: `${s.businessName} (${s.code})`})),
);

export const userAssociationOptionsSelector = createSelector(
    (state: State) => state.userDetail.associationOptions,
    options => options.map(o => ({value: o.id, label: o.name})),
);

export const userOtherSubjectsOptionsSelector = createSelector(
    (state: State) => state.userDetail.otherSubjectsOptions,
    options => options.map(o => ({value: o.id, label: o.otherSubjectName})),
);

export const otherSubjectRoleOptionsSelector = createSelector(
    (state: State) => state.userDetail.otherSubjectRoleOptions,
    options => options.map(o => ({value: o.id, label: o.name})),
);

export const associationRoleOptionsSelector = createSelector(
    (state: State) => state.userDetail.associationRoleOptions,
    options => options.map(o => ({value: o.id, label: o.name})),
);

export const breederRoleOptionsSelector = createSelector(
    (state: State) => state.userDetail.breederRoleOptions,
    options => options.map(o => ({value: o.id, label: o.name})),
);

export const organizationRoleOptionsSelector = createSelector(
    (state: State) => state.userDetail.organizationRoleOptions,
    options => options.map(o => ({value: o.id, label: o.name})),
);

export const userBreederOptionsSelector = createSelector(
    (state: State) => state.userDetail.breederOptions,
    options => options.map(o => ({
        value: o.id,
        label: `${o.registrationNumber}${o.subjectName ? ` - ${o.subjectName}` : ''}`,
    })),
);

export const userFarmOptionsSelector = createSelector(
    (state: State) => state.userDetail.farmOptions,
    options => options.map(o => ({value: o.id, label: `${o.code}${o.name ? ` - ${o.name}` : ``}`})),
);

export const userOrganizationOptionsSelector = createSelector(
    (state: State) => state.userDetail.organizationOptions,
    options => options.map(o => ({value: o.id, label: `${o.code}${o.name ? ` - ${o.name}` : ''}`})),
);

export const userCenterOptionsSelector = createSelector(
    (state: State) => state.userDetail.centerOptions,
    options => options.map(o => ({value: o.id, label: o.code})),
);

export const userOrganizationFarmOptionsSelector = createSelector(
    (state: State) => state.userDetail.organizationFarmOptions,
    options => options.map(o => ({value: o.id, label: `${o.code}${o.name ? ` - ${o.name}` : ``}`})),
);

