import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {matingAction, ConfirmCreateListAction} from '../action';
import {getAnimalListVersion} from './get-animal-list-version';

const te = t('mating/sagas');

function* execute({payload: {sex, animalListId, associationType}}: ConfirmCreateListAction): SagaIterator {
    const version = yield* call(getAnimalListVersion, animalListId);

    yield* put(matingAction.setLoading('confirmAnimalListNameInProgress', true));

    const response = yield* call(Api.updateAnimalListFromSession, {version}, {animalListId});

    const title = te('saveAnimalList');

    if (response.isSuccess) {
        yield* put(matingAction.getAnimalLists(sex, associationType, true));
        yield* put(showSuccess(title, te('listOfStallionsSavedSuccessfully')));
    } else {
        yield putAll(showBeError(response, title));
    }
    yield* put(matingAction.toggleOverwriteAnimalListVisibility(sex, none));
    yield* put(matingAction.setLoading('confirmAnimalListNameInProgress', false));
}

export function* confirmAnimalListSaga(): SagaIterator {
    yield takeLatestF('mating/CONFIRM_ANIMAL_LIST', execute);
}
