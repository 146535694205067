import {Opt} from 'ts-opt';

import {PagedModelAssociationRole} from 'api/gen/PagedModelAssociationRole';
import {PagedModelBasicRole} from 'api/gen/PagedModelBasicRole';
import {PagedModelBreederRole} from 'api/gen/PagedModelBreederRole';
import {PagedModelOrganizationRole} from 'api/gen/PagedModelOrganizationRole';
import {PagedModelOtherSubjectRole} from 'api/gen/PagedModelOtherSubjectRole';
import {PagedModelPermissionDetail} from 'api/gen/PagedModelPermissionDetail';
import {PagedModelRolePermission} from 'api/gen/PagedModelRolePermission';
import {PagedModelRoleView} from 'api/gen/PagedModelRoleView';
import {PagedModelUserProfileContact} from 'api/gen/PagedModelUserProfileContact';
import {PagedModelUserSubjectView} from 'api/gen/PagedModelUserSubjectView';
import {PagedModelUserView} from 'api/gen/PagedModelUserView';
import {PermissionDetail} from 'api/gen/PermissionDetail';
import {RoleDetail} from 'api/gen/RoleDetail';
import {RoleView} from 'api/gen/RoleView';
import {UserDetail} from 'api/gen/UserDetail';
import {State} from 'app/setup';

import {AdminLoadingId} from '../types/admin-loading-id';

export const simpleAdminSelector = {
    rolePermissionsPage: ({admin}: State): Opt<PagedModelRolePermission> => admin.rolePermissionsPage,
    roleUnassignedPermissionsPage: ({admin}: State): Opt<PagedModelRolePermission> =>
        admin.roleUnassignedPermissionsPage,
    rolesPage: ({admin}: State): Opt<PagedModelRoleView> => admin.rolesPage,
    permissionDetailsPage: ({admin}: State): Opt<PagedModelPermissionDetail> => admin.permissionDetailsPage,
    editedPermissionId: ({admin}: State): number | null => admin.permissionIdInEdit,
    editedRoleId: ({admin}: State): number | null => admin.roleIdInEdit,
    permissionCreateVisible: ({admin}: State): boolean => admin.permissionCreateVisible,
    roleCreateModalVisible: ({admin}: State): boolean => admin.roleCreateModalVisible,
    permissionDetailsTableData: ({admin}: State): Array<PermissionDetail> =>
        admin.permissionDetailsPage.prop('content').orElse([]),
    roleDetailsTableData: ({admin}: State): Array<RoleView> =>
        admin.rolesPage.prop('content').orElse([]),
    roleDetail: ({admin}: State): Opt<RoleDetail> => admin.roleDetail,
    subordinateAssignedRolePage: ({admin}: State): Opt<PagedModelBasicRole> => admin.subordinateAssignedRolePage,
    subordinateUnassignedRolePage: ({admin}: State): Opt<PagedModelBasicRole> => admin.subordinateUnassignedRolePage,
    usersPage: ({admin}: State): Opt<PagedModelUserView> => admin.usersPage,
    userDetail: ({admin}: State): Opt<UserDetail> => admin.userDetail,
    memberOfSubjectsPage: ({admin}: State): Opt<PagedModelUserSubjectView> => admin.memberOfSubjects,
    userContacts: ({admin}: State): Opt<PagedModelUserProfileContact> => admin.userContacts,
    userAssociations: ({admin}: State): Opt<PagedModelAssociationRole> => admin.userAssociations,
    userBreeders: ({admin}: State): Opt<PagedModelBreederRole> => admin.userBreeders,
    userOtherSubjectRoles: ({admin}: State): Opt<PagedModelOtherSubjectRole> => admin.userOtherSubjectRoles,
    organizations: ({admin}: State): Opt<PagedModelOrganizationRole> => admin.organizations,
    loading: ({admin}: State): Array<AdminLoadingId> => admin.loading,
};
