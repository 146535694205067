import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {SubjectFormValues} from '../../types/subject-form-values';

const te = t('admin/users');

export const createValidate = (isAssociation: boolean, isEdit: boolean) =>
    (values: SubjectFormValues): Errors<SubjectFormValues> => {
        const validator = new EskotValidator(values);
        const approveIsInvalid = (
            values.approvedFrom !== null
        && values.approvedTo !== null
        && values.approvedFrom > values.approvedTo
        );

        if (!isEdit) {
            validator.nonEmpty('approvedFrom', te('approvedFrom'));
        }
        validator.customRule(
            'approvedTo',
            approveIsInvalid,
            te('dateFromToIsInvalid'),
        );
        validator.nonEmpty('subjectId', isAssociation ? te('association') : te('subject'));
        validator.nonEmpty('roleIds', te('roles'));

        return validator.generateErrorsObject();
    };
