import {ActionSettings, Options, TableDataRequest, ToolbarButton, debounceTime} from '@fl/cmsch-fe-library';
import {isEmpty, debounce} from 'lodash/fp';
import {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {PagedModelUserSubjectView} from 'api/gen/PagedModelUserSubjectView';
import {Subject} from 'api/gen/Subject';
import {State} from 'app/setup';
import {TFunction, useOurTranslation} from 'app/translations';

import {defaultProps} from '../constants/consts';
import {userDetailAction} from '../model/action';
import {simpleUserDetailSelector, userSubjectOptionsSelector} from '../model/selector';
import {CreateOrEditOrNull} from '../types/create-or-edit-or-null';
import {MemberOfSubjectsTabProps} from '../types/member-of-subjects-tab-props';
import {MemberOfSubjectsTableType} from '../types/member-of-subjects-table-type';
import {UserMemberSubjectForm} from '../types/user-member-form-values';
import {getMatchingApprovedValues} from '../utils/get-matching-approved-values';

const getFormDataForInit = (
    rowForEdit: MemberOfSubjectsTableType,
    userProfileId: number,
): UserMemberSubjectForm => {
    const matchingApprovedValues = getMatchingApprovedValues(rowForEdit);

    return {
        subjectMemberId: rowForEdit.subjectUserProfileId,
        userProfileId,
        approvedFrom: matchingApprovedValues.approvedFrom,
        approvedTo: matchingApprovedValues.approvedTo,
        subjectId: rowForEdit.subjectId,
    };
};

interface UseMemberOfSubjectsTabSetup {
    headerButtons: Array<ToolbarButton>;
    actionSettings: ActionSettings<MemberOfSubjectsTableType>;
    visibleModal: boolean;
    subjectOptions: Options<number>;
    formDataForInit: UserMemberSubjectForm | null;
    pathnamesForRefresh: Array<RegExp>;
    userProfileId: number;
    isFromAdminModule: boolean;
    modalTitle: string;
    t: TFunction<'userDetail'>;
    handleOpenCreateModal(): void;
    getMemberOfSubjects(tableDataRequest: Opt<TableDataRequest<MemberOfSubjectsTableType>>): void;
    handleSubmitModal(): void;
    handleSubjectSearch(searchText: string): void;
    handleCloseModal(): void;
    validateAsync(): void;
    memberOfSubjectsSelector(state: State): Opt<PagedModelUserSubjectView>;
}

// eslint-disable-next-line max-lines-per-function
export const useMemberOfSubjectsTabSetup = ({
    getTableData,
    pathnamesForRefresh,
    selector,
    adminProps,
}: MemberOfSubjectsTabProps): UseMemberOfSubjectsTabSetup => {
    const isFromAdminModule = !isEmpty(adminProps);

    const {
        canUpdate,
        userProfileId,
        createEntry,
        updateEntry,
    } = isFromAdminModule ? adminProps : defaultProps;

    const dispatch = useDispatch();
    const {t} = useOurTranslation('userDetail');

    const [rowForEdit, setRowForEdit] = useState<MemberOfSubjectsTableType | null>(null);
    const [actionMode, setActionMode] = useState<CreateOrEditOrNull>(null);

    const visibleModal = useSelector(simpleUserDetailSelector.memberOfSubjectsModalVisible);
    const subjectOptions: Options<number> = useSelector(userSubjectOptionsSelector);

    const formDataForInit = useMemo(() => {
        if (rowForEdit && userProfileId) {
            return getFormDataForInit(rowForEdit, userProfileId);
        }
        return null;
    }, [rowForEdit, userProfileId]);

    const handleOpenCreateModal = useCallback(() => {
        setActionMode('create');
        dispatch(userDetailAction.setMemberOfSubjectsModalVisible(true));
        dispatch(userDetailAction.setEditedUserMemberId(null));
    }, [dispatch]);

    const handleOpenEditModal = useCallback((rowId: number, row: MemberOfSubjectsTableType) => {
        setActionMode('edit');
        const memberOfSubjectForEdit: Array<Subject> = [
            {
                businessName: row.businessName,
                code: row.code,
                id: row.subjectId,
            },
        ];
        dispatch(userDetailAction.setMemberOfSubjectsModalVisible(true));
        dispatch(userDetailAction.setEditedUserMemberId(rowId));
        dispatch(userDetailAction.setSubjectsOptions(memberOfSubjectForEdit));
        setRowForEdit(row);
    }, [dispatch]);

    const handleCloseModal = useCallback(() => {
        setActionMode(null);
        dispatch(userDetailAction.setMemberOfSubjectsModalVisible(false));
        dispatch(userDetailAction.setEditedUserMemberId(null));
    }, [dispatch]);

    const handleSubmitModal = useCallback(() => actionMode === 'edit' ? updateEntry?.() : createEntry?.(),
        [actionMode, createEntry, updateEntry]);

    const handleSubjectSearch = useCallback((value: string): void => {
        dispatch(userDetailAction.searchSubjects(value));
    }, [dispatch]);

    const onSyncValidate = useCallback(() => {
        dispatch(userDetailAction.validateUserMembership());
    }, [dispatch]);

    const validateDebounced = useMemo(() => debounce(debounceTime)(onSyncValidate), [onSyncValidate]);

    const modalTitle = useMemo(() => actionMode === 'edit'
        ? t('editUserAsMemberOfSubject')
        : t('creteUserAsMemberOfSubject'),
    [actionMode, t]);

    const headerButtons: Array<ToolbarButton> = useMemo(() => [
        {
            id: 'createUser',
            icon: 'plusOutlined',
            onClick: handleOpenCreateModal,
            title: t('memberOfSubject'),
            visuals: 'primary',
            hidden: !canUpdate,
        },
    ], [handleOpenCreateModal, t, canUpdate]);

    const actionSettings: ActionSettings<MemberOfSubjectsTableType> = useMemo(() => canUpdate ? ({
        extraActions: [
            {
                id: 'edit',
                role: 'edit',
                callback: handleOpenEditModal,
                hidden: !canUpdate,
            },
        ],
    }) : {}, [handleOpenEditModal, canUpdate]);

    return {
        getMemberOfSubjects: getTableData,
        actionSettings,
        headerButtons,
        formDataForInit,
        handleCloseModal,
        handleOpenCreateModal,
        handleSubjectSearch,
        handleSubmitModal,
        isFromAdminModule,
        memberOfSubjectsSelector: selector,
        modalTitle,
        pathnamesForRefresh,
        subjectOptions,
        t,
        userProfileId,
        validateAsync: validateDebounced,
        visibleModal,
    };
};
