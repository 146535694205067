import {TableColumn} from '@fl/cmsch-fe-library';

import {BreederRoleTableType} from '../../types/breeder-role-table-type';
import {getFarmCodeFormat} from './farm-code-format';

export const columns: Array<TableColumn<BreederRoleTableType>> = [
    {
        field: 'breederRegistrationNumber',
        type: 'number',
        formatValue: String,
        align: 'left',
    },
    {
        field: 'subjectName',
        type: 'string',
    },
    {
        field: 'roleCode',
        type: 'string',
    },
    {
        field: 'roleName',
        type: 'string',
    },
    {
        field: 'approvedFrom',
        type: 'date',
        dateFormat: 'date',
    },
    {
        field: 'approvedTo',
        type: 'date',
        dateFormat: 'date',
        filterTypes: ['null'],
    },
    {
        field: 'farmCode',
        type: 'string',
        formatValue: getFarmCodeFormat,
    },
];
