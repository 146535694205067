import {NullableKeys} from '@fl/cmsch-fe-library';

import {Criterias} from './criterias';

export const matingCowsFilterFormName = 'matingCowsFilter';

export type MatingCowsFilterFormValues = NullableKeys<{
    breederId: number,
    farmIds: Array<number>,
    criterias: Criterias,
    validationHack: number,
}>;

export const matingCowsInitialFormValues = {
    breederId: null,
    farmIds: null,
    criterias: null,
    validationHack: null,
};
