import {Options} from '@fl/cmsch-fe-library';

import {AuthDiscriminator} from 'api/gen/AuthDiscriminator';

export const adminDiscriminatorOptions: Options<AuthDiscriminator> = [
    {label: 'AppRole', value: 'APP_ROLE'},
    {label: 'SysAdmin', value: 'SYS_ADMIN'},
    {label: 'RepRole', value: 'REP_ROLE'},
    {label: 'DBRole', value: 'DB_ROLE'},
];
