import {AnimalType} from 'api/gen/AnimalType';
import {AppModule} from 'api/gen/AppModule';
import {GridCode} from 'api/gen/GridCode';
import {TableId} from 'common/types/table-id';
import {getTableConfig} from 'utils/table-config';

interface TableInfo {
    module: AppModule;
    code: GridCode;
}

export const getCabTableInfo = (animalType: AnimalType): TableInfo => {
    const tableId: TableId = animalType === 'BREEDING_FEMALES' ? 'comprehensiveBreedingFemale' : 'comprehensiveCalves';
    const {serverUserSettingsIds} = getTableConfig(tableId) || {};

    const {code, module} = serverUserSettingsIds || {};

    if (!code || !module) throw new Error(`Table config of table ${tableId} hasn't code or module`);

    return {
        code,
        module,
    };
};
