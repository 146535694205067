import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { CabFemalesTable } from '../components/comprehensive-breeding-female-table';
import { CabForm } from '../components/comprehensive-form';
import { CabModals } from '../components/comprehensive-modals';
import { animalBrowserAction } from '../model';
import { simpleAnimalBrowserSelector } from '../model/selector';
const breedingFemales = 'BREEDING_FEMALES';
const ComprehensiveBreedingFemaleBase: FC = _ => {
  const {
    t
  } = useOurTranslation('comprehensiveBreedingFemaleBrowser');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(animalBrowserAction.clearData());
    dispatch(animalBrowserAction.filterCabFemales('FIRST_LOAD'));
    dispatch(animalBrowserAction.getAnimalLists(breedingFemales));
    return () => {
      dispatch(animalBrowserAction.firstBreedingFemaleLoad());
    };
  }, [dispatch]);
  const title = t('title');
  const header = useMemo(() => <ScreenHeader title={title} moduleForTutorials="ANIMALS_BROWSER_ALL" />, [title]);
  const loading = useSelector(simpleAnimalBrowserSelector.loading);
  const sessionWasLoaded = useSelector(simpleAnimalBrowserSelector.firstCabBreedingFemalesLoad);
  const isCabFemalesSessionEmpty = useSelector(simpleAnimalBrowserSelector.isCabFemalesSessionEmpty);
  const cabFemalesLists = useSelector(simpleAnimalBrowserSelector.cabFemalesLists);
  const confirmAnimalListNameFromIdsLoading = loading.includes('confirmAnimalListNameFromIdsInProgress');
  const filterCabFemales = useCallback(() => {
    dispatch(animalBrowserAction.filterCabFemales());
  }, [dispatch]);
  return <Content browserTabTitle={t('pageTitle')} data-sentry-element="Content" data-sentry-component="ComprehensiveBreedingFemaleBase" data-sentry-source-file="comprehensive-breeding-female.tsx">
            <Card title={header} titleSize="big" data-sentry-element="Card" data-sentry-source-file="comprehensive-breeding-female.tsx">
                <CabForm onSubmit={filterCabFemales} animalType="BREEDING_FEMALES" animalsLists={cabFemalesLists} data-sentry-element="CabForm" data-sentry-source-file="comprehensive-breeding-female.tsx" />
                {sessionWasLoaded && !isCabFemalesSessionEmpty && <CabFemalesTable loading={loading} />}
                <CabModals isAnimalsPageEmpty={isCabFemalesSessionEmpty} animalsLists={cabFemalesLists} confirmAnimalListNameFromIdsLoading={confirmAnimalListNameFromIdsLoading} animalType="BREEDING_FEMALES" data-sentry-element="CabModals" data-sentry-source-file="comprehensive-breeding-female.tsx" />
            </Card>
        </Content>;
};
export const ComprehensiveBreedingFemale = memo(ComprehensiveBreedingFemaleBase);