import { ActionSettings, TableDataRequest } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as routerActions from 'redux-first-history';
import { Opt } from 'ts-opt';
import { simpleUserSelector } from 'app/user/model/selector';
import { ConnectedTable } from 'utils/tables';
import { adminUserPathNameForRefresh } from '../../constants/consts';
import { adminPermissions } from '../../constants/permissions';
import { adminAction } from '../../model';
import { simpleAdminSelector } from '../../model/selector';
import { UsersTableType } from '../../types/users-table-type';
import { usersColumns } from './columns';
import { rowMapper } from './row-mapper';
const UsersTableBase: FC = _ => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(simpleUserSelector.currentUserPermissions);
  const getUsers = useCallback((tableDataRequest: Opt<TableDataRequest<UsersTableType>>): void => {
    dispatch(adminAction.getUsers(tableDataRequest));
  }, [dispatch]);
  const handleShowDetail = useCallback((rowId: number) => {
    dispatch(routerActions.push(`/administrace/uzivatele/${rowId}`));
  }, [dispatch]);
  const actionSettings: ActionSettings<UsersTableType> = useMemo(() => ({
    extraActions: [{
      id: 'detail',
      callback: handleShowDetail,
      hidden: !userPermissions.includes(adminPermissions.users),
      icon: 'searchOutlined'
    }]
  }), [handleShowDetail, userPermissions]);
  return <Fragment>
            <ConnectedTable tableId="users" columns={usersColumns} onFetchTableData={getUsers} rowDataToTableRowMapper={rowMapper} rowDataPageSelector={simpleAdminSelector.usersPage} actionSettings={actionSettings} showFulltextFilter enableTableSettings enableTableUserSettings paginationPosition="both" highlightOnEdit pathnamesForRefresh={adminUserPathNameForRefresh} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
        </Fragment>;
};
export const UsersTable = memo(UsersTableBase);