import {none, Opt} from 'ts-opt';

import {PagedModelAssociationRole} from 'api/gen/PagedModelAssociationRole';
import {PagedModelBasicRole} from 'api/gen/PagedModelBasicRole';
import {PagedModelBreederRole} from 'api/gen/PagedModelBreederRole';
import {PagedModelOrganizationRole} from 'api/gen/PagedModelOrganizationRole';
import {PagedModelOtherSubjectRole} from 'api/gen/PagedModelOtherSubjectRole';
import {PagedModelPermissionDetail} from 'api/gen/PagedModelPermissionDetail';
import {PagedModelRolePermission} from 'api/gen/PagedModelRolePermission';
import {PagedModelRoleView} from 'api/gen/PagedModelRoleView';
import {PagedModelUserProfileContact} from 'api/gen/PagedModelUserProfileContact';
import {PagedModelUserSubjectView} from 'api/gen/PagedModelUserSubjectView';
import {PagedModelUserView} from 'api/gen/PagedModelUserView';
import {RoleDetail} from 'api/gen/RoleDetail';
import {UserDetail} from 'api/gen/UserDetail';

import {AdminLoadingId} from '../types/admin-loading-id';
import {AppInfoType} from '../types/app-info-type';

export interface AdminState {
    appInfo: Opt<AppInfoType>;
    rolesPage: Opt<PagedModelRoleView>;
    postRoleModalVisible: boolean;
    editRoleModalData: Opt<string>;
    roleDetail: Opt<RoleDetail>;
    rolePermissionsPage: Opt<PagedModelRolePermission>;
    roleUnassignedPermissionsPage: Opt<PagedModelRolePermission>;
    permissionDetailsPage: Opt<PagedModelPermissionDetail>;
    permissionIdInEdit: number | null;
    roleIdInEdit: number | null;
    permissionCreateVisible: boolean;
    roleCreateModalVisible: boolean;
    subordinateAssignedRolePage: Opt<PagedModelBasicRole>;
    subordinateUnassignedRolePage: Opt<PagedModelBasicRole>;
    usersPage: Opt<PagedModelUserView>;
    userDetail: Opt<UserDetail>;
    memberOfSubjects: Opt<PagedModelUserSubjectView>;
    userContacts: Opt<PagedModelUserProfileContact>;
    userAssociations: Opt<PagedModelAssociationRole>;
    userOtherSubjectRoles: Opt<PagedModelOtherSubjectRole>;
    organizations: Opt<PagedModelOrganizationRole>;
    userBreeders: Opt<PagedModelBreederRole>;
    loading: Array<AdminLoadingId>;
}

export const initialAdminState: AdminState = {
    appInfo: none,
    rolesPage: none,
    postRoleModalVisible: false,
    organizations: none,
    editRoleModalData: none,
    roleDetail: none,
    rolePermissionsPage: none,
    roleUnassignedPermissionsPage: none,
    permissionDetailsPage: none,
    permissionIdInEdit: null,
    roleIdInEdit: null,
    permissionCreateVisible: false,
    roleCreateModalVisible: false,
    subordinateAssignedRolePage: none,
    subordinateUnassignedRolePage: none,
    usersPage: none,
    userDetail: none,
    memberOfSubjects: none,
    userContacts: none,
    userAssociations: none,
    userOtherSubjectRoles: none,
    userBreeders: none,
    loading: [],
};
