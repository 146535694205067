import {ActionSettings, Options, TableDataRequest, ToolbarButton, debounceTime} from '@fl/cmsch-fe-library';
import {debounce, isEmpty} from 'lodash/fp';
import {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {Association} from 'api/gen/Association';
import {BasicRole} from 'api/gen/BasicRole';
import {PagedModelAssociationRole} from 'api/gen/PagedModelAssociationRole';
import {State} from 'app/setup';
import {TFunction, useOurTranslation} from 'app/translations';

import {defaultProps} from '../constants/consts';
import {userDetailAction} from '../model/action';
import {
    associationRoleOptionsSelector,
    simpleUserDetailSelector,
    userAssociationOptionsSelector,
} from '../model/selector';
import {AssociationRoleTableType} from '../types/association-role-table-type';
import {AssociationTabProps} from '../types/associaton-tab-props';
import {CreateOrEditOrNull} from '../types/create-or-edit-or-null';
import {SubjectFormEntryData} from '../types/subject-form-values';
import {getMatchingApprovedValues} from '../utils/get-matching-approved-values';

const associationForm = 'associationForm';

interface UseAssociationTabSetup {
    userProfileId: number;
    visibleModal: string | null;
    associationOptions: Options<number>;
    roleOptions: Options<number>;
    formDataForInit: SubjectFormEntryData | null;
    associationForm: string;
    headerButtons: Array<ToolbarButton>;
    actionSettings: ActionSettings<AssociationRoleTableType>;
    actionMode: CreateOrEditOrNull;
    pathnamesForRefresh: Array<RegExp>;
    isFromAdminModule: boolean;
    t: TFunction<'admin/users'>;
    getAsociations(tableDataRequest: Opt<TableDataRequest<AssociationRoleTableType>>): void;
    handleSubmitModal(): void;
    handleOpenCreateModal(): void;
    handleCloseModal(): void;
    handleAssociationSearch(searchText: string): void;
    validateDebounced(): void;
    asociationsSelector(state: State): Opt<PagedModelAssociationRole>;
}

// eslint-disable-next-line max-lines-per-function
export const useAssociationTabSetup = ({
    getTableData,
    pathnamesForRefresh,
    selector,
    adminProps,
}: AssociationTabProps): UseAssociationTabSetup => {
    const dispatch = useDispatch();
    const {t} = useOurTranslation('admin/users');
    const isFromAdminModule = !isEmpty(adminProps);
    const {canUpdate, userProfileId, createEntry, updateEntry} = isFromAdminModule ? adminProps : defaultProps;

    const [actionMode, setActionMode] = useState<CreateOrEditOrNull>(null);
    const [rowForEdit, setRowForEdit] = useState<AssociationRoleTableType | null>(null);

    const formDataForInit = useMemo((): SubjectFormEntryData | null => {
        if (rowForEdit === null) return null;
        const sameApproved = getMatchingApprovedValues(rowForEdit);

        return {
            userProfileSubjectRoleId: rowForEdit.userProfileSubjectRoleId,
            approvedFrom: sameApproved.approvedFrom,
            approvedTo: sameApproved.approvedTo,
            subjectId: rowForEdit.associationId,
            roleId: rowForEdit.roleId,
            roles: rowForEdit.roles,
        };
    }, [rowForEdit]);

    const visibleModal = useSelector(simpleUserDetailSelector.associationsModalVisible);
    const associationOptions: Options<number> = useSelector(userAssociationOptionsSelector);
    const roleOptions: Options<number> = useSelector(associationRoleOptionsSelector);

    const handleSubmitModal = useCallback(() => actionMode === 'edit' ? updateEntry?.() : createEntry?.(),
        [actionMode, createEntry, updateEntry]);

    const handleOpenEditModal = useCallback((_rowId: string, row: AssociationRoleTableType) => {
        setRowForEdit(row);
        setActionMode('edit');
        const stableAssociationOptions: Array<Association> = [
            {
                id: row.associationId,
                name: row.associationName,
            },
        ];
        const roles: Array<BasicRole> = row.roles.map((r): BasicRole => ({
            id: r.roleId,
            name: r.roleName,
            code: r.roleCode,
        }));
        dispatch(userDetailAction.setAssociationOptions(stableAssociationOptions));
        dispatch(userDetailAction.setAssociationRoleOptions(roles));
        dispatch(userDetailAction.setAssociationsModalVisible(associationForm));
    }, [dispatch]);

    const handleOpenCreateModal = useCallback(() => {
        setActionMode('create');
        dispatch(userDetailAction.setAssociationsModalVisible(associationForm));
        setRowForEdit(null);
    }, [dispatch]);

    const handleCloseModal = useCallback(() => {
        setActionMode(null);
        dispatch(userDetailAction.setAssociationsModalVisible(null));
        dispatch(userDetailAction.setAssociationOptions([]));
        dispatch(userDetailAction.setAssociationRoleOptions([]));
        setRowForEdit(null);
    }, [dispatch]);

    const handleAssociationSearch = useCallback((searchText: string): void => {
        dispatch(userDetailAction.searchAssociations(searchText));
    }, [dispatch]);

    const validateData = useCallback(() => {
        dispatch(userDetailAction.validateUserAssociations());
    }, [dispatch]);

    const validateDebounced = useMemo(() => debounce(debounceTime)(validateData), [validateData]);

    const headerButtons: Array<ToolbarButton> = useMemo(() => isFromAdminModule ? [
        {
            id: 'createRole',
            icon: 'plusOutlined',
            onClick: handleOpenCreateModal,
            title: t('association'),
            visuals: 'primary',
            hidden: !canUpdate,
        },
    ] : []
    , [handleOpenCreateModal, t, canUpdate, isFromAdminModule]);

    const actionSettings: ActionSettings<AssociationRoleTableType> = useMemo(() => canUpdate ? ({
        extraActions: [
            {
                id: 'edit',
                role: 'edit',
                callback: handleOpenEditModal,
                hidden: !canUpdate,
            },
        ],
    }) : {}, [handleOpenEditModal, canUpdate]);

    return {
        associationForm,
        t,
        userProfileId,
        isFromAdminModule,
        formDataForInit,
        visibleModal,
        associationOptions,
        roleOptions,
        getAsociations: getTableData,
        handleSubmitModal,
        handleOpenCreateModal,
        handleCloseModal,
        handleAssociationSearch,
        actionMode,
        asociationsSelector: selector,
        pathnamesForRefresh,
        validateDebounced,
        headerButtons,
        actionSettings,
    };
};
