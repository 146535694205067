import {Options} from '@fl/cmsch-fe-library';
import {none, Opt} from 'ts-opt';

import {AnalysisProtocol} from 'api/gen/AnalysisProtocol';
import {ApcStableDate} from 'api/gen/ApcStableDate';
import {CorrectionReportDetail} from 'api/gen/CorrectionReportDetail';
import {PagedModelCorrectionReport} from 'api/gen/PagedModelCorrectionReport';

import {AnalysisProtocolCorrectionLoadingId} from '../types/analysis-protocol-correction-loading-id';

export interface AnalysisProtocolCorrectionState {
    pageAnalysisProtocolCorrectionReports: Opt<PagedModelCorrectionReport>;
    loading: Array<AnalysisProtocolCorrectionLoadingId>;
    apcStableDate: Opt<ApcStableDate>;
    proficiencyTestDateOptions: Opt<Options<string>>;
    stableOptions: Opt<Options<string>>;
    technicianOptions: Opt<Options<string>>;
    createdCorrectionReports: Array<CorrectionReportDetail>;
    editingCorrectionReportId?: number;
    analysisProtocol: Opt<AnalysisProtocol>;
}

export const initialAnalysisProtocolCorrectionState: AnalysisProtocolCorrectionState = {
    pageAnalysisProtocolCorrectionReports: none,
    loading: [],
    apcStableDate: none,
    proficiencyTestDateOptions: none,
    stableOptions: none,
    technicianOptions: none,
    createdCorrectionReports: [],
    editingCorrectionReportId: undefined,
    analysisProtocol: none,
};
