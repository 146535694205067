import {TableColumn} from '@fl/cmsch-fe-library';

import {OtherSubjectRoleTableType} from '../../types/other-subject-role-table-type';

export const columns: Array<TableColumn<OtherSubjectRoleTableType>> = [
    {
        field: 'otherSubjectName',
        type: 'string',
    },
    {
        field: 'roleCode',
        type: 'string',
    },
    {
        field: 'roleName',
        type: 'string',
    },
    {
        field: 'approvedFrom',
        type: 'date',
        dateFormat: 'date',
    },
    {
        field: 'approvedTo',
        type: 'date',
        dateFormat: 'date',
        filterTypes: ['null'],
    },
];
