export const animalBrowserTranslations = {
    CS: {
        cowBrowserTable: {
            pageTitle: 'Prohlížeč plemenic',
            tableName: 'Přehled plemenic ve stáji',
            alive: 'Živá',
            bodyStructureToMusculature: 'Stavba těla / Osvalení',
            breed: 'Plem.',
            calveDate: 'Posl. otel.',
            changeCode: 'Z/V',
            currentLactationDays: 'Lakt. dny (prob.)',
            currentLactationFatKg: 'TukKg (prob.)',
            currentLactationFatPercentage: 'TukProc (prob.)',
            currentLactationLactoseKg: 'LaktKg (prob.)',
            currentLactationLactosePercentage: 'LaktProc (prob.)',
            currentLactationMilkKg: 'Posl. nádoj',
            currentLactationOrder: 'Poř. lakt. (prob.)',
            currentLactationProteinKg: 'BlkKg (prob.)',
            currentLactationProteinPercentage: 'BlkProc (prob.)',
            currentLactationSomaticCells: 'SB (prob.)',
            currentLactationMilkKgSum: 'Mléko celk. (prob.)',
            currentLactationUrea: 'Močovina (prob.)',
            daysFromBirth: 'CŽU_DenŽivota',
            birthDate: 'Dat. nar.',
            earTag: 'UZ',
            exterior: 'EXT celkem',
            extremities: 'Končetiny',
            avgLactationFatKg: 'TukKg (Ø)',
            avgLactationFatPercentage: 'TukProc (Ø)',
            fatherLineRegistry: 'Otec',
            mothersFatherLineRegistry: 'OM_LinReg',
            mothersFatherName: 'OM_jméno',
            fatherName: 'Jméno otce',
            genotype: 'g',
            inseminationOrder: 'Poř. insem.',
            avgLactationCount: 'Průměr z (Ø)',
            avgLactationDays: 'Lakt. Dny (Ø)',
            lastInbreedingAt: 'Posl. zapuš.',
            lastLactationDate: 'Dat. ukonč.(uko.)',
            lastLactationDays: 'Lakt. dny (uko.)',
            lastLactationFatKg: 'TukKg (uko.)',
            lastLactationFatPercentage: 'TukProc (uko.)',
            lastLactationMilkKg: 'Mléko (uko.)',
            lastLactationOrder: 'Poř. lakt. (uko.)',
            lastLactationProteinKg: 'BlkKg (uko.)',
            lastLactationProteinPercentage: 'BlkProc (uko.)',
            lifelongLactationFatKg: 'CŽU_TukKg',
            lifelongLactationFatPercentage: 'CŽU_TukProc',
            lifelongLactationDaysSum: 'CŽU_PočDníVse',
            lifelongLactationMilkKgSum: 'CŽU_MlékoCelk',
            lifelongLactationProteinKg: 'CŽU_BlkKg',
            lifelongLactationProteinPercentage: 'CŽU_BlkProc',
            lifelongStandardizedLactationDays: 'CŽU_PočDní',
            lifelongLactationMilkKg: 'CŽU_Mléko',
            maxLactationFatKg: 'TukKg (max)',
            maxLactationFatPercentage: 'TukProc (max)',
            maxLactationDays: 'Lakt. Dny (max)',
            maxLactationOrder: 'Poř. lakt. (max)',
            maxLactationMilkKg: 'Mléko (max)',
            maxLactationProteinKg: 'BlkKg (max)',
            maxLactationProteinPercentage: 'BlkProc (max)',
            avgLactationMilkKg: 'Mléko (Ø)',
            milkKgAvgPerDay: 'CŽU_NadojDenZivota',
            milkKgAvgPerLactationDay: 'CŽU_NadojDenLaktace',
            milkStrengthToFrame: 'Mléčná síla (H) / Rámec (C)',
            motherEarTag: 'Matka',
            name: 'Jméno',
            pregnant: 'Březí',
            avgLactationProteinKg: 'BlkKg (Ø)',
            avgLactationProteinPercentage: 'BlkProc (Ø)',
            selectionIndex: 'SI',
            servicePeriod: 'SP',
            studbookSection: 'PK',
            udder: 'Vemeno',
            proficiencyTest: 'KU',
            proficiencyTestDate: 'Posl. kont.',
            motherMaxLactationFatPercentage: 'TukProc (max)',
            motherFirstLactationDays: 'Poč. dní (L1)',
            motherFirstLactationMilkKg: 'Mléko (L1)',
            motherFirstLactationProteinKg: 'BlkKg (L1)',
            motherFirstLactationProteinPercentage: 'BlkProc(L1)',
            motherFirstLactationFatKg: 'TukKg (L1)',
            motherFirstLactationFatPercentage: 'TukProc (L1)',
            motherLifelongLactationMilkKg: 'CŽU-MlékoCelk',
            motherExterior: 'Ext',
            motherMilkStrengthToFrame: 'Mléčná síla (H) / Rámec (C)',
            motherBodyStructureToMusculature: 'Stavba těla / Osvalení',
            motherExtremities: 'Končetiny',
            motherUdder: 'Vemeno',
            stableCode: 'Stáj',
            subjectBusinessName: 'Chovatel',
            sex: 'Pohl.',
            firstInbreedingAt: 'Dat. 1. zap.',
            firstPregnancyAt: 'Dat. zabř.',
            inbreedingBullLineRegistry: 'Po býkovi',
            inbreedingBullEarTag: 'UZ býka',
            inbreedingBullName: 'Jméno býka',
            fatherEarTag: 'UZ Otce',
            genotypeIndex: 'G',
            fatherSelectionIndex: 'SI otce',
            motherSelectionIndex: 'SI matky',
            motherName: 'Jméno matky',
            motherStandardizedLactationCount: 'Poč. norm. lakt.',
            motherAvgLactationDays: 'Lakt. dny (Ø)',
            motherAvgLactationMilkKg: 'Mléko (Ø)',
            motherMaxLactationOrder: 'Poř. max. L matky',
            motherMaxLactationMilkKg: 'Mléko (max)',
            motherMaxLactationProteinKg: 'BlkKg (max)',
            motherMaxLactationProteinPercentage: 'BlkProc (max)',
            motherMaxLactationFatKg: 'TukKg (max)',
            export: 'Export plemenic ve stáji',
            lastMilkYield: 'Poslední nádoj',
        },
        cowBrowserTableTooltip: {
            alive: 'Stav zvířete (živá/neživá)',
            bodyStructureToMusculature: 'H – Stavba těla / C – osvalení',
            calveDate: 'Datum posledního otelení',
            currentLactationDays: 'Počet dní prob. lakt.',
            currentLactationFatKg: 'Kg tuku prob. lakt.',
            currentLactationFatPercentage: 'Procenta tuku prob. lakt.',
            currentLactationLactoseKg: 'Kg laktózy prob. lakt.',
            currentLactationLactosePercentage: 'Procento laktózy prob. lakt.',
            currentLactationMilkKg: 'Nádoj z posl. kontroly KU',
            currentLactationOrder: 'Pořadí prob. lakt.',
            currentLactationProteinKg: 'Kg bílkoviny prob. lakt.',
            currentLactationProteinPercentage: 'Procenta bílkoviny prob. lakt.',
            currentLactationSomaticCells: 'Poč. somatických buněk prob. lakt.',
            currentLactationMilkKgSum: 'Celkový nádoj prob. lakt.',
            currentLactationUrea: 'Močovina',
            daysFromBirth: 'Počet dní života',
            exterior: 'Souhrnná známka za exteriér',
            extremities: 'Končetiny',
            avgLactationFatKg: 'Průměrný tuk (kg)',
            avgLactationFatPercentage: 'TukProc (Ø)',
            mothersFatherLineRegistry: 'Registr otce matky',
            mothersFatherName: 'Jméno otce matky',
            inseminationOrder: 'Pořadí inseminace',
            avgLactationCount: 'Počet laktací pro průměr',
            avgLactationDays: 'Průměrné laktační dny',
            lastInbreedingAt: 'Datum posledního zapuštení',
            lastLactationDate: 'Datum ukončení posl. laktace',
            lastLactationDays: 'Počet dní (posl. ukončená lakt.)',
            lastLactationFatKg: 'Kg tuku (posl. ukončená lakt.)',
            lastLactationFatPercentage: 'Procenta tuku (posl. ukončená lakt.)',
            lastLactationMilkKg: 'Nádoj  za norm. laktaci (posl. ukončená lakt.)',
            lastLactationOrder: 'Poslední ukončená laktace',
            lastLactationProteinKg: 'Kg bílkoviny (posl. ukončená lakt.)',
            lastLactationProteinPercentage: 'Procenta bílkoviny (posl. ukončená lakt.)',
            lifelongLactationFatKg: 'Celoživotní – celková suma tuku (kg)',
            lifelongLactationFatPercentage: 'Celoživotní procento tuku',
            lifelongLactationDaysSum: 'Celoživotní – suma dní laktací',
            lifelongLactationMilkKgSum: 'Celoživotní – celková suma mléka',
            lifelongLactationProteinKg: 'Celoživotní – celková suma bílkovin (kg)',
            lifelongLactationProteinPercentage: 'Celoživotní procento bílkovin',
            lifelongStandardizedLactationDays: 'Celoživotní – suma dní normovaných laktací',
            lifelongLactationMilkKg: 'Celoživotní – celková suma mléka za norm. laktace',
            maxLactationFatKg: 'Kg tuku max. laktace',
            maxLactationFatPercentage: 'Procenta tuku max. laktace',
            maxLactationDays: 'Celkový počet dní max. laktace',
            maxLactationOrder: 'Pořadí max. laktace',
            maxLactationMilkKg: 'Nádoj (kg) max. laktace',
            maxLactationProteinKg: 'Kg bílkoviny max. laktace',
            maxLactationProteinPercentage: 'Procenta bílkoviny max. laktace',
            avgLactationMilkKg: 'Průměrný nádoj (kg)',
            milkKgAvgPerDay: 'Průměrný nádoj na den života',
            milkKgAvgPerLactationDay: 'Průměrný nádoj na den laktace',
            milkStrengthToFrame: 'H – Mléčná síla / C – Tělesný rámec',
            avgLactationProteinKg: 'Průměrná bílkovina (kg)',
            avgLactationProteinPercentage: 'Průměrná bílkovina (proc.)',
            servicePeriod: 'Servisperioda',
            proficiencyTest: 'Zvíře je v KU',
            proficiencyTestDate: 'Datum poslední KU',
            motherMaxLactationMilkKg: 'Nádoj (kg) za maximální laktaci matky',
            motherMaxLactationProteinKg: 'Kg bílkovin za maximální laktaci matky',
            motherMaxLactationProteinPercentage: 'Procenta bílkovin za maximální laktaci matky',
            motherMaxLactationFatKg: 'Kg tuku za maximální laktaci matky',
        },
        comprehensiveBreedingFemaleBrowser: {
            pageTitle: 'Komplexní seznam plemenic',
            title: 'Komplexní seznam plemenic',
        },
        comprehensiveCalfBrowser: {
            pageTitle: 'Komplexní seznam telat',
            title: 'Komplexní seznam telat',
        },
        stableTable: {
            pageTitle: 'Prohlížeč plemenic',
            tableName: 'Hospodářství/stáje',
            export: 'Export hospodářství/stájí',
        },
        stableTableTooltip: {
            showCowsOverview: 'Zobrazit přehled krav',
            showCalvesOverview: 'Zobrazit přehled telat',
        },
        tableActions: {
            exportToCSV: 'Export do CSV',
            exportDisabled: 'Žádná data pro export',
            exportInProgress: 'Probíhá export',
        },
        calfBrowserTable: {
            tableName: 'Přehled telat v hospodářství',
            backToList: 'Zpět na seznam',
            femaleCalf: 'J',
            maleCalf: 'B',
            export: 'Export telat v hospodářství',
        },
        animalBrowserSagas: {
            getProductionAreasInCentralEvidenceSaga: 'Nepodařilo se načíst výrobní oblasti',
            getHuidHash: 'Načtení HUID hash',
            loadCabFemalesList: 'Načtení komplexního seznamu plemenic.',
            zeroCabFemalesMatchTheFilter: 'Filtru neodpovídají žádné plemenice.',
            cabFemalesFiltering: 'Filtrování plemenic.',
            loadCabCalvesList: 'Načítání komplexního seznamu telat.',
            zeroCabCalvesMatchTheFilter: 'Žádná telata neodpovídají filtru.',
            cabCalvesFiltering: 'Filtrování telat.',
            loadAnimalsList: 'Načtení skupiny zvířat',
            renameAnimalList: 'Přejmenování skupiny zvířat',
            animalListRenamed: 'Skupina zvířat úspěšně přejmenována',
            deleteAnimalList: 'Odstranění skupiny zvířat',
            animalListDeleted: 'Skupina zvířat byl úspěšně smazána',
            createAnimalList: 'Vytvoření skupiny zvířat',
            animalListCreated: 'Skupina zvířat úspěšně vytvořena',
            saveAnimalList: 'Uložení skupiny zvířat',
            breederListSuccess: 'Skupina plemenic úspěšně uložena',
            calvesListSuccess: 'Skupina telat úspěšně uložena',
            addAnimals: 'Přidat zvířata',
            animalsAdded: 'Zvířata byla úspěšně přidána.',
            addAnimalsFromFile: 'Přidat zvířata ze souboru',
            addAnimalsFromDb: 'Vybrání skupiny zvířat',
            animalsAddedFromDb: 'Skupina zvířat byla úspěšně vybrána.',
            deleteAnimals: 'Odstranění zvířat',
            animalsDeleted: 'Zvířata byla úspěšně smazána',
            motherLink: 'Načtení odkazu na matku',
        },
        comprehensiveBreedingFemaleForm: {
            loading: 'Načítání komplexního seznamu plemenic.',
        },
        comprehensiveOverview: {
            filter: 'Filtrovat',
            search: 'Vyhledat',
            reset: 'Obnovit',
            filterTitle: 'Nastavení filtru',
            addAnimals: 'Přidat zvířata',
            addAnimalsFromFile: 'Přidat zvířata ze souboru',
            selectList: 'Vybrat skupinu',
            selectAnimalList: 'Vyberte skupinu zvířat',
            saveList: 'Uložit skupinu',
            manageLists: 'Spravovat skupiny',
            deleteAnimals: 'Smazat zvířata',
            animalList: 'Skupina zvířat',
            noSavedLists: 'Nebyly uloženy žádné slupiny.',
            managementOfSavedLists: 'Správa uložených skupin',
            listAlreadyExists: 'Skupina se zvoleným názvem již existuje. Přejete si přepsat uloženou skupinu?',
            atLeastOneAnimal: 'Skupina zvířat musí obsahovat minimálně jedno zvíře.',
            areYouSure: 'Jste si jisti, že chcete opravdu smazat tuto skupinu?',
            breeder: 'Chovatel',
            district: 'Okres',
            farm: 'Hospodářství',
            organizationIns: 'Oprávněná organizace ‐ Inseminace',
            organizationPt: 'Oprávněná organizace ‐ KU',
            organizationCenterIns: 'Středisko ‐ Inseminace',
            organizationCenterPt: 'Středisko ‐ KU',
            stable: 'Stáj',
            organizationPtTooltip: 'Zadejte kód nebo obchodní jméno oprávněné organizace.',
            organizationInsTooltip: 'Zadejte kód nebo obchodní jméno oprávněné organizace.',
            organizationCenterPtTooltip: 'Zadejte kód střediska oprávněné organizace.',
            organizationCenterInsTooltip: 'Zadejte kód střediska oprávněné organizace.',
            districtTooltip: 'Zadejte třímístný kód, kód RÚIAN nebo název okresu.',
            breederTooltip: 'Zadejte registrační číslo nebo obchodní jméno chovatele.',
            farmTooltip: 'Zadejte kód nebo název hospodářství / registrační číslo nebo obchodní jméno chovatele.',
            stableTooltip: 'Zadejte kód nebo název stáje / registrační číslo nebo obchodní jméno chovatele.',
        },
        comprehensiveBreedingCalfForm: {
            loading: 'Načítání komplexního seznamu telat.',
        },
    },
    EN: {
        cowBrowserTable: {
            pageTitle: 'Breeder cows browser',
            tableName: 'Breeder cows in stable',
            alive: 'Alive',
            bodyStructureToMusculature: 'Bodybuilding / Muscle',
            breed: 'Breed',
            calveDate: 'Calv. dat.',
            changeCode: 'Z/V',
            currentLactationDays: 'Lact. Days (Prob.)',
            currentLactationFatKg: 'FatKg (prob.)',
            currentLactationFatPercentage: 'FatkPerc(prob.)',
            currentLactationLactoseKg: 'LaktKg (prob.)',
            currentLactationLactosePercentage: 'LaktPerc(prob.)',
            currentLactationMilkKg: 'Last lact.',
            currentLactationOrder: 'Ord. lact. (prob.)',
            currentLactationProteinKg: 'ProtKg (prob.)',
            currentLactationProteinPercentage: 'ProtPerc(prob.)',
            currentLactationSomaticCells: 'SC (prob.)',
            currentLactationMilkKgSum: 'Tot. milk (prob.)',
            currentLactationUrea: 'Curr. Ur. (prob.)',
            daysFromBirth: 'Days of life',
            birthDate: 'Dat. of birth',
            earTag: 'ET',
            exterior: 'EXT total',
            extremities: 'Extremities',
            avgLactationFatKg: 'FatKg (Ø)',
            avgLactationFatPercentage: 'FatProc (Ø)',
            fatherLineRegistry: 'Father',
            mothersFatherLineRegistry: 'OM_LinReg',
            mothersFatherName: 'OM_Name',
            fatherName: 'Father\'s Name',
            genotype: 'g',
            inseminationOrder: 'Insemination Order',
            avgLactationCount: 'Average of (Ø)',
            avgLactationDays: 'Lact. Days (Ø)',
            lastInbreedingAt: 'Last Inbreed.',
            lastLactationDate: 'End Date (ended)',
            lastLactationDays: 'Lact. Days (ended)',
            lastLactationFatKg: 'FatKg (ended)',
            lastLactationFatPercentage: 'FatPerc(ended)',
            lastLactationMilkKg: 'Milk (ended)',
            lastLactationOrder: 'Last lactation (ended)',
            lastLactationProteinKg: 'ProtKg (ended)',
            lastLactationProteinPercentage: 'ProtPerc(ended)',
            lifelongLactationFatKg: 'Lifelong_FatKg',
            lifelongLactationFatPercentage: 'Lifelong_FatProc',
            lifelongLactationDaysSum: 'Lifelong_AllDays',
            lifelongLactationMilkKgSum: 'Lifelong_MilkSum',
            lifelongLactationProteinKg: 'Lifelong_ProtKg',
            lifelongLactationProteinPercentage: 'Lifelong_ProtProc',
            lifelongStandardizedLactationDays: 'Lifelong_Eddays',
            lifelongLactationMilkKg: 'Lifelong_Milk',
            maxLactationFatKg: 'FatKg (max)',
            maxLactationFatPercentage: 'FatPerc (max)',
            maxLactationDays: 'Lact. Days (max)',
            maxLactationOrder: 'Lact order (max)',
            maxLactationMilkKg: 'Milk (max)',
            maxLactationProteinKg: 'ProtKg (max)',
            maxLactationProteinPercentage: 'ProtPerc(max)',
            avgLactationMilkKg: 'Milk (Ø)',
            milkKgAvgPerDay: 'Lifelong_Lact/dayOfLife',
            milkKgAvgPerLactationDay: 'Lifelong_Lact/dayOfLact.',
            milkStrengthToFrame: 'Milk Power (H) / Frame ©',
            motherEarTag: 'Mother',
            name: 'Name',
            pregnant: 'Pregnant',
            avgLactationProteinKg: 'ProtKg (Ø)',
            avgLactationProteinPercentage: 'ProtPerc(Ø)',
            selectionIndex: 'SI',
            servicePeriod: 'SP',
            studbookSection: 'PK',
            udder: 'Udder',
            proficiencyTest: 'KC',
            proficiencyTestDate: 'Last contr.',
            motherMaxLactationFatPercentage: 'FatPerc (max)',
            motherFirstLactationDays: 'Lac. days (L1)',
            motherFirstLactationMilkKg: 'Milk (L1)',
            motherFirstLactationProteinKg: 'ProKg (L1)',
            motherFirstLactationProteinPercentage: 'ProPerc(L1)',
            motherFirstLactationFatKg: 'FatKg (L1)',
            motherFirstLactationFatPercentage: 'FatPerc (L1)',
            motherLifelongLactationMilkKg: 'LFL-MilkKg',
            motherExterior: 'Ext',
            motherMilkStrengthToFrame: 'Milk strength (H) / Frame (C)',
            motherBodyStructureToMusculature: 'Body struct. / Musculature',
            motherExtremities: 'Extremities',
            motherUdder: 'Udder',
            stableCode: 'Stable',
            subjectBusinessName: 'Subject',
            sex: 'Sex',
            firstInbreedingAt: 'Dat.1.inb.',
            firstPregnancyAt: 'Dat.preg.',
            inbreedingBullLineRegistry: 'Inbr. Bull',
            inbreedingBullEarTag: 'Bull ET',
            inbreedingBullName: 'Bull name',
            fatherEarTag: 'Father ET',
            genotypeIndex: 'Genotype index',
            fatherSelectionIndex: 'Father SI',
            motherSelectionIndex: 'Mother SI',
            motherName: 'Mother name',
            motherStandardizedLactationCount: 'St. lact. count',
            motherAvgLactationDays: 'Lact. days (Ø)',
            motherAvgLactationMilkKg: 'Milk (Ø)',
            motherMaxLactationOrder: 'Mother max. ord. L',
            motherMaxLactationMilkKg: 'Milk (max)',
            motherMaxLactationProteinKg: 'ProKg (max)',
            motherMaxLactationProteinPercentage: 'ProProc (max)',
            motherMaxLactationFatKg: 'FatKg (max)',
            export: 'Export cows in stable',
            lastMilkYield: 'Last milk yield',
        },
        cowBrowserTableTooltip: {
            alive: 'Animal condition (live / non-live)',
            bodyStructureToMusculature: 'H - body structure / C - musculature',
            calveDate: 'Date of last calving',
            currentLactationDays: 'Number of days of prob. lact.',
            currentLactationFatKg: 'Kg fat of prob. lakt.',
            currentLactationFatPercentage: 'Percent of fat of prob. lact.',
            currentLactationLactoseKg: 'Kg lactose of prob. lactation',
            currentLactationLactosePercentage: 'Percent lactose of prob. lactation.',
            currentLactationMilkKg: 'Lactation from last UC check',
            currentLactationOrder: 'Lactation order',
            currentLactationProteinKg: 'Kg of protein of prob. lact.',
            currentLactationProteinPercentage: 'Percentage of protein of prob. lact.',
            currentLactationSomaticCells: 'Number of somatic cells of prob. lact.',
            currentLactationMilkKgSum: 'Sum of milk of pob. lactation',
            currentLactationUrea: 'Urea',
            daysFromBirth: 'Life days',
            exterior: 'Aggregate mark for exterior',
            extremities: 'Extremities',
            avgLactationFatKg: 'Average fat (kg)',
            avgLactationFatPercentage: 'FatProc (Ø)',
            mothersFatherLineRegistry: 'Mother\'s father\'s register',
            mothersFatherName: 'Father\'s father\'s name',
            inseminationOrder: 'Insemination order',
            avgLactationCount: 'Number of lactations per average',
            avgLactationDays: 'Average lactation days',
            lastInbreedingAt: 'Date of last immersion',
            lastLactationDate: 'Date of last ended lactation',
            lastLactationDays: 'Number of days (last ended lact.)',
            lastLactationFatKg: 'Kg of fat (last ended lact.)',
            lastLactationFatPercentage: 'Percentage of fat (last ended lactation)',
            lastLactationMilkKg: 'Milk for norm. lactation (last ended lactation)',
            lastLactationOrder: 'Last ended lactation / n',
            lastLactationProteinKg: 'Kg of protein (last ended lact.)',
            lastLactationProteinPercentage: 'Percentage of protein (last ended lact.)',
            lifelongLactationFatKg: 'Lifetime - total fat (kg)',
            lifelongLactationFatPercentage: 'Lifetime fat percentage',
            lifelongLactationDaysSum: 'Lifelong - sum of days of lactation',
            lifelongLactationMilkKgSum: 'Lifetime - total sum of milk',
            lifelongLactationProteinKg: 'Lifetime - total protein (kg)',
            lifelongLactationProteinPercentage: 'Lifetime percentage of protein',
            lifelongStandardizedLactationDays: 'Lifelong - sum of days of standardized lactations',
            lifelongLactationMilkKg: 'Lifetime - total amount of milk during normal lactation',
            maxLactationFatKg: 'Kg fat of max. lactation',
            maxLactationFatPercentage: 'Percentage of fat of max. lactation',
            maxLactationDays: 'Total days of max. lactation',
            maxLactationOrder: 'Max Lactation Order',
            maxLactationMilkKg: 'Sum of milk (kg) of max. lactation',
            maxLactationProteinKg: 'Kg of protein of max. lactation',
            maxLactationProteinPercentage: 'Percentage of protein of max. lactation',
            avgLactationMilkKg: 'Average milk (kg)',
            milkKgAvgPerDay: 'Average sum of milk per day of life',
            milkKgAvgPerLactationDay: 'Average sum of milk of lactation day',
            milkStrengthToFrame: 'H - Milk force / C - Body frame',
            avgLactationProteinKg: 'Average protein (kg)',
            avgLactationProteinPercentage: 'Average protein (perc.)',
            servicePeriod: 'Service period',
            proficiencyTest: 'Animal is in UC',
            proficiencyTestDate: 'Date of last UC',
            motherMaxLactationMilkKg: 'Mother max lactation milk kg',
            motherMaxLactationProteinKg: 'Mother max lactation protein kg',
            motherMaxLactationProteinPercentage: 'Mother max lactation protein percentage',
            motherMaxLactationFatKg: 'Mother max lactation fat kg',
        },
        comprehensiveBreedingFemaleBrowser: {
            pageTitle: 'Comprehensive breeding female browser',
            title: 'Comprehensive breeding female browser',
        },
        comprehensiveCalfBrowser: {
            pageTitle: 'Comprehensive calf browser',
            title: 'Comprehensive calf browser',
        },
        stableTable: {
            pageTitle: 'Breeder cows browser',
            tableName: 'Stables',
            export: 'Stables export',
        },
        stableTableTooltip: {
            showCowsOverview: 'Show cows overview',
            showCalvesOverview: 'Show calves overview',
        },
        tableActions: {
            exportToCSV: 'Export to CSV',
            exportDisabled: 'No data to export',
            exportInProgress: 'Export in progress',
        },
        calfBrowserTable: {
            tableName: 'Calves in farm',
            backToList: 'Back to list',
            femaleCalf: 'F',
            maleCalf: 'M',
            export: 'Export calves in farm',
        },
        animalBrowserSagas: {
            getProductionAreasInCentralEvidenceSaga: 'Failed to load production areas',
            getHuidHash: 'Loading of HUID hash',
            loadCabFemalesList: 'Loading complex list of breeding cows.',
            zeroCabFemalesMatchTheFilter: 'Zero breeding cows match the filter.',
            cabFemalesFiltering: 'Filter breeding cows.',
            loadCabCalvesList: 'Loading complex list of calves.',
            zeroCabCalvesMatchTheFilter: 'Zero calves match the filter.',
            cabCalvesFiltering: 'Filtering calves.',
            loadAnimalsList: 'Load animals groups',
            renameAnimalList: 'Rename animal group',
            animalListRenamed: 'Animal group renamed successfully',
            deleteAnimalList: 'Delete animal group',
            animalListDeleted: 'Animal group was successfully deleted',
            createAnimalList: 'Create animal group',
            animalListCreated: 'Animals group was created successfully',
            saveAnimalList: 'Save animal group',
            breederListSuccess: 'List of breeding cows saved successfully',
            calvesListSuccess: 'Group of calves saved successfully',
            addAnimals: 'Add animals',
            animalsAdded: 'Animals were successfully added.',
            addAnimalsFromFile: 'Add animals from file',
            addAnimalsFromDb: 'Select animal group',
            animalsAddedFromDb: 'Animal group was successfully selected.',
            deleteAnimals: 'Delete animals',
            animalsDeleted: 'Animals were successfully deleted',
            motherLink: 'Load of mother link',
        },
        comprehensiveBreedingFemaleForm: {
            loading: 'Load complex female breeding list.',
        },
        comprehensiveOverview: {
            filter: 'Filter',
            search: 'Search',
            reset: 'Reset',
            filterTitle: 'Filter settings',
            addAnimals: 'Add animals',
            addAnimalsFromFile: 'Add animals from file',
            selectList: 'Select group',
            selectAnimalList: 'Select animal group',
            saveList: 'Save group',
            manageLists: 'Manage groups',
            deleteAnimals: 'Delete animals',
            animalList: 'Animal group',
            noSavedLists: 'No groups were saved.',
            managementOfSavedLists: 'Management of saved groups',
            listAlreadyExists: 'A group with the name you entered already exists. Do you wish to overwrite the saved group?',
            areYouSure: 'Are you sure you want to delete this group?',
            atLeastOneAnimal: 'Animal group has to contain at least one animal.',
            breeder: 'Breeder',
            district: 'District',
            farm: 'Farm',
            organizationIns: 'Authorized organization ‐ Insemination',
            organizationPt: 'Authorized organization ‐ PT',
            organizationCenterIns: 'Organization center ‐ Insemination',
            organizationCenterPt: 'Organization center ‐ PT',
            stable: 'Stable',
            organizationPtTooltip: 'Enter the code or business name of the authorized organization.',
            organizationInsTooltip: 'Enter the unit code of the authorized organization.',
            organizationCenterPtTooltip: 'Enter the code or business name of the authorized organization.',
            organizationCenterInsTooltip: 'Enter the unit code of the authorized organization.',
            districtTooltip: 'Enter the three-digit code, the RÚIAN code or the district name.',
            breederTooltip: 'Enter the breeder\'s registration number or business name.',
            farmTooltip: 'Enter the code or name of the farm / registration number or business name of the breeder.',
            stableTooltip: 'Enter the code or name of the stable / registration number or business name of the breeder.',
        },
        comprehensiveBreedingCalfForm: {
            loading: 'Load complex calf list.',
        },
    },
};
