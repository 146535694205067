import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {initialSystemState, SystemState} from './state';

const systemStateO = O.optic<SystemState>();

export const systemReducer = (state: SystemState = initialSystemState, action: AppAction): SystemState => {
    switch (action.type) {
        case 'system/CHANGE_REFRESH_MODAL_VISIBILITY': {
            const {visible} = action.payload;

            return O.set(systemStateO.prop('refreshModalVisible'))(visible)(state);
        }

        case 'system/CHANGE_SERVER_ERROR': {
            const {error} = action.payload;

            return O.set(systemStateO.prop('serverError'))(error)(state);
        }

        default:
            return state;
    }
};
