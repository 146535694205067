import {putAll, showBeError, takeEveryF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {GetAnimalsCountAction, animalBrowserAction} from '../action';

const tt = t('animalBrowserSagas');

function* execute({payload: {animalType}}: GetAnimalsCountAction): SagaIterator {
    const response = yield* call(Api.getCabAnimalSessionStatus, {animalType});

    if (response.isSuccess) {
        const {areAnimalsInSession} = response.data;

        yield* animalType === 'BREEDING_FEMALES'
            ? put(animalBrowserAction.setCabFemalesExistInSession(areAnimalsInSession))
            : put(animalBrowserAction.setCabCalvesExistInSession(areAnimalsInSession));
    } else {
        yield putAll(showBeError(response, tt('loadAnimalsList')));
    }
}

export function* getAnimalsCountSaga(): SagaIterator {
    yield takeEveryF('animalBrowser/GET_ANIMALS_COUNT', execute);
}
