import {Options, TableColumn} from '@fl/cmsch-fe-library';

import {DataSource} from 'api/gen/DataSource';
import {Milkability} from 'api/gen/Milkability';

import {formatMilkingTime} from '../../utils/format-milking-time';

const dataSourceOptions: Options<DataSource> = [
    {label: 'AUTO', value: 'AUTO'},
    {label: 'INI', value: 'INI'},
    {label: 'FORM', value: 'FORM'},
];

export const milkabilityOverviewColumns: Array<TableColumn<Milkability>> = [
    {
        field: 'examinationDate',
        type: 'date',
        dateFormat: 'date',
    },
    {
        field: 'earTag',
        type: 'string',
        filterTypes: ['betweenEarTags'],
    },
    {
        field: 'breederName',
        type: 'string',
    },
    {
        field: 'stableCode',
        type: 'string',
    },
    {
        field: 'stableName',
        type: 'string',
    },
    {
        field: 'organizationName',
        type: 'string',
    },
    {
        field: 'technicianNumber',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'breedAlpha6',
        type: 'string',
    },
    {
        field: 'fatherLineRegistry',
        type: 'string',
        filterTypes: ['null'],
    },
    {
        field: 'lactationOrder',
        type: 'number',
    },
    {
        field: 'calveDate',
        type: 'date',
        dateFormat: 'date',
    },
    {
        field: 'daysBetweenExamAndCalving',
        type: 'number',
    },
    {
        field: 'apmv',
        type: 'decimal',
        fixedDecimals: 2,
    },
    {
        field: 'milkingTime',
        type: 'number',
        filterTypes: ['equalsTime', 'betweenTimes'],
        customFiltersOnly: true,
        formatValue: formatMilkingTime,
    },
    {
        field: 'dataSource',
        type: 'option',
        options: dataSourceOptions,
    },
    {
        field: 'lastChangedBy',
        type: 'string',
    },
    {
        field: 'updated',
        type: 'date',
        dateFormat: 'datetime',
    },
    {
        field: 'milkedTotal',
        type: 'decimal',
        fixedDecimals: 2,
    },
    {
        field: 'subsidyClaim',
        type: 'boolean',
        filterTypes: ['boolean'],
    },
];
