import {Nullable} from '@fl/cmsch-fe-library';

export const createCriteriaListFormName = 'createCriteriaList';

export interface CreateCriteriaListFormValues {
    name: Nullable<string>;
}

export const initialCreateCriteriaListFormValues: CreateCriteriaListFormValues = {
    name: null,
};
