import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {GetAnimalListsAction, animalBrowserAction} from '../action';
import {getCabTableInfo} from './get-table-info';

function* execute({payload: {animalType, withoutLoading}}: GetAnimalListsAction): SagaIterator {
    const {code, module} = getCabTableInfo(animalType);

    if (!withoutLoading) yield* put(animalBrowserAction.setLoading('getAnimalLists', true));

    const response = yield* call(Api.getAnimalLists, {code, module});

    if (response.isSuccess) {
        yield* put(animalBrowserAction.getAnimalListsSuccess(response.data, animalType));
    } else {
        yield putAll(showBeError(response, t('animalBrowserSagas')('loadAnimalsList')));
    }

    if (!withoutLoading) yield* put(animalBrowserAction.setLoading('getAnimalLists', false));
}

export function* getAnimalListsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_ANIMAL_LISTS', execute);
}
