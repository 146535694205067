import {ActionSettings, Options, TableDataRequest, ToolbarButton, debounceTime} from '@fl/cmsch-fe-library';
import {debounce, isEmpty} from 'lodash/fp';
import {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {ContactType} from 'api/gen/ContactType';
import {PagedModelUserProfileContact} from 'api/gen/PagedModelUserProfileContact';
import {State} from 'app/setup';
import {TFunction, useOurTranslation} from 'app/translations';

import {defaultProps} from '../constants/consts';
import {userDetailAction} from '../model/action';
import {simpleUserDetailSelector} from '../model/selector';
import {ContactsTabProps} from '../types/contacts-tab-props';
import {CreateOrEditOrNull} from '../types/create-or-edit-or-null';
import {UserContactFormValues} from '../types/user-contact-form-values';
import {UserContactsTableType} from '../types/user-contacts-table-type';

const getUserContactOptions = (t: TFunction<'common'>): Options<ContactType> => [
    {label: t('fax'), value: 'FAX'},
    {label: t('email'), value: 'E_MAIL'},
    {label: t('phone'), value: 'PHONE'},
];

const getFormDataForInit = (
    rowForEdit: UserContactsTableType,
    userProfileId: number,
): UserContactFormValues => ({
    userProfileId,
    contactType: rowForEdit.contactType,
    value: rowForEdit.value,
    userContactId: rowForEdit.userContactId,
});

interface UseContactTabSetup {
    userProfileId: number;
    visibleModal: boolean;
    contactOptions: Options<ContactType>;
    formDataForInit: UserContactFormValues | null;
    headerButtons: Array<ToolbarButton>;
    actionSettings: ActionSettings<UserContactsTableType>;
    actionMode: CreateOrEditOrNull;
    pathnamesForRefresh: Array<RegExp>;
    isFromAdminModule: boolean;
    t: TFunction<'userDetail'>;
    tCommon: TFunction<'common'>;
    getContacts(tableDataRequest: Opt<TableDataRequest<UserContactsTableType>>): void;
    handleSubmitModal(): void;
    handleOpenCreateModal(): void;
    handleCloseModal(): void;
    validateDebounced(): void;
    contactsSelector(state: State): Opt<PagedModelUserProfileContact>;
}

// eslint-disable-next-line max-lines-per-function
export const useContactTabSetup = ({
    pathnamesForRefresh,
    adminProps,
    getTableData,
    selector,
}: ContactsTabProps): UseContactTabSetup => {
    const dispatch = useDispatch();
    const {t, tCommon} = useOurTranslation('userDetail');
    const [actionMode, setActionMode] = useState<CreateOrEditOrNull>(null);
    const [rowForEdit, setRowForEdit] = useState<UserContactsTableType | null>(null);
    const visibleModal = useSelector(simpleUserDetailSelector.userContactsModalVisible);

    const isFromAdminModule = !isEmpty(adminProps);

    const {
        canUpdate,
        userProfileId,
        createEntry,
        updateEntry,
        deleteEntry,
    } = isFromAdminModule ? adminProps : defaultProps;

    const formDataForInit = useMemo(() => {
        if (rowForEdit && userProfileId) {
            return getFormDataForInit(rowForEdit, userProfileId);
        }
        return null;
    }, [rowForEdit, userProfileId]);

    const contactOptions = useMemo(() => getUserContactOptions(tCommon), [tCommon]);

    const handleOpenEditContact = useCallback((_rowId: number, row: UserContactsTableType) => {
        setActionMode('edit');
        setRowForEdit(row);
        dispatch(userDetailAction.setUserContactsModalVisibility(true));
    }, [dispatch]);

    const handleOpenCreateModal = useCallback(() => {
        setActionMode('create');
        setRowForEdit(null);
        dispatch(userDetailAction.setUserContactsModalVisibility(true));
    }, [dispatch]);

    const handleSubmitModal = useCallback(() => actionMode === 'edit' ? updateEntry?.() : createEntry?.(),
        [actionMode, createEntry, updateEntry]);

    const handleCloseModal = useCallback(() => {
        dispatch(userDetailAction.setUserContactsModalVisibility(false));
        setActionMode(null);
    }, [dispatch]);

    const handleDeleteEntry = useCallback((rowId: number) => {
        deleteEntry?.(userProfileId, rowId);
    }, [deleteEntry, userProfileId]);

    const validateData = useCallback(() => {
        dispatch(userDetailAction.validateUserContact());
    }, [dispatch]);

    const validateDebounced = useMemo(() => debounce(debounceTime)(validateData)
        , [validateData]);

    const headerButtons: Array<ToolbarButton> = useMemo(() => isFromAdminModule ? [
        {
            id: 'createContact',
            icon: 'plusOutlined',
            onClick: handleOpenCreateModal,
            title: t('contact'),
            visuals: 'primary',
            hidden: !canUpdate,
        },
    ] : [], [handleOpenCreateModal, t, canUpdate, isFromAdminModule]);

    const actionSettings: ActionSettings<UserContactsTableType> = useMemo(() => canUpdate ? ({
        extraActions: [
            {
                id: 'delete',
                role: 'delete',
                callback: handleDeleteEntry,
                hidden: !canUpdate,
            },
            {
                id: 'edit',
                role: 'edit',
                callback: handleOpenEditContact,
                hidden: !canUpdate,
            },
        ],
    }) : {}, [handleDeleteEntry, handleOpenEditContact, canUpdate]);

    return {
        actionMode,
        formDataForInit,
        visibleModal,
        handleOpenCreateModal,
        handleSubmitModal,
        handleCloseModal,
        headerButtons,
        actionSettings,
        contactOptions,
        contactsSelector: selector,
        getContacts: getTableData,
        isFromAdminModule,
        pathnamesForRefresh,
        t,
        tCommon,
        userProfileId,
        validateDebounced,
    };
};
