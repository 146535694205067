import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {createOrUpdatePermissionFormName} from '../../types/permission-form-values';
import {getPermissionFormValues, buildPermissionUpdateValuesForApi} from '../../utils/permission';
import {adminAction, UpdatePermissionAction} from '../action';

const te = t('admin/sagas');

function* execute({payload: {permissionId}}: UpdatePermissionAction): SagaIterator {
    const permissionFormValues = yield* select(getPermissionFormValues);
    yield* put(formHelpers.startSubmit(createOrUpdatePermissionFormName));

    const response = yield* call(
        Api.updatePermission,
        buildPermissionUpdateValuesForApi(permissionFormValues),
        {permissionId},
    );

    const title = te('editPermission');

    if (response.isSuccess) {
        yield* put(adminAction.getPermissionDetails(opt({action: 'refresh'})));
        yield* put(showSuccess(title, te('permissionEdited')));
        yield* put(adminAction.setEditedPermissionId(null));
        yield* put(formHelpers.reset(createOrUpdatePermissionFormName));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(createOrUpdatePermissionFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* updatePermissionSaga(): SagaIterator {
    yield takeLatestF('admin/UPDATE_PERMISSION', execute);
}
