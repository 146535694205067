import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {AddAnimalsAction, animalBrowserAction} from '../action';
import {updateAnimalSessionAfterAddition} from './update-animals-session';

const te = t('animalBrowserSagas');

function* execute({payload: {animalType, animalIds}}: AddAnimalsAction): SagaIterator {
    yield* put(formHelpers.startSubmit('addAnimals'));

    const response = yield* call(Api.cabAddAnimalsToList, {animalIds}, {animalType});

    const title = te('addAnimals');

    if (response.isSuccess) {
        yield* put(showSuccess(title, te('animalsAdded'), false, 'add-animals'));
        yield* put(animalBrowserAction.toggleAddAnimalsModal());
        yield* put(formHelpers.stopSubmit('addAnimals'));
        yield* call(updateAnimalSessionAfterAddition, animalType, animalIds.length);
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit('addAnimals', extractFormErrorsFromResponse(response)));
    }
}

export function* addAnimalsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/ADD_ANIMALS', execute);
}
