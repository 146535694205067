import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse, NullableKeys} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {UpdateUserProfileOtherSubjectRole} from 'api/gen/UpdateUserProfileOtherSubjectRole';
import {t} from 'app/translations';
import {userDetailAction} from 'app/user-detail';
import {subjectFormName, SubjectFormValues} from 'app/user-detail/types/subject-form-values';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {adminAction, UpdateOtherSubjectsAction} from '../action';

const te = t('common');
const title = te('updateEntry');

const buildValuesForApi =
    ({
        approvedTo,
        userProfileAssociationRoleIds,
    }: NullableKeys<SubjectFormValues>): UpdateUserProfileOtherSubjectRole => ({
        approvedTo: opt(approvedTo).orNull(),
        userProfileOtherSubjectRoleIds: opt(userProfileAssociationRoleIds)
            .orCrash('No userProfileAssociationRoleIds found'),
    });

function* execute(_: UpdateOtherSubjectsAction): SagaIterator {
    const formValues = yield* select(state => formHelpers.formValues(subjectFormName)(state)
        .orCrash('no  form values'));
    yield* put(formHelpers.startSubmit(subjectFormName));

    const response = yield* call(Api.updateUserProfileOtherSubjectRoleBindings, buildValuesForApi(formValues));

    if (response.isSuccess) {
        const userProfileId = opt(formValues.userProfileId).orCrash('No userProfileId found');

        yield* put(adminAction.getOtherSubjectRoles(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('updateEntrySuccess'), false, 'updateOtherSubject'));
        yield* put(userDetailAction.setAssociationsModalVisible(null));
        yield* put(formHelpers.reset(subjectFormName));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(subjectFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* updateOtherSubjectsSaga(): SagaIterator {
    yield takeLatestF('admin/UPDATE_OTHER_SUBJECTS', execute);
}
