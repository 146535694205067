import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {uniqBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {simpleAnimalBrowserSelector} from 'app/animal-browser/model/selector';
import {cabFilterFormName} from 'app/animal-browser/types/cab-filter-form-values';
import {genOrganizationOptions} from 'app/animal-browser/utils/gen-options';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {animalBrowserAction, GetOrganizationInsOptionsAction} from '../action';

const tt = t('comprehensiveOverview');

function* execute({payload}: GetOrganizationInsOptionsAction): SagaIterator {
    const selectedValues = (yield* select(formHelpers.formValues(cabFilterFormName)))
        .prop('organizationInsIds').orElse([]);
    const oldOptions = (yield* select(simpleAnimalBrowserSelector.organizationsInsOptions)).orElse([]);
    const selectedOptions = oldOptions.filter(option => selectedValues.includes(option.value));

    yield* put(animalBrowserAction.setLoading('organizationInsSearch', true));

    const response = yield* call(
        Api.getCabOrganizationsWithInseminationMaintenanceAutocompleteList,
        {text: payload},
    );
    if (response.isSuccess) {
        yield* put(animalBrowserAction.getOrganizationInsOptionsSuccess(
            uniqBy('value', [...selectedOptions, ...genOrganizationOptions(response.data)]),
        ));
    } else {
        yield putAll(showBeError(response, tt('filter')));
    }

    yield* put(animalBrowserAction.setLoading('organizationInsSearch', false));
}
export function* getOrganizationInsOptionsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_ORGANIZATION_INS_OPTIONS', execute);
}
