import {downloadFileFromResponse, takeLatestF, showBeError, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {getRequestedTableCsvExportParams, simpleTableSelector} from 'utils/tables';

import {animalBrowserAction, GetStablesCsvAction} from '../action';

function* execute(_action: GetStablesCsvAction): SagaIterator {
    yield* put(animalBrowserAction.startExport());
    const tableState = opt(yield* select(simpleTableSelector.tableState('stableToChoose'))).orCrash('no tableState');

    const {fieldParams, sort} = getRequestedTableCsvExportParams(tableState);

    const response = yield* call(Api.exportStables, fieldParams, {sort});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('stableTable')('export')));
    }
    yield* put(animalBrowserAction.exportFinished());
}

export function* getStablesCsvSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_STABLES_CSV', execute);
}
