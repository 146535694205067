// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-legacy-screens-___styles__legacyIframe___F87Rh{width:100%;flex:auto;border:0}
`, "",{"version":3,"sources":["webpack://./src/app/legacy/screens/styles.sass"],"names":[],"mappings":"AAAA,wDAAc,UAAA,CAAW,SAAA,CAAU,QAAA","sourcesContent":[".legacyIframe{width:100%;flex:auto;border:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legacyIframe": `src-app-legacy-screens-___styles__legacyIframe___F87Rh`
};
export default ___CSS_LOADER_EXPORT___;
