import {CabBreedingFemale} from 'api/gen/CabBreedingFemale';
import {CabCalf} from 'api/gen/CabCalf';

type Animal = Partial<
  Pick<CabCalf & CabBreedingFemale,
    | 'inbreedingBullLineRegistry'
    | 'fatherLineRegistry'
    | 'fatherEarTag'
    | 'inbreedingBullEarTag'
    | 'mothersFatherLineRegistry'
    | 'earTag'
  >
>;
export const getBullBrowserLink = (key: keyof Animal) => (row: Animal): string => {
    const value = row[key];

    return `/ProhlizecZvirat/Index/${value ?? ''}`;
};
