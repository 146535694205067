export const modalsTranslations = {
    CS: {
        animalListModals: {
            name: 'Název',
            created: 'Vytvořeno',
            animalLists: 'Seznamy zvířat',
            criteriaLists: 'Seznamy kritérií',
            actionCannotBeReverted: 'Tuto akci nelze zvrátit!',
            newName: 'Nové jméno:',
            errorMessageEmpty: 'Vyplňte jméno.',
            confirmListName: 'Potvrďte jméno',
            enterListName: 'Zadejte jméno',
            saveToDb: 'Do databáze',
            saveToFile: 'Do souboru',
            listAlreadyExistsWarning: 'Uložením nového seznamu přepíšete původní',
        },
    },
    EN: {
        animalListModals: {
            name: 'Name',
            created: 'Created',
            animalLists: 'Animal lists',
            criteriaLists: 'Criteria lists',
            actionCannotBeReverted: 'This action cannot be reverted.',
            newName: 'New name:',
            errorMessageEmpty: 'Enter name.',
            confirmListName: 'Confirm name',
            enterListName: 'Enter name',
            saveToDb: 'To database',
            saveToFile: 'To file',
            listAlreadyExistsWarning: 'Saving the new list will overwrite the existing one',
        },
    },
};
