import { FooterButtons, Options } from '@fl/cmsch-fe-library';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useOurTranslation } from 'app/translations';
import { useForm } from 'utils/forms';
import { Warnings } from 'utils/validator';
import { createCriteriaListFormName, CreateCriteriaListFormValues, initialCreateCriteriaListFormValues } from './create-criteria-list-form-values';
import { CreateCriteriaListProps } from './create-criteria-list-props';
import { validate } from './validate';
const CreateCriteriaListFormBase: FC<CreateCriteriaListProps> = props => {
  const {
    onSubmit,
    onCancel,
    criteriaLists,
    tableSettingsNameList
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('mating/modalListsOverview');
  const handleSubmit = useCallback(({
    name
  }: CreateCriteriaListFormValues) => {
    if (name) onSubmit(name);
  }, [onSubmit]);
  const listsOptions: Options<string> = useMemo(() => criteriaLists.filterIn(x => x.editable).mapIn(y => ({
    value: y.name,
    label: y.name
  })).orElse([]), [criteriaLists]);
  const validateWarnings = useCallback(({
    name
  }: CreateCriteriaListFormValues): Warnings<CreateCriteriaListFormValues> => {
    const parametersNameMatch = listsOptions.some(x => x.value === name);
    // eslint because with include TS cant generate types correctly
    // eslint-disable-next-line unicorn/prefer-includes
    const tableSettingsNameMatch = tableSettingsNameList.some(x => x === name);
    if (parametersNameMatch) return {
      name: t('listAlreadyExistsWarning')
    };
    if (tableSettingsNameMatch) return {
      name: t('nameAlreadyExistsInTableWarning')
    };
    return {};
  }, [listsOptions, t, tableSettingsNameList]);
  const {
    Form,
    Fields,
    submitting,
    valid
  } = useForm({
    form: createCriteriaListFormName,
    initialValues: initialCreateCriteriaListFormValues,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate,
    onSubmit: handleSubmit,
    warn: validateWarnings
  });
  return <Form data-sentry-element="Form" data-sentry-component="CreateCriteriaListFormBase" data-sentry-source-file="form.tsx">
            <Fields.StringSelect name="name" label={t('name')} options={listsOptions} disabled={submitting} autoFocus includeSearchValueInOptions includeValueToOptions touchOnChange data-sentry-element="unknown" data-sentry-source-file="form.tsx" />
            <FooterButtons submitting={submitting} submitTitle={tCommon('save')} submitTooltip={valid ? '' : t('paramsSubmitTooltip')} disabledSubmit={!valid} onCancel={onCancel} data-sentry-element="FooterButtons" data-sentry-source-file="form.tsx" />
        </Form>;
};
export const CreateCriteriaListForm = memo(CreateCriteriaListFormBase);