import { LoaderWrapper, classNames, drop } from '@fl/cmsch-fe-library';
import { iframeResizer } from 'iframe-resizer';
import { maxBy, filter } from 'lodash/fp';
import React, { ComponentType, createRef, PureComponent, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose, Dispatch } from 'redux';
import { flow, Opt, opt } from 'ts-opt';
import { Language } from 'api/gen/Language';
import { Config } from 'app/config';
import { Content } from 'app/content';
import { getGTagIds } from 'app/gtag';
import { legacyPaths, LegacyPath } from 'app/navigation';
import { logger } from 'app/sentry-logger';
import { State } from 'app/setup';
import { WithTranslation } from 'app/translations/with-translation';
import { legacyAction } from '../model';
import { genGTagMessageForLegacyBe } from '../utils/gen-g-tag-message-for-legacy-be';
import styles from './styles.sass';
type RouterProps = Pick<RouteComponentProps, 'location'>;
interface StateProps {
  iframeLoading: boolean;
  statisticalCookieEnabled: boolean;
  language: Language;
}
interface DispatchProps {
  resetScreen(): void;
  setIframeLoading(value: boolean): void;
}
type Props = RouterProps & WithTranslation<'common/urlTitles'> & StateProps & DispatchProps;
const iframeLoadedTimeout = 200;
export const getLongestMatchingLegacyPath: (pathname: string) => (legacyPaths: ReadonlyArray<LegacyPath>) => Opt<LegacyPath> = pathname => flow(filter((x: LegacyPath) => pathname.startsWith(x.path)), maxBy((x: LegacyPath) => x.path.length), opt);
interface ProcessPathInput {
  pathname: string;
  search: string;
  legacyPathsArray: ReadonlyArray<LegacyPath>;
}
export const processLegacyPath = ({
  pathname,
  search,
  legacyPathsArray
}: ProcessPathInput): string => {
  const longestMatchingLegacyPath = getLongestMatchingLegacyPath(pathname)(legacyPathsArray).orCrash('legacy path for iframe not found');
  const restOfPathname = drop(longestMatchingLegacyPath.path.length)(pathname);
  const {
    legacyPath
  } = longestMatchingLegacyPath;
  const completeLegacyPath = `${Config.legacyEskotUrl}${legacyPath}${restOfPathname}${search}`;
  return completeLegacyPath;
};
class LegacyIframeBase extends PureComponent<Props> {
  private readonly iframeRef = createRef<HTMLIFrameElement>();
  public render(): ReactNode {
    const {
      iframeLoading,
      location: {
        pathname,
        search
      },
      t
    } = this.props;
    const completeLegacyPath = processLegacyPath({
      pathname,
      search,
      legacyPathsArray: legacyPaths
    });
    return <Content havePadding noFooter={iframeLoading} flex="column"
    // @ts-expect-error its based on iframe URLs
    browserTabTitle={t(pathname)} data-sentry-element="Content" data-sentry-component="LegacyIframeBase" data-sentry-source-file="legacy-iframe.tsx">
                {iframeLoading && <LoaderWrapper loading stretch size="large" text={`${t('loading')}`} />}
                <iframe ref={this.iframeRef} src={completeLegacyPath} className={classNames(styles.legacyIframe, iframeLoading && 'invisible')} onLoad={this.handleIframeLoaded} />
            </Content>;
  }
  public componentDidMount(): void {
    const {
      resetScreen
    } = this.props;
    resetScreen();
  }
  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const {
      setIframeLoading,
      location: {
        pathname
      },
      language,
      statisticalCookieEnabled
    } = this.props;
    const {
      location: {
        pathname: prevPathname
      },
      language: prevLanguage,
      statisticalCookieEnabled: prevStatisticalCookieEnabled
    } = prevProps;
    if (pathname !== prevPathname) {
      setIframeLoading(true);
    }
    if (language !== prevLanguage || statisticalCookieEnabled && !prevStatisticalCookieEnabled) {
      setIframeLoading(true);
      if (this.iframeRef.current) {
        // eslint-disable-next-line no-self-assign
        this.iframeRef.current.src = this.iframeRef.current.src; // causes reload with new locale
      }
    }
  }
  private readonly handleIframeLoaded = (): void => {
    const {
      setIframeLoading,
      statisticalCookieEnabled
    } = this.props;
    setTimeout(() => setIframeLoading(false), iframeLoadedTimeout);
    if (this.iframeRef.current) {
      // @ts-expect-error iframeResizer is not typed correctly
      iframeResizer({
        warningTimeout: 0
      }, this.iframeRef.current);
    }
    if (statisticalCookieEnabled) {
      getGTagIds().then(gTagIds => {
        if (gTagIds.isSome() && this.iframeRef.current?.contentWindow) {
          this.iframeRef.current.contentWindow.postMessage(genGTagMessageForLegacyBe(gTagIds.value), Config.legacyEskotUrl);
        }
      })
      // eslint-disable-next-line @fl/use-eta-reduction
      .catch(error => logger.logError(error));
    }
  };
}
const mapStateToProps = (state: State): StateProps => ({
  iframeLoading: state.legacy.iframeLoading,
  statisticalCookieEnabled: state.layout.statisticalCookieEnabled,
  language: state.user.language
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  resetScreen: (): void => void dispatch(legacyAction.resetScreen()),
  setIframeLoading: (value: boolean): void => void dispatch(legacyAction.setIframeLoading(value))
});
const enhance = compose<ComponentType>(withTranslation('common/urlTitles'), connect(mapStateToProps, mapDispatchToProps));
export const LegacyIframe = enhance(LegacyIframeBase);