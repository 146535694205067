import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {userDetailAction} from 'app/user-detail';
import {userContactFormName} from 'app/user-detail/types/user-contact-form-values';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {UpdateUserContactAction, adminAction} from '../action';

const te = t('admin/sagas');

function* execute(_: UpdateUserContactAction): SagaIterator {
    const {value, userProfileId, userContactId} = yield* select((state: State)=>
        formHelpers.formValues(userContactFormName)(state).orCrash('no user contact form values'));

    if (!value || !userProfileId || !userContactId) return; // not valid

    yield* put(formHelpers.startSubmit(userContactFormName));

    const response = yield* call(Api.updateUserContact, {value}, {userContactId});

    const title = te('updateContact');

    if (response.isSuccess) {
        yield* put(adminAction.getUserContacts(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('contactUpdated'), false, 'user-contact-edited'));
        yield* put(userDetailAction.setUserContactsModalVisibility(false));
        yield* put(formHelpers.reset(userContactFormName));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(userContactFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* updateUserContactSaga(): SagaIterator {
    yield takeLatestF('admin/UPDATE_USER_CONTACT', execute);
}
