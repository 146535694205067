import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {showBeError} from 'utils/show-be-error';

import {
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from '../../../../utils/tables';
import {t} from '../../../translations';
import {GetUserOtherSubjectsAction, userAction} from '../action';

const tt = t('userDetail/sagas');

function* execute({payload: {request, userProfileId}}: GetUserOtherSubjectsAction): SagaIterator {
    if (request.isEmpty) {
        return yield* put(userAction.getOtherSubjectsSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('userOtherSubjectRolesProfile'));
    const tableState = yield* select(simpleTableSelector.tableState('userOtherSubjectRolesProfile'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'userOtherSubjectRolesProfile',
        tableDataRequest: request.orCrash('No tableDataRequest for user\'s other subject roles.'),
        tableState,
        resetType: 'tableDataParams',
    });
    const columnSettings = getRequestedTableColumnSettings<'userOtherSubjectRolesProfile'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});

    const response = yield* call(Api.findLoggedInUserOtherSubjectRoles, filters, params, {userProfileId});
    if (response.isSuccess) {
        yield* put(userAction.getOtherSubjectsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('userOtherSubjectRolesProfile', tableDataParams));
    } else {
        yield putAll(showBeError(response, tt('loadOtherSubjects')));
        yield* put(tablesAction.tableDataFetchFailure('userOtherSubjectRolesProfile'));
    }
}

export function* getUserOtherSubjectsSaga(): SagaIterator {
    yield takeLatestF('user/GET_USER_OTHER_SUBJECTS', execute);
}
