import { BasicInput, formatDate } from '@fl/cmsch-fe-library';
import React, { useEffect, useState, memo, useCallback, ChangeEvent } from 'react';
import { opt, Opt, some } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { CriteriaList } from 'api/gen/CriteriaList';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { getListName } from '../../utils/get-list-name';
import styles from './styles.sass';
interface Props {
  item: AnimalList | CriteriaList;
  list: Array<AnimalList> | Array<CriteriaList>;
  listAlreadyExistsMessage: string;
  areYouSureToDeleteMessage: string;
  editList(id: number | null, name: string, gridUserSettingsId?: number | null): void;
  deleteList(id: number, gridUserSettingsId?: number | null): void;
}
const isListNameNotEmptyAndAvailable = (list: Array<AnimalList | CriteriaList>, name: Opt<string>): boolean => name.map(v => list.every((x: AnimalList | CriteriaList) => x.name !== v)).orFalse();
const isCriteriaListType = (list: AnimalList | CriteriaList): list is CriteriaList => 'gridUserSettingsId' in list;

// eslint-disable-next-line max-lines-per-function
const ListRowBase: React.FC<Props> = props => {
  const {
    item,
    list,
    listAlreadyExistsMessage,
    areYouSureToDeleteMessage,
    editList,
    deleteList
  } = props;
  const isCriteriaList = isCriteriaListType(item);
  const {
    t,
    tCommon
  } = useOurTranslation('animalListModals');
  const errorMsgEmpty = t('errorMessageEmpty');
  const [listName, setListName] = useState<Opt<string>>(some(item.name));
  const [isValid, setIsValid] = useState<boolean>(false);
  const [visibleEditField, setVisibleEditField] = useState(false);
  const [touched, setTouched] = useState<boolean>(false);
  useEffect(() => {
    setIsValid(isListNameNotEmptyAndAvailable(list, listName));
  }, [list, listName]);
  const showDeleteConfirm = useCallback(() => {
    Ant.Modal.confirm({
      title: areYouSureToDeleteMessage,
      content: t('actionCannotBeReverted'),
      okText: tCommon('yes'),
      okType: 'danger',
      cancelText: tCommon('no'),
      onOk() {
        deleteList(item.id, isCriteriaList ? item.gridUserSettingsId : undefined);
      }
    });
  }, [deleteList, item, isCriteriaList, t, tCommon, areYouSureToDeleteMessage]);
  const handleShowEdit = useCallback(() => {
    setVisibleEditField(!visibleEditField);
  }, [visibleEditField]);
  const handleEdit = useCallback(() => {
    if (isListNameNotEmptyAndAvailable(list, listName)) {
      const listNamePresent = listName.orCrash('should not be able to send animal name');
      setVisibleEditField(!visibleEditField);
      editList(item.id, listNamePresent, isCriteriaList ? item.gridUserSettingsId : undefined);
    }
  }, [visibleEditField, editList, item, isCriteriaList, listName, list]);
  const handleDelete = useCallback(() => showDeleteConfirm(), [showDeleteConfirm]);
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = opt(event.target.value);
    if (!touched) setTouched(true);
    setListName(value);
    setIsValid(isListNameNotEmptyAndAvailable(list, value));
  }, [touched, list]);
  const showNameFieldError = touched && !listName.contains(item.name) && !isValid;
  const isAnimalList = 'animalsInList' in item;
  return <div key={item.id} data-test-id={isAnimalList ? 'animal-list' : 'criteria-list'} data-sentry-component="ListRowBase" data-sentry-source-file="list-row.tsx">
            <Ant.Row gutter={16} className="pb-1" data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                <Ant.Col xs={16} sm={9} data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                    <div className={styles.colWrapper}>
                        {getListName(item)}
                    </div>
                </Ant.Col>
                <Ant.Col xs={8} sm={5} data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                    <div className={styles.colWrapper}>
                        {formatDate(item.created).orElse('')}
                    </div>
                </Ant.Col>
                <Ant.Col xs={12} sm={5} data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                    <Button onClick={handleShowEdit} block testId="edit" data-sentry-element="Button" data-sentry-source-file="list-row.tsx">{tCommon('edit')}</Button>
                </Ant.Col>
                <Ant.Col xs={12} sm={5} data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                    <Button onClick={handleDelete} danger block testId="delete" data-sentry-element="Button" data-sentry-source-file="list-row.tsx">{tCommon('delete')}</Button>
                </Ant.Col>
            </Ant.Row>
            <Ant.Row gutter={16} className="pt-2 pb-2" hidden={!visibleEditField} data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                <Ant.Col className="pt-1" data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                    {t('newName')}
                </Ant.Col>
                <Ant.Col flex="auto" data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                    <Ant.Form.Item validateStatus={showNameFieldError ? 'error' : undefined} hasFeedback help={showNameFieldError && (listName.isEmpty ? errorMsgEmpty : listAlreadyExistsMessage)} data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                        <BasicInput type="text" value={listName} onFieldChange={handleChange} data-sentry-element="BasicInput" data-sentry-source-file="list-row.tsx" />
                    </Ant.Form.Item>
                </Ant.Col>
                <Ant.Col xs={24} sm={5} data-sentry-element="unknown" data-sentry-source-file="list-row.tsx">
                    <Button key="submit" visuals="primary" block disabled={!isValid || listName.contains(item.name)} onClick={handleEdit} testId="submit-change" data-sentry-element="Button" data-sentry-source-file="list-row.tsx">
                        {tCommon('submit')}
                    </Button>
                </Ant.Col>
            </Ant.Row>
        </div>;
};
export const ListRow = memo(ListRowBase);