import {earTagRegexGen} from 'api/gen/EarTag';
import {milkedTotalRangeMaximum, milkedTotalRangeMinimum} from 'api/gen/MilkedTotalRange';
import {milkingTimeRangeMaximum, milkingTimeRangeMinimum} from 'api/gen/MilkingTimeRange';
import {technicianNumberRegexGen} from 'api/gen/TechnicianNumber';
import {t, tCommon} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {MilkabilityFormValues} from './form-values';

const te = t('milkability/overviewTable');

export const defaultEarTagValue = 'CZ000_________';
const secondsInMinute = 60;
const convertToMinutes = (value: number): number => value / secondsInMinute;

// eslint-disable-next-line max-lines-per-function
export const validate = (values: MilkabilityFormValues): Errors<MilkabilityFormValues> => {
    const validator = new EskotValidator(values);

    validator.nonEmpty('technicianNumber', te('technicianNumber'));
    validator.pattern('technicianNumber', technicianNumberRegexGen(), te('technicianNumber'));

    validator.nonEmpty('examinationDate', te('examinationDate'));
    validator.examinationDate('examinationDate');

    validator.nonEmpty('earTag', tCommon('earTag'));
    validator.pattern('earTag', earTagRegexGen(), tCommon('earTag'));

    validator.nonEmpty('milkedTotal', te('milkedTotal'));
    validator.betweenNumbers({
        fieldName: 'milkedTotal',
        min: milkedTotalRangeMinimum,
        max: milkedTotalRangeMaximum,
        label: te('milkedTotal'),
        unit: 'l',
        precision: 2,
        inclusive: true,
    });

    validator.nonEmpty('milkingTime', te('milkingTime'));
    validator.betweenTimes({
        fieldName: 'milkingTime',
        min: milkingTimeRangeMinimum,
        max: milkingTimeRangeMaximum,
        label: te('milkingTime'),
        unit: 'min',
        precision: 2,
        inclusive: true,
        convertErrorDisplayValue: convertToMinutes,
    });

    validator.apmv('apmv');

    return validator.generateErrorsObject();
};
