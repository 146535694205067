import { TypedFormFieldComponents } from '@fl/cmsch-fe-library';
import React, { FC, Fragment, memo, ReactNode } from 'react';
import { Opt } from 'ts-opt';
import { Accordion } from 'api/gen/Accordion';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { AccordionCollapse, AccordionCollapseProps } from 'app/mating/components/criteria-collapse/accordion-collapse';
import { Ant } from 'common/ant';
import { OurCollapse } from 'common/layout/components/our-collapse';
import { MatingBullsFilterFormValues, MatingCowsFilterFormValues } from '../../types';
interface Props<T> {
  criteriaAccordions: Array<Accordion>;
  criteriaDefinitions: Array<CriteriaDefinition>;
  extraFirstAccordionNodes: ReactNode;
  Fields: TypedFormFieldComponents<T>;
  resetCowsAccordionValues(accordionId: number): void;
  resetCriteriaValue(criteriaName: Opt<string>): void;
}
const span0 = 0;
const span4 = 4;
const span34 = 34;
export const firstAccordionPosition = 1;
const CriteriasInCollapseBase: FC<Props<MatingCowsFilterFormValues | MatingBullsFilterFormValues>> = props => {
  const {
    criteriaAccordions,
    criteriaDefinitions,
    extraFirstAccordionNodes,
    resetCowsAccordionValues,
    resetCriteriaValue,
    Fields
  } = props;
  const CriteriaFields = Fields.genSection<'criterias', AccordionCollapseProps>();
  return <Fragment>
            {criteriaAccordions.map(accordion => <OurCollapse id={accordion.id} title={accordion.name} key={accordion.id} isActive={accordion.position === firstAccordionPosition} resetValues={resetCowsAccordionValues}>
                    {accordion.position === firstAccordionPosition && extraFirstAccordionNodes}
                    <Ant.Row gutter={[{
        xs: span0,
        xl: span34
      }, span4]} id={accordion.id.toString()} className="ml-0 mr-0">
                        <CriteriaFields name="criterias" Component={AccordionCollapse} props={{
          resetCriteriaValue,
          criteriaDefinitions,
          accordion
        }} />
                    </Ant.Row>
                </OurCollapse>)}
        </Fragment>;
};
export const CriteriasInCollapse = memo(CriteriasInCollapseBase);