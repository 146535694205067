import {Response, AuthData, FormDataSchema} from '@fl/cmsch-fe-library';
import * as t from 'io-ts';

import {legacyApiRequester} from 'app/api';

export class LegacyUserApi {
    public static async loginLegacy(authData: AuthData): Promise<Response<string>> {
        const loginFormData = new FormData();
        loginFormData.append('Name', authData.username);
        loginFormData.append('Password', authData.password);
        loginFormData.append('Remember', 'true');

        return legacyApiRequester.makeRequest({
            url: '/Account/JLogin',
            method: 'post',
            data: loginFormData,
            requestSchema: FormDataSchema,
            responseSchema: t.string,
            expectedStatus: 200,
        });
    }
}
