import {Options} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import {Opt, none} from 'ts-opt';

import {AnimalList} from 'api/gen/AnimalList';
import {AnimalsBrowserFarm} from 'api/gen/AnimalsBrowserFarm';
import {AnimalsBrowserStable} from 'api/gen/AnimalsBrowserStable';
import {AvailableAnimal} from 'api/gen/AvailableAnimal';
import {PagedModelBreedingFemale} from 'api/gen/PagedModelBreedingFemale';
import {PagedModelCabBreedingFemale} from 'api/gen/PagedModelCabBreedingFemale';
import {PagedModelCabCalf} from 'api/gen/PagedModelCabCalf';
import {PagedModelCalf} from 'api/gen/PagedModelCalf';
import {PagedModelStableToChoose} from 'api/gen/PagedModelStableToChoose';
import {AnimalBrowserState} from 'app/animal-browser/model/state';
import {State} from 'app/setup';

import {BrowserLoadingId} from '../types/browser-loading-id';

export const simpleAnimalBrowserSelector = {
    calfBrowserPage: ({animalBrowser}: State): Opt<PagedModelCalf> => animalBrowser.calfBrowserPage,
    animalBrowser: ({animalBrowser}: State): AnimalBrowserState => animalBrowser,
    cowBrowserPage: ({animalBrowser}: State): Opt<PagedModelBreedingFemale> => animalBrowser.cowBrowserPage,
    cabFemalePage: ({animalBrowser}: State): Opt<PagedModelCabBreedingFemale> => animalBrowser.cabFemalePage,
    stablePage: ({animalBrowser}: State): Opt<PagedModelStableToChoose> => animalBrowser.stablesPage,
    stableInfo: ({animalBrowser}: State): Opt<AnimalsBrowserStable> => animalBrowser.stableInfo,
    farmInfo: ({animalBrowser}: State): Opt<AnimalsBrowserFarm> => animalBrowser.farmInfo,
    productionAreasInCentralEvidenceOptions: ({animalBrowser}: State): Opt<Options<string>> =>
        animalBrowser.productionAreasInCentralEvidenceOptions,
    exportInProgress: ({animalBrowser}: State): boolean => animalBrowser.exportInProgress,
    stablePageEmpty: ({animalBrowser}: State): boolean => isEmpty(animalBrowser.stablesPage.prop('content').orElse([])),
    isCabFemalePageEmpty: ({animalBrowser}: State): boolean =>
        animalBrowser.cabFemalePage.prop('content').forAll(isEmpty),
    isCabFemalesSessionEmpty: ({animalBrowser}: State): boolean => !animalBrowser.cabFemalesExistInSession,
    loading: ({animalBrowser}: State): Array<BrowserLoadingId> => animalBrowser.loading,
    isCabCalfPageEmpty: ({animalBrowser}: State): boolean =>
        animalBrowser.cabCalfPage.prop('content').forAll(isEmpty),
    cabCalfPage: ({animalBrowser}: State): Opt<PagedModelCabCalf> => animalBrowser.cabCalfPage,
    isCabCalvesSessionEmpty: ({animalBrowser}: State): boolean => !animalBrowser.cabCalvesExistInSession,
    organizationPtOptions: ({animalBrowser}: State): Opt<Options<number>> => animalBrowser.organizationsPtOptions,
    organizationsCenterPtOptions: ({animalBrowser}: State): Opt<Options<number>> =>
        animalBrowser.organizationsCenterPtOptions,
    organizationsInsOptions: ({animalBrowser}: State): Opt<Options<number>> =>
        animalBrowser.organizationsInsOptions,
    organizationsCenterInsOptions: ({animalBrowser}: State): Opt<Options<number>> =>
        animalBrowser.organizationsCenterInsOptions,
    districtCodesOptions: ({animalBrowser}: State): Opt<Options<number>> => animalBrowser.districtCodesOptions,
    breederOptions: ({animalBrowser}: State): Opt<Options<number>> => animalBrowser.breederOptions,
    farmOptions: ({animalBrowser}: State): Opt<Options<number>> => animalBrowser.farmOptions,
    stableOptions: ({animalBrowser}: State): Opt<Options<number>> => animalBrowser.stableOptions,
    firstCalvesLoad: ({animalBrowser}: State): boolean => animalBrowser.firstCabCalvesLoad,
    firstCabBreedingFemalesLoad: ({animalBrowser}: State): boolean => animalBrowser.firstCabBreedingFemalesLoad,
    createAnimalListsModalVisible: ({animalBrowser}: State): boolean => animalBrowser.createAnimalListsModalVisible,
    overviewListModalVisible: ({animalBrowser}: State): boolean => animalBrowser.overviewListModalVisible,
    animalLists: ({animalBrowser}: State): Opt<Array<AnimalList>> => animalBrowser.animalLists,
    cabCalvesLists: ({animalBrowser}: State): Opt<Array<AnimalList>> =>
        animalBrowser.animalListsTag.contains('CALVES') ? animalBrowser.animalLists : none,
    cabFemalesLists: ({animalBrowser}: State): Opt<Array<AnimalList>> =>
        animalBrowser.animalListsTag.contains('BREEDING_FEMALES') ? animalBrowser.animalLists : none,
    overwriteListNameModalVisible: ({animalBrowser}: State): boolean => animalBrowser.overwriteListNameModalVisible,
    overwriteAnimalListId: ({animalBrowser}: State): Opt<number> => animalBrowser.overwriteAnimalListId,
    createAnimalListsFromIdsModalVisible: ({animalBrowser}: State): boolean =>
        animalBrowser.createAnimalListsFromIdsModalVisible,
    overwriteListFromIdsNameModalVisible: ({animalBrowser}: State): boolean =>
        animalBrowser.overwriteListFromIdsNameModalVisible,
    overwriteAnimalListFromIdsId: ({animalBrowser}: State): Opt<number> => animalBrowser.overwriteAnimalListFromIdsId,
    selectedAnimalIds: ({animalBrowser}: State): Array<number> => animalBrowser.selectedAnimalIds,
    addAnimalsModalVisible: ({animalBrowser}: State): boolean => animalBrowser.addAnimalsModalVisible,
    availableAnimals: ({animalBrowser}: State): Opt<Array<AvailableAnimal>> => animalBrowser.availableAnimals,
    addAnimalsFromDBModalVisible: ({animalBrowser}: State): boolean => animalBrowser.addAnimalsFromDBModalVisible,
};
