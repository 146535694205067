import {takeLatestF, showBeError, putAll} from '@fl/cmsch-fe-library';
import {split} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {none, opt, orCrash, pipe, last} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {
    tablesAction,
    getRequestedTableColumnSettings,
    simpleTableSelector,
    getRequestedTablePageParams,
    convertTableDataParamsToDto,
} from 'utils/tables';

import {potentiallyChangeOfTableDataSource} from '../../utils/potentially-change-of-table-data-source';
import {animalBrowserAction, GetCowBrowserTableDataAction} from '../action';
import {simpleAnimalBrowserSelector} from '../selector';

const getFilterParams = ({router: {location}}: State): string =>
    pipe(location?.pathname, split('/'), last, orCrash('invalid url'));

// eslint-disable-next-line max-lines-per-function
function* execute(action: GetCowBrowserTableDataAction): SagaIterator {
    const {tableDataRequest} = action.payload;

    if (tableDataRequest.isEmpty) {
        return yield* put(animalBrowserAction.getcowBrowserTableDataSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('cowBrowser'));

    const tableState = yield* select(simpleTableSelector.tableState('cowBrowser'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'cowBrowser',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
        resetType: 'tableDataParams',
    });

    const columnSettings = getRequestedTableColumnSettings<'cowBrowser'>(tableState);
    const id = opt(yield* select(getFilterParams)).orCrash('Wrong ID');

    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});

    const response = yield* call(Api.getBreedingFemales, filters, params, {stableId: parseInt(id, 10)});

    const sableCode = (yield* select(simpleAnimalBrowserSelector.stableInfo)).prop('code').orUndef();
    if (!sableCode || potentiallyChangeOfTableDataSource(tableDataRequest.orCrash('No tableDataRequest'))) {
        const stableInfo = yield* call(Api.getAnimalsBrowserStable, {stableId: parseInt(id, 10)});
        if (stableInfo.isSuccess) {
            yield* put(animalBrowserAction.setStableName(stableInfo.data));
        }
    }
    if (response.isSuccess) {
        yield* put(animalBrowserAction.getcowBrowserTableDataSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('cowBrowser', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('cowBrowserTable')('tableName')));
    }
}

export function* getCowBrowserSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_COW_BROWSER_DATA', execute);
}
