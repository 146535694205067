import {head, isFull, opt} from 'ts-opt';

import {BreederRole} from 'api/gen/BreederRole';
import {RoleBreederFarm} from 'api/gen/RoleBreederFarm';
import {t} from 'app/translations';

import {BreederRoleTableType, FarmChildren, RolesChildren} from '../../types/breeder-role-table-type';

interface GetComputedFarmParams {
    role: RoleBreederFarm;
    breederRegistrationNumber: number;
    breederId: number;
    subjectId: number;
    subjectName: string | null;
}

const getComputedFarm = ({
    breederId,
    breederRegistrationNumber,
    role,
    subjectId,
    subjectName,
}: GetComputedFarmParams): Array<FarmChildren> | undefined => role.accessToAllFarms || role.farms?.length === 1
    ? undefined
    : role.farms?.map(farm => ({
        id: farm.rowId,
        breederId,
        approvedFrom: role.approvedFrom,
        approvedTo: role.approvedTo,
        roleCode: role.roleCode,
        roleIds: [role.roleId],
        roleName: role.roleName,
        userProfileBreederRoleIds: [role.userProfileBreederRoleId],
        farmCode: farm.farmCode,
        breederRegistrationNumber,
        farmId: farm.farmId,
        farmName: farm.farmName,
        level: 3,
        subjectId,
        subjectName,
    }));

const roleHasOneChild = (role: RoleBreederFarm | undefined): boolean =>
    opt(role).prop('farms').exists(xs => xs.length === 1)
    || opt(role).prop('accessToAllFarms').orFalse();

const shouldBeOnlyOneRow = (roles: Array<RoleBreederFarm>): boolean =>
    roles.length === 1
    && roleHasOneChild(roles[0]);

const getFarmCode = (role: RoleBreederFarm): string | null => {
    if (role.accessToAllFarms && isFull(role.farms)) return t('admin/users')('allFarms');

    return role.farms?.length === 1
        ? opt(role).prop('farms').headIn().prop('farmCode').orNull()
        : null;
};

// eslint-disable-next-line max-lines-per-function
export const rowMapper = ({
    roles,
    breederRegistrationNumber,
    rowId,
    breederId,
    subjectId,
    subjectName,
}: BreederRole): BreederRoleTableType => {
    const isSingleRow = shouldBeOnlyOneRow(roles);

    const getFirstLevelProp = <P extends keyof RoleBreederFarm>(prop: P): RoleBreederFarm[P] | null =>
        isSingleRow ? opt(roles).headIn().prop(prop).orNull() : null;

    return ({
        id: rowId,
        rowId,
        breederRegistrationNumber,
        breederId,
        roles,
        children: isSingleRow
            ? undefined
            : roles.map((role: RoleBreederFarm): RolesChildren => ({
                id: role.rowId,
                breederId,
                roles,
                breederRegistrationNumber,
                accessToAllFarms: role.accessToAllFarms && isFull(role.farms),
                approvedFrom: role.approvedFrom,
                approvedTo: role.approvedTo,
                roleCode: role.roleCode,
                roleIds: [role.roleId],
                roleName: role.roleName,
                userProfileBreederRoleIds: [role.userProfileBreederRoleId],
                farms: role.farms,
                children: getComputedFarm({
                    role,
                    breederRegistrationNumber,
                    breederId,
                    subjectId,
                    subjectName,
                }),
                farmCode: getFarmCode(role),
                farmIds: role.farms?.map(f => f.farmId) ?? [],
                level: 2,
                subjectId,
                subjectName,
            })),
        approvedFrom: getFirstLevelProp('approvedFrom'),
        approvedTo: getFirstLevelProp('approvedTo'),
        roleName: getFirstLevelProp('roleName'),
        roleCode: getFirstLevelProp('roleCode'),
        roleIds: roles.map(role => role.roleId),
        userProfileBreederRoleIds: roles.map(role => role.userProfileBreederRoleId),
        farmCode: opt(roles).filter(_ => isSingleRow).headIn().chain(role =>
            role.accessToAllFarms && isFull(role.farms)
                ? opt(t('admin/users')('allFarms'))
                : head(role.farms).prop('farmCode'),
        ).orNull(),
        level: 1,
        accessToAllFarms: roles.every(role => role.accessToAllFarms && isFull(role.farms)),
        farmIds: roles.flatMap(role => role.farms?.map(farm => farm.farmId) ?? []),
        farms: isSingleRow ? roles.flatMap(r => r.farms ?? []) : null,
        subjectId,
        subjectName,
    });
};
