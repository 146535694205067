import React, { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AssociationType } from 'api/gen/AssociationType';
import { MatingLoadingId } from 'app/mating/types';
import { matingAction } from '../../model';
import { MatingBullsFleckviehTable } from './mating-bulls-fleckvieh-table';
import { MatingBullsHolsteinTable } from './mating-bulls-holstein-table';
const MALE = 'MALE';
const HOLSTEIN = 'HOLSTEIN';
interface Props {
  associationType: AssociationType;
  loading: Array<MatingLoadingId>;
}
const MatingBullsTableBase: FC<Props> = ({
  associationType,
  loading
}) => {
  const dispatch = useDispatch();
  const exportInProgress = loading.includes('exportInProgress');
  const removeBull = useCallback((id: number) => {
    dispatch(matingAction.removeAnimal(MALE, id, associationType));
  }, [associationType, dispatch]);
  const exportBullselectorResult = useCallback((): void => {
    dispatch(matingAction.exportMatingAnimals(MALE, associationType));
  }, [dispatch, associationType]);
  return associationType === HOLSTEIN ? <MatingBullsHolsteinTable removeBull={removeBull} exportBullselectorResult={exportBullselectorResult} exportInProgress={exportInProgress} data-sentry-element="MatingBullsHolsteinTable" data-sentry-component="MatingBullsTableBase" data-sentry-source-file="index.tsx" /> : <MatingBullsFleckviehTable removeBull={removeBull} exportBullselectorResult={exportBullselectorResult} exportInProgress={exportInProgress} data-sentry-element="MatingBullsFleckviehTable" data-sentry-component="MatingBullsTableBase" data-sentry-source-file="index.tsx" />;
};
export const MatingBullsTable = memo(MatingBullsTableBase);