import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {userDetailAction} from 'app/user-detail';
import {userContactFormName} from 'app/user-detail/types/user-contact-form-values';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {adminAction, CreateUserContactAction} from '../action';

const te = t('admin/sagas');
const title = te('createContact');

function* execute(_: CreateUserContactAction): SagaIterator {
    const {contactType, userProfileId, value} = yield* select((state: State) =>
        formHelpers.formValues(userContactFormName)(state).orCrash('no user contact form values'));

    if (!contactType || !userProfileId || !value) return; // not valid
    yield* put(formHelpers.startSubmit(userContactFormName));

    const response = yield* call(Api.createUserContact, {contactType, userProfileId, value});

    if (response.isSuccess) {
        yield* put(adminAction.getUserContacts(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('contactAdded'), false, 'user-contact-created'));
        yield* put(userDetailAction.setUserContactsModalVisibility(false));
        yield* put(formHelpers.reset(userContactFormName));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(userContactFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* createUserContactSaga(): SagaIterator {
    yield takeLatestF('admin/CREATE_USER_CONTACT', execute);
}
