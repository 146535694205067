import {Dictionary, convertAllErrorsFromBeToFormError, putAll, takeLatestF, validateSchema} from '@fl/cmsch-fe-library';
import {isUndefined, keys, omitBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {isEmpty, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {ValidateAssociationRole} from 'api/gen/ValidateAssociationRole';
import {ValidateCreateAssociationRole} from 'api/gen/ValidateCreateAssociationRole';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {ValidateAsociationErrorsSchema} from '../../types/errors/validate-asociation-errors';
import {SubjectFormValuesErrors, subjectFormName} from '../../types/subject-form-values';
import {ValidateUserAssociationRolesAction} from '../action';

interface ApiTypes {
    associationId: number;
    roleIds: Array<number> | null;
    userProfileId: number;
    approvedFrom: string;
    approvedTo: string | null;
    userProfileAssociationRoleIds: Array<number> | null;
}
const getApiValues = ({
    associationId,
    roleIds,
    userProfileId,
    approvedFrom,
    approvedTo,
    userProfileAssociationRoleIds,
}: ApiTypes): ValidateAssociationRole => isEmpty(userProfileAssociationRoleIds)
    ? ({
        tag: 'ValidateCreateAssociationRole',
        roleIds: opt(roleIds).orCrash('Users associations form: roleIds not found'),
        associationId,
        userProfileId,
        approvedFrom,
        approvedTo,
    })
    : ({
        tag: 'ValidateUpdateAssociationRole',
        userProfileAssociationRoleIds: opt(userProfileAssociationRoleIds)
            .orCrash('Users associations form: userProfileAssociationRoleIds not found'),
        approvedTo,
    });

const mapErrors = (errors: Dictionary<keyof ValidateCreateAssociationRole, string>): SubjectFormValuesErrors => {
    const validErrors = validateSchema(ValidateAsociationErrorsSchema, 'FE')(errors);

    return omitBy(isUndefined)<SubjectFormValuesErrors>({
        approvedFrom: validErrors.approvedFrom,
        approvedTo: validErrors.approvedTo,
        subjectId: validErrors.associationId,
        roleIds: validErrors.roleIds,
    });
};

// eslint-disable-next-line max-lines-per-function
function* execute(_: ValidateUserAssociationRolesAction): SagaIterator {
    const {
        approvedFrom,
        approvedTo,
        subjectId,
        roleIds,
        userProfileAssociationRoleIds,
        userProfileId,
    } = (
        yield* select(formHelpers.formValues(subjectFormName))
    ).orCrash(`no ${subjectFormName} form values`);

    const pristine = yield* select(formHelpers.isPristine(subjectFormName));
    if (
        approvedFrom === null
        || subjectId === null
        || userProfileId === null
        || isEmpty(roleIds)
        || pristine
        || (approvedTo !== null && approvedFrom > approvedTo)
    ) return; // synchronously not valid

    yield* put(formHelpers.startAsyncValidation(subjectFormName));

    const response = yield* call(
        Api.validateUserProfileAssociationRoleBinding,
        getApiValues({
            associationId: subjectId,
            roleIds,
            userProfileId,
            approvedFrom,
            approvedTo,
            userProfileAssociationRoleIds,
        }),
    );

    if (!response.isSuccess) {
        if (response.isBadRequest) {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            const asociationErrors = mapErrors(errors.errors);
            yield* put(formHelpers.stopAsyncValidation(subjectFormName, errors.errors));
            const fieldsWithNewErrors = keys(asociationErrors) as Array<keyof typeof asociationErrors>;

            if (!isEmpty(fieldsWithNewErrors)) {
                yield* put(formHelpers.touch(subjectFormName, ...fieldsWithNewErrors));
            }
        } else {
            yield putAll(showBeError(response, t('common')('validateEntry')));
        }
    } else {
        yield* put(formHelpers.stopAsyncValidation(subjectFormName, {}));
    }
}

export function* validateUserAssociationsSaga(): SagaIterator {
    yield takeLatestF('userDetail/VALIDATE_USER_ASSOCIATIONS', execute);
}
