import React, { memo, FC, useMemo, Fragment } from 'react';
import { Opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { CriteriaList } from 'api/gen/CriteriaList';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Modal } from 'common/layout/components/modal';
import { AnimalListSection } from './animal-list-section';
import { CriteriaListSection } from './criteria-list-section';
interface Props {
  visible: boolean;
  animalLists: Opt<Array<AnimalList>>;
  criteriaLists?: Opt<Array<CriteriaList>>;
  title: string;
  noListsSaved: string;
  listAlreadyExistsMessage: string;
  areYouSureToDeleteMessage: string;
  renameAnimalList?(id: number, name: string): void;
  renameCriteriaList?(id: number | null, name: string, gridUserSettingsId?: number | null): void;
  deleteAnimalList?(animalListId: number): void;
  deleteCriteriaList?(criteriaListId: number, gridUserSettingsId: number | null): void;
  onCancel(): void;
}
const ModalListsOverviewBase: FC<Props> = props => {
  const {
    visible,
    animalLists,
    criteriaLists,
    title,
    noListsSaved,
    listAlreadyExistsMessage,
    areYouSureToDeleteMessage,
    renameAnimalList,
    renameCriteriaList,
    onCancel,
    deleteAnimalList,
    deleteCriteriaList
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('animalListModals');
  const showCriteriaLists = Boolean(criteriaLists && renameCriteriaList && deleteCriteriaList);
  const footer = useMemo(() => <Ant.Button key="back" onClick={onCancel}>
            {tCommon('close')}
        </Ant.Button>, [onCancel, tCommon]);
  const emptyList = useMemo(() => <Ant.Row gutter={16}>
            <Ant.Col xs={16}>
                {noListsSaved}
            </Ant.Col>
        </Ant.Row>, [noListsSaved]);
  const listHeader = useMemo(() => <Ant.Row gutter={16} className="font-weight-bold pb-2">
            <Ant.Col xs={16} sm={9}>
                {t('name')}
            </Ant.Col>
            <Ant.Col xs={8} sm={5}>
                {t('created')}
            </Ant.Col>
        </Ant.Row>, [t]);
  return <Modal title={title} visible={visible} onCancel={onCancel} footer={footer} data-sentry-element="Modal" data-sentry-component="ModalListsOverviewBase" data-sentry-source-file="index.tsx">
            {animalLists.nonEmpty && <Fragment>
                    <AnimalListSection showSectionTitle={showCriteriaLists} animalLists={animalLists} listHeader={listHeader} emptyList={emptyList} listAlreadyExistsMessage={listAlreadyExistsMessage} areYouSureToDeleteMessage={areYouSureToDeleteMessage} renameAnimalList={renameAnimalList} deleteAnimalList={deleteAnimalList} />
                    {showCriteriaLists && <Ant.Divider orientation="left" />}
                </Fragment>}
            {criteriaLists && renameCriteriaList && deleteCriteriaList && <CriteriaListSection criteriaLists={criteriaLists} listHeader={listHeader} emptyList={emptyList} listAlreadyExistsMessage={listAlreadyExistsMessage} areYouSureToDeleteMessage={areYouSureToDeleteMessage} renameCriteriaList={renameCriteriaList} deleteCriteriaList={deleteCriteriaList} />}
        </Modal>;
};
export const ModalListsOverview = memo(ModalListsOverviewBase);