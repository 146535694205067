import {downloadFileFromResponse, takeLatestF, showBeError, putAll} from '@fl/cmsch-fe-library';
import {split} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {opt, orCrash, pipe, last} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {getRequestedTableCsvExportParams, simpleTableSelector} from 'utils/tables';

import {animalBrowserAction, GetCalvesCsvAction} from '../action';

const getIdFromUrl = ({router: {location}}: State): string =>
    pipe(location?.pathname, split('/'), last, orCrash('invalid url'));

function* execute(_action: GetCalvesCsvAction): SagaIterator {
    yield* put(animalBrowserAction.startExport());
    const tableState = opt(yield* select(simpleTableSelector.tableState('calfBrowser'))).orCrash('no tableState');

    const {fieldParams, sort} = getRequestedTableCsvExportParams(tableState);

    const id = yield* select(getIdFromUrl);
    const pathParams = {farmId: parseInt(id, 10)};

    const response = yield* call(Api.exportCalvesByFarm, fieldParams, {sort}, pathParams);

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('calfBrowserTable')('export')));
    }
    yield* put(animalBrowserAction.exportFinished());
}

export function* getCalvesCsvSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_CALVES_CSV', execute);
}
