import {Nullable} from '@fl/cmsch-fe-library';

export const exportAnimalsFormName = 'exportAnimals';

export interface ExportAnimalsFormValues {
    exportAnimalsInList: Nullable<string>;
}

export const exportAnimalsFormInitialValues: ExportAnimalsFormValues = {
    exportAnimalsInList: null,
};
