import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {getDateOptions, getStableOptions} from '../../utils/get-apc-options';
import {analysisProtocolCorrectionAction, GetStableDateRelationsAction} from '../action';

function* execute({payload: {focus, earTag, correctionType}}: GetStableDateRelationsAction): SagaIterator {
    yield* put(analysisProtocolCorrectionAction.setLoading('stableDateOptionsLoading', true));
    const response = yield* call(Api.getStableDateRelations, {earTag});

    if (response.isSuccess) {
        const {data} = response;
        yield* put(analysisProtocolCorrectionAction.getStableDateRelationsSuccess(opt(data)));
        if (correctionType === 'UPDATE') {
            yield* put(data.tag === 'ApcDateListWithStables'
                ? analysisProtocolCorrectionAction.setStableOptions(getStableOptions(data.stableData))
                : analysisProtocolCorrectionAction.setStableOptions(getStableOptions(data.data)),
            );
        } else {
            if (data.tag === 'ApcDateListWithStables') {
                // only for TS, if correctionType !== 'UPDATE' tag always have to be 'ApcDateListWithStables'
                yield* put(analysisProtocolCorrectionAction.setProficiencyTestDateOptions(
                    getDateOptions(data.dateData),
                ));
            }
        }
        focus();
    } else {
        yield putAll(showBeError(response, t('apc/sagas')('stableDateRelations')));
    }

    yield* put(analysisProtocolCorrectionAction.setLoading('stableDateOptionsLoading', false));
}

export function* getStableDateRelationsSaga(): SagaIterator {
    yield takeLatestF('analysisProtocolCorrection/GET_STABLE_DATE_RELATIONS', execute);
}
