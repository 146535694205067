import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {uniqBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {GetOrganizationFarmsAction, userDetailAction} from '../action';

function* execute({payload: {organizationId}}: GetOrganizationFarmsAction): SagaIterator {
    yield* put(userDetailAction.setLoading('getFarms', true));

    const response = yield* call(Api.findAllFarmsByOrganizationId, {organizationId});

    if (response.isSuccess) {
        yield* put(userDetailAction.setOrganizationFarmOptions(uniqBy('id', response.data)));
    } else {
        yield putAll(showBeError(response, t('admin/users')('farmOptionsLoading')));
    }

    yield* put(userDetailAction.setLoading('getFarms', false));
}

export function* getOrganizationFarmsSaga(): SagaIterator {
    yield takeLatestF('userDetail/GET_ORGANIZATION_FARMS', execute);
}
