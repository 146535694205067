import {createCorrectionReportSaga} from './create-correction-report-saga';
import {deleteCorrectionReportSaga} from './delete-correction-report-saga';
import {exportCorrectionReportsSaga} from './export-correction-reports-saga';
import {getAnalysisProtocolSaga} from './get-analysis-protocol-saga';
import {getCorrectionReportDetailSaga} from './get-correction-report-detail-saga';
import {getCorrectionReportsSaga} from './get-correction-reports-saga';
import {getStableDateRelationSaga} from './get-stable-date-relation-saga';
import {getStableDateRelationsSaga} from './get-stable-date-relations-saga';
import {getTechnicianOptionsSaga} from './get-technician-options-saga';
import {validateCorrectionReportSaga} from './validate-correction-report-saga';

export const analysisProtocolCorrectionSagas = [
    createCorrectionReportSaga,
    getCorrectionReportsSaga,
    deleteCorrectionReportSaga,
    exportCorrectionReportsSaga,
    getAnalysisProtocolSaga,
    getStableDateRelationsSaga,
    getTechnicianOptionsSaga,
    validateCorrectionReportSaga,
    getCorrectionReportDetailSaga,
    getStableDateRelationSaga,
];
