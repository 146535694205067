import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {DeleteUserSettingsAction} from '../action';

const te = t('user/sagas');

export function* deleteUserSettingsSagaExecute({payload: {userSettingsId}}: DeleteUserSettingsAction): SagaIterator {
    const response = yield* call(Api.resetUserSettings, {gridUserSettingsId: userSettingsId});

    if (response.isSuccess) {
        yield* put(showSuccess(te('deleteUserSettings'), te('settingsReset')));
    } else {
        yield putAll(showBeError(response, te('deleteUserSettings')));
    }
}

export function* deleteUserSettingsSaga(): SagaIterator {
    yield takeLatestF('user/DELETE_USER_SETTINGS', deleteUserSettingsSagaExecute);
}
