import {NullableKeys} from '@fl/cmsch-fe-library';
import {genNakedPropOrCrash, opt} from 'ts-opt';

import {CreateOrUpdateRole} from 'api/gen/CreateOrUpdateRole';
import {State} from 'app/setup';
import {formHelpers} from 'utils/forms';

import {roleFormName} from '../types';

export const buildRoleValuesForApi = (formValues: NullableKeys<CreateOrUpdateRole>): CreateOrUpdateRole => {
    const get = genNakedPropOrCrash(formValues);

    return {
        code: get('code'),
        name: get('name'),
        discriminator: get('discriminator'),
        userEnable: opt(formValues).prop('userEnable').orFalse(),
    };
};

export const getRoleFormValues = (state: State): NullableKeys<CreateOrUpdateRole> =>
    formHelpers.formValues(roleFormName)(state).orCrash('no role form values');
