import {setItem} from '@fl/cmsch-fe-library';
import {filter} from 'lodash/fp';
import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {initialMilkabilityState, MilkabilityState} from './state';

const stateO = O.optic<MilkabilityState>();

export const milkabilityReducer = (
    state: MilkabilityState = initialMilkabilityState,
    action: AppAction,
): MilkabilityState => {
    switch (action.type) {
        case 'milkability/GET_MILKABILITY_REPORTS_SUCCESS': {
            const {tableData} = action.payload;

            return O.set(stateO.prop('pageMilkability'))(tableData)(state);
        }

        case 'milkability/CREATE_MILKABILITY_REPORT_SUCCESS': {
            const {data} = action.payload;

            return O.modify(stateO.prop('createdMilkabilityReports'))(
                x => x.filter(y => y.id !== data.id).concat(data),
            )(state);
        }

        case 'milkability/DELETE_MILKABILITY_REPORT_SUCCESS': {
            const {id} = action.payload;

            return O.modify(stateO.prop('createdMilkabilityReports'))(filter(y => y.id !== id))(state);
        }

        case 'milkability/RESET_CREATED_MILKABILITY_REPORTS': {
            return O.set(stateO.prop('createdMilkabilityReports'))([])(state);
        }

        case 'milkability/SET_EDITING_MILK_REPORT_ID': {
            const {id} = action.payload;

            return O.set(stateO.prop('editingMilkabilityReportId'))(id)(state);
        }

        case 'milkability/SET_LOADING': {
            const {id, loading} = action.payload;

            return O.modify(stateO.prop('loading'))(setItem(loading)(id))(state);
        }

        default:
            return state;
    }
};
