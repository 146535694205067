import {CabCalf} from 'api/gen/CabCalf';

import {AnimalBrowserLinkProps, getAnimalBrowserLink} from './get-animal-browser-link';
import {getBullBrowserLink} from './get-bull-browser-link';

export type CalfLinkProps = AnimalBrowserLinkProps & Pick<CabCalf, 'sex'>;

export const getCalfBrowserLink = (
    _id: number | string,
    row: CabCalf | CalfLinkProps,
): string => {
    const {sex} = row;

    return sex === 'FEMALE' ? getAnimalBrowserLink(row) : getBullBrowserLink('earTag')(row);
};
