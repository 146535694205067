import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse, NullableKeys} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {genNakedPropOrCrash, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {CreateUserProfileOrganizationRole} from 'api/gen/CreateUserProfileOrganizationRole';
import {t} from 'app/translations';
import {userDetailAction} from 'app/user-detail';
import {buildCenterOptions, BuildCenters} from 'app/user-detail/model/sagas/build-center-options';
import {subjectFacilityForm, SubjectFacilityFormValues} from 'app/user-detail/types/subject-facility-form-values';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {adminAction, CreateOrganizationAction} from '../action';

const te = t('common');
const title = te('createEntry');

const buildValuesForApi = (formValues: NullableKeys<SubjectFacilityFormValues>): CreateUserProfileOrganizationRole => {
    const get = genNakedPropOrCrash(formValues);

    return {
        approvedFrom: get('approvedFrom'),
        approvedTo: opt(formValues).prop('approvedTo').orNull(),
        roleIds: get('roleIds'),
        organizationId: get('subjectId'),
        userProfileId: get('userProfileId'),
        organizationCenterIds: get('centerIds'),
        farmIds: get('farmIds'),
    };
};

// eslint-disable-next-line max-lines-per-function
function* execute(_: CreateOrganizationAction): SagaIterator {
    const formValues = yield* select(state => formHelpers.formValues(subjectFacilityForm)(state)
        .orCrash(`no form values: ${subjectFacilityForm}`));
    yield* put(formHelpers.startSubmit(subjectFacilityForm));

    const buildCenters: BuildCenters = {
        centerIds: formValues.centerIds,
        type: 'ORGANIZATION_CENTER',
    };

    const buildFarms: BuildCenters = {
        centerIds: formValues.farmIds,
        type: 'ORGANIZATION_FARM',
    };

    const ocIds = yield* call(buildCenterOptions, buildCenters);
    const farmIds = yield* call(buildCenterOptions, buildFarms);

    const response = yield* call(Api.addUserProfileOrganizationRoleBindings, buildValuesForApi({
        centerIds: ocIds,
        approvedFrom: formValues.approvedFrom,
        approvedTo: formValues.approvedTo,
        roleIds: formValues.roleIds,
        subjectId: formValues.subjectId,
        userProfileId: formValues.userProfileId,
        userProfileRoleIds: formValues.userProfileRoleIds,
        farmIds,
    }));

    if (response.isSuccess) {
        const userProfileId = opt(formValues.userProfileId).orCrash('No userProfileId found');

        yield* put(adminAction.getOrganizations(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('createEntrySuccess'), false, 'createOrganization'));
        yield* put(userDetailAction.setFacilityModalVisible(null));
        yield* put(formHelpers.reset(subjectFacilityForm));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(subjectFacilityForm, extractFormErrorsFromResponse(response)));
    }
}

export function* createOrganizationsSaga(): SagaIterator {
    yield takeLatestF('admin/CREATE_ORGANIZATION', execute);
}
