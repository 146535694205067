import { isEmpty } from 'lodash/fp';
import React, { FC, Fragment, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Opt } from 'ts-opt';
import { AnimalList } from 'api/gen/AnimalList';
import { AnimalType } from 'api/gen/AnimalType';
import { ModalAddAnimals, ModalAddAnimalsFromDb, ModalListConfirmName, ModalListName, ModalListsOverview } from 'app/modals/components';
import { useOurTranslation } from 'app/translations';
import { animalBrowserAction, simpleAnimalBrowserSelector } from '../../model';
interface Props {
  animalType: AnimalType;
  isAnimalsPageEmpty: boolean;
  animalsLists: Opt<Array<AnimalList>>;
  confirmAnimalListNameFromIdsLoading: boolean;
}
const CabModalsBase: FC<Props> = ({
  isAnimalsPageEmpty,
  animalsLists,
  confirmAnimalListNameFromIdsLoading,
  animalType
}) => {
  const dispatch = useDispatch();
  const setAvailableAnimalsLoading = useCallback((): void => {
    dispatch(animalBrowserAction.setLoading('availableAnimalsLoading', true));
  }, [dispatch]);
  const getAvailableAnimals = useCallback((input: string): void => {
    dispatch(animalBrowserAction.getAvailableAnimals(animalType, input));
  }, [dispatch, animalType]);
  const addAnimals = useCallback((animalIds: Array<number>): void => {
    dispatch(animalBrowserAction.addAnimals(animalType, animalIds));
  }, [dispatch, animalType]);
  const toggleAddAnimalsFromDBVisibility = useCallback(() => {
    dispatch(animalBrowserAction.toggleAddAnimalsFromDBVisibility());
  }, [dispatch]);
  const addAnimalsFromDB = useCallback((animalListId: number) => {
    dispatch(animalBrowserAction.addAnimalsFromDB(animalType, animalListId));
  }, [dispatch, animalType]);
  const toggleCreateAnimalListFromIdsModalVisibility = useCallback(() => {
    dispatch(animalBrowserAction.toggleCreateAnimalsListFromIdsModal());
  }, [dispatch]);
  const toggleOverviewListModalVisibility = useCallback(() => {
    dispatch(animalBrowserAction.toggleOverviewAnimalsListModal());
  }, [dispatch]);
  const createAnimalListFromIds = useCallback((name: string) => {
    dispatch(animalBrowserAction.createAnimalListFromIds(name, animalType));
  }, [dispatch, animalType]);
  const renameAnimalList = useCallback((id: number, name: string) => {
    dispatch(animalBrowserAction.renameAnimalList(id, name, animalType));
  }, [dispatch, animalType]);
  const deleteAnimalList = useCallback((id: number) => {
    dispatch(animalBrowserAction.deleteAnimalList(id, animalType));
  }, [dispatch, animalType]);
  const toggleOverwriteListFromIdsModalVisibility = useCallback((id: Opt<number>) => {
    dispatch(animalBrowserAction.toggleOverrideAnimalListFromIdsVisibility(id));
  }, [dispatch]);
  const confirmOverwriteAnimalListFromIds = useCallback((animalListId: number) => {
    dispatch(animalBrowserAction.confirmOverrideAnimalListFromIds(animalType, animalListId));
  }, [dispatch, animalType]);
  const toggleAddAnimalsModalVisibility = useCallback(() => {
    dispatch(animalBrowserAction.toggleAddAnimalsModal());
  }, [dispatch]);
  const createAnimalListFromIdsModalVisible = useSelector(simpleAnimalBrowserSelector.createAnimalListsFromIdsModalVisible);
  const overviewListModalVisible = useSelector(simpleAnimalBrowserSelector.overviewListModalVisible);
  const confirmListFromIdsNameModalVisible = useSelector(simpleAnimalBrowserSelector.overwriteListFromIdsNameModalVisible);
  const confirmAnimalLisFromIdsId = useSelector(simpleAnimalBrowserSelector.overwriteAnimalListFromIdsId);
  const selectedAnimalIds = useSelector(simpleAnimalBrowserSelector.selectedAnimalIds);
  const addAnimalsModalVisible = useSelector(simpleAnimalBrowserSelector.addAnimalsModalVisible);
  const availableAnimals = useSelector(simpleAnimalBrowserSelector.availableAnimals);
  const loading = useSelector(simpleAnimalBrowserSelector.loading);
  const addAnimalsFromDBModalVisible = useSelector(simpleAnimalBrowserSelector.addAnimalsFromDBModalVisible);
  const {
    t,
    tCommon
  } = useOurTranslation('comprehensiveOverview');
  return <Fragment>
            <ModalListName visible={createAnimalListFromIdsModalVisible} animalLists={animalsLists} isAnimalsListEmpty={isAnimalsPageEmpty || isEmpty(selectedAnimalIds)} onSubmit={createAnimalListFromIds} onCancel={toggleCreateAnimalListFromIdsModalVisibility} animalListEmptyMessage={t('atLeastOneAnimal')} data-sentry-element="ModalListName" data-sentry-source-file="index.tsx" />
            <ModalListConfirmName visible={confirmListFromIdsNameModalVisible} animalListId={confirmAnimalLisFromIdsId} onSubmit={confirmOverwriteAnimalListFromIds} onCancel={toggleOverwriteListFromIdsModalVisibility} loading={confirmAnimalListNameFromIdsLoading} listAlreadyExistsMessage={t('listAlreadyExists')} data-sentry-element="ModalListConfirmName" data-sentry-source-file="index.tsx" />
            <ModalAddAnimals title={t('addAnimals')} fieldLabel={tCommon('earTagTooltip')} submitLabel={tCommon('add')} visible={addAnimalsModalVisible} availableAnimals={availableAnimals} onSubmit={addAnimals} onCancel={toggleAddAnimalsModalVisibility} onSearch={getAvailableAnimals} loadingAvailableAnimals={loading.includes('availableAnimalsLoading')} setAvailableAnimalsLoading={setAvailableAnimalsLoading} data-sentry-element="ModalAddAnimals" data-sentry-source-file="index.tsx" />
            <ModalAddAnimalsFromDb visible={addAnimalsFromDBModalVisible} animalLists={animalsLists} onSubmit={addAnimalsFromDB} onCancel={toggleAddAnimalsFromDBVisibility} title={t('selectAnimalList')} submitLabel={tCommon('select')} selectLabel={t('animalList')} animalListsLoading={loading.includes('getAnimalLists')} data-sentry-element="ModalAddAnimalsFromDb" data-sentry-source-file="index.tsx" />
            <ModalListsOverview visible={overviewListModalVisible} animalLists={animalsLists} onCancel={toggleOverviewListModalVisibility} renameAnimalList={renameAnimalList} deleteAnimalList={deleteAnimalList} noListsSaved={t('noSavedLists')} title={t('managementOfSavedLists')} areYouSureToDeleteMessage={t('areYouSure')} listAlreadyExistsMessage={t('listAlreadyExists')} data-sentry-element="ModalListsOverview" data-sentry-source-file="index.tsx" />
        </Fragment>;
};
export const CabModals = memo(CabModalsBase);