import React, { FC, memo, useMemo } from 'react';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { UsersTable } from '../components/users-table';
const UsersBase: FC = _ => {
  const {
    t
  } = useOurTranslation('admin/users');
  const header = useMemo(() => <ScreenHeader title={t('pageTitle')} />, [t]);
  return <Content browserTabTitle={t('pageTitle')} data-sentry-element="Content" data-sentry-component="UsersBase" data-sentry-source-file="users.tsx">
            <Card title={header} titleSize="big" noPadding data-sentry-element="Card" data-sentry-source-file="users.tsx">
                <UsersTable data-sentry-element="UsersTable" data-sentry-source-file="users.tsx" />
            </Card>
        </Content>;
};
export const Users = memo(UsersBase);