import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select, fork, join} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {languageStore} from 'utils/language-store';
import {showBeError} from 'utils/show-be-error';

import {ChangeAndSaveLanguage, userAction} from '../action';
import {setLegacyLanguageCookie} from './saga-utils';

function* changeAndSaveLanguageExecute({payload: {language}}: ChangeAndSaveLanguage): SagaIterator {
    languageStore.set(language);

    const forkedSetLanguageLegacy = yield* fork(setLegacyLanguageCookie, language);

    const currentUser = yield* select(state => (state as State).user.currentUser);

    if (currentUser.nonEmpty) {
        const response = yield* call(Api.setLanguage, {language});

        if (!response.isSuccess) {
            yield putAll(showBeError(response, t('user/sagas')('languageChange')));
        }
    }

    yield* join(forkedSetLanguageLegacy);
    yield* put(userAction.setUiLanguage(language));
}

export function* changeAndSaveLanguageSaga(): SagaIterator {
    yield takeLatestF('user/CHANGE_AND_SAVE_LANGUAGE', changeAndSaveLanguageExecute);
}
