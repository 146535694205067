import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {matingAction, RemoveAnimalAction} from '../action';

const te = t('mating/sagas');
function* execute({payload: {sex, animalId, associationType}}: RemoveAnimalAction): SagaIterator {
    const response = yield* call(Api.removeAnimalFromList, {sex, animalId, associationType});

    const title = te('removeAnimal');

    if (response.isSuccess) {
        yield* put(showSuccess(title, te('animalRemoved'), false, 'remove-animal'));

        const getMatingBulls = associationType === 'FLECKVIEH'
            ? matingAction.getFleckviehMatingBulls
            : matingAction.getHolsteinMatingBulls;

        yield* sex === 'FEMALE'
            ? put(matingAction.getMatingCows(opt({action: 'refresh'})))
            : put(getMatingBulls(opt({action: 'refresh'})));

        yield* sex === 'FEMALE'
            ? put(matingAction.setFemaleSessionState('selection'))
            : put(matingAction.setMaleSessionState('selection'));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* removeAnimalSaga(): SagaIterator {
    yield takeLatestF('mating/REMOVE_ANIMAL', execute);
}
