import {Options} from '@fl/cmsch-fe-library';
import {createSelector} from 'reselect';
import {Opt} from 'ts-opt';

import {AnalysisProtocol} from 'api/gen/AnalysisProtocol';
import {ApcStableDate} from 'api/gen/ApcStableDate';
import {CorrectionReportDetail} from 'api/gen/CorrectionReportDetail';
import {CorrectionType} from 'api/gen/CorrectionType';
import {PagedModelCorrectionReport} from 'api/gen/PagedModelCorrectionReport';
import {TechnicianNumber} from 'api/gen/TechnicianNumber';
import {State} from 'app/setup';
import {formHelpers} from 'utils/forms';

import {apcReportFormName} from '../components/apc-report-form/form-values';
import {AnalysisProtocolCorrectionLoadingId} from '../types/analysis-protocol-correction-loading-id';

export const simpleAnalysisProtocolCorrectionSelector = {
    pageCorrectionReports: ({analysisProtocolCorrection}: State): Opt<PagedModelCorrectionReport> =>
        analysisProtocolCorrection.pageAnalysisProtocolCorrectionReports,
    loading: ({analysisProtocolCorrection}: State): Array<AnalysisProtocolCorrectionLoadingId> =>
        analysisProtocolCorrection.loading,
    correctionType: (state: State): CorrectionType | undefined => formHelpers.formValues(apcReportFormName)(state)
        .prop('correctionType').orUndef(),
    proficiencyTestDateOptions: ({analysisProtocolCorrection}: State): Opt<Options<string>> =>
        analysisProtocolCorrection.proficiencyTestDateOptions,
    stableOptions: ({analysisProtocolCorrection}: State): Opt<Options<string>> =>
        analysisProtocolCorrection.stableOptions,
    stableDateRelations: ({analysisProtocolCorrection}: State): Opt<ApcStableDate> =>
        analysisProtocolCorrection.apcStableDate,
    technicianOptions: ({analysisProtocolCorrection}: State): Opt<Options<TechnicianNumber>> =>
        analysisProtocolCorrection.technicianOptions,
    createdCorrectionReports: ({analysisProtocolCorrection}: State): Array<CorrectionReportDetail> =>
        analysisProtocolCorrection.createdCorrectionReports,
    editingCorrectionReportId: ({analysisProtocolCorrection}: State): number | undefined =>
        analysisProtocolCorrection.editingCorrectionReportId,
    analysisProtocol: ({analysisProtocolCorrection}: State): Opt<AnalysisProtocol> =>
        analysisProtocolCorrection.analysisProtocol,
    apcStableDate: ({analysisProtocolCorrection}: State): Opt<ApcStableDate> =>
        analysisProtocolCorrection.apcStableDate,
};

export const analysisProtocolCorrectionLoadingSelector = createSelector(
    simpleAnalysisProtocolCorrectionSelector.loading,
    loading => ({
        deleteAnalysisProtocolCorrectionReport: loading.includes('deleteAnalysisProtocolCorrectionReport'),
        exportingAnalysisProtocolCorrectionOverview: loading.includes('exportingAnalysisProtocolCorrectionOverview'),
        correctionReportDetailLoading: loading.includes('correctionReportDetailLoading'),
    }),
);

export const technicianOptionsLoadingSelector = createSelector(
    simpleAnalysisProtocolCorrectionSelector.loading,
    loading => loading.includes('technicianOptionsLoading'),
);

export const stableDateOptionsLoadingSelector = createSelector(
    simpleAnalysisProtocolCorrectionSelector.loading,
    loading => loading.includes('stableDateOptionsLoading'),
);

export const analysisProtocolLoadingSelector = createSelector(
    simpleAnalysisProtocolCorrectionSelector.loading,
    loading => loading.includes('analysisProtocolLoading'),
);
