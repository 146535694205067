import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {GetCriteriaListsAction, matingAction} from '../action';

function* execute({payload: {bookmark, associationType, withoutLoading}}: GetCriteriaListsAction): SagaIterator {
    if (!withoutLoading) yield* put(matingAction.setLoading('criteriaLists', true));

    const response = yield* call(Api.getAllCriteriaLists, {bookmark, associationType});

    const title = t('mating/sagas')('loadCriteriaLists');

    if (response.isSuccess) {
        yield* put(matingAction.getCriteriaListsSuccess(response.data, bookmark));
    } else {
        yield putAll(showBeError(response, title));
    }

    if (!withoutLoading) yield* put(matingAction.setLoading('criteriaLists', false));
}

export function* getCriteriaListsSaga(): SagaIterator {
    yield takeLatestF('mating/GET_CRITERIA_LISTS', execute);
}
