import {takeLatestF, putAll, extractFormErrorsFromResponse, showInfo} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {BreedingFemaleFilter} from 'api/gen/BreedingFemaleFilter';
import {FilterCabFemalesAction} from 'app/animal-browser/model/action';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {cabFilterFormName} from '../../types/cab-filter-form-values';
import {updateCabFemalesSessionAfterFilter} from './update-cab-females-session-saga-utils';

const tt = t('animalBrowserSagas');

function* execute({payload}: FilterCabFemalesAction): SagaIterator {
    yield* put(formHelpers.startSubmit(cabFilterFormName));
    const values = (yield* select(formHelpers.formValues(cabFilterFormName)));
    const filterCriteria: BreedingFemaleFilter = {
        breederIds: values.prop('breederIds').orNull(),
        districtCodes: values.prop('districtCodes').orNull(),
        farmIds: values.prop('farmIds').orNull(),
        organizationInsIds: values.prop('organizationInsIds').orNull(),
        organizationPtIds: values.prop('organizationPtIds').orNull(),
        organizationCenterInsIds: values.prop('organizationCenterInsIds').orNull(),
        organizationCenterPtIds: values.prop('organizationCenterPtIds').orNull(),
        stableIds: values.prop('stableIds').orNull(),
    };

    const response = yield* call(Api.filterCabBreedingFemales, filterCriteria);

    if (response.isSuccess) {
        const {areAnimalsInSession} = response.data;
        yield* put(formHelpers.stopSubmit(cabFilterFormName));
        yield* call(updateCabFemalesSessionAfterFilter, areAnimalsInSession, payload);
        if (!areAnimalsInSession) yield* put(showInfo(tt('loadCabFemalesList'), tt('zeroCabFemalesMatchTheFilter')));
    } else {
        yield putAll(showBeError(response, tt('cabFemalesFiltering')));
        yield* put(formHelpers.stopSubmitUnsafe(cabFilterFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* filterCabFemalesSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/FILTER_CAB_FEMALES', execute);
}
