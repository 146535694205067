import { LoaderWrapper } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { Opt } from 'ts-opt';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { useForm } from 'utils/forms';
import { matingParamsSelectionFormName, MatingLoadingId } from '../../types';
import { actionPanelRowGutter, formSectionsRowGutter, initialValues, labelCol, wrapperCol, submitButtonLabel, rightFormSide, leftFormSide, middleFormArea } from './params-helpers';
import styles from './styles.sass';
interface Props {
  criteriaDefinitions: Opt<Array<CriteriaDefinition>>;
  loading: Array<MatingLoadingId>;
  criteriaListsEmpty: boolean;
  animalsAvailableInSession: boolean;
  onSubmit(): void;
  toggleSaveCriteriaListVisibility(): void;
  toggleLoadCriteriaListVisibility(): void;
  toggleCriteriaListOverviewVisibility(): void;
}

// eslint-disable-next-line max-lines-per-function
const MatingParamsSelectionFormBase: FC<Props> = props => {
  const {
    criteriaDefinitions,
    loading,
    criteriaListsEmpty,
    animalsAvailableInSession,
    onSubmit,
    toggleSaveCriteriaListVisibility,
    toggleLoadCriteriaListVisibility,
    toggleCriteriaListOverviewVisibility
  } = props;
  const {
    Form,
    Fields,
    valid,
    submitting,
    reset
  } = useForm({
    form: matingParamsSelectionFormName,
    initialValues,
    onSubmit
  }, {
    layout: 'horizontal',
    labelAlign: 'left',
    labelCol,
    wrapperCol,
    labelWrap: true
  });
  const {
    t,
    tCommon
  } = useOurTranslation('mating/matingParamsSelectionForm');
  const parmsForm = useMemo(() => <Ant.Row gutter={formSectionsRowGutter}>
            {leftFormSide(criteriaDefinitions, Fields, t)}
            {middleFormArea(criteriaDefinitions, Fields, t)}
            {rightFormSide(criteriaDefinitions, Fields, t)}
        </Ant.Row>, [criteriaDefinitions, Fields, t]);
  const calculateScoreButton = useMemo(() => <Ant.Col xs={24} lg={6}>
            <Button type="submit" disabled={!valid || !animalsAvailableInSession || submitting} title={submitButtonLabel(valid, animalsAvailableInSession, t)} loading={submitting} visuals="primary" block>
                {t('calculateScore')}
            </Button>
        </Ant.Col>, [animalsAvailableInSession, submitting, t, valid]);
  const loadCriteriaFromListButton = useMemo(() => <Ant.Col xs={24} lg={6}>
            <Button onClick={toggleLoadCriteriaListVisibility} loading={loading.includes('criteriaLists')} disabled={criteriaListsEmpty} title={criteriaListsEmpty ? t('noListAvailable') : undefined} block>
                {t('loadCriteriaFromList')}
            </Button>
        </Ant.Col>, [criteriaListsEmpty, loading, t, toggleLoadCriteriaListVisibility]);
  const saveCriteriaListButton = useMemo(() => <Ant.Col xs={24} lg={6}>
            <Button onClick={toggleSaveCriteriaListVisibility} disabled={!valid} title={!valid ? t('formIsInvalid') : undefined} block>
                {t('saveCriteriaList')}
            </Button>
        </Ant.Col>, [t, toggleSaveCriteriaListVisibility, valid]);
  const manageSavedListsButton = useMemo(() => <Ant.Col xs={24} lg={6}>
            <Button onClick={toggleCriteriaListOverviewVisibility} loading={loading.includes('criteriaLists')} block>
                {t('manageSavedLists')}
            </Button>
        </Ant.Col>, [loading, t, toggleCriteriaListOverviewVisibility]);
  return useMemo(() => <span className={styles.container}>
            <Form>
                {submitting && <LoaderWrapper loading background stretch size="large" text={t('scoreIsBeingCalculated')} />}
                <div className={styles.buttonWrapper}>
                    <Button onClick={reset} danger>{tCommon('resetSettings')}</Button>
                </div>
                {parmsForm}
                <Ant.Row justify="center" gutter={actionPanelRowGutter} className="mt-3 mb-3">
                    {calculateScoreButton}
                    {loadCriteriaFromListButton}
                    {saveCriteriaListButton}
                    {manageSavedListsButton}
                </Ant.Row>
            </Form>
        </span>, [Form, submitting, t, tCommon, reset, parmsForm, calculateScoreButton, loadCriteriaFromListButton, saveCriteriaListButton, manageSavedListsButton]);
};
export const MatingParamsSelectionForm = memo(MatingParamsSelectionFormBase);