// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-mating-components-animals-form-containter-___styles__container___Ql0Kd{display:grid}.src-app-mating-components-animals-form-containter-___styles__container___Ql0Kd .ant-form{width:100%;overflow:hidden}@media (max-width: 1199px){.src-app-mating-components-animals-form-containter-___styles__container___Ql0Kd{margin-left:3%;margin-right:24%}}@media (max-width: 991px){.src-app-mating-components-animals-form-containter-___styles__container___Ql0Kd{margin-left:3%;margin-right:3%}}@media (max-width: 575px){.src-app-mating-components-animals-form-containter-___styles__container___Ql0Kd{margin-left:2px;margin-right:2px}.src-app-mating-components-animals-form-containter-___styles__container___Ql0Kd .ant-form-horizontal .form-group .ant-form-item-has-error .ant-form-item-label-wrap label{margin-bottom:0!important}}
`, "",{"version":3,"sources":["webpack://./src/app/mating/components/animals-form-containter/styles.sass"],"names":[],"mappings":"AAAA,gFAAW,YAAA,CAAa,0FAA6B,UAAA,CAAW,eAAA,CAAgB,2BAA0B,gFAAW,cAAA,CAAe,gBAAA,CAAA,CAAkB,0BAAyB,gFAAW,cAAA,CAAe,eAAA,CAAA,CAAiB,0BAAyB,gFAAW,eAAA,CAAgB,gBAAA,CAAiB,0KAA6G,yBAAA,CAAA","sourcesContent":[".container{display:grid}.container :global .ant-form{width:100%;overflow:hidden}@media(max-width: 1199px){.container{margin-left:3%;margin-right:24%}}@media(max-width: 991px){.container{margin-left:3%;margin-right:3%}}@media(max-width: 575px){.container{margin-left:2px;margin-right:2px}.container :global .ant-form-horizontal .form-group .ant-form-item-has-error .ant-form-item-label-wrap label{margin-bottom:0 !important}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-app-mating-components-animals-form-containter-___styles__container___Ql0Kd`
};
export default ___CSS_LOADER_EXPORT___;
