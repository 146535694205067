import { Options, FooterButtons } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { head } from 'ts-opt';
import { matingSelector } from 'app/mating/model';
import { useOurTranslation } from 'app/translations';
import { useForm } from 'utils/forms';
import { getCriteriaListDetailsFormName, GetCriteriaListDetailsFormValues } from './get-criteria-list-details-form-values';
import { validate } from './validate';
interface Props {
  listsOptions: Options<number>;
  onSubmit(values: GetCriteriaListDetailsFormValues): void;
  onCancel(): void;
}
const GetCriteriaListDetailsFormBase: FC<Props> = props => {
  const {
    listsOptions,
    onSubmit,
    onCancel
  } = props;
  const {
    t,
    tCommon
  } = useOurTranslation('mating/modalGetCriteriaDetails');
  const initialValues = useMemo((): GetCriteriaListDetailsFormValues => ({
    criteriaListId: head(listsOptions).prop('value').orNull()
  }), [listsOptions]);
  const {
    Form,
    Fields,
    submitting,
    valid
  } = useForm({
    form: getCriteriaListDetailsFormName,
    initialValues,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate,
    onSubmit
  });
  const criteriaListLoading = useSelector(matingSelector.loading).includes('criteriaLists');
  return <Form data-sentry-element="Form" data-sentry-component="GetCriteriaListDetailsFormBase" data-sentry-source-file="form.tsx">
            <Fields.NumberSelect name="criteriaListId" label={t('criteriaList')} options={listsOptions} disabled={submitting} autoFocus fieldIsLoading={criteriaListLoading} touchOnChange data-sentry-element="unknown" data-sentry-source-file="form.tsx" />
            <FooterButtons valid={valid} submitting={submitting} submitTitle={tCommon('load')} onCancel={onCancel} data-sentry-element="FooterButtons" data-sentry-source-file="form.tsx" />
        </Form>;
};
export const GetCriteriaListDetailsForm = memo(GetCriteriaListDetailsFormBase);