import {takeLatestF, putAll, downloadFileFromResponse, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {formHelpers} from 'utils/forms';

import {exportAnimalsFormName} from '../../components/export-animals-form/export-animals-form-values';
import {matingAction, GetAnimalsCsvAction} from '../action';

const defaultFileName = 'export-zvirata';

function* execute(action: GetAnimalsCsvAction): SagaIterator {
    yield putAll(formHelpers.startSubmit(exportAnimalsFormName));
    const {sex, fileName = defaultFileName, associationType} = action.payload;

    const response = yield* call(Api.exportAnimalsInList, {fileName}, {sex, associationType});

    if (response.isSuccess) {
        yield* put(matingAction.toggleAnimalListsVisibility(sex));
        yield* call(downloadFileFromResponse, response);
        yield putAll(formHelpers.stopSubmit(exportAnimalsFormName));
    } else {
        yield putAll(formHelpers.stopSubmit(exportAnimalsFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* getAnimalsCsvSaga(): SagaIterator {
    yield takeLatestF('mating/GET_ANIMALS_CSV', execute);
}
