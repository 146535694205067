// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1200px){.src-app-mating-components-reset-all-settings-button-___styles__resetAllButton___pWCCT{margin-right:17px}}
`, "",{"version":3,"sources":["webpack://./src/app/mating/components/reset-all-settings-button/styles.sass"],"names":[],"mappings":"AAAA,2BAA0B,uFAAgB,iBAAA,CAAA","sourcesContent":["@media(min-width: 1200px){.resetAllButton{margin-right:17px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetAllButton": `src-app-mating-components-reset-all-settings-button-___styles__resetAllButton___pWCCT`
};
export default ___CSS_LOADER_EXPORT___;
