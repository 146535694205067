import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {SearchOtherSubjectsAction, userDetailAction} from '../action';

function* execute({payload}: SearchOtherSubjectsAction): SagaIterator {
    const response = yield* call(Api.searchOtherSubjects, {text: payload});

    if (response.isSuccess) {
        yield* put(userDetailAction.setOtherSubjectsOptions(response.data));
    } else {
        yield putAll(showBeError(response, t('admin/users')('otherSubjectsLoading')));
    }
}

export function* searchOtherSubjectsSaga(): SagaIterator {
    yield takeLatestF('userDetail/SEARCH_OTHER_ASSOCIATIONS', execute);
}
