import {optEmptyString} from 'ts-opt';

import {BreedingFemale} from 'api/gen/BreedingFemale';
import {CabBreedingFemale} from 'api/gen/CabBreedingFemale';
import {CabCalf} from 'api/gen/CabCalf';

export type AnimalBrowserLinkProps = Pick<BreedingFemale, 'huid' | 'earTag'>;

export const getAnimalBrowserLink = ({
    earTag,
    huid,
}: AnimalBrowserLinkProps | CabBreedingFemale | CabCalf): string =>
    `https://test.plemdat.cz/krava/krava?&povel=Hledej&USCIS=${earTag ?? ''}\
${optEmptyString(huid).map(x => `&HUID=${x}`).orElse('')}`;

export const getAnimalBrowserLinkForTable = (
    _id: number | string,
    row: AnimalBrowserLinkProps | CabBreedingFemale | CabCalf,
): string => getAnimalBrowserLink(row);

