import { Loader } from '@fl/cmsch-fe-library';
import React, { FC, memo } from 'react';
import { Content } from 'app/content';
import { UserDetailWrapper } from 'app/user-detail/components/user-detail-wrapper';
import { useProfileSetup } from '../hooks/use-profile-setup';
const ProfileBase: FC = () => {
  const {
    associationsProps,
    breedersProps,
    contactsProps,
    memberOfSubjectsProps,
    organizationsProps,
    otherSubjectsProps,
    t,
    tCommon,
    user
  } = useProfileSetup();
  return user.caseOf(u => <Content browserTabTitle={t('pageTitle')}>
                <UserDetailWrapper user={u} breedersProps={breedersProps} associationsProps={associationsProps} contactsProps={contactsProps} memberOfSubjectsProps={memberOfSubjectsProps} organizationsProps={organizationsProps} otherSubjectsProps={otherSubjectsProps} />
            </Content>, () => <Content browserTabTitle={tCommon('pageTitle')}>
                <Loader show size="large" text={tCommon('loading')} />
            </Content>);
};
export const Profile = memo(ProfileBase);