import { ConfirmButton } from '@fl/cmsch-fe-library';
import React, { FC, memo, useMemo } from 'react';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button, UploadButton } from 'common/buttons';
const actionPanelRowGutter = 8;
interface Props {
  getAnimalListsLoading: boolean;
  deleteAnimalsLoading: boolean;
  disableSaveList: boolean;
  disableSelectList: boolean;
  onAnimalsCsvUploadClick(file: File): void;
  onSaveListClick(): void;
  onAddAnimalsClick(): void;
  onSelectListClick(): void;
  onManageListsClick(): void;
  onDeleteAnimalsClick(): void;
}

// eslint-disable-next-line max-lines-per-function
const CABButtonsBase: FC<Props> = ({
  getAnimalListsLoading,
  deleteAnimalsLoading,
  onAnimalsCsvUploadClick,
  onSaveListClick,
  onAddAnimalsClick,
  onSelectListClick,
  onManageListsClick,
  onDeleteAnimalsClick,
  disableSaveList,
  disableSelectList
}) => {
  const {
    t
  } = useOurTranslation('comprehensiveOverview');
  const addAnimalsButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={onAddAnimalsClick} testId="addAnimals" block>
                {t('addAnimals')}
            </Button>
        </Ant.Col>, [t, onAddAnimalsClick]);
  const addAnimalsFromFileButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <UploadButton id="createListFromFile" title={t('addAnimalsFromFile')} fileTypes=".csv" uploadFile={onAnimalsCsvUploadClick} block testId="addAnimalsFromFile" />
        </Ant.Col>, [onAnimalsCsvUploadClick, t]);
  const selectListButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={onSelectListClick} block testId="selectListsButton" disabled={disableSelectList}>
                {t('selectList')}
            </Button>
        </Ant.Col>, [t, onSelectListClick, disableSelectList]);
  const saveListButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={onSaveListClick} block testId="saveList" disabled={disableSaveList}>
                {t('saveList')}
            </Button>
        </Ant.Col>, [t, onSaveListClick, disableSaveList]);
  const manageSavedListsButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={onManageListsClick} block testId="manageSavedLists" loading={getAnimalListsLoading}>
                {t('manageLists')}
            </Button>
        </Ant.Col>, [t, onManageListsClick, getAnimalListsLoading]);
  const deleteAnimalsButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <ConfirmButton onClick={onDeleteAnimalsClick} block testId="deleteAnimals" loading={deleteAnimalsLoading}>
                {t('deleteAnimals')}
            </ConfirmButton>
        </Ant.Col>, [t, onDeleteAnimalsClick, deleteAnimalsLoading]);
  return <Ant.Row justify="center" gutter={[actionPanelRowGutter, actionPanelRowGutter]} className="mt-4 mb-3" data-sentry-element="unknown" data-sentry-component="CABButtonsBase" data-sentry-source-file="index.tsx">
            {addAnimalsButton}
            {addAnimalsFromFileButton}
            {selectListButton}
            {saveListButton}
            {manageSavedListsButton}
            {deleteAnimalsButton}
        </Ant.Row>;
};
export const CABButtons = memo(CABButtonsBase);