// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-milkability-components-milkability-report-form-___styles__disabledHover___DtId9 .ant-picker .ant-input-affix-wrapper:hover{border-color:#d9d9d9}@media (min-width: 1200px){.src-app-milkability-components-milkability-report-form-___styles__reportForm___RrGyo{display:grid!important;grid-template-columns:repeat(17,1fr)}.src-app-milkability-components-milkability-report-form-___styles__reportForm___RrGyo>div{min-height:106px}.src-app-milkability-components-milkability-report-form-___styles__reportForm___RrGyo>.ant-col{max-width:100%!important;grid-column:span 2}.src-app-milkability-components-milkability-report-form-___styles__reportForm___RrGyo>.ant-col:nth-child(3){grid-column:span 3}.src-app-milkability-components-milkability-report-form-___styles__reportForm___RrGyo>.ant-col:nth-child(4){grid-column:span 3}.src-app-milkability-components-milkability-report-form-___styles__reportForm___RrGyo>.ant-col:nth-child(6){grid-column:span 1}}@media (max-width: 576px){.src-app-milkability-components-milkability-report-form-___styles__reportForm___RrGyo{max-width:98%;margin:auto!important}}
`, "",{"version":3,"sources":["webpack://./src/app/milkability/components/milkability-report-form/styles.sass"],"names":[],"mappings":"AAAA,oIAAkE,oBAAA,CAAqB,2BAA0B,sFAA4B,sBAAA,CAAwB,oCAAA,CAAsC,0FAAgC,gBAAA,CAAiB,+FAAqC,wBAAA,CAA0B,kBAAA,CAAmB,4GAAkD,kBAAA,CAAmB,4GAAkD,kBAAA,CAAmB,4GAAkD,kBAAA,CAAA,CAAoB,0BAAyB,sFAAY,aAAA,CAAnkB,qBAAA,CAAA","sourcesContent":[".disabledHover :global .ant-picker .ant-input-affix-wrapper:hover{border-color:#d9d9d9}@media(min-width: 1200px){:global :local(.reportForm){display:grid !important;grid-template-columns:repeat(17, 1fr)}:global :local(.reportForm)>div{min-height:106px}:global :local(.reportForm)>.ant-col{max-width:100% !important;grid-column:span 2}:global :local(.reportForm)>.ant-col:nth-child(3){grid-column:span 3}:global :local(.reportForm)>.ant-col:nth-child(4){grid-column:span 3}:global :local(.reportForm)>.ant-col:nth-child(6){grid-column:span 1}}@media(max-width: 576px){.reportForm{max-width:98%;margin:auto !important}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabledHover": `src-app-milkability-components-milkability-report-form-___styles__disabledHover___DtId9`,
	"reportForm": `src-app-milkability-components-milkability-report-form-___styles__reportForm___RrGyo`
};
export default ___CSS_LOADER_EXPORT___;
