import {initialNotificationState} from '@fl/cmsch-fe-library';

import {initialAdminState} from 'app/admin/model/state';
import {initialAnalysisProtocolCorrectionState} from 'app/analysis-protocol-correction/model/state';
import {initialAnimalBrowserState} from 'app/animal-browser/model/state';
import {initialLegacyState} from 'app/legacy/model/state';
import {initialMatingState} from 'app/mating/model/state';
import {initialMilkabilityState} from 'app/milkability/model/state';
import {initialSystemState} from 'app/system/model/state';
import {initialUserDetailState} from 'app/user-detail/model/state';
import {initialUserState} from 'app/user/model/state';
import {initialLayoutState} from 'common/layout/model/state';
import {initialTablesState} from 'utils/tables';

import {State} from './state';

export const defaultState: State = {
    tables: initialTablesState,
    user: initialUserState,
    admin: initialAdminState,
    legacy: initialLegacyState,
    mating: initialMatingState,
    animalBrowser: initialAnimalBrowserState,
    milkability: initialMilkabilityState,
    analysisProtocolCorrection: initialAnalysisProtocolCorrectionState,
    system: initialSystemState,
    layout: initialLayoutState,
    userDetail: initialUserDetailState,
    toastr: initialNotificationState,
    form: {},
    router: {},
};
