import {TableColumn} from '@fl/cmsch-fe-library';

import {MemberOfSubjectsTableType} from '../../types/member-of-subjects-table-type';

export const memberOfSubjectColumns: Array<TableColumn<MemberOfSubjectsTableType>> = [
    {
        field: 'code',
        type: 'string',
    },
    {
        field: 'businessName',
        type: 'string',
    },
    {
        field: 'approvedFrom',
        type: 'date',
        dateFormat: 'date',
    },
    {
        field: 'approvedTo',
        type: 'date',
        dateFormat: 'date',
        filterTypes: ['null'],
    },
];
