import {cloneDeep, includes} from 'lodash/fp';

import {CriteriaListDetails} from 'api/gen/CriteriaListDetails';
import {logger} from 'app/sentry-logger';

import {Criterias} from '../types/criterias';
import {
    MatingParamsSelectionFormValues,
    isMatingParamsCoefficient,
    isMatingParamsScoringWeights,
    isMatingParamsExteriorTraitWeights,
} from '../types/mating-params-selection-form-values';

// eslint-disable-next-line no-magic-numbers
const numberRangePercentages = [1, 5, 10, 20, 30] as const;

type NumberRangePercentage = typeof numberRangePercentages[number];

const isValidNumberRangePercentage = (x: number): x is NumberRangePercentage =>
    includes(x, numberRangePercentages);

// eslint-disable-next-line max-lines-per-function
export const getCriteriaFormValues = (criterias: Criterias, criteriaListDetails: CriteriaListDetails): Criterias => {
    const criteriaFormValues = cloneDeep(criterias);

    // eslint-disable-next-line max-lines-per-function
    criteriaListDetails.criteria.forEach(item => {
        if (item.tag === 'BOOL') {
            const currentFormValue = criteriaFormValues.radios[item.code];
            if (currentFormValue !== undefined) {
                criteriaFormValues.radios[item.code] = item.value;
            } else {
                logger.logError(new Error(`loaded RADIO criteria '${item.code}' not present in form`));
            }
        } else if (item.tag === 'DATE') {
            const currentFormValue = criteriaFormValues.dateRanges[item.code];
            if (currentFormValue !== undefined) {
                criteriaFormValues.dateRanges[item.code] = [
                    item.values.leftValue,
                    item.values.rightValue,
                ];
            } else {
                logger.logError(new Error(`loaded DATE criteria '${item.code}' not present in form`));
            }
        } else if (item.tag === 'SINGLE_INT') {
            const currentFormValue = criteriaFormValues.numberRanges[item.code];
            if (currentFormValue !== undefined) {
                const percentage = item.value;
                if (percentage && isValidNumberRangePercentage(percentage)) {
                    criteriaFormValues.numberRanges[item.code] = {
                        minimum: currentFormValue.minimum,
                        maximum: currentFormValue.maximum,
                        percentage,
                        selectedType: 'percentage',
                        criteriaType: currentFormValue.criteriaType,
                    };
                } else {
                    const err = `loaded SINGLE_INT criteria '${item.code}' has invalid percent ${String(percentage)}`;
                    logger.logError(new Error(err));
                }
            } else {
                logger.logError(new Error(`loaded SINGLE_INT criteria '${item.code}' not present in form`));
            }
        } else if (
            item.tag === 'INT'
            || item.tag === 'DECIMAL_3_1'
            || item.tag === 'DECIMAL_4_3'
            || item.tag === 'DECIMAL_5_2'
        ) {
            const currentFormValue = criteriaFormValues.numberRanges[item.code];
            if (currentFormValue !== undefined) {
                if (currentFormValue.criteriaType === item.tag) {
                    criteriaFormValues.numberRanges[item.code] = {
                        minimum: item.values.leftValue,
                        maximum: item.values.rightValue,
                        percentage: currentFormValue.percentage,
                        selectedType: 'number',
                        criteriaType: currentFormValue.criteriaType,
                    };
                } else {
                    const err = `loaded ${item.tag} criteria '${item.code}'` +
                        ` does not match default type ${currentFormValue.criteriaType}`;
                    logger.logError(new Error(err));
                }
            } else {
                logger.logError(new Error(`loaded ${item.tag} criteria '${item.code}' not present in form`));
            }
        }
    });

    return criteriaFormValues;
};

// eslint-disable-next-line max-lines-per-function
export const getComputationParamsFormValues = (
    formValues: MatingParamsSelectionFormValues,
    criteriaListDetails: CriteriaListDetails,
): MatingParamsSelectionFormValues => {
    const updatedFormValues = cloneDeep(formValues);

    criteriaListDetails.criteria.forEach(item => {
        if (isMatingParamsCoefficient(item.code)) {
            if (item.tag === 'SINGLE_DECIMAL_4_3') {
                updatedFormValues[item.code] = item.value;
            } else {
                logger.logError(new Error(`Code ${item.code} has wrong type`));
            }
        } else if (isMatingParamsScoringWeights(item.code)) {
            if (item.tag === 'SINGLE_INT') {
                updatedFormValues[item.code] = item.value;
            } else {
                logger.logError(new Error(`Code ${item.code} has wrong type`));
            }
        } else if (isMatingParamsExteriorTraitWeights(item.code)) {
            if (item.tag === 'INT') {
                updatedFormValues[item.code] = [
                    item.values.leftValue,
                    item.values.rightValue,
                ];
            } else {
                logger.logError(new Error(`Code ${item.code} has wrong type`));
            }
        } else {
            logger.logError(new Error(`Code ${item.code} is not present in form`));
        }
    });

    return updatedFormValues;
};
