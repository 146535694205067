/* eslint-disable import/no-unused-modules */
import {Options, TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {AnimalList} from 'api/gen/AnimalList';
import {AnimalType} from 'api/gen/AnimalType';
import {AnimalsBrowserFarm} from 'api/gen/AnimalsBrowserFarm';
import {AnimalsBrowserStable} from 'api/gen/AnimalsBrowserStable';
import {AvailableAnimal} from 'api/gen/AvailableAnimal';
import {BreedingFemale} from 'api/gen/BreedingFemale';
import {CabBreedingFemale} from 'api/gen/CabBreedingFemale';
import {CabCalf} from 'api/gen/CabCalf';
import {Calf} from 'api/gen/Calf';
import {PagedModelBreedingFemale} from 'api/gen/PagedModelBreedingFemale';
import {PagedModelCabBreedingFemale} from 'api/gen/PagedModelCabBreedingFemale';
import {PagedModelCabCalf} from 'api/gen/PagedModelCabCalf';
import {PagedModelCalf} from 'api/gen/PagedModelCalf';
import {PagedModelStableToChoose} from 'api/gen/PagedModelStableToChoose';
import {StableToChoose} from 'api/gen/StableToChoose';

import {BrowserLoadingId} from '../types/browser-loading-id';
import {BrowserType} from '../types/browser-type';

export const animalBrowserAction = {
    getStablesTableData: (tableDataRequest: Opt<TableDataRequest<StableToChoose>>) => ({
        type: 'animalBrowser/GET_STABLES_DATA',
        payload: {tableDataRequest},
    }) as const,
    getStablesTableDataSuccess: (tableData: Opt<PagedModelStableToChoose>) => ({
        type: 'animalBrowser/GET_STABLES_DATA_SUCCESS',
        payload: tableData,
    }) as const,
    getcowBrowserTableData: (tableDataRequest: Opt<TableDataRequest<BreedingFemale>>) => ({
        type: 'animalBrowser/GET_COW_BROWSER_DATA',
        payload: {tableDataRequest},
    }) as const,
    getcowBrowserTableDataSuccess: (tableData: Opt<PagedModelBreedingFemale>) => ({
        type: 'animalBrowser/GET_COW_BROWSER_DATA_SUCCESS',
        payload: tableData,
    }) as const,
    setStableName: (stableInfo: AnimalsBrowserStable) => ({
        type: 'animalBrowser/SET_STABLE_NAME',
        payload: stableInfo,
    }) as const,
    clearStableName: () => ({
        type: 'animalBrowser/CLEAR_STABLE_NAME',
    }) as const,
    exportToCsv: () => ({
        type: 'animalBrowser/EXPORT_TO_CSV',
    }) as const,
    getCalfBrowserTableData: (tableDataRequest: Opt<TableDataRequest<Calf>>) => ({
        type: 'animalBrowser/GET_CALF_BROWSER_DATA',
        payload: {tableDataRequest},
    }) as const,
    getCalfBrowserTableDataSuccess: (tableData: Opt<PagedModelCalf>) => ({
        type: 'animalBrowser/GET_CALF_BROWSER_DATA_SUCCESS',
        payload: tableData,
    }) as const,
    setFarmName: (farmInfo: AnimalsBrowserFarm) => ({
        type: 'animalBrowser/SET_FARM_NAME',
        payload: farmInfo,
    }) as const,
    clearFarmName: () => ({
        type: 'animalBrowser/CLEAR_FARM_NAME',
    }) as const,
    getCalvesCsv: () => ({
        type: 'animalBrowser/GET_CALVES_CSV',
    }) as const,
    getProductionAreasInCentralEvidence: () => ({
        type: 'animalBrowser/GET_PRODUCTION_AREAS',
    }) as const,
    getProductionAreasInCentralEvidenceSuccess: (productionAreasInCentralEvidenceOptions: Options<string>) => ({
        type: 'animalBrowser/GET_PRODUCTION_AREAS_SUCCESS',
        payload: {productionAreasInCentralEvidenceOptions},
    }) as const,
    startExport: () => ({
        type: 'animalBrowser/START_EXPORT',
    }) as const,
    exportFinished: () => ({
        type: 'animalBrowser/EXPORT_FINISHED',
    }) as const,
    getStablesCsv: () => ({
        type: 'animalBrowser/GET_STABLES_CSV',
    }) as const,
    getCabFemales: (tableDataRequest: Opt<TableDataRequest<CabBreedingFemale>>,
    ) => ({
        type: 'animalBrowser/GET_CAB_FEMALES',
        payload: {tableDataRequest},
    }) as const,
    getCabFemalesSuccess: (tableData: Opt<PagedModelCabBreedingFemale>) => ({
        type: 'animalBrowser/GET_CAB_FEMALES_SUCCESS',
        payload: {tableData},
    }) as const,
    filterCabFemales: (firstLoad?: 'FIRST_LOAD') => ({
        type: 'animalBrowser/FILTER_CAB_FEMALES',
        payload: firstLoad,
    }) as const,
    setCabFemalesExistInSession: (isFull: boolean) => ({
        type: 'animalBrowser/SET_CAB_FEMALES_EXIST',
        payload: {isFull},
    }) as const,
    getCabCalves: (tableDataRequest: Opt<TableDataRequest<CabCalf>>) => ({
        type: 'animalBrowser/GET_CAB_CALVES',
        payload: {tableDataRequest},
    }) as const,
    getCabCalvesSucces: (tableData: Opt<PagedModelCabCalf>) => ({
        type: 'animalBrowser/GET_CAB_CALVES_SUCCESS',
        payload: {tableData},
    }) as const,
    setCabCalvesExistInSession: (isFull: boolean) => ({
        type: 'animalBrowser/SET_CAB_CALVES_EXIST',
        payload: {isFull},
    }) as const,
    setLoading: (id: BrowserLoadingId, loading: boolean) => ({
        type: 'animalBrowser/SET_LOADING',
        payload: {id, loading},
    }) as const,
    exportCabAnimals: (animalType: AnimalType) => ({
        type: 'animalBrowser/EXPORT_CAB_ANIMALS',
        payload: {animalType},
    }) as const,
    filterCabCalves: (firstLoad?: 'FIRST_LOAD') => ({
        type: 'animalBrowser/FILTER_CAB_CALVES',
        payload: firstLoad,
    }) as const,
    getOrganizationPtOptions: (searchingText: string) => ({
        type: 'animalBrowser/GET_ORGANIZATIONS_PT_OPTIONS',
        payload: searchingText,
    }) as const,
    getOrganizationsPtSuccess: (organizationsPtOptions: Options<number>) => ({
        type: 'animalBrowser/GET_ORGANIZATIONS_PT_OPTIONS_SUCCESS',
        payload: organizationsPtOptions,
    }) as const,
    getOrganizationInsOptions: (searchingText: string) => ({
        type: 'animalBrowser/GET_ORGANIZATION_INS_OPTIONS',
        payload: searchingText,
    }) as const,
    getOrganizationInsOptionsSuccess: (organizationInsOptions: Options<number>) => ({
        type: 'animalBrowser/GET_ORGANIZATION_INS_OPTIONS_SUCCESS',
        payload: organizationInsOptions,
    }) as const,
    getBreederOptions: (searchingText: string) => ({
        type: 'animalBrowser/GET_BREEDER_OPTIONS',
        payload: searchingText,
    }) as const,
    getBreederOptionsSuccess: (breederOptions: Options<number>) => ({
        type: 'animalBrowser/GET_BREEDER_OPTIONS_SUCCESS',
        payload: breederOptions,
    }) as const,
    getDistrictCodesOptions: (searchingText: string) => ({
        type: 'animalBrowser/GET_DISTRICT_CODES_OPTIONS',
        payload: searchingText,
    }) as const,
    getDistrictCodesOptionsSuccess: (districtCodesOptions: Options<number>) => ({
        type: 'animalBrowser/GET_DISTRICT_CODES_OPTIONS_SUCCESS',
        payload: districtCodesOptions,
    }) as const,
    getFarmOptions: (searchingText: string) => ({
        type: 'animalBrowser/GET_FARM_OPTIONS',
        payload: searchingText,
    }) as const,
    getFarmOptionsSuccess: (farmOptions: Options<number>) => ({
        type: 'animalBrowser/GET_FARM_OPTIONS_SUCCESS',
        payload: farmOptions,
    }) as const,
    getStableOptions: (searchingText: string) => ({
        type: 'animalBrowser/GET_STABLE_OPTIONS',
        payload: searchingText,
    }) as const,
    getStableOptionsSuccess: (stableOptions: Options<number>) => ({
        type: 'animalBrowser/GET_STABLE_OPTIONS_SUCCESS',
        payload: stableOptions,
    }) as const,
    getOrganizationCenterInsOptions: (searchingText: string) => ({
        type: 'animalBrowser/GET_ORGANIZATION_CENTER_INS_OPTIONS',
        payload: searchingText,
    }) as const,
    getOrganizationCenterInsOptionsSuccess: (organizationInsOptions: Options<number>) => ({
        type: 'animalBrowser/GET_ORGANIZATION_CENTER_INS_OPTIONS_SUCCESS',
        payload: organizationInsOptions,
    }) as const,
    getOrganizationCenterPtOptions: (searchingText: string) => ({
        type: 'animalBrowser/GET_ORGANIZATION_CENTER_PT_OPTIONS',
        payload: searchingText,
    }) as const,
    getOrganizationCenterPtOptionsSuccess: (organizationInsOptions: Options<number>) => ({
        type: 'animalBrowser/GET_ORGANIZATION_CENTER_PT_OPTIONS_SUCCESS',
        payload: organizationInsOptions,
    }) as const,
    clearData: () => ({
        type: 'animalBrowser/CLEAR_DATA',
    }) as const,
    firstCalveLoad: (firstLoad?: 'FIRST_LOAD') => ({
        type: 'animalBrowser/FIRST_CALVE_LOAD',
        payload: firstLoad === 'FIRST_LOAD',
    }) as const,
    firstBreedingFemaleLoad: (firstLoad?: 'FIRST_LOAD') => ({
        type: 'animalBrowser/FIRST_BREEDING_FEMALE_LOAD',
        payload: firstLoad === 'FIRST_LOAD',
    }) as const,
    toggleCreateAnimalsListModal: () => ({
        type: 'animalBrowser/TOGGLE_CREATE_ANIMALS_LIST_MODAL',
    }) as const,
    toggleOverviewAnimalsListModal: () => ({
        type: 'animalBrowser/TOGGLE_OVERVIEW_ANIMALS_LIST_MODAL',
    }) as const,
    toggleOverrideAnimalListVisibility: (animalListId: Opt<number>) => ({
        type: 'animalBrowser/TOGGLE_OVERRIDE_ANIMALS_LIST_MODAL',
        payload: {animalListId},
    }) as const,
    getAnimalLists: (animalType: AnimalType, withoutLoading?: boolean) => ({
        type: 'animalBrowser/GET_ANIMAL_LISTS',
        payload: {animalType, withoutLoading},
    }) as const,
    getAnimalListsSuccess: (animalLists: Array<AnimalList>, animalType: AnimalType) => ({
        type: 'animalBrowser/GET_ANIMAL_LISTS_SUCCESS',
        payload: {animalLists, animalType},
    }) as const,
    renameAnimalList: (animalListId: number, name: string, animalType: AnimalType) => ({
        type: 'animalBrowser/RENAME_ANIMAL_LIST',
        payload: {animalListId, name, animalType},
    }) as const,
    deleteAnimalList: (animalListId: number, animalType: AnimalType) => ({
        type: 'animalBrowser/DELETE_ANIMAL_LIST',
        payload: {animalListId, animalType},
    }) as const,
    createAnimalList: (name: string, animalType: AnimalType) => ({
        type: 'animalBrowser/CREATE_ANIMAL_LIST',
        payload: {name, animalType},
    }) as const,
    confirmOverrideAnimalList: (animalType: AnimalType, animalListId: number) => ({
        type: 'animalBrowser/CONFIRM_OVERRIDE_ANIMAL_LIST',
        payload: {animalType, animalListId},
    }) as const,
    createAnimalListFromIds: (name: string, animalType: AnimalType) => ({
        type: 'animalBrowser/CREATE_ANIMAL_LIST_FROM_IDS',
        payload: {name, animalType},
    }) as const,
    toggleCreateAnimalsListFromIdsModal: () => ({
        type: 'animalBrowser/TOGGLE_CREATE_ANIMALS_LIST_FROM_IDS_MODAL',
    }) as const,
    toggleOverrideAnimalListFromIdsVisibility: (animalListId: Opt<number>) => ({
        type: 'animalBrowser/TOGGLE_OVERRIDE_ANIMALS_LIST_FROM_IDS_MODAL',
        payload: {animalListId},
    }) as const,
    confirmOverrideAnimalListFromIds: (animalType: AnimalType, animalListId: number) => ({
        type: 'animalBrowser/CONFIRM_OVERRIDE_ANIMAL_LIST_FROM_IDS',
        payload: {animalType, animalListId},
    }) as const,
    setSelectedAnimalIds: (animalIds: Array<number>) => ({
        type: 'animalBrowser/SET_SELECTED_ANIMAL_IDS',
        payload: {animalIds},
    }) as const,
    toggleAddAnimalsModal: () => ({
        type: 'animalBrowser/TOGGLE_ADD_ANIMALS_MODAL',
    }) as const,
    getAvailableAnimals: (animalType: AnimalType, text: string) => ({
        type: 'animalBrowser/GET_AVAILABLE_ANIMALS',
        payload: {animalType, text},
    }) as const,
    getAvailableAnimalsSuccess: (availableAnimals: Array<AvailableAnimal>) => ({
        type: 'animalBrowser/GET_AVAILABLE_ANIMALS_SUCCESS',
        payload: {availableAnimals},
    }) as const,
    addAnimals: (animalType: AnimalType, animalIds: Array<number>) => ({
        type: 'animalBrowser/ADD_ANIMALS',
        payload: {animalType, animalIds},
    }) as const,
    addAnimalsFromCsv: (animalType: AnimalType, file: File) => ({
        type: 'animalBrowser/ADD_ANIMALS_FROM_CSV',
        payload: {animalType, file},
    }) as const,
    toggleAddAnimalsFromDBVisibility: () => ({
        type: 'animalBrowser/TOGGLE_ADD_ANIMALS_FROM_DB_VISIBILITY',
    }) as const,
    addAnimalsFromDB: (animalType: AnimalType, animalListId: number) => ({
        type: 'animalBrowser/ADD_ANIMALS_FROM_DB',
        payload: {animalType, animalListId},
    }) as const,
    getAnimalsCount: (animalType: AnimalType) => ({
        type: 'animalBrowser/GET_ANIMALS_COUNT',
        payload: {animalType},
    }) as const,
    deleteAnimals: (animalType: AnimalType) => ({
        type: 'animalBrowser/DELETE_ANIMALS',
        payload: {animalType},
    }) as const,
    getMotherHuid: (earTag: string, huid: string, animalType: AnimalType, comprehensive: BrowserType) => ({
        type: 'animalBrowser/GET_MOTHER_HUID',
        payload: {earTag, huid, animalType, comprehensive},
    }) as const,
};

export type GetStablesDataAction = ReturnType<typeof animalBrowserAction.getStablesTableData>;
export type GetStablesDataSuccessAction = ReturnType<typeof animalBrowserAction.getStablesTableDataSuccess>;
export type GetCowBrowserTableDataAction = ReturnType<typeof animalBrowserAction.getcowBrowserTableData>;
export type GetCowBrowserTableDataSuccessAction = ReturnType<typeof animalBrowserAction.getcowBrowserTableDataSuccess>;
export type SetStableNameAction = ReturnType<typeof animalBrowserAction.setStableName>;
export type ClearStableNameAction = ReturnType<typeof animalBrowserAction.clearStableName>;
export type ExportToCsvAction = ReturnType<typeof animalBrowserAction.exportToCsv>;
export type GetCalfBrowserTableDataAction = ReturnType<typeof animalBrowserAction.getCalfBrowserTableData>;
export type GetCalfBrowserTableDataSuccessAction =
    ReturnType<typeof animalBrowserAction.getCalfBrowserTableDataSuccess>;
export type SetFarmNameAction = ReturnType<typeof animalBrowserAction.setFarmName>;
export type ClearFarmNameAction = ReturnType<typeof animalBrowserAction.clearFarmName>;
export type GetCalvesCsvAction = ReturnType<typeof animalBrowserAction.getCalvesCsv>;
export type GetProductionAreasInCentralEvidenceAction =
    ReturnType<typeof animalBrowserAction.getProductionAreasInCentralEvidence>;
export type GetProductionAreasInCentralEvidenceSuccessAction =
    ReturnType<typeof animalBrowserAction.getProductionAreasInCentralEvidenceSuccess>;
export type StartExportAction = ReturnType<typeof animalBrowserAction.startExport>;
export type ExportFinishedAction = ReturnType<typeof animalBrowserAction.exportFinished>;
export type GetStablesCsvAction = ReturnType<typeof animalBrowserAction.getStablesCsv>;
export type GetCabFemalesAction = ReturnType<typeof animalBrowserAction.getCabFemales>;
export type GetCabFemalesSuccessAction = ReturnType<typeof animalBrowserAction.getCabFemalesSuccess>;
export type FilterCabFemalesAction = ReturnType<typeof animalBrowserAction.filterCabFemales>;
export type FilterCabCalvesAction = ReturnType<typeof animalBrowserAction.filterCabCalves>;
export type SetCabFemalesExistAction = ReturnType<typeof animalBrowserAction.setCabFemalesExistInSession>;
export type GetCabCalvesAction = ReturnType<typeof animalBrowserAction.getCabCalves>;
export type GetCabCalvesSuccessAction = ReturnType<typeof animalBrowserAction.getCabCalvesSucces>;
export type SetCabCalvesExistInSessionAction = ReturnType<typeof animalBrowserAction.setCabCalvesExistInSession>;
export type AnimalBrowserSetLoadingAction = ReturnType<typeof animalBrowserAction.setLoading>;
export type ExportCabAnimalsAction = ReturnType<typeof animalBrowserAction.exportCabAnimals>;
export type GetOrganizationPtOptionsAction = ReturnType<typeof animalBrowserAction.getOrganizationPtOptions>;
export type GetOrganizationPtOptionsSuccessAction = ReturnType<typeof animalBrowserAction.getOrganizationsPtSuccess>;
export type GetOrganizationInsOptionsAction = ReturnType<typeof animalBrowserAction.getOrganizationInsOptions>;
export type GetOrganizationInsOptionsSuccessAction =
    ReturnType<typeof animalBrowserAction.getOrganizationInsOptionsSuccess>;
export type GetBreederOptionsAction = ReturnType<typeof animalBrowserAction.getBreederOptions>;
export type GetBreederOptionsSuccessAction = ReturnType<typeof animalBrowserAction.getBreederOptionsSuccess>;
export type GetDistrictCodesOptionsAction = ReturnType<typeof animalBrowserAction.getDistrictCodesOptions>;
export type GetDistrictCodesOptionsSuccessAction =
    ReturnType<typeof animalBrowserAction.getDistrictCodesOptionsSuccess>;
export type GetFarmOptionsAction = ReturnType<typeof animalBrowserAction.getFarmOptions>;
export type GetFarmOptionsSuccessAction = ReturnType<typeof animalBrowserAction.getFarmOptionsSuccess>;
export type GetStableOptionsAction = ReturnType<typeof animalBrowserAction.getStableOptions>;
export type GetStableOptionsSuccessAction = ReturnType<typeof animalBrowserAction.getStableOptionsSuccess>;
export type GetOrganizationCenterInsOptionsAction =
    ReturnType<typeof animalBrowserAction.getOrganizationCenterInsOptions>;
export type GetOrganizationCenterInsOptionsSuccessAction =
    ReturnType<typeof animalBrowserAction.getOrganizationCenterInsOptionsSuccess>;
export type GetOrganizationCenterPtOptionsAction =
    ReturnType<typeof animalBrowserAction.getOrganizationCenterPtOptions>;
export type GetOrganizationCenterPtOptionsSuccessAction =
    ReturnType<typeof animalBrowserAction.getOrganizationCenterPtOptionsSuccess>;
export type ClearDataAction = ReturnType<typeof animalBrowserAction.clearData>;
export type FirstBreedingFemaleAction = ReturnType<typeof animalBrowserAction.firstBreedingFemaleLoad>;
export type FirstCalveLoadAction = ReturnType<typeof animalBrowserAction.firstCalveLoad>;
export type ToggleCreateAnimalsListModalAction = ReturnType<typeof animalBrowserAction.toggleCreateAnimalsListModal>;
export type ToggleOverviewAnimalsListModalAction =
    ReturnType<typeof animalBrowserAction.toggleOverviewAnimalsListModal>;
export type GetAnimalListsAction = ReturnType<typeof animalBrowserAction.getAnimalLists>;
export type GetAnimalListsSuccessAction = ReturnType<typeof animalBrowserAction.getAnimalListsSuccess>;
export type RenameAnimalListAction = ReturnType<typeof animalBrowserAction.renameAnimalList>;
export type DeleteAnimalListAction = ReturnType<typeof animalBrowserAction.deleteAnimalList>;
export type CreateAnimalListAction = ReturnType<typeof animalBrowserAction.createAnimalList>;
export type ToggleOverrideAnimalListVisibilityAction =
    ReturnType<typeof animalBrowserAction.toggleOverrideAnimalListVisibility>;
export type ConfirmOverrideAnimalListAction = ReturnType<typeof animalBrowserAction.confirmOverrideAnimalList>;
export type CreateAnimalListFromIdsAction = ReturnType<typeof animalBrowserAction.createAnimalListFromIds>;
export type ToggleCreateAnimalsListFromIdsModalAction =
    ReturnType<typeof animalBrowserAction.toggleCreateAnimalsListFromIdsModal>;
export type ToggleOverrideAnimalListFromIdsVisibilityAction =
    ReturnType<typeof animalBrowserAction.toggleOverrideAnimalListFromIdsVisibility>;
export type ConfirmOverrideAnimalListFromIdsAction =
    ReturnType<typeof animalBrowserAction.confirmOverrideAnimalListFromIds>;
export type SetSelectedAnimalIdsAction = ReturnType<typeof animalBrowserAction.setSelectedAnimalIds>;
export type ToggleAddAnimalsModalAction = ReturnType<typeof animalBrowserAction.toggleAddAnimalsModal>;
export type GetAvailableAnimalsAction = ReturnType<typeof animalBrowserAction.getAvailableAnimals>;
export type GetAvailableAnimalsSuccessAction = ReturnType<typeof animalBrowserAction.getAvailableAnimalsSuccess>;
export type AddAnimalsAction = ReturnType<typeof animalBrowserAction.addAnimals>;
export type AddAnimalsFromCsvAction = ReturnType<typeof animalBrowserAction.addAnimalsFromCsv>;
export type ToggleAddAnimalsFromDBVisibilityAction =
    ReturnType<typeof animalBrowserAction.toggleAddAnimalsFromDBVisibility>;
export type AddAnimalsFromDBAction = ReturnType<typeof animalBrowserAction.addAnimalsFromDB>;
export type GetAnimalsCountAction = ReturnType<typeof animalBrowserAction.getAnimalsCount>;
export type DeleteAnimalsAction = ReturnType<typeof animalBrowserAction.deleteAnimals>;
export type GetMotherHuidAction = ReturnType<typeof animalBrowserAction.getMotherHuid>;

export type AnimalBrowserAction =
    | GetStablesDataAction
    | GetCowBrowserTableDataAction
    | GetCowBrowserTableDataSuccessAction
    | SetStableNameAction
    | ClearStableNameAction
    | ExportToCsvAction
    | GetStablesDataSuccessAction
    | GetCalfBrowserTableDataAction
    | GetCalfBrowserTableDataSuccessAction
    | SetFarmNameAction
    | ClearFarmNameAction
    | GetCalvesCsvAction
    | GetProductionAreasInCentralEvidenceAction
    | GetProductionAreasInCentralEvidenceSuccessAction
    | StartExportAction
    | ExportFinishedAction
    | GetStablesCsvAction
    | GetCabFemalesAction
    | GetCabFemalesSuccessAction
    | FilterCabFemalesAction
    | FilterCabCalvesAction
    | SetCabFemalesExistAction
    | GetCabCalvesAction
    | GetCabCalvesSuccessAction
    | SetCabCalvesExistInSessionAction
    | ExportCabAnimalsAction
    | AnimalBrowserSetLoadingAction
    | GetOrganizationPtOptionsAction
    | GetOrganizationInsOptionsAction
    | GetBreederOptionsAction
    | GetDistrictCodesOptionsAction
    | GetFarmOptionsAction
    | GetStableOptionsAction
    | GetOrganizationPtOptionsSuccessAction
    | GetOrganizationInsOptionsSuccessAction
    | GetBreederOptionsSuccessAction
    | GetDistrictCodesOptionsSuccessAction
    | GetFarmOptionsSuccessAction
    | GetStableOptionsSuccessAction
    | GetOrganizationCenterInsOptionsAction
    | GetOrganizationCenterPtOptionsAction
    | GetOrganizationCenterInsOptionsSuccessAction
    | GetOrganizationCenterPtOptionsSuccessAction
    | ClearDataAction
    | FirstBreedingFemaleAction
    | FirstCalveLoadAction
    | ToggleCreateAnimalsListModalAction
    | ToggleOverviewAnimalsListModalAction
    | GetAnimalListsAction
    | GetAnimalListsSuccessAction
    | RenameAnimalListAction
    | DeleteAnimalListAction
    | CreateAnimalListAction
    | ToggleOverrideAnimalListVisibilityAction
    | ConfirmOverrideAnimalListAction
    | CreateAnimalListFromIdsAction
    | ToggleCreateAnimalsListFromIdsModalAction
    | ToggleOverrideAnimalListFromIdsVisibilityAction
    | ConfirmOverrideAnimalListFromIdsAction
    | SetSelectedAnimalIdsAction
    | ToggleAddAnimalsModalAction
    | GetAvailableAnimalsAction
    | GetAvailableAnimalsSuccessAction
    | AddAnimalsAction
    | AddAnimalsFromCsvAction
    | ToggleAddAnimalsFromDBVisibilityAction
    | AddAnimalsFromDBAction
    | GetAnimalsCountAction
    | DeleteAnimalsAction
    | GetMotherHuidAction
    ;
