import {Association} from 'api/gen/Association';
import {BasicRole} from 'api/gen/BasicRole';
import {Breeder} from 'api/gen/Breeder';
import {Farm} from 'api/gen/Farm';
import {Organization} from 'api/gen/Organization';
import {OrganizationCenter} from 'api/gen/OrganizationCenter';
import {OtherSubject} from 'api/gen/OtherSubject';
import {Subject} from 'api/gen/Subject';

import {UserDetailLoadingId} from '../types/user-detail-loading-id';

export interface UserDetailState {
    memberOfSubjectsEditingId: number | null;
    memberOfSubjectsModalVisible: boolean;
    subjectOptions: Array<Subject>;
    userContactsModalVisible: boolean;
    associationsModalVisible: string | null;
    facilityModalVisible: string | null;
    associationOptions: Array<Association>;
    organizationOptions: Array<Organization>;
    centerOptions: Array<OrganizationCenter>;
    otherSubjectRoleOptions: Array<BasicRole>;
    associationRoleOptions: Array<BasicRole>;
    breederRoleOptions: Array<BasicRole>;
    organizationRoleOptions: Array<BasicRole>;
    otherSubjectsOptions: Array<OtherSubject>;
    breederOptions: Array<Breeder>;
    farmOptions: Array<Farm>;
    organizationFarmOptions: Array<Farm>;
    loading: Array<UserDetailLoadingId>;
}

export const initialUserDetailState: UserDetailState = {
    facilityModalVisible: null,
    memberOfSubjectsEditingId: null,
    memberOfSubjectsModalVisible: false,
    subjectOptions: [],
    associationOptions: [],
    userContactsModalVisible: false,
    associationsModalVisible: null,
    organizationOptions: [],
    centerOptions: [],
    otherSubjectRoleOptions: [],
    associationRoleOptions: [],
    breederRoleOptions: [],
    organizationRoleOptions: [],
    otherSubjectsOptions: [],
    breederOptions: [],
    farmOptions: [],
    loading: [],
    organizationFarmOptions: [],
};
