import React, { FC, memo, useMemo } from 'react';
import { Field as ReduxFormField } from 'redux-form';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button, UploadButton } from 'common/buttons';
import { SaveCriteriasButton } from '../../utils';
const actionPanelRowGutter = 8;
interface Props {
  valid: boolean;
  submitting: boolean;
  criteriaListsEmpty: boolean;
  animalListsEmpty: boolean;
  isAnimalsPageEmpty: boolean;
  handleCowsCsvUpload(file: File): void;
  toggleAnimalListsVisibility(): void;
  handleDeleteCowsFromList(): void;
  toggleAddAnimalsVisibility(): void;
  toggleAddAnimalsFromDBVisibility(): void;
  toggleListsOverviewVisibility(): void;
  toggleCriteriaListsVisibility(): void;
  toggleCriteriaListDetailsVisibility(): void;
}

// eslint-disable-next-line max-lines-per-function
const BottomButtonsBase: FC<Props> = props => {
  const {
    valid,
    submitting,
    criteriaListsEmpty,
    animalListsEmpty,
    isAnimalsPageEmpty,
    handleCowsCsvUpload,
    toggleAnimalListsVisibility,
    handleDeleteCowsFromList,
    toggleAddAnimalsVisibility,
    toggleAddAnimalsFromDBVisibility,
    toggleListsOverviewVisibility,
    toggleCriteriaListsVisibility,
    toggleCriteriaListDetailsVisibility
  } = props;
  const {
    t
  } = useOurTranslation('mating/matingCowsFilterForm');
  const filterCowsButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button type="submit" visuals="primary" disabled={!valid || submitting} loading={submitting} title={valid ? t('actionRewritesAnimalList') : t('formIsInvalid')} block testId="filterCows">
                {t('filter')}
            </Button>
        </Ant.Col>, [submitting, t, valid]);
  const addCowsButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleAddAnimalsVisibility} testId="addCows" block>{t('addCows')}</Button>
        </Ant.Col>, [t, toggleAddAnimalsVisibility]);
  const addCowsFromFileButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <UploadButton id="addCowsFromFile" title={t('addCowsFromFile')} fileTypes=".csv" uploadFile={handleCowsCsvUpload} block testId="addCowsFromFile" />
        </Ant.Col>, [handleCowsCsvUpload, t]);
  const addCowsFromListButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleAddAnimalsFromDBVisibility} disabled={animalListsEmpty} title={animalListsEmpty ? t('activeWithAtLeastOneCowList') : ''} block testId="addCowsFromList">
                {t('addCowsFromList')}
            </Button>
        </Ant.Col>, [animalListsEmpty, t, toggleAddAnimalsFromDBVisibility]);
  const saveCowsList = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleAnimalListsVisibility} disabled={isAnimalsPageEmpty} title={isAnimalsPageEmpty ? t('activeWithAtLeastOneAddedCow') : ''} block testId="saveCowsList">
                {t('saveCowsList')}
            </Button>
        </Ant.Col>, [isAnimalsPageEmpty, t, toggleAnimalListsVisibility]);
  const deleteCows = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={handleDeleteCowsFromList} disabled={isAnimalsPageEmpty} title={isAnimalsPageEmpty ? t('activeWithAtLeastOneAddedCow') : ''} block testId="deleteCows">
                {t('deleteCows')}
            </Button>
        </Ant.Col>, [handleDeleteCowsFromList, isAnimalsPageEmpty, t]);
  const loadCriteriaFromList = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleCriteriaListDetailsVisibility} disabled={criteriaListsEmpty} title={criteriaListsEmpty ? t('activeWithAtLeastOneCriteriaList') : ''} block testId="loadCriteria">
                {t('loadCriteriaFromList')}
            </Button>
        </Ant.Col>, [criteriaListsEmpty, t, toggleCriteriaListDetailsVisibility]);
  const saveCriteriasButton = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <ReduxFormField name="criterias" component={SaveCriteriasButton} title={t('saveCriterias')} toggleCriteriaListsVisibility={toggleCriteriaListsVisibility} />
        </Ant.Col>, [t, toggleCriteriaListsVisibility]);
  const manageSavedLists = useMemo(() => <Ant.Col xs={24} md={8} xxl={4}>
            <Button onClick={toggleListsOverviewVisibility} block testId="manageCowLists">
                {t('manageSavedLists')}
            </Button>
        </Ant.Col>, [t, toggleListsOverviewVisibility]);
  return <Ant.Row justify="center" gutter={[actionPanelRowGutter, actionPanelRowGutter]} className="mt-3 mb-3 mx-2" data-sentry-element="unknown" data-sentry-component="BottomButtonsBase" data-sentry-source-file="bottom-buttons.tsx">
            {filterCowsButton}
            {addCowsButton}
            {addCowsFromFileButton}
            {addCowsFromListButton}
            {saveCowsList}
            {deleteCows}
            {loadCriteriaFromList}
            {saveCriteriasButton}
            {manageSavedLists}
        </Ant.Row>;
};
export const BottomButtons = memo(BottomButtonsBase);