import {Options} from '@fl/cmsch-fe-library';
import {opt} from 'ts-opt';

import {CabFarmAutocomplete} from 'api/gen/CabFarmAutocomplete';
import {CabStableAutocomplete} from 'api/gen/CabStableAutocomplete';
import {Organization} from 'api/gen/Organization';

export const genStableOptions = (items: Array<CabStableAutocomplete>): Options<number> =>
    items.map(item => {
        const businessName = opt(item.subjectBusinessName).map(x => ` (${x})`).orElse('');
        const name = opt(item.name).map(x => ` - ${x}`).orElse('');

        return {
            value: item.id,
            label: `${item.code}${name}${businessName}`,
        };
    });

export const genFarmOptions = (items: Array<CabFarmAutocomplete>): Options<number> =>
    items.map(item => {
        const businessName = opt(item.subjectBusinessName).map(x => ` (${x})`).orElse('');
        const name = opt(item.name).map(x => ` - ${x}`).orElse('');

        return {
            value: item.id,
            label: `${item.code}${name}${businessName}`,
        };
    });

export const genOrganizationOptions = (items: Array<Organization>): Options<number> =>
    items.map(item => ({
        value: item.id,
        label: `${item.code}${item.name ? ` - ${item.name}` : ''}`,
    }));
