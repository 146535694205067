import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {uniqBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {simpleAnimalBrowserSelector} from 'app/animal-browser/model/selector';
import {cabFilterFormName} from 'app/animal-browser/types/cab-filter-form-values';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {genCentersOptions} from '../../utils/gen-centers-options';
import {animalBrowserAction, GetOrganizationCenterPtOptionsAction} from '../action';

const tt = t('comprehensiveOverview');

function* execute({payload}: GetOrganizationCenterPtOptionsAction): SagaIterator {
    const selectedValues = (yield* select(formHelpers.formValues(cabFilterFormName)))
        .prop('organizationCenterPtIds').orElse([]);
    const oldOptions = (yield* select(simpleAnimalBrowserSelector.organizationsCenterPtOptions)).orElse([]);
    const selectedOptions = oldOptions.filter(option => selectedValues.includes(option.value));

    yield* put(animalBrowserAction.setLoading('organizationCenterPtSearch', true));

    const response = yield* call(
        Api.getCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteList,
        {text: payload},
    );
    if (response.isSuccess) {
        yield* put(animalBrowserAction.getOrganizationCenterPtOptionsSuccess(
            uniqBy('value', [...selectedOptions, ...genCentersOptions(response.data)]),
        ));
    } else {
        yield putAll(showBeError(response, tt('filter')));
    }

    yield* put(animalBrowserAction.setLoading('organizationCenterPtSearch', false));
}
export function* getOrganizationCenterPtOptionsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/GET_ORGANIZATION_CENTER_PT_OPTIONS', execute);
}
