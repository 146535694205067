import {takeLatestF, putAll, extractFormErrorsFromResponse, showInfo} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {CalfFilter} from 'api/gen/CalfFilter';
import {FilterCabCalvesAction} from 'app/animal-browser/model/action';
import {cabFilterFormName} from 'app/animal-browser/types/cab-filter-form-values';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {updateCabCalvesSessionAfterFilter} from './update-cab-calves-session-saga-utils';

const tt = t('animalBrowserSagas');

function* execute({payload}: FilterCabCalvesAction): SagaIterator {
    yield* put(formHelpers.startSubmit(cabFilterFormName));
    const values = (yield* select(formHelpers.formValues(cabFilterFormName)));
    const filterCriteria: CalfFilter = {
        breederIds: values.prop('breederIds').orNull(),
        districtCodes: values.prop('districtCodes').orNull(),
        farmIds: values.prop('farmIds').orNull(),
        organizationInsIds: values.prop('organizationInsIds').orNull(),
        organizationPtIds: values.prop('organizationPtIds').orNull(),
        organizationCenterInsIds: values.prop('organizationCenterInsIds').orNull(),
        organizationCenterPtIds: values.prop('organizationCenterPtIds').orNull(),
    };

    const response = yield* call(Api.filterCabCalves, filterCriteria);
    if (response.isSuccess) {
        const {areAnimalsInSession} = response.data;
        yield* put(formHelpers.stopSubmit(cabFilterFormName));
        yield* call(updateCabCalvesSessionAfterFilter, areAnimalsInSession, payload);
        if (!areAnimalsInSession) yield* put(showInfo(tt('loadCabCalvesList'), tt('zeroCabCalvesMatchTheFilter')));
    } else {
        yield putAll(showBeError(response, tt('cabCalvesFiltering')));
        yield* put(formHelpers.stopSubmitUnsafe(cabFilterFormName, extractFormErrorsFromResponse(response)));
    }
}
export function* filterCabCalvesSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/FILTER_CAB_CALVES', execute);
}
