import {ActionSettings, Options, TableDataRequest, ToolbarButton, debounceTime} from '@fl/cmsch-fe-library';
import {debounce, isEmpty} from 'lodash/fp';
import {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {BasicRole} from 'api/gen/BasicRole';
import {Breeder} from 'api/gen/Breeder';
import {Farm} from 'api/gen/Farm';
import {PagedModelBreederRole} from 'api/gen/PagedModelBreederRole';
import {State} from 'app/setup';
import {TFunction, useOurTranslation} from 'app/translations';
import {Errors} from 'utils/validator';

import {createValidate} from '../components/breeders-tab/validate';
import {allOptionValue, defaultProps, thirdLevel} from '../constants/consts';
import {userDetailAction} from '../model/action';
import {
    userBreederOptionsSelector,
    userFarmOptionsSelector,
    simpleUserDetailSelector,
    breederRoleOptionsSelector,
} from '../model/selector';
import {BreederRoleTableType} from '../types/breeder-role-table-type';
import {BreedersTabProps} from '../types/breeders-tab-props';
import {CreateOrEditOrNull} from '../types/create-or-edit-or-null';
import {FarmsSettings} from '../types/farms-settings';
import {SubjectFacilityFormEntryData, SubjectFacilityFormValues} from '../types/subject-facility-form-values';
import {getMatchingApprovedValues} from '../utils/get-matching-approved-values';

const breederForm = 'breederForm';

interface UseBreedersTabSetup {
    headerButtons: Array<ToolbarButton>;
    actionSettings: ActionSettings<BreederRoleTableType>;
    visibleModal: string | null;
    roleOptions: Options<number>;
    breederOptions: Options<number>;
    formDataForInit: SubjectFacilityFormEntryData | null;
    actionMode: CreateOrEditOrNull;
    rowForEdit: BreederRoleTableType | null;
    breederForm: string;
    pathnamesForRefresh: Array<RegExp>;
    t: TFunction<'userDetail'>;
    userProfileId: number;
    isFromAdminModule: boolean;
    farmsSettings: FarmsSettings;
    handleOpenCreateModal(): void;
    getTableData(tableDataRequest: Opt<TableDataRequest<BreederRoleTableType>>): void;
    handleSubmitModal(): void;
    handleOrganizationSearch(searchText: string): void;
    handleCloseModal(): void;
    validateDebounced(): void;
    validate(values: SubjectFacilityFormValues): Errors<SubjectFacilityFormValues>;
    breedersSelector(state: State): Opt<PagedModelBreederRole>;
}

const getFormDataForInit = (
    rowForEdit: BreederRoleTableType,
    userProfileId: number,
): SubjectFacilityFormEntryData => {
    const matchingApprovedValues = getMatchingApprovedValues(rowForEdit);

    return {
        userProfileId,
        subjectId: rowForEdit.breederId,
        userProfileRoleIds: rowForEdit.userProfileBreederRoleIds,
        roleIds: rowForEdit.roleIds,
        farmIds: rowForEdit.accessToAllFarms ? [allOptionValue] : rowForEdit.farmIds,
        approvedFrom: matchingApprovedValues.approvedFrom,
        approvedTo: matchingApprovedValues.approvedTo,
        centerIds: null,
    };
};

// eslint-disable-next-line max-lines-per-function
export const useBreedersTabSetup = ({
    getTableData,
    pathnamesForRefresh,
    selector,
    adminProps,
}: BreedersTabProps): UseBreedersTabSetup => {
    const isFromAdminModule = !isEmpty(adminProps);

    const {canUpdate, userProfileId, createEntry, updateEntry} = isFromAdminModule ? adminProps : defaultProps;

    const dispatch = useDispatch();
    const {t} = useOurTranslation('userDetail');

    const [actionMode, setActionMode] = useState<CreateOrEditOrNull>(null);
    const [rowForEdit, setRowForEdit] = useState<BreederRoleTableType | null>(null);

    const visibleModal = useSelector(simpleUserDetailSelector.facilityModalVisible);
    const roleOptions = useSelector(breederRoleOptionsSelector);
    const breederOptions = useSelector(userBreederOptionsSelector);
    const farmOptions = useSelector(userFarmOptionsSelector);

    const farmsAreEmpty = useMemo(() => isEmpty(farmOptions), [farmOptions]);
    const validate = useMemo(() => createValidate(!farmsAreEmpty, rowForEdit !== null), [farmsAreEmpty, rowForEdit]);

    const formDataForInit = useMemo(() => {
        if (rowForEdit && userProfileId) {
            return getFormDataForInit(rowForEdit, userProfileId);
        }
        return null;
    }, [rowForEdit, userProfileId]);

    const handleSubmitModal = useCallback(() => actionMode === 'edit' ? updateEntry?.() : createEntry?.(),
        [actionMode, createEntry, updateEntry]);

    const handleOpenEditModal = useCallback((_rowId: string, row: BreederRoleTableType) => {
        const breeders: Array<Breeder> = [
            {
                id: row.breederId,
                registrationNumber: row.breederRegistrationNumber,
                subjectName: row.subjectName,
            },
        ];
        const farms: Array<Farm> = row.roles.flatMap(
            role => role.farms?.map(farm => ({
                id: farm.farmId,
                code: farm.farmCode,
                name: farm.farmName || '',
            })) ?? [],
        );
        const roles: Array<BasicRole> = row.roles.map((r): BasicRole => ({
            id: r.roleId,
            name: r.roleName,
            code: r.roleCode,
        }));
        setActionMode('edit');
        dispatch(userDetailAction.setFacilityModalVisible(breederForm));
        dispatch(userDetailAction.setBreeders(breeders));
        dispatch(userDetailAction.setFarms(farms));
        dispatch(userDetailAction.setBreederRoleOptions(roles));
        setRowForEdit(row);
    }, [dispatch]);

    const handleOpenCreateModal = useCallback(() => {
        setActionMode('create');
        dispatch(userDetailAction.setFacilityModalVisible(breederForm));
        setRowForEdit(null);
    }, [dispatch]);

    const handleCloseModal = useCallback(() => {
        setActionMode(null);
        dispatch(userDetailAction.setFacilityModalVisible(null));
        dispatch(userDetailAction.setBreeders([]));
        dispatch(userDetailAction.setBreederRoleOptions([]));
        dispatch(userDetailAction.setFarms([]));
        setRowForEdit(null);
    }, [dispatch]);

    const handleOrganizationSearch = useCallback((searchText: string): void => {
        dispatch(userDetailAction.searchBreeders(searchText));
    }, [dispatch]);

    const getFarms = useCallback((subjectId: Opt<number>): void => {
        subjectId.onSome(id => dispatch(userDetailAction.getFarms(id)));
    }, [dispatch]);

    const validateData = useCallback(() => {
        dispatch(userDetailAction.validateBreeder());
    }, [dispatch]);

    const validateDebounced = useMemo(() => debounce(debounceTime)(validateData), [validateData]);

    const headerButtons: Array<ToolbarButton> = useMemo(() => isFromAdminModule ? [
        {
            id: 'createRole',
            icon: 'plusOutlined',
            onClick: handleOpenCreateModal,
            title: t('addBreeder'),
            visuals: 'primary',
            hidden: !canUpdate,
        },
    ] : [], [handleOpenCreateModal, t, canUpdate, isFromAdminModule]);

    const actionSettings: ActionSettings<BreederRoleTableType> = useMemo(() => canUpdate
        ? {
            extraActions: [
                {
                    id: 'edit',
                    role: 'edit',
                    callback: handleOpenEditModal,
                    hidden: (_id: string, row: BreederRoleTableType) => row.level === thirdLevel,
                },
            ],
        }
        : {}, [handleOpenEditModal, canUpdate]);

    const farmsSettings: FarmsSettings = useMemo(() => ({
        allFarmTitle: t('allFarms'),
        farmOptions,
        farmsAreEmpty,
        farmTitle: t('farms'),
        isRequired: !farmsAreEmpty,
        getFarms,
    }), [farmsAreEmpty, farmOptions, getFarms, t]);

    return {
        getTableData,
        handleSubmitModal,
        handleOrganizationSearch,
        handleOpenCreateModal,
        handleCloseModal,
        visibleModal,
        roleOptions,
        breederOptions,
        formDataForInit,
        actionMode,
        rowForEdit,
        breederForm,
        pathnamesForRefresh,
        t,
        validateDebounced,
        validate,
        isFromAdminModule,
        userProfileId,
        headerButtons,
        actionSettings,
        breedersSelector: selector,
        farmsSettings,
    };
};
