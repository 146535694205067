import {Options} from '@fl/cmsch-fe-library';
import {catOpts, Opt, opt, some} from 'ts-opt';

import {BreederToChoose} from 'api/gen/BreederToChoose';
import {FarmToChoose} from 'api/gen/FarmToChoose';
import {TFunction} from 'app/translations';

type ModuleName = 'mating/matingCowsFilterForm';

export const computeBreederOptions = (
    breeders: Opt<Array<BreederToChoose>>,
    t: TFunction<ModuleName>,
): Options<number> => breeders.mapIn(br => ({
    value: br.id,
    label: catOpts([
        some(br.registrationNumber).map(n => `${n} -`),
        opt(br.businessName),
        opt(br.numOfAnimals).map(m => t('numberOfAnimals', {number: m})),
    ]).join(' '),
})).orElse([]);

export const computeFarmOptions = (farms: Opt<Array<FarmToChoose>>, t: TFunction<ModuleName>): Options<number> => farms
    .mapIn(br => ({
        value: br.id,
        label: catOpts([
            some(br.code).map(n => `${n} -`),
            opt(br.name?.replace(/,$/, '')),
            opt(br.numOfCows).map(m => t('numberOfAnimals', {number: m})),
        ]).join(' '),
    })).orElse([]);
