import { TypedFormFieldComponents } from '@fl/cmsch-fe-library';
import React, { useMemo, FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Opt } from 'ts-opt';
import { Accordion } from 'api/gen/Accordion';
import { AssociationType } from 'api/gen/AssociationType';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { OrganizationToChoose } from 'api/gen/OrganizationToChoose';
import { useForm } from 'utils/forms';
import { matingAction } from '../../model';
import { MatingBullsFilterFormValues, matingBullsFilterFormName, matingBullsInitialFormValues, MatingCowsFilterFormValues } from '../../types';
import { AnimalsFormContainer } from '../animals-form-containter';
import { CriteriasInCollapse } from '../criteria-collapse';
import { BottomButtons } from './bottom-buttons';
import { ExtraFirstAccordionNodes } from './extra-first-accordion-nodes';
import { validate } from './validate';
const MALE = 'MALE';
const antFormProps = {
  layout: 'horizontal',
  labelAlign: 'left',
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 8
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 16
    }
  },
  labelWrap: true
} as const;
interface Props {
  associationType: AssociationType;
  organizations: Opt<Array<OrganizationToChoose>>;
  criteriaAccordions: Opt<Array<Accordion>>;
  criteriaDefinitions: Opt<Array<CriteriaDefinition>>;
  criteriaListsEmpty: boolean;
  animalListsEmpty: boolean;
  isAnimalsPageEmpty: boolean;
  organizationsLoading: boolean;
  onSubmit(): void;
  toggleAnimalListsVisibility(): void;
  toggleAddAnimalsVisibility(): void;
  toggleAddAnimalsFromDBVisibility(): void;
  toggleListsOverviewVisibility(): void;
  toggleCriteriaListsVisibility(): void;
  toggleCriteriaListDetailsVisibility(): void;
}
const MatingBullsFilterFormBase: FC<Props> = props => {
  const {
    organizations,
    criteriaAccordions,
    criteriaDefinitions,
    criteriaListsEmpty,
    animalListsEmpty,
    isAnimalsPageEmpty,
    associationType,
    organizationsLoading,
    onSubmit,
    toggleAnimalListsVisibility,
    toggleAddAnimalsVisibility,
    toggleAddAnimalsFromDBVisibility,
    toggleListsOverviewVisibility,
    toggleCriteriaListsVisibility,
    toggleCriteriaListDetailsVisibility
  } = props;
  const dispatch = useDispatch();
  const resetBullsAccordionValues = useCallback((accordionId: number): void => {
    dispatch(matingAction.resetAccordionValues(MALE, accordionId));
  }, [dispatch]);
  const resetBullsCriteriaValue = useCallback((criteriaCode: Opt<string>): void => {
    dispatch(matingAction.resetSingleCriteriaValue(MALE, criteriaCode.orElse('')));
  }, [dispatch]);
  const addBullsFromCsv = useCallback((file: File) => {
    dispatch(matingAction.addAnimalsFromCsv(MALE, file, associationType));
  }, [associationType, dispatch]);
  const deleteBullsFromList = useCallback((silentMode?: boolean) => {
    dispatch(matingAction.deleteAnimalsFromList(MALE, associationType, silentMode));
  }, [associationType, dispatch]);
  const {
    Form,
    Fields,
    valid,
    submitting
  } = useForm({
    form: matingBullsFilterFormName,
    initialValues: matingBullsInitialFormValues,
    onSubmit,
    validate
  }, antFormProps);
  const extraFirstAccordionNodes = useMemo(() => <ExtraFirstAccordionNodes organizations={organizations} organizationsLoading={organizationsLoading} Fields={Fields} />, [Fields, organizations, organizationsLoading]);
  const criteriaAccordionNodes = useMemo(() => <CriteriasInCollapse criteriaAccordions={criteriaAccordions.orElse([])} criteriaDefinitions={criteriaDefinitions.orElse([])} extraFirstAccordionNodes={extraFirstAccordionNodes} resetCowsAccordionValues={resetBullsAccordionValues} resetCriteriaValue={resetBullsCriteriaValue}
  // TODO: improve type after updating typescript to 4.7+
  Fields={Fields as TypedFormFieldComponents<MatingCowsFilterFormValues | MatingBullsFilterFormValues>} />, [Fields, criteriaAccordions, criteriaDefinitions, extraFirstAccordionNodes, resetBullsAccordionValues, resetBullsCriteriaValue]);
  const bottomButtons = useMemo(() => <BottomButtons valid={valid} criteriaListsEmpty={criteriaListsEmpty} animalListsEmpty={animalListsEmpty} isAnimalsPageEmpty={isAnimalsPageEmpty} handleBullsCsvUpload={addBullsFromCsv} handleDeleteBullsFromList={deleteBullsFromList} submitting={submitting} toggleAddAnimalsFromDBVisibility={toggleAddAnimalsFromDBVisibility} toggleAddAnimalsVisibility={toggleAddAnimalsVisibility} toggleAnimalListsVisibility={toggleAnimalListsVisibility} toggleCriteriaListDetailsVisibility={toggleCriteriaListDetailsVisibility} toggleCriteriaListsVisibility={toggleCriteriaListsVisibility} toggleListsOverviewVisibility={toggleListsOverviewVisibility} />, [valid, submitting, criteriaListsEmpty, animalListsEmpty, isAnimalsPageEmpty, addBullsFromCsv, toggleAnimalListsVisibility, deleteBullsFromList, toggleAddAnimalsVisibility, toggleAddAnimalsFromDBVisibility, toggleListsOverviewVisibility, toggleCriteriaListsVisibility, toggleCriteriaListDetailsVisibility]);
  return useMemo(() => <AnimalsFormContainer>
            <Form>
                {criteriaAccordionNodes}
                {bottomButtons}
            </Form>
        </AnimalsFormContainer>, [Form, bottomButtons, criteriaAccordionNodes]);
};
export const MatingBullsFilterForm = memo(MatingBullsFilterFormBase);