import {beDateFormat, beDatetimeFormat} from '@fl/cmsch-fe-library';
import moment, {Moment} from 'moment';

interface ControlYearInterval {
    startDate: Moment;
    endDate: Moment;
}

export const getControlInterval = (controlDate: Moment): ControlYearInterval => {
    const previousControlYearSubtrahend = 2;
    const currentYear = controlDate.year();
    const actualDateIsAfterEndDate = controlDate.isAfter((moment(`${currentYear}-09-30`)));
    const addYear = actualDateIsAfterEndDate ? 1 : 0;
    const startDate = moment(`${currentYear - previousControlYearSubtrahend + addYear}-10-01`, beDateFormat);
    const endDate = moment(`${currentYear + addYear}-09-30`, beDateFormat);

    return {startDate, endDate};
};

export const isDateWithinTwoControlYears = (date: Moment, controlDate: Moment): boolean => {
    if (date.isValid() && controlDate.isValid()) {
        const {startDate, endDate} = getControlInterval(controlDate);

        return moment(date, beDatetimeFormat).isBetween(startDate, endDate, 'days', '[]');
    }

    return false;
};
