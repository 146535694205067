import { Modal } from '@fl/cmsch-fe-library';
import React, { Fragment, FC, memo } from 'react';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { ConnectedTable } from 'utils/tables';
import { useOrganizationsTabSetup } from '../../hooks/use-organizations-tab-setup';
import { OrganizationsTabProps } from '../../types/organizations-tab-props';
import { SubjectFacilityForm } from '../subject-facility-form';
import { columns } from './columns';
import { rowMapper } from './row-mapper';
const OrganizationsTableBase: FC<OrganizationsTabProps> = props => {
  const {
    userProfileId,
    visibleModal,
    formDataForInit,
    headerButtons,
    actionSettings,
    organizationOptions,
    orgForm,
    roleOptions,
    actionMode,
    pathnamesForRefresh,
    isFromAdminModule,
    centersSettings,
    farmsSettings,
    organizationsSelector,
    t,
    getOrganizations,
    handleSubmitModal,
    handleOpenCreateModal,
    handleCloseModal,
    handleOrganizationSearch,
    validateAsync,
    validate
  } = useOrganizationsTabSetup(props);
  return <Fragment>
            <ConnectedTable tableId={isFromAdminModule ? 'organizations' : 'organizationsProfile'} columns={columns} onFetchTableData={getOrganizations} rowDataPageSelector={organizationsSelector} actionSettings={actionSettings} headerButtons={headerButtons} withoutVerticalScrollbar pathnamesForRefresh={pathnamesForRefresh} lazyLoadingLimit={20} paginationPosition="both" showFulltextFilter enableTableSettings enableTableUserSettings rowDataToTableRowMapper={rowMapper} highlightOnEdit emptyTableContent={isFromAdminModule ? <Button role="create" onClick={handleOpenCreateModal}>
                        {t('organization')}
                    </Button> : undefined} data-sentry-element="ConnectedTable" data-sentry-source-file="index.tsx" />
            {isFromAdminModule && <Modal title={t(actionMode === 'create' ? 'creteAuthorizedAssociationRole' : 'editAuthorizedAssociationRole')} visible={visibleModal === orgForm} onCancel={handleCloseModal} footer={null} width={768}>
                    <Ant.Row>
                        <Ant.Col xs={24}>
                            <SubjectFacilityForm data={formDataForInit} onCancel={handleCloseModal} onSubmit={handleSubmitModal} subjectOptions={organizationOptions} roleOptions={roleOptions} userProfileId={userProfileId} onOrganizationSearch={handleOrganizationSearch} onSyncValidate={validateAsync} subjectTitle={t('organization')} validate={validate} subjectFacilityFormType="ORGANIZATION" farmsSettings={farmsSettings} centersSettings={centersSettings} />
                        </Ant.Col>
                    </Ant.Row>
                </Modal>}
        </Fragment>;
};
export const OrganizationsTable = memo(OrganizationsTableBase);