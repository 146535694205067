import {showAppError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {Config} from 'app/config';
import {t} from 'app/translations';

import {CheckFEVersionAction, systemAction} from '../action';

const tt = t('system/system');

function* checkFEVersionSagaExecute(action: CheckFEVersionAction): SagaIterator {
    const response = yield* call(Api.getFEVersion);

    if (response.isSuccess) {
        if (response.data !== Config.version) {
            yield* put(systemAction.changeRefreshModalVisibility(true));
            /* Only show an error if there is no new front-end version if this
               action was called because of a version mismatch response. */
        } else if (action.payload.showErrorOnVersionMismatchAndNoNewFeVersion) {
            showAppError({title: tt('versionMismatch'), message: tt('versionMismatchBody')});
        }
    }
}

export function* checkFEVersionSaga(): SagaIterator {
    yield takeLatestF('system/CHECK_FE_VERSION', checkFEVersionSagaExecute);
}
