import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {
    tablesAction,
    getRequestedTablePageParams,
    simpleTableSelector,
    convertTableDataParamsToDto,
    getRequestedTableColumnSettings,
} from 'utils/tables';

import {userAction, GetUserMemberOfSubjectsAction} from '../action';

function* execute({payload: {tableDataRequest, userProfileId}}: GetUserMemberOfSubjectsAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(userAction.getUserMemberOfSubjectsSuccess(none));
    }
    yield* put(tablesAction.tableDataFetchStart('memberOfSubjectsProfile'));

    const tableState = yield* select(simpleTableSelector.tableState('memberOfSubjectsProfile'));
    const tableDataParams = getRequestedTablePageParams({
        tableId: 'memberOfSubjectsProfile',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest: member of subjects'),
        tableState,
    });
    const columnSettings = getRequestedTableColumnSettings<'memberOfSubjectsProfile'>(tableState);
    const {filters, params} = convertTableDataParamsToDto({tableDataParams, columnSettings});
    const response = yield* call(Api.findLoggedInUserSubjectMembers, filters, params, {userProfileId});

    if (response.isSuccess) {
        yield* put(userAction.getUserMemberOfSubjectsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('memberOfSubjectsProfile', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('userDetail/sagas')('loadMemberOfSubjects')));
        yield* put(tablesAction.tableDataFetchFailure('memberOfSubjectsProfile'));
    }
}

export function* getUserMemberOfSubjectsSaga(): SagaIterator {
    yield takeLatestF('user/GET_USER_MEMBER_OF_SUBJECTS', execute);
}
