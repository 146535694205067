import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';

import {adminAction, DeleteUserContactAction} from '../action';

const te = t('admin/sagas');

function* execute({payload: {userProfileId, userContactId}}: DeleteUserContactAction): SagaIterator {
    const response = yield* call(Api.deleteUserContact, {userContactId});

    const title = te('deleteUserContact');

    if (response.isSuccess) {
        yield* put(adminAction.getUserContacts(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('contactDeleted'), false, 'user-contact-deleted'));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* deleteUserContactSaga(): SagaIterator {
    yield takeLatestF('admin/DELETE_USER_CONTACT', execute);
}
