/* eslint-disable max-lines */
import {TableColumn} from '@fl/cmsch-fe-library';

import {MaleFleckviehAnimalInList} from 'api/gen/MaleFleckviehAnimalInList';

import {getAnimalBrowserLink} from '../../utils';

export const matingFleckviehBullsColumns: Array<TableColumn<MaleFleckviehAnimalInList>> = [
    {
        field: 'lineRegistry',
        type: 'hyperlink',
        valueToUrl: getAnimalBrowserLink,
        openInNewTab: true,
        width: 130,
        align: 'right',
    }, {
        field: 'name',
        verticalName: true,
        type: 'string',
        width: 130,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'earTag',
        filterTypes: ['betweenEarTags'],
        type: 'string',
        width: 150,
        align: 'right',
    }, {
        field: 'birthDate',
        verticalName: true,
        type: 'date',
        dateFormat: 'date',
        width: 150,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'organizationCode',
        verticalName: true,
        type: 'string',
        width: 100,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'bvMilkKg',
        verticalName: true,
        type: 'number',
        width: 100,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'bvFatKg',
        verticalName: true,
        type: 'number',
        width: 80,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'bvFatPercentage',
        verticalName: true,
        type: 'decimal',
        width: 80,
        align: 'right',
        fixedDecimals: 2,
        filterTypes: ['null'],
    }, {
        field: 'bvProteinKg',
        verticalName: true,
        type: 'number',
        width: 115,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'bvProteinPercentage',
        verticalName: true,
        type: 'decimal',
        width: 110,
        align: 'right',
        fixedDecimals: 2,
        filterTypes: ['null'],
    }, {
        field: 'rbvSomaticCells',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvClinicalMastitis',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvFrame',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvBodyDepth',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvBackAngle',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvBackWidth',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvRearPosture',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvHoovesAngle',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvForeUdderAttachment',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvFrontTeatsPlacement',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvTeatsLength',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvUdderDepth',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvSuspensoryLigament',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvRearTeatsPlacement',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvExtremities',
        verticalName: true,
        type: 'number',
        width: 95,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvUdder',
        verticalName: true,
        type: 'number',
        width: 85,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvLongevity',
        verticalName: true,
        type: 'number',
        width: 85,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvMilkability',
        verticalName: true,
        type: 'number',
        width: 85,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'milkIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'totalBreedingValue',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvDailyGain',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvCarcassGrade',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvDressingPercentage',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'fleshIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvPersistence',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'udderHealthIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvPaternalCalvingEase',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvMaternalCalvingEase',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'fertilityIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvEarlyFertilityDisorders',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'vitalityIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvHypocalcemia',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvCysts',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'fitnessIndex',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvMusculature',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvBackHeight',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvBodyLength',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvAnkleJoint',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvPastern',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvFrontUdderAttachmentLength',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvRearUdderAttachmentLength',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvTeatsWidth',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvTeatsPosition',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    }, {
        field: 'rbvUdderPurity',
        verticalName: true,
        type: 'number',
        width: 65,
        align: 'right',
        filterTypes: ['null'],
    },
];
