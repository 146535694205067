import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {uniqBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {subjectFacilityForm} from '../../types/subject-facility-form-values';
import {SearchSubjectFacilityFormRoleOptionsAction, userDetailAction} from '../action';
import {simpleUserDetailSelector} from '../selector';

function* execute({
    payload: {
        searchText,
        subjectFacilityFormType,
    },
}: SearchSubjectFacilityFormRoleOptionsAction): SagaIterator {
    const isBreederForm = subjectFacilityFormType === 'BREEDER';
    const response = yield* call(
        isBreederForm ? Api.searchBreederRoles : Api.searchOrganizationRoles,
        {text: searchText},
    );

    if (response.isSuccess) {
        const roleSelector = isBreederForm
            ? simpleUserDetailSelector.breederRoleOptions
            : simpleUserDetailSelector.organizationRoleOptions;
        const oldOptions = yield* select(roleSelector);

        const ids = (yield* select(formHelpers.formValues(subjectFacilityForm))).prop('roleIds').orElse([]);
        const selectedOptions = oldOptions.filter(option => ids.includes(option.id));

        const setRoleOptions = isBreederForm
            ? userDetailAction.setBreederRoleOptions
            : userDetailAction.setOrganizationRoleOptions;
        yield* put(setRoleOptions(uniqBy('id', [...selectedOptions, ...response.data])));
    } else {
        yield putAll(showBeError(response, t('admin/users')('roleLoading')));
    }
}

export function* searchSubjectFacilityFormRolesOptionsSaga(): SagaIterator {
    yield takeLatestF('userDetail/SEARCH_SUBJECT_FACILITY_FORM_ROLE_OPTIONS', execute);
}
