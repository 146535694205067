import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {AssociationType} from 'api/gen/AssociationType';
import {simpleTableSelector} from 'utils/tables';

import {matingSelector} from '../../model';
import {MatingLoadingId} from '../../types';

const MALE = 'MALE';
const HOLSTEIN = 'HOLSTEIN';

const isBullsFilterFormLoading = (loading: Array<MatingLoadingId>): boolean =>
    loading.includes('animalLists')
    || loading.includes('criteriaDefinitions')
    || loading.includes('criteriaLists')
    || loading.includes('organizations')
;

const isBullsFilterFormReloading = (loading: Array<MatingLoadingId>): boolean =>
    loading.includes('criteriaListDetails')
;

interface BullTabState {
    tabLoading: boolean;
    formReloading: boolean;
}

export const useBullTabState = (
    associationType: AssociationType,
    loading: Array<MatingLoadingId>,
    isMating: boolean | undefined,
): BullTabState => {
    const animalListsTag = useSelector(matingSelector.animalListsTag);
    const criteriaListsTag = useSelector(matingSelector.criteriaListsTag);
    const bullsTableName = associationType === HOLSTEIN ? 'matingHolsteinBulls' : 'matingFleckviehBulls';
    const matingBullsTableState = useSelector(simpleTableSelector.tableState(bullsTableName));
    const holsteinPage = useSelector(matingSelector.maleHolsteinAnimalsPage);
    const fleckviehPage = useSelector(matingSelector.maleFleckviehAnimalsPage);
    const bullsPage = associationType === HOLSTEIN ? holsteinPage : fleckviehPage;

    const listsLoading = useMemo(
        () => !animalListsTag.contains(MALE) || !criteriaListsTag.contains(MALE),
        [animalListsTag, criteriaListsTag],
    );
    const formLoading = useMemo(() => isBullsFilterFormLoading(loading) || listsLoading, [loading, listsLoading]);
    const formReloading = useMemo(() => isBullsFilterFormReloading(loading), [loading]);
    const tableInitializing = useMemo(
        () => !(matingBullsTableState && bullsPage.nonEmpty),
        [matingBullsTableState, bullsPage],
    );

    return {
        tabLoading: formLoading || Boolean(isMating && tableInitializing),
        formReloading,
    };
};
