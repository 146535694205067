import { DateRangeType, NumberRangeValue, TypedFormFieldComponents } from '@fl/cmsch-fe-library';
import React, { FC, memo } from 'react';
import { Opt } from 'ts-opt';
import { CriteriaDefinition } from 'api/gen/CriteriaDefinition';
import { Criterias } from 'app/mating/types/criterias';
import { validateFormat, maxIsGreater } from 'utils/date-range-tests';
import { validateNumberRange, maxNumberIsGreater } from 'utils/number-range-tests';
const dateRangeValidate = (value: DateRangeType): string | undefined => {
  const formatError = validateFormat(value);
  if (formatError) return formatError;
  return maxIsGreater(value);
};
const numberRangeValidate = (value: NumberRangeValue): string | undefined => {
  const maxSmallerError = maxNumberIsGreater(value);
  if (maxSmallerError) return maxSmallerError;
  return validateNumberRange(value);
};
interface Props {
  criteriaDefinition: CriteriaDefinition;
  disableTopAnimals?: boolean;
  Fields: TypedFormFieldComponents<Criterias, string>;
  resetCriteriaValue(criteriaName: Opt<string>): void;
}
const buildFieldName = (type: keyof Criterias, code: string): string => `[${type}][${code}]`;
const AccordionFieldBase: FC<Props> = props => {
  const {
    criteriaDefinition,
    disableTopAnimals,
    Fields,
    resetCriteriaValue
  } = props;
  if (criteriaDefinition.tag === 'BOOL') {
    return <Fields.YesNoButtonGroup name={buildFieldName('radios', criteriaDefinition.code)} label={criteriaDefinition.name} hideHelpInitially />;
  } else if (criteriaDefinition.tag === 'DATE') {
    return <Fields.DateRange name={buildFieldName('dateRanges', criteriaDefinition.code)} label={criteriaDefinition.name} validate={dateRangeValidate} hideHelpInitially clearable />;
  } else {
    return <Fields.NumberRange label={criteriaDefinition.name} name={buildFieldName('numberRanges', criteriaDefinition.code)} validate={numberRangeValidate} hideHelpInitially criteriaCode={criteriaDefinition.code} onResetCriteria={resetCriteriaValue} disablePercentage={disableTopAnimals} cursorPointerOnDisabled />;
  }
};
export const AccordionField = memo(AccordionFieldBase);