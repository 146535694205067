import {inflectWord} from '@fl/cmsch-fe-library';

import {AnimalList} from 'api/gen/AnimalList';
import {CriteriaList} from 'api/gen/CriteriaList';

export const getListName = (item: AnimalList | CriteriaList): string => {
    if ('animalsInList' in item) {
        const animalsWord = inflectWord('zvíře', 'zvířata', 'zvířat', item.animalsInList);

        return `${item.name} (${item.animalsInList} ${animalsWord})`;
    } else {
        return `${item.name}`;
    }
};
