import {takeLatestF, putAll} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';
import {GetFarmsAction, matingAction} from '../action';
import {getMatingCowsFilterFormValues} from './get-mating-cows-filter-form-values';

function* execute(_: GetFarmsAction): SagaIterator {
    yield* put(formHelpers.resetSection(matingCowsFilterFormName, 'farmIds'));

    yield* put(formHelpers.change(matingCowsFilterFormName, 'farmIds', []));

    const matingCowsFilterFormValues = yield* call(getMatingCowsFilterFormValues);

    if (!matingCowsFilterFormValues.breederId) return;

    yield* put(matingAction.setLoading('farms', true));
    const {breederId} = matingCowsFilterFormValues;
    const response = yield* call(Api.getFarms, {breederId});

    if (response.isSuccess) {
        yield* put(matingAction.getFarmsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('mating/sagas')('loadFarmsForBreeder')));
    }
    yield* put(matingAction.setLoading('farms', false));
}

export function* getFarmsSaga(): SagaIterator {
    yield takeLatestF('mating/GET_FARMS', execute);
}
