import {Association} from 'api/gen/Association';
import {BasicRole} from 'api/gen/BasicRole';
import {Breeder} from 'api/gen/Breeder';
import {Farm} from 'api/gen/Farm';
import {Organization} from 'api/gen/Organization';
import {OrganizationCenter} from 'api/gen/OrganizationCenter';
import {OtherSubject} from 'api/gen/OtherSubject';
import {Subject} from 'api/gen/Subject';

import {SubjectFacilityFormType} from '../types/subject-facility-form-values';
import {SubjectFormType} from '../types/subject-form-values';
import {UserDetailLoadingId} from '../types/user-detail-loading-id';

export const userDetailAction = {
    setEditedUserMemberId: (id: number | null) => ({
        type: 'userDetail/SET_EDITED_USER_MEMBER_ID',
        payload: id,
    }) as const,
    setMemberOfSubjectsModalVisible: (visible: boolean) => ({
        type: 'userDetail/SET_MEMBER_OF_SUBJECTS_CREATE_VISIBLE',
        payload: visible,
    }) as const,
    setSubjectsOptions: (searchText: Array<Subject>) => ({
        type: 'userDetail/SET_SUBJECTS_OPTIONS',
        payload: searchText,
    }) as const,
    setOtherSubjectRoleOptions: (roles: Array<BasicRole>) => ({
        type: 'userDetail/SET_OTHER_SUBJECT_ROLE_OPTIONS',
        payload: roles,
    }) as const,
    setAssociationRoleOptions: (roles: Array<BasicRole>) => ({
        type: 'userDetail/SET_ASSOCIATION_ROLE_OPTIONS',
        payload: roles,
    }) as const,
    setBreederRoleOptions: (roles: Array<BasicRole>) => ({
        type: 'userDetail/SET_BREEDER_ROLE_OPTIONS',
        payload: roles,
    }) as const,
    setOrganizationRoleOptions: (roles: Array<BasicRole>) => ({
        type: 'userDetail/SET_ORGANIZATION_ROLE_OPTIONS',
        payload: roles,
    }) as const,
    setUserContactsModalVisibility: (visible: boolean) => ({
        type: 'userDetail/SET_USER_CONTACTS_MODAL_VISIBILITY',
        payload: visible,
    }) as const,
    setAssociationsModalVisible: (name: string | null) => ({
        type: 'userDetail/SET_ASSOCIATIONS_MODAL_VISIBILE',
        payload: name,
    }) as const,
    setFacilityModalVisible: (name: string | null) => ({
        type: 'userDetail/SET_FACILITY_MODAL_VISIBILE',
        payload: name,
    }) as const,
    setOrganizationOptions: (organizations: Array<Organization>) => ({
        type: 'userDetail/SET_ORGANIZATIONS_OPTIONS',
        payload: organizations,
    }) as const,
    setCentersOptions: (centers: Array<OrganizationCenter>) => ({
        type: 'userDetail/SET_CENTERS_OPTIONS',
        payload: centers,
    }) as const,
    setOrganizationFarmOptions: (farms: Array<Farm>) => ({
        type: 'userDetail/SET_ORGANIZATION_FARM_OPTIONS',
        payload: farms,
    }) as const,
    setOtherSubjectsOptions: (searchText: Array<OtherSubject>) => ({
        type: 'userDetail/SET_OTHER_SUBJECTS_OPTIONS',
        payload: searchText,
    }) as const,
    setAssociationOptions: (options: Array<Association>) => ({
        type: 'userDetail/SET_ASSOCIATION_OPTIONS',
        payload: options,
    }) as const,
    setBreeders: (breeders: Array<Breeder>) => ({
        type: 'userDetail/SET_BREEDERS',
        payload: breeders,
    }) as const,
    setFarms: (farms: Array<Farm>) => ({
        type: 'userDetail/SET_FARMS',
        payload: farms,
    }) as const,
    setLoading: (id: UserDetailLoadingId, loading: boolean) => ({
        type: 'userDetail/SET_LOADING',
        payload: {id, loading},
    }) as const,
    updateBreeder: () => ({
        type: 'userDetail/UPDATE_BREEDER',
    }) as const,
    createBreeder: () => ({
        type: 'userDetail/CREATE_BREEDER',
    }) as const,
    searchBreeders: (searchText: string) => ({
        type: 'userDetail/SEARCH_BREEDERS',
        payload: {searchText},
    }) as const,
    getFarms: (breederId: number) => ({
        type: 'userDetail/GET_FARMS',
        payload: {breederId},
    }) as const,
    getOrganizationFarms: (organizationId: number) => ({
        type: 'userDetail/GET_ORGANIZATION_FARMS',
        payload: {organizationId},
    }) as const,
    validateBreeder: () => ({
        type: 'userDetail/VALIDATE_BREEDER',
    }) as const,
    searchAssociations: (searchText: string) => ({
        type: 'userDetail/SEARCH_ASSOCIATIONS',
        payload: searchText,
    }) as const,
    validateUserAssociations: () => ({
        type: 'userDetail/VALIDATE_USER_ASSOCIATIONS',
    }) as const,
    updateAssociationRoles: () => ({
        type: 'userDetail/UPDATE_ASSOCIATION_ROLES',
    }) as const,
    createAssociationRoles: () => ({
        type: 'userDetail/CREATE_ASSOCIATION_ROLES',
    }) as const,
    validateUserContact: () => ({
        type: 'userDetail/VALIDATE_USER_CONTACT',
    }) as const,
    searchSubjects: (searchText: string) => ({
        type: 'userDetail/SEARCH_SUBJECTS',
        payload: searchText,
    }) as const,
    validateUserMembership: () => ({
        type: 'userDetail/VALIDATE_USER_MEMBERSHIP',
    }) as const,
    getOrganizationCenters: (organizationId: number) => ({
        type: 'userDetail/GET_ORGANIZATION_CENTERS',
        payload: {organizationId},
    }) as const,
    searchOrganizations: (searchText: string) => ({
        type: 'userDetail/SEARCH_ORGANIZATIONS',
        payload: searchText,
    }) as const,
    validateOrganizations: () => ({
        type: 'userDetail/VALIDATE_ORGANIZATIONS',
    }) as const,
    searchOtherSubjects: (searchText: string) => ({
        type: 'userDetail/SEARCH_OTHER_ASSOCIATIONS',
        payload: searchText,
    }) as const,
    validateOtherSubjects: () => ({
        type: 'userDetail/VALIDATE_OTHER_SUBJECTS',
    }) as const,
    searchSubjectFacilityFormRoleOptions: (searchText: string, subjectFacilityFormType: SubjectFacilityFormType) => ({
        type: 'userDetail/SEARCH_SUBJECT_FACILITY_FORM_ROLE_OPTIONS',
        payload: {searchText, subjectFacilityFormType},
    }) as const,
    searchSubjectFormRoleOptions: (searchText: string, subjectFormType: SubjectFormType) => ({
        type: 'userDetail/SEARCH_SUBJECT_FORM_ROLE_OPTIONS',
        payload: {searchText, subjectFormType},
    }) as const,
};
export type SetEditedUserMemberIdAction = ReturnType<typeof userDetailAction.setEditedUserMemberId>;
export type SetMemberOfSubjectsModalVisibleAction = ReturnType<typeof userDetailAction.setMemberOfSubjectsModalVisible>;
export type SetSubjectsOptionsAction = ReturnType<typeof userDetailAction.setSubjectsOptions>;
export type SetOtherSubjectRoleOptionsAction = ReturnType<typeof userDetailAction.setOtherSubjectRoleOptions>;
export type SetAssociationRoleOptionsAction = ReturnType<typeof userDetailAction.setAssociationRoleOptions>;
export type SetBreederRoleOptionsAction = ReturnType<typeof userDetailAction.setBreederRoleOptions>;
export type SetOrganizationRoleOptionsAction = ReturnType<typeof userDetailAction.setOrganizationRoleOptions>;
export type SetUserContactsModalVisibilityAction = ReturnType<typeof userDetailAction.setUserContactsModalVisibility>;
export type SetAssociationsModalVisibleAction = ReturnType<typeof userDetailAction.setAssociationsModalVisible>;
export type SetFacilityModalVisibleAction = ReturnType<typeof userDetailAction.setFacilityModalVisible>;
export type SetOrganizationOptionsAction = ReturnType<typeof userDetailAction.setOrganizationOptions>;
export type SetCentersOptionsAction = ReturnType<typeof userDetailAction.setCentersOptions>;
export type SetOtherSubjectsOptionsAction = ReturnType<typeof userDetailAction.setOtherSubjectsOptions>;
export type SetAssociationOptionsAction = ReturnType<typeof userDetailAction.setAssociationOptions>;
export type SetBreedersAction = ReturnType<typeof userDetailAction.setBreeders>;
export type SetFarmsAction = ReturnType<typeof userDetailAction.setFarms>;
export type SetLoadingAction = ReturnType<typeof userDetailAction.setLoading>;
export type UpdateBreederAction = ReturnType<typeof userDetailAction.updateBreeder>;
export type CreateBreederAction = ReturnType<typeof userDetailAction.createBreeder>;
export type SearchBreedersAction = ReturnType<typeof userDetailAction.searchBreeders>;
export type GetFarmsAction = ReturnType<typeof userDetailAction.getFarms>;
export type ValidateBreederAction = ReturnType<typeof userDetailAction.validateBreeder>;
export type SearchAssociationsAction = ReturnType<typeof userDetailAction.searchAssociations>;
export type ValidateUserAssociationRolesAction = ReturnType<typeof userDetailAction.validateUserAssociations>;
export type UpdateAssociationRolesAction = ReturnType<typeof userDetailAction.updateAssociationRoles>;
export type CreateAssociationRolesAction = ReturnType<typeof userDetailAction.createAssociationRoles>;
export type ValidateUserContactAction = ReturnType<typeof userDetailAction.validateUserContact>;
export type ValidateUserMembershipAction = ReturnType<typeof userDetailAction.validateUserMembership>;
export type SearchSubjectsAction = ReturnType<typeof userDetailAction.searchSubjects>;
export type GetOrganizationCentersAction = ReturnType<typeof userDetailAction.getOrganizationCenters>;
export type SearchOrganizationsAction = ReturnType<typeof userDetailAction.searchOrganizations>;
export type ValidateOrganizationsAction = ReturnType<typeof userDetailAction.validateOrganizations>;
export type SearchOtherSubjectsAction = ReturnType<typeof userDetailAction.searchOtherSubjects>;
export type ValidateOtherSubjectsAction = ReturnType<typeof userDetailAction.validateOtherSubjects>;
export type SearchSubjectFacilityFormRoleOptionsAction =
    ReturnType<typeof userDetailAction.searchSubjectFacilityFormRoleOptions>;
export type SearchSubjectFormRoleOptionsAction = ReturnType<typeof userDetailAction.searchSubjectFormRoleOptions>;
export type SetOrganizationFarmOptionsAction = ReturnType<typeof userDetailAction.setOrganizationFarmOptions>;
export type GetOrganizationFarmsAction = ReturnType<typeof userDetailAction.getOrganizationFarms>;

export type UserDetailAction =
    | SetEditedUserMemberIdAction
    | SetMemberOfSubjectsModalVisibleAction
    | SetSubjectsOptionsAction
    | SetUserContactsModalVisibilityAction
    | SetAssociationsModalVisibleAction
    | SetFacilityModalVisibleAction
    | SetOrganizationOptionsAction
    | SetCentersOptionsAction
    | SetOtherSubjectsOptionsAction
    | SetAssociationOptionsAction
    | SetBreedersAction
    | SetFarmsAction
    | SetLoadingAction
    | UpdateBreederAction
    | CreateBreederAction
    | SearchBreedersAction
    | GetFarmsAction
    | ValidateBreederAction
    | UpdateAssociationRolesAction
    | CreateAssociationRolesAction
    | ValidateUserAssociationRolesAction
    | SearchAssociationsAction
    | ValidateUserContactAction
    | SearchSubjectsAction
    | ValidateUserMembershipAction
    | GetOrganizationCentersAction
    | SearchOrganizationsAction
    | ValidateOrganizationsAction
    | SearchOtherSubjectsAction
    | ValidateOtherSubjectsAction
    | SearchSubjectFacilityFormRoleOptionsAction
    | SearchSubjectFormRoleOptionsAction
    | SetOtherSubjectRoleOptionsAction
    | SetAssociationRoleOptionsAction
    | SetBreederRoleOptionsAction
    | SetOrganizationRoleOptionsAction
    | SetOrganizationFarmOptionsAction
    | GetOrganizationFarmsAction
    ;
