import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse, NullableKeys} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {UpdateUserProfileOrganizationRole} from 'api/gen/UpdateUserProfileOrganizationRole';
import {t} from 'app/translations';
import {userDetailAction} from 'app/user-detail';
import {subjectFacilityForm, SubjectFacilityFormValues} from 'app/user-detail/types/subject-facility-form-values';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {adminAction, UpdateOrganizationAction} from '../action';

const te = t('common');
const title = te('updateEntry');

const buildValuesForApi =
    ({
        approvedTo,
        userProfileRoleIds,
    }: NullableKeys<SubjectFacilityFormValues>): UpdateUserProfileOrganizationRole => ({
        approvedTo: opt(approvedTo).orNull(),
        userProfileOrganizationRoleIds: opt(userProfileRoleIds)
            .orCrash('User member of subject edit: Required userProfileOrganizationRoleIds found'),
    });

function* execute(_: UpdateOrganizationAction): SagaIterator {
    const formValues = yield* select(state => formHelpers.formValues(subjectFacilityForm)(state)
        .orCrash(`no form values: ${subjectFacilityForm}`));
    yield* put(formHelpers.startSubmit(subjectFacilityForm));

    const response = yield* call(Api.updateUserProfileOrganizationRoleBindings, buildValuesForApi(formValues));

    if (response.isSuccess) {
        const userProfileId = opt(formValues.userProfileId).orCrash('No userProfileId found');

        yield* put(adminAction.getOrganizations(opt({action: 'refresh'}), userProfileId));
        yield* put(showSuccess(title, te('updateEntrySuccess'), false, 'updateOrganization'));
        yield* put(userDetailAction.setFacilityModalVisible(null));
        yield* put(formHelpers.reset(subjectFacilityForm));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(subjectFacilityForm, extractFormErrorsFromResponse(response)));
    }
}

export function* updateOrganizationsSaga(): SagaIterator {
    yield takeLatestF('admin/UPDATE_ORGANIZATION', execute);
}
