import {takeLatestF, putAll, extractFormErrorsFromResponse, showInfo} from '@fl/cmsch-fe-library';
import {isEqual} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {opt, optEmptyArray} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {AssociationType} from 'api/gen/AssociationType';
import {CriteriaFilters} from 'api/gen/CriteriaFilters';
import {Sex} from 'api/gen/Sex';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {allOrganizationsValue} from '../../consts';
import {matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';
import {matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';
import {getParsedFilterCriterias} from '../../utils/get-parsed-filter-criterias';
import {FilterAnimalsAction} from '../action';
import {matingSelector} from '../selector';
import {getMatingBullsFilterFormValues} from './get-mating-bulls-filter-form-values';
import {getMatingCowsFilterFormValues} from './get-mating-cows-filter-form-values';
import {updateAnimalSessionAfterFilter} from './update-animal-session-saga-utils';

const tt = t('mating/sagas');

function* buildFemaleFilterCriterias(): SagaIterator<CriteriaFilters> {
    const values = yield* call(getMatingCowsFilterFormValues);
    const ids = optEmptyArray(values.farmIds).orCrash('no mating farmIds');
    const filters = getParsedFilterCriterias(opt(values.criterias).orCrash('no filter criterias'));

    return {ids, filters};
}

function* executeFemale(associationType: AssociationType): SagaIterator {
    yield* put(formHelpers.startSubmit(matingCowsFilterFormName));

    const filterCriterias = yield* call(buildFemaleFilterCriterias);
    const sex: Sex = 'FEMALE';
    const response = yield* call(Api.filterAnimalsInList, filterCriterias, {sex, associationType});
    if (response.isSuccess) {
        const {areAnimalsInSession} = response.data;

        yield* put(formHelpers.stopSubmit(matingCowsFilterFormName));
        yield* call(updateAnimalSessionAfterFilter, sex, associationType, areAnimalsInSession);
        if (!areAnimalsInSession) yield* put(showInfo(tt('loadCowList'), tt('noStudsMatchTheFilter')));
    } else {
        yield putAll(showBeError(response, tt('animalsFiltering')));
        yield* put(formHelpers.stopSubmitUnsafe(matingCowsFilterFormName, extractFormErrorsFromResponse(response)));
    }
}

function* buildMaleFilterCriterias(): SagaIterator<CriteriaFilters> {
    const values = yield* call(getMatingBullsFilterFormValues);
    const ids = optEmptyArray(values.organizationIds).orCrash('no mating organizationIds');
    const filters = getParsedFilterCriterias(opt(values).propOrCrash('criterias'));

    if (isEqual(ids, [allOrganizationsValue])) {
        const organizations = (yield* select(matingSelector.organizations)).orCrash('missing organizations');

        return {ids: organizations.map(o => o.id), filters};
    }

    return {ids, filters};
}

function* executeMale(associationType: AssociationType): SagaIterator {
    yield* put(formHelpers.startSubmit(matingBullsFilterFormName));

    const filterCriterias = yield* call(buildMaleFilterCriterias);
    const sex: Sex = 'MALE';
    const response = yield* call(Api.filterAnimalsInList, filterCriterias, {sex, associationType});

    if (response.isSuccess) {
        const {areAnimalsInSession} = response.data;
        yield* put(formHelpers.stopSubmit(matingBullsFilterFormName));
        yield* call(updateAnimalSessionAfterFilter, sex, associationType, areAnimalsInSession);
        if (!areAnimalsInSession) yield* put(showInfo(tt('loadingBullList'), tt('noBullsMatchTheFilter')));
    } else {
        yield putAll(showBeError(response, tt('animalsFiltering')));
        yield* put(formHelpers.stopSubmitUnsafe(
            matingBullsFilterFormName,
            extractFormErrorsFromResponse(response)),
        );
    }
}

function* execute({payload: {sex, associationType}}: FilterAnimalsAction): SagaIterator {
    yield* sex === 'FEMALE'
        ? call(executeFemale, associationType)
        : call(executeMale, associationType);
}

export function* filterAnimalsSaga(): SagaIterator {
    yield takeLatestF('mating/FILTER_ANIMALS', execute);
}
