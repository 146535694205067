import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {animalBrowserAction, CreateAnimalListFromIdsAction} from '../action';
import {simpleAnimalBrowserSelector} from '../selector';
import {getAnimalListId} from './get-animal-list-id';
import {getCabTableInfo} from './get-table-info';

const te = t('animalBrowserSagas');

function* execute({payload: {name, animalType}}: CreateAnimalListFromIdsAction): SagaIterator {
    yield* put(formHelpers.startSubmit('createAnimalList'));

    const {code, module} = getCabTableInfo(animalType);
    const response = yield* call(Api.validateAnimalList, {id: null, name, module, code});
    const title = te('createAnimalList');

    if (response.isSuccess) {
        const animalIds = yield* select(simpleAnimalBrowserSelector.selectedAnimalIds);
        const storingResponse = yield* call(Api.createAnimalListFromIds, {module, code, name, animalIds});
        yield* put(animalBrowserAction.toggleCreateAnimalsListFromIdsModal());
        yield* put(formHelpers.stopSubmit('createAnimalList'));

        if (storingResponse.isSuccess) {
            yield* put(animalBrowserAction.getAnimalLists(animalType, true));
            yield* put(showSuccess(title, te('animalListCreated'), false, 'createAnimalList'));
        } else {
            yield putAll(showBeError(storingResponse, title, {includeFieldErrors: true}));
        }
    } else if (response.isBadRequest) {
        yield* put(formHelpers.stopSubmit('createAnimalList'));
        yield* put(animalBrowserAction.toggleCreateAnimalsListFromIdsModal());

        const id = yield* call(getAnimalListId, name);

        yield* put(animalBrowserAction.toggleOverrideAnimalListFromIdsVisibility(opt(id)));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit('createAnimalList', extractFormErrorsFromResponse(response)));
    }
}

export function* createAnimalListFromIdsSaga(): SagaIterator {
    yield takeLatestF('animalBrowser/CREATE_ANIMAL_LIST_FROM_IDS', execute);
}
