import { debounceTime } from '@fl/cmsch-fe-library';
import { debounce, map } from 'lodash/fp';
import React, { FC, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { optArrayOpt, orCrash, pipe, zipArray } from 'ts-opt';
import { matingAction, matingSelector } from '../../model';
import { BullPreference, BullRanking, MatingBullPreferencesFormValues } from '../../types';
import { getSortedBullRankCountsFromScoringResults } from '../../utils';
import { ModalSaveScoringResults } from '../modal-save-scoring-results';
import { MatingBullPreferencesForm } from './mating-bull-preferences-form';
import { ScoringResultsTable } from './scoring-results-table';
const buildBullPreference = ([ranking, percentage]: [BullRanking, number]): BullPreference => ({
  percentage,
  lineRegistry: ranking.lineRegistry
});
export const buildBullPreferences = (formValues: MatingBullPreferencesFormValues, bullRankings: Array<BullRanking>): Array<BullPreference> => pipe(optArrayOpt(formValues.bullPreferences), orCrash('missing bull preferences'), zipArray(bullRankings), map(buildBullPreference));
export const ResultTab: FC = _ => {
  const matingResult = useSelector(matingSelector.matingResult);
  const bullPreferences = useSelector(matingSelector.bullPreferences);
  const recalculatedScoringResults = useSelector(matingSelector.recalculatedScoringResults);
  const saveScoringResultsModalVisible = useSelector(matingSelector.saveScoringResultsModalVisible);
  const shownScoringResults = useMemo(() => recalculatedScoringResults.orElse(matingResult.prop('results').orElse([])), [matingResult, recalculatedScoringResults]);
  const {
    bullRankings,
    sumOfBullsRankCounts
  } = useMemo(() => getSortedBullRankCountsFromScoringResults(shownScoringResults), [shownScoringResults]);
  const dispatch = useDispatch();
  const resetScoringResults = useCallback(() => dispatch(matingAction.resetScoringResults()), [dispatch]);
  const toggleSaveScoringResultsVisibility = useCallback(() => {
    dispatch(matingAction.toggleSaveScoringResultsVisibility());
  }, [dispatch]);
  const saveScoringResultsToFile = useCallback((fileName: string) => {
    dispatch(matingAction.saveScoringResultsToFile(fileName));
  }, [dispatch]);
  const handleSubmit = useCallback((formValues: MatingBullPreferencesFormValues) => {
    dispatch(matingAction.recalculateBullPreferences(buildBullPreferences(formValues, bullRankings)));
  }, [bullRankings, dispatch]);
  const debouncedSubmit = useMemo(() => debounce(debounceTime)(handleSubmit), [handleSubmit]);
  return <div data-sentry-component="ResultTab" data-sentry-source-file="index.tsx">
            <MatingBullPreferencesForm bullRankings={bullRankings} sumOfBullsRankCounts={sumOfBullsRankCounts} bullPreferences={bullPreferences} resetScoringResults={resetScoringResults} toggleSaveScoringResultsVisibility={toggleSaveScoringResultsVisibility} onSubmit={debouncedSubmit} data-sentry-element="MatingBullPreferencesForm" data-sentry-source-file="index.tsx" />
            <ScoringResultsTable scoringResults={shownScoringResults} data-sentry-element="ScoringResultsTable" data-sentry-source-file="index.tsx" />
            <ModalSaveScoringResults visible={saveScoringResultsModalVisible} onSubmit={saveScoringResultsToFile} onCancel={toggleSaveScoringResultsVisibility} data-sentry-element="ModalSaveScoringResults" data-sentry-source-file="index.tsx" />
        </div>;
};