import {createSelector} from 'reselect';
import {Opt} from 'ts-opt';

import {PagedModelAssociationRole} from 'api/gen/PagedModelAssociationRole';
import {PagedModelBreederRole} from 'api/gen/PagedModelBreederRole';
import {PagedModelOrganizationRole} from 'api/gen/PagedModelOrganizationRole';
import {PagedModelOtherSubjectRole} from 'api/gen/PagedModelOtherSubjectRole';
import {PagedModelUserProfileContact} from 'api/gen/PagedModelUserProfileContact';
import {PagedModelUserSubjectView} from 'api/gen/PagedModelUserSubjectView';
import {Principal} from 'api/gen/Principal';
import {UserProfile} from 'api/gen/UserProfile';
import {State} from 'app/setup';
import {UserState} from 'app/user/model/state';

interface PathNames {
    previous: Opt<string>;
    current: Opt<string>;
}

export const simpleUserSelector = {
    user: ({user}: State): UserState => user,
    userProfile: ({user}: State): Opt<UserProfile> => user.userProfile,
    currentUser: ({user}: State): Opt<Principal> => user.currentUser,
    currentUserPermissions: ({user}: State): Array<string> => user.currentUser
        .prop('permissions').orElse([]),
    isUserLoading: ({user}: State): boolean => user.isUserLoading,
    userMemberOfSubjects: ({user}: State): Opt<PagedModelUserSubjectView> => user.userMemberOfSubjects,
    userContacts: ({user}: State): Opt<PagedModelUserProfileContact> => user.userContacts,
    userAssociations: ({user}: State): Opt<PagedModelAssociationRole> => user.userAssociations,
    userBreeders: ({user}: State): Opt<PagedModelBreederRole> => user.userBreeders,
    userOtherSubjects: ({user}: State): Opt<PagedModelOtherSubjectRole> => user.userOtherSubjects,
    userOrganizations: ({user}: State): Opt<PagedModelOrganizationRole> => user.userOrganizations,
};

export const pathNamesSelector = createSelector(
    (state: State) => simpleUserSelector.user(state).previousPathname,
    (state: State) => simpleUserSelector.user(state).currentPathname,
    (previousPathname, currentPathname): PathNames => ({
        previous: previousPathname,
        current: currentPathname,
    }),
);
