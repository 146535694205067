import {convertAllErrorsFromBeToFormError, omit, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {genNakedPropOrCrash, opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {CreateMilkability} from 'api/gen/CreateMilkability';
import {formHelpers} from 'utils/forms';
import {replaceUrlWithoutRerender} from 'utils/replace-url-without-rerender';

import {CreateMilkabilityReportAction, milkabilityAction} from '../action';

// eslint-disable-next-line max-lines-per-function
function* execute(action: CreateMilkabilityReportAction): SagaIterator {
    const {data, focusEarTag, editingId} = action.payload;

    yield* put(formHelpers.startSubmit('milkabilityReport'));

    const {examinationDate, technicianNumber} = data;

    const get = genNakedPropOrCrash(data);

    const milkabilityReport: CreateMilkability = {
        earTag: get('earTag'),
        examinationDate: get('examinationDate'),
        milkedTotal: get('milkedTotal'),
        milkingTime: opt(data).prop('milkingTime').prop('value').orCrash('milkingTime is missing'),
        technicianNumber: get('technicianNumber'),
    };

    const response = yield* editingId
        ? call(Api.updateMilkabilityReport, omit(milkabilityReport, ['earTag']), {id: editingId})
        : call(Api.createMilkabilityReport, milkabilityReport);

    if (response.isSuccess) {
        yield* put(milkabilityAction.createMilkabilityReportSuccess(response.data));
        yield* put(formHelpers.initialize('milkabilityReport', {
            technicianNumber,
            examinationDate,
            apmv: null,
            earTag: null,
            milkedTotal: null,
            milkingTime: null,
        }));
        yield* put(milkabilityAction.setEditingMilkabilityReportId(undefined));
        replaceUrlWithoutRerender('/milkability/new');
        setTimeout(() => focusEarTag?.());
    } else {
        const errors = convertAllErrorsFromBeToFormError(response.data);
        yield* put(formHelpers.stopAsyncValidation('milkabilityReport', errors.errors));
        yield* put(formHelpers.setAsyncWarnings('milkabilityReport', errors.warnings));
    }
    yield* put(formHelpers.stopSubmit('milkabilityReport', {}));
}

export function* createMilkabilityReportSaga(): SagaIterator {
    yield takeLatestF('milkability/CREATE_MILKABILITY_REPORT', execute);
}
