import { TableColumn } from '@fl/cmsch-fe-library';
import React, { MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Calf } from 'api/gen/Calf';
import { MotherLinkProps } from 'app/animal-browser/types/mother-link-props';
import { getCalfBrowserLink } from 'app/animal-browser/utils/get-calf-browser-link';
import { getBullBrowserLink } from '../../utils/get-bull-browser-link';
import { getCalfSexOptions } from '../../utils/get-sex-options';

// eslint-disable-next-line max-lines-per-function
export const getCalfBrowserColumns = (handleMotherLink: (row: MotherLinkProps) => (event: MouseEvent) => void): Array<TableColumn<Calf>> => [{
  field: 'earTag',
  type: 'hyperlink',
  filterTypes: ['betweenEarTags'],
  valueToUrl: getCalfBrowserLink,
  openInNewTab: true
}, {
  field: 'genotype',
  type: 'string',
  filterTypes: ['null']
}, {
  field: 'selectionIndex',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null']
}, {
  field: 'stableCode',
  type: 'string'
}, {
  field: 'subjectBusinessName',
  type: 'string',
  filterTypes: ['null']
}, {
  field: 'sex',
  type: 'option',
  options: getCalfSexOptions()
}, {
  field: 'birthDate',
  type: 'date',
  dateFormat: 'date',
  filterTypes: ['null']
}, {
  field: 'breed',
  type: 'string',
  filterTypes: ['null']
}, {
  field: 'studbookSection',
  type: 'string',
  filterTypes: ['null']
}, {
  field: 'firstInbreedingAt',
  type: 'date',
  dateFormat: 'date',
  filterTypes: ['null']
}, {
  field: 'firstPregnancyAt',
  type: 'date',
  dateFormat: 'date',
  filterTypes: ['null']
}, {
  field: 'pregnant',
  type: 'optionalBoolean',
  filterTypes: ['null']
}, {
  field: 'inbreedingBullLineRegistry',
  type: 'router',
  filterTypes: ['null'],
  valueToLink: getBullBrowserLink('inbreedingBullLineRegistry'),
  openInNewTab: true
}, {
  field: 'inbreedingBullEarTag',
  filterTypes: ['betweenEarTags', 'null'],
  type: 'router',
  valueToLink: getBullBrowserLink('inbreedingBullEarTag'),
  openInNewTab: true
}, {
  field: 'inbreedingBullName',
  type: 'string',
  filterTypes: ['null']
}, {
  field: 'fatherLineRegistry',
  type: 'router',
  valueToLink: getBullBrowserLink('fatherLineRegistry'),
  openInNewTab: true,
  filterTypes: ['null']
}, {
  field: 'fatherEarTag',
  type: 'router',
  filterTypes: ['betweenEarTags', 'null'],
  valueToLink: getBullBrowserLink('fatherLineRegistry'),
  openInNewTab: true
}, {
  field: 'fatherName',
  type: 'string',
  filterTypes: ['null']
}, {
  field: 'genotypeIndex',
  type: 'optionalBoolean',
  filterTypes: ['null']
}, {
  field: 'fatherSelectionIndex',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null']
}, {
  field: 'motherEarTag',
  filterTypes: ['betweenEarTags', 'null'],
  type: 'string',
  formatValue: (value: string, row: MotherLinkProps): ReactNode => {
    const handleClick = handleMotherLink(row);
    return <Link to="#" onClick={handleClick} onAuxClick={handleClick}>
                    {value}
                </Link>;
  }
}, {
  field: 'motherSelectionIndex',
  type: 'decimal',
  fixedDecimals: 1,
  filterTypes: ['null']
}, {
  field: 'motherName',
  type: 'string',
  filterTypes: ['null']
}, {
  field: 'motherStandardizedLactationCount',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherAvgLactationDays',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherAvgLactationMilkKg',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherMaxLactationOrder',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherMaxLactationMilkKg',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherMaxLactationProteinKg',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherMaxLactationProteinPercentage',
  type: 'decimal',
  verticalName: true,
  fixedDecimals: 2,
  filterTypes: ['null']
}, {
  field: 'motherMaxLactationFatKg',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherMaxLactationFatPercentage',
  type: 'decimal',
  verticalName: true,
  fixedDecimals: 2,
  filterTypes: ['null']
}, {
  field: 'motherFirstLactationDays',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherFirstLactationMilkKg',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherFirstLactationProteinKg',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherFirstLactationProteinPercentage',
  type: 'decimal',
  verticalName: true,
  fixedDecimals: 2,
  filterTypes: ['null']
}, {
  field: 'motherFirstLactationFatKg',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherFirstLactationFatPercentage',
  type: 'decimal',
  verticalName: true,
  fixedDecimals: 2,
  filterTypes: ['null']
}, {
  field: 'motherLifelongLactationMilkKg',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherExterior',
  type: 'string',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherMilkStrengthToFrame',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherBodyStructureToMusculature',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherExtremities',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}, {
  field: 'motherUdder',
  type: 'number',
  verticalName: true,
  filterTypes: ['null']
}];