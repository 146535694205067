import { TableDataRequest } from '@fl/cmsch-fe-library';
import React, { FC, useCallback, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Opt } from 'ts-opt';
import { StableToChoose } from 'api/gen/StableToChoose';
import { Content } from 'app/content';
import { useOurTranslation } from 'app/translations';
import { Card, ScreenHeader } from 'common/layout';
import { StablesTable } from '../components/stables-table';
import { animalBrowserAction, simpleAnimalBrowserSelector } from '../model';
interface DispatchProps {
  getStables(tableDataRequest: Opt<TableDataRequest<StableToChoose>>): void;
}
type Props = DispatchProps;
const FarmsAndStablesBase: FC<Props> = props => {
  const {
    getStables
  } = props;
  const {
    t
  } = useOurTranslation('stableTable');
  const title = t('tableName');
  const header = useMemo(() => <ScreenHeader title={title} moduleForTutorials="ANIMALS_BROWSER" />, [title]);
  const dispatch = useDispatch();
  const exportToCsv = useCallback(() => {
    dispatch(animalBrowserAction.getStablesCsv());
  }, [dispatch]);
  const exportInProgress = useSelector(simpleAnimalBrowserSelector.exportInProgress);
  const stablesPageEmpty = useSelector(simpleAnimalBrowserSelector.stablePageEmpty);
  return <Content browserTabTitle={t('pageTitle')} data-sentry-element="Content" data-sentry-component="FarmsAndStablesBase" data-sentry-source-file="farms-and-stables.tsx">
            <Card title={header} titleSize="big" noPadding data-sentry-element="Card" data-sentry-source-file="farms-and-stables.tsx">
                <StablesTable exportInProgress={exportInProgress} exportDisabled={exportInProgress || stablesPageEmpty} getStables={getStables} exportToCsv={exportToCsv} data-sentry-element="StablesTable" data-sentry-source-file="farms-and-stables.tsx" />
            </Card>
        </Content>;
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getStables: (tableDataRequest: Opt<TableDataRequest<StableToChoose>>): void => void dispatch(animalBrowserAction.getStablesTableData(tableDataRequest))
});
export const FarmsAndStables = connect(null, mapDispatchToProps)(FarmsAndStablesBase);