import {beErrorsTranslations, componentsTranslations} from '@fl/cmsch-fe-library';
import merge from 'ts-deepmerge';

import {adminTranslations} from 'app/admin/translations';
import {analysisProtocolCorrectionTranslations} from 'app/analysis-protocol-correction/translations';
import {animalBrowserTranslations} from 'app/animal-browser/translations';
import {matingTranslations} from 'app/mating/translations';
import {milkabilityTranslations} from 'app/milkability/translations';
import {modalsTranslations} from 'app/modals/translations';
import {systemTranslations} from 'app/system/translations';
import {userDetailTranslations} from 'app/user-detail/translations';
import {userTranslations} from 'app/user/translations';
import {layoutTranslations} from 'common/layout/translations';

import {commonTranslations} from './common-translations';

export const appTranslations = merge(
    commonTranslations,
    userTranslations,
    matingTranslations,
    milkabilityTranslations,
    adminTranslations,
    animalBrowserTranslations,
    analysisProtocolCorrectionTranslations,
    layoutTranslations,
    systemTranslations,
    modalsTranslations,
    userDetailTranslations,
);

export const translations = merge(
    appTranslations,
    beErrorsTranslations,
    componentsTranslations,
);
