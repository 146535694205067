export const adminTranslations = {
    CS: {
        'admin/permissions': {
            code: 'Kód',
            discriminator: 'Diskriminátor',
            userEnable: 'Uživatelské oprávnění',
            pageTitle: 'Oprávnění',
            permissions: 'Oprávnění',
            controller: 'Kontejner',
            action: 'Akce',
            comment: 'Popis',
            newPermission: 'Oprávnění',
            createNewPermission: 'Vytvoření nového oprávnění',
            editPermission: 'Úprava oprávnění',
            confirmDeleteRow: 'Tato akce může mít negativní vliv na celou aplikaci. Opravdu chcete oprávnění odstranit?',
        },
        'admin/roles': {
            pageTitle: 'Role',
            code: 'Kód',
            discriminator: 'Diskriminátor',
            userEnableRole: 'Uživatelská role',
            name: 'Název',
            subordinateAssignedRoles: 'Přiřazené role',
            subordinateUnassignedRoles: 'Zbývající role',
            subordinateRoles: 'Podřízené role',
            newRole: 'Vytvoření nové role',
            role: 'Role',
            editRole: 'Úprava role',
            roles: 'Role',
        },
        'admin/roleUnassignedPermissionsTable': {
            unassignedPermissions: 'Zbývající oprávnění',
        },
        'admin/rolePermissionsTable': {
            assignedPermissions: 'Přiřazená oprávnění',
        },
        'admin/appInfo': {
            pageTitle: 'Informace o aplikaci',
            missingScripts: 'Chybějící scripty',
            deploymentDate: 'Datum nasazení',
            library: 'Knihovna',
            applicationInfo: 'Informace o aplikaci',
            webName: 'eSkotWeb',
            externalLibraries: 'Verze externích knihoven',
            version: 'Verze',
            database: 'Databáze',
            eSkotDatabaseVersion: 'Verze databáze eSkot',
        },
        'admin/users': {
            roles: 'Role',
            associations: 'Svazy',
            association: 'Svaz',
            authorizedOrganizations: 'Oprávněné organizace',
            organization: 'Organizace',
            centers: 'Střediska',
            breeders: 'Chovatelé',
            breederNumber: 'Registrační číslo chovatele',
            farms: 'Hospodářství',
            otherSubjects: 'Ostatní subjekty',
            memberOfSubjects: 'Člen subjektů',
            contactInformation: 'Kontaktní údaje',
            email: 'Registrační e-mail',
            loadUserDetail: 'Načítání detailu uživatele',
            users: 'Uživatelé',
            pageTitle: 'Uživatelé',
            active: 'Aktivní',
            dateFromToIsInvalid: 'Schváleno do nesmí být dřív než Schváleno od.',
            editUserAsMemberOfSubject: 'Upravit člena subjektu',
            creteUserAsMemberOfSubject: 'Přidat člena subjektu',
            memberOfSubject: 'Člen subjektu',
            creteAssociationRole: 'Přidat role svazu',
            creteAuthorizedAssociationRole: 'Přidat role organizaci',
            editAuthorizedAssociationRole: 'Upravit role organizaci',
            createOtherSubjectRole: 'Přidat role subjektu',
            editAssociationRole: 'Upravit role svazu',
            editOtherSubjectRole: 'Upravit role subjektu',
            subjectsLoading: 'Načítání subjektů',
            roleLoading: 'Načítání rolí',
            associationsLoading: 'Načítání svazů',
            organizationsLoading: 'Načítání organizací',
            centersLoading: 'Načítání středisek',
            otherSubjectsLoading: 'Načítání ostatních subjektů',
            subject: 'Subjekt',
            approvedFrom: 'Schváleno od',
            approvedTo: 'Schváleno do',
            addContact: 'Přidat kontakt',
            contact: 'Kontakt',
            editContact: 'Upravit kontakt',
            contactType: 'Typ kontaktu',
            contactValue: 'Hodnota kontaktu',
            allCenters: 'Všechna střediska',
            allFarms: 'Všechna hospodářství',
            breedersOptionsLoading: 'Načtení chovatelů',
            farmOptionsLoading: 'Načtení hospodářství',
            createRoleToUser: 'Přidat roli chovateli',
            updateRoleToUser: 'Upravit roli chovateli',
            addBreeder: 'Chovatel',
        },
        'admin/sagas': {
            createPermission: 'Vytvoření oprávnění',
            permissionAdded: 'Oprávnění bylo úspěšně přidáno.',
            deletePermission: 'Odstranění oprávnění',
            permissionDeleted: 'Oprávnění bylo úspěšně odstraněno.',
            deleteRole: 'Odstranění role',
            roleDeleted: 'Role byla úspěšně odstraněna.',
            loadRole: 'Načtení role',
            loadUserDetail: 'Načtení uživatele',
            addPermissionToRole: 'Přidání oprávnění k roli',
            loadUsersList: 'Načtení uživatelů',
            appInfo: 'Informace o aplikaci',
            removePermissionFromRole: 'Odebrání oprávnění roli',
            editRole: 'Úprava role',
            roleEdited: 'Role byla úspěšně upravena.',
            loadRoleList: 'Načtení rolí',
            loadUnassignedPermissions: 'Načtení nepřiřazených oprávnění k roli',
            createRole: 'Vytvoření role',
            roleAdded: 'Role byla úspěšně přidána.',
            loadPermissionsList: 'Načtení oprávnění',
            editPermission: 'Úprava oprávnění',
            permissionEdited: 'Oprávnění bylo úspěšně upraveno.',
            loadPermissionList: 'Načtení oprávnění role',
            updateUserMembership: 'Úprava člena subjektu',
            userMembershipEdited: 'Člen subjektu byl úspěšně upraven.',
            createUserMembership: 'Přidání člena subjektu',
            userMembershipCreated: 'Člen subjektu byl úspěšně přidán.',
            deleteUserContact: 'Odstranění kontaktu',
            contactDeleted: 'Kontakt byl úspěšně odstraněn.',
            updateContact: 'Upravení kontaktu',
            contactUpdated: 'Kontakt byl úspěšně upraven.',
            createContact: 'Vytvoření kontaktu',
            contactAdded: 'Kontakt byl úspěšně přidán.',
        },
    },
    EN: {
        'admin/permissions': {
            pageTitle: 'Permissions',
            permissions: 'Permissions',
            code: 'Code',
            discriminator: 'Discriminator',
            userEnable: 'User permission',
            controller: 'Controller',
            action: 'Action',
            comment: 'Comment',
            newPermission: 'Permission',
            createNewPermission: 'Create new permission',
            editPermission: 'Edit permission',
            confirmDeleteRow: 'This action may have a negative effect on the entire application. Are you sure you want to remove the permission?',
        },
        'admin/roles': {
            pageTitle: 'Roles',
            code: 'Code',
            discriminator: 'Discriminator',
            userEnableRole: 'User role',
            name: 'Name',
            subordinateAssignedRoles: 'Assigned roles',
            subordinateUnassignedRoles: 'Unassigned roles',
            subordinateRoles: 'Subordinate roles',
            newRole: 'Create new role',
            role: 'Role',
            roles: 'Roles',
            editRole: 'Edit role',
        },
        'admin/roleUnassignedPermissionsTable': {
            unassignedPermissions: 'Unassigned permissions',
        },
        'admin/rolePermissionsTable': {
            assignedPermissions: 'Assigned permissions',
        },
        'admin/appInfo': {
            pageTitle: 'Application information',
            missingScripts: 'Missing scripts',
            deploymentDate: 'Deployment date',
            library: 'Library',
            applicationInfo: 'Application information',
            webName: 'eSkotWeb',
            externalLibraries: 'External libraries versions',
            version: 'Version',
            database: 'Database',
            eSkotDatabaseVersion: 'eSkot database version',
        },
        'admin/users': {
            roles: 'Roles',
            associations: 'Associations',
            association: 'Association',
            authorizedOrganizations: 'Organizations',
            organization: 'Organization',
            centers: 'Centers',
            breeders: 'Breeders',
            breederNumber: 'Breeder registration number',
            farms: 'Farms',
            otherSubjects: 'Other subjects',
            memberOfSubjects: 'Member of subjects',
            contactInformation: 'Contact',
            email: 'Registration email',
            loadUserDetail: 'Load user detail',
            users: 'Users',
            pageTitle: 'Users',
            active: 'Active',
            addContact: 'Add contact',
            contact: 'Contact',
            editContact: 'Edit contact',
            dateFromToIsInvalid: 'Approved to date cannot be earlier than the approved from date.',
            editUserAsMemberOfSubject: 'Update subject member',
            creteUserAsMemberOfSubject: 'Add subject member',
            memberOfSubject: 'Subject member',
            creteAssociationRole: 'Create association role',
            creteAuthorizedAssociationRole: 'Create authorized association role',
            editAuthorizedAssociationRole: 'Edit authorized association role',
            editAssociationRole: 'Edit association role',
            createOtherSubjectRole: 'Create other subject role',
            editOtherSubjectRole: 'Edit other subject role',
            subjectsLoading: 'Loading of subjects',
            roleLoading: 'Loading of roles',
            associationsLoading: 'Loading of associations',
            organizationsLoading: 'Loading of organizations',
            centersLoading: 'Loading of centers',
            otherSubjectsLoading: 'Loading of other subjects',
            subject: 'Subject',
            approvedFrom: 'Approved from',
            approvedTo: 'Approved to',
            allCenters: 'All organization centers',
            contactType: 'Contact type',
            contactValue: 'Contact value',
            allFarms: 'All farms',
            breedersOptionsLoading: 'Loading breeders',
            farmOptionsLoading: 'Loading farms',
            createRoleToUser: 'Add role to breeder',
            updateRoleToUser: 'Edit role to breeder',
            addBreeder: 'Breeder',
        },
        'admin/sagas': {
            createPermission: 'Create permission',
            permissionAdded: 'Permission was successfully added.',
            deletePermission: 'Delete permission',
            permissionDeleted: 'Permission was successfully deleted.',
            deleteRole: 'Delete role',
            roleDeleted: 'Role was successfully deleted.',
            loadRole: 'Load role',
            loadUserDetail: 'Load user',
            addPermissionToRole: 'Add permission to role',
            loadUsersList: 'Load users',
            appInfo: 'App information',
            removePermissionFromRole: 'Remove permission from role',
            editRole: 'Edit role',
            roleEdited: 'Role was successfully edited.',
            loadRoleList: 'Load roles',
            loadUnassignedPermissions: 'Load permissions unassigned to a role',
            createRole: 'Create role',
            roleAdded: 'Role was successfully added.',
            loadPermissionsList: 'Load permissions',
            editPermission: 'Edit permission',
            permissionEdited: 'Permission was successfully edited.',
            loadPermissionList: 'Load permissions for a role',
            updateUserMembership: 'Edit of user in subject',
            userMembershipEdited: 'User in subject was successfully edited.',
            createUserMembership: 'Create of user in subject',
            userMembershipCreated: 'User in subject was successfully created.',
            deleteUserContact: 'Delete user contact',
            contactDeleted: 'Contact was successfully deleted.',
            updateContact: 'Edit user contact',
            contactUpdated: 'Contact was successfully edited.',
            createContact: 'Contact contact',
            contactAdded: 'Contact was successfully added.',
        },
    },
};
