import {takeLatestF, putAll, showSuccess, extractFormErrorsFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {roleFormName} from '../../types';
import {buildRoleValuesForApi, getRoleFormValues} from '../../utils/role';
import {adminAction, CreateRoleAction} from '../action';

const te = t('admin/sagas');
const title = te('createRole');

function* execute(_: CreateRoleAction): SagaIterator {
    yield* put(formHelpers.startSubmit(roleFormName));
    const roleFormValues = yield* select(getRoleFormValues);

    const response = yield* call(Api.createRole, buildRoleValuesForApi(roleFormValues));

    if (response.isSuccess) {
        yield* put(adminAction.setRoleCreateModalVisibility(false));
        yield* put(adminAction.getRoles(opt({action: 'refresh'})));
        yield* put(showSuccess(title, te('roleAdded'), false, 'create-role'));
        yield* put(formHelpers.reset(roleFormName));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(roleFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* createRoleSaga(): SagaIterator {
    yield takeLatestF('admin/CREATE_ROLE', execute);
}
