import {t} from 'app/translations';
import {EskotValidator} from 'utils/eskot-validator';
import {Errors} from 'utils/validator';

import {GetCriteriaListDetailsFormValues} from './get-criteria-list-details-form-values';

export const validate = (values: GetCriteriaListDetailsFormValues): Errors<GetCriteriaListDetailsFormValues> => {
    const validator = new EskotValidator(values);

    validator.nonEmpty('criteriaListId', t('mating/modalGetCriteriaDetails')('criteriaList'));

    return validator.generateErrorsObject();
};
