export const legacyAction = {
    resetScreen: () => ({
        type: 'legacy/RESET_SCREEN',
    }) as const,
    setIframeLoading: (value: boolean) => ({
        type: 'legacy/SET_IS_IFRAME_LOADING',
        payload: {value},
    }) as const,
};

export type ResetScreenAction = ReturnType<typeof legacyAction.resetScreen>;
export type SetIframeLoadingAction = ReturnType<typeof legacyAction.setIframeLoading>;

export type LegacyAction =
    | ResetScreenAction
    | SetIframeLoadingAction
;
