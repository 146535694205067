import {takeLeadingF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {State} from 'app/setup';
import {languageStore} from 'utils/language-store';

import {InitializeAction, userAction} from '../action';
import {afterBeLoginSetup, setLegacyLanguageCookie} from './saga-utils';

function* execute({payload: {isRestrictedPath}}: InitializeAction): SagaIterator {
    const savedLanguageCookie = languageStore.get();

    if (savedLanguageCookie) {
        yield* put(userAction.setUiLanguage(savedLanguageCookie));
    } else {
        const currentUiLanguage = yield* select((state: State) => state.user.language);
        languageStore.set(currentUiLanguage);
        yield* call(setLegacyLanguageCookie, currentUiLanguage);
    }

    yield* call(afterBeLoginSetup, isRestrictedPath);

    yield* put(userAction.initializeFinish());
}

export function* initializeSaga(): SagaIterator {
    yield takeLeadingF('user/INITIALIZE', execute);
}
