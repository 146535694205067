import {sortBy, reverse} from 'lodash/fp';
import * as O from 'optics-ts';
import {pipe} from 'ts-opt';

import {MatingResult} from 'api/gen/MatingResult';
import {ScoringResult} from 'api/gen/ScoringResult';

const resultsO = O.optic<MatingResult>().prop('results').elems();

const sortBullsInScoringResult = (scoringResult: ScoringResult): ScoringResult => ({
    earTag: scoringResult.earTag,
    bulls: pipe(scoringResult.bulls, sortBy(x => x.score), reverse),
});

export const getSortedMatingResult = O.modify(resultsO)(sortBullsInScoringResult);
