import React, { FC, memo } from 'react';
import { Config } from 'app/config';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { Card } from 'common/layout';
import { useForm } from 'utils/forms';
import { loginFormName, initialLoginFormValues } from '../../types/login-form-values';
import { validate } from './validate';
import styles from './styles.sass';
interface Props {
  onSubmit(): void;
}
const LoginFormBase: FC<Props> = props => {
  const {
    onSubmit
  } = props;
  const {
    t
  } = useOurTranslation('user/login');
  const {
    Form,
    Fields,
    submitting,
    valid
  } = useForm({
    form: loginFormName,
    initialValues: initialLoginFormValues,
    onSubmit,
    validate
  }, {
    layout: 'vertical'
  });
  return <div className={styles.login} data-sentry-component="LoginFormBase" data-sentry-source-file="index.tsx">
            <Card data-sentry-element="Card" data-sentry-source-file="index.tsx">
                <Ant.Col span={22} offset={1} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Form data-sentry-element="Form" data-sentry-source-file="index.tsx">
                        <Fields.Input name="username" type="text" label={t('username')} disabled={submitting} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                        <Fields.Input name="password" type="password" label={t('password')} disabled={submitting} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                        <Button type="submit" visuals="primary" block disabled={!valid || submitting} loading={submitting} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                            {t('loginButton')}
                        </Button>
                        <div className="col-12 d-flex justify-content-end mt-3 ml-4">
                            <a href={Config.restorePasswordLink}>
                                <Button visuals="link" data-sentry-element="Button" data-sentry-source-file="index.tsx">{t('forgottenPassword')}</Button>
                            </a>
                        </div>
                    </Form>
                </Ant.Col>
            </Card>
        </div>;
};
export const LoginForm = memo(LoginFormBase);