import {TableColumn} from '@fl/cmsch-fe-library';

import {TFunction} from 'app/translations';

import {UserContactsTableType} from '../../types/user-contacts-table-type';

export const columns = (tCommon: TFunction<'common'>): Array<TableColumn<UserContactsTableType>> => [
    {
        field: 'contactType',
        type: 'option',
        options: [
            {
                label: tCommon('email'),
                value: 'E_MAIL',
            },
            {
                label: tCommon('phone'),
                value: 'PHONE',
            },
            {
                label: tCommon('fax'),
                value: 'FAX',
            },
        ],
    },
    {
        field: 'value',
        type: 'string',
    },
];
