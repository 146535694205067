/* eslint-disable no-underscore-dangle, @typescript-eslint/naming-convention */

type Environment = 'development' | 'staging' | 'production';

declare const __URL__: string;
declare const __API__: string;
declare const __LEGACY_ESKOT_URL__: string;
declare const __ENV__: Environment;
declare const __VERSION__: string;
declare const __GA_TRACKING_ID__: string | undefined;
declare const __DISABLE_IMMUTABILITY_CHECK__: boolean;
declare const __RESTORE_PASSWORD_LINK__: string;
declare const __FE_LIBRARY_VERSION__: string;
declare const __LOCAL__: boolean;
declare const __YEAR__: number;
declare const __E2E__: boolean;
declare const __DISABLE_LEGACY_LOGIN__: boolean;

export class Config {
    public static readonly url: string = __URL__;

    public static readonly apiUrl: string = __API__;

    public static readonly legacyEskotUrl: string = __LEGACY_ESKOT_URL__;

    public static readonly environment: Environment = __ENV__;

    public static readonly version: string = __VERSION__;

    public static readonly isProduction = Config.environment === 'production' || Config.environment === 'staging';

    public static readonly isDevelopment = Config.environment === 'development';

    public static readonly gaTrackingId: string | undefined = __GA_TRACKING_ID__;

    public static readonly disableImmutabilityCheck: boolean = __DISABLE_IMMUTABILITY_CHECK__;

    public static readonly restorePasswordLink: string = __RESTORE_PASSWORD_LINK__;

    public static readonly feLibraryVersion: string = __FE_LIBRARY_VERSION__;

    public static readonly local = __LOCAL__;

    public static readonly year = __YEAR__;

    public static readonly e2e = __E2E__;

    public static readonly disableLegacyLogin = __DISABLE_LEGACY_LOGIN__;
}
