import {DefaultAdminProps} from '../types/user-detail-tab-props';

export const thirdLevel = 3;

export const allOptionValue = Number.MAX_SAFE_INTEGER;

export const defaultProps: DefaultAdminProps = {
    canUpdate: false,
    userProfileId: NaN,
    createEntry: undefined,
    updateEntry: undefined,
    deleteEntry: undefined,
};
