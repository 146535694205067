import { ActionSettings, Table, useWindowDimensions, LG } from '@fl/cmsch-fe-library';
import { orderBy } from 'lodash/fp';
import React, { FC, memo, useMemo } from 'react';
import { CorrectionReportDetail } from 'api/gen/CorrectionReportDetail';
import { useOurTranslation } from 'app/translations';
import { getColumns } from './columns';
import tableStyles from './styles.sass';
interface Props {
  createdApcReports: Array<CorrectionReportDetail>;
  deleteCreatedReportInProgress?: boolean;
  editCreatedApcReport(row: CorrectionReportDetail): void;
  deleteCreatedApcReport(id: number, isCreatedCorrectionReport: boolean): void;
  resetCreatedApcReports(): void;
}
const CreatedApcTableBase: FC<Props> = props => {
  const {
    createdApcReports,
    deleteCreatedReportInProgress,
    editCreatedApcReport,
    deleteCreatedApcReport: deleteCreatedCorrectionReport,
    resetCreatedApcReports: resetCreatedMilkabilityReports
  } = props;
  const {
    t
  } = useOurTranslation('apc/createdReportsTable');
  const {
    t: tOverview
  } = useOurTranslation('apc/overviewTable');
  const {
    width
  } = useWindowDimensions();
  const useVerticalNames = width < LG;
  const actionSettings = useMemo((): ActionSettings<CorrectionReportDetail> => ({
    extraActions: [{
      id: 'delete',
      role: 'delete',
      callback: id => deleteCreatedCorrectionReport(Number(id), true),
      disabledTitle: tOverview('deleteDisabledTitle')
    }, {
      id: 'edit',
      role: 'edit',
      callback: (_, row) => editCreatedApcReport(row),
      confirmClick: true,
      confirmTitle: t('rewriteApcReportFormData'),
      disabledTitle: tOverview('editDisabledTitle'),
      confirmPlacement: 'topLeft'
    }]
  }), [tOverview, t, deleteCreatedCorrectionReport, editCreatedApcReport]);
  const sortedApcReports = useMemo(() => orderBy(x => x.id, 'desc', createdApcReports), [createdApcReports]);
  const columns = useMemo(() => getColumns(useVerticalNames, t, tOverview), [useVerticalNames, t, tOverview]);
  return <div className={tableStyles.reportsTable} data-sentry-component="CreatedApcTableBase" data-sentry-source-file="index.tsx">
            <Table tableId="created-apc-reports-table" columns={columns} rows={sortedApcReports} actionSettings={actionSettings} scroll={{
      x: true
    }} emptyTableMessages={{
      noRows: t('noApcReports')
    }} loading={deleteCreatedReportInProgress} onUnmount={resetCreatedMilkabilityReports} size="small" data-sentry-element="Table" data-sentry-source-file="index.tsx" />
        </div>;
};
export const CreatedApcReportsTable = memo(CreatedApcTableBase);