import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put} from 'typed-redux-saga';

import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';

import {matingBullsFilterFormName} from '../../types/mating-bulls-filter-form-values';
import {matingCowsFilterFormName} from '../../types/mating-cows-filter-form-values';
import {matingParamsSelectionFormName} from '../../types/mating-params-selection-form-values';
import {matingAction, ResetFormsAction} from '../action';

const te = t('mating/sagas');

function* execute({payload: {associationType}}: ResetFormsAction): SagaIterator {
    yield* put(formHelpers.reset(matingCowsFilterFormName));
    // Resetting/inniting form again does not run validations if nothing changes, changing hack field mitigates this bug
    yield* put(formHelpers.change(matingCowsFilterFormName, 'validationHack', Date.now()));
    yield* put(formHelpers.reset(matingBullsFilterFormName));
    yield* put(formHelpers.change(matingBullsFilterFormName, 'validationHack', Date.now()));
    yield* put(formHelpers.reset(matingParamsSelectionFormName));
    yield* put(matingAction.deleteAnimalsFromList('FEMALE', associationType, true));
    yield* put(matingAction.deleteAnimalsFromList('MALE', associationType, true));

    const title = te('resetAllSettings');

    yield putAll(showSuccess(title, te('resetComplete'), false, 'reset-forms'));
}

export function* resetFormsSaga(): SagaIterator {
    yield takeLatestF('mating/RESET_FORMS', execute);
}
