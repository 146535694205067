import {convertAllErrorsFromBeToFormError, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {optEmptyString, zipToOptArray} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {PermissionActionNameV2Schema} from 'api/gen/PermissionActionNameV2';
import {PermissionCodeV2Schema} from 'api/gen/PermissionCodeV2';
import {PermissionControllerNameV2Schema} from 'api/gen/PermissionControllerNameV2';
import {PermissionDetail} from 'api/gen/PermissionDetail';
import {ValidatePermission} from 'api/gen/ValidatePermission';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {CreateOrEditMode} from '../../types/permission-form-mode';
import {createOrUpdatePermissionFormName} from '../../types/permission-form-values';
import {ValidatePermissionAction} from '../action';

const fieldsAreValid = (actionName: string | null, controllerName: string | null, code: string | null): boolean =>
    (code === null || PermissionCodeV2Schema.is(code))
    && (actionName === null || PermissionActionNameV2Schema.is(actionName))
    && (controllerName === null || PermissionControllerNameV2Schema.is(controllerName));

const dataAreValid = (
    actionName: string | null,
    controllerName: string | null,
    code: string | null,
    tableRecord: PermissionDetail | undefined,
    mode: CreateOrEditMode,
): boolean => {
    if (mode === 'edit' && tableRecord && code === tableRecord.code) return false;

    return fieldsAreValid(actionName, controllerName, code);
};

const genApiObject = (
    actionName: string | null,
    controllerName: string | null,
    code: string | null,
    tableRecord: PermissionDetail | undefined,
    mode: CreateOrEditMode,
): ValidatePermission => {
    if (mode === 'edit') {
        if (tableRecord) {
            const computedCode = tableRecord.code === code ? null : code;
            return {code: computedCode, actionName: null, controllerName: null};
        } else {
            throw new Error('Table record not found');
        }
    } else {
        const [computedActionName, computedControllerName] = zipToOptArray([actionName, controllerName])
            .orElseAny([null, null] as const);
        return {code, actionName: computedActionName, controllerName: computedControllerName};
    }
};

// eslint-disable-next-line max-lines-per-function
function* execute({payload: {mode, tableRecord}}: ValidatePermissionAction): SagaIterator {
    const {
        actionName,
        controllerName,
        code,
    } = (yield* select(formHelpers.formValues(createOrUpdatePermissionFormName)))
        .orCrash('no permission form values');

    const actionValue = optEmptyString(actionName).orNull();
    const controllerValue = optEmptyString(controllerName).orNull();
    const codeValue = optEmptyString(code).orNull();

    if (!dataAreValid(actionValue, controllerValue, codeValue, tableRecord, mode)) return;

    yield* put(formHelpers.startAsyncValidation(createOrUpdatePermissionFormName));

    const response = yield* call(
        Api.validatePermission,
        genApiObject(actionValue, controllerValue, codeValue, tableRecord, mode),
    );

    if (!response.isSuccess) {
        if (response.isBadRequest) {
            const errors = convertAllErrorsFromBeToFormError(response.data);
            yield* put(formHelpers.stopAsyncValidation(createOrUpdatePermissionFormName, errors.errors));
            yield* put(isNull(controllerValue) || isNull(actionValue)
                ? formHelpers.touch(createOrUpdatePermissionFormName, 'code')
                : formHelpers.touch(createOrUpdatePermissionFormName, 'code', 'actionName', 'controllerName'),
            );
        } else {
            yield putAll(showBeError(response, t('admin/sagas')('editPermission')));
        }
    } else {
        yield* put(formHelpers.stopAsyncValidation(createOrUpdatePermissionFormName, {}));
    }
}

export function* validatePermissionSaga(): SagaIterator {
    yield takeLatestF('admin/VALIDATE_PERMISSION', execute);
}
