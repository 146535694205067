// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-app-milkability-components-created-milkability-reports-table-___styles__reportsTable___rpCio{margin-top:1.5rem}@media (min-width: 1200px){.src-app-milkability-components-created-milkability-reports-table-___styles__reportsTable___rpCio{margin-top:.5rem}}
`, "",{"version":3,"sources":["webpack://./src/app/milkability/components/created-milkability-reports-table/styles.sass"],"names":[],"mappings":"AAAA,kGAAc,iBAAA,CAAkB,2BAA0B,kGAAc,gBAAA,CAAA","sourcesContent":[".reportsTable{margin-top:1.5rem}@media(min-width: 1200px){.reportsTable{margin-top:.5rem}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportsTable": `src-app-milkability-components-created-milkability-reports-table-___styles__reportsTable___rpCio`
};
export default ___CSS_LOADER_EXPORT___;
