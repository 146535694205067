import {TableDataRequest, ToolbarButton, ActionSettings} from '@fl/cmsch-fe-library';
import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {Opt} from 'ts-opt';

import {RoleView} from 'api/gen/RoleView';
import {TFunction, useOurTranslation} from 'app/translations';
import {simpleUserSelector} from 'app/user/model/selector';
import useCurrentUserHasAnyPermission from 'app/user/utils/use-current-user-has-any-permission';

import {adminPermissions} from '../constants/permissions';
import {adminAction} from '../model/action';
import {simpleAdminSelector} from '../model/selector';

interface UseRoleScreenSetup {
    isCreateModalVisible: boolean;
    id: number | null;
    t: TFunction<'admin/roles'>;
    headerButtons: Array<ToolbarButton>;
    actionSettings: ActionSettings<RoleView>;
    getRoles(tableDataRequest: Opt<TableDataRequest<RoleView>>): void;
    handleCancelCreateRole(): void;
    handleCreateRole(): void;
    openCreateRoleModal(): void;
    handleCancelEditRole(): void;
    handleSubmitEditRole(): void;
}

// eslint-disable-next-line max-lines-per-function
export const useRolesScreenSetup = (): UseRoleScreenSetup => {
    const dispatch = useDispatch();
    const {t} = useOurTranslation('admin/roles');
    const isCreateModalVisible = useSelector(simpleAdminSelector.roleCreateModalVisible);
    const userPermissions = useSelector(simpleUserSelector.currentUserPermissions);
    const id = useSelector(simpleAdminSelector.editedRoleId);

    const handleCreateRole = useCallback(() => {
        dispatch(adminAction.createRole());
    }, [dispatch]);

    const handleCancelCreateRole = useCallback(() => {
        dispatch(adminAction.setRoleCreateModalVisibility(false));
    }, [dispatch]);

    const getRoles = useCallback(
        (tableDataRequest: Opt<TableDataRequest<RoleView>>): void => {
            dispatch(adminAction.getRoles(tableDataRequest));
        }, [dispatch]);

    const openCreateRoleModal = useCallback(() => {
        dispatch(adminAction.setRoleCreateModalVisibility(true));
    }, [dispatch]);

    const handleDeleteRole = useCallback((rowId: number) => {
        dispatch(adminAction.deleteRole(rowId));
    }, [dispatch]);

    const handleCancelEditRole = useCallback(() => {
        dispatch(adminAction.setEditedRoleId(null));
    }, [dispatch]);

    const handleSubmitEditRole = useCallback(() => {
        if (id) dispatch(adminAction.updateRole(id));
    }, [dispatch, id]);

    const handleEditCallback = useCallback((rowId: number) => {
        dispatch(adminAction.setEditedRoleId(rowId));
    }, [dispatch]);

    const handleShowDetail = useCallback((rowId: number) => {
        dispatch(routerActions.push(`/administrace/role/${rowId}`));
    }, [dispatch]);

    const headerButtons: Array<ToolbarButton> = useMemo(() => [
        {
            id: 'createRole',
            icon: 'plusOutlined',
            onClick: openCreateRoleModal,
            title: t('role'),
            visuals: 'primary',
            hidden: !userPermissions.includes(adminPermissions.createRole),
        },
    ], [openCreateRoleModal, t, userPermissions]);

    const hasTableActionPermissions = useCurrentUserHasAnyPermission([
        adminPermissions.deletePermissons,
        adminPermissions.updatePermissions,
        adminPermissions.roles,
    ]);

    const actionSettings: ActionSettings<RoleView> = useMemo(() => hasTableActionPermissions ? ({
        extraActions: [
            {
                id: 'delete',
                role: 'delete',
                callback: handleDeleteRole,
                hidden: !userPermissions.includes(adminPermissions.deleteRole),
            },
            {
                id: 'edit',
                role: 'edit',
                callback: handleEditCallback,
                hidden: !userPermissions.includes(adminPermissions.updateRole),
            },
            {
                id: 'detail',
                callback: handleShowDetail,
                hidden: !userPermissions.includes(adminPermissions.roles),
                icon: 'searchOutlined',
            },
        ],
    }) : {}, [hasTableActionPermissions, handleDeleteRole, handleEditCallback, handleShowDetail, userPermissions]);

    return {
        isCreateModalVisible,
        id,
        t,
        getRoles,
        handleCancelCreateRole,
        handleCreateRole,
        openCreateRoleModal,
        handleCancelEditRole,
        handleSubmitEditRole,
        headerButtons,
        actionSettings,
    };
};
