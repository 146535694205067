import {takeLatestF, putAll, showSuccess} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {showBeError} from 'utils/show-be-error';
import {tablesAction} from 'utils/tables';

import {DeleteCriteriaListAction, matingAction} from '../action';
import {getCriteriaListVersion} from './get-criteria-list-version';
import {getMatingTableId} from './get-mating-table-id';

const te = t('mating/sagas');

function* execute({
    payload: {
        criteriaListId,
        bookmark,
        associationType,
        gridUserSettingsId,
    },
}: DeleteCriteriaListAction): SagaIterator {
    const version = yield* call(getCriteriaListVersion, criteriaListId);
    const response = yield* call(Api.deleteCriteriaList, {version}, {criteriaListId});

    const title = te('deleteCriteriaList');

    if (response.isSuccess) {
        yield* put(matingAction.getCriteriaLists(bookmark, associationType, true));
        const tableId = getMatingTableId(bookmark, associationType);
        if (gridUserSettingsId && tableId) {
            yield* put(tablesAction.getTableSettings(tableId, 'FORCE_LOAD'));
        }
        yield* put(showSuccess(title, te('criteriaListDeleted'), false, 'delete-criteria-list'));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* deleteCriteriaListSaga(): SagaIterator {
    yield takeLatestF('mating/DELETE_CRITERIA_LIST', execute);
}
